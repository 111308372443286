import { useTranslation } from "react-i18next";
import { Button, TextInput } from "imagine-ui";
import { useState } from "react";

interface Props {
  onResetClick: (email: string) => void;
  errorMessage?: string; // Error message to display
  loading?: boolean;
}

export const ForgottenPassword = (props: Props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  return (
    <div id="reset-password">
      {props.errorMessage !== "" && (
        <p className="text-danger" id="reset-password-error">
          {props.errorMessage}
        </p>
      )}
      <div className="mb">
        <span className="label">{t("users:emailAddress")}</span>
        <TextInput
          elementId="reset-password-email-input"
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </div>
      <div className="row items-center">
        <Button
          elementId="reset-password-button"
          className="mt mt-lg--md"
          onClick={() => props.onResetClick(email)}
          loading={props.loading}
        >
          {t("users:resetPassword")}
        </Button>
      </div>
    </div>
  );
};
