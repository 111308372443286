import { useTranslation } from "react-i18next";
import { Modal, Label } from "imagine-ui";
import { useState } from "react";
import { Delimiter } from "imagine-essentials";
import { UnitScale } from "project";
import { MeasureInput } from "draw";

type Props = {
  visible: boolean;
  onClose: () => void;
  onChange: (distance: number) => void;
  distance: number;
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Displays a modal with an input for the distance between two points on the reference image
 */
export const ReferenceImageDistanceModal = (props: Props) => {
  const { t } = useTranslation();

  return (
    <Modal
      title={t("distance")}
      visible={props.visible}
      onClose={props.onClose}
      confirmText={t("imagine:save")}
      center
      elementId="reference-image-distance-modal"
    >
      <div id="reference-image-distance-page">
        <div className="mt">
          <Label>{t("enterDistanceBetweenPoints")}</Label>
          <MeasureInput
            elementId="referenceImageDistance"
            value={props.distance}
            delimiter={props.delimiter}
            unitScale={props.unitScale}
            onFinish={props.onChange}
            omitFractions
          />
        </div>
      </div>
    </Modal>
  );
};
