import React, { useEffect } from "react";
import { Loader } from "@googlemaps/js-api-loader";
import { MapPosition } from "draw";
import { SentryReporter } from "imagine-essentials";

type Props = {
  center?: MapPosition;
  zoom?: number; // Zoom is the Google Maps zoom level
};

/**
 * Get the latitude and longitude in an easy to use MapPosition object
 * @param ll Latitude and longitude
 */
const toMapPosition = (ll: google.maps.LatLng) => {
  return {
    lat: ll.lat(),
    lng: ll.lng(),
  } as MapPosition;
};

// meters_per_pixel = 156543.03392 * Math.cos(latLng.lat() * Math.PI / 180) / Math.pow(2, zoom)
let map: google.maps.Map;
/**
 * Displays a map from Google Maps.
 */
export const SatelliteMap = (props: Props) => {
  useEffect(() => {
    try {
      const loader = new Loader({
        apiKey: "AIzaSyB6437lw96uwoQc8KbiZzWepMt8p95GJ8c",
        version: "weekly",
      });

      loader.load().then(() => {
        map = new google.maps.Map(
          document.getElementById("satellite-map") as HTMLElement,
          {
            // center: {lat: 55.7159, lng: 12.4452}, //55.71614788083828, 12.44527774089585
            center: props.center || { lat: 0, lng: 0 },
            zoom: props.zoom || 20,
            tilt: 0,
            disableDefaultUI: true,
            mapTypeId: "satellite",
            gestureHandling: "none", //"greedy",
          }
        );

        // map.addListener("bounds_changed", updateMap);
        // map.addListener("dragend", releaseMap);
      });
    } catch (e) {
      console.error("Failed to load the satellite map");
      SentryReporter.captureException(e);
    }

    return () => {
      // google.maps.event.clearListeners(map, "bounds_changed");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (map !== undefined && props.center !== undefined) {
      const center = map.getCenter();
      if (center !== undefined) {
        const currentCenter = toMapPosition(center);
        if (
          props.center.lat !== currentCenter.lat ||
          props.center.lng !== currentCenter.lng
        ) {
          map.setCenter(props.center);
        }
      }
    }
  }, [props.center]);

  useEffect(() => {
    if (map !== undefined && props.zoom !== undefined) {
      map.setZoom(props.zoom);
    }
  }, [props.zoom]);

  return (
    // <div className="satellite-map-wrapper">
    <div className="satellite-map" id="satellite-map"></div>
    // </div>
  );
};
