import { Point, Rectangle, Size, Tools } from "imagine-essentials";
import { UnitScale } from "project";
import { Measure } from "..";
import { of } from "rxjs";

const unitsPerPixel = (zoom: number) => {
  return 1 / zoom;
};

/**
 * Get the number of meters that a given number of pixels will represent on the screen.
 * @param value
 * @param zoom
 * @returns
 */
const pixelToUnit = (value: number, zoom: number) => {
  return value / zoom;
};

/**
 * Get number of pixels that a given value in meters will be on the screen.
 * @param value
 * @param zoom
 * @returns
 */
const unitToPixel = (value: number, zoom: number) => {
  return value * zoom;
};

const unitToPixelSize = (unitSize: Size, zoom: number) => {
  return {
    width: unitToPixel(unitSize.width, zoom),
    height: unitToPixel(unitSize.height, zoom),
  } as Size;
};

const unitToPixelPosition = (
  unitPosition: Point,
  zoom: number,
  reference: Point
) => {
  return {
    x: Tools.round(unitToPixel(unitPosition.x, zoom) + reference.x, 2),
    y: Tools.round(unitToPixel(unitPosition.y, zoom) + reference.y, 2),
  } as Point;
};

const pixelToUnitSize = (pixelSize: Size, zoom: number, decimals = 2) => {
  return {
    width: Tools.round(pixelToUnit(pixelSize.width, zoom), decimals),
    height: Tools.round(pixelToUnit(pixelSize.height, zoom), decimals),
  } as Size;
};

const pixelToUnitPosition = (
  pixelPosition: Point,
  zoom: number,
  reference: Point,
  decimals = 3
) => {
  return {
    x: Tools.round(pixelToUnit(pixelPosition.x - reference.x, zoom), decimals),
    y: Tools.round(pixelToUnit(pixelPosition.y - reference.y, zoom), decimals),
  } as Point;
};

const getPointSum = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x + b.x, decimals),
    y: Tools.round(a.y + b.y, decimals),
  } as Point;
};

const getSizeSum = (a: Size, b: Size, decimals = 2) => {
  return {
    width: Tools.round(a.width + b.width, decimals),
    height: Tools.round(a.height + b.height, decimals),
  } as Size;
};

const getPointSubtracted = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x - b.x, decimals),
    y: Tools.round(a.y - b.y, decimals),
  } as Point;
};

const getSizeSubtracted = (a: Size, b: Size, decimals = 2) => {
  return {
    width: Tools.round(a.width - b.width, decimals),
    height: Tools.round(a.height - b.height, decimals),
  } as Size;
};

const getPointMultiplied = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x * b.x, decimals),
    y: Tools.round(a.y * b.y, decimals),
  } as Point;
};

const isPointsNear = (a: Point, b: Point, dist: number) => {
  if (a.x > b.x - dist && a.x < b.x + dist) {
    if (a.y > b.y - dist && a.y < b.y + dist) {
      return true;
    }
  }
  return false;
};

const calculateDistance = (a: Point, b: Point, decimals = 2) => {
  return Tools.round(
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)),
    decimals
  );
};

const isPointsEqual = (a: Point, b: Point) => {
  return a.x === b.x && a.y === b.y;
};

/**
 * Calculates new center position for the canvas after zoom has been performed. The location of the mouse
 * is kept still.
 * @param unitSize Size of canvas in units
 * @param oldZoom Old zoom index
 * @param newZoom New zoom index.
 * @param mousePos Mouse position on the board (px)
 * @param pxCornerPos Current corner position of the canvas
 * @param reference Reference coordinates for the board
 */
const calculateZoomedCanvasCenter = (
  unitSize: Size,
  oldZoom: number,
  newZoom: number,
  mousePos: Point,
  pxCornerPos: Point,
  reference: Point
) => {
  // Calculate mouse position in canvas
  const mousePosUnit = {
    x: pixelToUnit(mousePos.x - pxCornerPos.x - reference.x, oldZoom),
    y: pixelToUnit(mousePos.y - pxCornerPos.y - reference.y, oldZoom),
  };

  const pxSizeNew = {
    width: unitToPixel(unitSize.width, newZoom),
    height: unitToPixel(unitSize.height, newZoom),
  };

  const mousePosCanvasNew = {
    x: unitToPixel(mousePosUnit.x, newZoom),
    y: unitToPixel(mousePosUnit.y, newZoom),
  };

  const offset = {
    x: mousePos.x - mousePosCanvasNew.x - reference.x,
    y: mousePos.y - mousePosCanvasNew.y - reference.y,
  };

  const canvasCenter = {
    x: offset.x + pxSizeNew.width / 2,
    y: offset.y + pxSizeNew.height / 2,
  };

  return canvasCenter;
};

/**
 * Calculates new zero reference after zoom has been performed. The location of the mouse
 * is kept still.
 * @param oldZoom Old zoom value
 * @param newZoom New zoom value.
 * @param mousePos Mouse position on the board (px)
 * @param zeroReference Current zero reference
 * @param boardOffset Reference coordinates for the board
 */
const calculateZoomedZeroReference = (
  oldZoom: number,
  newZoom: number,
  mousePos: Point,
  zeroReference: Point,
  boardOffset: Point
) => {
  // Calculate mouse position in canvas
  const mousePosUnit = {
    x: pixelToUnit(mousePos.x - zeroReference.x - boardOffset.x, oldZoom),
    y: pixelToUnit(mousePos.y - zeroReference.y - boardOffset.y, oldZoom),
  };

  const mousePosCanvasNew = {
    x: unitToPixel(mousePosUnit.x, newZoom),
    y: unitToPixel(mousePosUnit.y, newZoom),
  };

  const offset = {
    x: mousePos.x - mousePosCanvasNew.x - boardOffset.x,
    y: mousePos.y - mousePosCanvasNew.y - boardOffset.y,
  };

  return offset;
};

/**
 * Calculates the canvas offset relative to the board based on the center position.
 * @param unitSize The size of the area (canvas) in units
 * @param pxCenterPos The current center position
 * @param zoom
 */
const calculateCanvasOffset = (
  unitSize: Size,
  pxCenterPos: Point,
  zoom: number
) => {
  const pxSize = unitToPixelSize(unitSize, zoom);

  return {
    x: pxCenterPos.x - pxSize.width / 2,
    y: pxCenterPos.y - pxSize.height / 2,
  };
};

/**
 * Calculated endpoint from a position and a size.
 */
const getEndPoint = (position: Point, size: Size) => {
  return {
    x: Tools.round(position.x + size.width, 3),
    y: Tools.round(position.y + size.height, 3),
  } as Point;
};

/**
 * Get the size of the rectangle going from point A to point B.
 * @param a Upper left corner.
 * @param b Lower right coner.
 */
const getAreaSize = (a: Point, b: Point) => {
  return {
    width: b.x - a.x,
    height: b.y - a.y,
  } as Size;
};

/**
 * Get the unit length  that a single grid cell should represent.
 * @param zoom
 */
const getGridCellSize = (zoom: number, unitScale: UnitScale) => {
  // 1 m = XX px
  const SCALE_WIDTH = 500;
  const metersWithinScale = SCALE_WIDTH / unitToPixel(1, zoom);
  if (unitScale === UnitScale.METRIC) {
    // Scale should be able to contain 6 steps in order to align with the grid
    const exactStep = metersWithinScale / 6;
    let step = 0.01;
    if (exactStep >= 10) step = 10;
    else if (exactStep >= 5) step = 5;
    else if (exactStep >= 2) step = 2;
    else if (exactStep >= 1) step = 1;
    else if (exactStep >= 0.5) step = 0.5;
    else if (exactStep >= 0.2) step = 0.2;
    else if (exactStep >= 0.1) step = 0.1;
    else if (exactStep >= 0.05) step = 0.05;
    else if (exactStep >= 0.02) step = 0.02;
    return step;
  } else {
    const oneFoot = 1 / Measure.convertToImperialFeet(1);
    const feetWithinScale = Measure.convertToImperialFeet(metersWithinScale);
    const exactStepFeet = feetWithinScale / 6;

    let step = 0.01;
    if (exactStepFeet >= 300) step = oneFoot * 300;
    else if (exactStepFeet >= 150) step = oneFoot * 150;
    else if (exactStepFeet >= 90) step = oneFoot * 90;
    else if (exactStepFeet >= 75) step = oneFoot * 75;
    else if (exactStepFeet >= 60) step = oneFoot * 60;
    else if (exactStepFeet >= 30) step = oneFoot * 30;
    else if (exactStepFeet >= 15) step = oneFoot * 15; // 1 grid = 5yd
    else if (exactStepFeet >= 9) step = oneFoot * 9;
    else if (exactStepFeet >= 6) step = oneFoot * 6;
    else if (exactStepFeet >= 3) step = oneFoot * 3;
    else if (exactStepFeet >= 2) step = oneFoot * 2;
    else if (exactStepFeet >= 1) step = oneFoot * 1;
    else if (exactStepFeet >= 0.5) step = oneFoot / 2;
    else if (exactStepFeet >= 0.25) step = oneFoot / 4;
    else step = oneFoot / 8;

    return step;
  }
};

const hasParentClass = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  element: any,
  className: string,
  depth: number
): boolean => {
  if (element === undefined) return false;
  if (element === null) return false;
  if (element.className === undefined) return false;
  if (element.className === null) return false;
  if (element.className.baseVal === undefined) return false;
  if (element.className.baseVal === className) return true;
  if (element.parentElement === undefined) return false;
  if (depth > 0) {
    return hasParentClass(element.parentElement, className, depth - 1);
  }
  return false;
};

const getCanvasCenter = (boardSize: Size, boardOffset: Point) => {
  return {
    x: boardOffset.x + boardSize.width / 2,
    y: boardOffset.y + boardSize.height / 2,
  };
};

const roundSize = (size: Size, decimals: number) => {
  return {
    width: Tools.round(size.width, decimals),
    height: Tools.round(size.height, decimals),
  };
};

const roundPoint = (point: Point, decimals: number) => {
  return {
    x: Tools.round(point.x, decimals),
    y: Tools.round(point.y, decimals),
  };
};

const getRectangleFromPoints = (p1: Point, p2: Point) => {
  const position = {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
  };
  const size = {
    width: Math.abs(p1.x - p2.x),
    height: Math.abs(p1.y - p2.y),
  };
  return {
    position: position,
    size: size,
  };
};

const isPointWithinRectangle = (point: Point, rectangle: Rectangle) => {
  if (point.x < rectangle.position.x) return false;
  if (point.y < rectangle.position.y) return false;
  if (point.x > rectangle.position.x + rectangle.size.width) return false;
  if (point.y > rectangle.position.y + rectangle.size.height) return false;
  return true;
};

export const CanvasOperations = {
  unitsPerPixel,
  pixelToUnit,
  unitToPixel,
  unitToPixelSize,
  unitToPixelPosition,
  pixelToUnitSize,
  pixelToUnitPosition,
  getPointSum,
  getSizeSum,
  getPointSubtracted,
  getSizeSubtracted,
  getPointMultiplied,
  isPointsNear,
  calculateDistance,
  isPointsEqual,
  calculateZoomedCanvasCenter,
  calculateZoomedZeroReference,
  calculateCanvasOffset,
  getEndPoint,
  getAreaSize,
  getGridCellSize,
  hasParentClass,
  getCanvasCenter,
  roundSize,
  roundPoint,
  getRectangleFromPoints,
  isPointWithinRectangle,
};
