import { ModelDesign, ModelDesignSvg, ShapeType } from "..";
import { Point, SentryReporter } from "imagine-essentials";
import { Link, useWindowWidth } from "imagine-ui";
import {
  useState,
  useEffect,
  useRef,
  useLayoutEffect,
  useMemo,
  useCallback,
} from "react";
import { useTranslation } from "react-i18next";

const pos = { x: 0, y: 0 };

type Props = {
  designs: ModelDesign[];
  selected?: number;
  onSelect?: (id?: number) => void;
  onPreview?: (id: number | null) => void; // Emitted when the mouse is hovering a design, or left a design
  displayNames?: boolean;
  disabled?: boolean;
  idPrefix?: string;
  includeClear?: boolean; // First option will be a cross icons that clears the selection
  className?: string;
  max?: number;
};

/**
 * Displays a window to selected a model design by pressing it.
 * @param props
 */
export const ModelPicker = (props: Props) => {
  const { t } = useTranslation();
  const targetRef = useRef<HTMLDivElement>(null);
  const [designSize, setDesignSize] = useState({ width: 40, height: 40 });
  const [columns, setColumns] = useState(3);

  const [hoverDesign, setHoverDesign] = useState<number | null>(null);
  const [showAll, setShowAll] = useState(false);

  /**
   * The designs that should be rendered. Will only render some unless user chooses to show all.
   */
  const renderDesigns = useMemo(() => {
    const end = showAll ? undefined : props.max || 18;
    let firstOption;
    if (props.includeClear) {
      firstOption = {
        id: 0,
        name: t("imagine:none"),
        viewBox: "0 0 240 240",
        shapes: [
          {
            type: ShapeType.PATH,
            properties: {
              path: "m77.5 70a7.5 7.5 0 0 0-5.3027 2.1973 7.5 7.5 0 0 0 0 10.605l37.197 37.197-37.197 37.197a7.5 7.5 0 0 0 0 10.605 7.5 7.5 0 0 0 10.605 0l37.197-37.197 37.197 37.197a7.5 7.5 0 0 0 10.605 0 7.5 7.5 0 0 0 0-10.605l-37.197-37.197 11.377-11.375 25.82-25.822a7.5 7.5 0 0 0 0-10.605 7.5 7.5 0 0 0-5.3027-2.1973 7.5 7.5 0 0 0-5.3027 2.1973l-25.822 25.82-11.375 11.377-37.197-37.197a7.5 7.5 0 0 0-5.3027-2.1973z",
            },
            style: {
              fillColor: "red",
            },
          },
        ],
      };
    }
    try {
      const models = props.designs.slice(0, end);
      if (firstOption) return [firstOption, ...models];
      else return models;
    } catch (error) {
      console.error("Failed to slice designs", end, props.designs);
      SentryReporter.captureException(error, {
        Action: "Getting subset of designs",
        "Show count": end,
        Designs: JSON.stringify(props.designs),
      });
      return props.designs;
    }
  }, [props.designs, showAll]);

  const select = (id: number) => {
    if (props.onSelect) {
      if (props.selected === id) props.onSelect();
      else props.onSelect(id);
    }
  };

  const markDesign = (id: number) => {
    setHoverDesign(id);
    if (props.onPreview) {
      props.onPreview(id);
    }
  };

  const unmarkDesign = () => {
    setHoverDesign(null);
    if (props.onPreview) {
      props.onPreview(null);
    }
  };

  const toggleMarkedDesign = (id: number) => {
    if (hoverDesign === id) unmarkDesign();
    else markDesign(id);
  };

  // Make the designs 1/3 of the picker width
  const updateSize = useCallback(() => {
    if (targetRef.current !== undefined) {
      const width =
        targetRef.current !== null ? targetRef.current.offsetWidth : 0;
      let cols = 3;
      if (width > 500) cols = 6;
      else if (width > 400) cols = 5;
      else if (width > 300) cols = 4;
      const gap = 4; // Equivalent to gap-xs
      let d = Math.floor((width - 2 * gap) / cols) - 16;
      if (d < 0) d = 0;
      const newDesignSize = {
        width: d,
        height: d,
      };
      // Size might be negative initially when all components are not ready yet
      if (newDesignSize.width < 0 || newDesignSize.height < 0) {
        console.warn("Invalid design size, setting to 0");
        setDesignSize({ width: 0, height: 0 });
      } else {
        setDesignSize(newDesignSize);
        setColumns(cols);
      }
    }
  }, []);

  useEffect(() => {
    setShowAll(false);
  }, [props.designs]);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateSize();
    });
    const currentRef = targetRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [targetRef, updateSize]);

  return (
    <div
      className={
        "model-picker-wrapper" + (props.className ? " " + props.className : "")
      }
      ref={targetRef}
    >
      <div className={"model-picker grid columns-" + columns + " gap-xs"}>
        {renderDesigns.map((design: ModelDesign, index: number) => (
          <div
            key={design.id}
            id={(props.idPrefix || "model-picker-design") + "-" + index}
            className={props.selected === design.id ? "model marked" : "model"}
            onClick={() => select(design.id)}
            onMouseEnter={() => markDesign(design.id)}
            onMouseLeave={() => unmarkDesign()}
            onTouchStart={() => toggleMarkedDesign(design.id)}
          >
            <ModelDesignSvg
              design={design}
              size={designSize}
              position={pos}
              viewBox={design.viewBox}
              cursor="pointer"
            />
            {design.name && props.displayNames && (
              <p className="text-truncate text-center text-xs">{design.name}</p>
            )}
          </div>
        ))}
      </div>
      {/* <div className="model-picker">
        {renderDesigns.map((design: ModelDesign, index: number) => (
          <div
            key={design.id}
            id={(props.idPrefix || "model-picker-design") + "-" + index}
            className={props.selected === design.id ? "model marked" : "model"}
            style={{
              width: modelSize.width,
              height: modelSize.height + (props.displayNames ? 20 : 0),
              marginRight: index % 3 === 2 ? 0 : space,
              marginBottom: space,
            }}
            onClick={() => select(design.id)}
            onMouseEnter={() => markDesign(design.id)}
            onMouseLeave={() => unmarkDesign()}
            onTouchStart={() => toggleMarkedDesign(design.id)}
          >
            <ModelDesignSvg
              design={design}
              size={designSize}
              position={pos}
              viewBox={design.viewBox}
              cursor="pointer"
            />
            {design.name && props.displayNames && (
              <p className="truncate-text text-center">{design.name}</p>
            )}
          </div>
        ))}
      </div> */}
      {renderDesigns.length < props.designs.length && (
        <div className="py text-center">
          <Link onClick={() => setShowAll(true)}>{t("imagine:showAll")}</Link>
        </div>
      )}
    </div>
  );
};
