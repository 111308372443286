import { Label, Modal, TextInput } from "imagine-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onSave: (name: string) => void;
  name: string;
  title: string;
  confirmText: string;
  loading?: boolean;
}

export const PlanNameModal = (props: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState(props.name);

  const save = () => {
    props.onSave(name);
  };

  return (
    <Modal
      title={props.title}
      visible
      onClose={props.onClose}
      center
      onConfirm={save}
      confirmDisabled={name === ""}
      confirmText={props.confirmText}
      loading={props.loading}
      elementId="plan-name-modal"
    >
      <Label>{t("imagine:name")}:</Label>
      <TextInput value={name} onChange={setName} elementId="plan-name-input" />
    </Modal>
  );
};
