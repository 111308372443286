import { Tools } from "imagine-essentials";
import { ColorVariant } from "..";

interface Props {
  max: number;
  value: number;
  color?: ColorVariant;
  showText?: boolean;
  className?: string;
}

export const ProgressBar = (props: Props) => {
  const progress = Math.min(
    100,
    Tools.round((props.value / props.max) * 100, 1)
  );
  const progressColorClass = " progress-" + (props.color || "accent");
  return (
    <div
      className={
        "progress-bar" + (props.className ? " " + props.className : "")
      }
    >
      <div
        className={"progress" + progressColorClass}
        style={{ width: progress + "%" }}
      >
        {props.showText ? progress + "%" : ""}
      </div>
    </div>
  );
};
