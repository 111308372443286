import { ChangePassword, UsersApi } from "imagine-users";
import { useState } from "react";

export const ChangePasswordContainer = () => {
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const save = async (currentPassword: string, newPassword: string) => {
    setLoading(true);
    const result = await UsersApi.updatePassword(currentPassword, newPassword);
    if (result.successMessage) {
      setSuccessMessage(result.successMessage);
    }
    if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    }
    setLoading(false);
  };

  return (
    <ChangePassword
      onSave={save}
      errorMessage={errorMessage}
      successMessage={successMessage}
      loading={loading}
    />
  );
};
