import { Api, AppInfo } from "imagine-essentials";
import { useEffect, useMemo, useState } from "react";
import { VersionNotificationPage } from "../../components";
import { WebsiteSelector, useAppSelector } from "../../store";
import { useTranslation } from "react-i18next";
import { KeyPageUtils } from "imagine-pages";
import { Language } from "imagine-i18n";

export const VersionNotificationPageContainer = () => {
  const [t, i18n] = useTranslation();
  const [visible, setVisible] = useState(false);
  const [userVersion, setUserVersion] = useState<string>("");
  const keyPages = useAppSelector(WebsiteSelector.getKeyPages);

  const plantProfileUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        33,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  const referenceImageUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        21,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  useEffect(() => {
    // Store the version the user has used. This makes it possible to notify the user when a new version is released.
    const lastVersion = localStorage.getItem("version");
    const currentVersion = AppInfo.getAppVersion();

    if (lastVersion !== currentVersion) {
      localStorage.setItem("version", currentVersion);
    }
    if (lastVersion !== null) {
      setUserVersion(lastVersion);
      // Update this when a new version should cause the notification to be showed
      if (AppInfo.isVersionOlder(lastVersion, "2.5.0")) {
        setVisible(true);
      }
    }
  }, []);

  return (
    <>
      {visible && (
        <VersionNotificationPage
          lastVersion={userVersion}
          onClose={() => setVisible(false)}
          plantProfileUrl={plantProfileUrl}
          referenceImageUrl={referenceImageUrl}
        />
      )}
    </>
  );
};
