import { useMemo } from "react";
import { TextAlign } from "draw";
import { Icon } from "imagine-ui";

interface Props {
  align?: TextAlign;
}

export const TextAlignIcon = (props: Props) => {
  const iconName = useMemo(() => {
    switch (props.align) {
      case TextAlign.LEFT:
        return "align-left";
      case TextAlign.RIGHT:
        return "align-right";
      case TextAlign.CENTER:
        return "align-center";
      default:
        return "none";
    }
  }, [props.align]);

  return <Icon name={iconName}></Icon>;
};
