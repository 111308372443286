import { useEffect, useMemo, useState } from "react";
import { MapLocationModal } from "../../components";
import { MapMode, Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
  CanvasActions,
  CanvasSelector,
} from "../../store";
import { PlantCollection, PlantCollectionOverviewPage } from "plants";
import { MapLocation } from "../../types";
import { MapPosition } from "draw";
import { useTranslation } from "react-i18next";
import { Loader } from "@googlemaps/js-api-loader";
import { UserActions } from "imagine-users";
import { FeatureKey } from "project";
import { MapOperations } from "../../utils";

/**
 * Responsible for connecting the state and API calls to the map location.
 */
export const MapLocationModalContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const mapAddress = useAppSelector(CanvasSelector.getMapAddress);
  const mapZoomIndex = useAppSelector(CanvasSelector.getMapZoomIndex);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const [locations, setLocations] = useState<MapLocation[]>([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

  const selectLocation = async (location: MapLocation) => {
    dispatch(CanvasActions.setMapAddress(location.address));
    dispatch(CanvasActions.setMapCenter(location.mapPosition));
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));

    const mapPPM = MapOperations.getMapPPM(
      mapZoomIndex,
      location.mapPosition.lat
    );

    dispatch(CanvasActions.setZoom(mapPPM));
    dispatch(CanvasActions.setMapMode(MapMode.VISIBLE));

    dispatch(UserActions.track(FeatureKey.SATELLITE_MAP));

    const maxZoom = await MapOperations.getMaxZoom(location.mapPosition);
    if (maxZoom !== undefined) dispatch(CanvasActions.setMapMaxZoom(maxZoom));

    if (location.address !== mapAddress) {
      dispatch(
        CanvasActions.linkMap({
          center: location.mapPosition,
          ppm: mapPPM,
          force: true,
        })
      );
    }
  };

  const warningMessage = useMemo(() => {
    return mapAddress !== "" ? t("mapAddressWillBeOverriden") : "";
  }, [mapAddress, t]);

  /**
   * Use Google Map Geocoder to find a location based on a free text address.
   */
  const getMapPosition = async (address: string) => {
    setLoading(true);
    const loader = new Loader({
      // apiKey: "AIzaSyA3LsOVkitoEswjuBNDKj8Us-7TwZP4f9I",
      apiKey: "AIzaSyB6437lw96uwoQc8KbiZzWepMt8p95GJ8c",
      version: "weekly",
    });

    await loader.load();
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ address: address }, function (results, status) {
      if (status === "OK" && results !== null) {
        setLocations(
          results.map((result: google.maps.GeocoderResult) => {
            return {
              address: result.formatted_address,
              mapPosition: {
                lat: result.geometry.location.lat(),
                lng: result.geometry.location.lng(),
              } as MapPosition,
            } as MapLocation;
          })
        );
        setMessage("");
        setLoading(false);
      } else {
        setLocations([]);
        setMessage(t("noLocationsFound"));
        setLoading(false);
      }
    });
  };

  // useEffect(() => {

  // }, []);

  return (
    <MapLocationModal
      visible={displayedPage === Pages.MAP_LOCATION}
      locations={locations}
      onFindClick={getMapPosition}
      onSelectLocation={selectLocation}
      onClose={close}
      warningMessage={warningMessage}
      message={message}
      loading={loading}
    />
  );
};
