import { RootState } from "..";

export const WebsiteSelector = {
  getKeyPages: (state: RootState) => {
    return state.website.keyPages;
  },
  getKeyPagesStatus: (state: RootState) => {
    return state.website.keyPagesStatus;
  },
};
