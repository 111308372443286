import { useMemo } from "react";

interface Props {
  title?: string;
  children?: React.ReactNode;
  className?: string;
  onClick?: () => void;
  size?: "sm" | "md" | "lg";
  active?: boolean;
}

/**
 * Displays a card.
 * @param props title (optional), children (optional), className (optional)
 * @returns
 */
export const Card = (props: Props) => {
  const className = useMemo(() => {
    let classes = "card";
    if (props.size) {
      classes += ` card-${props.size}`;
    }
    if (props.onClick) {
      classes += " card-clickable";
    }
    if (props.active) {
      classes += " active";
    }
    if (props.className) {
      classes += ` ${props.className}`;
    }

    return classes;
  }, [props.size, props.onClick, props.className, props.active]);

  return (
    <div className={className} onClick={props.onClick}>
      {props.title && (
        <div className="title">
          <h4>{props.title}</h4>
        </div>
      )}
      {props.children}
    </div>
  );
};
