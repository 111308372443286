import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PlantCollection, PlantCount } from "plants";
import { fetchPlantCollections } from "./plantThunks";
import { RequestStatus } from "imagine-essentials";
import { PlantSchema } from "draw";

interface PlantManagementState {
  focusPlantTemplate: PlantSchema | null;
  copyPlant: boolean;
  searchResult: PlantSchema[];
  plantCollections: PlantCollection[];
  plantCollectionsStatus: RequestStatus;
  openPlantCollectionId: number | null;
  plantCollectionsTab: string;
  plantCollectionView: string;
  plantPurchaseList: PlantCount[]; // For purchase
  selectedPlantNurseryIds: number[];
}

const initialState: PlantManagementState = {
  focusPlantTemplate: null,
  copyPlant: false,
  searchResult: [],
  plantCollections: [],
  plantCollectionsStatus: RequestStatus.IDLE,
  openPlantCollectionId: null,
  plantCollectionsTab: "user",
  plantCollectionView: "cards",
  plantPurchaseList: [],
  selectedPlantNurseryIds: [],
};

export const plantManagementSlice = createSlice({
  name: "plant-management",
  initialState,
  reducers: {
    setFocusTemplate: (state, action: PayloadAction<PlantSchema | null>) => {
      state.focusPlantTemplate = action.payload;
    },
    setCopyPlant: (state, action: PayloadAction<boolean>) => {
      state.copyPlant = action.payload;
    },
    setSearchResult: (state, action: PayloadAction<PlantSchema[]>) => {
      state.searchResult = action.payload;
    },
    openPlantCollection: (state, action: PayloadAction<number>) => {
      state.openPlantCollectionId = action.payload;
    },
    clearOpenPlantCollection: (state) => {
      state.openPlantCollectionId = null;
    },
    finishPlantCollections: (state) => {
      state.plantCollectionsStatus = RequestStatus.READY;
    },
    clearPlantCollections: (state) => {
      state.plantCollections = [];
      state.plantCollectionsStatus = RequestStatus.IDLE;
    },
    setPlantCollectionsTab: (state, action: PayloadAction<string>) => {
      state.plantCollectionsTab = action.payload;
    },
    setPlantCollectionView: (state, action: PayloadAction<string>) => {
      state.plantCollectionView = action.payload;
    },
    updatePlant: (state, action: PayloadAction<PlantSchema>) => {
      if (state.focusPlantTemplate) {
        if (state.focusPlantTemplate.id === action.payload.id) {
          state.focusPlantTemplate = action.payload;
        }
      }
      if (state.searchResult.length > 0) {
        const updatedResult = state.searchResult.map((plant) => {
          if (plant.id === action.payload.id) {
            return action.payload;
          }
          return plant;
        });
        state.searchResult = updatedResult;
      }
      if (state.plantCollections.length > 0) {
        const updatedCollections = state.plantCollections.map((collection) => {
          if (collection.plants) {
            const updatedPlants = collection.plants.map((plant) => {
              if (plant.id === action.payload.id) {
                return action.payload;
              }
              return plant;
            });
            return {
              ...collection,
              plants: updatedPlants,
            };
          }
          return collection;
        });
        state.plantCollections = updatedCollections;
      }
    },
    setPlantPurchaseList: (state, action: PayloadAction<PlantCount[]>) => {
      state.plantPurchaseList = action.payload;
    },
    setSelectedPlantNurseryIds: (state, action: PayloadAction<number[]>) => {
      state.selectedPlantNurseryIds = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchPlantCollections.fulfilled,
      (state, action: PayloadAction<PlantCollection[]>) => {
        state.plantCollections = action.payload;
        state.plantCollectionsStatus = RequestStatus.READY;
      }
    );
  },
});

export const PlantManagementActions = plantManagementSlice.actions;

export default plantManagementSlice.reducer;
