import { useMemo } from "react";
import {
  useAppSelector,
  CanvasSelector,
  PlanEditorSelector,
} from "../../store";
import { StaticArea } from "../../components";
import { CursorMode } from "../../enums";
import { ShapeType } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for drawing the static area
 */
export const StaticAreaContainer = () => {
  const area = useAppSelector(PlanEditorSelector.getArea);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const selectedShapeTool = useAppSelector(CanvasSelector.getSelectedShapeTool);

  const user = useAppSelector(UserSelector.getUserNotNull);
  const preferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const cursorOverride = useMemo(() => {
    if (cursorMode === CursorMode.DRAG) return "grab";
    if (selectedShapeTool !== ShapeType.NONE) return "crosshair";
    return undefined;
  }, [cursorMode, selectedShapeTool]);

  return (
    <>
      {area !== undefined && !isEditingArea && (
        <StaticArea
          area={area}
          zoom={zoom}
          zeroReference={zeroReference}
          cursor={cursorOverride}
          unitScale={preferences.unitScale}
          delimiter={delimiter}
        />
      )}
    </>
  );
};
