import { Icon } from "imagine-ui";

interface Props {
  icon: string;
  text: string;
  onClick: () => void;
  elementId?: string;
}

export const UserDropdownItem = (props: Props) => {
  return (
    <button
      className="user-dropdown-item"
      onClick={props.onClick}
      id={props.elementId}
    >
      <Icon name={props.icon} className="icon" />
      <span className="text">{props.text}</span>
    </button>
  );
};
