export enum ObjectCategory {
  ACCESSORIES = 0, // Garbage bin, compost, drying reck, firepit
  HOUSES = 1, // House
  SMALL_BUILDINGS = 2, // Arbor, pergola, raised bed, carport, shed
  FURNITURE = 3, // Table, bench,  hammock, BBQ
  GARDEN_PLAY = 4, // Trampoline, swing, sandbox, playtower
  POOLS = 5,
  RAISED_BEDS = 6, // planters,
  _SIZE = 7, // To easily get the size
}
