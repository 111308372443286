import { Trans, useTranslation } from "react-i18next";
import {
  CanvasSelector,
  LayoutSelector,
  PlanEditorSelector,
  useAppSelector,
} from "../../store";
import { Accordion, AccordionItem, Device, Link } from "imagine-ui";
import { CursorMode } from "../../enums";
import { ShapeType } from "draw";

export const AlertContainer = () => {
  const { t } = useTranslation();
  const activeTab = useAppSelector(LayoutSelector.getActiveTab);
  const isTouchDevice = Device.isTouchDevice();
  const mapFrozen = useAppSelector(CanvasSelector.isMapFrozen);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const isPreviewMode = useAppSelector(PlanEditorSelector.getPreviewPlan);

  const selectedShape = useAppSelector(CanvasSelector.getSelectedShapeTool);

  return (
    <div className="px text-sm">
      {activeTab === "draw-tab" && mapFrozen && (
        <p>{t("drawDisabledDueToMapFrozen")}</p>
      )}
      {activeTab === "draw-tab" &&
        cursorMode === CursorMode.DRAG &&
        !isTouchDevice && <p>{t("drawDisabledDueToDragMode")}</p>}
      {activeTab === "draw-tab" && isPreviewMode && (
        <p>{t("drawDisabledDueToPreviewMode")}</p>
      )}
      {/* {isTouchDevice && (
        <p className="mt">
          <Trans
            i18nKey="someFeaturesAreNotSupportedOnMobileDevices"
            components={{
              link1: (
                <Link
                  href="https://gardensketcher.com/da/mobile-enheder"
                  target="_blank"
                />
              ),
            }}
          />
        </p>
      )} */}

      {selectedShape === ShapeType.LINE && (
        <>
          <p className="text-bold">{t("drawLineShapeTipHeading")}</p>
          <p>{t("drawLineShapeTips")}</p>
        </>
      )}
    </div>
  );
};
