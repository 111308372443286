import { useTranslation } from "react-i18next";

import { Icon } from "imagine-ui";
import { CheckoutStep } from "..";

type Props = {
  step: CheckoutStep;
  onClick?: (step: CheckoutStep) => void;
  className?: string;
};

/**
 *
 */
export const CheckoutStepBar = (props: Props) => {
  const { t } = useTranslation();

  const getClassNames = (step: CheckoutStep) => {
    let className = "step";
    // Can only click on previous step unless current step is confirmation - then all is locked
    if (props.step > step && props.step !== CheckoutStep.CONFIRMATION)
      className += " visited";
    if (props.step === step) className += " active";
    return className;
  };

  /**
   * Go to a new step in the checkout step bar.
   */
  const select = (step: CheckoutStep) => {
    if (
      props.onClick &&
      props.step > step &&
      props.step !== CheckoutStep.CONFIRMATION
    ) {
      props.onClick(step);
    }
  };

  return (
    <div
      id="checkout-steps"
      className={
        "checkout-steps-bar" + (props.className ? " " + props.className : "")
      }
    >
      <div className="line"></div>
      <div className="checkout-steps">
        <div
          id="checkout-step-select-membership"
          className={getClassNames(CheckoutStep.SELECT_MEMBERSHIP)}
          onClick={() => select(CheckoutStep.SELECT_MEMBERSHIP)}
        >
          <div className="icon">
            <Icon name="basket" />
          </div>
          <div className="text">{t("memberships:selectMembership")}</div>
        </div>
        <div
          id="checkout-step-customer-info"
          className={getClassNames(CheckoutStep.CUSTOMER_INFO)}
          onClick={() => select(CheckoutStep.CUSTOMER_INFO)}
        >
          <div className="icon">
            <Icon name="user" />
          </div>
          <div className="text">{t("memberships:customerInfo")}</div>
        </div>
        <div
          id="checkout-step-payment"
          className={getClassNames(CheckoutStep.PAYMENT)}
          onClick={() => select(CheckoutStep.PAYMENT)}
        >
          <div className="icon">
            <Icon name="credit-card" />
          </div>
          <div className="text">{t("memberships:payment")}</div>
        </div>
        <div
          id="checkout-step-confirmation"
          className={getClassNames(CheckoutStep.CONFIRMATION)}
          onClick={() => select(CheckoutStep.CONFIRMATION)}
        >
          <div className="icon">
            <Icon name="receipt" />
          </div>
          <div className="text">{t("memberships:confirmation")}</div>
        </div>
      </div>
    </div>
  );
};
