import {
  CancelReason,
  MembershipActions,
  MembershipInfo,
  MembershipSelector,
  MembershipThunks,
  MembershipType,
  MembershipsApi,
  MembershipsTools,
  Subscription,
  SubscriptionInfo,
  SubscriptionManagerApi,
} from "imagine-memberships";
import { UserSelector } from "imagine-users";
import { useAppDispatch, useAppSelector } from "../../store";
import {
  MembershipProductTemplates,
  Stripe,
  StripeAdditionalDetails,
} from "project";
import { Alert, NotificationType, useNotification } from "imagine-ui";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { UpdatePaymentInformationContainer } from ".";

export const MembershipContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const user = useAppSelector(UserSelector.getUserNotNull);

  const subscriptions = useAppSelector(MembershipSelector.getSubscriptions);
  const paymentMethod = useAppSelector(MembershipSelector.getPaymentMethod);

  const [showPaymentWindow, setShowPaymentWindow] = useState(false);
  const [preparingNewSubscription, setPreparingNewSubscription] =
    useState(false);
  const paymentUpdated = useAppSelector(MembershipSelector.getPaymentUpdated);
  const activeSubscription = useAppSelector(
    MembershipSelector.getActiveSubscription
  );
  const activeInvoice = useAppSelector(MembershipSelector.getActiveInvoice);

  /**
   * A ghost subscription is created when a user is migrated from old data to new data, and has updated payment information to Stripe. The
   * ghost subscription will take over and become the active one when current one expires.
   */
  const ghostSubscription = useMemo(() => {
    if (subscriptions.length === 0) return undefined;
    if (!activeSubscription) return undefined;
    return subscriptions.find(
      (sub) =>
        sub.status === "trialing" &&
        sub.currentPeriodEndDate === activeSubscription.currentPeriodEndDate
    );
  }, [subscriptions]);

  // const upcomingInvoice = useMemo(() => {
  //   return invoices.find((inv) => inv.status === "draft");
  // }, [invoices]);

  const membership = useAppSelector(MembershipSelector.getActiveMembership);
  const membershipDeal = useAppSelector(
    MembershipSelector.getActiveMembershipDeal
  );
  const continuationMembershipDeal = useAppSelector(
    MembershipSelector.getContinuationMembershipDeal
  );
  const bonusDays = useAppSelector(MembershipSelector.getBonusDays);
  const membershipProducts = MembershipsTools.getMembershipProducts(
    MembershipProductTemplates,
    user.country
  );

  const membershipHistory = useAppSelector(
    MembershipSelector.getMembershipHistory
  );

  const cancelSubscription = async (
    reasons: CancelReason[],
    comment: string
  ) => {
    if (activeSubscription?.stripeId) {
      // Cancel Stripe subscription
      const response = await SubscriptionManagerApi.unsubscripe(
        activeSubscription.stripeId,
        reasons,
        comment
      );
      if (response.success) {
        let msg = t("memberships:subscriptionCancelled");
        if (reasons.length > 0 || comment !== "") {
          msg = t("memberships:thanksForCancelFeedback") + " " + msg;
        }
        notification.add(NotificationType.ERROR, msg);
      } else if (response.errorMessage) {
        notification.add(NotificationType.ERROR, response.errorMessage);
      }
    } else if (membership) {
      // Cancel old membership
      const result = await MembershipsApi.cancelSubscription(
        user.id,
        reasons,
        comment
      );
      if (result.errorMessage) {
        notification.add(NotificationType.ERROR, result.errorMessage);
      } else if (result.successMessage) {
        dispatch(MembershipThunks.fetchUserSubscriptions());
        notification.add(NotificationType.INFO, result.successMessage);
      }
    }
  };

  const updatePaymentInformation = async () => {
    if (!activeSubscription) return;
    if (activeSubscription.stripeId) {
      // Update payment information in Stripe for current subscription
    } else {
      // Create a new subscription in Stripe that starts when current subscription ends
      setPreparingNewSubscription(true);

      const response = await SubscriptionManagerApi.updatePayment();
      if (response.success && response.data) {
        dispatch(MembershipActions.setClientSecret(response.data.clientSecret));
        setShowPaymentWindow(true);
      }
      setPreparingNewSubscription(false);
    }
  };

  return (
    <>
      {paymentUpdated && (
        <Alert color="success" className="mb-xl">
          {t("memberships:paymentInformationUpdated")}
        </Alert>
      )}

      {activeSubscription && activeInvoice && (
        <SubscriptionInfo
          invoice={activeInvoice}
          subscriptions={subscriptions}
          activeSubscription={activeSubscription}
          ghostSubscription={ghostSubscription}
          paymentMethod={paymentMethod}
          userRole={user.role}
          onCancelSubscription={cancelSubscription}
          productDetails={StripeAdditionalDetails.productDetails}
          couponDetails={StripeAdditionalDetails.couponDetails}
          onUpdatePaymentInformation={updatePaymentInformation}
          preparingPaymentInformation={preparingNewSubscription}
        >
          {showPaymentWindow && <UpdatePaymentInformationContainer />}
        </SubscriptionInfo>
      )}
      {/* Memberships bought before v. 2.2.0 and not migrated yet */}
      {membership !== null && !activeSubscription && (
        <MembershipInfo
          membership={membership || undefined}
          membershipDeal={membershipDeal || undefined}
          continuationMembershipDeal={continuationMembershipDeal || undefined}
          bonusDays={bonusDays}
          membershipProducts={membershipProducts}
          membershipHistory={membershipHistory}
          userRole={user.role}
          fallbackMembershipProductType={MembershipType.FREE}
          onCancelSubscription={cancelSubscription}
        />
      )}
      {membership === null && activeSubscription === undefined && (
        <>
          <p>{t("memberships:buyMembershipToAccessMoreFeatures")}</p>
        </>
      )}
    </>
  );
};
