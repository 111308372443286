import { Modal } from "imagine-ui";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { PlantImage } from "..";
import { Api } from "imagine-essentials";

type Props = {
  onClosed: () => void;
  onSelected: (image: PlantImage) => void;
  visible: boolean;
  images: PlantImage[];
  loading?: boolean;
};

/**
 * The page to display existing images relevant for a plant (based on latin name)
 */
export const PlantImageGalleryModal = (props: Props) => {
  const { t, i18n } = useTranslation();

  const getHybridText = (plantImage: PlantImage) => {
    if (plantImage.hybridName === null || plantImage.hybridName === "")
      return "";
    return "'" + plantImage.hybridName + "'";
  };

  return (
    <Modal
      title={t("plants:selectExistingImage")}
      visible={props.visible}
      onClose={() => props.onClosed()}
      hideFooter={true}
      elementId="plant-image-selector"
      size="lg"
    >
      {props.images.length === 0 && <p>{t("plants:noMatchingPlantImages")}</p>}
      <div className="grid columns-4 gap" id="plant-image-list">
        {props.images.length > 0 &&
          props.images.map((image: PlantImage, index: number) => (
            <div
              id={"plant-image-" + index}
              key={image.id.toString()}
              className=""
              onClick={() => props.onSelected(image)}
            >
              <img
                src={Api.getHost() + "/plant-images/" + image.image}
                className="fluid rounded clickable"
              />
              <p className="text-sm text-dark-muted">
                {image.latinName}
                <br />
                <span className="text-italic">{getHybridText(image)}</span>
              </p>
            </div>
          ))}
      </div>
    </Modal>
  );
};
