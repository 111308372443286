import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, ColorVariant } from "..";

type Props = {
  title?: string;
  visible: boolean;
  children?: React.ReactNode;
  onConfirm?: () => void;
  confirmText?: string;
  onClose?: () => void;
  closeText?: string;
  hideFooter?: boolean;
  discreteHeader?: boolean; // If set, the header will be more discrete and joined with the content
  confirmDisabled?: boolean;
  hideHeader?: boolean; // No header
  hideCancel?: boolean;
  size?: "sm" | "md" | "lg" | "xl" | "fullscreen"; // - default is medium
  backdropClassName?: string; /// Optional class name added to the backdrop
  loading?: boolean; // True if confirm button should display a loading animation
  elementId?: string;
  className?: string;
  center?: boolean;
  color?: ColorVariant;
  tunnelContent?: boolean; // The content will be rendered directly without the modal
};

/**
 * Displays a modal.
 */
export const Modal = (props: Props) => {
  const { t } = useTranslation();

  const modalClass = useMemo(() => {
    let className = props.visible ? " open" : "";
    if (props.center) className += " center";
    return className;
  }, [props.center, props.visible]);

  const modalDialogClass = useMemo(() => {
    let className = "modal-dialog";
    if (props.size !== undefined && props.size !== "md") {
      className += " modal-" + props.size;
    }
    return className;
  }, [props.size]);

  /**
   * Touches passes through the overlay by default, so this will stop the touch event from propagating.
   * @param e
   */
  const blockTouch = (e: React.TouchEvent) => {
    e.stopPropagation();
  };

  // const dialogClassName = useMemo(() => {
  //   let className = "";
  //   if (props.size === "xl" && Device.isMaxSize(windowWidth, DeviceSize.MD)) {
  //     className += "modal-max";
  //   }
  //   if (props.size === "lg" && Device.isMaxSize(windowWidth, DeviceSize.MD)) {
  //     className += "modal-max";
  //   }
  //   if (
  //     props.size === undefined &&
  //     Device.isMaxSize(windowWidth, DeviceSize.MD)
  //   ) {
  //     className += "modal-max";
  //   }
  //   if (props.size === "sm" && Device.isMaxSize(windowWidth, DeviceSize.SM)) {
  //     className += "modal-max";
  //   }
  //   className += " " + props.className;
  //   return className;
  // }, [props.className, props.size, windowWidth]);

  return (
    <>
      {!props.tunnelContent && (
        <div className={"modal" + modalClass} onTouchStart={blockTouch}>
          <div className={modalDialogClass} id={props.elementId}>
            {!props.discreteHeader && !props.hideHeader && (
              <div className="modal-header">
                <h5
                  id={(props.elementId ? props.elementId + "-" : "") + "title"}
                >
                  {props.title}
                </h5>
                <button
                  className="btn-close"
                  onClick={props.onClose}
                  id={
                    (props.elementId ? props.elementId + "-" : "") +
                    "close-button"
                  }
                />
              </div>
            )}
            <div className="modal-body">
              {props.discreteHeader && !props.hideHeader && (
                <div className="row items-space-between mt-sm mb-xl items-y-center">
                  <div></div>
                  <h5
                    className="mt-none mb-none"
                    id={
                      (props.elementId ? props.elementId + "-" : "") + "title"
                    }
                  >
                    {props.title}
                  </h5>
                  <button
                    className="btn-close"
                    onClick={props.onClose}
                    id={
                      (props.elementId ? props.elementId + "-" : "") +
                      "close-button"
                    }
                  />
                </div>
              )}
              {props.children}
            </div>

            {!props.hideFooter && props.size !== "fullscreen" && (
              <div className="modal-footer row gap-sm items-right">
                {!props.hideCancel && (
                  <Button
                    transparent
                    color="dark"
                    onClick={props.onClose}
                    elementId={
                      (props.elementId ? props.elementId + "-" : "") +
                      "cancel-button"
                    }
                  >
                    {props.closeText || t("imagine:cancel")}
                  </Button>
                )}
                <Button
                  onClick={props.onConfirm}
                  color={props.color || "accent"}
                  disabled={props.confirmDisabled}
                  loading={props.loading}
                  elementId={
                    (props.elementId ? props.elementId + "-" : "") +
                    "confirm-button"
                  }
                >
                  {props.confirmText || t("imagine:ok")}
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
      {props.tunnelContent && (
        <div className="modal-tunnel">
          {props.title && <h4>{props.title}</h4>}
          {props.children}
          <div className="row gap-sm items-right">
            <Button
              onClick={props.onConfirm}
              color={props.color || "accent"}
              disabled={props.confirmDisabled}
              loading={props.loading}
              elementId={
                (props.elementId ? props.elementId + "-" : "") +
                "confirm-button"
              }
            >
              {props.confirmText || t("imagine:ok")}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};
