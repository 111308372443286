import { Delimiter, Fraction, Tools } from "imagine-essentials";
import { UnitScale } from "project";
import { ImperialMeasure } from "..";

const feetsPerMeter = 3.28084;

/**
 * Get fraction in either 1/2, 1/4, 1/8 or  1/16 intervals
 * @param value
 */
const getFraction = (value: number) => {
  const fractionValue = value - Math.floor(value);
  const fraction: Fraction = {
    numerator: 0,
    denominator: 0,
  };
  if (fractionValue > 0) {
    if (fractionValue < 0.125) {
      fraction.numerator = 0;
      fraction.denominator = 0;
    } else if (fractionValue < 0.25 + 0.125) {
      fraction.numerator = 1;
      fraction.denominator = 4;
    } else if (fractionValue < 0.5 + 0.125) {
      fraction.numerator = 1;
      fraction.denominator = 2;
    } else if (fractionValue < 0.75 + 0.125) {
      fraction.numerator = 3;
      fraction.denominator = 4;
    } else {
      fraction.numerator = 1;
      fraction.denominator = 1;
    }
  }
  return fraction;
};

/**
 * Converts a value in meter to feet
 * @param value
 * @returns
 */
const convertToImperial = (
  value: number,
  omitYards?: boolean,
  omitFractions?: boolean
) => {
  let feet = value * feetsPerMeter;
  let yards = 0;
  let inches = 0;
  let fraction = { numerator: 0, denominator: 0 } as Fraction;
  if (feet > 9 && !omitYards) {
    yards = Math.floor(feet / 3);
    feet = feet - yards * 3;
  }
  const feetDecimal = feet - Math.floor(feet);
  if (feetDecimal > 0) {
    inches = feetDecimal * 12;
    feet = Math.floor(feet);
    const inchesDecimal = inches - Math.floor(inches);
    if (inchesDecimal > 0 && !omitFractions) {
      inches = Math.floor(inches);
      fraction = getFraction(inchesDecimal);
      if (fraction.numerator === 1 && fraction.denominator === 1) {
        inches += 1;
        fraction.numerator = 0;
        fraction.denominator = 0;
      }
    } else {
      inches = Math.round(inches);
    }
  }

  return {
    yards: yards,
    feet: feet,
    inches: inches,
    fraction: fraction,
  } as ImperialMeasure;
};

const convertToImperialFeet = (value: number) => {
  return value * feetsPerMeter;
};

const convertFromImperial = (
  yards: number,
  feet: number,
  inches: number,
  fraction?: Fraction
) => {
  const inchesPerMeter = feetsPerMeter * 12;
  const yardsPerMeter = feetsPerMeter / 3;
  let fractionValue = 0;
  if (fraction) {
    fractionValue = fraction.numerator / fraction.denominator;
  }
  return (
    yards / yardsPerMeter +
    feet / feetsPerMeter +
    (inches + fractionValue) / inchesPerMeter
  );
};

const getMetricText = (value: number, delimiter: Delimiter) => {
  if (value < 1) {
    return `${Tools.round(value * 100, 0)} cm`;
  }
  return `${Tools.numberToText(value, 2, delimiter)} m`;
};

const getImperialText = (
  value: number,
  omitYards?: boolean,
  omitFractions?: boolean
) => {
  const imperialMeasure = convertToImperial(value, omitYards, omitFractions);

  let text = "";
  if (imperialMeasure.yards > 0) {
    text += `${imperialMeasure.yards} yd `;
  }
  if (imperialMeasure.feet > 0 || imperialMeasure.yards > 0) {
    text += `${imperialMeasure.feet} ft `;
  }
  if (imperialMeasure.inches > 0) {
    text += `${imperialMeasure.inches}`;
    if (imperialMeasure.fraction) {
      if (imperialMeasure.fraction.numerator > 0) {
        if (
          imperialMeasure.fraction.numerator === 1 &&
          imperialMeasure.fraction.denominator === 2
        ) {
          text += " \u00bc";
        } else if (
          imperialMeasure.fraction.numerator === 1 &&
          imperialMeasure.fraction.denominator === 4
        ) {
          text += " \u00bd";
        } else if (
          imperialMeasure.fraction.numerator === 3 &&
          imperialMeasure.fraction.denominator === 4
        ) {
          text += " \u00be";
        } else {
          text += ` ${imperialMeasure.fraction.numerator}/${imperialMeasure.fraction.denominator}`;
        }
      }
    }
    text += " in";
  }

  return text;
};

const getMetricTextInterval = (
  valueMin: number,
  valueMax: number,
  delimiter: Delimiter
) => {
  const max = Math.max(valueMin, valueMax);
  let unit = "m";
  let factor = 1;
  let decimals = 2;
  if (max < 1) {
    unit = "cm";
    factor = 100;
    decimals = 0;
  }
  if (valueMin === valueMax) {
    return (
      Tools.numberToText(valueMin * factor, decimals, delimiter) + " " + unit
    );
  }
  return (
    Tools.numberToText(valueMin * factor, decimals, delimiter) +
    " - " +
    Tools.numberToText(valueMax * factor, decimals, delimiter) +
    " " +
    unit
  );
};

const getImperialTextInterval = (
  valueMin: number,
  valueMax: number,
  omitYards?: boolean,
  omitFractions?: boolean
) => {
  if (valueMin === valueMax) {
    return getImperialText(valueMin, omitYards, omitFractions);
  }

  return (
    getImperialText(valueMin, omitYards, omitFractions) +
    " - " +
    getImperialText(valueMax, omitYards, omitFractions)
  );
};

/**
 * Get the text representation of a measure
 * @param value The measure in meters
 * @param unit
 * @param delimiter
 * @returns
 */
const getMeasureText = (
  value: number,
  unit: UnitScale,
  delimiter: Delimiter,
  omitYards?: boolean,
  omitFractions?: boolean
) => {
  switch (unit) {
    case UnitScale.METRIC:
      return getMetricText(value, delimiter);
    case UnitScale.IMPERIAL:
      return getImperialText(value, omitYards, omitFractions);
  }
};

const getMeasureTextInterval = (
  valueMin: number,
  valueMax: number,
  unit: UnitScale,
  delimiter: Delimiter,
  omitYards?: boolean,
  omitFractions?: boolean
) => {
  switch (unit) {
    case UnitScale.METRIC:
      return getMetricTextInterval(valueMin, valueMax, delimiter);
    case UnitScale.IMPERIAL:
      return getImperialTextInterval(
        valueMin,
        valueMax,
        omitYards,
        omitFractions
      );
  }
};

export const Measure = {
  convertToImperial,
  convertToImperialFeet,
  convertFromImperial,
  getMeasureText,
  getMeasureTextInterval,
};
