import _ from "lodash";
import {
  BorderType,
  CanvasOperations,
  Curve,
  CurvedLinePathProperties,
  DimensionProperties,
  EllipseProperties,
  Group,
  GroupOperations,
  Item,
  ItemType,
  Layer,
  LinePathProperties,
  Model,
  ObjectSchema,
  PlantModelOperations,
  RectangleProperties,
  ShapeProperties,
  ShapeType,
  ShapeValidation,
  TextProperties,
  PlantSchema,
  PlantModelText,
  PlantCategory,
} from "..";
import { Point, SentryReporter, Size, Tools } from "imagine-essentials";

/**
 * Get shape properties with the wrapping item's position added to the shape
 * position (needed to position shapes correctly, otherwise all are positioned at 0,0)
 */
const getShapePropertiesWithItemOffset = (
  shape: ShapeProperties,
  item: Item
) => {
  if (
    shape.type === ShapeType.RECTANGLE &&
    ShapeValidation.isRectangle(shape.properties)
  ) {
    const properties = shape.properties as RectangleProperties;
    return {
      ...properties,
      position: CanvasOperations.getPointSum(
        item.position,
        properties.position,
        3
      ),
    } as RectangleProperties;
  } else if (
    shape.type === ShapeType.ELLIPSE &&
    ShapeValidation.isEllipse(shape.properties)
  ) {
    const properties = shape.properties as EllipseProperties;
    return {
      ...properties,
      center: CanvasOperations.getPointSum(item.position, properties.center, 3),
    };
  } else if (
    shape.type === ShapeType.LINE &&
    ShapeValidation.isLinePath(shape.properties)
  ) {
    const properties = shape.properties as LinePathProperties;
    const updatedPoints = properties.points.map((point: Point) => {
      return CanvasOperations.getPointSum(point, item.position, 3);
    });
    return {
      ...properties,
      points: updatedPoints,
    };
  } else if (
    shape.type === ShapeType.CURVED_LINE &&
    ShapeValidation.isCurvedLinePath(shape.properties)
  ) {
    const properties = shape.properties as CurvedLinePathProperties;
    const updatedCurves = properties.curves.map((curve: Curve) => {
      return {
        p1: CanvasOperations.getPointSum(curve.p1, item.position, 3),
        h1: CanvasOperations.getPointSum(curve.h1, item.position, 3),
        p2: CanvasOperations.getPointSum(curve.p2, item.position, 3),
        h2: CanvasOperations.getPointSum(curve.h2, item.position, 3),
      };
    });
    return {
      ...properties,
      curves: updatedCurves,
    };
  } else if (
    shape.type === ShapeType.DIMENSION &&
    ShapeValidation.isDimension(shape.properties)
  ) {
    console.error("Dimension shapes should not be allowed in objects");
    const properties = shape.properties as DimensionProperties;
    return {
      start: CanvasOperations.getPointSum(item.position, properties.start, 3),
      end: CanvasOperations.getPointSum(item.position, properties.end, 3),
    };
  } else if (
    shape.type === ShapeType.TEXT &&
    ShapeValidation.isText(shape.properties)
  ) {
    const properties = shape.properties as TextProperties;
    return {
      ...properties,
      position: CanvasOperations.getPointSum(
        item.position,
        properties.position,
        3
      ),
    } as TextProperties;
  } else return shape.properties;
};

const getObjectTemplateShapes = (template: ObjectSchema) => {
  const shapes: ShapeProperties[] = [];
  try {
    template.items.forEach((item: Item) => {
      if (item.shapeProperties !== undefined) {
        const shape = {
          ...item.shapeProperties,
          properties: getShapePropertiesWithItemOffset(
            item.shapeProperties,
            item
          ),
          rotation: item.rotation,
        } as ShapeProperties;
        shapes.push(shape);
      }
    });
    return shapes;
  } catch (e) {
    console.error(template);
    throw new Error("Object template is invalid");
  }
};

const createPlantModel = (
  item: Item,
  zoom: number,
  offset: Point,
  plantTemplates: PlantSchema[],
  month: number,
  showPlantHeight: boolean
) => {
  let model: Model;
  if (item.templateId !== undefined) {
    const templ = PlantModelOperations.getTemplate(
      item.templateId,
      plantTemplates
    );
    const pxSize = CanvasOperations.unitToPixelSize(item.size, zoom);
    model = PlantModelOperations.createModel(
      templ,
      zoom,
      offset,
      month,
      pxSize
    );
    model.id = item.id;
    model.rotation = item.rotation;

    if (item.locked) model.locked = true;
    // To compensate for plant center position
    // const plantOffset = {
    //   x: offset.x - model.size.width / 2,
    //   y: offset.y - model.size.height / 2,
    // };

    const plantOffset = {
      x: offset.x,
      y: offset.y,
    };

    model.position = CanvasOperations.unitToPixelPosition(
      item.position,
      zoom,
      plantOffset
    );
    // Show plant as half size in the first month with leaves
    if (month !== 0) {
      if (
        templ.category !== PlantCategory.TREE &&
        templ.category !== PlantCategory.SHRUB &&
        templ.category !== PlantCategory.CLIMBER &&
        templ.category !== PlantCategory.ROSES
      ) {
        if (
          Tools.getMin(templ.leafSeason) === month &&
          templ.leafSeason.length < 12
        ) {
          model.size = {
            width: model.size.width / 2,
            height: model.size.height / 2,
          };
          model.position = {
            x: model.position.x + model.size.width / 2,
            y: model.position.y + model.size.height / 2,
          };
        }
      }
    }

    if (showPlantHeight) {
      // Show full min-max height (value found by zooming in and out and adjusting)
      if (model.size.width >= 65) {
        if (templ.heightMin !== undefined && templ.heightMax !== undefined) {
          model.text = PlantModelText.getHeightTextFull(templ, ",");
        }
      }
      // Show average/short height (value found by zooming in and out and adjusting)
      else if (model.size.width >= 35) {
        model.text = PlantModelText.getHeightAvgTextFull(templ, ",");
      }
    }
  } else {
    console.error(item);
    throw new Error("Plant item missing template");
  }
  return model;
};

const createObjectModel = (
  item: Item,
  zoom: number,
  offset: Point,
  objectTemplates: ObjectSchema[]
) => {
  let model: Model;
  if (item.templateId !== undefined) {
    const template = objectTemplates.find((template: ObjectSchema) => {
      return template.id === item.templateId;
    });
    if (template === undefined) {
      console.error("Object template not passed: " + item.templateId);
      throw new Error("Object template not passed: " + item.templateId);
    }
    model = {
      id: item.id,
      itemType: ItemType.OBJECT,
      size: CanvasOperations.unitToPixelSize(item.size, zoom),
      rotation: item.rotation,
      position: CanvasOperations.unitToPixelPosition(
        item.position,
        zoom,
        offset
      ),
      shapes: getObjectTemplateShapes(template),
      viewBox: "0 0 " + template.width + " " + template.height,
    };
    if (item.locked) model.locked = true;
  } else {
    console.error(item);
    throw new Error("Object item missing template");
  }
  return model;
};

const createShapeModel = (item: Item, zoom: number, offset: Point) => {
  const minDimension = 40;
  let model: Model;
  if (item.shapeProperties !== undefined) {
    model = {
      id: item.id,
      itemType: ItemType.SHAPE,
      size: CanvasOperations.unitToPixelSize(item.size, zoom),
      rotation: item.rotation,
      position: CanvasOperations.unitToPixelPosition(
        item.position,
        zoom,
        offset
      ),
      shapes: [_.cloneDeep(item.shapeProperties)],
      viewBox: item.viewBox,
    };

    if (item.locked) model.locked = true;

    if (model.size.width < 30 && model.size.height < 30) {
      if (model.shapes.length === 1) {
        if (model.shapes[0].type === ShapeType.DIMENSION) {
          // Don't display a dimension smaller than this
          return null;
        }
      }
    }
    if (model.shapes.length === 1) {
      // Don't apply fill for shapes wihout closed path (straight lines)
      if (model.shapes[0].type === ShapeType.LINE) {
        const closePath = (model.shapes[0].properties as LinePathProperties)
          .closePath;
        if (closePath === undefined || closePath === false) {
          if (model.shapes[0].style !== undefined) {
            model.shapes[0].style.opacity = 0;
            // Always show a border in this case, otherwise shape will dissappear
            if (model.shapes[0].style.borderType === BorderType.NONE) {
              model.shapes[0].style.borderType = BorderType.SOLID;
            }
          }
        }
      }
      // Don't apply fill for shapes wihout closed path (curved lines)
      if (model.shapes[0].type === ShapeType.CURVED_LINE) {
        const closePath = (
          model.shapes[0].properties as CurvedLinePathProperties
        ).closePath;
        if (closePath === undefined || closePath === false) {
          if (model.shapes[0].style !== undefined) {
            model.shapes[0].style.opacity = 0;
            // Always show a border in this case, otherwise shape will dissappear
            if (model.shapes[0].style.borderType === BorderType.NONE) {
              model.shapes[0].style.borderType = BorderType.SOLID;
            }
          }
        }
      }

      if (
        model.shapes[0].type === ShapeType.DIMENSION ||
        model.shapes[0].type === ShapeType.LINE ||
        model.shapes[0].type === ShapeType.CURVED_LINE
      ) {
        const size = { ...model.size };
        const vb = model.viewBox.split(" ");
        if (vb.length < 4) {
          console.error("Viewbox error");
          return model;
        }

        // If this is an actual shape rather than a line, it should not compensate for perfect vertical or horizontal lines
        // (causes the shape to move when zooming in)
        if (model.shapes[0].type === ShapeType.LINE) {
          const lineProperties = model.shapes[0]
            .properties as LinePathProperties;
          if (lineProperties.points.length > 2) return model;
        }
        if (model.shapes[0].type === ShapeType.CURVED_LINE) {
          const lineProperties = model.shapes[0]
            .properties as CurvedLinePathProperties;
          if (lineProperties.curves.length > 1) return model;
        }
        // Make sure shape width or height doesn't get too narrow for perfect vertical or horizontal lines (lines and dimensions)
        if (size.width < minDimension && size.width < size.height) {
          const diff = minDimension - size.width;
          model.size.width = minDimension;
          model.position.x = Tools.round(model.position.x - diff / 2, 1);
          // Also adjust viewbox since this is used to calcualte points per pixel
          const adjustedViewboxWidth = Tools.round(
            (Number(vb[3]) / size.height) * minDimension,
            2
          );
          const widthDiff = adjustedViewboxWidth - Number(vb[2]);
          const adjustedXPosition = Tools.round(
            Number(vb[0]) - widthDiff / 2,
            2
          ); //Util.round(-adjustedViewboxWidth / 2, 2);
          model.viewBox =
            adjustedXPosition +
            " " +
            vb[1] +
            " " +
            adjustedViewboxWidth +
            " " +
            vb[3];
        } else if (size.height < minDimension && size.height < size.width) {
          const diff = minDimension - size.height;
          model.size.height = minDimension;
          model.position.y = Tools.round(model.position.y - diff / 2, 1);
          // Also adjust viewbox since this is used to calcualte points per pixel
          const adjustedViewboxHeight = Tools.round(
            (Number(vb[2]) / size.width) * minDimension,
            2
          );
          const heightDiff = adjustedViewboxHeight - Number(vb[3]);
          const adjustedYPosition = Tools.round(
            Number(vb[1]) - heightDiff / 2,
            2
          ); //Util.round(-adjustedViewboxHeight / 2, 2);
          model.viewBox =
            vb[0] +
            " " +
            adjustedYPosition +
            " " +
            vb[2] +
            " " +
            adjustedViewboxHeight;
        }
      }
    }
  } else {
    console.error(item);
    throw new Error("Shape item missing shape properties");
  }
  return model;
};

/**
 * Generates a model based on an item. If the function returns zero it indicates that the model should
 * not be displayed (fx a dimension that is too small to display properly).
 */
const getModelFromItem = (
  item: Item,
  zoom: number,
  offset: Point,
  plantTemplates: PlantSchema[],
  objectTemplates: ObjectSchema[],
  month: number,
  showPlantHeight: boolean
) => {
  switch (item.type) {
    case ItemType.PLANT:
      return createPlantModel(
        item,
        zoom,
        offset,
        plantTemplates,
        month,
        showPlantHeight
      );
    case ItemType.OBJECT:
      return createObjectModel(item, zoom, offset, objectTemplates);
    case ItemType.SHAPE:
      return createShapeModel(item, zoom, offset);
    default:
      console.error("Unknown item type:", item.type);
      throw new Error("Missing model creator for item type " + item.type);
  }
};

const getUpdatedItemFromModel = (
  item: Item,
  model: Model,
  zoom: number,
  offset: Point
) => {
  // TODO: Round position and size to 2 decimals
  const newItem = {
    ...item,
    position: CanvasOperations.roundPoint(
      CanvasOperations.pixelToUnitPosition(model.position, zoom, offset),
      3
    ),
    size: CanvasOperations.roundSize(
      CanvasOperations.pixelToUnitSize(model.size, zoom, 3),
      3
    ),
  } as Item;
  // Text shape is positioned in the center, to make up for this
  if (item.type === ItemType.SHAPE) {
    if (item.shapeProperties?.type === ShapeType.TEXT) {
      newItem.position = {
        x: Tools.round(newItem.position.x + newItem.size.width / 2, 3),
        y: Tools.round(newItem.position.y + newItem.size.height / 2, 3),
      };
    }
  }
  return newItem;
};

const getRenderModels = (
  layers: Layer[],
  groups: Group[],
  newItems: Item[],
  zoom: number,
  offset: Point,
  plantTemplates: PlantSchema[],
  objectTemplates: ObjectSchema[],
  month: number,
  showPlantHeight: boolean,
  staticRender: boolean
) => {
  try {
    const newModels: Model[] = [];
    if (layers.length === 0) {
      console.warn(
        "List of layers are empty. This is ok initially, but no models can be generated."
      );
      return [];
    }
    // The layers should be drawn in the order given by the array, first index on the back
    layers.forEach((layer: Layer) => {
      // Only make models of items in visible layers
      if (layer.visible) {
        const layerItems = newItems.filter((item: Item) => {
          return item.layerId === layer.id;
        });
        layerItems.forEach((item: Item) => {
          // Check if the item's group is currently visible
          if (GroupOperations.isGroupVisible(item.groupId, groups)) {
            const newModel = getModelFromItem(
              item,
              zoom,
              offset,
              plantTemplates,
              objectTemplates,
              month,
              showPlantHeight
            );
            if (newModel !== null) {
              newModels.push(newModel);
            }
          }
        });
      }
    });
    // Add items with no layer (used in objects)
    newItems.forEach((item: Item) => {
      if (item.layerId === undefined) {
        // Don't include the currently selected item(s) - they are rendered from elsewhere

        const newModel = getModelFromItem(
          item,
          zoom,
          offset,
          plantTemplates,
          objectTemplates,
          month,
          showPlantHeight
        );
        if (newModel !== null) {
          newModels.push(newModel);
        }
      }
    });
    return newModels;
  } catch (error) {
    console.error("Severe error when generating item models:", error);
    SentryReporter.captureException(
      error,
      { Action: "Generating render models" },
      "fatal"
    );
    // throw new Error("Severe error when generating item models");
    return [] as Model[];
  }
};

const getAreaModel = (
  unitSize: Size,
  position: Point,
  offset: Point,
  zoom: number,
  shapeProperties: ShapeProperties,
  rotation: number
) => {
  const viewBox = "0 0 " + unitSize.width + " " + unitSize.height;

  const model: Model = {
    id: -100,
    itemType: ItemType.SHAPE,
    size: CanvasOperations.unitToPixelSize(unitSize, zoom),
    rotation: rotation,
    position: CanvasOperations.unitToPixelPosition(position, zoom, offset),
    shapes: [shapeProperties],
    viewBox: viewBox,
  };
  return model;
};

/**
 * Finds a model in a list of models. Throws exception if it does not exist.
 */
const findModel = (models: Model[], id: number) => {
  const model = models.find((m: Model) => {
    return m.id === id;
  });
  if (model === undefined) {
    console.error("Existing models:", models);
    throw new Error("Model ID does not exist: " + id);
  }
  return model;
};

const getDefaultModel = (type: ItemType) => {
  return {
    id: 0,
    itemType: type,
    size: { width: 0, height: 0 },
    rotation: 0,
    position: { x: 0, y: 0 },
    shapes: [],
    viewBox: "0 0 0 0",
  } as Model;
};

export const ModelOperations = {
  getObjectTemplateShapes,
  createPlantModel,
  createObjectModel,
  createShapeModel,
  getModelFromItem,
  getUpdatedItemFromModel,
  getRenderModels,
  getAreaModel,
  findModel,
  getDefaultModel,
};
