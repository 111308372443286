import { PlantSchema } from "draw";
import { PlantCollection, PlantConverter } from "..";
import { Api, SentryReporter } from "imagine-essentials";

const getPlantCollections = async (planId = 0) => {
  const result = await Api.get("api/plant-collections/user/" + planId);
  if (result.success) {
    try {
      const collections = result.data.map((data: PlantCollection) => {
        let plants: PlantSchema[] = [];
        if (data.plants !== undefined) {
          plants = PlantConverter.fromDbFormats(data.plants);
        }
        return { ...data, plants: plants } as PlantCollection;
      });
      return collections as PlantCollection[];
    } catch (e) {
      SentryReporter.captureException(e, {
        Action: "Parsing plant collections",
      });
      return [];
    }
  } else {
    if (result.status === 403) {
      console.warn("User does not have permission to load plant collections");
    } else {
      SentryReporter.captureException(
        "Unexpected error when getting plant collections for user",
        { Result: JSON.stringify(result) }
      );
    }
    return [];
  }
};

/**
 * Get plant collection with full data.
 * @param id Id of the plant collection
 * @returns Plant collection or undefined
 */
const getPlantCollection = async (id: number) => {
  const result = await Api.get("api/plant-collections/" + id);
  if (result.success) {
    try {
      const plants = PlantConverter.fromDbFormats(result.data.plants);
      const collection = result.data;
      return { ...collection, plants: plants } as PlantCollection;
    } catch (e) {
      console.error("Failed to parse plant collection");
      console.error(e);
      SentryReporter.captureException(e, {
        Action: "Parse plant collection",
        Collection: JSON.stringify(result.data),
      });
    }
  }
};

/**
 * Create a new plant collection.
 * @param plantCollection The plant collection info.
 * @returns The created plant collection with the additional properties userId and ID speficied
 */
const createPlantCollection = async (plantCollection: PlantCollection) => {
  const data = {
    name: plantCollection.name,
    description: plantCollection.description,
    type: plantCollection.type,
    planId: plantCollection.planId,
  };
  const result = await Api.post("api/plant-collections/add", data);
  if (result.success) {
    try {
      const coll = result.data;
      // Plants are not returned so the collection object is not completely overridden
      return {
        ...plantCollection,
        name: coll.name,
        description: coll.description,
        id: coll.id,
        userId: coll.userId,
        planId: coll.planId,
        type: coll.type,
      } as PlantCollection;
    } catch (e) {
      SentryReporter.captureException(
        "Failed to parse newly created plant collection",
        { Collection: JSON.stringify(result.data) }
      );
      console.error("Failed to parse newly created plant collection");
      console.error(e);
    }
  } else {
    SentryReporter.captureException("Failed to create plant collection", {
      Result: JSON.stringify(result),
    });
  }
};

/**
 * Create a new plant collection.
 * @param plantCollection The plant collection info.
 * @returns The created plant collection with the additional properties userId and ID speficied
 */
const updatePlantCollection = async (plantCollection: PlantCollection) => {
  const data = {
    name: plantCollection.name,
    description: plantCollection.description,
    type: plantCollection.type,
    planId: plantCollection.planId,
  };
  const result = await Api.post(
    "api/plant-collections/update/" + plantCollection.id,
    data
  );
  if (result.success) {
    try {
      const coll = result.data;
      // Plants are not returned so the collection object is not completely overridden
      return {
        ...plantCollection,
        name: coll.name,
        description: coll.description,
        id: coll.id,
        userId: coll.userId,
        planId: coll.planId,
        type: coll.type,
      } as PlantCollection;
    } catch (e) {
      SentryReporter.captureException(
        "Failed to parse updated plant collection",
        { Collection: JSON.stringify(result.data) }
      );
      console.error("Failed to parse updated plant collection");
      console.error(e);
    }
  } else {
    SentryReporter.captureException("Failed to update plant collection", {
      Result: JSON.stringify(result),
    });
  }
};

const deletePlantCollection = async (id: number) => {
  const result = await Api.post("api/plant-collections/delete/" + id, []);
  if (!result.success) {
    SentryReporter.captureException("Failed to delete plant collection", {
      Result: JSON.stringify(result),
    });
  }
  return result.success;
};

/**
 * Add a plant to a collection. If it fails Sentry is noticed.
 * @param plantId
 * @param collectionId
 * @returns True of success and false otherwise.
 */
const addPlant = async (plantId: number, collectionId: number) => {
  const data = {
    plantId: plantId,
    collectionId: collectionId,
  };
  const result = await Api.post("api/plant-collections/add-plant", data);
  if (!result.success) {
    console.error(result);
    SentryReporter.captureException("Failed to add plant to collection");
  }
  return result.success;
};

/**
 * Remove a plant from a collection. If it fails Sentry is noticed.
 * @param plantId
 * @param collectionId
 * @returns True if success and false otherwise.
 */
const removePlant = async (plantId: number, collectionId: number) => {
  const data = {
    plantId: plantId,
    collectionId: collectionId,
  };
  const result = await Api.post("api/plant-collections/remove-plant", data);
  if (!result.success) {
    console.error(result);
    SentryReporter.captureException("Failed to remove plant from collection");
  }
  return result.success;
};

export const PlantCollectionsApi = {
  getPlantCollections,
  getPlantCollection,
  createPlantCollection,
  updatePlantCollection,
  deletePlantCollection,
  addPlant,
  removePlant,
};
