import { Checkbox, Modal } from "imagine-ui";
import { PlantCard, PlantCount } from "..";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { UnitScale } from "project";
import { Delimiter } from "imagine-essentials";

interface Props {
  plants: PlantCount[];
  onClose: () => void;
  visible: boolean;
  unitScale: UnitScale;
  delimiter: Delimiter;
}

export const PlantListPage = (props: Props) => {
  const { t } = useTranslation();

  const [showCount, setShowCount] = useState(true);
  const [showHeight, setShowHeight] = useState(false);
  const [showProperties, setShowProperties] = useState(false);
  const [showColors, setShowColors] = useState(false);

  const print = () => {
    window.print();
  };

  return (
    <Modal
      title={t("plants:plantList")}
      visible={props.visible}
      onClose={props.onClose}
      confirmText={t("imagine:print")}
      onConfirm={print}
      size="lg"
    >
      <div>
        <div className="grid gap columns-4 columns-2--md columns-1--sm mb-xl no-print">
          <Checkbox
            label={t("plants:showCount")}
            onChange={setShowCount}
            checked={showCount}
          />
          <Checkbox
            label={t("plants:showHeight")}
            onChange={setShowHeight}
            checked={showHeight}
          />
          <Checkbox
            label={t("plants:showProperties")}
            onChange={setShowProperties}
            checked={showProperties}
          />
          <Checkbox
            label={t("plants:showColors")}
            onChange={setShowColors}
            checked={showColors}
          />
        </div>
        <div className="mb-xl print-only">
          {t("plants:plantlistGeneratedByGardenSketcher")}
        </div>

        <div className="grid gap columns-2 columns-1--md">
          {props.plants.map((plant) => (
            <PlantCard
              key={plant.plant.id}
              plant={plant.plant}
              amount={showCount ? plant.count : undefined}
              showHeight={showHeight}
              showProperties={showProperties}
              showSeason={showColors}
              boxed
              className="no-page-break-inside"
              unitScale={props.unitScale}
              delimiter={props.delimiter}
            />
          ))}
        </div>
      </div>
    </Modal>
  );
};
