import { useMemo } from "react";
import { ColorVariant } from "..";

interface Props {
  children?: React.ReactNode;
  color?: ColorVariant;
  rounded?: boolean;
  className?: string;
}

export const Badge = (props: Props) => {
  const className = useMemo(() => {
    let className = "badge";

    if (props.color) {
      className += " badge-" + props.color;
    } else {
      className += " badge-accent";
    }
    if (props.rounded) className += " badge-rounded";
    if (props.className) className += " " + props.className;
    return className;
  }, [props.className, props.color, props.rounded]);

  return <span className={className}>{props.children}</span>;
};
