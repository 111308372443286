import { useState, useEffect } from "react";
import ReactSelect, { DropdownIndicatorProps, components } from "react-select";
import { useTranslation } from "react-i18next";
import { Icon } from "..";

export type SelectOption = {
  value: string | number;
  label: string;
};

type SelectProps = {
  options: SelectOption[];
  selected?: string | number;
  onSelectedNumber?: (value: number) => void;
  onSelectedString?: (value: string) => void;
  className?: string;
  size?: string; // sm, default, lg
  elementId?: string;
  searchable?: boolean;
  invalid?: boolean; // Mark as invalid if true
};

/**
 * Text input field with support for numbers. The onChanged event is fired
 * when the input has lost focus.
 * @param props
 */
export const Select = (props: SelectProps) => {
  const { t } = useTranslation(["imagine"]);

  const [invalid, setInvalid] = useState(props.invalid || false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSelect = (option: any) => {
    setInvalid(false);
    if (props.onSelectedNumber) {
      props.onSelectedNumber(option.value as number);
    }
    if (props.onSelectedString) {
      props.onSelectedString(option.value as string);
    }
  };

  const getMinHeight = () => {
    if (props.size === "sm") return "29px";
    else if (props.size === "lg") return 30 + 16 + "px";
    else return "40px";
  };

  const getLineHeight = () => {
    if (props.size === "sm") return 21 + "px";
    else if (props.size === "lg") return 30 + "px";
    else return 24 + "px";
  };

  const getStyles = () => {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      control: (provided: any) => ({
        ...provided,
        minHeight: getMinHeight(),
        height: getMinHeight(),
        lineHeight: getLineHeight(),
      }),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      indicatorsContainer: (provided: any) => ({
        ...provided,
        height: getMinHeight(),
      }),
    };
  };

  const getClassName = () => {
    let className = props.className ? props.className : "";
    className += " imagine-select-" + props.size || "md";
    if (invalid) return className + " invalid";
    else return className;
  };

  useEffect(() => {
    if (props.invalid !== undefined) {
      setInvalid(props.invalid);
    }
  }, [props.invalid]);

  const DropdownIndicator = (
    props: DropdownIndicatorProps<SelectOption, true>
  ) => {
    return (
      <components.DropdownIndicator {...props}>
        <Icon name="chevron-down" />
      </components.DropdownIndicator>
    );
  };

  return (
    <ReactSelect
      id={props.elementId}
      options={props.options}
      onChange={handleSelect}
      value={props.options.filter((option) => option.value === props.selected)}
      isSearchable={props.searchable || false}
      placeholder={t("imagine:selectDotDotDot")}
      noOptionsMessage={() => t("imagine:empty")}
      className={"imagine-select " + getClassName()}
      classNamePrefix="react-select"
      theme={(theme) => ({
        ...theme,
        colors: {
          ...theme.colors,
          primary: "#479966",
          primary75: "#6bad84",
          primary50: "#90c1a3",
          primary25: "#b5d6c1",
        },
      })}
      styles={getStyles()}
      components={{ DropdownIndicator }}
    />
  );
};
