import { useMemo, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  PlantManagementThunks,
  LayoutSelector,
  LayoutActions,
  PlanEditorSelector,
} from "../../store";
import {
  PlantCollection,
  PlantCollectionPage,
  PlantCollectionsApi,
  PlantData,
} from "plants";

import { useTranslation } from "react-i18next";
import { UserActions, UserSelector } from "imagine-users";
import { NotificationType, useNotification } from "imagine-ui";
import { Pages } from "../../enums";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { PlantSchema } from "draw";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for connection the state and API calls to the plant collection page.
 */
export const PlantCollectionPageContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const openPlantCollection = useAppSelector(
    PlantManagementSelector.getOpenPlantCollection
  );
  const allPlantCollections = useAppSelector(
    PlantManagementSelector.getPlantCollections
  );
  const user = useAppSelector(UserSelector.getUserNotNull);
  const delimiter = I18nTools.getDelimiter(user.language);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const planId = useAppSelector(PlanEditorSelector.getPlanId);
  const planOwner = useAppSelector(PlanEditorSelector.getPlanUserId);
  const plantCollectionView = useAppSelector(
    PlantManagementSelector.getPlantCollectionView
  );

  const [saving, setSaving] = useState(false);

  const availablePlanId = useMemo(() => {
    if (planOwner === user.id) return planId;
    return 0;
  }, [planId, planOwner, user.id]);

  const plantCollection = useMemo(() => {
    if (openPlantCollection === null)
      return PlantData.getEmptyPlantCollection();
    return openPlantCollection;
  }, [openPlantCollection]);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  /**
   * Save a plant collection and reloads list of plant collections.
   * @param plantCollection
   * @returns
   */
  const save = async (plantCollection: PlantCollection) => {
    setSaving(true);
    let updatedPlantCollection: PlantCollection | undefined;
    if (plantCollection.id === 0) {
      updatedPlantCollection = await PlantCollectionsApi.createPlantCollection(
        plantCollection
      );
    } else {
      updatedPlantCollection = await PlantCollectionsApi.updatePlantCollection(
        plantCollection
      );
    }
    setSaving(false);
    if (updatedPlantCollection === undefined) {
      notification.add(
        NotificationType.ERROR,
        t("plants:failedToSavePlantCollection")
      );
      return;
    }
    dispatch(
      PlantManagementActions.openPlantCollection(updatedPlantCollection.id)
    );
    dispatch(UserActions.track(FeatureKey.CREATE_PLANT_COLLECTION));
    dispatch(PlantManagementThunks.fetchPlantCollections(planId));
  };

  /**
   * Delete plant collection and reload plant collections.
   * @returns
   */
  const deletePlantCollection = async () => {
    if (openPlantCollection === null) {
      return;
    }
    const deleted = await PlantCollectionsApi.deletePlantCollection(
      openPlantCollection.id
    );
    if (deleted) {
      dispatch(PlantManagementActions.clearOpenPlantCollection());
      dispatch(PlantManagementThunks.fetchPlantCollections(planId));
      close();
    } else {
      notification.add(
        NotificationType.ERROR,
        t("plants:failedToDeletePlantCollection")
      );
    }
  };

  const removePlant = async (plantId: number) => {
    if (openPlantCollection === null) return;
    const removed = await PlantCollectionsApi.removePlant(
      plantId,
      openPlantCollection.id
    );
    if (removed) {
      dispatch(PlantManagementThunks.fetchPlantCollections(planId));
    } else {
      notification.add(
        NotificationType.ERROR,
        t("plants:failedToRemovePlantFromCollection")
      );
    }
  };

  const showPlantInfo = (plant: PlantSchema) => {
    dispatch(PlantManagementActions.setFocusTemplate(plant));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_INFO));
  };

  const copyPlant = async (plantId: number, collectionId: number) => {
    const added = await PlantCollectionsApi.addPlant(plantId, collectionId);
    if (added) {
      dispatch(PlantManagementThunks.fetchPlantCollections(planId));
    }
  };

  const updateView = (view: string) => {
    dispatch(PlantManagementActions.setPlantCollectionView(view));
  };

  return (
    <PlantCollectionPage
      visible={displayedPage === Pages.PLANT_COLLECTION}
      plantCollection={plantCollection}
      plantCollections={allPlantCollections}
      onSave={save}
      onDelete={deletePlantCollection}
      onRemovePlant={removePlant}
      onCopyPlant={copyPlant}
      onShowPlantInfo={showPlantInfo}
      onClose={close}
      saving={saving}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
      showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
      planId={availablePlanId}
      onViewChange={updateView}
      view={plantCollectionView}
    />
  );
};
