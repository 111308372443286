import i18next from "i18next";
import { Layer } from "..";
import { SentryReporter } from "imagine-essentials";

const getDefaultLayers = () => {
  return [
    {
      id: 1,
      name: i18next.t("layer") + " 1",
      index: 0,
      visible: true,
    },
  ] as Layer[];
};

/**
 * Get the layer from a list based on an ID. The ID must exist otherwise an exception is thrown.
 * @param layers
 * @param id
 */
const getLayer = (layers: Layer[], id: number) => {
  const layer = layers.find((layer: Layer) => {
    return layer.id === id;
  });
  if (layer !== undefined) return layer;
  else {
    SentryReporter.captureException("Unable to find layer with id", {
      id: id,
      layers: JSON.stringify(layers),
    });
    return undefined;
  }
};

/**
 * Updates the layers with matching ID. The remaining layers in the list are not affected.
 */
const updateLayers = (layers: Layer[], layer: Layer) => {
  return layers.map((l: Layer) => {
    if (l.id === layer.id) return layer;
    else return l;
  });
};

/**
 * Inserts the layer in the list of layers at the given index. All indexes in the list
 * are updated, and the list is returned.
 */
const insertLayer = (layer: Layer, layers: Layer[]) => {
  const newLayers: Layer[] = [];
  const originalIndex = layer.index;
  let index = 0;
  layers.forEach((l: Layer) => {
    if (index === originalIndex) {
      newLayers.push({ ...layer, index: index++ });
    }
    // Make sure we don't get 2 identical layers (if layers was already in the list)
    if (l.id !== layer.id) {
      newLayers.push({ ...l, index: index++ });
    }
  });
  // Inserting at end will no be included in loop
  if (originalIndex >= index) {
    newLayers.push({ ...layer, index: index });
  }
  return newLayers;
};

/**
 * Removes a layer from a list. All indexes in the list are updated and the
 * list is returned.
 */
const deleteLayer = (layerId: number, layers: Layer[]) => {
  const newLayers: Layer[] = [];
  let index = 0;
  layers.forEach((l: Layer) => {
    if (l.id !== layerId) {
      newLayers.push({
        ...l,
        index: index++,
      });
    }
  });
  return newLayers;
};

/**
 * Checks if a layers is currently visible. If id is undefined, it will return false.
 */
const isLayerVisible = (id: number | undefined, layers: Layer[]) => {
  if (id === undefined) return false;
  const layer = getLayer(layers, id);
  if (layer === undefined) return false;
  return layer.visible;
};

const getNextLayerId = (layers: Layer[]) => {
  const layerIds = layers.map((layer: Layer) => layer.id);
  let idAvailable = false;
  let id = 0;
  do {
    id++;
    if (!layerIds.includes(id)) {
      idAvailable = true;
    }
  } while (!idAvailable && id < 100);
  return id;
};

export const LayerOperations = {
  getDefaultLayers,
  insertLayer,
  deleteLayer,
  getLayer,
  updateLayers,
  isLayerVisible,
  getNextLayerId,
};
