import { Country, I18nText, I18nTools } from "..";
import { StringOption } from "imagine-essentials";

const getLanguageOptions = () => {
  const languages = I18nTools.getSupportedLanguages();
  return languages.map((lan: string) => {
    return {
      label: I18nText.getLanguageText(lan),
      value: lan,
    } as StringOption;
  });
};

const getCountryOptions = () => {
  const countries = I18nTools.getSupportedCountries();
  return countries.map((country: string) => {
    return {
      label: I18nText.getCountryText(country),
      value: country,
    } as StringOption;
  });
};

const getCountryIsoOptions = () => {
  const countries = I18nTools.getSupportedCountries();
  return countries.map((country: Country) => {
    return {
      label: I18nText.getCountryText(country),
      value: I18nTools.getCountryIsoCode(country),
    } as StringOption;
  });
};

export const I18nData = {
  getLanguageOptions,
  getCountryOptions,
  getCountryIsoOptions,
};
