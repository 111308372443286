import {
  DisplayProduct,
  StripeInvoice,
  StripeSubscription,
  Subscription,
} from "..";
import { useTranslation } from "react-i18next";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";
import { useMemo } from "react";

type Props = {
  className?: string;
  subscription: Subscription;
  ghostSubscription?: Subscription;
  product: DisplayProduct;
};

/**
 * The page displays a summary of the membership containing title, start date, expiry date (incl. bonus days).
 */
export const SubscriptionSummary = (props: Props) => {
  const { t } = useTranslation();

  const unsubscribed = useMemo(() => {
    if (props.ghostSubscription) {
      return props.ghostSubscription.cancelAtPeriodEnd;
    } else {
      return props.subscription.cancelAtPeriodEnd;
    }
  }, [props.subscription]);

  const currentPeriodExtensions = useMemo(() => {
    let totalDays = 0;
    if (props.subscription?.extensions) {
      props.subscription.extensions.forEach((ext) => {
        totalDays += ext.days;
      });
    }
    return totalDays;
  }, [props.subscription]);

  return (
    <div className={props.className}>
      <>
        <h2 className="mb-xl mt-none">{props.product.title}</h2>
        {/* <p>
          {t("memberships:memberSince")}:&nbsp;
          {DateTimeText.getDbDateTimeText(
            props.subscription.startDate,
            DateTimeFormat.LONG_DATE
          )}
        </p> */}
        <p className="mt mb-sm text-bold">{t("memberships:currentPeriod")}</p>
        <p>
          {t("memberships:startDate")}:&nbsp;
          {DateTimeText.getDbDateTimeText(
            props.subscription.currentPeriodStartDate,
            DateTimeFormat.LONG_DATE
          )}
        </p>
        {!unsubscribed && (
          <>
            <p id="membership-summary-next-payment">
              {t("memberships:nextPayment")}:&nbsp;
              {DateTimeText.getDbDateTimeText(
                props.subscription.currentPeriodEndDate,
                DateTimeFormat.LONG_DATE
              )}
            </p>
          </>
        )}
        {unsubscribed && (
          <>
            <p id="membership-summary-expiry-date">
              {t("memberships:expiryDate")}:&nbsp;
              {DateTimeText.getDbDateTimeText(
                props.subscription.currentPeriodEndDate,
                DateTimeFormat.LONG_DATE
              )}
            </p>
          </>
        )}
        {currentPeriodExtensions > 0 && (
          <p className="pt-sm text-sm text-italic text-dark-muted">
            (
            {t("memberships:subscriptionExtendedWithCountDays", {
              count: currentPeriodExtensions,
            })}
            )
          </p>
        )}
      </>
    </div>
  );
};
