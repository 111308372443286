import { UserRole, UserSelector, UserThunks } from "imagine-users";
import {
  LayoutActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  PlantManagementActions,
  PlantManagementSelector,
  PlantManagementThunks,
  WebsiteActions,
  WebsiteSelector,
  WebsiteThunks,
  useAppDispatch,
  useAppSelector,
} from "./store";
import { useCallback, useEffect, useState } from "react";
import { UserHelpers, UserPreferences } from "project";
import { RequestStatus, UrlParser } from "imagine-essentials";
import { NotificationModal, NotificationProvider } from "imagine-ui";
import {
  KeyboardShortcutContainer,
  LayoutContainer,
  TimedEventContainer,
} from "./containers";
import "./i18n";
import "./style/main.scss";
import {
  CheckoutStep,
  MembershipActions,
  MembershipSelector,
  MembershipThunks,
} from "imagine-memberships";
import { Pages } from "./enums";
import { PlanThunks, WindowLocation } from "./utils";
import { LayerOperations } from "draw";
import i18n from "./i18n";

const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userStatus = useAppSelector(UserSelector.getStatus);
  const membershipStatus = useAppSelector(MembershipSelector.getStatus);
  const stripeMembershipStatus = useAppSelector(
    MembershipSelector.getStripeStatus
  );
  const plantCollectionStatus = useAppSelector(
    PlantManagementSelector.getPlantCollectionsStatus
  );
  const keyPagesStatus = useAppSelector(WebsiteSelector.getKeyPagesStatus);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const preferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const planStatus = useAppSelector(PlanEditorSelector.getPlanRequestStatus);
  const [urlHandled, setUrlHandled] = useState(false);
  const [languageHandled, setLanguageHandled] = useState(false);

  const [reloadUserCount, setReloadUserCount] = useState(4);
  const [sleep, setSleep] = useState(false);

  const unsavedPlanChanges = useAppSelector(
    PlanEditorSelector.hasUnsavedChanges
  );
  const unsavedObjectChanges = useAppSelector(
    ObjectEditorSelector.hasUnsavedChanges
  );
  const isObjectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const userTrackerActions = useAppSelector(UserSelector.getUsedFeatures);

  useEffect(() => {
    console.log("App created");
    console.log(window.location.hostname, process.env.DEV_HOST);
  }, []);

  const warnLeave = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event: any) => {
      if (unsavedPlanChanges || (isObjectMode && unsavedObjectChanges)) {
        event.preventDefault();
        event.returnValue = "There is pending work. Sure you want to leave?";
        if (userTrackerActions.length > 0) {
          dispatch(UserThunks.postUsedFeatures());
        }
      }
    },
    [
      unsavedPlanChanges,
      isObjectMode,
      unsavedObjectChanges,
      userTrackerActions.length,
      dispatch,
    ]
  );

  useEffect(() => {
    window.addEventListener("beforeunload", warnLeave);

    return () => {
      window.removeEventListener("beforeunload", warnLeave);
    };
  }, [warnLeave]);

  useEffect(() => {
    if (userTrackerActions.length > 5) {
      dispatch(UserThunks.postUsedFeatures());
    }
  }, [userTrackerActions, dispatch]);

  /**
   * This will open any initial pages specified in the url
   */
  const handleUrlAfterUser = useCallback(async () => {
    if (UrlParser.urlMatches("sign-up")) {
      if (user.role === UserRole.NONE) {
        dispatch(LayoutActions.setDisplayedPage(Pages.SIGN_UP));
      }
      setUrlHandled(true);
      return;
    }

    if (UrlParser.urlMatches("login")) {
      if (user.role === UserRole.NONE) {
        dispatch(LayoutActions.setDisplayedPage(Pages.LOGIN));
      }
      setUrlHandled(true);
      return;
    }

    if (UrlParser.urlMatches("buy")) {
      if (user.role !== UserRole.NONE) {
        dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
        dispatch(LayoutActions.setUserAccountTab("buy"));
      } else {
        dispatch(LayoutActions.setDisplayedPage(Pages.LOGIN));
      }
      setUrlHandled(true);
      return;
    }

    // if (UrlParser.urlMatches("membership-completed")) {
    //   dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
    //   dispatch(LayoutActions.setUserAccountTab("buy"));
    //   dispatch(MembershipActions.setCheckoutStep(CheckoutStep.CONFIRMATION));
    //   // The user might be redicteed to this page before the purchase is completed, and cause the
    //   // user role to not be updated yet. Keep requesting user details until the role is updated
    //   if (user.role !== UserRole.NONE) {
    //     if (user.role !== UserRole.PREMIUM) {
    //       if (reloadUserCount > 0 && !sleep) {
    //         console.log(
    //           "User role not updated yet, reloading user data",
    //           reloadUserCount
    //         );
    //         // The load sequence should be hanging until the user role is updated or retries are exhausted
    //         console.log("Start timeout", reloadUserCount);
    //         setSleep(true);
    //         setTimeout(() => {
    //           console.log("Reset user", reloadUserCount);
    //           setReloadUserCount(reloadUserCount - 1);
    //           setSleep(false);
    //           dispatch(UserActions.clearUser());
    //           // dispatch(MembershipActions.clearMemberships());
    //         }, 3000);
    //       } else if (reloadUserCount < 1) {
    //         console.log("User role not updated after purchase - give up");
    //         // Give up and tell user that membership is not ready yet
    //         dispatch(
    //           MembershipActions.setOrderStatus(OrderStatus.NOT_COMPLETE)
    //         );
    //         setUrlHandled(true);
    //       }
    //     } else {
    //       console.log("User has premium role");
    //       // User has premium role
    //       const params = UrlParser.getSearchParams();
    //       const paymentIdentifier = UrlParser.getParam(
    //         params,
    //         "paymentIdentifier"
    //       );

    //       dispatch(MembershipActions.setOrderStatus(OrderStatus.COMPLETE));
    //       if (paymentIdentifier) {
    //         const purchasedMembership =
    //           await MembershipsApi.findPurchasedMembership(paymentIdentifier);
    //         console.log("Purchased membership", purchasedMembership);
    //         dispatch(
    //           MembershipActions.setPurchasedMembership(
    //             purchasedMembership || null
    //           )
    //         );
    //         // This will make it fetch the membership data again
    //         dispatch(MembershipActions.setMembershipStatus(RequestStatus.IDLE));
    //       }
    //       setUrlHandled(true);
    //     }
    //   }

    //   return;
    // }

    if (UrlParser.urlMatches("membership-success")) {
      dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
      dispatch(LayoutActions.setUserAccountTab("buy"));
      dispatch(MembershipActions.setCheckoutStep(CheckoutStep.CONFIRMATION));
      setUrlHandled(true);
    } else if (UrlParser.urlMatches("payment-updated")) {
      dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
      dispatch(LayoutActions.setUserAccountTab("membership"));
      dispatch(MembershipActions.setPaymentUpdated(true));
      setUrlHandled(true);
    } else if (UrlParser.urlMatches("membership")) {
      dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
      dispatch(LayoutActions.setUserAccountTab("membership"));
      setUrlHandled(true);
    } else if (UrlParser.urlMatches("plan")) {
      const planId = UrlParser.getParam(UrlParser.getSearchParams(), "id");
      if (planId) {
        console.log("Auto open plan", planId);
        localStorage.setItem("planId", planId);
      }
      WindowLocation.clearUrlParams();
      setUrlHandled(true);
    }

    // if (UrlParser.urlMatches("membership-declined")) {
    //   dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
    //   dispatch(LayoutActions.setUserAccountTab("buy"));
    //   dispatch(MembershipActions.setCheckoutStep(CheckoutStep.CONFIRMATION));
    //   dispatch(MembershipActions.setOrderStatus(OrderStatus.DECLINED));
    //   setUrlHandled(true);
    // }

    setUrlHandled(true);
  }, [dispatch, user]);

  const loadData = useCallback(async () => {
    if (userStatus === RequestStatus.IDLE) {
      console.log("Request user data ");
      await dispatch(UserThunks.fetchLoggedInUser());
    }
    if (userStatus === RequestStatus.READY && !languageHandled) {
      if (user.role !== UserRole.NONE) {
        dispatch(MembershipActions.setCustomerCountry(user.country));

        if (i18n.language !== user.language) {
          console.log("Setting language", user.language);
          await i18n.changeLanguage(user.language);
        }
      }

      setLanguageHandled(true);
    }
    if (!urlHandled && languageHandled) {
      await handleUrlAfterUser();
    }
    // // If user is not logged in or has not saves preferences yet, set default preferences
    // if (
    //   urlHandled &&
    //   user.role !== UserRole.NONE &&
    //   Object.keys(preferences).length === 0
    // ) {
    //   console.log("Setting default preferences", preferences);
    //   dispatch(
    //     UserActions.setPreferences(
    //       UserHelpers.getDefaultPreferences(user.country)
    //     )
    //   );
    // }

    if (planStatus === RequestStatus.IDLE && urlHandled) {
      let planId = localStorage.getItem("planId");
      console.log("Loading last opened plan", planId);
      // if (user.role === UserRole.NONE) {
      //   planId = "2";
      // }
      if (planId) {
        const opened = await PlanThunks.openPlan(dispatch, parseInt(planId));
        if (!opened) {
          localStorage.removeItem("planId");
        }
      } else {
        // Default layers must be set if no plan is loaded and after user has been initialized to use the correct language
        dispatch(
          PlanEditorActions.setInitialLayers(LayerOperations.getDefaultLayers())
        );
        dispatch(PlanEditorActions.setPlanRequestStatus(RequestStatus.READY));
      }
    }

    if (
      stripeMembershipStatus === RequestStatus.IDLE &&
      (planStatus === RequestStatus.READY ||
        planStatus === RequestStatus.FAILED)
    ) {
      console.log(
        "Request membership data",
        stripeMembershipStatus,
        urlHandled
      );
      if (user.role !== UserRole.NONE) {
        dispatch(MembershipActions.finishStripeMembershipLoading());
        await dispatch(MembershipThunks.fetchUserSubscriptions());
      } else {
        dispatch(MembershipActions.finishStripeMembershipLoading());
      }
    }

    // console.log("Membership status", stripeMembershipStatus, membershipStatus);

    if (
      membershipStatus === RequestStatus.IDLE &&
      (stripeMembershipStatus === RequestStatus.READY ||
        stripeMembershipStatus === RequestStatus.FAILED)
    ) {
      console.log("Request old membership data", urlHandled);
      if (user.role !== UserRole.NONE) {
        await dispatch(MembershipThunks.fetchMembershipData());
      } else {
        dispatch(MembershipActions.finishMembershipLoading());
      }
    }

    // Plant collections might already be loaded if a plan was opened
    if (
      plantCollectionStatus === RequestStatus.IDLE &&
      membershipStatus === RequestStatus.READY
    ) {
      console.log("Request plant collections");
      if (hasPremiumAccess) {
        let planId = localStorage.getItem("planId") || "0";
        await dispatch(
          PlantManagementThunks.fetchPlantCollections(parseInt(planId))
        );
      } else {
        dispatch(PlantManagementActions.finishPlantCollections());
      }
    }

    if (
      keyPagesStatus === RequestStatus.IDLE &&
      plantCollectionStatus === RequestStatus.READY
    ) {
      await dispatch(WebsiteThunks.fetchKeyPages());
    }
  }, [
    dispatch,
    handleUrlAfterUser,
    hasPremiumAccess,
    languageHandled,
    membershipStatus,
    planStatus,
    plantCollectionStatus,
    stripeMembershipStatus,
    urlHandled,
    user.language,
    user.role,
    userStatus,
    keyPagesStatus,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      await loadData();
    };

    fetchData();
  }, [loadData]);

  return (
    <>
      {/* <Helmet>
        <html lang={language} />
      </Helmet> */}

      <NotificationProvider>
        <KeyboardShortcutContainer />
        <TimedEventContainer />
        <LayoutContainer />
        <NotificationModal />
      </NotificationProvider>
    </>
  );
};

export default App;
