import { Modal, TextInput } from "imagine-ui";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  text: string;
  onFinish: (text: string) => void;
  onClose: () => void;
}

export const TextShapeModal = (props: Props) => {
  const { t } = useTranslation();
  const [text, setText] = useState(props.text);

  useEffect(() => {
    const element = document.getElementById(
      "text-shape-text-input"
    ) as HTMLTextAreaElement;
    if (element) {
      const length = element.value.length;
      element.selectionStart = length;
      element.selectionEnd = length;
      element.focus();
    }
  }, []);

  return (
    <Modal
      visible
      title={t("text")}
      onConfirm={() => props.onFinish(text)}
      onClose={props.onClose}
    >
      <TextInput
        value={text}
        onChange={setText}
        multiline
        height="md"
        elementId="text-shape-text-input"
      />
    </Modal>
  );
};
