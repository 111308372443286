import React from "react";
import {
  RectangleProperties,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
} from "..";
import { Delimiter, Point } from "imagine-essentials";
import { UnitScale } from "project";

interface Props {
  rectangle: RectangleProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  cursor?: string; // override cursor
  rotation?: number;
  className?: string;
  uniqueId?: string; // Used to link the patterns to the shapes
}

const defaultStyle: ShapeStyle = {
  borderColor: "#000000",
  fillColor: "#CCCCCC",
  opacity: 1,
};

const lineWidthPx = 1;

export const RectangleSvg = (props: Props) => {
  const style = props.style !== undefined ? props.style : defaultStyle;

  return (
    <>
      {props.rectangle.size.width > 0 && props.rectangle.size.height > 0 && (
        <g
          transform={
            "rotate(" +
            (props.rotation || 0) +
            ", " +
            (props.rectangle.position.x + props.rectangle.size.width / 2) +
            ", " +
            (props.rectangle.position.y + props.rectangle.size.height / 2) +
            ")"
          }
        >
          <defs>
            <ShapePattern
              color={style.fillColor}
              startPosition={props.rectangle.position}
              id={props.uniqueId}
              pattern={style.fillPattern}
            />
          </defs>
          <rect
            width={props.rectangle.size.width}
            height={props.rectangle.size.height}
            x={props.rectangle.position.x}
            y={props.rectangle.position.y}
            stroke={ShapeOperations.getStroke(style)}
            strokeOpacity={ShapeOperations.getStrokeOpacity()}
            strokeDasharray={ShapeOperations.getStrokeDashArray(
              style,
              lineWidthPx
            )}
            fill={ShapeOperations.getFill(style, props.uniqueId)}
            fillOpacity={ShapeOperations.getOpacity(style)}
            style={{ cursor: props.cursor || "move" }}
            className={props.className}
            strokeWidth={lineWidthPx}
            vectorEffect="non-scaling-stroke"
          />
        </g>
      )}
    </>
  );
};
