import {
  useAppDispatch,
  useAppSelector,
  LayoutSelector,
  LayoutActions,
  ObjectEditorSelector,
  ObjectEditorActions,
} from "../../store";
import { Pages } from "../../enums";
import { RenameObjectModal } from "../../components";

/**
 * Handles the saving of a new object to the database, together with setting the name and category.
 */
export const RenameObjectModalContainer = () => {
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const name = useAppSelector(ObjectEditorSelector.getName);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const updateName = async (newName: string) => {
    dispatch(ObjectEditorActions.setName(newName));
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      {displayedPage === Pages.RENAME_OBJECT && (
        <RenameObjectModal name={name} onClose={close} onSave={updateName} />
      )}
    </>
  );
};
