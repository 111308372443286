import i18next from "i18next";
import { Zone } from "..";

const findZone = (zones: Zone[], id: number) => {
  return zones.find((zone: Zone) => zone.id === id);
};

/**
 * Updates the zone with matching ID. The remaining zones in the list are not affected.
 */
const updateZone = (zones: Zone[], zone: Zone) => {
  return zones.map((l: Zone) => {
    if (l.id === zone.id) return zone;
    else return l;
  });
};

/**
 * Inserts the zone in the list of zones at the given index. All indexes in the list
 * are updated, and the list is returned.
 */
const insertZone = (zone: Zone, zones: Zone[]) => {
  const newZones: Zone[] = [];
  const originalIndex = zone.index;
  let index = 0;
  zones.forEach((l: Zone) => {
    if (index === originalIndex) {
      newZones.push({ ...zone, index: index++ });
    }
    // Make sure we don't get 2 identical layers (if layers was already in the list)
    if (l.id !== zone.id) {
      newZones.push({ ...l, index: index++ });
    }
  });
  // Inserting at end will no be included in loop
  if (originalIndex >= index) {
    newZones.push({ ...zone, index: index });
  }
  return newZones;
};

/**
 * Removes a layer from a list. All indexes in the list are updated and the
 * list is returned.
 */
const deleteZone = (zoneId: number, zones: Zone[]) => {
  const newZones: Zone[] = [];
  let index = 0;
  zones.forEach((l: Zone) => {
    if (l.id !== zoneId) {
      newZones.push({
        ...l,
        index: index++,
      });
    }
  });
  return newZones;
};

/**
 * Checks if a layers is currently visible. If id is undefined, it will return false.
 */
const isZoneVisible = (id: number | undefined, zones: Zone[]) => {
  if (id === undefined) return false;
  const zone = findZone(zones, id);
  if (zone) {
    return zone.visible;
  }
  return false;
};

const getNextZoneId = (zones: Zone[]) => {
  const zoneIds = zones.map((zone: Zone) => zone.id);
  let idAvailable = false;
  let id = 0;
  do {
    id++;
    if (!zoneIds.includes(id)) {
      idAvailable = true;
    }
  } while (!idAvailable && id < 100);
  return id;
};

export const ZoneOperations = {
  findZone,
  insertZone,
  deleteZone,
  updateZone,
  isZoneVisible,
  getNextZoneId,
};
