import { useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Invoice,
  InvoiceInfo,
  Membership,
  MembershipDeal,
  MembershipProduct,
  MembershipType,
} from "..";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";
import { CurrencyTools } from "imagine-i18n";
import { Button, Device, Modal } from "imagine-ui";
import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";

type Props = {
  membershipHistory: Membership[];
  membershipProducts?: MembershipProduct[];
  membershipDeals: MembershipDeal[]; // List of deals used in memberships
  invoiceInfo?: InvoiceInfo[];
  download?: boolean;
};

/**
 * The page displays all payments made and lets the user download an invoice for each.
 */
export const Payments = (props: Props) => {
  const { t } = useTranslation();
  const isTouchDevice = useMemo(() => {
    return Device.isTouchDevice();
  }, []);
  const [invoiceMembership, setInvoiceMembership] = useState<
    Membership | undefined
  >();

  const getExpiryDisplayText = useCallback(
    (expiryDate: string | null) => {
      if (expiryDate === null) return t("never");
      else
        return DateTimeText.getDbDateTimeText(
          expiryDate,
          DateTimeFormat.LONG_DATE
        );
    },
    [t]
  );

  const getMembershipName = (membershipType: MembershipType) => {
    if (props.membershipProducts === undefined) return "";
    const membershipProduct = props.membershipProducts.find(
      (product: MembershipProduct) => {
        return membershipType === product.type;
      }
    );
    if (membershipProduct === undefined) return "";
    else return t(membershipProduct.titleKey);
  };

  const membershipDeal = useMemo(() => {
    if (invoiceMembership === undefined) return undefined;
    if (invoiceMembership.dealId && invoiceMembership.dealId > 0) {
      return props.membershipDeals.find(
        (d: MembershipDeal) => d.id === invoiceMembership.dealId
      );
    }
  }, [invoiceMembership, props.membershipDeals]);

  const membershipProduct = useMemo(() => {
    if (
      invoiceMembership === undefined ||
      props.membershipProducts === undefined
    )
      return undefined;

    return props.membershipProducts.find(
      (p: MembershipProduct) => p.type === invoiceMembership.type
    );
  }, [invoiceMembership, props.membershipProducts]);

  return (
    <div id="payments" className="mt">
      {props.membershipHistory.map((membership: Membership, index: number) => (
        <div key={index.toString()} id={"payment-item-" + index}>
          {!membership.cancelled && (
            <div className={"card mb"}>
              <div className="row items-space-between items-y-center">
                <div className="">
                  {DateTimeText.getDbDateTimeText(
                    membership.startDate,
                    DateTimeFormat.LONG_DATE
                  )}
                </div>
                <div className="text-right">
                  <span
                  // className={
                  //   membership.cancelled === CancelState.REFUNDED ||
                  //   membership.cancelled === CancelState.PAYMENT_NOT_COMPLETED
                  //     ? "text-decoration-line-through"
                  //     : ""
                  // }
                  >
                    {membership.price !== undefined &&
                      membership.currency !== undefined &&
                      CurrencyTools.getDisplayPrice(
                        membership.price,
                        membership.currency,
                        true
                      )}
                  </span>
                  {/* PDF viewer not working on touch devices */}
                  {!isTouchDevice && (
                    <Button
                      className="ml"
                      onClick={() => setInvoiceMembership(membership)}
                      elementId={"invoice-button-" + index}
                      transparent
                      color="dark"
                      icon="pdf"
                    ></Button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      ))}

      <Modal
        visible={invoiceMembership !== undefined}
        onClose={() => setInvoiceMembership(undefined)}
        elementId="invoice-preview"
        size="fullscreen"
      >
        <div className="pdf-viewer h-full" id="pdf-invoice-viewer">
          {invoiceMembership !== undefined &&
            props.invoiceInfo !== undefined &&
            membershipProduct !== undefined && (
              <>
                {!props.download && (
                  <PDFViewer width="100%" height="100%">
                    <Invoice
                      membership={invoiceMembership}
                      invoiceInfoList={props.invoiceInfo}
                      membershipProduct={membershipProduct}
                      membershipDeal={membershipDeal}
                    />
                  </PDFViewer>
                )}
                {props.download && (
                  <PDFDownloadLink
                    fileName="Faktura"
                    document={
                      <Invoice
                        membership={invoiceMembership}
                        invoiceInfoList={props.invoiceInfo}
                        membershipProduct={membershipProduct}
                        membershipDeal={membershipDeal}
                      />
                    }
                  />
                )}
              </>
            )}
        </div>
      </Modal>
    </div>
  );
};
