import {
  Area,
  AreaCompressed,
  Curve,
  CurvedLinePathProperties,
  CurvedLinePathPropertiesCompressed,
  DimensionProperties,
  DimensionPropertiesCompressed,
  EllipseProperties,
  EllipsePropertiesCompressed,
  Group,
  GroupCompressed,
  Item,
  ItemCompressed,
  Layer,
  LayerCompressed,
  LinePathProperties,
  LinePathPropertiesCompressed,
  PathProperties,
  PathPropertiesCompressed,
  PlanData,
  PlanDataCompressed,
  PlanSettings,
  PlanSettingsCompressed,
  RectangleProperties,
  RectanglePropertiesCompressed,
  ReferenceImage,
  ReferenceImageCompressed,
  ShapeProperties,
  ShapePropertiesCompressed,
  ShapeStyle,
  ShapeStyleCompressed,
  ShapeType,
  ShapeValidation,
  SizeCompressed,
  TextProperties,
  TextPropertiesCompressed,
} from "..";
import { Point, Size, Tools } from "imagine-essentials";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const removeUndefined = (obj: any) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

const compressSize = (size: Size) => {
  return {
    w: Tools.round(size.width, 5),
    h: Tools.round(size.height, 5),
  } as SizeCompressed;
};

const uncompressSize = (size: SizeCompressed) => {
  return {
    width: size.w,
    height: size.h,
  } as Size;
};

const compressPoint = (point: Point) => {
  return {
    x: Tools.round(point.x, 5),
    y: Tools.round(point.y, 5),
  } as Point;
};

const compressLayer = (layer: Layer) => {
  return {
    id: layer.id,
    n: layer.name,
    i: layer.index,
    v: layer.visible,
  } as LayerCompressed;
};

const uncompressLayer = (layer: LayerCompressed) => {
  return {
    id: layer.id,
    name: layer.n,
    index: layer.i,
    visible: layer.v,
  } as Layer;
};

const compressGroup = (group: Group) => {
  return {
    id: group.id,
    n: group.name,
    i: group.index,
    v: group.visible,
  } as GroupCompressed;
};

const uncompressGroup = (group: GroupCompressed) => {
  return {
    id: group.id,
    name: group.n,
    index: group.i,
    visible: group.v,
  } as Group;
};

const compressRectangleProperties = (properties: RectangleProperties) => {
  return {
    p: properties.position,
    s: compressSize(properties.size),
  } as RectanglePropertiesCompressed;
};

const uncompressRectangleProperties = (
  properties: RectanglePropertiesCompressed
) => {
  return {
    position: properties.p,
    size: uncompressSize(properties.s),
  } as RectangleProperties;
};

const compressPathProperties = (properties: PathProperties) => {
  return {
    p: properties.path,
  } as PathPropertiesCompressed;
};

const uncompressPathProperties = (properties: PathPropertiesCompressed) => {
  return {
    path: properties.p,
  } as PathProperties;
};

const compressEllipseProperties = (properties: EllipseProperties) => {
  return {
    r: compressSize(properties.radius),
    c: compressPoint(properties.center),
  } as EllipsePropertiesCompressed;
};

const uncompressEllipseProperties = (
  properties: EllipsePropertiesCompressed
) => {
  return {
    radius: uncompressSize(properties.r),
    center: properties.c,
  } as EllipseProperties;
};

const compressLinePathProperties = (properties: LinePathProperties) => {
  return {
    p: properties.points.map((point: Point) => compressPoint(point)),
    cp: properties.closePath,
  } as LinePathPropertiesCompressed;
};

const uncompressLinePathProperties = (
  properties: LinePathPropertiesCompressed
) => {
  return {
    points: properties.p.map((point: Point) => point),
    closePath: properties.cp,
  } as LinePathProperties;
};

const compressCurvedLinePathProperties = (
  properties: CurvedLinePathProperties
) => {
  return {
    c: properties.curves.map((curve: Curve) => curve),
    cp: properties.closePath,
  } as CurvedLinePathPropertiesCompressed;
};

const uncompressCurvedLinePathProperties = (
  properties: CurvedLinePathPropertiesCompressed
) => {
  return {
    curves: properties.c.map((curve: Curve) => curve),
    closePath: properties.cp,
  } as CurvedLinePathProperties;
};

const compressDimensionProperties = (properties: DimensionProperties) => {
  return {
    s: compressPoint(properties.start),
    e: compressPoint(properties.end),
  } as DimensionPropertiesCompressed;
};

const uncompressDimensionProperties = (
  properties: DimensionPropertiesCompressed
) => {
  return {
    start: properties.s,
    end: properties.e,
  } as DimensionProperties;
};

const compressTextProperties = (properties: TextProperties) => {
  return {
    t: properties.text,
    tl: properties.textLines,
    p: compressPoint(properties.position),
    s: compressSize(properties.size),
    m: properties.mode,
    f: properties.fontSize,
    a: properties.textAlign,
  } as TextPropertiesCompressed;
};

const uncompressTextProperties = (properties: TextPropertiesCompressed) => {
  return {
    text: properties.t,
    textLines: properties.tl,
    position: properties.p,
    size: uncompressSize(properties.s),
    mode: properties.m,
    fontSize: properties.f,
    textAlign: properties.a,
  } as TextProperties;
};

const compressShapeStyle = (style: ShapeStyle | undefined) => {
  if (style === undefined) return undefined;
  return removeUndefined({
    bc: style.borderColor,
    fc: style.fillColor,
    fp: style.fillPattern,
    o: style.opacity,
    bt: style.borderType,
    sc: style.shineColor,
  }) as ShapeStyleCompressed;
};

const uncompressShapeStyle = (style: ShapeStyleCompressed | undefined) => {
  if (style === undefined) return undefined;
  return {
    borderColor: style.bc,
    fillColor: style.fc,
    fillPattern: style.fp,
    opacity: style.o,
    borderType: style.bt,
    shineColor: style.sc,
  } as ShapeStyle;
};

const compressShapeProperties = (shape: ShapeProperties | undefined) => {
  if (shape === undefined) return undefined;
  let properties;
  if (
    shape.type === ShapeType.RECTANGLE &&
    ShapeValidation.isRectangle(shape.properties)
  ) {
    properties = compressRectangleProperties(
      shape.properties as RectangleProperties
    );
  } else if (
    shape.type === ShapeType.PATH &&
    ShapeValidation.isPath(shape.properties)
  ) {
    properties = compressPathProperties(shape.properties as PathProperties);
  } else if (
    shape.type === ShapeType.ELLIPSE &&
    ShapeValidation.isEllipse(shape.properties)
  ) {
    properties = compressEllipseProperties(
      shape.properties as EllipseProperties
    );
  } else if (
    shape.type === ShapeType.CURVED_LINE &&
    ShapeValidation.isCurvedLinePath(shape.properties)
  ) {
    properties = compressCurvedLinePathProperties(
      shape.properties as CurvedLinePathProperties
    );
  } else if (
    shape.type === ShapeType.LINE &&
    ShapeValidation.isLinePath(shape.properties)
  ) {
    properties = compressLinePathProperties(
      shape.properties as LinePathProperties
    );
  } else if (
    shape.type === ShapeType.DIMENSION &&
    ShapeValidation.isDimension(shape.properties)
  ) {
    properties = compressDimensionProperties(
      shape.properties as DimensionProperties
    );
  } else if (
    shape.type === ShapeType.TEXT &&
    ShapeValidation.isText(shape.properties)
  ) {
    properties = compressTextProperties(shape.properties as TextProperties);
  }

  return removeUndefined({
    t: shape.type,
    r: shape.rotation,
    p: properties,
    s: compressShapeStyle(shape.style),
  }) as ShapePropertiesCompressed;
};

const uncompressShapeProperties = (
  shape: ShapePropertiesCompressed | undefined
) => {
  if (shape === undefined) return undefined;
  let properties;
  if (shape.t === ShapeType.RECTANGLE) {
    properties = uncompressRectangleProperties(
      shape.p as RectanglePropertiesCompressed
    );
  } else if (shape.t === ShapeType.PATH) {
    properties = uncompressPathProperties(shape.p as PathPropertiesCompressed);
  } else if (shape.t === ShapeType.ELLIPSE) {
    properties = uncompressEllipseProperties(
      shape.p as EllipsePropertiesCompressed
    );
  } else if (shape.t === ShapeType.CURVED_LINE) {
    properties = uncompressCurvedLinePathProperties(
      shape.p as CurvedLinePathPropertiesCompressed
    );
  } else if (shape.t === ShapeType.LINE) {
    properties = uncompressLinePathProperties(
      shape.p as LinePathPropertiesCompressed
    );
  } else if (shape.t === ShapeType.DIMENSION) {
    properties = uncompressDimensionProperties(
      shape.p as DimensionPropertiesCompressed
    );
  } else if (shape.t === ShapeType.TEXT) {
    properties = uncompressTextProperties(shape.p as TextPropertiesCompressed);
  }
  return {
    type: shape.t,
    rotation: shape.r,
    properties: properties,
    style: uncompressShapeStyle(shape.s),
  } as ShapeProperties;
};

const compressItem = (item: Item) => {
  return removeUndefined({
    id: item.id,
    i: item.index,
    t: item.type,
    ti: item.templateId,
    sp: compressShapeProperties(item.shapeProperties),
    s: compressSize(item.size),
    r: item.rotation,
    p: compressPoint(item.position),
    vb: item.viewBox,
    l: item.layerId,
    g: item.groupId,
    ld: item.locked,
  }) as ItemCompressed;
};

const uncompressItem = (item: ItemCompressed) => {
  return {
    id: item.id,
    index: item.i,
    type: item.t,
    templateId: item.ti,
    shapeProperties: uncompressShapeProperties(item.sp),
    size: uncompressSize(item.s),
    rotation: item.r,
    position: item.p,
    viewBox: item.vb,
    layerId: item.l,
    groupId: item.g,
    locked: item.ld,
  } as Item;
};

const compressArea = (area: Area | undefined) => {
  if (area === undefined) return undefined;
  return {
    s: compressShapeProperties(area.shape),
    si: compressSize(area.size),
    p: compressPoint(area.position),
    r: area.rotation,
  } as AreaCompressed;
};

const uncompressArea = (area: AreaCompressed | undefined) => {
  if (area === undefined) return undefined;
  return {
    shape: uncompressShapeProperties(area.s),
    size: uncompressSize(area.si),
    position: area.p,
    rotation: area.r,
  } as Area;
};

const compressReferenceImage = (referenceImage: ReferenceImage | undefined) => {
  if (referenceImage === undefined) return undefined;
  return {
    f: referenceImage.fileName,
    s: compressSize(referenceImage.size),
    c: referenceImage.center,
    r: referenceImage.rotation,
    pa: referenceImage.pointA,
    pb: referenceImage.pointB,
    d: referenceImage.distance,
  } as ReferenceImageCompressed;
};

const uncompressReferenceImage = (
  referenceImage: ReferenceImageCompressed | undefined
) => {
  if (referenceImage === undefined) return undefined;
  return {
    fileName: referenceImage.f,
    size: uncompressSize(referenceImage.s),
    center: referenceImage.c,
    rotation: referenceImage.r,
    pointA: referenceImage.pa,
    pointB: referenceImage.pb,
    distance: referenceImage.d,
  } as ReferenceImage;
};

const compressPlanSettings = (planSettings: PlanSettings | undefined) => {
  if (planSettings === undefined) return undefined;
  return removeUndefined({
    z: planSettings.zoom,
    zr: compressPoint(planSettings.zeroReference),
    sl: planSettings.showLayers,
    sg: planSettings.showGroups,
    spl: planSettings.showPlantLibrary,
    sol: planSettings.showObjectLibrary,
    ss: planSettings.showScale,
    sgr: planSettings.showGrid,
    sph: planSettings.showPlantHeights,
    ma: planSettings.mapAddress,
    mc: planSettings.mapCenter,
    mz: planSettings.mapZoom,
    mzr: planSettings.mapZeroReference,
    mp: planSettings.mapPPM,
    mzrp: planSettings.mapZeroReferencePosition,
    cl: planSettings.currentLayerId,
    sri: planSettings.showReferenceImage,
  }) as PlanSettingsCompressed;
};

const uncompressPlanSettings = (
  planSettings: PlanSettingsCompressed | undefined
) => {
  if (planSettings === undefined) return undefined;
  return {
    zoom: planSettings.z,
    zeroReference: planSettings.zr,
    showLayers: planSettings.sl,
    showGroups: planSettings.sg,
    showPlantLibrary: planSettings.spl,
    showObjectLibrary: planSettings.sol,
    showScale: planSettings.ss,
    showGrid: planSettings.sgr,
    showPlantHeights: planSettings.sph,
    mapAddress: planSettings.ma,
    mapCenter: planSettings.mc,
    mapZoom: planSettings.mz,
    mapZeroReference: planSettings.mzr,
    mapPPM: planSettings.mp,
    mapZeroReferencePosition: planSettings.mzrp,
    currentLayerId: planSettings.cl,
    showReferenceImage: planSettings.sri,
  } as PlanSettings;
};

const compressPlanData = (planData: PlanData) => {
  return removeUndefined({
    l: planData.layers.map((layer: Layer) => compressLayer(layer)),
    g: planData.groups.map((group: Group) => compressGroup(group)),
    i: planData.items.map((item: Item) => compressItem(item)),
    a: compressArea(planData.area),
    ps: compressPlanSettings(planData.planSettings),
    ri: compressReferenceImage(planData.referenceImage),
  }) as PlanDataCompressed;
};

const uncompressPlanData = (planData: PlanDataCompressed) => {
  return {
    layers: planData.l.map((layer: LayerCompressed) => uncompressLayer(layer)),
    groups: planData.g.map((group: GroupCompressed) => uncompressGroup(group)),
    items: planData.i.map((item: ItemCompressed) => uncompressItem(item)),
    area: uncompressArea(planData.a),
    planSettings: uncompressPlanSettings(planData.ps),
    referenceImage: uncompressReferenceImage(planData.ri),
  } as PlanData;
};

export const PlanConverter = {
  compressPlanData,
  uncompressPlanData,
};
