import { PlantSchema } from "draw";
import { Tools } from "imagine-essentials";

/**
 * Get the plant in a format ready for the application. Database stores dimensions in cm, while app
 * uses m.
 */
const fromDbFormat = (template: PlantSchema) => {
  const avgHeight = ((template.heightMax || 0) + (template.heightMin || 0)) / 2;
  return {
    ...template,
    // category: template.category || 0,
    width: Tools.round(template.width / 100, 2),
    heightMin: Tools.round((template.heightMin || 0) / 100, 2),
    heightMax: Tools.round((template.heightMax || 0) / 100, 2),
    heightLeaf: Tools.round((template.heightLeaf || avgHeight) / 100, 2),
    leafSeason: template.leafSeason !== null ? template.leafSeason : [],
    flowerSeason: template.flowerSeason !== null ? template.flowerSeason : [],
    leafColor: template.leafColor === null ? undefined : template.leafColor,
    flowerTemplate:
      template.flowerTemplate === null ? 0 : template.flowerTemplate,
    flowerColorVariegation:
      template.flowerColorVariegation === null
        ? undefined
        : template.flowerColorVariegation,
    flowerColorVariegationGroup:
      template.flowerColorVariegationGroup === null
        ? undefined
        : template.flowerColorVariegationGroup,
    leafTemplate: template.leafTemplate === null ? 0 : template.leafTemplate,
    branchTemplate:
      template.branchTemplate === null ? 0 : template.branchTemplate,
    leafColorVariegation:
      template.leafColorVariegation === null
        ? undefined
        : template.leafColorVariegation, // No color is stored as null in database, but needs to be undefined in the app
    leafColorGroup:
      template.leafColorGroup === null ? undefined : template.leafColorGroup,
    leafColorTransitional:
      template.leafColorTransitional !== null
        ? template.leafColorTransitional
        : undefined,
    leafColorTransitionalSeason:
      template.leafColorTransitionalSeason !== null
        ? template.leafColorTransitionalSeason
        : [], // Because some plants are stored with null in this field. Can be removed when all has been updated, but in the meantime this prevents an error in the application becasue it expects an array
  } as PlantSchema;
};

const toDbFormat = (template: PlantSchema) => {
  const avgHeight = ((template.heightMax || 0) + (template.heightMin || 0)) / 2;
  return {
    ...template,
    width: Tools.round(template.width * 100, 2),
    heightMin: Tools.round((template.heightMin || 0) * 100, 2),
    heightMax: Tools.round((template.heightMax || 0) * 100, 2),
    heightLeaf: Tools.round((template.heightLeaf || avgHeight) * 100, 2),
  } as PlantSchema;
};

const fromDbFormats = (templates: PlantSchema[]) => {
  return templates.map((template: PlantSchema) => {
    return fromDbFormat(template);
  });
};

export const PlantConverter = {
  fromDbFormat,
  toDbFormat,
  fromDbFormats,
};
