import { useMemo } from "react";

import {
  CanvasOperations,
  Group,
  Item,
  ItemOperations,
  Layer,
  Model,
  ModelOperations,
  ModelSvg,
  ObjectSchema,
  PlantSchema,
} from "draw";
import { Delimiter, Point, Size } from "imagine-essentials";
import { CursorMode } from "../../enums";
import { Device } from "imagine-ui";
import { UnitScale } from "project";

type StaticItemProps = {
  items: Item[];
  showPlantHeights: boolean;
  plantTemplates: PlantSchema[]; // Templates needed to render the plant items
  objectTemplates: ObjectSchema[]; // Templates needed to render the object templates
  faded?: boolean; // Hides the items behind a semi-transparent overlay if set
  onItemClick?: (id: number) => void;
  onItemCtrlClick?: (id: number) => void;
  onItemRightClick?: (id: number, position: Point) => void;
  onFastTouch: (id: number) => void;
  locked?: boolean; // No items can be selected or interacted with
  cursor?: string;
  canvasSize: Size;
  zeroReference: Point;
  zoom: number;
  layers: Layer[];
  groups: Group[];
  month: number;
  cursorMode: CursorMode;
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Responsible for drawing all static items.
 */
export const StaticItems = (props: StaticItemProps) => {
  const isTouchDevice = Device.isTouchDevice();

  const clicked = (id: number) => {
    if (props.locked) return;
    if (props.cursorMode === CursorMode.DRAG && !isTouchDevice) return;
    if (props.onItemClick) props.onItemClick(id);
  };

  const ctrlClicked = (id: number) => {
    if (props.locked) return;

    if (props.cursorMode === CursorMode.DRAG && !isTouchDevice) return;
    if (props.onItemCtrlClick) props.onItemCtrlClick(id);
  };

  const rightClicked = (id: number, position: Point) => {
    if (props.locked) return;

    if (props.cursorMode === CursorMode.DRAG && !isTouchDevice) return;
    if (props.onItemRightClick) props.onItemRightClick(id, position);
  };

  const fastTouch = (id: number) => {
    if (props.locked) return;

    if (props.onFastTouch) props.onFastTouch(id);
  };

  const longTouch = (id: number) => {
    if (props.locked) return;

    if (props.cursorMode === CursorMode.POINTER) return;
    if (props.onItemClick) props.onItemClick(id);
  };

  // const areaRotation = useMemo(() => {
  //   if (area === undefined) return "";
  //   const model = Models.getAreaModel(
  //     area.size,
  //     area.position,
  //     zeroReference,
  //     zoom,
  //     area.shape,
  //     area.rotation ?? 0
  //   );
  //   return (
  //     "rotate(" +
  //     model.rotation +
  //     ", " +
  //     (model.position.x + model.size.width / 2) +
  //     ", " +
  //     (model.position.y + model.size.height / 2) +
  //     ")"
  //   );
  // }, [area, zeroReference, zoom]);

  /**
   * Remembers the value and only re-calculates the item model when one of the dependencies have changes.
   */
  const itemModels = useMemo(() => {
    const unitZeroReference = CanvasOperations.pixelToUnitPosition(
      props.zeroReference,
      props.zoom,
      { x: 0, y: 0 },
      3
    );
    // This is the area that is visible
    const visibleBoard = {
      size: CanvasOperations.pixelToUnitSize(props.canvasSize, props.zoom, 3),
      position: {
        x: -unitZeroReference.x,
        y: -unitZeroReference.y,
      },
    };

    const visibleItems = ItemOperations.getItemsWithinRectangle(
      props.items,
      visibleBoard,
      false,
      true
    );

    return ModelOperations.getRenderModels(
      props.layers,
      props.groups,
      visibleItems,
      props.zoom,
      props.zeroReference,
      props.plantTemplates,
      props.objectTemplates,
      props.month,
      props.showPlantHeights || false,
      true
    );
  }, [
    props.canvasSize,
    props.groups,
    props.items,
    props.layers,
    props.month,
    props.objectTemplates,
    props.plantTemplates,
    props.showPlantHeights,
    props.zeroReference,
    props.zoom,
  ]);

  const getCursor = (locked?: boolean) => {
    if (props.cursor) return props.cursor;
    else if (locked || props.locked) return "default";
    else return "move";
  };

  const inactive =
    props.locked || (props.cursorMode === CursorMode.DRAG && !isTouchDevice);

  return (
    <>
      {itemModels.map((model: Model, index: number) => (
        <g
          key={model.id.toString()}
          id={"staticitem-" + index}
          transform={
            "rotate(" +
            model.rotation +
            ", " +
            (model.position.x + model.size.width / 2) +
            ", " +
            (model.position.y + model.size.height / 2) +
            ")"
          }
          className={"static-item" + (model.locked ? " locked" : "")}
        >
          <ModelSvg
            model={model}
            onMouseDown={(event) => clicked(model.id)}
            onLongTouch={() => longTouch(model.id)}
            onTouchStart={() => fastTouch(model.id)}
            onCtrlClick={() => ctrlClicked(model.id)}
            onRightClicked={(pos: Point) => rightClicked(model.id, pos)}
            cursor={getCursor(model.locked)}
            className={
              "static-item bg-accent" + (model.locked ? " locked" : "")
            }
            inactive={inactive || model.locked}
            propagateTouch
            delimiter={props.delimiter}
            unitScale={props.unitScale}
          />
        </g>
      ))}
      {props.faded && (
        <rect
          width={props.canvasSize.width}
          height={props.canvasSize.height}
          x={0}
          y={0}
          fill={"#CCBD82"}
          opacity={0.8}
          className="object-mode-overlay"
        />
      )}
    </>
  );
};
