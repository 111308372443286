import { Icon } from "imagine-ui";

export interface ToolbarTab {
  title: string;
  id: string;
  icon: string;
  onClick: () => void;
}
interface Props {
  children?: React.ReactNode;
  tabs: ToolbarTab[];
  activeTab: string;
  narrowLayout: boolean;
  elementId?: string;
}

export const Toolbar = (props: Props) => {
  return (
    <div className="toolbar" id={props.elementId}>
      <div className="tool-tab-bar">
        {props.tabs.map((tab: ToolbarTab) => (
          <button
            className={"tab" + (props.activeTab === tab.id ? " active" : "")}
            id={tab.id}
            onClick={tab.onClick}
            key={tab.id}
          >
            {props.narrowLayout ? <Icon name={tab.icon} /> : tab.title}
          </button>
        ))}
      </div>
      <div className="toolbar-body">{props.children}</div>
    </div>
  );
};
