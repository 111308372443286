import { Point } from "imagine-essentials";

interface Props {
  uniqueId: string; /// Unique ID is added to the type
  onClick: () => void;
  position: Point; // The center of the circle
  radius: number;
  className?: string;
}

/**
 * Circle with a check mark
 */
export const CheckCircle = (props: Props) => {
  // Panel height is set specifically to keep it fixed and allow panel items to scroll
  return (
    <svg
      width={props.radius * 2}
      height={props.radius * 2}
      x={props.position.x - props.radius}
      y={props.position.y - props.radius}
      viewBox="0 0 48 48"
      xmlns="http://www.w3.org/2000/svg"
      // onClick={props.onClick}
      onMouseDown={props.onClick}
      onTouchStart={props.onClick}
      className="check-circle"
      // id="board"
    >
      <ellipse
        //  style="fill:#00b600;fill-opacity:1;stroke:#00ff00;stroke-width:0.356484"
        stroke="none"
        // fill={Specs.colors.secondary}
        cx="24"
        cy="24"
        rx="24"
        ry="24"
        className="check-circle-content"
      />
      <path
        //  style="fill:#ffffff;fill-opacity:1;stroke:none;stroke-width:0.756521px;stroke-linecap:butt;stroke-linejoin:miter;stroke-opacity:1"
        d="M 12.88,25.74 15.63,21.68 23.39,30.73 34.9,13.2 38.22,17.01 23.47,37.82 Z"
        stroke="none"
        fill="#FFFFFF"
        className="check-circle-content"
      />
    </svg>
  );
};
