import {
  CanvasActions,
  LayoutSelector,
  PlanEditorActions,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import { CanvasSelector } from "../../store";
import { CursorMode, MapMode } from "../../enums";
import { Button, ButtonGroup, Device } from "imagine-ui";
import { useMemo } from "react";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";

/**
 * Displays tools on top of the canvas. Eg. cursor mode.
 * @param props
 * @returns
 */
export const ZoomButtonsContainer = () => {
  const dispatch = useAppDispatch();

  const sidebarRightOpen = useAppSelector(LayoutSelector.getSidebarRightOpen);
  const footerOpen = useAppSelector(LayoutSelector.getFooterOpen);
  const blockedBottomHeight = useAppSelector(CanvasSelector.getBlockedBottom);
  const isTouchDevice = Device.isTouchDevice();
  const mapVisible =
    useAppSelector(CanvasSelector.getMapMode) === MapMode.VISIBLE;
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const showZoomButtons =
    userPreferences.showZoomButtons || (isTouchDevice && mapVisible);

  const zoomIn = () => {
    dispatch(CanvasActions.zoomInAuto());
  };

  const canZoomIn = useAppSelector(CanvasSelector.canZoomIn);

  const zoomOut = () => {
    dispatch(CanvasActions.zoomOutAuto());
  };

  const canZoomOut = useAppSelector(CanvasSelector.canZoomOut);

  const className = useMemo(() => {
    let classes = "bg-main rounded canvas-tools-bottom-right";
    if (sidebarRightOpen) classes += " sidebar-open";
    if (footerOpen) classes += " footer-open";
    return classes;
  }, [footerOpen, sidebarRightOpen]);

  const style = useMemo(() => {
    return {
      bottom: blockedBottomHeight + 8 + "px",
    };
  }, [blockedBottomHeight]);

  return (
    <>
      {showZoomButtons && (
        <div className={className} style={style}>
          <ButtonGroup direction="vertical">
            <Button
              icon="magnifier-plus"
              color="secondary"
              onClick={zoomIn}
              disabled={!canZoomIn}
            ></Button>
            <Button
              icon="magnifier-minus"
              color="secondary"
              onClick={zoomOut}
              disabled={!canZoomOut}
            ></Button>
          </ButtonGroup>
        </div>
      )}
    </>
  );
};
