import { Checkbox } from "imagine-ui";
import { ColorGroup, ColorUtils, PlantModelText } from "draw";

interface Props {
  selected: ColorGroup[];
  onChange: (colorGroups: ColorGroup[]) => void;
  columns: 2 | 3;
  availableColors?: ColorGroup[];
}

const defaultColors = [
  ColorGroup.WHITE,
  ColorGroup.PINK,
  ColorGroup.BLUE,
  ColorGroup.YELLOW,
  ColorGroup.RED,
  ColorGroup.GREEN,
  ColorGroup.ORANGE,
  ColorGroup.VIOLET,
  ColorGroup.BLACK,
];

/**
 * Displays the color groups in 3 columns with checkboxes.
 * @param props  selected, onChange
 * @returns
 */
export const ColorCheckboxes = (props: Props) => {
  const updateColor = (color: ColorGroup, checked: boolean) => {
    let newColors: ColorGroup[] = [];
    if (checked) {
      newColors = [...props.selected] || [];
      newColors.push(color);
    } else {
      newColors =
        props.selected.filter((c: ColorGroup) => {
          return c !== color;
        }) || [];
    }
    props.onChange(newColors);
  };

  const colors = props.availableColors || defaultColors;

  return (
    <div className={"grid columns-" + props.columns}>
      {colors.map((color: ColorGroup) => (
        <Checkbox
          key={color}
          label={ColorUtils.getColorGroupText(color)}
          checked={props.selected.includes(color) || false}
          onChange={(checked: boolean) => updateColor(color, checked)}
        />
      ))}
      {/* <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.WHITE)}
        checked={props.selected.includes(ColorGroup.WHITE) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.WHITE, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.PINK)}
        checked={props.selected.includes(ColorGroup.PINK) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.PINK, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.BLUE)}
        checked={props.selected.includes(ColorGroup.BLUE) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.BLUE, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.YELLOW)}
        checked={props.selected.includes(ColorGroup.YELLOW) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.YELLOW, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.RED)}
        checked={props.selected.includes(ColorGroup.RED) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.RED, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.GREEN)}
        checked={props.selected.includes(ColorGroup.GREEN) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.GREEN, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.ORANGE)}
        checked={props.selected.includes(ColorGroup.ORANGE) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.ORANGE, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.VIOLET)}
        checked={props.selected.includes(ColorGroup.VIOLET) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.VIOLET, checked)}
      />
      <Checkbox
        label={ColorUtils.getColorGroupText(ColorGroup.BLACK)}
        checked={props.selected.includes(ColorGroup.BLACK) || false}
        onChange={(checked: boolean) => updateColor(ColorGroup.BLACK, checked)}
      /> */}
    </div>
  );
};
