import { useEffect, useState } from "react";
import { Point, Size } from "imagine-essentials";
import { CanvasOperations } from "..";
import React from "react";

type Props = {
  children: React.ReactNode;
  unitSize: Size; // Size in units
  // primaryColor: string;
  // secondaryColor: string;
  startPosition?: Point;
  viewBox: string;
  zoom?: number;
  id: string;
};

/**
 * The template for all pattern definitions. This makes it faster to add new patterns.
 */
export const PatternTemplate = (props: Props) => {
  const [size, setSize] = useState(props.unitSize);

  useEffect(() => {
    if (props.zoom !== undefined) {
      setSize(CanvasOperations.unitToPixelSize(props.unitSize, props.zoom));
    }
  }, [props.unitSize, props.zoom]);

  return (
    <pattern
      id={props.id}
      viewBox={props.viewBox}
      width={size.width}
      height={size.height}
      x={props.startPosition?.x || 0}
      y={props.startPosition?.y || 0}
      patternUnits="userSpaceOnUse"
    >
      {props.children}
    </pattern>
  );
};
