import { Api, DataResponse, SentryReporter } from "imagine-essentials";
import {
  CountrySummaryPlant,
  MissingPlant,
  PlantImage,
  PlantSearchFilter,
} from "../types";
import { PlantConverter, PlantSearchHelper } from "../utils";
import { t } from "i18next";
import { PlantSchema } from "draw";
import { Country } from "imagine-i18n";
import { HardinessZoneUSDA } from "project";

const search = async (filter: PlantSearchFilter) => {
  const result = await Api.post(
    "api/plants/search",
    PlantSearchHelper.adjustPlantSearchFilter(filter)
  );
  if (result.success) {
    return PlantConverter.fromDbFormats(result.data);
  }
};

const getMultiplePlantTemplates = async (ids: number[]) => {
  if (ids.length === 0)
    return {
      success: true,
      data: [],
    };
  const result = await Api.get("api/plants/ids/" + ids.join(","));
  const response: DataResponse<PlantSchema[]> = {
    success: result.success,
  };
  if (result.success) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response.data = result.data.map((plant: any) => {
      return PlantConverter.fromDbFormat(plant as PlantSchema);
    }) as PlantSchema[];
  } else {
    SentryReporter.captureException("Failed to get multiple plant templates", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const addPlant = async (plant: PlantSchema) => {
  const dbPlant = PlantConverter.toDbFormat(plant);

  // Add or update plant data (not image yet)
  const result = await Api.post("api/plants/add", dbPlant);
  const response: DataResponse<PlantSchema> = {
    success: result.success,
  };
  if (result.success) {
    response.data = PlantConverter.fromDbFormat(result.data);
  } else {
    SentryReporter.captureException("Failed to create plant", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const updatePlant = async (plant: PlantSchema) => {
  const dbPlant = PlantConverter.toDbFormat(plant);

  // Add or update plant data (not image yet)
  const result = await Api.post("api/plants/update/" + plant.id, dbPlant);
  const response: DataResponse<PlantSchema> = {
    success: result.success,
  };
  if (result.success) {
    response.data = PlantConverter.fromDbFormat(result.data);
  } else {
    SentryReporter.captureException("Failed to update plant", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const uploadPlantImage = async (
  plantId: number,
  image: File,
  publicImage: boolean
) => {
  const formData = new FormData();
  formData.append("image", image);
  formData.append("public", publicImage ? "1" : "0");

  const result = await Api.postForm("api/plants/image/" + plantId, formData);
  const response: DataResponse<PlantSchema> = { success: result.success };
  if (result.success) {
    // Plant image id has changed if new image was uploaded
    response.data = PlantConverter.fromDbFormat(result.data);
  } else {
    if (result.status === 415) {
      response.errorMessage = t("plants:imageSizeOrFormatNotSupported");
      response.errorCode = "imageSizeOrFormatNotSupported";
    }
    if (result.status === 400) {
      response.errorMessage = t("plants:imageUploadFailed");
      response.errorCode = "imageUploadFailed";
    } else {
      SentryReporter.captureException("Failed to set plant image", {
        Result: JSON.stringify(result),
      });
    }
  }
  return response;
};

const getUserPlants = async () => {
  const result = await Api.get("api/plants/user");
  const response: DataResponse<PlantSchema[]> = {
    success: result.success,
  };
  if (result.success) {
    response.data = PlantConverter.fromDbFormats(result.data);
  } else {
    SentryReporter.captureException("Failed to get user plants", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getPlantImages = async (latinName: string) => {
  const result = await Api.post("api/plants/images/search", {
    latinName: latinName,
  });
  const response: DataResponse<PlantImage[]> = {
    success: result.success,
  };
  if (result.status && result.data) {
    try {
      response.data = result.data;
    } catch (e) {
      SentryReporter.captureException("Unable to parse plant images", {
        Images: JSON.stringify(result.data),
      });
    }
  } else
    SentryReporter.captureException("Failed to get plant images", {
      Result: JSON.stringify(result),
    });
  return response;
};

const getCountrySummary = async (countries: Country[]) => {
  const result = await Api.post("api/plants/country-summary", countries);
  const response: DataResponse<CountrySummaryPlant[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    response.data = result.data.map((plant: any) => {
      if (plant.variants === null) {
        return {
          latinName: plant.latinName,
          hybridName: plant.hybridName,
          count: plant.count,
          variants: [],
        };
      }
      return {
        latinName: plant.latinName,
        hybridName: plant.hybridName,
        count: plant.count,
        variants: plant.variants.split(",").map((variant: string) => {
          const parts = variant.split(" ");
          if (parts.length >= 3) {
            const hardinessZoneParts = parts[2].split("-");
            if (hardinessZoneParts.length >= 2) {
              return {
                id: parseInt(parts[0]),
                country: parts[1] as Country,
                hardinessZoneMin: parseInt(
                  hardinessZoneParts[0]
                ) as HardinessZoneUSDA,
                hardinessZoneMax: parseInt(
                  hardinessZoneParts[1]
                ) as HardinessZoneUSDA,
              };
            }
          }
          throw new Error("Invalid variant format: " + variant);
        }),
      };
    });
  } else {
    SentryReporter.captureException("Failed to get country summary", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getPlant = async (id: number) => {
  const result = await Api.get("api/plants/id/" + id);
  const response: DataResponse<PlantSchema> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = PlantConverter.fromDbFormat(result.data);
  } else {
    SentryReporter.captureException("Failed to get plant", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getMissingPlants = async () => {
  const result = await Api.get("api/plants/missing");
  const response: DataResponse<MissingPlant[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to get missing plants", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const deleteMissingPlantEntry = async (id: number) => {
  const result = await Api.post("api/plants/missing/delete", { id: id });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to delete missing plant entry", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getUnprocessedPlants = async () => {
  const result = await Api.get("api/plants/unprocessed");
  const response: DataResponse<PlantSchema[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = PlantConverter.fromDbFormats(result.data);
  } else {
    SentryReporter.captureException("Failed to get unprocessed plants", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const processPlant = async (id: number, publicPlant: boolean) => {
  const result = await Api.post("api/plants/" + id + "/process", {
    public: publicPlant,
  });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to process plant", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getPlantDiffs = async (
  latinName: string,
  countryA: string,
  countryB: string
) => {
  const result = await Api.post("api/plants/diff", {
    latinName,
    countryA,
    countryB,
  });
  const response: DataResponse<PlantSchema[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = PlantConverter.fromDbFormats(result.data);
  } else {
    SentryReporter.captureException("Failed to get plant diffs", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const getAllPlantImages = async () => {
  const result = await Api.get("api/plants/images/all");
  const response: DataResponse<PlantImage[]> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to get all plant images", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const deletePlantImage = async (id: number) => {
  const result = await Api.post("api/plants/images/delete/" + id, {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to delete plant image", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

/**
 * Allows updating latin name, hybrid name and public status of a plant image
 * @param image
 * @returns
 */
const updatePlantImage = async (image: PlantImage) => {
  const result = await Api.post("api/plants/images/update/" + image.id, image);
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to update plant image", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

export const PlantsApi = {
  search,
  getMultiplePlantTemplates,
  addPlant,
  updatePlant,
  uploadPlantImage,
  getUserPlants,
  getPlantImages,
  getCountrySummary,
  getPlant,
  getMissingPlants,
  deleteMissingPlantEntry,
  getUnprocessedPlants,
  processPlant,
  getPlantDiffs,
  getAllPlantImages,
  deletePlantImage,
  updatePlantImage,
};
