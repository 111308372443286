import {
  PDFViewer,
  Page,
  Text,
  View,
  Document,
  Image,
  Svg,
  G,
  Rect,
  Circle,
} from "@react-pdf/renderer";
import { Item, ItemOperations, ItemType, PlantSchema } from "draw";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";
import { Point, Rectangle } from "imagine-essentials";
import { Modal } from "imagine-ui";
import { useMemo } from "react";
import { PlantingPlanStyle as styles } from "..";
import { useTranslation } from "react-i18next";
import { PlantText } from "plants";
import { PlantReference } from "../../types";
import logoBig from "../../assets/img/invoice-logo.png";
import _ from "lodash";

interface Props {
  visible: boolean;
  onClose: () => void;
  rectangle: Rectangle;
  name: string;
  items: Item[];
  plantTemplates: PlantSchema[];
}

interface PlantData {
  position: Point;
  radius: number;
  templateId: number;
  labelNo: number;
  color: string;
}

// 1 cm on the paper is X meters
// const scales = [0.1, 0.2, 0.25, 0.5, 1, 1.2, 1.5, 2];
const scales = [2, 1.5, 1.2, 1, 0.5, 0.25, 0.2, 0.2];
// Paper dimensions in cm
const paperMargin = 1.5;
const paperSize = {
  width: 31 - 2 * paperMargin,
  height: 21 - 2 * paperMargin - 3,
};
const strokeWidth = 0.05; //0.003;

/**
 * Get the largest possible ara in cm with a measurable scale.
 * @param area The area item.
 */
const getPaperScale = (area: Rectangle) => {
  const width = area.size.width;
  const height = area.size.height;

  let scaleX = scales[scales.length - 1];
  let scaleY = scales[scales.length - 1];

  for (let i = 0; i < scales.length; i++) {
    if (width / scales[i] < paperSize.width) scaleX = scales[i];
    if (height / scales[i] < paperSize.height) scaleY = scales[i];
  }

  const scale = Math.max(scaleX, scaleY);
  return scale;
};

/**
 * Displays the planting plan as a PDF document.
 */
export const PlantingPlanPage = (props: Props) => {
  const { t } = useTranslation();

  const displayDate = useMemo(() => {
    const today = new Date();
    return DateTimeText.getDateTimeText(today, DateTimeFormat.LONG_DATE);
  }, []);

  /**
   * List of plant items included in the selected area.
   */
  const includedItems = useMemo(() => {
    const plantItems = props.items.filter(
      (item: Item) => item.type === ItemType.PLANT
    );

    return ItemOperations.getItemsWithinRectangle(
      plantItems,
      props.rectangle,
      true,
      true
    );
  }, [props.rectangle, props.items]);

  /**
   * Get the minimal rectangle that contains all the items. In case user marked a rectangle larger than necessary.
   */
  const adjustedRectangle = useMemo(() => {
    return ItemOperations.getContainingRectangle(includedItems);
  }, [includedItems]);

  // Viewbox is the area in meters
  const viewBox =
    adjustedRectangle.position.x +
    " " +
    adjustedRectangle.position.y +
    " " +
    adjustedRectangle.size.width +
    " " +
    adjustedRectangle.size.height;

  /**
   * The number of meters that fit in one cm on the paper.
   */
  const scaleRatio = useMemo(() => {
    return getPaperScale(adjustedRectangle);
  }, [adjustedRectangle]);

  /**
   * The width and height of the area in cm, ready for the paper.
   */
  const size = useMemo(() => {
    return {
      width: adjustedRectangle.size.width / scaleRatio,
      height: adjustedRectangle.size.height / scaleRatio,
    };
  }, [scaleRatio, adjustedRectangle]);

  const plantLabelSize = scaleRatio / 3;

  /**
   * List of plants used in the entire plan. Each plant is represented as a PlantReference. All
   * plants are includes to ensure that the plant number labels are consistent when making
   * multiple planting plans.
   */
  const plantReferences = useMemo(() => {
    // Sort the plant templates to make sure the plant numbers are consistent
    const sortedPlantTemplates = _.cloneDeep(props.plantTemplates);
    sortedPlantTemplates.sort((a: PlantSchema, b: PlantSchema) => {
      return PlantText.getLatinAndHybridName(a).localeCompare(
        PlantText.getLatinAndHybridName(b)
      );
    });

    // Get array of used plant template ids
    const templateIdList = includedItems.map((item: Item) => {
      return item.templateId || 0;
    });
    // Create the plant references - one for each plant template
    const references = sortedPlantTemplates.map(
      (template: PlantSchema, index: number) => {
        return {
          templateId: template.id,
          displayName: PlantText.getFullName(template),
          image: template.image,
          count: templateIdList.filter(
            (templateId: number) => templateId === template.id
          ).length,
          ref: index + 1,
          color:
            template.flowerSeason.length > 0
              ? template.flowerColor
              : template.leafColor,
        };
      }
    );

    return references;
  }, [includedItems, props.plantTemplates]);

  const includedPlantReferences = useMemo(() => {
    return plantReferences.filter((ref: PlantReference) => {
      return ref.count > 0;
    });
  }, [plantReferences]);

  const models = useMemo(() => {
    return includedItems
      .filter((item: Item) => item.type === ItemType.PLANT)
      .map((item: Item) => {
        const pos = { ...item.position };
        const plantPos = {
          x: pos.x + item.size.width / 2,
          y: pos.y + item.size.width / 2,
        };
        const ref = plantReferences.find(
          (plantRef: PlantReference) => plantRef.templateId === item.templateId
        );
        const plantNo = ref !== undefined ? ref.ref : 0;
        const color = ref !== undefined ? ref.color : "#FFF";
        return {
          position: plantPos,
          radius: item.size.width / 2,
          templateId: item.templateId,
          labelNo: plantNo,
          color: color,
        } as PlantData;
      });
  }, [includedItems, plantReferences]);

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      title={t("plantingPlan")}
      elementId="invoice-preview"
      size="fullscreen"
    >
      <PDFViewer width="100%" height="100%">
        <Document>
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.drawing}>
              <Svg
                style={{
                  width: size.width + "cm",
                  height: size.height + "cm",
                }}
                viewBox={viewBox}
              >
                {models.map((plant: PlantData, index: number) => (
                  <G key={index}>
                    <Circle
                      cx={plant.position.x}
                      cy={plant.position.y}
                      r={plant.radius}
                      stroke="black"
                      strokeWidth={0.001}
                      fill={plant.color}
                      fillOpacity={0.5}
                    />
                    <Text
                      x={plant.position.x}
                      y={plant.position.y}
                      fill={"black"}
                      strokeWidth={0.001}
                      dominantBaseline="middle"
                      textAnchor="middle"
                      style={{ fontSize: plantLabelSize + "pt" }}
                    >
                      {plant.labelNo}
                    </Text>
                  </G>
                ))}
              </Svg>
            </View>
            <View style={styles.footer}>
              <View style={styles.customLogo}></View>
              <View style={styles.planInfo}>
                <Text style={styles.title}>{props.name}</Text>
                <Text style={styles.subTitle}>
                  {t("plantingPlan")}, {displayDate}
                </Text>
                <View style={styles.filler1}></View>
                <Text style={styles.scaleRatio}>
                  {t("scaleRatio")} 1:{Math.round(scaleRatio * 100)}
                </Text>
              </View>
              <View style={styles.gsLogo}>
                <Image src={logoBig}></Image>
              </View>
            </View>
            {/* <View style={styles.plan}> */}

            {/* </View> */}
            {/* </View> */}
          </Page>
          <Page size="A4" style={styles.page} orientation="portrait">
            <View style={styles.header}>
              <View style={styles.customLogo}></View>
              <View style={styles.planInfo}>
                <View style={styles.filler1}></View>
                <Text style={styles.title}>{props.name}</Text>
                <Text style={styles.subTitle}>
                  {t("plants:plantList")}, {displayDate}
                </Text>
                <View style={styles.filler1}></View>
              </View>
              <View style={styles.gsLogo}>
                <Image src="/img/invoice-logo.png"></Image>
              </View>
            </View>
            <View style={styles.plantList}>
              {includedPlantReferences.map((plantRef: PlantReference) => (
                <Text key={plantRef.templateId} style={styles.plantName}>
                  {plantRef.ref}. {plantRef.displayName} ({plantRef.count} stk.)
                </Text>
              ))}
            </View>
          </Page>
        </Document>
      </PDFViewer>
    </Modal>
  );
};
