/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CanvasOperations,
  Item,
  ItemType,
  PlanSettings,
  ShapeType,
  TextAlign,
  TextMode,
  TextProperties,
  Zoom,
} from "draw";
import { MapOperations } from ".";
import { AppInfo, Point } from "imagine-essentials";

/**
 * The app expexts a textmode on all text items. In old plans, this is not present, so
 * this function sets the text mode to static for all text items that does not have a text
 * mode already.
 * @param items List of items.
 * @param version The version the plan was last saved from
 */
const patchItems = (items: any, version: string) => {
  if (!Array.isArray(items)) return [];
  return items.map((item: any) => {
    if (item.type !== undefined) {
      if (item.type === ItemType.SHAPE) {
        if (item.shapeProperties !== undefined) {
          if (item.shapeProperties.type !== undefined) {
            if (item.shapeProperties.type === ShapeType.TEXT) {
              if (item.shapeProperties.properties !== undefined) {
                if (AppInfo.isVersionOlder(version, "1.5")) {
                  item = patchTextShape(item);
                }
                // if (item.shapeProperties.properties.mode === undefined) {
                //   item.shapeProperties.properties.mode = TextMode.STATIC;
                // }
              }
            }
          }
        }
      }
    }
    return item as Item;
  });
};

/**
 * In version 1.5 the text shapes was improved and does not longer include a text mode, but has a font size instead. Also
 * a previous text mode was setting size to 0, so this must be updated in order to make those text elements visible. This is
 * only needed if the plan was last saved on a version lower than 1.5
 * @param item
 */
const patchTextShape = (item: Item) => {
  const defaultSize = { width: 4, height: 1 };
  const properties = item.shapeProperties?.properties as TextProperties;
  if (properties === undefined) return item;
  const size = properties.mode !== TextMode.STATIC ? item.size : defaultSize;
  const newTextProperties: TextProperties = {
    text: properties.text,
    textLines: [properties.text],
    size: { ...size },
    fontSize: 30,
    position: {
      x: 0,
      y: 0,
    },
    textAlign: TextAlign.LEFT,
  };

  const newItem = {
    ...item,
  };
  if (newItem.shapeProperties?.properties !== undefined) {
    newItem.shapeProperties.properties = newTextProperties;
  }
  newItem.viewBox = "0 0 " + size.width + " " + size.height;
  // Previously the position was the center of the text, but in 1.5 it will be the upper left corner
  newItem.position = {
    x: newItem.position.x - size.width / 2,
    y: newItem.position.y - size.height / 2,
  };
  if (properties.mode === TextMode.STATIC) {
    newItem.size = defaultSize;
    newItem.viewBox = "0 0 4 1";
  }
  return newItem;
};

/**
 * If plan is from version 1.0, then update the zoom and map values. The zoom and how map is linked has changed
 * between 1.0 and 1.1. Returns the plan setting with correct values.
 * @param settings The plan settings.
 * @param version Current version of the plan.
 */
const patchSettings = (settings: PlanSettings, version: string) => {
  if (version === "1.0" || version === "0.1") {
    // Use default zoom
    const zoom = Zoom.getZoomValue(80);
    const updatedSettings = {
      ...settings,
      zoom,
    };
    if (
      settings.mapAddress !== "" &&
      settings.mapZeroReference !== undefined &&
      settings.mapPPM !== undefined &&
      settings.mapCenter !== undefined
    ) {
      // Map is linked the old way
      updatedSettings.zeroReference = { ...settings.mapZeroReference };
      updatedSettings.zoom = Zoom.getZoomValue(
        Zoom.getClosestZoom(settings.mapPPM)
      );
      // const diff = CanvasOperations.getPointSubtracted(
      //   settings.mapZeroReference,
      //   boardCenter
      // );
      // const diffMeters = CanvasOperations.pixelToUnitPosition(
      //   diff,
      //   settings.mapPPM,
      //   { x: 0, y: 0 },
      //   6
      // );
      updatedSettings.mapZeroReferencePosition = settings.mapCenter;
      // MapOperations.getMapPositionOffset(settings.mapCenter, diffMeters);
    }
    return updatedSettings;
  }
  return settings;
};

export const PlanPatcher = {
  patchItems,
  patchTextShape,
  patchSettings,
};
