import _ from "lodash";
import {
  CanvasOperations,
  Curve,
  CurvedLinePathProperties,
  Group,
  GroupOperations,
  Item,
  ItemType,
  Layer,
  LayerOperations,
  PlantSchema,
  RectangleProperties,
  ShapeOperations,
  ShapeType,
} from "..";

import { Point, Rectangle, SentryReporter, Trig } from "imagine-essentials";

/**
 * Replaces all plant items in a list of items with another plant.
 * @param items The list of items
 * @param plantTemplate The new plant template
 * @returns The updated plant items. If the list container non-plant items, these
 * are excluded from the returned list.
 */
const replacePlantItems = (items: Item[], plantTemplate: PlantSchema) => {
  const newSize = { width: plantTemplate.width, height: plantTemplate.width };
  const updateItems = items.map((item: Item) => {
    if (item.type !== ItemType.PLANT) return item;
    // If size is changed, the position must be updated to keep the plant centered
    const sizeDiff = CanvasOperations.getSizeSubtracted(newSize, item.size);
    const posOffset = { x: sizeDiff.width / 2, y: sizeDiff.height / 2 };
    return {
      ...item,
      templateId: plantTemplate.id,
      size: { ...newSize },
      position: CanvasOperations.getPointSubtracted(item.position, posOffset),
    };
  });
  // Only include plant items
  return updateItems.filter((item: Item) => item.type === ItemType.PLANT);
};

/**
 * Get the item from a list based on an ID. The ID must exist otherwise an exception is thrown.
 */
const getItem = (items: Item[], id: number) => {
  const item = items.find((item: Item) => {
    return item.id === id;
  });
  if (item !== undefined) return item;
  else {
    console.error("Unable to find id", id, items);
    throw Error("Item does not exist");
  }
};

/**
 * Returns a list of items in a given layer.
 */
const getItemsInLayer = (items: Item[], layerId: number) => {
  return items.filter((item: Item) => {
    return item.layerId === layerId;
  });
};

/**
 * Returns a list of items in a given group.
 */
const getItemsInGroup = (items: Item[], groupId: number) => {
  return items.filter((item: Item) => {
    return item.groupId === groupId;
  });
};

/**
 * Get all the items that is located within the rectangle. If item has any part outside
 * of the rectangle, it is not included.
 * @param items List of items
 * @param rectangle Rectangle.
 * @param strict True if items are only included if all parts of the item is within the rectangle.
 * @param includeLockedItems True if the locked items should also be included in list of item withing marked area
 * @returns List of items within rectangle.
 */
const getItemsWithinRectangle = (
  items: Item[],
  rectangle: Rectangle,
  strict: boolean,
  includeLockedItems: boolean = false
) => {
  const upperLeft = rectangle.position;
  const lowerRight = {
    x: rectangle.position.x + rectangle.size.width,
    y: rectangle.position.y + rectangle.size.height,
  };
  return items.filter((item: Item) => {
    if (!includeLockedItems && item.locked) return false;
    const itemUpperLeft = item.position;
    const itemLowerRight = {
      x: item.position.x + item.size.width,
      y: item.position.y + item.size.height,
    };
    if (strict) {
      if (itemUpperLeft.y < upperLeft.y) return false;
      if (itemUpperLeft.x < upperLeft.x) return false;
      if (itemLowerRight.y > lowerRight.y) return false;
      if (itemLowerRight.x > lowerRight.x) return false;
      return true;
    } else {
      if (itemUpperLeft.y > lowerRight.y) return false;
      if (itemUpperLeft.x > lowerRight.x) return false;
      if (itemLowerRight.y < upperLeft.y) return false;
      if (itemLowerRight.x < upperLeft.x) return false;
      return true;
    }
  });
};

/**
 * Updates the items with matching ID. The remaining items in the list are not affected.
 */
const updateItems = (items: Item[], item: Item) => {
  return items.map((itm: Item) => {
    if (itm.id === item.id) return item;
    else return itm;
  });
};

const updateMultipleItems = (itemList: Item[], updatedItems: Item[]) => {
  const updateItemIds = updatedItems.map((item: Item) => {
    return item.id;
  });
  return itemList.map((item: Item) => {
    if (updateItemIds.includes(item.id)) {
      return findItem(updatedItems, item.id);
    } else return item;
  });
};

/**
 * Excludes some items from a list of items.
 * @param itemsList The item list.
 * @param excludingItems The items to be excluded, given by their IDs
 */
const excludeItems = (itemsList: Item[], excludingItems: number[]) => {
  return itemsList.filter((item: Item) => {
    if (excludingItems.includes(item.id)) return false;
    else return true;
  });
};

/**
 * Inserts the item in the list of items at the given index. All indexes in the list
 * are updated, and the list is returned.
 */
const insertItem = (item: Item, items: Item[]) => {
  const newItems: Item[] = [];
  const originalIndex = item.index;
  let index = 0;
  items.forEach((itm: Item) => {
    if (index === originalIndex) {
      newItems.push({ ...item, index: index++ });
    }
    // Make sure we don't get 2 identical items (if item was already in the list)
    if (itm.id !== item.id) {
      newItems.push({ ...itm, index: index++ });
    }
  });
  // Inserting at end will no be included in loop
  if (originalIndex >= index) {
    newItems.push({ ...item, index: index });
  }
  return newItems;
};

/**
 * Inserts multiple items in the list of items at the given index. All indexes in the list
 * are updated, and the list is returned.
 */
const insertItems = (itemList: Item[], insertItems: Item[]) => {
  // let newItems: Item[] = [];

  let newItems = itemList;
  insertItems.forEach((item: Item) => {
    newItems = insertItem(item, newItems);
  });
  return newItems;
};

/**
 * Removes an item from a list. All indexes in the list are updated and the
 * list is returned.
 */
const deleteItem = (item: Item, items: Item[]) => {
  const newItems: Item[] = [];
  let index = 0;
  items.forEach((itm: Item) => {
    if (itm.id !== item.id) {
      newItems.push({
        ...itm,
        index: index++,
      });
    }
  });
  return newItems;
};

/**
 * Delete multiple items from a list of items.
 */
const deleteItems = (itemList: Item[], deleteItems: Item[]) => {
  const newItems: Item[] = [];

  const deleteIds = deleteItems.map((item: Item) => {
    return item.id;
  });

  let index = 0;
  itemList.forEach((item: Item) => {
    if (!deleteIds.includes(item.id)) {
      newItems.push({
        ...item,
        index: index++,
      });
    }
  });
  return newItems;
};

const moveUp = (item: Item, zoom: number) => {
  // Round up to nearest 0.1 cm
  const diff = Math.ceil(CanvasOperations.pixelToUnit(1, zoom) * 1000) / 1000;
  const pos = {
    x: item.position.x,
    y: item.position.y - diff,
  };
  return {
    ...item,
    position: pos,
  } as Item;
};

const moveUpMultiple = (items: Item[], zoom: number) => {
  return items.map((item: Item) => {
    return moveUp(item, zoom);
  });
};

const moveDown = (item: Item, zoom: number) => {
  // Round up to nearest 0.1 cm
  const diff = Math.ceil(CanvasOperations.pixelToUnit(1, zoom) * 1000) / 1000;
  const pos = {
    x: item.position.x,
    y: item.position.y + diff,
  };
  return {
    ...item,
    position: pos,
  } as Item;
};

const moveDownMultiple = (items: Item[], zoom: number) => {
  return items.map((item: Item) => {
    return moveDown(item, zoom);
  });
};

const moveLeft = (item: Item, zoom: number) => {
  // Round up to nearest 0.1 cm
  const diff = Math.ceil(CanvasOperations.pixelToUnit(1, zoom) * 1000) / 1000;
  const pos = {
    x: item.position.x - diff,
    y: item.position.y,
  };
  return {
    ...item,
    position: pos,
  } as Item;
};

const moveLeftMultiple = (items: Item[], zoom: number) => {
  return items.map((item: Item) => {
    return moveLeft(item, zoom);
  });
};

const moveRight = (item: Item, zoom: number) => {
  // Round up to nearest 0.1 cm
  const diff = Math.ceil(CanvasOperations.pixelToUnit(1, zoom) * 1000) / 1000;
  const pos = {
    x: item.position.x + diff,
    y: item.position.y,
  };
  return {
    ...item,
    position: pos,
  } as Item;
};

const moveRightMultiple = (items: Item[], zoom: number) => {
  return items.map((item: Item) => {
    return moveRight(item, zoom);
  });
};

const findItem = (list: Item[], itemId: number) => {
  const foundItem = list.find((item: Item) => {
    return item.id === itemId;
  });
  if (foundItem !== undefined) return foundItem;
  else {
    SentryReporter.captureException("Item does not exist in list", {
      List: list,
      "Item ID": itemId,
    });
    console.error("List:", list);
    console.error("Item ID:", itemId);
    throw new Error("Item does not exist in list");
  }
};

/**
 * Find all visible plant items that is made from a specific template.
 */
const findPlantItems = (
  template: number,
  items: Item[],
  layers: Layer[],
  groups: Group[]
) => {
  return items.filter((item: Item) => {
    if (item.type === ItemType.PLANT) {
      if (item.templateId === template) {
        if (LayerOperations.isLayerVisible(item.layerId, layers)) {
          if (GroupOperations.isGroupVisible(item.groupId, groups)) {
            return true;
          }
        }
      }
    }
    return false;
  });
};

const countPlantItems = (items: Item[]) => {
  const plants = items.filter((item: Item) => {
    return item.type === ItemType.PLANT;
  });
  return plants.length;
};

const countObjectItems = (items: Item[]) => {
  const plants = items.filter((item: Item) => {
    return item.type === ItemType.OBJECT;
  });
  return plants.length;
};

const countShapeItems = (items: Item[]) => {
  const plants = items.filter((item: Item) => {
    return item.type === ItemType.SHAPE;
  });
  return plants.length;
};

/**
 * Makes sure the index property specified on each item matches the actual index position in the list.
 * @param items
 */
const fixIndexValues = (items: Item[]) => {
  return items.map((item: Item, index: number) => {
    return { ...item, index: index } as Item;
  });
};

/**
 * Sorts the items so the index position of each items matches the index property for the item. After that
 * all index properties are cleaned up.
 * @param items
 */
const sortByIndex = (items: Item[]) => {
  const sortedItems = _.cloneDeep(items);
  sortedItems.sort((a: Item, b: Item) => {
    return a.index - b.index;
  });
  return fixIndexValues(sortedItems);
};

/**
 * Get the containing item rectangle (in units) for a rectangle shape
 */
const getItemContainerFromRectangle = (
  rectangle: RectangleProperties,
  zoom: number,
  reference: Point
) => {
  const containerUnit = {
    size: CanvasOperations.pixelToUnitSize(rectangle.size, zoom),
    position: CanvasOperations.pixelToUnitPosition(
      rectangle.position,
      zoom,
      reference
    ),
  };
  return containerUnit as Rectangle;
};

/**
 * Get the rectangle shape in px based on an item container.
 * @param item
 * @param zoom
 * @param reference
 * @param padding
 */
const getRectangleFromItemContainer = (
  item: Item,
  zoom: number,
  reference: Point,
  padding: number
) => {
  const containerPx = {
    size: CanvasOperations.unitToPixelSize(item.size, zoom),
    position: CanvasOperations.unitToPixelPosition(
      item.position,
      zoom,
      reference
    ),
  };
  const rect = {
    size: CanvasOperations.getSizeSubtracted(containerPx.size, {
      width: padding * 2,
      height: padding * 2,
    }),
    position: CanvasOperations.getPointSum(containerPx.position, {
      x: padding,
      y: padding,
    }),
  };
  return rect as RectangleProperties;
};

/**
 * Get the item container that can hold all curve points and handle points.
 */
const getItemContainerFromCurves = (
  curves: Curve[],
  zoom: number,
  reference: Point,
  shapeType: ShapeType,
  padding: number
) => {
  let min = {
    x: 9999,
    y: 9999,
  };
  let max = {
    x: 0,
    y: 0,
  };
  curves.forEach((curve: Curve) => {
    if (shapeType === ShapeType.CURVED_LINE) {
      min = ShapeOperations.getMinPoint(
        min,
        ShapeOperations.getMinCurvePoint(curve)
      );
      max = ShapeOperations.getMaxPoint(
        max,
        ShapeOperations.getMaxCurvePoint(curve)
      );
    } else if (shapeType === ShapeType.LINE) {
      min = ShapeOperations.getMinPoint(min, curve.p1);
      min = ShapeOperations.getMinPoint(min, curve.p2);
      max = ShapeOperations.getMaxPoint(max, curve.p1);
      max = ShapeOperations.getMaxPoint(max, curve.p2);
    }
  });
  // Apply padding
  const paddingPoint = {
    x: padding,
    y: padding,
  };
  min = CanvasOperations.getPointSubtracted(min, paddingPoint);
  max = CanvasOperations.getPointSum(max, paddingPoint);
  const diff = CanvasOperations.getPointSubtracted(max, min);

  // // A minimum width and height are used in order to be able to select the item in case it is
  // // simply a vertical or horizontal line with width or height only 1 px
  // if (diff.x < minLimit) {
  //   const d = minLimit - diff.x;
  //   min.x = min.x - d / 2;
  //   diff.x = minLimit;
  // }
  // if (diff.y < minLimit) {
  //   const d = minLimit - diff.y;
  //   min.y = min.y - d / 2;
  //   diff.y = minLimit;
  // }

  const sizePx = {
    width: diff.x,
    height: diff.y,
  };

  return {
    position: CanvasOperations.pixelToUnitPosition(min, zoom, reference),
    size: CanvasOperations.pixelToUnitSize(sizePx, zoom),
  } as Rectangle;
};

const convertCurvesToUnit = (
  curves: CurvedLinePathProperties,
  zoom: number,
  reference: Point
) => {
  const curvesUnit = curves.curves.map((curve: Curve) => {
    return {
      p1: CanvasOperations.pixelToUnitPosition(curve.p1, zoom, reference, 3),
      p2: CanvasOperations.pixelToUnitPosition(curve.p2, zoom, reference, 3),
      h1: CanvasOperations.pixelToUnitPosition(curve.h1, zoom, reference, 3),
      h2: CanvasOperations.pixelToUnitPosition(curve.h2, zoom, reference, 3),
    } as Curve;
  });
  return {
    ...curves,
    curves: curvesUnit,
  } as CurvedLinePathProperties;
};

const convertCurvesToPx = (
  curves: CurvedLinePathProperties,
  zoom: number,
  reference: Point
) => {
  const curvesUnit = curves.curves.map((curve: Curve) => {
    return {
      p1: CanvasOperations.unitToPixelPosition(curve.p1, zoom, reference),
      p2: CanvasOperations.unitToPixelPosition(curve.p2, zoom, reference),
      h1: CanvasOperations.unitToPixelPosition(curve.h1, zoom, reference),
      h2: CanvasOperations.unitToPixelPosition(curve.h2, zoom, reference),
    } as Curve;
  });
  return {
    ...curves,
    curves: curvesUnit,
  } as CurvedLinePathProperties;
};

const addObjectItems = (
  items: Item[],
  objectItems: Item[],
  currentLayer: number,
  objectIndex: number
) => {
  const newItems: Item[] = [];
  if (objectItems.length === 0) return items;

  let addedObjectItems = 0;

  items.forEach((item: Item, index: number) => {
    if (index <= objectIndex) {
      newItems.push(item);
    } else {
      if (addedObjectItems === 0) {
        objectItems.forEach((oItem: Item, oIndex: number) => {
          newItems.push({
            ...oItem,
            index: index + oIndex,
            layerId: oItem.layerId === undefined ? currentLayer : oItem.layerId,
          });
          addedObjectItems++;
        });
      }
      newItems.push({
        ...item,
        index: index + addedObjectItems,
      });
    }
  });
  // If object index is last, it is not added yet
  if (addedObjectItems === 0) {
    const index = newItems.length;
    objectItems.forEach((oItem: Item, oIndex: number) => {
      newItems.push({
        ...oItem,
        index: index + oIndex,
        layerId: oItem.layerId === undefined ? currentLayer : oItem.layerId,
      });
      addedObjectItems++;
    });
  }

  return newItems;
};

/**
 * Check if only one item is selected and that item is a shape.
 */
const isOnlyOneShapeItemSelected = (selectedItems: Item[]) => {
  if (selectedItems.length === 1) {
    if (selectedItems[0].type === ItemType.SHAPE) {
      return true;
    }
  }
  return false;
};

/**
 * Returns true if only one item is selected and that item is either a rectangle, ellipse, line path or curved line path.
 */
const isAreaShapeSelected = (selectedItems: Item[]) => {
  if (selectedItems.length === 1) {
    if (selectedItems[0].type === ItemType.SHAPE) {
      const type = selectedItems[0].shapeProperties?.type;
      return (
        type === ShapeType.RECTANGLE ||
        type === ShapeType.ELLIPSE ||
        type === ShapeType.LINE ||
        type === ShapeType.CURVED_LINE
      );
    }
  }
  return false;
};

/**
 * Get the position of an item (meters), based on the model position (px)
 * @param modelPosition
 * @param boardOffset
 * @param zeroReference
 * @param zoom
 * @returns
 */
const getItemPosition = (
  modelPosition: Point,
  boardOffset: Point,
  zeroReference: Point,
  zoom: number
) => {
  // Must set proper initial start position in unit
  const pxCornerPos = {
    x: modelPosition.x - boardOffset.x - zeroReference.x,
    y: modelPosition.y - boardOffset.y - zeroReference.y,
  };
  // Board position so center of item is at mouse position
  const unitPosition = {
    x: CanvasOperations.pixelToUnit(pxCornerPos.x, zoom),
    y: CanvasOperations.pixelToUnit(pxCornerPos.y, zoom),
  };
  return unitPosition;
};

/**
 * Get an item object with minimal and default info about the item. Most
 * properties are supposed to be overwritten, but this is a starting point.
 */
const getEmptyItem = (type: ItemType = ItemType.PLANT, rotation?: number) => {
  const emptyItem: Item = {
    id: 0,
    position: { x: 0, y: 0 },
    index: 0,
    size: { width: 0, height: 0 },
    rotation: 0,
    type: ItemType.PLANT,
    viewBox: "0 0 100 100",
  };

  return {
    ...emptyItem,
    type: type,
    rotation: rotation || 0,
  } as Item;
};

const removeLayerAndGroup = (items: Item[]) => {
  return items.map((item: Item) => {
    return {
      ...item,
      layerId: undefined,
      groupId: undefined,
    };
  });
};

/**
 * Get the containing rectangle for a list of items.
 * @param items The list of items
 * @returns
 */
const getContainingRectangle = (items: Item[]) => {
  let min = { x: 9999, y: 9999 };
  let max = { x: -9999, y: -9999 };
  items.forEach((item: Item) => {
    min = Trig.getMinPoint(min, item.position);
    max = Trig.getMaxPoint(max, item.position);
    const itemMax = {
      x: item.position.x + item.size.width,
      y: item.position.y + item.size.height,
    };
    max = Trig.getMaxPoint(max, itemMax);
  });

  return {
    position: min,
    size: {
      width: max.x - min.x,
      height: max.y - min.y,
    },
  } as Rectangle;
};

/**
 * Get a ID based on a timestamp. This function makes it possible to create up to 100 unique IDs per ms, by
 * using the counter
 */
const getTimestampId = (counter?: number) => {
  // January 1st 2020 (no need for timestamps before this, so we can keep numbers smaller by using a reference)
  const referenceTimestamp = 1577836800000;
  const t = Date.now() - referenceTimestamp;
  const c = counter === undefined ? 0 : counter;
  return t * 100 + c;
};

export const ItemOperations = {
  replacePlantItems,
  insertItem,
  insertItems,
  deleteItem,
  deleteItems,
  getItem,
  getItemsInLayer,
  getItemsInGroup,
  getItemsWithinRectangle,
  updateItems,
  updateMultipleItems,
  excludeItems,
  moveUp,
  moveUpMultiple,
  moveDown,
  moveDownMultiple,
  moveLeft,
  moveLeftMultiple,
  moveRight,
  moveRightMultiple,
  findItem,
  findPlantItems,
  countPlantItems,
  countObjectItems,
  countShapeItems,
  fixIndexValues,
  sortByIndex,
  getItemContainerFromRectangle,
  getRectangleFromItemContainer,
  getItemContainerFromCurves,
  convertCurvesToUnit,
  convertCurvesToPx,
  addObjectItems,
  isOnlyOneShapeItemSelected,
  isAreaShapeSelected,
  getItemPosition,
  getEmptyItem,
  removeLayerAndGroup,
  getContainingRectangle,
  getTimestampId,
};
