import { useCallback, useMemo } from "react";
import { CanvasOperations, Item, ItemType, ShapeType } from "draw";
import { ShapeCreator } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  CanvasSelector,
  LayoutActions,
  PlanEditorActions,
  PlanEditorSelector,
  ObjectEditorSelector,
  ObjectEditorActions,
} from "../../store";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { Point } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";
import { Pages } from "../../enums";

export const ShapeCreatorContainer = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const currentLayersId = useAppSelector(PlanEditorSelector.getCurrentLayerId);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const objectTemplates = useAppSelector(PlanEditorSelector.getObjectTemplates);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const isAddingItem = useAppSelector(PlanEditorSelector.isAddingItem);
  const isEnteringText = useAppSelector(CanvasSelector.isEnteringText);
  const visibleCanvasRectangle = useAppSelector(
    CanvasSelector.getVisibleRectangle
  );

  const selectedShapeTool = useAppSelector(CanvasSelector.getSelectedShapeTool);
  const delimiter = I18nTools.getDelimiter(user.language);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  /**
   * A selected shape item or null.
   */
  const item = useMemo(() => {
    if (selectedItems.length === 1) {
      if (selectedItems[0].type === ItemType.SHAPE) {
        return selectedItems[0];
      }
    }
    return null;
  }, [selectedItems]);

  /**
   * The shape type of the selected shape item, or the selected shape tool (if no shape item is selected)
   */
  const itemShapeType = useMemo(() => {
    if (item !== null) {
      if (item.shapeProperties) {
        return item.shapeProperties.type;
      }
    }
    return selectedShapeTool;
  }, [selectedShapeTool, item]);

  const updateItem = useCallback(
    (item: Item) => {
      if (selectedShapeTool !== ShapeType.NONE) {
        // New item should be created
        if (objectMode) {
          dispatch(ObjectEditorActions.addItem(item));
        } else {
          dispatch(PlanEditorActions.addItem(item));
        }
        dispatch(LayoutActions.setActiveTab("item-tab"));
        dispatch(CanvasActions.setSelectedShapeTool(ShapeType.NONE));
      } else {
        // Update existing item
        if (objectMode) {
          dispatch(ObjectEditorActions.updateItem(item));
        } else {
          dispatch(PlanEditorActions.updateItem(item));
        }
      }
    },
    [dispatch, objectMode, selectedShapeTool]
  );

  const closeCreator = () => {};

  const openItemContextMenu = (position: Point) => {
    const pos = CanvasOperations.getPointSubtracted(position, canvasOffset);
    dispatch(CanvasActions.openContextMenu(pos));
  };

  const closeContextMenu = () => {
    dispatch(CanvasActions.closeContextMenu());
  };

  const setEnteringText = (enteringText: boolean) => {
    dispatch(CanvasActions.setEnteringText(enteringText));
  };

  const cursorOverride = useMemo(() => {
    if (selectedShapeTool !== ShapeType.NONE) {
      return "crosshair";
    }
    return "";
  }, [selectedShapeTool]);

  const openTextShapeModal = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.TEXT_SHAPE_TEXT));
  };

  return (
    <ShapeCreator
      shapeType={itemShapeType}
      item={item}
      onItemChange={updateItem}
      onFinish={closeCreator}
      onClick={closeContextMenu}
      onRightClick={openItemContextMenu}
      zoom={zoom}
      zeroReference={zeroReference}
      canvasOffset={canvasOffset}
      snapEnabled={userPreferences.snap}
      cursor={cursorOverride}
      delimiter={delimiter}
      unitScale={userPreferences.unitScale}
      onEditText={openTextShapeModal}
      visibleRectangle={visibleCanvasRectangle}
    />
  );
};
