import React, { useMemo } from "react";
import { Device, Icon } from "imagine-ui";
// import '../../styling/navigation.css';

type Props = {
  text?: string;
  icon?: string | React.ReactNode;
  selected?: boolean;
  disabled?: boolean;
  elementId?: string;
  half?: boolean; // Only half height
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string; // Additional class on container div
};

/**
 * This displays a button customized for the header.
 * @param props
 */
export const ToolButton = (props: Props) => {
  const isTouchDevice = Device.isTouchDevice();
  const className = useMemo(() => {
    let className = "tool-button";
    if (props.half) {
      className += " half";
    }
    if (!props.icon) {
      className += " text-line";
    }
    if (props.selected) {
      className += " selected";
    }
    if (props.disabled) {
      className += " disabled";
    }
    return className;
  }, [props.disabled, props.half, props.icon, props.selected]);

  const handleClick = () => {
    if (props.disabled || isTouchDevice) return;
    if (props.onClick) {
      props.onClick();
    }
  };

  const handleTouch = (event: any) => {
    if (props.disabled) return;
    if (props.onClick) {
      props.onClick();
    }
  };

  const fittedText = useMemo(() => {
    if (props.text === undefined) return "";
    if (props.half) return props.text;
    const words = props.text.split(" ");
    if (words.length === 0) return "";
    if (words.length === 1) return words[0];
    if (words.length === 2) return words[0] + "<br />" + words[1];
    if (words.length === 3) {
      if (words[0].length < words[2].length) {
        return words[0] + " " + words[1] + "<br />" + words[2];
      } else {
        return words[0] + "<br />" + words[1] + " " + words[2];
      }
    }
    if (words.length === 4) {
      return words[0] + " " + words[1] + "<br />" + words[2] + " " + words[3];
    }
    return words[0] + " " + words[1] + "<br />" + words[2] + " " + words[3];
  }, [props.text, props.half]);

  const getIcon = () => {
    if (props.icon) {
      if (typeof props.icon === "string") {
        return <Icon name={props.icon} />;
      }
      return props.icon;
    }
  };

  return (
    <div
      className={
        "tool-container" + (props.className ? " " + props.className : "")
      }
    >
      <button
        id={props.elementId}
        className={className}
        onClick={handleClick}
        onTouchStart={handleTouch}
      >
        <>
          {props.icon && <div className="button-icon">{getIcon()}</div>}
          {props.text && (
            <div
              className="button-text"
              dangerouslySetInnerHTML={{ __html: fittedText }}
            ></div>
          )}
        </>
      </button>
      {props.children}
    </div>
  );
};
