import {
  useAppDispatch,
  useAppSelector,
  LayoutSelector,
  LayoutActions,
  ObjectEditorSelector,
  ObjectEditorActions,
} from "../../store";
import { ObjectsApi } from "draw";
import { useState } from "react";
import { Pages } from "../../enums";
import { SaveObjectModal } from "../../components";
import { useTranslation } from "react-i18next";
import { NotificationType, useNotification } from "imagine-ui";

/**
 * Handles the saving of a new object to the database, together with setting the name and category.
 */
export const SaveObjectModalContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { t } = useTranslation();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const [loading, setLoading] = useState(false);

  const name = useAppSelector(ObjectEditorSelector.getName);
  const category = useAppSelector(ObjectEditorSelector.getCategory);

  const currentObjectTemplate = useAppSelector(
    ObjectEditorSelector.getCurrentObjectTemplate
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  /**
   * Saves a new object to the database. This can either be a completely new obejct or a copy of an existing object.
   * @param name
   * @param category
   */
  const saveNew = async (name: string, category: number) => {
    setLoading(true);
    const updatedTemplate = {
      ...currentObjectTemplate,
      name: name,
      category: category,
    };
    const response = await ObjectsApi.addObjectTemplate(updatedTemplate);
    if (response.success && response.data) {
      dispatch(ObjectEditorActions.setSavedObjectTemplate(response.data));
      dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    } else {
      notification.add(NotificationType.ERROR, t("objectSaveFailed"));
      dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    }
    setLoading(false);
  };

  return (
    <>
      {displayedPage === Pages.SAVE_OBJECT}
      <SaveObjectModal
        name={name}
        category={category}
        onClose={close}
        onSave={saveNew}
        loading={loading}
      />
    </>
  );
};
