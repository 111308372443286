import { SentryReporter } from "imagine-essentials";
import { Group } from "..";

const defaultGroups: Group[] = [];

const getDefaultGroups = () => {
  return defaultGroups;
};

/**
 * Get the group from a list based on an ID. The ID must exist otherwise an exception is thrown.
 */
const getGroup = (groups: Group[], id: number) => {
  const group = groups.find((group: Group) => {
    return group.id === id;
  });
  if (group !== undefined) return group;
  else {
    SentryReporter.captureException("Unable to find group with id", {
      id: id,
      groups: JSON.stringify(groups),
    });
    return undefined;
  }
};

/**
 * Updates the groups with matching ID. The remaining groups in the list are not affected.
 */
const updateGroups = (groups: Group[], group: Group) => {
  return groups.map((g: Group) => {
    if (g.id === group.id) return group;
    else return g;
  });
};

/**
 * Inserts the group in the list of layers at the given index. All indexes in the list
 * are updated, and the list is returned. If the list already contains a group with the
 * same ID as the inserted group, it will be overwritten.
 */
const insertGroup = (group: Group, groups: Group[]) => {
  let newGroups: Group[] = [];
  const originalIndex = group.index;
  let index = 0;
  groups.forEach((g: Group) => {
    if (index === originalIndex) {
      newGroups.push({ ...group, index: index++ });
    }
    // Make sure we don't get 2 identical groups (if group was already in the list)
    if (g.id !== group.id) {
      newGroups.push({ ...g, index: index++ });
    }
  });
  // Inserting at end will no be included in loop
  if (originalIndex >= index) {
    newGroups.push({ ...group, index: index });
  }
  return newGroups;
};

/**
 * Removes a group from a list. All indexes in the list are updated and the
 * list is returned.
 */
const deleteGroup = (groupId: number, groups: Group[]) => {
  const newGroups: Group[] = [];
  let index = 0;
  groups.forEach((g: Group) => {
    if (g.id !== groupId) {
      newGroups.push({
        ...g,
        index: index++,
      });
    }
  });
  return newGroups;
};

/**
 * Checks if a group is currently visible. If group id is undefined it will return true.
 */
const isGroupVisible = (groupId: number | undefined, groups: Group[]) => {
  // It's perfectly valid not to belong to a group - will then always be visible
  if (groupId === undefined) {
    return true;
  }
  const group = groups.find((group: Group) => {
    return group.id === groupId;
  });
  if (group === undefined) {
    console.error(
      "Cannot find item group " + groupId + ". Maybe it has been deleted."
    );
    return true;
  }
  return group.visible;
};

const getNextGroupId = (groups: Group[]) => {
  const groupIds = groups.map((group: Group) => group.id);
  let idAvailable = false;
  let id = 0;
  do {
    id++;
    if (!groupIds.includes(id)) {
      idAvailable = true;
    }
  } while (!idAvailable && id < 100);
  return id;
};

export const GroupOperations = {
  getDefaultGroups,
  insertGroup,
  deleteGroup,
  getGroup,
  updateGroups,
  isGroupVisible,
  getNextGroupId,
};
