import { CanvasOperations, Mouse, MouseState } from "draw";
import { Point, Rectangle, Trig } from "imagine-essentials";
import { useCallback, useEffect, useState } from "react";

interface Props {
  zoom: number;
  zeroReference: Point;
  canvasOffset: Point;
  onChange: (rectangle: Rectangle | null) => void; // The marked rectangle in meters
  onFinish: (rectangle: Rectangle) => void; // The marked rectangle in meters
}

export const MarkedRectangle = (props: Props) => {
  const [rectangleStartPoint, setRectangleStartPoint] = useState<
    Point | undefined
  >(undefined);

  const { onChange, onFinish } = props;

  // The rectangle that is being drawn (in pixels)
  const [rectangle, setRectangle] = useState<Rectangle | null>(null);

  const startMarkingRectangle = useCallback((point: Point) => {
    setRectangleStartPoint(point);
  }, []);

  const getRectangleUnit = useCallback(
    (rectanglePx: Rectangle) => {
      // Get the rectange area in meters to calculate which items are within this area
      const reference = props.zeroReference;
      const unitPosition = CanvasOperations.pixelToUnitPosition(
        rectanglePx.position,
        props.zoom,
        reference,
        3
      );
      const unitSize = CanvasOperations.pixelToUnitSize(
        rectanglePx.size,
        props.zoom,
        3
      );
      return {
        position: unitPosition,
        size: unitSize,
      };
    },
    [props.zeroReference, props.zoom]
  );

  const drawMarkedRectangle = useCallback(
    (point: Point) => {
      if (rectangleStartPoint === undefined) return;

      const position = Trig.getPointSubtracted(
        {
          x: Math.min(rectangleStartPoint.x, point.x),
          y: Math.min(rectangleStartPoint.y, point.y),
        },
        props.canvasOffset
      );
      const size = {
        width: Math.abs(rectangleStartPoint.x - point.x),
        height: Math.abs(rectangleStartPoint.y - point.y),
      };
      setRectangle({ position: position, size: size });
      // Get the rectange area in meters to calculate which items are within this area
      const rectangleUnit = getRectangleUnit({
        position: position,
        size: size,
      });
      onChange(rectangleUnit);
    },
    [getRectangleUnit, onChange, props.canvasOffset, rectangleStartPoint]
  );

  const releaseMarkedRectangle = useCallback(() => {
    if (rectangleStartPoint !== undefined) {
      if (rectangle !== null) {
        const rectangleUnit = getRectangleUnit(rectangle);
        onFinish(rectangleUnit);
      }
      setRectangleStartPoint(undefined);
      setRectangle(null);
    }
  }, [getRectangleUnit, onFinish, rectangle, rectangleStartPoint]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        drawMarkedRectangle(state.position);
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      if (
        state.elementClass === "board" ||
        state.elementClass === "canvas" ||
        state.elementClass === "area" ||
        state.elementClass === "static-reference-image" ||
        (state.elementClass.includes("locked") &&
          state.elementClass.includes("static-item"))
      ) {
        startMarkingRectangle(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      releaseMarkedRectangle();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [drawMarkedRectangle, releaseMarkedRectangle, startMarkingRectangle]);

  return (
    <>
      {rectangle && (
        <rect
          x={rectangle.position.x}
          y={rectangle.position.y}
          width={rectangle.size.width}
          height={rectangle.size.height}
          fill="rgba(0, 0, 0, 0.1)"
        />
      )}
    </>
  );
};
