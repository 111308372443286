import { useCallback, useEffect } from "react";
import {
  CanvasActions,
  CanvasSelector,
  LayoutActions,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { EventProcess, Item, ItemOperations } from "draw";
import { NotificationType, useNotification } from "imagine-ui";
import { PlanThunks } from "../../utils";
import { useTranslation } from "react-i18next";
import { ObjectThunks } from "../../utils/ObjectThunks";
import { UserRole, UserSelector } from "imagine-users";
import { Pages } from "../../enums";
import { UserHelpers, UserPreferences } from "project";

/**
 * This container handles all the events that are triggered by a timer.
 * @returns
 */
export const TimedEventContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const { t } = useTranslation();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const planSettingsCanvas = useAppSelector(CanvasSelector.getPlanSettings);
  const planSettingsPlanEditor = useAppSelector(
    PlanEditorSelector.getPlanSettings
  );
  const currentObjectTemplate = useAppSelector(
    ObjectEditorSelector.getCurrentObjectTemplate
  );
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);

  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const unsavedPlanChanges = useAppSelector(
    PlanEditorSelector.hasUnsavedChanges
  );
  const unsavedObjectChanges = useAppSelector(
    ObjectEditorSelector.hasUnsavedChanges
  );

  const savePlan = useCallback(async () => {
    // Never try to save if user does not have permission
    if (user.role === UserRole.NONE || user.role === UserRole.STANDARD) return;
    // Never try to save if no changes have been made
    if (!unsavedPlanChanges) return;
    // Never try to save if user is not the owner of the plan
    if (planInfo.userId !== user.id) return;
    // Never try to save if plan is not initially saved with a name
    if (planInfo.id === 0) return;

    console.log("Auto saving plan", planInfo);
    if (planInfo.id === 0) {
      dispatch(LayoutActions.setDisplayedPage(Pages.SAVE_PLAN));
      return;
    }

    const planSettings = {
      ...planSettingsCanvas,
      ...planSettingsPlanEditor,
    };

    // Error message is not shown to the user, since this is an auto-save happening in the background
    await PlanThunks.savePlan(
      dispatch,
      planInfo,
      layers,
      groups,
      items,
      area,
      planSettings,
      referenceImage,
      false
    );
  }, [
    area,
    dispatch,
    groups,
    items,
    layers,
    notification,
    planInfo,
    planSettingsCanvas,
    planSettingsPlanEditor,
    t,
  ]);

  const saveObject = useCallback(async () => {
    // Never try to save if user does not have permission
    if (user.role === UserRole.NONE || user.role === UserRole.STANDARD) return;
    // Never try to save if no changes have been made
    if (!unsavedObjectChanges) return;
    // Never try to save if user is not the owner of the object
    if (currentObjectTemplate.userId !== user.id) return;
    // Never try to save if object is not initially saved with a name
    if (currentObjectTemplate.id === 0) return;
    console.log("Auto saving object");
    await ObjectThunks.saveObject(dispatch, currentObjectTemplate, false);
  }, [currentObjectTemplate, dispatch, notification]);

  useEffect(() => {
    const autoSaveInterval = setInterval(() => {
      if (userPreferences.enableAutoSave) {
        if (user.role === UserRole.NONE || user.role === UserRole.STANDARD)
          return;
        if (objectMode) {
          saveObject();
        } else {
          savePlan();
        }
      }
    }, 1000 * 60 * 5); // 5 minutes

    return () => {
      clearInterval(autoSaveInterval);
    };
  }, [
    dispatch,
    objectMode,
    saveObject,
    savePlan,
    userPreferences.enableAutoSave,
  ]);

  return null;
};
