import { useCallback, useMemo, useState } from "react";
import {
  BonusDays,
  CancelReason,
  CancelSubscriptionConfirm,
  Membership,
  MembershipDeal,
  MembershipProduct,
  MembershipProductCard,
  MembershipType,
} from "..";
import { UserRole } from "imagine-users";
import { useTranslation } from "react-i18next";
import { Button, Modal } from "imagine-ui";
import { DateTimeTools } from "imagine-datetime";
import { MembershipSummary } from "./MembershipSummary";
import { BonusDaysInfoCard } from "./BonusDaysInfoCard";

type Props = {
  className?: string;
  membership?: Membership;
  membershipDeal?: MembershipDeal;
  continuationMembershipDeal?: MembershipDeal;
  bonusDays?: BonusDays[];
  membershipProducts?: MembershipProduct[];
  membershipHistory?: Membership[];
  fallbackMembershipProductType: MembershipType; // The membership product to display if no membership is found
  hideProduct?: boolean;
  userRole?: UserRole;
  onCancelSubscription: (reasons: CancelReason[], comment: string) => void;
};

/**
 * The page displays the current membership with expiry date
 */
export const MembershipInfo = (props: Props) => {
  const { t } = useTranslation();

  // When member cancels a subscription
  const [
    showCancelSubscriptionConfirmation,
    setShowCancelSubscriptionConfirmation,
  ] = useState(false);

  const [cancelReason, setCancelReason] = useState<CancelReason[]>([]);
  const [cancelComment, setCancelComment] = useState("");

  const membership = useMemo(() => {
    if (props.membership === undefined) return null;
    if (props.membership.id !== undefined) return props.membership;
    return null;
  }, [props.membership]);

  const findMembershipProduct = useCallback(
    (type: MembershipType) => {
      if (props.membershipProducts === undefined) return undefined;
      return props.membershipProducts.find((product: MembershipProduct) => {
        return product.type === type;
      });
    },
    [props.membershipProducts]
  );

  /**
   * Hold a membership with start date later than today. Undefined if no such membership exists.
   */
  const futureMembership = useMemo(() => {
    if (props.membershipHistory === undefined) return undefined;
    return props.membershipHistory.find((membership: Membership) => {
      const start = DateTimeTools.getDateFromDbFormat(membership.startDate);
      const today = new Date();
      return start.getTime() > today.getTime();
    });
  }, [props.membershipHistory]);

  const initiateDeactiveAutoRenewal = async () => {
    setShowCancelSubscriptionConfirmation(true);
  };

  const deactivateAutoRenewal = async () => {
    setShowCancelSubscriptionConfirmation(false);
    props.onCancelSubscription(cancelReason, cancelComment);
  };

  /**
   * Find the membership product that matches the current membership.
   */
  const membershipProduct = useMemo(() => {
    if (props.membership === undefined)
      return findMembershipProduct(props.fallbackMembershipProductType);
    return findMembershipProduct(props.membership.type);
  }, [
    findMembershipProduct,
    props.fallbackMembershipProductType,
    props.membership,
  ]);

  const hasBonusDays = () => {
    if (props.bonusDays === undefined) return false;
    else return props.bonusDays.length > 0;
  };

  return (
    <div id="membership-info" className={props.className}>
      {membershipProduct !== undefined && membership !== null && (
        <>
          <div className="grid columns-3 columns-2--lg columns-1--md gap-xl">
            <div className="">
              <MembershipProductCard
                membershipProduct={membershipProduct}
                membership={props.membership}
                hideButton
                elementId="active-membership"
                membershipDeal={props.membershipDeal}
                continuationMembershipDeal={props.continuationMembershipDeal}
              />
            </div>

            <div className="colspan-2 colspan-1--lg">
              <MembershipSummary
                membership={membership}
                renewed={futureMembership !== undefined}
                bonusDays={props.bonusDays ?? []}
              />

              {futureMembership !== undefined && (
                <div className="hightlighted-area mt-5">
                  <p className="text-info fst-italic small-font">
                    {t("memberships:replacingMembership")}:
                  </p>
                  <MembershipSummary
                    membership={futureMembership}
                    bonusDays={props.bonusDays ?? []}
                  />
                </div>
              )}

              {membership.renew && membership.expiryDate !== null && (
                <Button
                  className="mt"
                  onClick={initiateDeactiveAutoRenewal}
                  color="danger"
                  outline
                >
                  {t("memberships:cancelSubscription")}
                </Button>
              )}
            </div>
          </div>
          {hasBonusDays() && (
            <div className="">
              <h2 className="mt-xl">{t("memberships:bonusDays")}</h2>
              <p className="text-info text-italic text-sm my">
                {t("bonusDaysExplanation")}
              </p>
              {props.bonusDays!.map((bonus: BonusDays, index: number) => (
                <BonusDaysInfoCard
                  bonus={bonus}
                  className="mb"
                  key={index.toString()}
                />
              ))}
            </div>
          )}
        </>
      )}
      {showCancelSubscriptionConfirmation && (
        <Modal
          visible
          title={t("memberships:confirmCancelSubscription")}
          confirmText={t("memberships:cancelSubscription")}
          onClose={() => setShowCancelSubscriptionConfirmation(false)}
          onConfirm={deactivateAutoRenewal}
          size="lg"
          discreteHeader
          color="danger"
        >
          <CancelSubscriptionConfirm
            reason={cancelReason}
            onReasonChanged={setCancelReason}
            comment={cancelComment}
            onCommentChanged={setCancelComment}
          />
        </Modal>
      )}
    </div>
  );
};
