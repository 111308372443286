import React, { useMemo } from "react";
import { ColorUtils } from "..";
import { ColorInput as ImagineColorInput } from "imagine-ui";
import { Colors } from "project";

type ColorInputProps = {
  color: string | undefined;
  onChange?: (hex: string) => void;
  className?: string;
  elementId?: string;
};

/**
 * A wrapper for the ColorInput from imagine-ui. This one has the Garden Sketcher colors pre-defined.
 * @param props
 * @returns
 */
export const ColorInput = (props: ColorInputProps) => {
  const colorText = useMemo(() => {
    const colorGroup = ColorUtils.getColorGroup(props.color);
    return ColorUtils.getColorGroupText(colorGroup);
  }, [props.color]);

  return (
    <ImagineColorInput
      color={props.color}
      onChange={props.onChange}
      colors={Colors.allColors}
      text={colorText}
      className={props.className}
      elementId={props.elementId}
    />
  );
};
