import { useCallback, useEffect, useMemo, useState } from "react";
import { Point, Size, Tools } from "imagine-essentials";
import { CanvasOperations, EventProcess, Mouse, MouseState } from "draw";

const snapAngles = [0, 45, 90, 135, 180, -45, -90, -135, -180];
const snapLimit = 5;

const snapAngle = (angle: number) => {
  let snappedAngle = angle;
  snapAngles.forEach((snapAngle: number) => {
    if (angle > snapAngle - snapLimit && angle < snapAngle + snapLimit) {
      snappedAngle = snapAngle;
      return;
    }
  });
  return snappedAngle;
};

interface Props {
  position: Point; // Px
  size: Size; // Px
  rotation: number; // Angle
  onRotationStarted: () => void;
  onRotationChanged: (rotation: number) => void;
  onRotationFinished: (rotation: number) => void;
  snap: boolean;
  canvasOffset: Point;
}

const angleBetweenVectors = (a: Point, b: Point) => {
  const num = a.x * b.x + a.y * b.y;
  const denum =
    Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2)) *
    Math.sqrt(Math.pow(b.x, 2) + Math.pow(b.y, 2));
  return Math.acos(num / denum);
};

/**
 * Displays a SVG wrapper element containing all the passed shapes. The last shape
 * in the array is drawn on top. The viewbox is always 100x100 for plants.
 * @param props
 */
export const RotateHandle = (props: Props) => {
  const [rotating, setRotating] = useState(false);
  const [angle, setAngle] = useState(props.rotation);
  const [startPos, setStartPos] = useState<Point | null>(null);
  const [startHandlePos, setStartHandlePos] = useState<Point | null>(null);

  const { onRotationStarted, onRotationChanged, onRotationFinished } = props;

  // Get center of the item
  const getAbsoluteCenterPos = useCallback(() => {
    const center = {
      x: props.position.x + props.size.width / 2,
      y: props.position.y + props.size.height / 2,
    };
    return center;
  }, [props.position, props.size]);

  // Get position of the handle based on a rotation angle
  const getHandlePos = useCallback(
    (angle: number) => {
      const length = props.size.height / 2 + 30;
      const center = {
        x: props.position.x + props.size.width / 2,
        y: props.position.y + props.size.height / 2,
      };

      const x = -Math.sin(Tools.degreesToRadians(angle + 180)) * length;
      const y = Math.cos(Tools.degreesToRadians(angle + 180)) * length;
      return {
        x: center.x + x,
        y: center.y + y,
      };
    },
    [props.position, props.size]
  );

  const getVector = useCallback(
    (point: Point) => {
      if (startPos === null) return { x: 0, y: 0 };
      const center = getAbsoluteCenterPos();
      const pointRelative = CanvasOperations.getPointSubtracted(
        point,
        props.canvasOffset,
        1
      );
      const vector = CanvasOperations.getPointSubtracted(
        pointRelative,
        center,
        1
      );
      return vector;
    },
    [props.canvasOffset, getAbsoluteCenterPos, startPos]
  );

  /**
   * Get rotation angle based on curent mouse position.
   */
  const getAngle = useCallback(
    (position: Point) => {
      if (startPos === null) return 0;
      const startVector = { x: 0, y: -100 }; //getVector(startPos);
      const handleVector = getVector(position);

      let angle = angleBetweenVectors(startVector, handleVector);
      if (handleVector.x < 0) angle = -angle;
      if (props.snap) {
        return snapAngle(Tools.radiansToDegrees(angle));
      } else {
        return Tools.radiansToDegrees(angle);
      }
    },
    [getVector, props.snap, startPos]
  );

  // const [handlePos, setHandlePos] = useState<Point>(
  //   getHandlePos(props.rotation)
  // );

  const handlePos = useMemo(() => {
    return getHandlePos(angle);
  }, [angle, getHandlePos]);

  const grabHandle = (event: any) => {
    event.stopPropagation();
    setRotating(true);
    const pos = EventProcess.getEventPosition(event);
    setStartPos(pos);
    setStartHandlePos(getHandlePos(props.rotation));
    props.onRotationStarted();
    // event.stopPropagation();
    // props.onMousePressedChanged(true);
  };

  const rotate = useCallback(
    (position: Point) => {
      if (startPos !== null) {
        const a = getAngle(position);
        setAngle(a);
        // setHandlePos(getHandlePos(a));
        onRotationChanged(a);
      }
    },
    [getAngle, onRotationChanged, startPos]
  );

  const release = useCallback(() => {
    if (rotating) {
      setRotating(false);
      setStartPos(null);
      setStartHandlePos(null);
      onRotationFinished(angle);
    }
  }, [angle, onRotationFinished, rotating]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed && rotating) {
        rotate(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      release();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [release, rotate, rotating]);

  // useEffect(() => {
  //   if (!props.mouse.pressed) release();
  // }, [props.mouse.pressed]);

  // useEffect(() => {
  //   if (rotating) {
  //     rotate();
  //   }
  // }, [props.mouse.position]);

  // useEffect(() => {
  //   setHandlePos(getHandlePos(props.rotation));
  // }, [props.position, props.size, props.rotation]);

  return (
    <>
      {props.size.width > 0 && (
        <svg
          x={handlePos.x - 12}
          y={handlePos.y - 12}
          viewBox={"0 0 24 24"}
          width={24}
          height={24}
          preserveAspectRatio="none"
          onMouseDown={grabHandle}
          onTouchStart={grabHandle}
          onMouseUp={release}
          onTouchEnd={release}
          className="rotation-handle"
          id="item-rotation-handle"
        >
          <path
            strokeWidth="0.5"
            fill="white"
            stroke="black"
            fillOpacity={rotating ? "0" : "1"}
            strokeOpacity={rotating ? "0" : "1"}
            d="M11.52,3.43A9.09,9.09,0,0,0,5.7,5.55V2.35H4.07v6.5h6.5V7.21H6.3a7.46,7.46,0,1,1-1.47,8.65l-1.46.73A9.11,9.11,0,1,0,11.52,3.43Z"
          ></path>
          <rect
            x={0}
            y={0}
            width={24}
            height={24}
            fill={"red"}
            fillOpacity="0"
            className="rotate-handle"
            cursor="grab"
          ></rect>
        </svg>
      )}
    </>
  );
};
