import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import * as Sentry from "@sentry/react";
import "./style/main.scss";
import { Provider } from "react-redux";
import { store } from "./store/store.ts";
import ReactPixel from "react-facebook-pixel";

const alwaysReport = false;

const config = {
  production: import.meta.env.PROD,
  development: import.meta.env.DEV,
  localHost:
    window.location.hostname === "localhost" ||
    window.location.hostname === process.env.DEV_HOST,
};

if ((config.production && !config.localHost) || alwaysReport) {
  Sentry.init({
    dsn: "https://a090fa21f82843e4b6af0d73b5bcaeff@o1077038.ingest.sentry.io/6080582",
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.replayIntegration({
        // Additional SDK configuration goes in here, for example:
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });

  ReactPixel.init("400794839645425");
} else if (config.production) {
  ReactPixel.init("800248445570396");
}

/**
 * Strict mode causes the App to render twice. This is only an issue in dev mode
 * because it does some extra checks.
 */
ReactDOM.createRoot(document.getElementById("root")!).render(
  // <React.StrictMode>
  <Provider store={store}>
    <App />
  </Provider>
  // </React.StrictMode>
);
