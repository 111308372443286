import { t } from "i18next";
import { PlantCollectionType } from "../enums";
import { PlantProperty } from "../types";
import { HardinessZoneRHS, HardinessZoneUSDA } from "project";
import { PlantSchema } from "draw";
import { DateTimeText } from "imagine-datetime";

/**
 * Get the hybrid name in single quotes. If plant has not hybrid name it will return an empty string.
 * @param plant
 * @returns
 */
const getFormattedHybridText = (plant: PlantSchema) => {
  if (plant.hybridName && plant.hybridName !== "") {
    return "'" + plant.hybridName + "'";
  } else return "";
};

const getLatinName = (plant: PlantSchema) => {
  if (plant.latinName) return plant.latinName;
  return "";
};

/**
 * Get both latin name and hybrid name formatted in single quotes. If plant has no hybrid name, it will
 * be omitted.
 * @param plant
 * @returns
 */
const getLatinAndHybridName = (plant: PlantSchema) => {
  return getLatinName(plant) + " " + getFormattedHybridText(plant);
};

/**
 * Get the full name of a plant, include common name, latin name and hybrid name.
 * @param plant
 * @returns
 */
const getFullName = (plant: PlantSchema) => {
  return plant.name + ", " + getLatinAndHybridName(plant);
};

const getPlantCollectionTypeText = (type: PlantCollectionType) => {
  switch (type) {
    case PlantCollectionType.NONE:
      return t("plants:noCollectionType");
    case PlantCollectionType.GOES_WELL_TOGETHER:
      return t("plants:plantsGoesWellTogether");
    case PlantCollectionType.GARDEN_BED:
      return t("plants:gardenBed");
    case PlantCollectionType.FAVORITES:
      return t("plants:favoritePlants");
    default:
      return t("imagine:unknown");
  }
};

const getPlantPropertyText = (property: PlantProperty) => {
  switch (property) {
    case "sun":
      return t("plants:sun");
    case "semiShade":
      return t("plants:semiShade");
    case "shade":
      return t("plants:shade");
    case "fallColors":
      return t("plants:interestingFallColors");
    case "groundcover":
      return t("plants:groundcover");
    case "evergreen":
      return t("plants:evergreen");
    case "wind":
      return t("plants:windTolerant");
    case "bees":
      return t("plants:attractsBees");
    case "birds":
      return t("plants:goodForBirds");
    case "edible":
      return t("plants:edibleFruitsBerryEtc");
    case "allergyFriendly":
      return t("plants:allergyFriendly");
    case "coast":
      return t("plants:coastalArea");
    case "cutFlowers":
      return t("plants:cutFlower");
    case "nitrogen":
      return t("plants:nitrogenFixating");
    case "carbon":
      return t("plants:carbonFarming");
    case "poisonous":
      return t("plants:poisonous");
    case "drySoil":
      return t("plants:drySoil");
    case "drainedSoil":
      return t("plants:wellDrainedSoil");
    case "wetSoil":
      return t("plants:wetSoil");
    case "waterPlant":
      return t("plants:waterPlant");
    case "fragrant":
      return t("plants:fragrant");
    case "winter":
      return t("plants:winterInterest");
    case "thorns":
      return t("plants:thorns");
    case "acidSoil":
      return t("plants:acidSoil");
    case "alkalicSoil":
      return t("plants:alkalicSoil");
    case "neutralSoil":
      return t("plants:neutralSoil");
    case "sandySoil":
      return t("plants:sandySoil");
    case "loamSoil":
      return t("plants:loamySoil");
    case "claySoil":
      return t("plants:claySoil");
    case "rootSpreading":
      return t("plants:speadingByRoots");
    case "seedSpreading":
      return t("plants:speadingBySeeds");
    case "native":
      return t("plants:native");
    case "pots":
      return t("plants:pots");
    case "hedge":
      return t("plants:hedge");
    case "specialCare":
      return t("plants:specialCare");
    default:
      console.warn("Unsupported plant property", property);
      return "";
  }
};

const getHardinessZoneTextUSDA = (
  zone: HardinessZoneUSDA,
  emptyText: string
) => {
  switch (zone) {
    case HardinessZoneUSDA.ZONE_1A:
      return "1a";
    case HardinessZoneUSDA.ZONE_1B:
      return "1b";
    case HardinessZoneUSDA.ZONE_2A:
      return "2a";
    case HardinessZoneUSDA.ZONE_2B:
      return "2b";
    case HardinessZoneUSDA.ZONE_3A:
      return "3a";
    case HardinessZoneUSDA.ZONE_3B:
      return "3b";
    case HardinessZoneUSDA.ZONE_4A:
      return "4a";
    case HardinessZoneUSDA.ZONE_4B:
      return "4b";
    case HardinessZoneUSDA.ZONE_5A:
      return "5a";
    case HardinessZoneUSDA.ZONE_5B:
      return "5b";
    case HardinessZoneUSDA.ZONE_6A:
      return "6a";
    case HardinessZoneUSDA.ZONE_6B:
      return "6b";
    case HardinessZoneUSDA.ZONE_7A:
      return "7a";
    case HardinessZoneUSDA.ZONE_7B:
      return "7b";
    case HardinessZoneUSDA.ZONE_8A:
      return "8a";
    case HardinessZoneUSDA.ZONE_8B:
      return "8b";
    case HardinessZoneUSDA.ZONE_9A:
      return "9a";
    case HardinessZoneUSDA.ZONE_9B:
      return "9b";
    case HardinessZoneUSDA.ZONE_10A:
      return "10a";
    case HardinessZoneUSDA.ZONE_10B:
      return "10b";
    case HardinessZoneUSDA.ZONE_11A:
      return "11a";
    case HardinessZoneUSDA.ZONE_11B:
      return "11b";
    case HardinessZoneUSDA.ZONE_12A:
      return "12a";
    case HardinessZoneUSDA.ZONE_12B:
      return "12b";
    case HardinessZoneUSDA.ZONE_13A:
      return "13a";
    case HardinessZoneUSDA.ZONE_13B:
      return "13b";
    default:
      return emptyText;
  }
};

const hardinessZoneUSDAtoRHS = (zone: HardinessZoneUSDA) => {
  switch (zone) {
    case HardinessZoneUSDA.ZONE_1A:
    case HardinessZoneUSDA.ZONE_1B:
    case HardinessZoneUSDA.ZONE_2A:
    case HardinessZoneUSDA.ZONE_2B:
    case HardinessZoneUSDA.ZONE_3A:
    case HardinessZoneUSDA.ZONE_3B:
    case HardinessZoneUSDA.ZONE_4A:
    case HardinessZoneUSDA.ZONE_4B:
    case HardinessZoneUSDA.ZONE_5A:
    case HardinessZoneUSDA.ZONE_5B:
    case HardinessZoneUSDA.ZONE_6A:
      return HardinessZoneRHS.ZONE_H7;
    case HardinessZoneUSDA.ZONE_6B:
    case HardinessZoneUSDA.ZONE_7A:
      return HardinessZoneRHS.ZONE_H6;
    case HardinessZoneUSDA.ZONE_7B:
    case HardinessZoneUSDA.ZONE_8A:
      return HardinessZoneRHS.ZONE_H5;
    case HardinessZoneUSDA.ZONE_8B:
    case HardinessZoneUSDA.ZONE_9A:
      return HardinessZoneRHS.ZONE_H4;
    case HardinessZoneUSDA.ZONE_9B:
    case HardinessZoneUSDA.ZONE_10A:
      return HardinessZoneRHS.ZONE_H3;
    case HardinessZoneUSDA.ZONE_10B:
      return HardinessZoneRHS.ZONE_H2;
    case HardinessZoneUSDA.ZONE_11A:
    case HardinessZoneUSDA.ZONE_11B:
      return HardinessZoneRHS.ZONE_H1C;
    case HardinessZoneUSDA.ZONE_12A:
    case HardinessZoneUSDA.ZONE_12B:
      return HardinessZoneRHS.ZONE_H1B;
    case HardinessZoneUSDA.ZONE_13A:
    case HardinessZoneUSDA.ZONE_13B:
      return HardinessZoneRHS.ZONE_H1A;
    default:
      return HardinessZoneRHS.NONE;
  }
};

const getHardinessZoneTextRHS = (zone: HardinessZoneRHS, emptyText: string) => {
  switch (zone) {
    case HardinessZoneRHS.ZONE_H7:
      return "H7";
    case HardinessZoneRHS.ZONE_H6:
      return "H6";
    case HardinessZoneRHS.ZONE_H5:
      return "H5";
    case HardinessZoneRHS.ZONE_H4:
      return "H4";
    case HardinessZoneRHS.ZONE_H3:
      return "H3";
    case HardinessZoneRHS.ZONE_H2:
      return "H2";
    case HardinessZoneRHS.ZONE_H1C:
      return "H1C";
    case HardinessZoneRHS.ZONE_H1B:
      return "H1B";
    case HardinessZoneRHS.ZONE_H1A:
      return "H1A";
    default:
      return emptyText;
  }
};

const getPlantHardinessZoneIntervalUSDA = (plant: PlantSchema) => {
  if (plant.hardinessZoneMin && plant.hardinessZoneMax) {
    return (
      getHardinessZoneTextUSDA(
        plant.hardinessZoneMin,
        t("plants:unknownZone")
      ) +
      " - " +
      getHardinessZoneTextUSDA(plant.hardinessZoneMax, t("plants:unknownZone"))
    );
  } else if (plant.hardinessZoneMin) {
    return getHardinessZoneTextUSDA(
      plant.hardinessZoneMin,
      t("plants:unknownZone")
    );
  } else if (plant.hardinessZoneMax) {
    return getHardinessZoneTextUSDA(
      plant.hardinessZoneMax,
      t("plants:unknownZone")
    );
  } else {
    return t("plants:unknownZone");
  }
};

const getPlantHardinessZoneRHS = (plant: PlantSchema) => {
  if (plant.hardinessZoneRhs) {
    return getHardinessZoneTextRHS(
      plant.hardinessZoneRhs,
      t("plants:unknownZone")
    );
  } else {
    return t("plants:unknownZone");
  }
};

const getPlantHardinessZoneInterval = (plant: PlantSchema) => {
  return getPlantHardinessZoneIntervalUSDA(plant);
};

export const PlantText = {
  getFormattedHybridText,
  getLatinAndHybridName,
  getFullName,
  getPlantCollectionTypeText,
  getPlantPropertyText,
  getHardinessZoneTextUSDA,
  hardinessZoneUSDAtoRHS,
  getHardinessZoneTextRHS,
  getPlantHardinessZoneRHS,
  getPlantHardinessZoneInterval,
};
