import { Device } from "imagine-ui";
import React, { useContext, useEffect, useState } from "react";

type ContextMenuProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
};

/**
 * Displays an custom context menu when right clicking.
 * @param props
 */
export const ContextMenuItem = (props: ContextMenuProps) => {
  const isTouchDevice = Device.isTouchDevice();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = () => {
    if (isTouchDevice) return;
    if (props.onClick) {
      if (props.disabled !== true) props.onClick();
    }
  };

  const handleTouchEnd = (event) => {
    event.preventDefault();
    event.stopPropagation();
  };

  const handleTouchStart = (event) => {
    event.stopPropagation();
    if (props.onClick) {
      if (props.disabled !== true) props.onClick();
    }
  };

  return (
    <button
      id={props.id}
      className={
        "context-menu-item no-select item" +
        (props.disabled === true ? " disabled" : "")
      }
      onClick={handleClick}
      onTouchStart={handleTouchStart}
    >
      <span className="text">{props.children}</span>
    </button>
  );
};
