import { CanvasOperations, Mouse, MouseState, Trig } from "draw";
import { Point, Tools } from "imagine-essentials";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SceneView } from "../types";
import { Person } from ".";

interface Props {
  zeroReference: Point;
  zoom: number;
  canvasOffset: Point;
  onViewChange: (sceneView: SceneView) => void;
}

export const SceneryView = (props: Props) => {
  const { onViewChange } = props;
  const [startPos, setStartPos] = useState<Point | undefined>(undefined);
  const [endPos, setEndPos] = useState<Point | undefined>(undefined);

  const personWidth = CanvasOperations.unitToPixel(0.5, props.zoom);

  const viewCorners = useMemo(() => {
    const cameraAngle = 70 / 2;
    if (startPos && endPos) {
      const dist = CanvasOperations.calculateDistance(startPos, endPos);
      const directionVector = CanvasOperations.getPointSubtracted(
        endPos,
        startPos
      );
      const length = dist / Math.cos(Tools.degreesToRadians(cameraAngle));
      let viewAngle = Trig.getAngle(directionVector, { x: 0, y: -1 });
      if (directionVector.x < 0) viewAngle = -viewAngle;

      const sideAngle1 = viewAngle - cameraAngle;
      const sideAngle2 = viewAngle + cameraAngle;

      const sidePoint1 = {
        x: startPos.x + Math.sin(Tools.degreesToRadians(sideAngle1)) * length,
        y: startPos.y - Math.cos(Tools.degreesToRadians(sideAngle1)) * length,
      };
      const sidePoint2 = {
        x: startPos.x + Math.sin(Tools.degreesToRadians(sideAngle2)) * length,
        y: startPos.y - Math.cos(Tools.degreesToRadians(sideAngle2)) * length,
      };

      console.log("Length", dist, length);
      console.log("View angle", viewAngle);
      return [sidePoint1, sidePoint2];
    }
  }, [startPos, endPos]);

  const startPosPx = useMemo(() => {
    if (!startPos) return;
    return CanvasOperations.unitToPixelPosition(
      startPos,
      props.zoom,
      props.zeroReference
    );
  }, [startPos, props.zoom, props.zeroReference]);

  const path = useMemo(() => {
    if (viewCorners && startPosPx) {
      const viewCornersPx = viewCorners.map((p) =>
        CanvasOperations.unitToPixelPosition(p, props.zoom, props.zeroReference)
      );
      return `M ${viewCornersPx[0].x} ${viewCornersPx[0].y} L ${startPosPx.x} ${startPosPx.y} L ${viewCornersPx[1].x} ${viewCornersPx[1].y}`;
    }
  }, [viewCorners]);

  const direction = useMemo(() => {
    if (startPos && endPos) {
      const zeroVector = { x: 0, y: -1 };
      if (startPos.x > endPos.x) {
        zeroVector.y = 1;
      }
      const directionVector = CanvasOperations.getPointSubtracted(
        endPos,
        startPos
      );
      return Trig.getAngle(zeroVector, directionVector);
    }
    return 0;
  }, [startPos, endPos]);

  const startDrawing = useCallback(
    (pos: Point) => {
      const unitPos = CanvasOperations.pixelToUnitPosition(
        pos,
        props.zoom,
        props.zeroReference
      );
      console.log("Start pos", pos, unitPos);
      setStartPos(unitPos);
    },
    [props.zoom, props.zeroReference]
  );

  const updateView = useCallback(
    (pos: Point) => {
      const unitPos = CanvasOperations.pixelToUnitPosition(
        pos,
        props.zoom,
        props.zeroReference
      );
      setEndPos(unitPos);
    },
    [props.zoom, props.zeroReference]
  );

  const finishDrawing = useCallback(() => {
    if (viewCorners && viewCorners.length === 2 && startPos) {
      const viewScene = {
        cameraPosition: startPos,
        viewPointA: viewCorners[0],
        viewPointB: viewCorners[1],
      };
      setStartPos(undefined);
      setEndPos(undefined);

      onViewChange(viewScene);
    }
  }, [viewCorners, onViewChange, startPos]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      if (state.pressed) {
        updateView(pos);
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      startDrawing(pos);
    });

    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      finishDrawing();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    startDrawing,
    updateView,
    props.zeroReference,
    props.canvasOffset,
    finishDrawing,
  ]);

  return (
    <g>
      {startPosPx && (
        <Person position={startPosPx} direction={direction} zoom={props.zoom} />
      )}
      {viewCorners && (
        <>
          <path d={path + " Z"} fill="rgba(0, 0, 0, 0.1)" stroke="none" />
          <path
            d={path}
            fill="rgba(0, 0, 0, 0.1)"
            // stroke="#252522"
            strokeWidth={1}
          />
        </>
      )}
    </g>
  );
};
