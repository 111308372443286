import { useMemo } from "react";

import { Area, ModelOperations, ModelSvg } from "draw";
import { Delimiter, Point } from "imagine-essentials";
import { UnitScale } from "project";

type Props = {
  area: Area;
  zoom: number;
  zeroReference: Point;
  cursor?: string;
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Responsible for drawing the static area.
 */
export const StaticArea = (props: Props) => {
  const model = useMemo(() => {
    if (props.area === undefined) return undefined;
    return ModelOperations.getAreaModel(
      props.area.size,
      props.area.position,
      props.zeroReference,
      props.zoom,
      props.area.shape,
      props.area.rotation ?? 0
    );
  }, [props.area, props.zeroReference, props.zoom]);

  const cursor = props.cursor ? props.cursor : "default";

  return (
    <>
      {model !== undefined && (
        <g
          transform={
            "rotate(" +
            model.rotation +
            ", " +
            (model.position.x + model.size.width / 2) +
            ", " +
            (model.position.y + model.size.height / 2) +
            ")"
          }
          className="area"
        >
          <ModelSvg
            model={model}
            className="area"
            elementId="area"
            cursor={cursor}
            inactive
            delimiter={props.delimiter}
            unitScale={props.unitScale}
          />
        </g>
      )}
    </>
  );
};
