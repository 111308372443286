import {
  useAppDispatch,
  useAppSelector,
  LayoutSelector,
  LayoutActions,
  PlantManagementThunks,
  PlanEditorSelector,
  CanvasSelector,
} from "../../store";
import { PlanInfo } from "draw";
import { useCallback, useEffect, useState } from "react";
import { Pages } from "../../enums";
import { OpenPlanModal } from "../../components";
import { PlansApi } from "../../api";
import { useTranslation } from "react-i18next";
import { PlanThunks } from "../../utils";
import { NotificationType, useNotification } from "imagine-ui";
import { SceneryPage } from "scenery";
import { UserHelpers, UserPreferences } from "project";
import { UserSelector } from "imagine-users";
import { I18nTools } from "imagine-i18n";

/**
 *
 */
export const SceneryPageContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { t } = useTranslation();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const items = useAppSelector(PlanEditorSelector.getVisibleItems);
  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const sceneView = useAppSelector(CanvasSelector.getSceneView);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      {displayedPage === Pages.SCENERY_PAGE && sceneView && (
        <SceneryPage
          onClose={close}
          cameraPosition={sceneView.cameraPosition}
          viewPointA={sceneView.viewPointA}
          viewPointB={sceneView.viewPointB}
          items={items}
          plantTemplates={plantTemplates}
          delimiter={delimiter}
          unitScale={userPreferences.unitScale}
        />
      )}
    </>
  );
};
