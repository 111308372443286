import { useTranslation } from "react-i18next";

export const Version2Dot0 = () => {
  const { t } = useTranslation();
  return (
    <>
      <h5 className="mt">{t("2_0_updatedLayout")}</h5>
      <p>{t("2_0_updatedLayoutDescription")}</p>
      <h5 className="mt">{t("2_0_updatedMyPlants")}</h5>
      <p>{t("2_0_updatedMyPlantsDescription")}</p>
      <h5 className="mt">{t("2_0_updatedObjectEditor")}</h5>
      <p>{t("2_0_updatedObjectEditorDescription")}</p>
    </>
  );
};
