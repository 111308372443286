import React from "react";
import { PathProperties, ShapeOperations, ShapeStyle } from "..";
import { Point } from "imagine-essentials";

interface Props {
  path: PathProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  uniqueId?: string; // A unique id for the coloring gradient, only neccessary if shine is set
  cursor?: string;
  className?: string;
}

const defaultStyle: ShapeStyle = {
  borderColor: "#CC0000",
  fillColor: "#CCCCCC",
  opacity: 1,
};

const lineWidthPx = 1;

/**
 * Draws any path, but is currently only used to draw plants. One plant might consist of multiple paths.
 * @param props
 */
export const PathSvg = (props: Props) => {
  const getGradientId = (url: boolean) => {
    let id = "ShineSpot" + props.uniqueId;
    if (url) id = "url(#" + id + ")";
    return id;
  };

  const style = props.style !== undefined ? props.style : defaultStyle;

  // TODO: Skip gradient if image is small to increase performance
  return (
    <>
      {style.shineColor && (
        <defs>
          <radialGradient id={getGradientId(false)} cx="0.35" cy="0.65" r="0.5">
            <stop offset="0%" stopColor={style.shineColor} />
            <stop offset="100%" stopColor={style.fillColor} />
          </radialGradient>
        </defs>
      )}
      <path
        d={props.path.path}
        stroke={ShapeOperations.getStroke(style)}
        strokeOpacity={ShapeOperations.getStrokeOpacity()}
        strokeDasharray={ShapeOperations.getStrokeDashArray(style, lineWidthPx)}
        fill={ShapeOperations.getFill(style)}
        opacity={ShapeOperations.getOpacity(style)}
        style={{ cursor: props.cursor || "move" }}
        className={props.className}
        strokeWidth={style.borderColor ? lineWidthPx : 0}
        vectorEffect="non-scaling-stroke"
      />
    </>
  );
};
