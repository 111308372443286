/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Action,
  ActionType,
  AlterDataType,
  AlterResult,
  Area,
  Group,
  GroupOperations,
  Item,
  ItemOperations,
  Layer,
  LayerOperations,
} from "..";

const historySize = 10;

/**
 * Push an item update action to the action history and returns the new action history.
 */
const pushItemUpdateAction = (
  itemBefore: Item | null,
  itemAfter: Item | null,
  actionHistory: Action[]
) => {
  const action = {
    type: ActionType.ITEM_UPDATE,
    before: itemBefore,
    after: itemAfter,
  };

  const newActionHistory = [...actionHistory, action];
  if (newActionHistory.length > historySize) {
    newActionHistory.shift();
  }
  return newActionHistory;
};

/**
 * Push a multi-item update action to the action history and returns the new action history.
 */
const pushItemsUpdateAction = (
  itemsBefore: Item[] | null,
  itemsAfter: Item[] | null,
  actionHistory: Action[]
) => {
  const action = {
    type: ActionType.ITEMS_UPDATE,
    before: itemsBefore,
    after: itemsAfter,
  };

  const newActionHistory = [...actionHistory, action];
  if (newActionHistory.length > historySize) {
    newActionHistory.shift();
  }
  return newActionHistory;
};

/**
 * Push a layer update action to the action history and returns the new action history.
 */
const pushLayerUpdateAction = (
  layerBefore: Layer | null,
  layerAfter: Layer | null,
  actionHistory: Action[]
) => {
  const action = {
    type: ActionType.LAYER_UPDATE,
    before: layerBefore,
    after: layerAfter,
  };
  const newActionHistory = [...actionHistory, action];
  if (newActionHistory.length > historySize) {
    newActionHistory.shift();
  }
  return newActionHistory;
};

/**
 * Push a group update action to the action history and returns the new action history.
 */
const pushGroupUpdateAction = (
  groupBefore: Group | null,
  groupAfter: Group | null,
  actionHistory: Action[]
) => {
  const action = {
    type: ActionType.GROUP_UPDATE,
    before: groupBefore,
    after: groupAfter,
  };
  const newActionHistory = [...actionHistory, action];
  if (newActionHistory.length > historySize) {
    newActionHistory.shift();
  }
  return newActionHistory;
};

const pushAreaUpdateAction = (
  areaBefore: Area | null,
  areaAfter: Area | null,
  actionHistory: Action[]
) => {
  const action = {
    type: ActionType.AREA_UPDATE,
    before: areaBefore !== null ? { ...areaBefore } : null,
    after: areaAfter !== null ? { ...areaAfter } : null,
  };
  const newActionHistory = [...actionHistory, action];
  if (newActionHistory.length > historySize) {
    newActionHistory.shift();
  }
  return newActionHistory;
};

const isItem = (value: any) => {
  // Items in action history must contain an index (otherwise index is not required for items)
  if ((value as Item).id !== undefined && (value as Item).index !== undefined) {
    return true;
  } else {
    console.warn("isItem failed:", value);
    return false;
  }
};

/**
 * Check if passed value is an array of items.
 */
const isItemArray = (value: any) => {
  if (!Array.isArray(value)) return false;
  let isItems = true;
  value.forEach((item: any) => {
    if (!isItem(item)) {
      isItems = false;
      return;
    }
  });
  return isItems;
};

const isLayer = (value: any) => {
  if (
    (value as Layer).id !== undefined &&
    (value as Layer).index !== undefined
  ) {
    return true;
  } else {
    console.warn("isLayer failed:", value);
    return false;
  }
};

const isGroup = (value: any) => {
  if (
    (value as Group).id !== undefined &&
    (value as Group).index !== undefined
  ) {
    return true;
  } else {
    console.warn("isGroup failed:", value);
    return false;
  }
};

/**
 * Updates the item list according to the undo action
 * @param items
 * @param action
 */
const undoItems = (items: Item[], action: Action) => {
  if (action.before === null && isItem(action.after)) {
    // Item was added - now remove again
    return ItemOperations.deleteItem(action.after as Item, items);
  } else if (isItem(action.before) && action.after === null) {
    // Item was deleted - now get back (at original index also)
    return ItemOperations.insertItem(action.before as Item, items);
  } else if (isItem(action.before) && isItem(action.after)) {
    // Item was updated, now go back to the 'before' state
    const before = action.before as Item;
    const after = action.after as Item;
    let newItems: Item[] = [];
    if (before.index === after.index || before.index === undefined) {
      newItems = ItemOperations.updateItems(items, before);
    } else {
      // If index has changed then all items should be updated and the
      // item should be moved to the correct index
      newItems = ItemOperations.insertItem(before, items);
    }
    return newItems;
  } else {
    console.error("No states passed Item validation:", action);
    throw Error("No actions defined");
  }
};

/**
 * Updates the item list for multiple items according to the undo action
 */
const undoMultipleItems = (items: Item[], action: Action) => {
  if (action.before === null && isItemArray(action.after)) {
    // Items were added - now remove again
    return ItemOperations.deleteItems(items, action.after as Item[]);
  } else if (isItemArray(action.before) && action.after === null) {
    // Items were deleted - now get back (at original index also)
    return ItemOperations.insertItems(items, action.before as Item[]);
  } else if (isItemArray(action.before) && isItemArray(action.after)) {
    // Items were updated, now go back to the 'before' state
    const before = action.before as Item[];
    const after = action.after as Item[];
    // let newItems: Item[] = [];

    const updatedIds = after.map((item: Item) => {
      return item.id;
    });

    const newItems = items.map((item: Item) => {
      if (updatedIds.includes(item.id)) {
        return ItemOperations.getItem(before, item.id);
      } else return item;
    });

    // Fix order according to index. The object editor can change order of multiple items
    return ItemOperations.sortByIndex(newItems);

    // return newItems;
  } else {
    console.error("No states passed Item validation:", action);
    throw Error("No actions defined");
  }
};

const undoLayers = (layers: Layer[], action: Action) => {
  if (action.before === null && isLayer(action.after)) {
    // Layer was added - now remove again
    const layer = action.after as Layer;
    return LayerOperations.deleteLayer(layer.id, layers);
  } else if (isLayer(action.before) && action.after === null) {
    // Layer was deleted - now get it back (at original index also)
    const deletedLayer = action.before as Layer;
    const newLayers = LayerOperations.insertLayer(deletedLayer, layers);

    return newLayers;
  } else if (isLayer(action.before) && isLayer(action.after)) {
    // Layer was updated, now go back to the 'before' state
    const before = action.before as Layer;
    const after = action.after as Layer;
    let newLayers: Layer[] = [];
    if (before.index === after.index || before.index === undefined) {
      newLayers = LayerOperations.updateLayers(layers, before as Layer);
    } else {
      // If index has changed then all layers should be updated and the
      // layer should be moved to the correct index
      newLayers = LayerOperations.insertLayer(before, layers);
    }
    return newLayers;
  } else {
    console.error("No states passed Layer validation:", action);
    throw Error("No actions defined");
  }
};

const undoGroups = (groups: Group[], action: Action) => {
  if (action.before === null && isGroup(action.after)) {
    // Group was added - now remove again
    const group = action.after as Group;
    return GroupOperations.deleteGroup(group.id, groups);
  } else if (isGroup(action.before) && action.after === null) {
    // Group was deleted - now get it back (at original index also)
    const deletedGroup = action.before as Group;
    const newGroups = GroupOperations.insertGroup(deletedGroup, groups);
    return newGroups;
  } else if (isGroup(action.before) && isGroup(action.after)) {
    // Group was updated, now go back to the 'before' state
    const before = action.before as Group;
    const after = action.after as Group;
    let newGroups: Group[] = [];
    if (before.index === after.index || before.index === undefined) {
      newGroups = GroupOperations.updateGroups(groups, before as Group);
    } else {
      newGroups = GroupOperations.insertGroup(before as Group, groups);
    }
    return newGroups;
  } else {
    console.error("No states passed Group validation:", action);
    throw Error("No actions defined");
  }
};

const undoArea = (action: Action) => {
  if (action.before === null) return undefined;
  return action.before as Area;
};

const redoItems = (items: Item[], action: Action) => {
  const flippedAction = {
    type: action.type,
    before: action.after,
    after: action.before,
  };
  // By switching 'before' and 'after' the undo logic can be re-used
  return undoItems(items, flippedAction);
};

const redoMultipleItems = (items: Item[], action: Action) => {
  const flippedAction = {
    type: action.type,
    before: action.after,
    after: action.before,
  };
  return undoMultipleItems(items, flippedAction);
};

const redoLayers = (layers: Layer[], action: Action) => {
  const flippedAction = {
    type: action.type,
    before: action.after,
    after: action.before,
  };
  return undoLayers(layers, flippedAction);
};

const redoGroups = (groups: Group[], action: Action) => {
  const flippedAction = {
    type: action.type,
    before: action.after,
    after: action.before,
  };
  return undoLayers(groups, flippedAction);
};

const redoArea = (action: Action) => {
  if (action.after === null) return undefined;
  return action.after as Area;
};

/**
 * Undoes an action from the action history. The action is moved to the undone history instead.
 * All possibly affectable data must be passed in order to be updated.
 */
export const undo = (
  actionHistory: Action[],
  actionUndoneHistory: Action[],
  items: Item[],
  layers: Layer[],
  groups: Group[],
  area: Area | null
) => {
  const newActionHistory = [...actionHistory];
  const undoAction = newActionHistory.pop();
  if (undoAction === undefined) {
    console.warn("No action removed from history:", actionHistory);
    return;
  }
  const newActionUndoneHistory = [...actionUndoneHistory, undoAction];

  const alterResult: AlterResult = {
    actionHistory: newActionHistory,
    actionUndoneHistory: newActionUndoneHistory,
    alteredDataType: AlterDataType.NONE,
    alteredData: null,
  };
  switch (undoAction.type) {
    case ActionType.ITEM_UPDATE:
      alterResult.alteredDataType = AlterDataType.ITEMS;
      alterResult.alteredData = undoItems(items, undoAction);
      break;
    case ActionType.ITEMS_UPDATE:
      alterResult.alteredDataType = AlterDataType.ITEMS;
      alterResult.alteredData = undoMultipleItems(items, undoAction);
      break;
    case ActionType.LAYER_UPDATE:
      alterResult.alteredDataType = AlterDataType.LAYERS;
      alterResult.alteredData = undoLayers(layers, undoAction);
      break;
    case ActionType.GROUP_UPDATE:
      alterResult.alteredDataType = AlterDataType.GROUPS;
      alterResult.alteredData = undoGroups(groups, undoAction);
      break;
    case ActionType.AREA_UPDATE:
      alterResult.alteredDataType = AlterDataType.AREA;
      alterResult.alteredData = undoArea(undoAction);
      break;
    default:
      throw Error("Action type not supported: " + undoAction.type);
  }
  return alterResult;
};

export const redo = (
  actionHistory: Action[],
  actionUndoneHistory: Action[],
  items: Item[],
  layers: Layer[],
  groups: Group[],
  area: Area | null
) => {
  const newActionUndoneHistory = [...actionUndoneHistory];
  const redoAction = newActionUndoneHistory.pop();
  if (redoAction === undefined) {
    console.warn("No action removed from undone history:", actionHistory);
    return;
  }
  const newActionHistory = [...actionHistory, redoAction];

  const alterResult: AlterResult = {
    actionHistory: newActionHistory,
    actionUndoneHistory: newActionUndoneHistory,
    alteredDataType: AlterDataType.NONE,
    alteredData: null,
  };
  switch (redoAction.type) {
    case ActionType.ITEM_UPDATE:
      alterResult.alteredDataType = AlterDataType.ITEMS;
      alterResult.alteredData = redoItems(items, redoAction);
      break;
    case ActionType.ITEMS_UPDATE:
      alterResult.alteredDataType = AlterDataType.ITEMS;
      alterResult.alteredData = redoMultipleItems(items, redoAction);
      break;
    case ActionType.LAYER_UPDATE:
      alterResult.alteredDataType = AlterDataType.LAYERS;
      alterResult.alteredData = redoLayers(layers, redoAction);
      break;
    case ActionType.GROUP_UPDATE:
      alterResult.alteredDataType = AlterDataType.GROUPS;
      alterResult.alteredData = redoGroups(groups, redoAction);
      break;
    case ActionType.AREA_UPDATE:
      alterResult.alteredDataType = AlterDataType.AREA;
      alterResult.alteredData = redoArea(redoAction);
      break;
    default:
      throw Error("Action type not supported: " + redoAction.type);
  }
  return alterResult;
};

export const ActionHistoryOperations = {
  pushItemUpdateAction: pushItemUpdateAction,
  pushItemsUpdateAction: pushItemsUpdateAction,
  pushLayerUpdateAction: pushLayerUpdateAction,
  pushGroupUpdateAction: pushGroupUpdateAction,
  pushAreaUpdateAction: pushAreaUpdateAction,
  undo: undo,
  redo: redo,
  undoItems,
  undoMultipleItems,
  undoLayers,
  undoGroups,
  undoArea,
  redoItems,
  redoMultipleItems,
  redoLayers,
  redoGroups,
  redoArea,
};
