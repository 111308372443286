import {
  useAppDispatch,
  useAppSelector,
  PlanEditorActions,
  PlanEditorSelector,
} from "../../store";
import {
  ToolButton,
  ToolDropdown,
  ToolGroup,
  ToolSeparator,
} from "../../components";
import { useMemo } from "react";
import { Group, GroupOperations, Item, Layer, LayerOperations } from "draw";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { ShapeItemToolbarContainer } from ".";

/**
 * Displays the tool and actions in the item toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const AreaItemToolbarContainer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const area = useAppSelector(PlanEditorSelector.getArea);

  return (
    <>
      {isEditingArea && (
        <div id="item-toolbar" className="toolbar-row">
          <ShapeItemToolbarContainer />
        </div>
      )}
    </>
  );
};
