import { CouponDetails, ProductDetails } from "imagine-memberships";

const productDetails: ProductDetails[] = [
  {
    name: "Garden Sketcher Basic",
    titleKey: "project:basisMembership",
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
    ],
  },
  {
    name: "Garden Sketcher Premium",
    titleKey: "project:premiumMembership",
    featureKeys: [
      "project:insertPlantsAndObjects",
      "project:overviewSeasonColors",
      "project:createPlantsAndObjects",
      "project:generatePlanList",
      "project:createPlantCollections",
      "project:saveUpTo10Plans",
      "project:drawPropertyOnSatelliteMap",
    ],
  },
];

const couponDetails: CouponDetails[] = [
  {
    id: "haveselskabet50",
    titleKey: "project:couponHaveselskabet50",
    descriptionKey: "project:couponHaveselskabet50Description",
    productName: "Garden Sketcher Premium",
    productRecurringInterval: "year",
  },
  {
    id: "haveselskabet15",
    titleKey: "project:couponHaveselskabet15",
    descriptionKey: "project:couponHaveselskabet15Description",
    productName: "Garden Sketcher Premium",
    productRecurringInterval: "year",
  },
];

export const StripeAdditionalDetails = {
  productDetails,
  couponDetails,
};
