import { useTranslation } from "react-i18next";
import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
} from "../../store";
import { useState } from "react";
import { UserPreferencesModal } from "../../components";
import { UserActions, UserSelector, UsersApi } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";

export const UserPreferencesModalContainer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const activeTab = useAppSelector(LayoutSelector.getUserAccountTab);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    setErrorMessage("");
    setLoading(false);
  };

  const updateUserPreferences = async (preferences: UserPreferences) => {
    setLoading(true);
    const response = await UsersApi.updatePreferences(preferences);
    if (response.success) {
      dispatch(UserActions.setPreferences(preferences));
      close();
    } else if (!response.success && response.errorMessage) {
      setErrorMessage(response.errorMessage);
    }
    setLoading(false);
  };

  return (
    <>
      {displayedPage === Pages.USER_PREFERENCES && (
        <>
          <UserPreferencesModal
            preferences={userPreferences}
            onSave={updateUserPreferences}
            onClose={close}
            errorMessage={errorMessage}
            loading={loading}
          />
        </>
      )}
    </>
  );
};
