// Pixels per meter. 1 meter equals this number of pixels (each zoom level is 1.2 times larger)

const zoomLevel = [
  2120.5, 2019.5, 1923.4, 1831.8, 1744.6, 1661.5, 1582.4, 1507, 1435.3, 1366.9,
  1301.8, 1239.8, 1180.8, 1124.6, 1071, 1020, 971.4, 925.2, 881.1, 839.2, 799.2,
  761.1, 724.9, 690.4, 657.5, 626.2, 596.4, 568, 540.9, 515.2, 490.6, 467.3,
  445, 423.8, 403.7, 384.4, 366.1, 348.7, 332.1, 316.3, 301.2, 286.9, 273.2,
  260.2, 247.8, 236, 224.8, 214.1, 203.9, 194.2, 184.9, 176.1, 167.7, 159.7,
  152.1, 144.9, 138, 131.4, 125.2, 119.2, 113.5, 108.1, 103, 98.1, 93.4, 88.9,
  84.7, 80.7, 76.8, 73.2, 69.7, 66.4, 63.2, 60.2, 57.3, 54.6, 52, 49.5, 47.2,
  44.9, 42.8, 40.7, 38.8, 37, 35.2, 33.5, 31.9, 30.4, 29, 27.6, 26.3, 25, 23.8,
  22.7, 21.6, 20.6, 19.6, 18.7, 17.8, 16.9, 16.1, 15.4, 14.6, 13.9, 13.3, 12.6,
  12, 11.5, 10.9, 10.4, 9.9, 9.4, 9, 8.6, 8.1, 7.8, 7.4, 7, 6.7, 6.4, 6.1, 5.8,
  5.5, 5.3, 5, 4.16, 3.47, 2.89, 2.41, 2.01, 1.67, 1.39, 1.16, 0.97, 0.81, 0.67,
  0.56,
];

const defaultZoomIndex = 80;

/**
 * Get the zoom value (pixels per meter) for a given zoom index.
 * @param index
 * @returns
 */
const getZoomValue = (index: number) => {
  if (zoomLevel.length > index) return zoomLevel[index];
  throw new Error("Zoom index exceeds list of zoom levels");
};

/**
 * Get the index of the Garden Sketcher zoom. If the zoom value does not match and index value, then it will find the closest value.
 * @param zoom Pixels per meter
 */
const getZoomIndex = (zoom: number) => {
  let index = zoomLevel.findIndex((zoomValue: number) => zoom === zoomValue);
  if (index === -1) {
    index = getClosestZoom(zoom);
  }
  return index;
};

/**
 * Get a new zoom value (pixels per meter) based on a original zoom value, and an offset in indexes.
 * @param zoom Zoom in ppm
 * @param offset Offset in indexes
 */
const getZoomOffset = (zoom: number, offset: number) => {
  const originalIndex = getZoomIndex(zoom);

  let newZoomIndex = originalIndex + offset;
  if (newZoomIndex < 0) newZoomIndex = 0;
  if (newZoomIndex >= zoomLevel.length) newZoomIndex = zoomLevel.length - 1;
  return zoomLevel[newZoomIndex];
};

/**
 * Get the zoom index where the pixels per meter is closest to the value passed.
 * @param ppm Points per pixel
 */
const getClosestZoom = (ppm: number) => {
  let diff = 999;
  let zoom = 0;
  zoomLevel.forEach((zoomPPM: number, index: number) => {
    const levelDiff = Math.abs(zoomPPM - ppm);
    if (levelDiff < diff) {
      diff = levelDiff;
      zoom = index;
    }
  });
  return zoom;
};

/**
 * The maximum zoom value in pixels per meter
 */
const maxZoom = () => {
  return zoomLevel[zoomLevel.length - 1];
};

/**
 * Checks that the zoom value is within allowed range for Garden Sketcher zoom.
 * @param zoom Pixels per meter
 */
const checkZoom = (zoom: number) => {
  const zoomIndex = getZoomIndex(zoom);
  return zoomIndex >= 0 && zoomIndex <= zoomLevel.length - 1;
};

const zoomInAllowed = (zoom: number) => {
  const zoomIndex = getZoomIndex(zoom);
  return zoomIndex > 0;
};

const zoomOutAllowed = (zoom: number) => {
  const zoomIndex = getZoomIndex(zoom);
  return zoomIndex < zoomLevel.length - 1;
};

const getDefaultZoom = () => {
  return getZoomValue(defaultZoomIndex);
};

export const Zoom = {
  getZoomValue,
  getZoomOffset,
  checkZoom,
  maxZoom: maxZoom,
  getClosestZoom,
  zoomInAllowed,
  zoomOutAllowed,
  getZoomIndex,
  getDefaultZoom,
};
