import { useTranslation } from "react-i18next";
import {
  CanvasActions,
  LayoutActions,
  PlanEditorActions,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import {
  CanvasSelector,
  ObjectEditorSelector,
  PlanEditorSelector,
} from "../../store";
import { CursorMode, MapMode, Pages } from "../../enums";
import { ToolButton, ToolSeparator } from "../../components";
import { useMemo } from "react";
import { Device, useNotification } from "imagine-ui";
import { UserSelector } from "imagine-users";

/**
 * Displays the tool and actions in the home toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const AreaToolbarContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const hasStandardAccess = useAppSelector(UserSelector.hasStandardAccess);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const unsavedPlanChanges = useAppSelector(
    PlanEditorSelector.hasUnsavedChanges
  );
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);

  const selectedShape = useAppSelector(CanvasSelector.getSelectedShapeTool);

  const area = useAppSelector(PlanEditorSelector.getArea);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const mapAddress = useAppSelector(CanvasSelector.getMapAddress);
  const isMapFrozen = useAppSelector(CanvasSelector.isMapFrozen);
  const mapMode = useAppSelector(CanvasSelector.getMapMode);
  const isTouchDevice = useMemo(() => {
    return Device.isTouchDevice();
  }, []);
  const editingReferenceImage = useAppSelector(
    PlanEditorSelector.isEditingReferenceImage
  );
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);
  const showReferenceImage = useAppSelector(
    CanvasSelector.getShowReferenceImage
  );

  const editArea = () => {
    if (!area) return;
    dispatch(PlanEditorActions.setEditingArea(true));
  };

  const toggleMapVisibility = () => {
    if (mapMode === MapMode.NONE && mapAddress !== "") {
      dispatch(CanvasActions.setMapMode(MapMode.VISIBLE));
    } else if (mapMode === MapMode.VISIBLE) {
      dispatch(CanvasActions.setMapMode(MapMode.NONE));
    }
  };

  const selectAddress = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.MAP_LOCATION));
  };

  const toggleMapFreeze = () => {
    const newFrozenState = !isMapFrozen;
    dispatch(CanvasActions.setMapFrozen(newFrozenState));
    if (newFrozenState) {
      dispatch(CanvasActions.setCursorMode(CursorMode.DRAG));
      dispatch(PlanEditorActions.setSelectedItems([]));
    } else {
      dispatch(CanvasActions.setCursorMode(CursorMode.POINTER));
    }
  };

  const toggleReferenceImage = () => {
    dispatch(CanvasActions.setShowReferenceImage(!showReferenceImage));
  };

  const editReferenceImage = () => {
    dispatch(
      PlanEditorActions.setEditingReferenceImage(!editingReferenceImage)
    );
  };

  const openReferenceImageModal = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.REFERENCE_IMAGE_PAGE));
  };

  return (
    <div id="area-toolbar" className="toolbar-row">
      <ToolButton
        elementId="edit-area-nav-button"
        text={t("editArea")}
        icon="edit-map"
        onClick={editArea}
        disabled={!hasStandardAccess || !area}
        selected={isEditingArea}
      />{" "}
      <ToolSeparator />
      <ToolButton
        elementId="show-reference-image-nav-button"
        text={t("showReferenceImage")}
        icon="reference-image"
        onClick={toggleReferenceImage}
        disabled={!hasPremiumAccess || !referenceImage}
        selected={showReferenceImage}
      />
      <ToolButton
        elementId="edit-image-nav-button"
        text={t("editReferenceImage")}
        icon="reference-image-edit"
        onClick={editReferenceImage}
        disabled={!hasPremiumAccess || !showReferenceImage}
        selected={editingReferenceImage}
      />
      <ToolButton
        elementId="reference-image-nav-button"
        text={t("selectReferenceImage")}
        icon="reference-image-select"
        onClick={openReferenceImageModal}
        disabled={!hasPremiumAccess || planInfo.id === 0}
      />
      <ToolSeparator />
      <ToolButton
        elementId="show-map-nav-button"
        text={t("showMap")}
        icon="pin-map"
        onClick={toggleMapVisibility}
        disabled={!hasPremiumAccess || mapAddress === "" || isMapFrozen}
        selected={mapMode !== MapMode.NONE}
      />
      <ToolButton
        elementId="set-location-nav-button"
        text={t("setLocation")}
        icon="pin"
        onClick={selectAddress}
        disabled={!hasPremiumAccess || isMapFrozen}
      />
      <ToolButton
        elementId="freeze-map-nav-button"
        text={t("freezeMap")}
        icon="pin-map-2"
        onClick={toggleMapFreeze}
        disabled={
          !hasPremiumAccess || mapAddress === "" || mapMode === MapMode.NONE
        }
        selected={isMapFrozen}
      />
    </div>
  );
};
