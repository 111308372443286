import { Button, Device, Link, ProgressBar } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { CursorMode, MapMode, Pages, Tour } from "../../enums";
import {
  CanvasSelector,
  LayoutActions,
  LayoutSelector,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemType } from "draw";
import { UserActions, UserThunks } from "imagine-users";
import { FeatureKey } from "project";

enum Steps {
  NEW_PLAN, // If layers are already created in current plan
  CREATE_ITEMS, // If no item is created already
  DESELECT_ITEM, // If an item is selected
  OPEN_LAYERS_SIDEBAR,
  CLICK_NEW_LAYER,
  RENAME_LAYER_1,
  RENAME_LAYER_2,
  SELECT_ITEM,
  ADD_ITEM_TO_LAYER,
  SELECT_ANOTHER_ITEM,
  ADD_ANOTHER_ITEM_TO_LAYER,
  DESELECT_ALL_ITEMS,
  REORDER_LAYERS,
  HIDE_LAYER,
  SELECT_LAYER_2,
  ADD_ITEM_TO_LAYER_DIRECTLY,
  DONE,
}

interface Props {
  onFinish: () => void;
}

export const LayersTourContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [zoomActions, setZoomActions] = useState(0);
  const [dragCompleted, setDragCompleted] = useState(false);

  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const sidebarLeftActiveTab = useAppSelector(
    LayoutSelector.getSidebarLeftActiveTab
  );
  const currentLayerId = useAppSelector(PlanEditorSelector.getCurrentLayerId);

  const touchDevice = Device.isTouchDevice();
  const activeTab = useAppSelector(LayoutSelector.getActiveTab);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const mapAddress = useAppSelector(CanvasSelector.getMapAddress);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const selectedItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const mapVisible =
    useAppSelector(CanvasSelector.getMapMode) === MapMode.VISIBLE;
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);

  const firstLayer = useMemo(() => {
    return layers.find((l) => l.id === 1);
  }, [layers]);

  const firstLayerName = useMemo(() => {
    return firstLayer?.name || "";
  }, [firstLayer]);

  const secondLayer = useMemo(() => {
    return layers.find((l) => l.id === 2);
  }, [layers]);

  const secondLayerName = useMemo(() => {
    return secondLayer?.name || "";
  }, [secondLayer]);

  const firstLayerItems = useMemo(() => {
    return items.filter((i) => i.layerId === 1);
  }, [items]);

  const secondLayerItems = useMemo(() => {
    return items.filter((i) => i.layerId === 2);
  }, [items]);

  const selectedItem = useMemo(() => {
    return selectedItems.length > 0 ? selectedItems[0] : undefined;
  }, [selectedItems]);

  const tourStep = useMemo(() => {
    if (layers.length > 2 || firstLayer === undefined) {
      return Steps.NEW_PLAN;
    }
    // Create items
    if (items.length < 5) {
      return Steps.CREATE_ITEMS;
    }
    // Create new layer
    if (secondLayer === undefined) {
      if (selectedItems.length > 0) {
        return Steps.DESELECT_ITEM;
      }
      if (sidebarLeftActiveTab !== "layers") {
        return Steps.OPEN_LAYERS_SIDEBAR;
      } else {
        return Steps.CLICK_NEW_LAYER;
      }
    }
    // Rename both layers
    if (firstLayer.name === t("layer") + " 1") {
      return Steps.RENAME_LAYER_1;
    }
    if (secondLayer.name === t("layer") + " 2") {
      return Steps.RENAME_LAYER_2;
    }

    // Add first item to second layer
    if (secondLayerItems.length === 0) {
      if (selectedItem === undefined) {
        return Steps.SELECT_ITEM;
      } else {
        return Steps.ADD_ITEM_TO_LAYER;
      }
    }

    // Add second item to second layer
    if (secondLayerItems.length === 1) {
      if (selectedItem === undefined) {
        return Steps.SELECT_ANOTHER_ITEM;
      } else if (selectedItem.layerId === secondLayer.id) {
        return Steps.SELECT_ANOTHER_ITEM;
      } else {
        return Steps.ADD_ANOTHER_ITEM_TO_LAYER;
      }
    }

    if (secondLayerItems.length === 2) {
      if (firstLayer.index === 0) {
        if (selectedItems.length > 0) {
          return Steps.DESELECT_ALL_ITEMS;
        } else {
          return Steps.REORDER_LAYERS;
        }
      }

      if (currentLayerId !== secondLayer.id) {
        return Steps.SELECT_LAYER_2;
      }

      if (firstLayer.visible) {
        return Steps.HIDE_LAYER;
      }

      return Steps.ADD_ITEM_TO_LAYER_DIRECTLY;
    }

    return Steps.DONE;
  }, [
    sidebarLeftActiveTab,
    layers,
    items,
    selectedItem,
    currentLayerId,
    firstLayer,
    secondLayer,
    selectedItems,
  ]);

  const completeTour = useCallback(async () => {
    dispatch(UserActions.track(FeatureKey.FINISH_TOUR_LAYERS));
    await dispatch(UserThunks.postUsedFeatures());
    props.onFinish();
  }, []);

  useEffect(() => {
    if (tourStep === Steps.DONE) {
      completeTour();
    }
  }, [tourStep]);

  const finishTour = () => {
    dispatch(LayoutActions.setCurrentTour(Tour.NONE));
  };

  return (
    <div className="">
      <p className="text-bold">{t("createLayers")}</p>
      <ProgressBar value={tourStep} max={Steps.DONE} className="my-sm" />
      {tourStep === Steps.NEW_PLAN && <p>{t("layersTourNewPlan")}</p>}
      {tourStep === Steps.CREATE_ITEMS && <p>{t("layersTourCreateItems")}</p>}
      {tourStep === Steps.DESELECT_ITEM && <p>{t("layersTourDeselectItem")}</p>}
      {tourStep === Steps.OPEN_LAYERS_SIDEBAR && (
        <p>{t("layersTourOpenLayersSidebar")}</p>
      )}
      {tourStep === Steps.CLICK_NEW_LAYER && (
        <p>{t("layersTourClickNewLayer")}</p>
      )}
      {tourStep === Steps.RENAME_LAYER_1 && (
        <p>
          {t(
            touchDevice
              ? "layersTourRenameLayer1Touch"
              : "layersTourRenameLayer1"
          )}
        </p>
      )}
      {tourStep === Steps.RENAME_LAYER_2 && (
        <p>{t("layersTourRenameLayer2")}</p>
      )}
      {tourStep === Steps.SELECT_ITEM && (
        <p>
          {t("layersTourSelectItem", {
            layer1: firstLayerName,
            layer2: secondLayerName,
          })}
        </p>
      )}
      {tourStep === Steps.ADD_ITEM_TO_LAYER && (
        <p>{t("layersTourAddItemToLayer", { layer2: secondLayerName })}</p>
      )}
      {tourStep === Steps.SELECT_ANOTHER_ITEM && (
        <p>{t("layersTourSelectAnotherItem", { layer2: secondLayerName })}</p>
      )}
      {tourStep === Steps.ADD_ANOTHER_ITEM_TO_LAYER && (
        <p>
          {t("layersTourAddAnotherItemToLayer", { layer2: secondLayerName })}
        </p>
      )}
      {tourStep === Steps.DESELECT_ALL_ITEMS && (
        <p>{t("layersTourDeselectAllItems")}</p>
      )}
      {tourStep === Steps.REORDER_LAYERS && (
        <p>{t("layersTourReorderLayers")}</p>
      )}
      {tourStep === Steps.HIDE_LAYER && (
        <p>{t("layersTourHideLayer", { layer1: firstLayerName })}</p>
      )}
      {tourStep === Steps.SELECT_LAYER_2 && (
        <p>
          {t("layersTourSelectLayer2", {
            layer1: firstLayerName,
            layer2: secondLayerName,
          })}
        </p>
      )}
      {tourStep === Steps.ADD_ITEM_TO_LAYER_DIRECTLY && (
        <p>{t("layersTourAddItemToLayerDirectly")}</p>
      )}

      {tourStep === Steps.DONE && (
        <>
          <p className="mb">
            {t("layersTourItemAddedDirectly", { layer2: secondLayerName })}
          </p>
          <p className="mb">{t("layersTourDone")}</p>
          <p>{t("layersTourDoneGroupInfo")}</p>
        </>
      )}
      <Button
        color="danger"
        outline
        size="sm"
        onClick={finishTour}
        className="mt"
      >
        {t("finishTour")}
      </Button>
    </div>
  );
};
