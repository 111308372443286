import { useTranslation } from "react-i18next";
import { PlantCollection, PlantCollectionCard } from "..";
import { Modal, Button, TabBar, Tab } from "imagine-ui";
import { useMemo, useState } from "react";

type Props = {
  visible: boolean;
  plantCollections: PlantCollection[];
  onSelect: (plantCollection: PlantCollection) => void;
  onCreateNew: () => void;
  onClose: () => void;
  activeTab: string;
  onTabChange: (tab: string) => void;
};

/**
 * Displays a page with an overview of all plant collections.
 */
export const PlantCollectionOverviewPage = (props: Props) => {
  const { t } = useTranslation();

  const displayedCollections = useMemo(() => {
    if (props.activeTab === "user") {
      return props.plantCollections.filter(
        (collection) => collection.planId === 0
      );
    } else {
      return props.plantCollections.filter(
        (collection) => collection.planId !== 0
      );
    }
  }, [props.activeTab]);

  const tabs = useMemo(() => {
    return [
      {
        text: t("plants:userPlantCollections"),
        id: "user",
      },
      {
        text: t("plants:planPlantCollections"),
        id: "plan",
      },
    ] as Tab[];
  }, []);

  return (
    <Modal
      title={t("plants:plantCollections")}
      visible={props.visible}
      onClose={props.onClose}
      confirmText={t("imagine:close")}
      onConfirm={props.onClose}
      hideCancel
      size="xl"
    >
      <div>
        <TabBar
          active={props.activeTab}
          onChange={props.onTabChange}
          tabs={tabs}
          className="mb-xl"
        />

        <div className="grid gap columns-4 columns-3--lg columns-2--md columns-1--sm mb-xl">
          {displayedCollections.map((collection: PlantCollection) => (
            <div key={collection.id.toString()} className="">
              <PlantCollectionCard
                plantCollection={collection}
                onClick={() => props.onSelect(collection)}
              ></PlantCollectionCard>
            </div>
          ))}
        </div>
        <Button className="mt-4" onClick={props.onCreateNew} outline>
          {t("plants:createPlantCollection")}
        </Button>
      </div>
    </Modal>
  );
};
