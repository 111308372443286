import { AppInfo } from "imagine-essentials";
import { Modal } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { Version2Dot0, Version2Dot3, Version2Dot5 } from ".";
import facebookIcon from "../../assets/img/facebook.png";
import istagramIcon from "../../assets/img/instagram.png";

interface Props {
  lastVersion: string;
  onClose: () => void;
  plantProfileUrl: string;
  referenceImageUrl: string;
}

export const VersionNotificationPage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      visible
      title={t("gardenSketcherHasBeenUpdated")}
      hideCancel
      discreteHeader
      size="lg"
      onClose={props.onClose}
      onConfirm={props.onClose}
    >
      {AppInfo.isVersionOlder(props.lastVersion, "2.5.0") && (
        <Version2Dot5
          profileViewUrl={props.plantProfileUrl}
          referenceImageUrl={props.referenceImageUrl}
        />
      )}
      {AppInfo.isVersionOlder(props.lastVersion, "2.3.0") && <Version2Dot3 />}
      {AppInfo.isVersionOlder(props.lastVersion, "2.0.0") && <Version2Dot0 />}
      <p className="text-center mt-xl">{t("followOnSocalMedia")}</p>
      <div className="text-center mt-lg">
        <a target="_blank" href="https://www.facebook.com/gardensketcher">
          <img src={facebookIcon} width="64px" alt="Facebook" />
        </a>
        <a
          target="_blank"
          href="https://www.instagram.com/garden_sketcher/"
          className="ml-xl"
        >
          <img src={istagramIcon} width="64px" alt="Instagram" />
        </a>
      </div>
    </Modal>
  );
};
