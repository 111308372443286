import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useSelector, useDispatch } from "react-redux";
import { userReducer } from "imagine-users";
import plantManagementReducer from "./plant-management/plantManagementSlice";
import layoutReducer from "./layout/layoutSlice";
import planEditorReducer from "./plan-editor/planEditorSlice";
import canvasReducer from "./canvas/canvasSlice";
import objectEditorReducer from "./object-editor/objectEditorSlice";
import { membershipReducer } from "imagine-memberships";
import websiteReducer from "./website/websiteSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    membership: membershipReducer,
    plantManagement: plantManagementReducer,
    layout: layoutReducer,
    planEditor: planEditorReducer,
    canvas: canvasReducer,
    objectEditor: objectEditorReducer,
    website: websiteReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
