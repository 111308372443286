import { useCallback, useEffect } from "react";
import {
  CanvasContainer,
  FooterContainer,
  LeftSidebarContainer,
  RightSidebarContainer,
} from "..";
import { Point, Size } from "imagine-essentials";
import { Device } from "imagine-ui";
import { Board } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  LayoutSelector,
} from "../../store";

export const BoardContainer = () => {
  const dispatch = useAppDispatch();
  const isSmOrsmaller = useAppSelector(LayoutSelector.isSmOrSmaller);
  const touchDevice = Device.isTouchDevice();

  /**
   * Update the canvas size and offset. Adjusts the size according to presence of
   * sidebars and footer.
   */
  const updateCanvasSize = useCallback(
    (size: Size, offset: Point) => {
      const actualSize = {
        width: isSmOrsmaller ? size.width : size.width - 96,
        height: size.height,
      };
      const actualOffset = {
        ...offset,
        x: isSmOrsmaller ? offset.x : offset.x + 49,
      };
      dispatch(CanvasActions.setSize(actualSize));
      dispatch(CanvasActions.setOffset(actualOffset));
      dispatch(CanvasActions.setReady());
    },
    [dispatch, isSmOrsmaller]
  );

  return (
    <>
      <Board onResize={updateCanvasSize} touchDevice={touchDevice}>
        <CanvasContainer />
        {!isSmOrsmaller && (
          <>
            <LeftSidebarContainer />
            <RightSidebarContainer />
          </>
        )}
      </Board>
      {isSmOrsmaller && <FooterContainer />}
    </>
  );
};
