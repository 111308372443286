import {
  Item,
  ItemType,
  Model,
  ModelOperations,
  ModelSvg,
  PlantSchema,
  ShapeOperations,
} from "draw";
import { Point, Size, Trig } from "imagine-essentials";
import { UnitScale } from "project";
import { useMemo } from "react";

type Props = {
  item: Item; /// The item to create thumbnail image for
  plantTemplates?: PlantSchema[]; /// List of plant templates needed in case item is a plant
  size: Size; /// Pixel size of the image. The thumbnail will fill this size as much as possible while keeping the aspect ratio
};

const getRotatedSize = (size: Size, rotation: number) => {
  if (rotation === 0) return size;
  const center = {
    x: size.width / 2,
    y: size.height / 2,
  };
  const upperLeft = ShapeOperations.getRotatedPosition(
    { x: 0, y: 0 },
    center,
    rotation
  );
  const upperRight = ShapeOperations.getRotatedPosition(
    { x: size.width, y: 0 },
    center,
    rotation
  );
  const lowerLeft = ShapeOperations.getRotatedPosition(
    { x: 0, y: size.height },
    center,
    rotation
  );
  const lowerRight = ShapeOperations.getRotatedPosition(
    { x: size.width, y: size.height },
    center,
    rotation
  );

  let xMin = upperLeft.x;
  let xMax = upperLeft.x;
  let yMin = upperLeft.y;
  let yMax = upperLeft.y;

  const list = [upperLeft, upperRight, lowerLeft, lowerRight];

  list.forEach((point: Point) => {
    if (point.x < xMin) xMin = point.x;
    if (point.x > xMax) xMax = point.x;
    if (point.y < yMin) yMin = point.y;
    if (point.y > yMax) yMax = point.y;
  });

  const containerSize = {
    width: xMax - xMin,
    height: yMax - yMin,
  };
  return containerSize;
};

const getThumbnailSize = (maxSize: Size, containerSize: Size) => {
  const thumbnailSize = { ...maxSize };
  const containerRatio = containerSize.width / containerSize.height;
  const thumbnailRatio = maxSize.width / maxSize.height;

  // Model is wider; max size is width
  if (containerRatio > thumbnailRatio) {
    thumbnailSize.height = thumbnailSize.width / containerRatio;
  } else {
    thumbnailSize.width = thumbnailSize.height * containerRatio;
  }
  return thumbnailSize;
};

const getModel = (item: Item, size: Size) => {
  if (item.type === ItemType.SHAPE) {
    const newModel = ModelOperations.createShapeModel(item, 1, { x: 0, y: 0 });
    if (newModel === null)
      return ModelOperations.getDefaultModel(ItemType.SHAPE);
    const thumbnailSize = getThumbnailSize(size, newModel.size);

    const rotatedSize = getRotatedSize(thumbnailSize, newModel.rotation);

    const position = {
      x: (rotatedSize.width - thumbnailSize.width) / 2,
      y: (rotatedSize.height - thumbnailSize.height) / 2,
    };

    return {
      ...newModel,
      size: thumbnailSize,
      position: position, //{ x: 0, y: 0 },
    } as Model;
  } else {
    return ModelOperations.getDefaultModel(ItemType.SHAPE);
  }
};

/**
 * Displays an image of an item. The image will contain a thumbnail of the item in a size given
 * in the props.
 * @param props
 */
export const ItemThumbnail = (props: Props) => {
  // const [model, setModel] = useState<Model>(getModel(props.item, props.size));
  // const [container, setContainer] = useState<Rectangle>()

  // useEffect(() => {
  //   setModel(getModel(props.item, props.size));
  // }, [props.item, props.size]);

  const model = useMemo(() => {
    return getModel(props.item, props.size);
  }, [props.item, props.size]);

  const containerViewbox = useMemo(() => {
    const rotatedSize = Trig.getSizeSum(
      getRotatedSize(model.size, model.rotation),
      { width: 2, height: 2 }
    );

    return rotatedSize;
  }, [model.rotation, model.size]);

  const container = useMemo(() => {
    const rotatedSize = getRotatedSize(model.size, model.rotation);

    const thumbnailSize = getThumbnailSize(props.size, rotatedSize);

    const position = {
      x: thumbnailSize.width - model.size.width,
      y: thumbnailSize.height - model.size.height,
    };

    const zeroPosition = { x: 0, y: 0 };
    return {
      size: thumbnailSize,
      position: zeroPosition,
    };
  }, [model.rotation, model.size, props.size]);

  return (
    <svg
      x={container.position.x}
      y={container.position.y}
      viewBox={
        "-1 -1 " + containerViewbox.width + " " + containerViewbox.height
      }
      //viewBox={model.viewBox}
      width={container.size.width}
      height={container.size.height}
      preserveAspectRatio="none"
    >
      <g
        transform={
          "rotate(" +
          (model.rotation || 0) +
          ", " +
          (model.position.x + model.size.width / 2) +
          ", " +
          (model.position.y + model.size.height / 2) +
          ")"
        }
      >
        <ModelSvg model={model} unitScale={UnitScale.METRIC} delimiter="." />
      </g>
    </svg>
  );
};
