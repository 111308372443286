import { useTranslation } from "react-i18next";
import { useEffect, useMemo, useState } from "react";
import {
  ModelPicker,
  PlantModelOperations,
  PlantProperties,
  PlantSchema,
  ColorInput,
  ColorUtils,
  ModelDesignSvg,
  MonthSlider,
  MeasureInput,
  PlantCategory,
} from "draw";
import { MonthCheckboxes, PlantData, PlantImage } from "..";
import _ from "lodash";
import { Api, Delimiter } from "imagine-essentials";
import {
  Alert,
  Button,
  Checkbox,
  CollapsibleBox,
  ImageInput,
  Label,
  Link,
  Modal,
  NotificationType,
  NumberInput,
  Select,
  Slider,
  TextInput,
  useNotification,
} from "imagine-ui";
import { Colors, UnitScale } from "project";
import { I18nData } from "imagine-i18n";
import {
  FlowerProfilePosition,
  FlowerProfileTemplates,
  LeafProfileTemplates,
  PlantProfileData,
  PlantProfileSVG,
} from "scenery";

interface Props {
  plant: PlantSchema;
  onClose: () => void;
  onSavePlant: (
    plant: PlantSchema,
    image?: File,
    publicImage?: boolean
  ) => void;
  blockName?: boolean;
  loading?: boolean;
  editMode?: boolean;
  copyMode?: boolean;
  onOpenImageGallery: (latinName: string) => void;
  plantImage?: PlantImage; // In case plant should reuse an existing image
  delimiter: Delimiter;
  unitScale: UnitScale;
  showHardinessZoneUSDA?: boolean;
  showHardinessZoneRHS?: boolean;
  tunnelContent?: boolean;
  showAdminFields?: boolean;
  enableBetaFeatures?: boolean;
}

export const PlantEditorModal = (props: Props) => {
  const { t } = useTranslation();

  const [imagePublic, setImagePublic] = useState(false);

  const [plant, setPlant] = useState<PlantSchema>(_.cloneDeep(props.plant));

  const [newPlantImage, setNewPlantImage] = useState<File | undefined>(
    undefined
  );

  const categories = useMemo(() => {
    return PlantData.getCategoryOptions(false);
  }, []);

  const notification = useNotification();

  const [displayMonth, setDisplayMonth] = useState(7);
  const [showAllLeafProfileTemplates, setShowAllLeafProfileTemplates] =
    useState(false);
  // const [leafPercentage, setLeafPercentage] = useState(1);

  /**
   * Leaf height is set with a percentage slider, but stored as a value in cm in tha database.
   */
  const leafPercentage = useMemo(() => {
    if (plant.heightMin && plant.heightMax && plant.heightLeaf !== undefined) {
      const averagePlantHeight = (plant.heightMax + plant.heightMin) / 2;
      return plant.heightLeaf / averagePlantHeight;
    }
    return 1;
  }, [plant.heightMin, plant.heightMax, plant.heightLeaf]);

  const setLeafPercentage = (value: number) => {
    if (!plant.heightMin || !plant.heightMax) return;
    const averagePlantHeight = (plant.heightMax + plant.heightMin) / 2;
    const leafHeight = averagePlantHeight * value;
    updatePlant({ heightLeaf: leafHeight });
  };

  const showLeafHeightSlider = useMemo(() => {
    if (plant.flowerProfileTemplate === 0) return false;
    const template = FlowerProfileTemplates.find(
      (d) => d.id === plant.flowerProfileTemplate
    );
    return template?.flowerPosition === FlowerProfilePosition.EXTEND;
  }, [plant.flowerProfileTemplate]);

  const design = useMemo(() => {
    return PlantModelOperations.createDesign(plant, displayMonth);
  }, [plant, displayMonth]);

  const leafDesigns = useMemo(() => {
    const d = PlantModelOperations.getLeafDesigns(
      plant.leafColor,
      plant.category
    );
    return d;
  }, [plant.leafColor, plant.category]);

  const leafProfileDesigns = useMemo(() => {
    return PlantProfileData.getLeafProfileDesigns(
      plant.leafColor,
      showAllLeafProfileTemplates ? undefined : plant.category
    );
  }, [plant.leafColor, plant.category, showAllLeafProfileTemplates]);

  const flowerDesigns = useMemo(() => {
    return PlantModelOperations.getFlowerDesigns(plant.flowerColor);
  }, [plant.flowerColor]);

  const flowerProfileDesigns = useMemo(() => {
    return PlantProfileData.getFlowerProfileDesigns(
      plant.flowerColor,
      plant.leafColor,
      plant.leafProfileTemplate || 0
    );
  }, [plant.flowerColor, plant.leafColor, plant.leafProfileTemplate]);

  const fruitDesigns = useMemo(() => {
    return PlantModelOperations.getFruitDesigns(
      plant.fruitColor || Colors.red[0]
    );
  }, [plant.fruitColor]);

  const fruitProfileDesigns = useMemo(() => {
    return PlantProfileData.getFruitProfileDesigns(
      plant.fruitColor || Colors.red[0],
      plant.leafProfileTemplate || 0
    );
  }, [plant.fruitColor, plant.leafProfileTemplate]);

  const branchDesigns = useMemo(() => {
    return PlantModelOperations.getBranchDesigns(plant.branchColor);
  }, [plant.branchColor]);

  const branchProfileDesigns = useMemo(() => {
    return PlantProfileData.getBranchProfileDesigns(
      plant.branchColor,
      plant.leafProfileTemplate || 0
    );
  }, [plant.branchColor, plant.leafProfileTemplate]);

  const multiColoredLeaves = useMemo(() => {
    return (
      plant.leafColorVariegation !== undefined &&
      plant.leafColorVariegation !== null &&
      plant.leafColorVariegation !== ""
    );
  }, [plant.leafColorVariegation]);

  const multiColoredFlowers = useMemo(() => {
    return (
      plant.flowerColorVariegation !== undefined &&
      plant.flowerColorVariegation !== null &&
      plant.flowerColorVariegation !== ""
    );
  }, [plant.flowerColorVariegation]);

  const showLeafHeight = useMemo(() => {
    return (
      plant.category === PlantCategory.ANNUALS ||
      plant.category === PlantCategory.BULB ||
      plant.category === PlantCategory.GRASS ||
      plant.category === PlantCategory.PERENNIAL
    );
  }, [plant.category]);

  const setMultiColoredLeaves = (value: boolean) => {
    if (value) {
      updatePlant({
        leafColorVariegation: plant.leafColor,
        leafColorVariegationGroup: ColorUtils.getColorGroup(plant.leafColor),
      });
    } else {
      updatePlant({
        leafColorVariegation: undefined,
        leafColorVariegationGroup: undefined,
      });
    }
  };

  const setMultiColoredFlowers = (value: boolean) => {
    if (value) {
      updatePlant({
        flowerColorVariegation: plant.flowerColor,
        flowerColorVariegationGroup: ColorUtils.getColorGroup(
          plant.flowerColor
        ),
      });
    } else {
      updatePlant({
        flowerColorVariegation: "",
        flowerColorVariegationGroup: undefined,
      });
    }
  };

  const updatePlant = (object: Partial<PlantSchema>) => {
    const p = Object.assign(plant, object);
    setPlant({ ...p });
  };

  const updatePlantProfileLeaveTemplate = (value: number | undefined) => {
    // Check if current flower template is supported
    if (plant.flowerProfileTemplate && value !== undefined) {
      const template = FlowerProfileTemplates.find(
        (d) => d.id === plant.flowerProfileTemplate
      );
      if (template?.leafTemplates?.includes(value)) {
        updatePlant({ leafProfileTemplate: value });
        return;
      }
      // If not, set flower template to 0
      updatePlant({ leafProfileTemplate: value, flowerProfileTemplate: 0 });
      return;
    }
    updatePlant({ leafProfileTemplate: value });
  };

  const updatePlantProperties = (object: Partial<PlantProperties>) => {
    const p = Object.assign(plant.properties || {}, object);
    setPlant({
      ...plant,
      properties: p,
    });
  };

  const updateCategory = (val: number) => {
    const p = {
      ...plant,
      category: val,
    };
    setShowAllLeafProfileTemplates(false);
    setPlant(p);
  };

  // const updateMultiColored = (value: boolean) => {
  //   setMultiColored(value);
  //   if (!value) {
  //     updatePlant({ leafColorVariegation: undefined });
  //   }
  // };

  const imagePath = useMemo(() => {
    if (plant.image) {
      return Api.getHost() + "/plant-images/" + plant.image;
    } else return "";
  }, [plant.image]);

  useEffect(() => {
    if (props.plantImage) {
      updatePlant({
        imageId: props.plantImage.id,
        image: props.plantImage.image,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.plantImage]);

  const removeImage = () => {
    updatePlant({ imageId: undefined, image: "" });
  };

  /**
   * Only administrators and moderator can edit name on public plants. Users can only edit names on own private plants.
   * Copied plant can always be edited.
   */
  const blockPlantName = () => {
    return props.blockName;
  };

  const save = () => {
    const plantData = _.cloneDeep(plant);
    if (plantData.name.length < 1) {
      notification.add(NotificationType.INFO, t("plants:missingPlantName"));
      return;
    }
    if (plantData.latinName === undefined || plantData.latinName?.length < 1) {
      notification.add(
        NotificationType.INFO,
        t("plants:missingPlantLatinName")
      );
      return;
    }
    if (!plantData.heightMin || !plantData.heightMax) {
      notification.add(NotificationType.INFO, t("plants:missingHeight"));
      return;
    }
    if (plantData.leafSeason.length === 0) {
      notification.add(NotificationType.INFO, t("plants:missingLeavesSeason"));
      return;
    }

    if (plantData.flowerSeason.length === 0 && plantData.flowerTemplate > 0) {
      notification.add(NotificationType.INFO, t("plants:missingFlowerSeason"));
      return;
    }
    // Add flower color groups for filtering
    if (plantData.flowerSeason.length > 0) {
      plantData.flowerColorGroup = ColorUtils.getColorGroup(
        plantData.flowerColor
      );
      if (multiColoredFlowers) {
        plantData.flowerColorVariegationGroup = ColorUtils.getColorGroup(
          plantData.flowerColorVariegation
        );
      }
    }
    // Add leaf color groups for filtering
    plantData.leafColorGroup = ColorUtils.getColorGroup(plantData.leafColor);
    if (multiColoredLeaves) {
      plantData.leafColorVariegationGroup = ColorUtils.getColorGroup(
        plantData.leafColorVariegation
      );
    }

    props.onSavePlant(plantData, newPlantImage, imagePublic);
  };

  const hardinessZoneUsdaOptions = useMemo(() => {
    if (props.showHardinessZoneUSDA) {
      return PlantData.getHardinessZoneOptionsUSDA(t("plants:unknownZone"));
    }
    return [];
  }, [props.showHardinessZoneUSDA, t]);

  const hardinessZoneRhsOptions = useMemo(() => {
    if (props.showHardinessZoneRHS) {
      return PlantData.getHardinessZoneOptionsRHS(t("plants:unknownZone"));
    }
    return [];
  }, [props.showHardinessZoneRHS, t]);

  const countryOptions = useMemo(() => {
    return I18nData.getCountryOptions();
  }, []);

  return (
    <Modal
      title={props.editMode ? t("plants:editPlant") : t("plants:createPlant")}
      visible
      onClose={() => props.onClose()}
      onConfirm={save}
      confirmText={t("imagine:save")}
      size={"xl"}
      loading={props.loading}
      elementId="plant-editor-modal"
      tunnelContent={props.tunnelContent}
    >
      <>
        {plant.public && props.editMode && !props.showAdminFields && (
          <Alert color="warning" className="mb-lg">
            {t("plants:publicPlantWarning")}
          </Alert>
        )}
        {props.copyMode && (
          <Alert color="warning" className="mb-lg">
            {t("plants:copyPlantMessage")}
          </Alert>
        )}
        {props.showAdminFields && (
          <>
            <div className="grid columns-3 columns-2--md gap mb">
              <div>
                <Label>{t("imagine:country")}</Label>
                <Select
                  options={countryOptions}
                  selected={plant.country}
                  onSelectedString={(val: string) =>
                    updatePlant({ country: val })
                  }
                />
              </div>
              <div>
                <Checkbox
                  id={"plant-editor-public"}
                  label={t("plants:publicPlant")}
                  checked={plant.public || false}
                  onChange={(checked: boolean) =>
                    updatePlant({ public: checked })
                  }
                  className="mt-lg pt-xs"
                />
              </div>
            </div>
            <hr className="separator my" />
          </>
        )}
        <div className="grid columns-3 columns-2--md gap">
          <div className="colspan-2">
            <div className="grid columns-2 columns-1--sm gap">
              <div>
                <div className="mb">
                  <Label>{t("imagine:name")}*:</Label>
                  <TextInput
                    elementId="plant-editor-name"
                    value={plant.name || ""}
                    onFinish={(name: string) => updatePlant({ name: name })}
                    disabled={blockPlantName()}
                  />
                </div>

                <div className="mb">
                  <Label>{t("plants:latinName")}:</Label>
                  <TextInput
                    elementId="plant-editor-latin-name"
                    value={plant.latinName || ""}
                    onFinish={(name: string) =>
                      updatePlant({ latinName: name })
                    }
                    disabled={blockPlantName()}
                  />
                </div>

                <div className="mb">
                  <Label>{t("plants:hybridName")}:</Label>
                  <TextInput
                    elementId="plant-editor-hybrid-name"
                    value={plant.hybridName || ""}
                    onFinish={(name: string) =>
                      updatePlant({ hybridName: name })
                    }
                    disabled={blockPlantName()}
                  />
                </div>

                {props.showHardinessZoneUSDA && (
                  <div className="mb">
                    <Label>{t("plants:hardinessZoneUSDA")}:</Label>
                    <div className="row items-y-center">
                      <Select
                        elementId="plant-editor-hardiness-zone-min"
                        selected={plant.hardinessZoneMin || 0}
                        onSelectedNumber={(val: number) =>
                          updatePlant({ hardinessZoneMin: val })
                        }
                        options={hardinessZoneUsdaOptions}
                        className="stretch"
                      />
                      <span className="px-sm">-</span>
                      <Select
                        elementId="plant-editor-hardiness-zone-max"
                        selected={plant.hardinessZoneMax || 0}
                        onSelectedNumber={(val: number) =>
                          updatePlant({ hardinessZoneMax: val })
                        }
                        options={hardinessZoneUsdaOptions}
                        className="stretch"
                      />
                    </div>
                  </div>
                )}
                {props.showHardinessZoneRHS && (
                  <div className="mb">
                    <Label>{t("plants:hardinessZoneRHS")}:</Label>
                    <Select
                      elementId="plant-editor-hardiness-zone-min"
                      selected={plant.hardinessZoneRhs || 0}
                      onSelectedNumber={(val: number) =>
                        updatePlant({ hardinessZoneRhs: val })
                      }
                      options={hardinessZoneRhsOptions}
                      className="stretch"
                    />
                  </div>
                )}
              </div>
              <div>
                <div className="mb">
                  <Label>{t("imagine:category")}*:</Label>
                  <Select
                    elementId="plant-editor-category"
                    options={categories}
                    onSelectedNumber={updateCategory}
                    selected={plant.category as number}
                  />
                </div>

                <div className="mb">
                  {props.unitScale === UnitScale.METRIC && (
                    <Label>
                      {t("imagine:height")}{" "}
                      {showLeafHeight
                        ? "(" + t("plants:includingFlowers") + ")"
                        : ""}
                      :
                    </Label>
                  )}
                  {props.unitScale === UnitScale.IMPERIAL && (
                    <Label>
                      {t("plants:minHeight")}{" "}
                      {showLeafHeight
                        ? "(" + t("plants:includingFlowers") + ")"
                        : ""}
                      :
                    </Label>
                  )}

                  <div
                    className={
                      props.unitScale === UnitScale.METRIC
                        ? "row items-y-center"
                        : ""
                    }
                  >
                    <div className="stretch">
                      <MeasureInput
                        elementId="plant-editor-height-min"
                        value={plant.heightMin || 0}
                        delimiter={props.delimiter}
                        unitScale={props.unitScale}
                        onFinish={(value: number) =>
                          updatePlant({ heightMin: value })
                        }
                        omitYards
                        omitFractions
                      />
                    </div>
                    {props.unitScale === UnitScale.METRIC && (
                      <div className="px-sm">-</div>
                    )}
                    {props.unitScale === UnitScale.IMPERIAL && (
                      <Label className="mt">
                        {t("plants:maxHeight")}{" "}
                        {showLeafHeight
                          ? "(" + t("plants:includingFlowers") + ")"
                          : ""}
                        :
                      </Label>
                    )}

                    <div className="stretch">
                      <MeasureInput
                        elementId="plant-editor-height-max"
                        value={plant.heightMax || 0}
                        delimiter={props.delimiter}
                        unitScale={props.unitScale}
                        onFinish={(value: number) =>
                          updatePlant({ heightMax: value })
                        }
                        omitYards
                        omitFractions
                      />
                    </div>
                  </div>
                </div>

                <div className="mb">
                  <Label>{t("imagine:width")}*:</Label>
                  <MeasureInput
                    elementId="plant-editor-width"
                    value={plant.width}
                    onFinish={(value: number) => updatePlant({ width: value })}
                    delimiter={props.delimiter}
                    unitScale={props.unitScale}
                    omitYards
                    omitFractions
                  />
                </div>

                <div className="mb">
                  <Label>{t("plants:timeToMaturity")}:</Label>
                  <div className="stretch">
                    <NumberInput
                      elementId="plant-editor-time-to-mature"
                      value={plant.timeToMaturity || 0}
                      delimiter={props.delimiter}
                      onFinish={(value: number) =>
                        updatePlant({ timeToMaturity: value })
                      }
                      unit={t("plants:years")}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mb">
              <Label>{t("imagine:note")}:</Label>
              <TextInput
                elementId="plant-editor-note"
                value={plant.note || ""}
                onFinish={(name: string) => updatePlant({ note: name })}
              />
            </div>
          </div>

          <div>
            <Label>{t("imagine:image")}:</Label>
            {newPlantImage && (
              <Checkbox
                label={t("plants:haveImageRights")}
                checked={imagePublic}
                onChange={(val) => setImagePublic(val)}
                className="mb-sm"
                id="image-public"
              />
            )}
            <ImageInput
              id="plant-image"
              onImageSelected={setNewPlantImage}
              initialImage={imagePath}
              previewSizeRatio={{ width: 1, height: 1 }}
              maxSize={5}
              onError={(message: string) =>
                notification.add(NotificationType.ERROR, message)
              }
              onRemoveImage={removeImage}
            />
            {plant.latinName !== "" && plant.latinName !== undefined && (
              <Button
                elementId="select-existing-plant-image"
                outline
                className="mt"
                onClick={() => props.onOpenImageGallery(plant.latinName || "")}
              >
                {t("plants:selectExistingImage")}
              </Button>
            )}
          </div>
        </div>
        <hr className="separator my" />
        <h3 className="my-xl">{t("plants:layout")}</h3>
        <CollapsibleBox
          title={t("plants:leaves")}
          initialOpen
          elementId="plant-editor-leaf-section"
        >
          <div className="grid columns-4 columns-2--md columns-1--sm gap-lg">
            <div className="">
              <h6 className="my">{t("plants:colorAndSeason")}</h6>
              <ColorInput
                elementId="plant-editor-leaf-color"
                color={plant.leafColor}
                onChange={(color: string) => updatePlant({ leafColor: color })}
              />
              {multiColoredLeaves && (
                <ColorInput
                  color={plant.leafColorVariegation}
                  onChange={(color: string) =>
                    updatePlant({ leafColorVariegation: color })
                  }
                  className="mt"
                  elementId="plant-editor-leaf-color-secondary"
                />
              )}
              <Checkbox
                id={"plant-editor-multi-colored"}
                label={t("plants:multiColored")}
                checked={multiColoredLeaves}
                onChange={setMultiColoredLeaves}
                className="mt"
              />

              <Checkbox
                id={"plant-editor-see-through"}
                label={t("plants:seeThrough")}
                checked={plant.leafTransparent || false}
                onChange={(checked: boolean) =>
                  updatePlant({ leafTransparent: checked })
                }
                className="mt-sm"
              />
              <MonthCheckboxes
                selected={plant.leafSeason}
                direction="vertical"
                idPrefix="plant-editor-leaf-season"
                onChange={(season: number[]) =>
                  updatePlant({ leafSeason: season })
                }
                className="mt-sm"
              ></MonthCheckboxes>
            </div>
            <div>
              <div className="">
                <h6 className="my">{t("plants:topView")}</h6>
                <ModelPicker
                  designs={leafDesigns}
                  selected={plant.leafTemplate}
                  onSelect={(value) =>
                    updatePlant({ leafTemplate: value || plant.leafTemplate })
                  }
                  idPrefix="plant-editor-leaf-design"
                />
              </div>
            </div>

            <div className="">
              <h6 className="my">{t("plants:sideView")}</h6>
              <div className="h-250 scroll-y-always">
                <ModelPicker
                  designs={leafProfileDesigns}
                  selected={plant.leafProfileTemplate}
                  max={30}
                  onSelect={(value) =>
                    updatePlantProfileLeaveTemplate(
                      value || plant.leafProfileTemplate
                    )
                  }
                  idPrefix="plant-editor-leaf-profile-design"
                />

                {!showAllLeafProfileTemplates && (
                  <div className="text-center">
                    <Link onClick={() => setShowAllLeafProfileTemplates(true)}>
                      {t("imagine:showAll")}
                    </Link>
                  </div>
                )}
              </div>
            </div>

            <div className="">
              <h6 className="my">{t("plants:transitionalColorAndSeason")}</h6>
              <ColorInput
                elementId="plant-editor-leaf-color-transitional"
                color={plant.leafColorTransitional || plant.leafColor}
                onChange={(color: string) =>
                  updatePlant({ leafColorTransitional: color })
                }
              />
              <MonthCheckboxes
                idPrefix="plant-editor-leaf-color-transitional-season"
                selected={plant.leafColorTransitionalSeason || []}
                // direction="horizontal"
                onChange={(season: number[]) =>
                  updatePlant({ leafColorTransitionalSeason: season })
                }
                className="mt"
              ></MonthCheckboxes>
            </div>
          </div>
        </CollapsibleBox>

        <CollapsibleBox
          title={t("plants:flowers")}
          className="mt"
          elementId="plant-editor-flower-section"
        >
          <div className="grid columns-4 columns-2--md columns-1--sm gap-lg">
            <div className="">
              <h6 className="my">{t("plants:colorAndSeason")}</h6>
              <ColorInput
                elementId="plant-editor-flower-color"
                color={plant.flowerColor}
                onChange={(color: string) =>
                  updatePlant({ flowerColor: color })
                }
              />

              {multiColoredFlowers && (
                <ColorInput
                  color={plant.flowerColorVariegation}
                  onChange={(color: string) =>
                    updatePlant({ flowerColorVariegation: color })
                  }
                  className="mt"
                  elementId="plant-editor-flower-color-secondary"
                />
              )}
              <Checkbox
                id={"plant-editor-multi-colored-flowers"}
                label={t("plants:multiColored")}
                checked={multiColoredFlowers}
                onChange={setMultiColoredFlowers}
                className="mt"
              />

              <MonthCheckboxes
                idPrefix="plant-editor-flower-season"
                selected={plant.flowerSeason}
                onChange={(season: number[]) =>
                  updatePlant({ flowerSeason: season })
                }
                className="mt"
              ></MonthCheckboxes>
            </div>
            <div className="">
              <h6 className="my">{t("plants:topView")}</h6>
              <ModelPicker
                idPrefix="plant-editor-flower-design"
                designs={flowerDesigns}
                selected={plant.flowerTemplate}
                includeClear
                onSelect={(value) =>
                  updatePlant({ flowerTemplate: value || 0 })
                }
              />
            </div>

            <div>
              <h6 className="my">{t("plants:sideView")}</h6>
              <div className="h-250 scroll-y-always">
                <ModelPicker
                  designs={flowerProfileDesigns}
                  selected={plant.flowerProfileTemplate}
                  includeClear
                  max={30}
                  onSelect={(value) =>
                    updatePlant({
                      flowerProfileTemplate: value,
                    })
                  }
                  idPrefix="plant-editor-flower-profile-design"
                />
              </div>
            </div>
          </div>
        </CollapsibleBox>

        <CollapsibleBox
          title={t("plants:trunkOrBranches")}
          className="mt"
          elementId="plant-editor-branches-section"
        >
          <div className="grid columns-4 columns-2--md columns-1--sm gap-lg">
            <div className="">
              <h6 className="my">{t("imagine:color")}</h6>
              <ColorInput
                elementId="plant-editor-branch-color"
                color={plant.branchColor}
                onChange={(color: string) =>
                  updatePlant({ branchColor: color })
                }
              />
            </div>
            <div className="">
              <h6 className="my">{t("plants:topView")}</h6>
              <ModelPicker
                idPrefix="plant-editor-branch-design"
                designs={branchDesigns}
                selected={plant.branchTemplate}
                includeClear
                onSelect={(value) =>
                  updatePlant({ branchTemplate: value || 0 })
                }
              />
            </div>

            <div className="">
              <h6 className="my">{t("plants:sideView")}</h6>
              <ModelPicker
                designs={branchProfileDesigns}
                selected={plant.branchProfileTemplate}
                includeClear
                onSelect={(value) =>
                  updatePlant({
                    branchProfileTemplate: value,
                  })
                }
                idPrefix="plant-editor-branch-profile-design"
              />
            </div>
          </div>
        </CollapsibleBox>

        <CollapsibleBox
          title={t("plants:fruitsAndBerries")}
          className="mt"
          elementId="plant-editor-fruit-section"
        >
          <div className="grid columns-4 columns-2--md columns-1--sm gap-lg">
            <div className="">
              <h6 className="my">{t("plants:colorAndSeason")}</h6>
              <ColorInput
                elementId="plant-editor-fruit-color"
                color={plant.fruitColor}
                onChange={(color: string) => updatePlant({ fruitColor: color })}
              />

              <MonthCheckboxes
                idPrefix="plant-editor-fruit-season"
                selected={plant.fruitSeason || []}
                onChange={(season: number[]) =>
                  updatePlant({ fruitSeason: season })
                }
                className="mt"
              ></MonthCheckboxes>
            </div>
            <div className="">
              <h6 className="my">{t("plants:topView")}</h6>
              <ModelPicker
                idPrefix="plant-editor-fruit-design"
                designs={fruitDesigns}
                selected={plant.fruitTemplate}
                includeClear
                onSelect={(value) => updatePlant({ fruitTemplate: value || 0 })}
              />
            </div>

            <div>
              <h6 className="my">{t("plants:sideView")}</h6>
              <div className="h-250 scroll-y-always">
                <ModelPicker
                  designs={fruitProfileDesigns}
                  selected={plant.fruitProfileTemplate}
                  includeClear
                  onSelect={(value) =>
                    updatePlant({
                      fruitProfileTemplate: value,
                    })
                  }
                  idPrefix="plant-editor-fruit-profile-design"
                />
              </div>
            </div>
          </div>
        </CollapsibleBox>

        <hr className="my" />
        <div
          className={
            "row items-center" +
            (plant.flowerColor === "#ffffff" ? " bg-secondary py" : "")
          }
        >
          <div className="w-250 h-250 mr" id="plant-editor-plant-preview">
            <ModelDesignSvg
              design={design}
              size={{ width: 250, height: 250 }}
              position={{ x: 0, y: 0 }}
            />
          </div>

          <>
            <div className="w-250 h-250 ml" id="plant-editor-plant-profile">
              {plant.heightMin && plant.heightMax ? (
                <>
                  <PlantProfileSVG
                    plant={plant}
                    size={{ width: 250, height: 250 }}
                    position={{ x: 0, y: 0 }}
                    month={displayMonth}
                  />
                </>
              ) : (
                <p className="text-center p">
                  {t("plants:setHeightToShowSideview")}
                </p>
              )}
            </div>
            {showLeafHeightSlider && (
              <div className="ml">
                <Slider
                  min={0.1}
                  max={1}
                  value={leafPercentage}
                  onChange={setLeafPercentage}
                  stepSize={0.05}
                  vertical
                  className="h-250"
                />
              </div>
            )}
          </>
        </div>
        <div className="bg-light">
          <MonthSlider
            month={displayMonth}
            onChanged={(month: number) => setDisplayMonth(month)}
          ></MonthSlider>
        </div>
        <hr className="my" />
        <h3 className="my-xl">{t("plants:plantProperties")}</h3>
        <div className="grid columns-4 columns-2--lg columns-1--sm">
          <div className="mt">
            <Label>{t("plants:lightConditions")}:</Label>
            <Checkbox
              id={"plant-editor-property-sun"}
              label={t("plants:sun")}
              checked={plant.properties?.sun || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ sun: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-semi-shade"}
              label={t("plants:semiShade")}
              checked={plant.properties?.semiShade || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ semiShade: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-shade"}
              label={t("plants:shade")}
              checked={plant.properties?.shade || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ shade: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:soilTypes")}:</Label>
            <Checkbox
              id={"plant-editor-property-loam-soil"}
              label={t("plants:loamySoil")}
              checked={plant.properties?.loamSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ loamSoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-sandy-soil"}
              label={t("plants:sandySoil")}
              checked={plant.properties?.sandySoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ sandySoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-clay-soil"}
              label={t("plants:claySoil")}
              checked={plant.properties?.claySoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ claySoil: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:waterConditions")}:</Label>
            <Checkbox
              id={"plant-editor-property-drained"}
              label={t("plants:wellDrainedSoil")}
              checked={plant.properties?.drainedSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ drainedSoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-dry-soil"}
              label={t("plants:drySoil")}
              checked={plant.properties?.drySoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ drySoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-wet-soil"}
              label={t("plants:wetSoil")}
              checked={plant.properties?.wetSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ wetSoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-water-plant"}
              label={t("plants:waterPlant")}
              checked={plant.properties?.waterPlant || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ waterPlant: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:phConditions")}:</Label>
            <Checkbox
              id={"plant-editor-property-neutral-soil"}
              label={t("plants:neutralSoil")}
              checked={plant.properties?.neutralSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ neutralSoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-acid-soil"}
              label={t("plants:acidSoil")}
              checked={plant.properties?.acidSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ acidSoil: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-alkalic-soil"}
              label={t("plants:alkalicSoil")}
              checked={plant.properties?.alkalicSoil || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ alkalicSoil: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:plantUsage")}:</Label>
            <Checkbox
              id={"plant-editor-property-groundcover"}
              label={t("plants:groundcover")}
              checked={plant.properties?.groundcover || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ groundcover: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-edible"}
              label={t("plants:edibleFruitsBerryEtc")}
              checked={plant.properties?.edible || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ edible: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-cutFlower"}
              label={t("plants:cutFlower")}
              checked={plant.properties?.cutFlowers || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ cutFlowers: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-pots"}
              label={t("plants:pots")}
              checked={plant.properties?.pots || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ pots: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-hedge"}
              label={t("plants:hedge")}
              checked={plant.properties?.hedge || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ hedge: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-coast"}
              label={t("plants:coastalArea")}
              checked={plant.properties?.coast || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ coast: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:contributions")}:</Label>
            <Checkbox
              id={"plant-editor-property-bees"}
              label={t("plants:attractsBees")}
              checked={plant.properties?.bees || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ bees: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-birds"}
              label={t("plants:goodForBirds")}
              checked={plant.properties?.birds || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ birds: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-nitrogen"}
              label={t("plants:nitrogenFixating")}
              checked={plant.properties?.nitrogen || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ nitrogen: value })
              }
            />

            <Checkbox
              id={"plant-editor-property-carbon"}
              label={t("plants:carbonFarming")}
              checked={plant.properties?.carbon || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ carbon: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-native"}
              label={t("plants:native")}
              checked={plant.properties?.native || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ native: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:plantQualities")}:</Label>

            <Checkbox
              id={"plant-editor-property-evergreen"}
              label={t("plants:evergreen")}
              checked={plant.properties?.evergreen || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ evergreen: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-winter"}
              label={t("plants:winterInterest")}
              checked={plant.properties?.winter || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ winter: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-fall-colors"}
              label={t("plants:interestingFallColors")}
              checked={plant.properties?.fallColors || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ fallColors: value })
              }
            />

            <Checkbox
              id={"plant-editor-property-fragrant"}
              label={t("plants:fragrant")}
              checked={plant.properties?.fragrant || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ fragrant: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-allergyFriendly"}
              label={t("plants:allergyFriendly")}
              checked={plant.properties?.allergyFriendly || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ allergyFriendly: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-wind"}
              label={t("plants:windTolerant")}
              checked={plant.properties?.wind || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ wind: value })
              }
            />
          </div>

          <div className="mt">
            <Label>{t("plants:beware")}:</Label>
            <Checkbox
              id={"plant-editor-property-poisonous"}
              label={t("plants:poisonous")}
              checked={plant.properties?.poisonous || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ poisonous: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-thorns"}
              label={t("plants:thorns")}
              checked={plant.properties?.thorns || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ thorns: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-root-spreading"}
              label={t("plants:speadingByRoots")}
              checked={plant.properties?.rootSpreading || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ rootSpreading: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-seed-spreading"}
              label={t("plants:speadingBySeeds")}
              checked={plant.properties?.seedSpreading || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ seedSpreading: value })
              }
            />
            <Checkbox
              id={"plant-editor-property-special-care"}
              label={t("plants:specialCare")}
              checked={plant.properties?.specialCare || false}
              onChange={(value: boolean) =>
                updatePlantProperties({ specialCare: value })
              }
            />
          </div>
        </div>
      </>
    </Modal>
  );
};
