import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import {
  Membership,
  MembershipDeal,
  MembershipPeriod,
  MembershipProduct,
  MembershipsText,
} from "..";
import { CurrencyTools } from "imagine-i18n";
import { ProductCard } from "imagine-ui";

type Props = {
  membershipProduct: MembershipProduct;
  membership?: Membership; // Displays an active membership if set (price overrides product)
  onButtonClick?: () => void;
  hideButton?: boolean;
  // changeMembership?: boolean; // If user can change to this, instead of buying this
  elementId?: string;
  // userRole?: UserRole;
  membershipDeal?: MembershipDeal; // In case a membership deal should be offered to the user
  continuationMembershipDeal?: MembershipDeal; // In case a membership deal is continued on another deal
};

/**
 * Displays a product card. If a membership is set, then it will show the data from the membership. Otherwise it
 * will use data from the membership product, and possibly add membership deal affecting the product.
 */
export const MembershipProductCard = (props: Props) => {
  const { t } = useTranslation();

  const buttonText = useMemo(() => {
    if (props.membershipProduct.canBuy) {
      return t("memberships:buy");
    }
    if (props.membershipProduct.canSwitch) {
      return t("memberships:switchMembership");
    }
    if (props.membershipProduct.canCreate) {
      return t("users:createAccount");
    }
    return undefined;
  }, [props.membershipProduct, t]);

  const changeMembershipText = useMemo(() => {
    if (props.hideButton) return "";
    if (props.membershipProduct.canSwitch) {
      return t("memberships:newMembershipStartWhenCurrentExpires");
    }

    return "";
  }, [props.hideButton, props.membershipProduct.canSwitch, t]);

  const price = useMemo(() => {
    if (props.membershipProduct === undefined) return 0;
    if (props.membership) return props.membership.price;
    return props.membershipProduct.price ?? 0;
  }, [props.membership, props.membershipProduct]);

  const currency = useMemo(() => {
    return props.membership?.currency ?? props.membershipProduct.currency;
  }, [props.membership?.currency, props.membershipProduct]);

  const validMembershipDeal = useMemo(() => {
    if (props.membershipDeal && props.membershipProduct) {
      const type = props.membership?.type ?? props.membershipProduct.type;
      if (props.membershipDeal.membershipType === type) {
        return props.membershipDeal;
      }
    }
    return undefined;
  }, [props.membership?.type, props.membershipDeal, props.membershipProduct]);

  const period = useMemo(() => {
    if (
      props.membershipProduct === undefined ||
      price === undefined ||
      currency === undefined
    )
      return "";
    let period = MembershipsText.getMembershipPeriodText(
      props.membershipProduct.period
    );

    if (validMembershipDeal && validMembershipDeal.count > 0) {
      if (props.membershipProduct.period === MembershipPeriod.YEAR) {
        period = t("memberships:firstXYears", {
          count: validMembershipDeal.count,
        });
      } else if (props.membershipProduct.period === MembershipPeriod.MONTH) {
        period = t("memberships:firstXMonths", {
          count: validMembershipDeal.count,
        });
      }
    }
    return period;
  }, [currency, price, props.membershipProduct, t, validMembershipDeal]);

  const subPeriod = useMemo(() => {
    if (props.membershipProduct === undefined) return "";
    if (validMembershipDeal === undefined) return "";
    let productPrice = CurrencyTools.getDisplayPrice(
      props.membershipProduct.price,
      currency,
      false
    );
    if (props.continuationMembershipDeal) {
      productPrice = CurrencyTools.getDisplayPrice(
        props.membershipProduct.price -
          props.membershipProduct.price *
            props.continuationMembershipDeal.discount,
        currency,
        false
      );
    }
    const text =
      t("memberships:hereafterRenewsAt") +
      " " +
      productPrice +
      " " +
      MembershipsText.getMembershipPeriodText(props.membershipProduct.period);
    return text;
  }, [
    currency,
    props.continuationMembershipDeal,
    props.membershipProduct,
    t,
    validMembershipDeal,
  ]);

  const features = useMemo(() => {
    if (props.membershipProduct.featureKeys === undefined) return [];
    return props.membershipProduct.featureKeys?.map((featureKey: string) => {
      return t(featureKey);
    });
  }, [props.membershipProduct.featureKeys, t]);

  return (
    <>
      <ProductCard
        title={t(props.membershipProduct.titleKey)}
        features={features}
        buttonText={buttonText}
        price={price}
        currency={currency}
        footerText={changeMembershipText}
        discount={validMembershipDeal?.discount}
        discountName={validMembershipDeal?.name}
        discountDescription={validMembershipDeal?.description}
        period={period}
        subPeriod={subPeriod}
        onButtonClick={props.onButtonClick}
        actualPrice={props.membership !== undefined}
        elementId={props.elementId}
      />
    </>
  );
};
