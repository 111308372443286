import { useMemo } from "react";
import { BonusDays, Membership } from "..";
import { useTranslation } from "react-i18next";
import { DateTimeFormat, DateTimeText, DateTimeTools } from "imagine-datetime";

type Props = {
  className?: string;
  membership: Membership;
  renewed?: boolean;
  bonusDays: BonusDays[];
};

/**
 * The page displays a summary of the membership containing title, start date, expiry date (incl. bonus days).
 */
export const MembershipSummary = (props: Props) => {
  const { t } = useTranslation();

  const membershipBonusDays = useMemo(() => {
    return props.bonusDays.filter(
      (bonusDays: BonusDays) => bonusDays.membershipId === props.membership.id
    );
  }, [props.bonusDays, props.membership.id]);

  const bonusExpiryDate = useMemo(() => {
    if (membershipBonusDays.length === 0) return undefined;
    let expiry = membershipBonusDays[0].expiryDate;
    membershipBonusDays.forEach((bonus: BonusDays) => {
      if (DateTimeTools.isDateBefore(expiry, bonus.expiryDate)) {
        expiry = bonus.expiryDate;
      }
    });
    return expiry;
  }, [membershipBonusDays]);

  /**
   * Get the actual expiry date for a membership by including possible bonus days.
   */
  const getActualExpiryDate = () => {
    if (props.membership === undefined) return "";
    // if (props.membership.bonusExpiryDate === null) return t("memberships:never");
    // if (
    //   props.membership.bonusExpiryDate !== undefined &&
    //   props.membership.bonusExpiryDate !== null
    // )
    //   return props.membership.bonusExpiryDate;
    if (props.membership.expiryDate === null) return t("memberships:never");

    return bonusExpiryDate ?? props.membership.expiryDate;
    // let expiryDate = props.membership.expiryDate;
    // if (props.bonusDays) {
    //   if (props.bonusDays.length > 0) {
    //     const lastBonus = props.bonusDays[props.bonusDays.length - 1];
    //     expiryDate = lastBonus.expiryDate;
    //   }
    // }
    // if (expiryDate === null) return "";
    // return expiryDate;
  };

  const hasBonusDays = () => {
    // return (
    //   props.membership.bonusExpiryDate !== undefined &&
    //   props.membership.bonusExpiryDate !== null
    // );
    return membershipBonusDays.length > 0;
  };

  /**
   * Get total number of bonus days added to the membership.
   */
  const getBonusDaysCount = () => {
    if (!hasBonusDays()) return 0;
    if (props.membership.expiryDate !== null && bonusExpiryDate !== undefined) {
      const originalExpiry = DateTimeTools.getDateFromDbFormat(
        props.membership.expiryDate
      ).getTime();
      // const bonusExpiryDate = DateTimeConversion.getDateFromDbFormat(
      //   props.membership.bonusExpiryDate
      // ).getTime();
      const bonusExpiry =
        DateTimeTools.getDateFromDbFormat(bonusExpiryDate).getTime();
      return Math.round((bonusExpiry - originalExpiry) / (1000 * 3600 * 24));
    }
  };

  return (
    <div className={props.className}>
      <>
        <h2 className="mb-xl mt-none">{t(props.membership.titleKey)}</h2>
        <p>
          {t("memberships:activationDate")}:&nbsp;
          {DateTimeText.getDbDateTimeText(
            props.membership!.startDate,
            DateTimeFormat.LONG_DATE
          )}
        </p>
        {props.renewed && <p></p>}
        {!props.renewed &&
          props.membership.renew &&
          props.membership.expiryDate && (
            <>
              <p id="membership-summary-next-payment">
                {t("memberships:nextPayment")}:&nbsp;
                {DateTimeText.getDbDateTimeText(
                  getActualExpiryDate(),
                  DateTimeFormat.LONG_DATE
                )}
                {hasBonusDays() && (
                  <span className="text-dark-muted">
                    &nbsp;(
                    {t("memberships:includingCountBonusDays", {
                      count: getBonusDaysCount(),
                    })}
                    )
                  </span>
                )}
              </p>
              {hasBonusDays() && (
                <p className="text-dark-muted text-italic text-sm mt-sm">
                  {t("memberships:nextPaymentWasOriginallyDate", {
                    nextPaymentDate: DateTimeText.getDbDateTimeText(
                      props.membership!.expiryDate,
                      DateTimeFormat.LONG_DATE
                    ),
                  })}
                </p>
              )}
            </>
          )}
        {(props.renewed ||
          (!props.membership.renew &&
            props.membership.expiryDate !== null)) && (
          <>
            <p id="membership-summary-expiry-date">
              {t("memberships:expiryDate")}:&nbsp;
              {DateTimeText.getDbDateTimeText(
                getActualExpiryDate(),
                DateTimeFormat.LONG_DATE
              )}
              {hasBonusDays() && (
                <span className="text-dark-muted">
                  &nbsp;(
                  {t("memberships:includingCountBonusDays", {
                    count: getBonusDaysCount(),
                  })}
                  )
                </span>
              )}
            </p>
            {hasBonusDays() && (
              <p className="text-dark-muted text-italic text-sm">
                {t("memberships:expiryDateWasOriginallyDate", {
                  expiryDate: DateTimeText.getDbDateTimeText(
                    props.membership.expiryDate!,
                    DateTimeFormat.LONG_DATE
                  ),
                })}
              </p>
            )}
            {props.renewed && (
              <span className="badge bg-secondary">
                {t("memberships:replacedByNewMembership")}
              </span>
            )}
          </>
        )}
        {!props.renewed && !props.membership.expiryDate && (
          <p id="membership-summary-no-expiry-date">
            {t("memberships:expiryDate")}:&nbsp;
            {t("memberships:never")}
          </p>
        )}
      </>
    </div>
  );
};
