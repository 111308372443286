import { DisplayProduct, SubscriptionProductCard } from "..";

interface Props {
  products: DisplayProduct[];
}

export const StripeProductListing = (props: Props) => {
  return (
    <div
      id="buy-membership-select"
      className={"grid gap columns-" + props.products.length}
    >
      {props.products.map((product, index) => (
        <div className="row items-space-around" key={product.id + "-" + index}>
          <SubscriptionProductCard
            key={product.id + "-" + index}
            product={product}
            elementId={"membership-" + index.toString()}
          />
        </div>
      ))}
    </div>
  );
};
