import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  PlanEditorActions,
  PlanEditorSelector,
  PlantManagementActions,
  LayoutActions,
} from "../../store";
import { Item, ItemType, Measure, PlantModelText, PlantSchema } from "draw";
import {
  ToolButton,
  ToolGroup,
  ToolInfo,
  ToolNumberInput,
  ToolSeparator,
} from "../../components";
import { useMemo } from "react";
import { Api, Tools } from "imagine-essentials";
import { PlantText } from "plants";
import { Pages } from "../../enums";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Displays the tool and actions in the plant item toolbar.
 * @param props
 * @returns
 */
export const PlantItemToolbarContainer = () => {
  const { t } = useTranslation();
  const selectedPlantItems = useAppSelector(
    PlanEditorSelector.getSelectedPlants
  );
  const user = useAppSelector(UserSelector.getUserNotNull);
  const UserPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);
  const visibleItems = useAppSelector(PlanEditorSelector.getVisibleItems);
  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);

  const dispatch = useAppDispatch();

  /**
   * The template of the selected plant. If multiple plants are selected and they are all
   * the same template, this is also set. Not set if multiple different plants are selected.
   */
  const selectedPlantTemplate = useMemo(() => {
    if (selectedPlantItems.length < 1) return undefined;
    const selectedTemplateId = selectedPlantItems[0].templateId;

    if (selectedPlantItems.length > 1) {
      const differentTemplate = selectedPlantItems.find(
        (item: Item) => item.templateId !== selectedTemplateId
      );
      if (differentTemplate) return undefined;
    }
    return plantTemplates.find(
      (template: PlantSchema) => template.id === selectedTemplateId
    );
  }, [plantTemplates, selectedPlantItems]);

  /**
   * Shows the actual item diameter if one plant is selected or all selected plants have the same diameter.
   * Otherwise diameter from template
   */
  const displayedPlantDiameter = useMemo(() => {
    if (selectedPlantItems.length > 0 && selectedPlantTemplate) {
      const diameter = selectedPlantItems[0].size.width;
      let different = false;
      selectedPlantItems.forEach((item: Item) => {
        if (item.size.width !== diameter) {
          different = true;
        }
      });
      return different ? selectedPlantTemplate.width : diameter;
    }
  }, [selectedPlantItems, selectedPlantTemplate]);

  const updateDiameter = (val: number) => {
    if (selectedPlantItems.length < 1) return;
    // Update all selected plants
    const newPlantItems = selectedPlantItems.map((item: Item) => {
      const originalDiameter = item.size.width;
      const originalPos = item.position;

      const diameterDiff = val - originalDiameter;

      const newPos = {
        x: Tools.round(originalPos.x - diameterDiff / 2, 2),
        y: Tools.round(originalPos.y - diameterDiff / 2, 2),
      };

      return {
        ...item,
        size: {
          width: val,
          height: val,
        },
        position: newPos,
      };
    });

    dispatch(PlanEditorActions.updateMultipleItems(newPlantItems));
  };

  /**
   * Select all plants with the selected template
   */
  const selectAllPlants = () => {
    const plantItems = visibleItems.filter((item: Item) => {
      return (
        item.type === ItemType.PLANT &&
        item.templateId === selectedPlantTemplate?.id
      );
    });
    dispatch(PlanEditorActions.setSelectedItems(plantItems));
  };

  const replacePlants = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_FINDER));
  };

  const openPlantInfo = () => {
    if (selectedPlantTemplate) {
      dispatch(PlantManagementActions.setFocusTemplate(selectedPlantTemplate));
      dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_INFO));
    }
  };

  const plantHeightText = useMemo(() => {
    if (selectedPlantTemplate) {
      return Measure.getMeasureTextInterval(
        selectedPlantTemplate.heightMin || 0,
        selectedPlantTemplate.heightMax || 0,
        UserPreferences.unitScale,
        delimiter,
        true,
        true
      );
    }
  }, [selectedPlantTemplate, UserPreferences.unitScale, delimiter]);

  return (
    <>
      {selectedPlantItems.length > 0 && (
        <>
          {selectedPlantTemplate && (
            <>
              <ToolGroup>
                <ToolInfo onClick={openPlantInfo} elementId="plant-info-tool">
                  <div className="row items-stretch">
                    {selectedPlantTemplate?.image && (
                      <div>
                        <img
                          id="selected-items-image"
                          className="tool-image mr"
                          alt="Plant"
                          src={
                            Api.getHost() +
                            "/plant-images/" +
                            selectedPlantTemplate.image
                          }
                        />
                      </div>
                    )}
                    <div className="text-left column items-space-between">
                      <div className="text-bold">
                        {selectedPlantTemplate.name}
                      </div>
                      <div className="text-italic">
                        {PlantText.getLatinAndHybridName(selectedPlantTemplate)}
                      </div>
                      <div className="text-dark-muted">
                        {selectedPlantItems.length > 1 && (
                          <span>
                            {t("countPlantsSelected", {
                              count: selectedPlantItems.length,
                            })}
                          </span>
                        )}
                        &nbsp;
                      </div>
                    </div>
                  </div>
                </ToolInfo>
              </ToolGroup>
              <ToolSeparator />
              <ToolGroup>
                <ToolNumberInput
                  icon="width"
                  text={t("plants:diameter")}
                  onChange={updateDiameter}
                  value={displayedPlantDiameter}
                  unitScale={UserPreferences.unitScale}
                  delimiter={delimiter}
                  elementId="plant-diameter-input"
                  omitYards
                  omitFractions
                />
                <ToolNumberInput
                  icon="height"
                  text={t("imagine:height")}
                  readonlyValue={plantHeightText}
                  unitScale={UserPreferences.unitScale}
                  delimiter={delimiter}
                  elementId="plant-height-text"
                />
              </ToolGroup>

              <ToolSeparator />
              <ToolButton
                elementId="select-all-plants-tool"
                text={t("selectAll")}
                icon="select-plant"
                onClick={selectAllPlants}
              />

              <ToolButton
                elementId="replace-plants-tool"
                text={t("replacePlants", {
                  count: selectedPlantItems.length,
                })}
                icon="change-plant"
                onClick={replacePlants}
              />
            </>
          )}
        </>
      )}
    </>
  );
};
