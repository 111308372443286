import { Zone, ZoneOperations } from "draw";
import { Point } from "imagine-essentials";
import { Button, Device, Icon } from "imagine-ui";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DragDropContext,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import {
  ContextMenu,
  ContextMenuItem,
  StrictModeDroppable,
  ZoneItem,
} from "..";

interface Props {
  zones: Zone[];
  activeId?: number;
  onReorderZones: (zones: Zone[]) => void;
  onUpdateZone: (zone: Zone) => void;
  onAddZone: (zone: Zone) => void;
  onDeleteZone: (zoneId: number) => void;
  onSelectActiveZone?: (zoneId: number) => void;
  onSelectAllElements?: (zoneId: number) => void;
  defaultName: string;
  smallDevice?: boolean;
  minCount: number; // The minimal count of zones
  elementId?: string; // "layers"
  itemIdPrefix: string; // "layer"
  addZoneText: string;
}

export const ZoneManager = (props: Props) => {
  const { t } = useTranslation();
  const isTouchDevice = Device.isTouchDevice();

  const reverseZones = useMemo(() => {
    return Array.from(props.zones).reverse();
  }, [props.zones]);

  const [contextMenuPos, setContextMenuPos] = useState({ x: 0, y: 0 });
  const [showContextMenu, setShowContextMenu] = useState(false);

  // The layer to be renamed or deleted. Not the same as selected layer
  const [selectedZone, setSelectedZone] = useState(0);
  const [rename, setRename] = useState(false);

  // The indexes are based on the reversed list
  const reorder = (sourceIndex: number, destinationIndex: number) => {
    let newZones = Array.from(reverseZones);
    const [removed] = newZones.splice(sourceIndex, 1);
    newZones.splice(destinationIndex, 0, removed);
    // Update index values
    newZones = newZones.map((zone: Zone, index: number) => {
      return {
        ...zone,
        index: newZones.length - index - 1,
      };
    });

    props.onReorderZones(newZones.reverse());
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleDragEnd = (result: any) => {
    // Check if item was dropped over a droppable area
    if (result.destination === null) return;
    reorder(result.source.index, result.destination.index);
  };

  const updateVisibility = (id: number, visible: boolean) => {
    const originalZone = props.zones.find((zone: Zone) => zone.id === id);
    if (originalZone === undefined) return;

    const newZone = {
      ...originalZone,
      visible: visible,
    } as Zone;
    props.onUpdateZone(newZone);
  };

  const updateName = (id: number, name: string) => {
    setRename(false);
    const originalZone = props.zones.find((zone: Zone) => zone.id === id);
    if (originalZone === undefined) return;

    const newZone = {
      ...originalZone,
      name: name,
    } as Zone;
    props.onUpdateZone(newZone);
  };

  const addZone = () => {
    const newId = ZoneOperations.getNextZoneId(props.zones);
    const newZone = {
      id: newId,
      name: props.defaultName + " " + (props.zones.length + 1.0),
      visible: true,
      index: 0,
    } as Zone;
    props.onAddZone(newZone);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRightClick = (event: any, id: number) => {
    event.preventDefault();
    if (props.smallDevice) setContextMenuPos({ x: 20, y: event.clientY });
    else setContextMenuPos({ x: event.clientX, y: event.clientY });
    setSelectedZone(id);
    setShowContextMenu(true);
  };

  const handleLongPress = (position: Point, id: number) => {
    if (props.smallDevice) setContextMenuPos({ x: 10, y: position.y });
    else setContextMenuPos(position);
    setSelectedZone(id);
    setShowContextMenu(true);
  };

  const closeContextMenu = () => {
    setShowContextMenu(false);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const setZoneActive = (id: number, event: any) => {
    // Ignore is active zome cannot be set
    if (!props.onSelectActiveZone) return;

    // Ignore if it was actually the checkbox that was clicked
    if (event.target.type === "checkbox") return;

    // Force selected layer to be visible if it is not already
    const activeZone = ZoneOperations.findZone(props.zones, id);
    if (!activeZone) return;

    if (!activeZone.visible) {
      updateVisibility(id, true);
    }

    props.onSelectActiveZone(id);
  };

  const renameZone = () => {
    setRename(true);
  };

  const canDeleteZone = useMemo(() => {
    return props.zones.length > props.minCount;
  }, [props.minCount, props.zones.length]);

  const deleteZone = () => {
    if (selectedZone !== 0) {
      props.onDeleteZone(selectedZone);
    }
  };

  const canSelectAllElements = useMemo(() => {
    const zone = ZoneOperations.findZone(props.zones, selectedZone);
    if (!zone) return false;
    return zone.visible;
  }, [props.zones, selectedZone]);

  const selectAllElements = () => {
    if (props.onSelectAllElements) {
      props.onSelectAllElements(selectedZone);
    }
  };

  const getZoneClass = (id: number) => {
    if (props.activeId === id) {
      return "item-list-item selected";
    }
    return "item-list-item";
  };

  return (
    <div
      className="p zone-manager scroll-y h-full"
      id={props.elementId}
      onClick={closeContextMenu}
    >
      <Button className="mb" onClick={addZone} size="sm" outline>
        {props.addZoneText}
      </Button>
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId={props.itemIdPrefix + "-item-list"}>
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              className="item-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {reverseZones.map((zone: Zone, index: number) => (
                <Draggable
                  key={zone.id.toString()}
                  draggableId={props.itemIdPrefix + "-" + zone.id.toString()}
                  index={index}
                >
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      className={getZoneClass(zone.id)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      // {...provided.dragHandleProps}
                      onClick={(event) => setZoneActive(zone.id, event)}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      onContextMenuCapture={(event: any) =>
                        handleRightClick(event, zone.id)
                      }
                      id={props.itemIdPrefix + "-items-" + index}
                    >
                      <ZoneItem
                        text={zone.name}
                        checked={zone.visible}
                        idPrefix={props.itemIdPrefix + "-item-" + index}
                        onTextFinished={(text: string) =>
                          updateName(zone.id, text)
                        }
                        onCheckedChanged={(visible: boolean) =>
                          updateVisibility(zone.id, visible)
                        }
                        editable={rename && selectedZone === zone.id}
                        onLongTouch={(pos: Point) =>
                          handleLongPress(pos, zone.id)
                        }
                      />

                      <div
                        className="drag-handle"
                        {...provided.dragHandleProps}
                      >
                        <Icon name="grab" />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {showContextMenu && (
        <ContextMenu
          position={contextMenuPos}
          onClose={() => setShowContextMenu(false)}
        >
          <ContextMenuItem
            id={props.elementId + "-rename"}
            onClick={renameZone}
          >
            {t("imagine:rename")}
          </ContextMenuItem>
          {props.onSelectAllElements && (
            <ContextMenuItem
              id={props.elementId + "-select-elements"}
              onClick={selectAllElements}
              disabled={!canSelectAllElements}
            >
              {t("selectAllElements")}
            </ContextMenuItem>
          )}
          <ContextMenuItem
            id={props.elementId + "-delete"}
            onClick={deleteZone}
            disabled={!canDeleteZone}
          >
            {t("imagine:delete")}
          </ContextMenuItem>
        </ContextMenu>
      )}
    </div>
  );
};
