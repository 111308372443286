import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { StripeInvoice, SubscriptionInvoice } from "..";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";
import { Currency, CurrencyTools } from "imagine-i18n";
import { Button, Device } from "imagine-ui";
import { Api } from "imagine-essentials";

type Props = {
  invoices: SubscriptionInvoice[];
  download?: boolean;
};

/**
 * The page displays all payments made and lets the user download an invoice for each.
 */
export const StripeInvoices = (props: Props) => {
  const { t } = useTranslation();
  const isTouchDevice = useMemo(() => {
    return Device.isTouchDevice();
  }, []);

  return (
    <div id="invoices" className="mt">
      {props.invoices.map((invoice: SubscriptionInvoice, index: number) => (
        <div key={index.toString()} id={"payment-item-" + index}>
          <div className={"card mb"}>
            <div className="row items-space-between items-y-center">
              {/* <div className="colspan-2">
                    <span className="text-bold">
                      {getMembershipName(invoice.type)}
                     
                    </span>
                  </div> */}
              <div className="">
                {DateTimeText.getDbDateTimeText(
                  invoice.dateCreated,
                  DateTimeFormat.LONG_DATE
                )}
              </div>
              <div className="text-right">
                <span>
                  {CurrencyTools.getDisplayPrice(
                    invoice.total / 100,
                    invoice.currency as Currency,
                    true
                  )}
                </span>

                <Button
                  className="ml"
                  href={Api.getHost() + "/pdf/invoice/" + invoice.id}
                  target="_blank"
                  elementId={"invoice-button-" + index}
                  transparent
                  color="dark"
                  icon="pdf"
                ></Button>
              </div>
            </div>
          </div>
        </div>
      ))}

      {/* <Modal
        visible={invoiceMembership !== undefined}
        onClose={() => setInvoiceMembership(undefined)}
        elementId="invoice-preview"
        size="fullscreen"
      >
        <div className="pdf-viewer h-full" id="pdf-invoice-viewer">
          {invoiceMembership !== undefined && (
              <>
                {!props.download && (
                  <PDFViewer width="100%" height="100%" >
                   
                  </PDFViewer>
                )}
                
              </>
            )}
        </div>
      </Modal> */}
    </div>
  );
};
