import { useTranslation } from "react-i18next";
import { Button, Checkbox, Link, TextInput } from "imagine-ui";
import { useState } from "react";

interface Props {
  onLoginClick: (email: string, password: string, rememberMe: boolean) => void;
  onForgotPasswordClick: (email: string) => void;
  message?: string; // Optional message to display are the top
  errorMessage?: string; // Error message to display
  onCreateAccountClick?: () => void;
  loading?: boolean;
}

export const LogIn = (props: Props) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);

  const login = () => {
    props.onLoginClick(email, password, rememberMe);
  };

  return (
    <div id="login">
      {props.errorMessage && (
        <p className="text-danger mb" id="login-error">
          {props.errorMessage}
        </p>
      )}
      {props.message && (
        <p className="text-dark mb" id="login-message">
          {props.message}
        </p>
      )}
      <div className="mb">
        <span className="label">{t("users:emailAddress")}</span>
        <TextInput
          elementId="login-email"
          value={email}
          onChange={(value) => setEmail(value)}
        />
      </div>
      <div className="mb">
        <span className="label">{t("users:password")}</span>
        <TextInput
          elementId="login-password"
          password={true}
          value={password}
          onChange={(value) => setPassword(value)}
          onEnterPress={login}
        />
      </div>
      <div className="mb">
        <div className="grid columns-2 columns-1--sm gap">
          <div className="">
            <Checkbox
              id="login-remember-me"
              checked={rememberMe}
              onChange={(checked) => setRememberMe(checked)}
              label={t("users:rememberMe")}
            />
          </div>
          <div className="text-right text-left--sm">
            <Link
              elementId="login-forgot-password"
              onClick={() => props.onForgotPasswordClick(email)}
            >
              {t("users:forgotPassword")}
            </Link>
          </div>
        </div>
      </div>
      <div className="text-center">
        <Button
          elementId="login-submit"
          onClick={login}
          className="mt mt-lg--md"
          loading={props.loading}
        >
          {t("users:login")}
        </Button>
      </div>
      {props.onCreateAccountClick && (
        <>
          <hr className="my-lg" />
          <p className="text-center">
            {t("users:doNotHaveAccount", "Do you not have an account?")}{" "}
            <Link
              elementId="login-no-account"
              onClick={props.onCreateAccountClick}
            >
              {t("users:createAccount")}
            </Link>
          </p>
        </>
      )}
    </div>
  );
};
