import { useCallback, useEffect, useRef, useState } from "react";
import { Point, Rectangle, Size } from "imagine-essentials";
import { CanvasOperations, EventProcess, Mouse, MouseState } from "draw";
import { Device } from "imagine-ui";

interface Props {
  children?: React.ReactNode[] | React.ReactNode;
  backgroundChildren?: React.ReactNode[] | React.ReactNode;
  foregroundChildren?: React.ReactNode[] | React.ReactNode;
  visibleRectangle: Rectangle;
  size: Size;
  offset: Point;
  cursor?: string;
  mapVisible: boolean;
  enableDrag: boolean;
  zoom: number;
  zeroReference: Point;
  onClick: () => void;
  onZoom: (offset: number, position: Point) => void; // Offset is in zoom levels, position is on locally on canvas
  onUpdateZoom: (zoom: number, zeroReference: Point) => void;
  inactive?: boolean;
  onDragStart: () => void;
  onDrag: (distance: Point) => void;
  onDragFinish?: () => void;
  onRightClick: (position: Point, className: string) => void;
}

/**
 * Linear equation to make Safari deltaY match Chrome
 * @param scroll
 * @returns
 */
const convertScroll = (scroll: number) => {
  if (scroll > 0) return 1.7 * scroll + 93;
  else return 1.7 * scroll - 93;
};

/**
 * Contains the outer SVG element that makes op the canvas and contains all elements.
 * Also responsible for calculation zoom and dragging.
 * @param props
 * @returns
 */
export const Canvas = (props: Props) => {
  const { onDrag, onDragStart, onZoom } = props;
  const viewBox = "0 0 " + props.size.width + " " + props.size.height;
  const targetRef = useRef<HTMLDivElement>(null);
  const style = {
    width: props.size.width + "px",
    height: props.size.height + "px",
    cursor: props.cursor,
  };

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState<Point>({ x: 0, y: 0 });

  const [isPinchZooming, setIsPinchZooming] = useState(false);
  const isTouchDevice = Device.isTouchDevice();

  const [initialPinchRectangle, setInitialPinchRectangle] =
    useState<Rectangle | null>(null);

  // Safari uses different deltaY values than other browsers.
  const [slowScroll, setSlowScroll] = useState(false);

  const triggerClick = () => {
    if (isTouchDevice) return;
    props.onClick();
  };

  const triggerTouch = () => {
    props.onClick();
  };

  /**
   * Ignore the default context menu and instead fires right click event (since this is what
   * triggered the context menu).
   * @param event
   */
  const handleContextMenu = (event: any) => {
    event.preventDefault();
    if (isTouchDevice) return;
    if (props.onRightClick) {
      const pos = CanvasOperations.getPointSubtracted(
        EventProcess.getEventPosition(event),
        props.offset
      );
      props.onRightClick(pos, EventProcess.getClass(event));
    }
  };

  /**
   * Calculate zoom based on a scroll event.
   */
  const calculateZoom = useCallback(
    (event: any) => {
      if (EventProcess.isCtrlPressed(event)) {
        event.preventDefault();
      }
      // Don't allow scrolling while dragging (causes items to jump, fix might be possible but unneeded)
      if (isDragging) return;

      if (props.mapVisible) {
        const offset = event.deltaY > 0 ? -1 : 1;

        onZoom(offset, EventProcess.getEventPosition(event));
      } else {
        let scroll = event.deltaY;
        // Adjust the scroll speed (Safari slow scroll is 4 while other browsers' are 100)
        // DeltaY values:
        // - Chrome   100 - 600
        // - Edge     100 - 400
        // - Firefox  102 - 1020
        // - Safari     4 - 300
        if (slowScroll) {
          // Linear equation to make Safari deltaY match Chrome
          scroll = convertScroll(scroll);
        } else if (scroll < 50 && scroll > -50) {
          setSlowScroll(true);
          scroll = convertScroll(scroll);
        }
        const speed = Math.round(scroll / 125);
        let squaredSpeed = speed * Math.abs(speed);
        const limit = 10;
        if (squaredSpeed > limit) squaredSpeed = limit;
        else if (squaredSpeed < -limit) squaredSpeed = -limit;

        const offset = squaredSpeed * -1;

        onZoom(offset, EventProcess.getEventPosition(event));
      }
    },
    [isDragging, onZoom, slowScroll, props.mapVisible]
  );

  const startDragging = useCallback(
    (position: Point) => {
      if (props.enableDrag && !isDragging && !isPinchZooming) {
        setIsDragging(true);
        // setOriginalZeroReference({ ...zeroReference });
        setDragStart(position);
        onDragStart();
        //setCursor("grabbing");
      }
    },
    [isDragging, isPinchZooming, onDragStart, props.enableDrag]
  );

  const drag = useCallback(
    (position: Point) => {
      if (isDragging) {
        if (!props.enableDrag) {
          setIsDragging(false);
        } else {
          const offset = {
            x: position.x - dragStart.x,
            y: position.y - dragStart.y,
          };
          onDrag(offset);
          // dispatch(
          //   BoardActions.setZeroReference(
          //     BoardConversion.getPointSum(originalZeroReference, offset)
          //   )
          // );
        }
      }
    },
    [dragStart, isDragging, onDrag, props.enableDrag]
  );

  const startPinchZooming = useCallback(
    (positions: Point[]) => {
      // Pinch zoom not available when map is visible (map is not zoomable via pinch zooming)
      if (props.mapVisible) return;
      if (positions.length === 2 && !isPinchZooming) {
        setIsPinchZooming(true);
        const pos1 = CanvasOperations.getPointSubtracted(
          positions[0],
          props.offset
        );
        const pos2 = CanvasOperations.getPointSubtracted(
          positions[1],
          props.offset
        );

        const unitPos1 = CanvasOperations.pixelToUnitPosition(
          pos1,
          props.zoom,
          props.zeroReference,
          4
        );
        const unitPos2 = CanvasOperations.pixelToUnitPosition(
          pos2,
          props.zoom,
          props.zeroReference,
          4
        );

        const rect = CanvasOperations.getRectangleFromPoints(
          unitPos1,
          unitPos2
        );
        setInitialPinchRectangle(rect);
        // Pinch zooming has priority over dragging
        if (isDragging) setIsDragging(false);
      }
    },
    [
      isDragging,
      isPinchZooming,
      props.mapVisible,
      props.offset,
      props.zeroReference,
      props.zoom,
    ]
  );

  const pinchZoom = useCallback(
    (positions: Point[]) => {
      if (
        isPinchZooming &&
        positions.length === 2 &&
        initialPinchRectangle !== null
      ) {
        if (isDragging) setIsDragging(false);
        if (props.mapVisible) return;
        if (!props.enableDrag) {
          setIsPinchZooming(false);
        } else {
          const pos1 = CanvasOperations.getPointSubtracted(
            positions[0],
            props.offset
          );
          const pos2 = CanvasOperations.getPointSubtracted(
            positions[1],
            props.offset
          );
          const rectPx = CanvasOperations.getRectangleFromPoints(pos1, pos2);

          // Need to make sure that this rectangle is the same aspect ratio as the original pinch points
          const initialAspectRatio =
            initialPinchRectangle.size.width /
            initialPinchRectangle.size.height;
          const currentAspectRatio = rectPx.size.width / rectPx.size.height;
          if (initialAspectRatio > currentAspectRatio) {
            // Initial rectange is wider than current -> increase width of current. Center should stay fixed
            const newWidth = rectPx.size.height * initialAspectRatio;
            rectPx.position.x =
              rectPx.position.x - (newWidth - rectPx.size.width) / 2;
            rectPx.size.width = newWidth;
          } else if (initialAspectRatio < currentAspectRatio) {
            // Initial rectangel is taller than current -> increase height of current. Center should stay fixed
            const newHeight = rectPx.size.height * initialAspectRatio;
            rectPx.position.y =
              rectPx.position.y - (newHeight - rectPx.size.height) / 2;
            rectPx.size.height = newHeight;
          }

          // Calculate zoom in pixels per meter
          const ppm = rectPx.size.width / initialPinchRectangle.size.width;
          // dispatch(BoardActions.setZoom(ppm));

          // Now we want to calculate the zero reference that will positions the initial pinch rectangle positions at the rectPx position
          // (Isolating reference in the pixelToUnit function formula - basic math)
          const ref = {
            x: rectPx.position.x - initialPinchRectangle.position.x * ppm,
            y: rectPx.position.y - initialPinchRectangle.position.y * ppm,
          };

          props.onUpdateZoom(ppm, ref);

          // dispatch(BoardActions.setZeroReference(ref));
        }
      }
    },
    [initialPinchRectangle, isDragging, isPinchZooming, props]
  );

  const stopBoardDragOrPinchZoom = useCallback(() => {
    setIsDragging(false);
    setIsPinchZooming(false);
  }, []);

  useEffect(() => {
    if (props.inactive) return;
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        if (state.positions.length === 2) {
          pinchZoom(state.positions);
        } else if (props.enableDrag) {
          drag(state.position);
        } else {
          // drawMarkedRectangle(state.position);
        }
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      if (state.positions.length === 2) {
        startPinchZooming(state.positions);
      } else if (props.enableDrag && state.position !== undefined) {
        if (
          CanvasOperations.isPointWithinRectangle(
            state.position,
            props.visibleRectangle
          )
        ) {
          startDragging(state.position);
        }
      } else if (
        state.elementClass === "board" ||
        state.elementClass === "canvas"
      ) {
        // startMarkingRectangle(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      stopBoardDragOrPinchZoom();
      // releaseMarkedRectangle();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    drag,
    pinchZoom,
    props.enableDrag,
    props.inactive,
    props.visibleRectangle,
    startDragging,
    startPinchZooming,
    stopBoardDragOrPinchZoom,
  ]);

  useEffect(() => {
    const current = targetRef.current;
    if (current) {
      current.addEventListener("wheel", calculateZoom, { passive: false });
    }

    return () => {
      if (current) {
        current.removeEventListener("wheel", calculateZoom);
      }
    };
  }, [calculateZoom]);

  return (
    <div
      className="canvas-container"
      style={style}
      // onWheel={calculateZoom}
      onContextMenuCapture={handleContextMenu}
      ref={targetRef}
    >
      {props.backgroundChildren}

      <svg
        width={props.size.width}
        height={props.size.height}
        viewBox={viewBox}
        xmlns="http://www.w3.org/2000/svg"
        onMouseDown={triggerClick}
        onTouchStart={triggerTouch}
        className={"canvas"}
        id="board"
      >
        {props.children}
      </svg>
      {props.foregroundChildren}
    </div>
  );
};
