import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  CanvasSelector,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  LayoutActions,
  PlantManagementActions,
  PlantManagementThunks,
} from "../../store";
import { MapMode, Pages } from "../../enums";
import { CanvasOperations, Zoom } from "draw";
import { ToolButton, ToolSeparator } from "../../components";
import { NotificationType, useNotification } from "imagine-ui";
import { UserActions, UserSelector } from "imagine-users";
import { FeatureKey } from "project";
import { PlanThunks } from "../../utils";

/**
 * Displays the tool and actions in the home toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const HomeToolbarContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const hasStandardAccess = useAppSelector(UserSelector.hasStandardAccess);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const hasAdminAccess = useAppSelector(UserSelector.hasAdminAccess);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const unsavedPlanChanges = useAppSelector(
    PlanEditorSelector.hasUnsavedChanges
  );
  const boardSize = useAppSelector(CanvasSelector.getSize);
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const planSettingsCanvas = useAppSelector(CanvasSelector.getPlanSettings);
  const planSettingsPlanEditor = useAppSelector(
    PlanEditorSelector.getPlanSettings
  );
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);

  const clearPlan = () => {
    if (objectMode) {
      console.error("Cannot create new plan while in object editing mode");
      return;
    }
    dispatch(PlanEditorActions.clearPlan());
    //dispatch(PlanActions.setPlanInfo(Plans.getDefaultPlanInfo()));
    dispatch(CanvasActions.setMapMode(MapMode.NONE));
    dispatch(CanvasActions.setMapFrozen(false));
    dispatch(CanvasActions.setZoom(Zoom.getDefaultZoom()));
    dispatch(CanvasActions.setMapAddress(""));
    dispatch(CanvasActions.setMapZeroReferenceCoordinates(undefined));
    dispatch(
      CanvasActions.setZeroReference(
        CanvasOperations.getCanvasCenter(boardSize, { x: 0, y: 0 })
      )
    );
    //dispatch(PlanActions.setSelectedItems([]));

    localStorage.removeItem("planId");
    dispatch(UserActions.track(FeatureKey.NAV_NEW_PLAN));
    dispatch(PlantManagementThunks.fetchPlantCollections(0));
  };

  const newPlan = () => {
    if (unsavedPlanChanges) {
      notification.add(
        NotificationType.CONFIRM,
        t("unsavedChangesConfirmNewPlan")
      );
      notification.onConfirm(() => {
        clearPlan();
      });
    } else {
      clearPlan();
    }
  };

  const openPlanManager = () => {
    if (unsavedPlanChanges) {
      notification.add(NotificationType.CONFIRM, t("unsavedPlanWarning"));
      notification.onConfirm(() => {
        dispatch(LayoutActions.setDisplayedPage(Pages.OPEN_PLAN));
      });
    } else {
      dispatch(LayoutActions.setDisplayedPage(Pages.OPEN_PLAN));
    }
  };

  const openSavePlanModal = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.SAVE_PLAN));
  };

  const savePlan = async () => {
    dispatch(CanvasActions.setLoadingMessage(t("savingPlan")));
    const planSettings = {
      ...planSettingsCanvas,
      ...planSettingsPlanEditor,
    };

    const errorMesage = await PlanThunks.savePlan(
      dispatch,
      planInfo,
      layers,
      groups,
      items,
      area,
      planSettings,
      referenceImage
    );
    if (errorMesage !== "") {
      notification.add(NotificationType.ERROR, errorMesage);
    }

    dispatch(CanvasActions.setLoadingMessage(""));
  };

  const openPlantFinder = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_FINDER));
  };

  const openPlantCollections = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_COLLECTIONS_OVERVIEW));
  };

  const openPlantEditor = () => {
    dispatch(PlantManagementActions.setFocusTemplate(null));
    dispatch(PlantManagementActions.setCopyPlant(false));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_EDITOR));
  };

  const openUserPlants = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.USER_PLANTS));
  };

  const openPlantPurchasePage = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_PURCHASE_PAGE));
  };

  const centerPlan = () => {
    if (area) {
      dispatch(CanvasActions.centerArea(area));
      dispatch(UserActions.track(FeatureKey.NAV_CENTER_PLAN));
    }
  };

  const zoomIn = () => {
    dispatch(CanvasActions.zoomInAuto());
  };

  const canZoomIn = useAppSelector(CanvasSelector.canZoomIn);

  const zoomOut = () => {
    dispatch(CanvasActions.zoomOutAuto());
  };

  const canZoomOut = useAppSelector(CanvasSelector.canZoomOut);

  return (
    <div id="home-toolbar" className="toolbar-row">
      <ToolButton
        elementId="new-plan-nav-button"
        text={t("newPlan")}
        icon="document"
        onClick={newPlan}
        disabled={!hasStandardAccess}
      />
      <ToolButton
        elementId="open-nav-button"
        text={t("imagine:open")}
        icon="folder"
        onClick={openPlanManager}
        disabled={!hasStandardAccess}
      />
      <ToolButton
        elementId="save-nav-button"
        text={t("imagine:save")}
        icon="save"
        onClick={savePlan}
        disabled={
          !hasPremiumAccess ||
          !unsavedPlanChanges ||
          planInfo.userId !== user.id
        }
      />
      <ToolButton
        elementId="save-as-nav-button"
        text={t("imagine:saveAs")}
        icon="save-as"
        onClick={openSavePlanModal}
        disabled={!hasPremiumAccess}
      />
      <ToolSeparator />
      <ToolButton
        icon="magnifier-plant"
        elementId="find-plant-nav-button"
        text={t("plants:findPlant")}
        onClick={openPlantFinder}
        disabled={!hasStandardAccess || objectMode}
      />
      <ToolButton
        elementId="create-plant-nav-button"
        text={t("plants:createPlant")}
        onClick={openPlantEditor}
        disabled={!hasStandardAccess || objectMode}
        icon="add-plant"
      />
      <ToolButton
        elementId="user-plants-nav-button"
        text={t("plants:myPlants")}
        onClick={openUserPlants}
        disabled={!hasStandardAccess}
        icon="plant-user"
      />
      <ToolButton
        icon="plant-box"
        elementId="plant-collection-nav-button"
        text={t("plants:plantCollections")}
        onClick={openPlantCollections}
        disabled={!hasPremiumAccess || objectMode}
      />
      {/* {hasAdminAccess && (
        <ToolButton
          icon="basket"
          elementId="plant-purchase-nav-button"
          text={t("plantPurchase:purchasePlants")}
          onClick={openPlantPurchasePage}
          disabled={objectMode}
        />
      )} */}
      <ToolSeparator />

      <ToolButton
        elementId="zoom-in-nav-button"
        text={t("zoomIn")}
        onClick={zoomIn}
        icon="magnifier-plus"
        disabled={!canZoomIn}
      />

      <ToolButton
        elementId="zoom-out-nav-button"
        text={t("zoomOut")}
        onClick={zoomOut}
        icon="magnifier-minus"
        disabled={!canZoomOut}
      />

      <ToolButton
        elementId="center-plan-nav-button"
        text={t("centerPlan")}
        onClick={centerPlan}
        icon="center"
        disabled={!area}
      />
    </div>
  );
};
