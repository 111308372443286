import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  Action,
  ActionHistoryOperations,
  ActionType,
  Area,
  Cloner,
  Group,
  Item,
  ItemOperations,
  ItemType,
  Layer,
  LayerOperations,
  ObjectSchema,
  Plan,
  PlanInfo,
  PlantSchema,
  ReferenceImage,
} from "draw";
import { PlanOperations } from "../../utils";
import _ from "lodash";
import { RequestStatus } from "imagine-essentials";

interface PlanEditorState {
  items: Item[];
  selectedItems: Item[];
  layers: Layer[];
  currentLayerId: number;
  groups: Group[];
  area?: Area;
  planInfo: PlanInfo;
  referenceImage?: ReferenceImage;
  actionHistory: Action[];
  actionUndoneHistory: Action[];
  objectTemplates: ObjectSchema[];
  plantTemplates: PlantSchema[];
  unsavedChanges: boolean;
  copiedItems: Item[];
  snap: boolean;
  previewPlan: boolean;
  previewMonth: number;
  addingItem: boolean; // Item is currently being added and might not exist in list of items yet
  editingArea: boolean;
  editingReferenceImage: boolean;
  stampType: ItemType | null;
  stampTemplate: PlantSchema | ObjectSchema | null;
  objectSearchResult: ObjectSchema[];
  planRequestStatus: RequestStatus; // Plan ready to be displayed (could also be an empty plan)
}

const initialState: PlanEditorState = {
  items: [],
  selectedItems: [],
  layers: LayerOperations.getDefaultLayers(),
  currentLayerId: LayerOperations.getDefaultLayers()[0].id,
  groups: [],
  //area: Areas.getDefaultArea(),
  planInfo: PlanOperations.getDefaultPlanInfo(),
  actionHistory: [],
  actionUndoneHistory: [],
  objectTemplates: [],
  plantTemplates: [],
  unsavedChanges: false,
  copiedItems: [],
  snap: localStorage.getItem("enableSnap") === "false" ? false : true,
  previewPlan: false,
  previewMonth: 7,
  addingItem: false,
  editingArea: false,
  editingReferenceImage: false,
  stampType: null,
  stampTemplate: null,
  objectSearchResult: [],
  planRequestStatus: RequestStatus.IDLE,
};

/**
 * This store hold all the main data related to the plan editor.
 */
export const planEditorSlice = createSlice({
  name: "planEditor",
  initialState,
  reducers: {
    undo: (state) => {
      if (state.actionHistory.length === 0) return;
      state.selectedItems = [];
      const undoAction = state.actionHistory.pop();
      if (undoAction === undefined) return;
      state.unsavedChanges = true;
      switch (undoAction.type) {
        case ActionType.ITEM_UPDATE:
          state.items = ActionHistoryOperations.undoItems(
            state.items,
            undoAction
          );
          break;
        case ActionType.ITEMS_UPDATE:
          state.items = ActionHistoryOperations.undoMultipleItems(
            state.items,
            undoAction
          );
          break;
        case ActionType.LAYER_UPDATE:
          state.layers = ActionHistoryOperations.undoLayers(
            state.layers,
            undoAction
          );
          // If current layer was deleting during the undo process, select a new current layer
          // eslint-disable-next-line no-case-declarations
          const currentLayer = state.layers.find(
            (layer: Layer) => layer.id === state.currentLayerId
          );
          if (currentLayer === undefined) {
            state.currentLayerId = state.layers[0].id;
          }
          break;
        case ActionType.GROUP_UPDATE:
          state.groups = ActionHistoryOperations.undoGroups(
            state.groups,
            undoAction
          );
          break;
        case ActionType.AREA_UPDATE:
          state.area = ActionHistoryOperations.undoArea(undoAction);
          break;
        default:
          throw Error("Action type not supported: " + undoAction.type);
      }
      state.actionUndoneHistory.push(undoAction);
    },
    redo: (state) => {
      if (state.actionUndoneHistory.length === 0) return;
      state.selectedItems = [];
      const redoAction = state.actionUndoneHistory.pop();
      if (redoAction === undefined) return;
      state.unsavedChanges = true;
      switch (redoAction.type) {
        case ActionType.ITEM_UPDATE:
          state.items = ActionHistoryOperations.redoItems(
            state.items,
            redoAction
          );
          break;
        case ActionType.ITEMS_UPDATE:
          state.items = ActionHistoryOperations.redoMultipleItems(
            state.items,
            redoAction
          );
          break;
        case ActionType.LAYER_UPDATE:
          state.layers = ActionHistoryOperations.redoLayers(
            state.layers,
            redoAction
          );
          break;
        case ActionType.GROUP_UPDATE:
          state.groups = ActionHistoryOperations.redoGroups(
            state.groups,
            redoAction
          );
          break;
        case ActionType.AREA_UPDATE:
          state.area = ActionHistoryOperations.redoArea(redoAction);
          break;
        default:
          throw Error("Action type not supported: " + redoAction.type);
      }
      state.actionHistory.push(redoAction);
    },
    clearPlan: (state) => {
      state.items = [];
      state.selectedItems = [];
      state.layers = LayerOperations.getDefaultLayers();
      state.currentLayerId = LayerOperations.getDefaultLayers()[0].id;
      state.groups = [];
      state.area = undefined;
      state.copiedItems = [];
      // state.planInfo = Plans.getDefaultPlanInfo();
      state.actionHistory = [];
      state.actionUndoneHistory = [];
      state.objectTemplates = [];
      state.plantTemplates = [];
      state.unsavedChanges = false;
      state.plantTemplates = [];
      state.objectTemplates = [];
      state.planInfo = PlanOperations.getDefaultPlanInfo();
      state.referenceImage = undefined;
    },
    openPlan: (state, action: PayloadAction<Plan>) => {
      state.items = action.payload.data.items;
      state.selectedItems = [];
      state.layers = action.payload.data.layers;
      state.groups = action.payload.data.groups;
      state.area = action.payload.data.area;
      state.planInfo = action.payload.info;
      // The filename in plan info matches whether a file is actually present on the server. The reference
      // image object might not match if user did not save after uploading or deleting a reference image.
      if (state.planInfo.referenceImage && action.payload.data.referenceImage) {
        state.referenceImage = action.payload.data.referenceImage;
      } else {
        state.referenceImage = undefined;
      }
      // Plans saved before 2.0.0 does not contain the currentLayerId
      state.currentLayerId =
        action.payload.data.planSettings?.currentLayerId ||
        action.payload.data.layers[0].id;
      state.copiedItems = [];
      state.actionHistory = [];
      state.actionUndoneHistory = [];
      state.unsavedChanges = false;
    },
    setPlanInfo: (state, action: PayloadAction<PlanInfo>) => {
      state.planInfo = action.payload;
      state.unsavedChanges = false;
    },
    setReferenceImage: (
      state,
      action: PayloadAction<ReferenceImage | undefined>
    ) => {
      state.referenceImage = action.payload;
      state.unsavedChanges = true;
    },
    setSelectedItems: (state, action: PayloadAction<Item[]>) => {
      state.selectedItems = action.payload;
    },
    // Items
    setItems: (state, action: PayloadAction<Item[]>) => {
      state.items = action.payload;
    },
    addItem: (state, action: PayloadAction<Item>) => {
      const newItem: Item = {
        ...action.payload,
        layerId: state.currentLayerId,
        index: state.items.length,
        id: ItemOperations.getTimestampId(0),
      };
      state.items.push(newItem);
      state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
        null,
        newItem,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      state.selectedItems = [newItem];
    },
    addMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const newItems = action.payload.map((item: Item, index: number) => {
        return {
          ...item,
          layerId: state.currentLayerId,
          index: state.items.length + index,
          id: ItemOperations.getTimestampId(index),
        };
      });
      state.items = state.items.concat(newItems);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        null,
        newItems,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      state.selectedItems = newItems;
    },
    updateItem: (state, action: PayloadAction<Item>) => {
      const oldItem = state.items.find(
        (item: Item) => item.id === action.payload.id
      );
      const items = state.items.map((item: Item) => {
        if (item.id === action.payload.id) return action.payload;
        else return item;
      });
      state.items = ItemOperations.sortByIndex(items);
      if (oldItem !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
          oldItem,
          action.payload,
          state.actionHistory
        );
      }
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      // Also update selected items
      state.selectedItems = ItemOperations.updateItems(
        state.selectedItems,
        action.payload
      );
    },
    updateMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const updateItemIds = action.payload.map((item: Item) => item.id);
      // Old items needed for undo
      const oldItems = state.items.filter((item: Item) =>
        updateItemIds.includes(item.id)
      );

      const items = state.items.map((item: Item) => {
        // If item is in update list, then use updated item
        if (updateItemIds.includes(item.id)) {
          const updatedItem = action.payload.find(
            (i: Item) => i.id === item.id
          );
          if (updatedItem !== undefined) return updatedItem;
        }
        // Else use the original item
        return item;
      });
      state.items = ItemOperations.sortByIndex(items);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        oldItems,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      // Also update selected items
      state.selectedItems = ItemOperations.updateMultipleItems(
        state.selectedItems,
        action.payload
      );
    },
    deleteItem: (state, action: PayloadAction<Item>) => {
      const items = state.items.filter(
        (item: Item) => item.id !== action.payload.id
      );
      const selectedItems = state.selectedItems.filter(
        (item: Item) => item.id !== action.payload.id
      );
      state.items = ItemOperations.fixIndexValues(items);
      state.selectedItems = selectedItems;
      state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
        action.payload,
        null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const itemIds = action.payload.map((item: Item) => item.id);
      const items = state.items.filter(
        (item: Item) => !itemIds.includes(item.id)
      );
      const selectedItems = state.selectedItems.filter(
        (item: Item) => !itemIds.includes(item.id)
      );
      state.items = ItemOperations.fixIndexValues(items);
      state.selectedItems = selectedItems;
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        action.payload,
        null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteSelectedItems: (state) => {
      const selectedItemIds = state.selectedItems.map((item: Item) => item.id);
      const items = state.items.filter(
        (item: Item) => !selectedItemIds.includes(item.id)
      );
      const deletedItems = [...state.selectedItems];

      state.items = ItemOperations.fixIndexValues(items);
      state.selectedItems = [];
      if (selectedItemIds.length === 1) {
        state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
          deletedItems[0],
          null,
          state.actionHistory
        );
      } else {
        state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
          deletedItems,
          null,
          state.actionHistory
        );
      }
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    // Remove items without undo available
    removeMultipleItems: (state, action: PayloadAction<number[]>) => {
      const items = state.items.filter(
        (item: Item) => !action.payload.includes(item.id)
      );
      state.items = items;
      state.unsavedChanges = true;
    },
    clearItems: (state) => {
      state.items = [];
    },
    // Layers
    setLayers: (state, action: PayloadAction<Layer[]>) => {
      state.layers = action.payload;
      state.unsavedChanges = true;
    },
    setInitialLayers: (state, action: PayloadAction<Layer[]>) => {
      state.layers = action.payload;
    },
    updateLayer: (state, action: PayloadAction<Layer>) => {
      const oldLayer = state.layers.find(
        (layer: Layer) => layer.id === action.payload.id
      );
      state.layers = state.layers.map((layer: Layer) => {
        if (layer.id === action.payload.id) return action.payload;
        return layer;
      });
      if (oldLayer !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushLayerUpdateAction(
          oldLayer,
          action.payload,
          state.actionHistory
        );
        state.actionUndoneHistory = [];
      }
      state.unsavedChanges = true;
    },
    addLayer: (state, action: PayloadAction<Layer>) => {
      state.layers.push(action.payload);
      state.actionHistory = ActionHistoryOperations.pushLayerUpdateAction(
        null,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteLayer: (state, action: PayloadAction<number>) => {
      // It should not be possible to delete the last layer
      if (state.layers.length < 2) return;
      const oldLayer = state.layers.find(
        (layer: Layer) => layer.id === action.payload
      );
      state.layers = state.layers.filter((layer: Layer) => {
        return layer.id !== action.payload;
      });
      // Set new current layer if current layer was deleted
      if (action.payload === state.currentLayerId) {
        state.currentLayerId = state.layers[0].id;
      }
      if (oldLayer !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushLayerUpdateAction(
          oldLayer,
          null,
          state.actionHistory
        );
        state.actionUndoneHistory = [];
      }
      state.unsavedChanges = true;
    },
    setCurrentLayer: (state, action: PayloadAction<number>) => {
      state.currentLayerId = action.payload;
    },
    // Groups
    setGroups: (state, action: PayloadAction<Group[]>) => {
      state.groups = action.payload;
      state.unsavedChanges = true;
    },
    updateGroup: (state, action: PayloadAction<Group>) => {
      const oldGroup = state.groups.find(
        (group: Group) => group.id === action.payload.id
      );
      state.groups = state.groups.map((group: Group) => {
        if (group.id === action.payload.id) return action.payload;
        return group;
      });
      if (oldGroup !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushGroupUpdateAction(
          oldGroup,
          action.payload,
          state.actionHistory
        );
        state.actionUndoneHistory = [];
      }
      state.unsavedChanges = true;
    },
    addGroup: (state, action: PayloadAction<Group>) => {
      state.groups.push(action.payload);
      state.actionHistory = ActionHistoryOperations.pushGroupUpdateAction(
        null,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteGroup: (state, action: PayloadAction<number>) => {
      const oldGroup = state.groups.find(
        (group: Group) => group.id === action.payload
      );
      state.groups = state.groups.filter((group: Group) => {
        return group.id !== action.payload;
      });
      if (oldGroup !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushGroupUpdateAction(
          oldGroup,
          null,
          state.actionHistory
        );
        state.actionUndoneHistory = [];
      }
      state.unsavedChanges = true;
    },
    // Area
    setArea: (state, action: PayloadAction<Area | undefined>) => {
      const oldArea =
        state.area !== undefined ? ({ ...state.area } as Area) : null;
      state.area = action.payload;
      // Update action history
      state.actionHistory = ActionHistoryOperations.pushAreaUpdateAction(
        oldArea,
        action.payload || null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    /**
     * Adds or updated an object template.
     */
    addObjectTemplate: (state, action: PayloadAction<ObjectSchema>) => {
      let updated = false;
      const templates = state.objectTemplates.map((template: ObjectSchema) => {
        if (template.id === action.payload.id) {
          updated = true;
          return action.payload;
        } else return template;
      });
      if (!updated) templates.push(action.payload);
      state.objectTemplates = templates;
    },
    setObjectTemplates: (state, action: PayloadAction<ObjectSchema[]>) => {
      state.objectTemplates = action.payload;
    },
    /**
     * Adds or updated a plant template.
     */
    addPlantTemplate: (state, action: PayloadAction<PlantSchema>) => {
      let updated = false;
      const templates = state.plantTemplates.map((template: PlantSchema) => {
        if (template.id === action.payload.id) {
          updated = true;
          return action.payload;
        } else return template;
      });
      if (!updated) templates.push(action.payload);
      state.plantTemplates = templates;
    },
    setPlantTemplates: (state, action: PayloadAction<PlantSchema[]>) => {
      state.plantTemplates = action.payload;
    },
    resetUnsavedChanges: (state) => {
      state.unsavedChanges = false;
    },
    copySelectedItems: (state) => {
      if (state.selectedItems.length > 0) {
        state.copiedItems = state.selectedItems.filter(
          (item: Item) => !item.locked
        );
      }
    },
    pasteCopiedItems: (state) => {
      if (state.copiedItems.length > 0) {
        const newItems = state.copiedItems.map((item: Item, index: number) => {
          // Position new items a little shifted from the original position
          const pos = {
            x: item.position.x + item.size.width / 2,
            y: item.position.y,
          };
          const newItem = Cloner.cloneItem(item);
          newItem.id = ItemOperations.getTimestampId(index);
          newItem.position = pos;
          newItem.index = state.items.length + index;
          return newItem;
        });
        if (newItems.length === 1) {
          planEditorSlice.caseReducers.addItem(state, {
            payload: newItems[0],
            type: "planEditor/addItem",
          });
        } else {
          planEditorSlice.caseReducers.addMultipleItems(state, {
            payload: newItems,
            type: "planEditor/addItem",
          });
        }
        state.selectedItems = newItems;
        // Make new items the copied ones, in order to make multiple pasted items not lay on top of each other
        state.copiedItems = newItems;
      }
    },
    setCopiedItems: (state, action: PayloadAction<Item[]>) => {
      state.copiedItems = action.payload;
    },
    setSnap: (state, action: PayloadAction<boolean>) => {
      state.snap = action.payload;
    },
    setAddingItem: (state, action: PayloadAction<boolean>) => {
      state.addingItem = action.payload;
    },
    setEditingArea: (state, action: PayloadAction<boolean>) => {
      state.editingReferenceImage = false;
      state.editingArea = action.payload;
    },
    setEditingReferenceImage: (state, action: PayloadAction<boolean>) => {
      state.editingArea = false;
      state.editingReferenceImage = action.payload;
    },
    setStampType: (state, action: PayloadAction<ItemType>) => {
      state.stampType = action.payload;
    },
    setStampTemplate: (
      state,
      action: PayloadAction<PlantSchema | ObjectSchema>
    ) => {
      state.stampTemplate = action.payload;
    },
    clearStamp: (state) => {
      state.stampTemplate = null;
      state.stampType = null;
    },
    setPreviewPlan: (state, action: PayloadAction<boolean>) => {
      state.previewPlan = action.payload;
      if (action.payload) {
        state.selectedItems = [];
        state.editingArea = false;
        state.addingItem = false;
      }
    },
    setPreviewMonth: (state, action: PayloadAction<number>) => {
      state.previewMonth = action.payload;
    },
    setObjectSearchResult: (state, action: PayloadAction<ObjectSchema[]>) => {
      state.objectSearchResult = action.payload;
    },
    // Will update the object library when an object template is modified
    updateObjectSearchResult: (state, action: PayloadAction<ObjectSchema>) => {
      const updated = state.objectSearchResult.map((template: ObjectSchema) => {
        if (template.id === action.payload.id) return action.payload;
        return template;
      });
      state.objectSearchResult = updated;
    },
    lockSelectedItem: (state) => {
      if (state.selectedItems.length === 1) {
        const updatedItem = Cloner.cloneItem(state.selectedItems[0]);
        updatedItem.locked = true;
        planEditorSlice.caseReducers.updateItem(state, {
          payload: updatedItem,
          type: "planEditor/updateItem",
        });
      }
    },
    unlockSelectedItem: (state) => {
      if (state.selectedItems.length === 1) {
        const updatedItem = Cloner.cloneItem(state.selectedItems[0]);
        updatedItem.locked = false;
        planEditorSlice.caseReducers.updateItem(state, {
          payload: updatedItem,
          type: "planEditor/updateItem",
        });
      }
    },
    moveSelectedItemToFront: (state) => {
      if (state.selectedItems.length === 1) {
        const item = Cloner.cloneItem(state.selectedItems[0]);
        item.index = state.items.length;
        planEditorSlice.caseReducers.updateItem(state, {
          payload: item,
          type: "planEditor/updateItem",
        });
      }
    },
    moveSelectedItemToBack: (state) => {
      if (state.selectedItems.length === 1) {
        const item = Cloner.cloneItem(state.selectedItems[0]);
        item.index = -1;
        planEditorSlice.caseReducers.updateItem(state, {
          payload: item,
          type: "planEditor/updateItem",
        });
      }
    },
    setPlanRequestStatus: (state, action: PayloadAction<RequestStatus>) => {
      state.planRequestStatus = action.payload;
    },
    selectAllElementsInLayer: (state, action: PayloadAction<number>) => {
      // Verify that layer exists
      const layer = state.layers.find((l: Layer) => l.id === action.payload);

      if (layer === undefined) return;
      if (layer.visible === false) return;

      const visibleGroupIds = state.groups
        .filter((group: Group) => group.visible)
        .map((group: Group) => group.id);

      const items = state.items.filter((item: Item) => {
        if (item.layerId !== action.payload) return false;
        if (item.groupId) {
          if (!visibleGroupIds.includes(item.groupId)) return false;
        }
        return true;
      });
      state.selectedItems = items;
    },
    selectAllElementsInGroup: (state, action: PayloadAction<number>) => {
      // Verify that group exists
      const group = state.groups.find((g: Group) => g.id === action.payload);

      if (group === undefined) return;
      if (group.visible === false) return;

      const visibleLayerIds = state.layers
        .filter((layer: Layer) => layer.visible)
        .map((layer: Layer) => layer.id);

      const items = state.items.filter((item: Item) => {
        if (item.groupId !== action.payload) return false;
        if (item.layerId) {
          if (!visibleLayerIds.includes(item.layerId)) return false;
        }
        return true;
      });
      state.selectedItems = items;
    },
  },
});

export const PlanEditorActions = planEditorSlice.actions;

export default planEditorSlice.reducer;
