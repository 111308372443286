import {
  useAppDispatch,
  useAppSelector,
  PlanEditorActions,
  PlanEditorSelector,
  ObjectEditorSelector,
  ObjectEditorActions,
} from "../../store";
import {
  ToolButton,
  ToolDropdown,
  ToolGroup,
  ToolSeparator,
} from "../../components";
import { useMemo } from "react";
import {
  Group,
  GroupOperations,
  Item,
  ItemType,
  Layer,
  LayerOperations,
} from "draw";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import {
  MultipleItemsToolbarContainer,
  ObjectItemToolbarContainer,
  PlantItemToolbarContainer,
  ShapeItemToolbarContainer,
} from "..";

/**
 * Displays the tool and actions in the item toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const ItemToolbarContainer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);

  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );

  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const oneItemSelected = selectedItems.length === 1;

  const isOnlyOneShapeItemSelected = useMemo(() => {
    return (
      selectedItems.length === 1 && selectedItems[0].type === ItemType.SHAPE
    );
  }, [selectedItems]);

  const onlyPlantsSelected = useMemo(() => {
    if (selectedItems.length < 1) return false;
    const nonPlant = selectedItems.find(
      (item: Item) => item.type !== ItemType.PLANT
    );
    return nonPlant === undefined;
  }, [selectedItems]);

  const singlePlantTemplateSelected = useMemo(() => {
    const plantTemplates: number[] = [];
    selectedItems.forEach((item: Item) => {
      if (item.type === ItemType.PLANT && item.templateId !== undefined) {
        if (!plantTemplates.includes(item.templateId)) {
          plantTemplates.push(item.templateId);
        }
      }
    });
    return plantTemplates.length === 1 && onlyPlantsSelected;
  }, [onlyPlantsSelected, selectedItems]);

  const onlyOneObjectSelected = useMemo(() => {
    if (selectedItems.length !== 1) return false;
    return selectedItems[0].type === ItemType.OBJECT;
  }, [selectedItems]);

  const selectedLayer = useMemo(() => {
    if (objectMode) return undefined;
    if (selectedItems.length === 1) return selectedItems[0].layerId;
    else {
      const differentLayer = selectedItems.find(
        (item: Item) => item.layerId !== selectedItems[0].layerId
      );
      if (differentLayer) return undefined;
      return selectedItems[0].layerId;
    }
  }, [objectMode, selectedItems]);

  const layerText = useMemo(() => {
    if (selectedLayer) {
      const layer = LayerOperations.getLayer(layers, selectedLayer);
      if (layer !== undefined) return layer.name;
    }
    return t("layer");
  }, [layers, selectedLayer, t]);

  const selectedGroup = useMemo(() => {
    if (objectMode) return undefined;
    if (selectedItems.length === 1) return selectedItems[0].groupId;
    else {
      const differentLayer = selectedItems.find(
        (item: Item) => item.groupId !== selectedItems[0].groupId
      );
      if (differentLayer) return undefined;
      return selectedItems[0].groupId;
    }
  }, [objectMode, selectedItems]);

  const groupText = useMemo(() => {
    if (selectedGroup) {
      const group = GroupOperations.getGroup(groups, selectedGroup);
      if (group !== undefined) return group.name;
    }
    return t("group");
  }, [groups, selectedGroup, t]);

  const layerOptions = useMemo(() => {
    return layers
      .map((layer: Layer) => {
        return {
          value: layer.id,
          label: layer.name,
        };
      })
      .reverse(); // Layers in the left sidebar are in reverse order
  }, [layers]);

  const groupOptions = useMemo(() => {
    return groups
      .map((group: Group) => {
        return {
          value: group.id,
          label: group.name,
        };
      })
      .reverse(); // Groups in the left sidebar are in reverse order
  }, [groups]);

  const updateLayer = (val: number) => {
    if (objectMode) return;
    const updatedItems = selectedItems.map((item: Item) => {
      return {
        ...item,
        layerId: val,
      };
    });
    dispatch(PlanEditorActions.updateMultipleItems(updatedItems));
  };

  const updateGroup = (val: number) => {
    if (objectMode) return;
    const updatedItems = selectedItems.map((item: Item) => {
      return {
        ...item,
        groupId: val,
      };
    });

    dispatch(PlanEditorActions.updateMultipleItems(updatedItems));
  };

  // const updateItemLock = (locked: boolean) => {
  //   if (objectMode) return;
  //   if (!oneItemSelected) return;
  //   if()
  //   const updatedItem = _.cloneDeep(selectedItems[0]);
  //   updatedItem.locked = locked;
  //   dispatch(PlanEditorActions.updateItem(updatedItem));
  // };

  const lock = () => {
    dispatch(PlanEditorActions.lockSelectedItem());
  };

  const unlock = () => {
    dispatch(PlanEditorActions.unlockSelectedItem());
  };

  const deleteItems = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.deleteSelectedItems());
    } else {
      dispatch(PlanEditorActions.deleteSelectedItems());
    }
  };

  return (
    <>
      {selectedItems.length > 0 && (
        <div id="item-toolbar" className="toolbar-row">
          {isOnlyOneShapeItemSelected && <ShapeItemToolbarContainer />}

          {onlyPlantsSelected && <PlantItemToolbarContainer />}
          {onlyOneObjectSelected && <ObjectItemToolbarContainer />}
          {!onlyOneObjectSelected &&
            selectedItems.length > 1 &&
            !singlePlantTemplateSelected && <MultipleItemsToolbarContainer />}
          {!objectMode && (
            <>
              <ToolSeparator />
              <ToolGroup>
                <ToolDropdown
                  elementId="selected-items-layer"
                  options={layerOptions}
                  icon="layers"
                  text={layerText}
                  onSelect={updateLayer}
                  selected={selectedLayer}
                  layout="text-list"
                  half
                />
                <ToolDropdown
                  elementId="selected-items-group"
                  options={groupOptions}
                  icon="group"
                  text={groupText}
                  onSelect={updateGroup}
                  selected={selectedGroup}
                  layout="text-list"
                  disabled={groups.length === 0}
                  half
                />
              </ToolGroup>
            </>
          )}
          {oneItemSelected && !objectMode && (
            <>
              <ToolSeparator />
              {selectedItems[0].locked && (
                <ToolButton
                  elementId="selected-items-unlock"
                  text={t("unlock")}
                  icon="lock-open"
                  onClick={unlock}
                />
              )}
              {!selectedItems[0].locked && (
                <ToolButton
                  elementId="selected-items-lock"
                  text={t("lock")}
                  icon="lock"
                  onClick={lock}
                />
              )}
            </>
          )}
          <ToolSeparator />
          <ToolButton
            elementId="selected-items-delete"
            text={t("imagine:delete")}
            icon="trash"
            onClick={deleteItems}
          />
        </div>
      )}
    </>
  );
};
