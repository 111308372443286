import { PlanSettings, Zoom } from "draw";
import { MapMode } from "../../enums";
import { RootState } from "..";
import { MapConstant } from "..";
import { Rectangle } from "imagine-essentials";
import { createSelector } from "@reduxjs/toolkit";

export const CanvasSelector = {
  getOffset: (state: RootState) => {
    return state.canvas.offset;
  },
  getSize: (state: RootState) => {
    return state.canvas.size;
  },
  getVisibleRectangle: createSelector(
    (state: RootState) => state.canvas.blockedLeft,
    (state: RootState) => state.canvas.blockedRight,
    (state: RootState) => state.canvas.blockedBottom,
    (state: RootState) => state.canvas.size,
    (state: RootState) => state.canvas.offset,
    (blockedLeft, blockedRight, blockedBottom, size, offset) => {
      return {
        size: {
          width: size.width - (blockedLeft + blockedRight),
          height: size.height - blockedBottom,
        },
        position: {
          x: offset.x + blockedLeft,
          y: offset.y,
        },
      } as Rectangle;
    }
  ),
  getZoom: (state: RootState) => {
    return state.canvas.zoom;
  },
  getZeroReference: (state: RootState) => {
    return state.canvas.zeroReference;
  },
  getMapMode: (state: RootState) => {
    return state.canvas.map.mode;
  },
  getMapAddress: (state: RootState) => {
    return state.canvas.map.address;
  },
  getMapCenter: (state: RootState) => {
    return state.canvas.map.center;
  },
  getMapZoomIndex: (state: RootState) => {
    return state.canvas.map.zoomIndex;
  },
  getMapZeroReference: (state: RootState) => {
    return state.canvas.map.zeroReference;
  },
  getMapZeroReferenceCoordinates: (state: RootState) => {
    return state.canvas.map.zeroReferenceCoordinates;
  },
  isMapFrozen: (state: RootState) => {
    return state.canvas.map.frozen;
  },
  isDragging: (state: RootState) => {
    return state.canvas.isDragging;
  },
  canZoomIn: (state: RootState) => {
    if (state.canvas.map.mode === MapMode.VISIBLE) {
      return state.canvas.map.zoomIndex + 1 <= MapConstant.mapMaxZoomLevelTemp;
    } else {
      return Zoom.zoomInAllowed(state.canvas.zoom);
    }
  },
  canZoomOut: (state: RootState) => {
    if (state.canvas.map.mode === MapMode.VISIBLE) {
      return state.canvas.map.zoomIndex - 1 >= MapConstant.mapMinZoomLevel;
    } else {
      return Zoom.zoomOutAllowed(state.canvas.zoom);
    }
  },
  /**
   * Get the settings that should be stored in the user plan
   * @param state
   */
  getMapSettings: (state: RootState) => {
    return {
      mapAddress: state.canvas.map.address,
      mapCenter: state.canvas.map.center,
      mapZoom: state.canvas.map.zoomIndex,
      mapZeroReference: state.canvas.map.zeroReference,
      mapZeroReferencePosition: state.canvas.map.zeroReferenceCoordinates,
    };
  },
  isReady: (state: RootState) => {
    return state.canvas.ready;
  },
  getMessage: (state: RootState) => {
    return state.canvas.message;
  },
  getLoadingMessage: (state: RootState) => {
    return state.canvas.loadingMessage;
  },
  isEnteringText: (state: RootState) => {
    return state.canvas.enteringText;
  },
  isUpdatingDimension: (state: RootState) => {
    return state.canvas.updatingDimension;
  },
  getMarkedRectangle: (state: RootState) => {
    return state.canvas.markedRectangle;
  },
  getCursorOverride: (state: RootState) => {
    return state.canvas.cursorOverride;
  },
  getSelectedShapeTool: (state: RootState) => {
    return state.canvas.selectedShapeTool;
  },
  getCursorMode: (state: RootState) => {
    return state.canvas.cursorMode;
  },
  getContextMenuPosition: (state: RootState) => {
    return state.canvas.contextMenuPosition;
  },
  isContextMenuOpen: (state: RootState) => {
    return state.canvas.contextMenuPosition !== null;
  },
  getPlanSettings: createSelector(
    (state: RootState) => state.canvas.zoom,
    (state: RootState) => state.canvas.zeroReference,
    (state: RootState) => state.canvas.map.address,
    (state: RootState) => state.canvas.map.center,
    (state: RootState) => state.canvas.map.zoomIndex,
    (state: RootState) => state.canvas.map.zeroReference,
    (state: RootState) => state.canvas.map.zoomPPM,
    (state: RootState) => state.canvas.map.zeroReferenceCoordinates,
    (state: RootState) => state.canvas.showReferenceImage,
    (
      zoom,
      zeroReference,
      mapAddress,
      mapCenter,
      mapZoom,
      mapZeroReference,
      mapPPM,
      mapZeroReferencePosition,
      showReferenceImage
    ) => {
      return {
        zoom,
        zeroReference,
        mapAddress,
        mapCenter,
        mapZoom,
        mapZeroReference,
        mapPPM,
        mapZeroReferencePosition,
        showReferenceImage,
      } as PlanSettings;
    }
  ),
  getMarkerMode: (state: RootState) => {
    return state.canvas.markerMode;
  },
  getBlockedBottom: (state: RootState) => {
    return state.canvas.blockedBottom;
  },
  getSceneView: (state: RootState) => {
    return state.canvas.sceneView;
  },
  getShowReferenceImage: (state: RootState) => {
    return state.canvas.showReferenceImage;
  },
};
