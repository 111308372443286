import React, { useMemo } from "react";
import {
  CanvasOperations,
  LinePathProperties,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
} from "..";
import { Point } from "imagine-essentials";

const getPath = (lines: LinePathProperties) => {
  let newPath = "";
  lines.points.forEach((point: Point) => {
    if (newPath === "") {
      newPath = "M";
    } else {
      newPath += " L";
    }
    newPath += " " + point.x + " " + point.y;
  });
  if (lines.closePath === true) {
    const first = lines.points[0];
    newPath += " L " + first.x + " " + first.y;
  }
  return newPath;
};

const getCenter = (line: LinePathProperties) => {
  let finalMin = { x: 99999, y: 99999 };
  let finalMax = { x: -99999, y: -99999 };
  line.points.forEach((point: Point) => {
    finalMin = ShapeOperations.getMinPoint(finalMin, point);
    finalMax = ShapeOperations.getMaxPoint(finalMax, point);
  });
  const size = CanvasOperations.getPointSubtracted(finalMax, finalMin);
  if (!(size.x >= 0)) console.error("Negative width error for line", line);
  if (!(size.y >= 0)) console.error("Negative height error for line", line);
  return {
    x: finalMin.x + size.x / 2,
    y: finalMin.y + size.y / 2,
  } as Point;
};

const defaultStyle: ShapeStyle = {
  borderColor: "#000000",
  fillColor: "#CCCCCC",
  opacity: 1,
};

const lineWidthPx = 1;
// Hidden border with this width makes it possibel to still grab the border
const lineWidthPxGrab = 5;

interface Props {
  linePath: LinePathProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  cursor?: string;
  rotation?: number;
  className?: string;
  uniqueId?: string; // Used to link the patterns to the shapes
}

/**
 * Draws a path from a line path property. The width is always 1 px. It could also be set to a
 * given width in m or cm, but that requires more work to adjust the viewbox to include a wide
 * line width.
 * @param props
 */
export const LinePathSvg = (props: Props) => {
  const style = props.style !== undefined ? props.style : defaultStyle;

  const path = useMemo(() => {
    return getPath(props.linePath);
  }, [props.linePath]);

  const center = useMemo(() => {
    return getCenter(props.linePath);
  }, [props.linePath]);

  const fillColor = props.linePath.closePath
    ? ShapeOperations.getFill(style, props.uniqueId)
    : "none";

  return (
    <g
      transform={
        "rotate(" +
        (props.rotation || 0) +
        ", " +
        center.x +
        ", " +
        center.y +
        ")"
      }
    >
      <defs>
        <ShapePattern
          color={style.fillColor}
          startPosition={center}
          id={props.uniqueId}
          pattern={style.fillPattern}
        />
      </defs>
      <path
        d={path}
        stroke={ShapeOperations.getStroke(style)}
        strokeOpacity={ShapeOperations.getStrokeOpacity()}
        strokeDasharray={ShapeOperations.getStrokeDashArray(style, lineWidthPx)}
        fill={fillColor}
        fillOpacity={ShapeOperations.getOpacity(style)}
        style={{ cursor: props.cursor || "move" }}
        className={props.className}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
      />
      <path
        d={path}
        stroke={"red"}
        strokeOpacity={0}
        fill={"none"}
        style={{ cursor: props.cursor || "move" }}
        pointerEvents="stroke"
        className={props.className}
        strokeWidth={lineWidthPxGrab}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};
