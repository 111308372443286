import { UserActions } from "imagine-users";
import { ZoneManager } from "../../components";
import {
  LayoutActions,
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  PlantManagementSelector,
  WebsiteSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { ItemOperations, Layer } from "draw";
import { FeatureKey } from "project";
import { useTranslation } from "react-i18next";
import {
  Button,
  Icon,
  Link,
  NotificationType,
  useNotification,
} from "imagine-ui";
import { Pages, Tour } from "../../enums";
import { AlertContainer } from "../support-containers/AlertContainer";
import { useMemo } from "react";
import { KeyPageUtils } from "imagine-pages";
import { Language } from "imagine-i18n";
import { Api } from "imagine-essentials";
import { TourContainer } from "../support-containers/TourContainer";

interface Props {
  visible: boolean;
}

export const ChecklistContainer = (props: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const planId = useAppSelector(PlanEditorSelector.getPlanId);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const plantCollections = useAppSelector(
    PlantManagementSelector.getPlantCollections
  );
  const items = useAppSelector(PlanEditorSelector.getAllItems);

  const notification = useNotification();
  const keyPages = useAppSelector(WebsiteSelector.getKeyPages);
  const currentTour = useAppSelector(LayoutSelector.getCurrentTour);

  const openFeedbackPage = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.FEEDBACK));
  };

  const gettingStartedUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        3,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  const getPageUrl = (key: number) => {
    return KeyPageUtils.getAbsolutePageUrl(
      key,
      keyPages,
      i18n.language as Language
    );
  };

  return (
    <>
      {props.visible && (
        <div className="grid gap columns-1 p w-full text-sm">
          <table className="table table-transparent">
            <tbody>
              <tr>
                <td width="10%">{planId ? <Icon name="check" /> : ""}</td>
                <td>{t("saveThePlan")}</td>
              </tr>
              <tr>
                <td width="10%">{area ? <Icon name="check" /> : ""}</td>
                <td>
                  <Link href={getPageUrl(38)} target="_blank">
                    {t("defineArea")}
                  </Link>
                </td>
              </tr>
              <tr>
                <td width="10%">
                  {layers.length > 1 ? <Icon name="check" /> : ""}
                </td>
                <td>
                  <Link href={getPageUrl(32)} target="_blank">
                    {t("createLayer")}
                  </Link>
                </td>
              </tr>
              {items.length > 100 && (
                <tr>
                  <td width="10%">
                    <Icon name="check" />
                  </td>
                  <td>
                    <Link href={getPageUrl(32)} target="_blank">
                      {t("createGroup")}
                    </Link>
                  </td>
                </tr>
              )}

              <tr>
                <td width="10%">
                  {plantCollections.length > 0 ? <Icon name="check" /> : ""}
                </td>
                <td>
                  <Link href={getPageUrl(43)} target="_blank">
                    {t("createPlantCollection")}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
