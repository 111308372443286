import { useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { Point, Size } from "imagine-essentials";
import { CanvasOperations } from "draw";

interface Props {
  children: React.ReactNode[] | React.ReactNode;
  onResize: (size: Size, offset: Point) => void;
  touchDevice: boolean;
}

export const Board = (props: Props) => {
  const boardRef = useRef<HTMLDivElement>(null);

  const { onResize } = props;

  const updateLayout = useCallback(() => {
    if (boardRef.current !== undefined) {
      const width =
        boardRef.current !== null ? boardRef.current.offsetWidth : 0;
      const height =
        boardRef.current !== null ? boardRef.current.offsetHeight : 0;
      const rect = boardRef.current?.getBoundingClientRect();

      if (rect !== undefined) {
        const size = { width: width, height: height };
        const offset = { x: rect.x, y: rect.y };
        onResize(size, offset);
      }
    }
  }, [onResize]);

  /**
   * This is to get the dimensions of the wrapper div and use those dimensions as the
   * viewbox for the SVG. It will also set the board size so it's readable by other
   * components.
   */
  useLayoutEffect(() => {
    updateLayout();
  }, [updateLayout]);

  const preventPullDownToRefresh = useCallback(
    (event: any) => {
      if (!props.touchDevice) return;

      if (CanvasOperations.hasParentClass(event.target, "canvas", 5)) {
        event.preventDefault();
      }
    },
    [props.touchDevice]
  );

  useEffect(() => {
    window.addEventListener("resize", updateLayout);
    document.addEventListener("touchmove", preventPullDownToRefresh, {
      passive: false,
    });

    return () => {
      window.removeEventListener("resize", updateLayout);
      document.removeEventListener("touchmove", preventPullDownToRefresh);
    };
  }, [preventPullDownToRefresh, updateLayout]);

  return (
    <div className="board" ref={boardRef}>
      {props.children}
    </div>
  );
};
