import { Size } from "..";

const getImageDimensions = (url: string) => {
  return new Promise<Size>((resolve, reject) => {
    const img = new Image();
    img.onload = () => {
      resolve({ width: img.width, height: img.height } as Size);
    };
    img.onerror = (error) => reject(error);

    console.log("Image url", url);
    img.src = url;
  });
};

export const ImageUtils = {
  getImageDimensions,
};
