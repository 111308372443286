import { useContext } from "react";
import { NotificationContext } from "..";

/**
 * Hook for components that need to show a notification.
 */
export const useNotification = () => {
  const context = useContext(NotificationContext);
  return {
    add: context.add,
    clear: context.clear,
    onConfirm: context.onConfirm,
  };
};
