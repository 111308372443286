import { Checkbox } from "imagine-ui";
import { DateTimeText } from "imagine-datetime";

type Props = {
  selected: number[];
  onChange: (months: number[]) => void;
  direction?: "vertical" | "horizontal";
  idPrefix?: string;
  className?: string;
};

/**
 * Displays 2 rows or 2 columns of months with checkboxes
 * @param props selected, onChange, direction (optional), idPrefix (optional)
 */
export const MonthCheckboxes = (props: Props) => {
  const updateMonth = (month: number, checked: boolean) => {
    let newMonths: number[] = [];
    if (checked) {
      if (props.selected === undefined) newMonths = [month];
      else {
        // Need to make a copy for trigger value change in parent
        newMonths = [...props.selected];
        newMonths.push(month);
      }
    } else {
      if (props.selected !== undefined) {
        newMonths = props.selected.filter((m: number) => {
          return m !== month;
        });
      }
    }
    props.onChange(newMonths);
  };

  const columnClass =
    props.direction === "horizontal" ? "columns-1" : "columns-2";

  return (
    <>
      <div
        className={
          "grid " + columnClass + (props.className ? " " + props.className : "")
        }
        id={props.idPrefix}
      >
        <div
          className={
            "grid " + (props.direction === "horizontal" ? "columns-6" : "")
          }
        >
          <Checkbox
            label={DateTimeText.getMonthTextShort(1)}
            checked={props.selected.includes(1)}
            onChange={(checked: boolean) => updateMonth(1, checked)}
            id={props.idPrefix + "-1"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(2)}
            checked={props.selected.includes(2)}
            onChange={(checked: boolean) => updateMonth(2, checked)}
            id={props.idPrefix + "-2"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(3)}
            checked={props.selected.includes(3)}
            onChange={(checked: boolean) => updateMonth(3, checked)}
            id={props.idPrefix + "-3"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(4)}
            checked={props.selected.includes(4)}
            onChange={(checked: boolean) => updateMonth(4, checked)}
            id={props.idPrefix + "-4"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(5)}
            checked={props.selected.includes(5)}
            onChange={(checked: boolean) => updateMonth(5, checked)}
            id={props.idPrefix + "-5"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(6)}
            checked={props.selected.includes(6)}
            onChange={(checked: boolean) => updateMonth(6, checked)}
            id={props.idPrefix + "-6"}
          />
        </div>

        <div
          className={
            "grid " + (props.direction === "horizontal" ? "columns-6" : "")
          }
        >
          <Checkbox
            label={DateTimeText.getMonthTextShort(7)}
            checked={props.selected.includes(7)}
            onChange={(checked: boolean) => updateMonth(7, checked)}
            id={props.idPrefix + "-7"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(8)}
            checked={props.selected.includes(8)}
            onChange={(checked: boolean) => updateMonth(8, checked)}
            id={props.idPrefix + "-8"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(9)}
            checked={props.selected.includes(9)}
            onChange={(checked: boolean) => updateMonth(9, checked)}
            id={props.idPrefix + "-9"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(10)}
            checked={props.selected.includes(10)}
            onChange={(checked: boolean) => updateMonth(10, checked)}
            id={props.idPrefix + "-10"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(11)}
            checked={props.selected.includes(11)}
            onChange={(checked: boolean) => updateMonth(11, checked)}
            id={props.idPrefix + "-11"}
          />

          <Checkbox
            label={DateTimeText.getMonthTextShort(12)}
            checked={props.selected.includes(12)}
            onChange={(checked: boolean) => updateMonth(12, checked)}
            id={props.idPrefix + "-12"}
          />
        </div>
      </div>
    </>
  );
};
