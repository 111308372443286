import { ColorUtils } from "imagine-essentials";
import { useMemo, useState } from "react";
import { ColorPicker } from "..";

type Props = {
  color?: string;
  onChange?: (hex: string) => void;
  className?: string;
  elementId?: string;
  colors: string[];
  text?: string; // Text to display in the input field
};

const defaultColor = "#ffffff";

/**
 * Field that looks similar to the text inputs, but displays a color. A
 * color picker is displayed in a modal when it is clicked.
 * @param props
 */
export const ColorInput = (props: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const openColorPicker = () => {
    setDisplayColorPicker(true);
  };

  const closeColorPicker = () => {
    setDisplayColorPicker(false);
  };

  const style = useMemo(() => {
    return {
      backgroundColor: props.color || defaultColor,
      color: ColorUtils.getTextColor(props.color || defaultColor),
    };
  }, [props.color]);

  const handleColorChange = (newColor: string) => {
    setDisplayColorPicker(false);
    if (props.onChange) {
      props.onChange(newColor);
    }
  };

  return (
    <div
      className={
        "input-group clickable" + (props.className ? " " + props.className : "")
      }
    >
      <div
        id={props.elementId}
        className="input"
        onClick={openColorPicker}
        style={style}
      >
        {props.text}
      </div>
      <ColorPicker
        show={displayColorPicker}
        color={props.color}
        onChange={handleColorChange}
        onClose={closeColorPicker}
        colors={props.colors}
      />
    </div>
  );
};
