import { useTranslation } from "react-i18next";

export const SignUpConfirmation = () => {
  const { t } = useTranslation();
  return (
    <p id="signup-confirmation">
      {t("users:thansForSigningUp")}
      <br />
      <br />
      {t(
        "users:activationMailSend",
        "You will receive an activation email within a few minutes. Please check for spam box if it does not show up."
      )}
    </p>
  );
};
