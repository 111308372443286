import { Button, Device, Link, ProgressBar } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { CursorMode, MapMode, Pages, Tour } from "../../enums";
import {
  CanvasSelector,
  LayoutActions,
  LayoutSelector,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemType } from "draw";
import { UserActions, UserThunks } from "imagine-users";
import { FeatureKey } from "project";

enum Steps {
  OPEN_AREA_TAB = 1,
  OPEN_LOCATION_MODAL,
  SELECT_ADDRESS,
  ZOOM_MAP,
  ACTIVATE_DRAG, // Only for non-touch devices
  DRAG_MAP,
  DEAKTIVATE_DRAG, // Only for non-touch devices
  OPEN_DRAW_TAB,
  DRAW_SHAPE,
  OPEN_AREA_TAB_TO_HIDE_MAP,
  HIDE_MAP,
  DEFINE_AREA,
  DONE,
}

interface Props {
  onFinish: () => void;
}

export const SatelliteMapTourContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [zoomActions, setZoomActions] = useState(0);
  const [dragCompleted, setDragCompleted] = useState(false);

  const touchDevice = Device.isTouchDevice();
  const activeTab = useAppSelector(LayoutSelector.getActiveTab);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const mapAddress = useAppSelector(CanvasSelector.getMapAddress);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const mapVisible =
    useAppSelector(CanvasSelector.getMapMode) === MapMode.VISIBLE;
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);

  const shapeItems = useMemo(() => {
    return items.filter((item) => item.type === ItemType.SHAPE);
  }, [items]);

  const tourStep = useMemo(() => {
    if (!mapAddress) {
      // No address is set
      if (displayedPage !== Pages.MAP_LOCATION) {
        if (activeTab !== "area-tab") {
          return Steps.OPEN_AREA_TAB;
        } else {
          return Steps.OPEN_LOCATION_MODAL;
        }
      }
      return Steps.SELECT_ADDRESS;
    } else {
      // Map address is set
      if (zoomActions < 3) {
        return Steps.ZOOM_MAP;
      } else if (!dragCompleted) {
        if (!touchDevice && cursorMode !== CursorMode.DRAG) {
          return Steps.ACTIVATE_DRAG;
        }
        return Steps.DRAG_MAP;
      }
      if (!touchDevice && cursorMode === CursorMode.DRAG) {
        return Steps.DEAKTIVATE_DRAG;
      }
      if (shapeItems.length === 0 && !area) {
        if (activeTab !== "draw-tab") {
          return Steps.OPEN_DRAW_TAB;
        } else {
          return Steps.DRAW_SHAPE;
        }
      } else {
        // Shape is drawn
        if (mapVisible) {
          if (activeTab !== "area-tab") {
            return Steps.OPEN_AREA_TAB_TO_HIDE_MAP;
          } else {
            return Steps.HIDE_MAP;
          }
        } else if (!area) {
          return Steps.DEFINE_AREA;
        } else {
          return Steps.DONE;
        }
      }
    }
  }, [
    mapAddress,
    displayedPage,
    activeTab,
    mapVisible,
    shapeItems,
    area,
    cursorMode,
    touchDevice,
    zoomActions,
    dragCompleted,
  ]);

  useEffect(() => {
    if (tourStep === Steps.ZOOM_MAP && mapVisible) {
      setZoomActions(zoomActions + 1);
    }
  }, [zoom]);

  useEffect(() => {
    if (tourStep === Steps.DRAG_MAP) {
      setDragCompleted(true);
    }
  }, [zeroReference]);

  const completeTour = useCallback(async () => {
    dispatch(UserActions.track(FeatureKey.FINISH_TOUR_SATELLITE_MAP));
    await dispatch(UserThunks.postUsedFeatures());
    props.onFinish();
  }, []);

  useEffect(() => {
    if (tourStep === Steps.DONE) {
      completeTour();
    }
  }, [tourStep]);

  const finishTour = () => {
    dispatch(LayoutActions.setCurrentTour(Tour.NONE));
  };

  return (
    <div className="">
      <p className="text-bold">{t("drawPropertyWithSatelliteMap")}</p>
      <ProgressBar value={tourStep} max={Steps.DONE} className="my-sm" />
      {tourStep === Steps.OPEN_AREA_TAB && (
        <p>{t("satelliteTourOpenAreaTab")}</p>
      )}
      {tourStep === Steps.OPEN_LOCATION_MODAL && (
        <p>{t("satelliteTourClickSetLocation")}</p>
      )}
      {tourStep === Steps.SELECT_ADDRESS && (
        <p>{t("satelliteTourEnterAddress")}</p>
      )}
      {tourStep === Steps.ZOOM_MAP && (
        <p>
          {t(
            touchDevice ? "satelliteTourZoomMapTouch" : "satelliteTourZoomMap"
          )}
        </p>
      )}
      {tourStep === Steps.ACTIVATE_DRAG && (
        <p>{t("satelliteTourActivateDrag")}</p>
      )}
      {tourStep === Steps.DRAG_MAP && (
        <p>
          {t(
            touchDevice ? "satelliteTourDragMapTouch" : "satelliteTourDragMap"
          )}
        </p>
      )}
      {tourStep === Steps.DEAKTIVATE_DRAG && (
        <p>{t("satelliteTourDeactivateDrag")}</p>
      )}
      {tourStep === Steps.OPEN_DRAW_TAB && (
        <p>{t("satelliteTourOpenDrawTab")}</p>
      )}
      {tourStep === Steps.DRAW_SHAPE && <p>{t("satelliteTourDrawShape")}</p>}
      {tourStep === Steps.OPEN_AREA_TAB_TO_HIDE_MAP && (
        <>
          <p className="mb">{t("satelliteTourOpenAreaTabToHideMap")}</p>
          <p className="mb text-italic">
            {t("satelliteTourOpenAreaTabToHideMapTip")}
          </p>
          <p>{t("satelliteTourOpenAreaTabToHideMap2")}</p>
        </>
      )}
      {tourStep === Steps.HIDE_MAP && <p>{t("satelliteTourHideMap")}</p>}
      {tourStep === Steps.DEFINE_AREA && (
        <p>
          {t(
            touchDevice
              ? "satelliteTourDefineAreaTouch"
              : "satelliteTourDefineArea"
          )}
        </p>
      )}
      {tourStep === Steps.DONE && (
        <>
          <p className="mb">{t("satelliteTourDone")}</p>
          <p>{t("satelliteTourEdit")}</p>
        </>
      )}
      <Button
        color="danger"
        outline
        size="sm"
        onClick={finishTour}
        className="mt"
      >
        {t("finishTour")}
      </Button>
    </div>
  );
};
