import { useEffect, useMemo, useState } from "react";
import { Icon } from "imagine-ui";
import { SidebarTab } from "./Sidebar";
import { EventProcess, Mouse, MouseState } from "draw";
import { Point, Trig } from "imagine-essentials";

interface Props {
  children?: React.ReactNode[] | React.ReactNode;
  open: boolean;
  activeTab: string;
  tabs: SidebarTab[];
  height: number;
  onClose: () => void;
}

export const Footer = (props: Props) => {
  const [swipeStart, setSwipeStart] = useState<Point | null>(null);
  const style = useMemo(() => {
    return {
      bottom: props.open ? "48px" : -props.height + "px",
      height: props.height + "px",
    };
  }, [props.height, props.open]);

  const activeTitle = useMemo(() => {
    const activeTab = props.tabs.find(
      (tab: SidebarTab) => tab.id === props.activeTab
    );
    if (activeTab !== undefined) return activeTab.title;
    return "";
  }, [props.activeTab, props.tabs]);

  const startSwipe = (event) => {
    const position = EventProcess.getEventPosition(event);
    setSwipeStart(position);
  };

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (swipeStart) {
        const diff = Trig.getPointSubtracted(swipeStart, state.position);
        if (diff.y < -100 && Math.abs(diff.x) < 50) {
          setSwipeStart(null);
          props.onClose();
        }
      }
    });
    const releaseObserver = Mouse.release.subscribe(() => {
      setSwipeStart(null);
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [props, swipeStart]);

  return (
    <div className="footer">
      <div className="footer-content" style={style}>
        <div className="footer-title" onTouchStart={startSwipe}>
          {" "}
          {activeTitle}
        </div>
        <div className="footer-body" onTouchStart={startSwipe}>
          {props.children}
        </div>
      </div>
      <div className="footer-tabs">
        {props.tabs.map((tab: SidebarTab) => (
          <button
            className={
              "btn " +
              (props.activeTab === tab.id ? "btn-accent" : "btn-outline-accent")
            }
            key={tab.id}
            onClick={tab.onClick}
          >
            <Icon name={tab.icon} />
          </button>
        ))}
      </div>
    </div>
  );
};
