import { useTranslation } from "react-i18next";

import { useAppSelector, PlanEditorSelector } from "../../store";
import { Item, ItemType } from "draw";
import { ToolGroup, ToolSeparator } from "../../components";
import { useMemo } from "react";
import _ from "lodash";

/**
 * Displays the tool and actions in the plant item toolbar.
 * @param props
 * @returns
 */
export const MultipleItemsToolbarContainer = () => {
  const { t } = useTranslation();
  const selectedItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedPlantItems = useAppSelector(
    PlanEditorSelector.getSelectedPlants
  );
  const selectedObjectItems = useAppSelector(
    PlanEditorSelector.getSelectedObjects
  );

  const selectedShapeItems = useMemo(() => {
    return selectedItems.filter((item: Item) => item.type === ItemType.SHAPE);
  }, [selectedItems]);

  return (
    <>
      <ToolGroup>
        <div className="tool-info-text text-left pl-xs pr-sm">
          {selectedPlantItems.length > 0 && (
            <div id="selected-plants-count" className="mb-xs">
              <span>
                {t("countPlantsSelected", {
                  count: selectedPlantItems.length,
                })}
              </span>
            </div>
          )}
          {selectedObjectItems.length > 0 && (
            <div id="selected-objects-count" className="mb-xs">
              <span>
                {t("countObjectsSelected", {
                  count: selectedObjectItems.length,
                })}
              </span>
            </div>
          )}
          {selectedShapeItems.length > 0 && (
            <div id="selected-shapes-count" className="">
              <span>
                {t("countShapesSelected", {
                  count: selectedShapeItems.length,
                })}
              </span>
            </div>
          )}
        </div>
      </ToolGroup>
    </>
  );
};
