import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const getImages = (language: string) => {
  switch (language) {
    case "da":
      return [
        "https://gardensketcher.com/upload/img/plantesamlinger/da-plantekort.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/da-plantenavne.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/da-stemningsbillede.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/da-plantemodeller.png",
      ];
    default:
      return [
        "https://gardensketcher.com/upload/img/plantesamlinger/en-plant-cards.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/en-plant-names.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/en-moodboard.png",
        "https://gardensketcher.com/upload/img/plantesamlinger/en-plant-models.png",
      ];
  }
};

export const Version2Dot3 = () => {
  const { t, i18n } = useTranslation();

  const images = useMemo(() => getImages(i18n.language), [i18n.language]);

  return (
    <>
      <h5 className="mt">{t("2_3_addedGuides")}</h5>
      <p className="mt-sm">{t("2_3_addedGuidesDescription")}</p>
      <p className="mt-sm">{t("2_3_addedGuidesDescription2")}</p>
      <h5 className="mt">{t("2_3_updatedPlantCollections")}</h5>
      <p className="mt-sm">{t("2_3_updatedPlantCollectionsDescription")}</p>
      <div className="grid columns-2 columns-1--md gap-xl mt-xl">
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt="" className="fluid" />
          </div>
        ))}
      </div>
    </>
  );
};
