import { useEffect, useMemo, useState } from "react";
import { Delimiter, Tools } from "imagine-essentials";
import { TextInput } from "..";

interface Props {
  value?: number;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  onChange?: (value: number) => void;
  onFinish?: (value: number) => void; // Enter, tab or lost focus
  onEnterPress?: (value: number) => void;
  elementId?: string;
  className?: string;
  invalid?: boolean;
  delimiter?: Delimiter;
  decimals?: number;
  unit?: string;
}

export const NumberInput = (props: Props) => {
  const delimiter = useMemo(() => props.delimiter || ".", [props.delimiter]);
  const decimals = useMemo(() => props.decimals || 2, [props.decimals]);
  const [internalValue, setInternalValue] = useState(
    Tools.numberToText(props.value || 0, decimals, delimiter)
  );

  useEffect(() => {
    if (props.value !== undefined) {
      setInternalValue(Tools.numberToText(props.value, decimals, delimiter));
    }
  }, [decimals, delimiter, props.value]);

  const finish = (val: string) => {
    if (props.onFinish) {
      props.onFinish(Tools.textToNumber(val));
    }
  };

  const change = (val: string) => {
    setInternalValue(val);
    if (props.onChange) {
      props.onChange(Tools.textToNumber(val));
    }
  };

  const enterPress = (val: string) => {
    if (props.onEnterPress) {
      props.onEnterPress(Tools.textToNumber(val));
    }
  };

  return (
    <>
      <TextInput
        value={internalValue}
        size={props.size}
        disabled={props.disabled}
        elementId={props.elementId}
        className={props.className}
        invalid={props.invalid}
        prependText={props.unit || undefined}
        onFinish={finish}
        onChange={change}
        onEnterPress={enterPress}
      />
    </>
  );
};

export default TextInput;
