import { ColorUtils } from "imagine-essentials";
import {
  BranchProfileTemplates,
  FlowerProfileTemplates,
  FruitProfileTemplates,
  LeafProfileTemplates,
} from "../templates";
import { ProfileTemplate } from "../types";
import { ModelDesign, PlantCategory, ShapeProperties, ShapeType } from "draw";

const getLeafProfileDesigns = (
  color: string,
  category: PlantCategory | undefined
) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const leafDesigns = LeafProfileTemplates.filter((data: ProfileTemplate) => {
    if (category === undefined) return true;
    if (data.categories === undefined) return true;
    return data.categories.includes(category);
  }).map((data: ProfileTemplate) => {
    const shapeStyles = [
      {
        border: true,
        borderColor: ColorUtils.darkenColor(visibleColor),
        fill: true,
        fillColor: visibleColor,
        shineColor: ColorUtils.lightenColor(visibleColor),
      },
      {
        border: true,
        borderColor: ColorUtils.darkenColor(visibleColor),
        fill: false,
      },
    ];
    const shapes: ShapeProperties[] = [];
    data.paths.forEach((path) => {
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[0],
      });
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[1],
      });
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: shapes,
      viewBox: `0 0 ${data.size.width} ${data.size.height}`,
    };
    return design;
  });
  return leafDesigns;
};

const getFlowerProfileDesigns = (
  color: string,
  stemColor: string,
  leafProfileTemplate: number
) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const flowerDesigns = FlowerProfileTemplates.filter((data: ProfileTemplate) =>
    data.leafTemplates?.includes(leafProfileTemplate)
  ).map((data: ProfileTemplate) => {
    const shapeStyles = [
      {
        // borderColor: ColorUtils.darkenColor(visibleColor),
        fillColor: visibleColor,
      },
      // {
      //   border: true,
      //   borderColor: ColorUtils.darkenColor(visibleColor),
      //   fill: false,
      // },
    ];

    const shapes: ShapeProperties[] = [];
    if (data.stemPaths) {
      data.stemPaths.forEach((stemPath) => {
        shapes.push({
          type: ShapeType.PATH,
          properties: {
            path: stemPath,
          },
          style: {
            borderColor: ColorUtils.darkenColor(stemColor),
            fillColor: stemColor,
          },
        });
      });
    }
    data.paths.forEach((path) => {
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[0],
      });
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: shapes,
      viewBox: `0 0 ${data.size.width} ${data.size.height}`,
    };
    return design;
  });
  return flowerDesigns;
};

const getBranchProfileDesigns = (
  color: string,
  leafProfileTemplate: number
) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const branchDesigns = BranchProfileTemplates.filter((data: ProfileTemplate) =>
    data.leafTemplates?.includes(leafProfileTemplate)
  ).map((data: ProfileTemplate) => {
    const shapeStyles = [
      {
        borderColor: ColorUtils.darkenColor(visibleColor),
        fillColor: visibleColor,
      },
      // {
      //   border: true,
      //   borderColor: ColorUtils.darkenColor(visibleColor),
      //   fill: false,
      // },
    ];

    const shapes: ShapeProperties[] = [];

    data.paths.forEach((path) => {
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[0],
      });
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: shapes,
      viewBox: `0 0 ${data.size.width} ${data.size.height}`,
    };
    return design;
  });
  return branchDesigns;
};

const getFruitProfileDesigns = (color: string, leafProfileTemplate: number) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const fruitDesigns = FruitProfileTemplates.filter((data: ProfileTemplate) =>
    data.leafTemplates?.includes(leafProfileTemplate)
  ).map((data: ProfileTemplate) => {
    const shapeStyles = [
      {
        borderColor: ColorUtils.darkenColor(visibleColor),
        fillColor: visibleColor,
      },
    ];

    const shapes: ShapeProperties[] = [];

    data.paths.forEach((path) => {
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[0],
      });
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: shapes,
      viewBox: `0 0 ${data.size.width} ${data.size.height}`,
    };
    return design;
  });
  return fruitDesigns;
};

export const PlantProfileData = {
  getLeafProfileDesigns,
  getFlowerProfileDesigns,
  getBranchProfileDesigns,
  getFruitProfileDesigns,
};
