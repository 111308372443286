const green = [
  "#e9ffdb", // Nyanza
  "#b2ccc4", // Light blue-green
  "#acdf87", // Crayola yellow green
  "#85bb65", // Dollar bill
  "#71a92c", // Corn green
  "#2a8000", // Napier green
  "#0b6623", // Forrest green
  "#c4d300", // Shrek green
  "#8db600", // Apple green
  "#8a9a5b", // Moss green
  "#6c925c", // Tennis court green
  // "#4f7942", // Fern green
  "#123524", // Phthalo green
];

const violet = [
  "#fae6fa", // Pale purple
  "#e0b0ff", // Mauve
  "#db7093", // Pale violet red
  "#da70d6", // Orchid
  "#df44ff", // Phlox
  "#b65fcf", // Lilac
  "#9966cc", // Amethyst
  "#9370db", // Medium purple
  "#893bff", // Aztech purple
  "#4b0082", // Indigo
  "#720058", // Pompadour
  "#32174d", // Russian violet
];

//

const yellow = [
  "#fffacd", // Lemon chiffon
  "#fffe71", // Pastel yellow
  "#fff000", // Yellow rose
  "#fedc56", // Mustard
  "#ffd700", // Gold
  "#e2ca76", // Sand
];

const orange = [
  "#f9c596", // Apricot orange
  "#ffbd31", // Honey
  "#ffad01", // Orange yellow
  // "#eaa221", // Marigold orange
  "#f78702", // Tangerine
  "#e26310", // Metallic orange
  "#ff5e0e", // Vivid orange
];

// const red = ["#ed2939", "#d21f3c", "#ff0800", "#d30000", "#960019", "#800000"];
const red = [
  "#a52a2a", // Auburn red
  "#e3242b", // Rose
  "#b90e0a", // Crimson
  "#900d09", // Scarlet

  "#680c07", // Currant
  "#420c09", // Mahogany
];
const pink = [
  "#ffd1dc", // Pastel pink
  "#ffb7c5", // Cherry blossom
  "#ff66cc", // Rose pink
  "#ffa07a", // Light salmon
  "#f08080", // Light coral
  "#e75480", // Dark pink
];
// const pink = ["#fdb9c8", "#f987c5", "#ff00ff", "#fe5bac", "#fc0fc0", "#fb607f"];

const blue = [
  "#c9ffe5", // Aero blue
  "#add8e6", // Sky blue
  "#a1caf1", // Baby blue eyes
  "#6495ed", // Cornflower blue
  "#4682b4", // Steel blue
  "#b2ffff", // Celeste
  "#40e0d0", // Turqoise
  "#008080", // Teal
  "#5d8aa8", // Air force blue
  "#5a4fcf", // Iris blue
  "#4c516d", // Independence blue
  "#2c3863", // Admiral blue
];

const brown = [
  "#f5f5dc", // Beige
  "#fad6a5", // Champagne
  "#c3b091", // Khaki
  "#c49102", // Dijon
  "#bb9351", // Maple syrup
  "#cd7f32", // Bronze
  "#9b7653", // Dirt
  "#98623c", // Ash brown
  "#7f7053", // Gray brown
  "#937a62", // Hardwood
  "#6b4423", // Kobicha
  "#4b3621", // Cafe noir
];

const grey = [
  "#ffffff", // White
  "#f5f5f5", // White smoke
  "#dcdcdc", // Gainsboro
  "#c0c0c0", // Silver
  "#a9a9a9", // Dark gray
  "#848482", // Old silver
  "#5e5e5e", // Scorpion
  "#726e6d", // Smokey grey
  "#5c5858", // Gray dolphin
  "#36454f", // Charcoal
  "#2a3439", // Gunmetal
  "#000000", // Black
];

// const allColors = yellow.concat(orange, red, pink, violet, blue, green, brown, grey);
const allColors = yellow.concat(
  orange,
  pink,
  red,
  violet,
  blue,
  green,
  brown,
  grey
);

const colorMatrix = [
  yellow,
  orange,
  pink,
  red,
  violet,
  blue,
  green,
  brown,
  grey,
];

export const Colors = {
  allColors,
  colorMatrix,
  green,
  brown,
  violet,
  grey,
  orange,
  yellow,
  red,
  pink,
  blue,
};
