import { useMemo } from "react";
import { Trans, useTranslation } from "react-i18next";
import { CancelReason, MembershipsData } from "..";
import { Checkbox, TextInput } from "imagine-ui";
import { Option } from "imagine-essentials";

interface Props {
  reason: CancelReason[];
  comment: string;
  onReasonChanged: (reason: CancelReason[]) => void;
  onCommentChanged: (comment: string) => void;
}

/**
 * Displays a list of reasons for cancelling the subscription, and also a text area for the user to write a comment.
 * @param props
 * @returns
 */
export const CancelSubscriptionConfirm = (props: Props) => {
  const { t } = useTranslation();
  const options = useMemo(() => {
    return MembershipsData.getCancelReasonOptions();
  }, []);

  const isSelected = (reason: CancelReason) => {
    return props.reason.includes(reason);
  };

  const toggle = (reason: CancelReason, selected: boolean) => {
    if (selected) {
      if (!props.reason.includes(reason))
        props.onReasonChanged([...props.reason, reason]);
    } else {
      props.onReasonChanged(
        props.reason.filter((r: CancelReason) => r !== reason)
      );
    }
  };

  return (
    <>
      <p className="mb-xl">{t("provideReasonForCancellingSubscription")}</p>
      <div>
        {options.map((reason: Option) => (
          <Checkbox
            key={reason.value}
            className="mb"
            label={reason.label}
            checked={isSelected(Number(reason.value))}
            onChange={(checked: boolean) =>
              toggle(Number(reason.value), checked)
            }
          />
        ))}
      </div>
      <p className="mt-xl">{t("memberships:pleaseElaborate")}:</p>
      <TextInput
        value={props.comment}
        multiline
        onFinish={props.onCommentChanged}
      />
    </>
  );
};
