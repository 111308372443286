import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Alert, Button, TextInput } from "imagine-ui";

type Props = {
  onSave: (currentPassword: string, newPassword: string) => void;
  className?: string;
  errorMessage?: string;
  loading?: boolean;
  successMessage?: string;
};

/**
 * Displays a form to change password.
 */
export const ChangePassword = (props: Props) => {
  const { t } = useTranslation();

  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordRepeated, setNewPasswordRepeated] = useState("");

  // Whether the data has been validated, this does not indicate whether validation passed or failed
  const [validated, setValidated] = useState(false);
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [newPasswordError, setNewPasswordError] = useState("");
  const [newPasswordRepeatedError, setNewPasswordRepeatedError] = useState("");

  const validate = () => {
    let valid = true;
    if (currentPassword == "") {
      setCurrentPasswordError(t("users:missingCurrentPassword"));
      valid = false;
    } else setCurrentPasswordError("");

    if (newPassword.length < 6) {
      setNewPasswordError(t("users:passwordMin6Characters"));
      valid = false;
    } else setNewPasswordError("");

    if (newPassword !== newPasswordRepeated) {
      setNewPasswordRepeatedError(t("users:repeatedPasswordNotMatching"));
      valid = false;
    } else setNewPasswordRepeatedError("");
    setValidated(true);

    return valid;
  };

  const clearValidation = () => {
    if (validated) {
      setValidated(false);
    }
  };

  const save = () => {
    if (validate()) {
      props.onSave(currentPassword, newPassword);
    }
  };

  return (
    <div id="change-password">
      {props.errorMessage && (
        <p className="text-danger" id="change-password-error-message">
          {props.errorMessage}
        </p>
      )}
      {props.successMessage && (
        <Alert color="success" elementId="change-password-success-message">
          {props.successMessage}
        </Alert>
      )}
      <div
        className={
          "grid columns-4 columns-1--sm" +
          (props.className ? props.className : "")
        }
      >
        <div></div>
        <div className="colspan-2 colspan-1--sm">
          <div className="mb">
            <label className="label">{t("users:currentPassword")}:</label>
            <TextInput
              elementId="change-password-current-password"
              invalid={currentPasswordError !== "" && validated}
              value={currentPassword}
              onFinish={(value: string) => setCurrentPassword(value)}
              password={true}
              onChange={clearValidation}
            />
            {currentPasswordError && validated && (
              <div className="text-danger">{currentPasswordError}</div>
            )}
          </div>
          <div className="mb">
            <label className="label">{t("users:newPassword")}:</label>
            <TextInput
              elementId="change-password-new-password"
              invalid={newPasswordError !== "" && validated}
              value={newPassword}
              onFinish={(value: string) => setNewPassword(value)}
              password={true}
              onChange={clearValidation}
            />
            {newPasswordError && validated && (
              <div className="text-danger">{newPasswordError}</div>
            )}
          </div>
          <div className="mb">
            <label className="label">{t("users:repeatNewPassword")}:</label>
            <TextInput
              elementId="change-password-new-password-repeated"
              invalid={newPasswordRepeatedError !== "" && validated}
              value={newPasswordRepeated}
              onFinish={(value: string) => setNewPasswordRepeated(value)}
              password={true}
              onChange={clearValidation}
            />
            {newPasswordRepeatedError && validated && (
              <div className="text-danger">{newPasswordRepeatedError}</div>
            )}
          </div>
        </div>
        <div></div>
      </div>
      <div className="row items-right">
        <Button
          elementId="change-password-update"
          onClick={save}
          loading={props.loading}
        >
          {t("imagine:save")}
        </Button>
      </div>
    </div>
  );
};
