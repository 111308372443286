/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Area,
  Group,
  Item,
  ItemType,
  Layer,
  LayerOperations,
  ObjectSchema,
  ObjectsApi,
  Plan,
  PlanConverter,
  PlanData,
  PlanInfo,
  PlanSettings,
  Zoom,
  PlantSchema,
} from "draw";
import { Api, AppInfo, SentryReporter } from "imagine-essentials";

import * as htmlToImage from "html-to-image";
import download from "downloadjs";
import { PlanPatcher } from ".";
import { PlantsApi } from "plants";

/**
 * Get all default plan settings. Currently not used.
 */
const getDefaultPlanSettings = () => {
  return {
    zoom: Zoom.getZoomValue(80),
    zeroReference: { x: 0, y: 0 },
    showLayers: false,
    showGroups: false,
    showPlantLibrary: true,
    showObjectLibrary: true,
    showScale: true,
    showGrid: true,
    showPlantHeights: false,
  };
};

const getDefaultPlanInfo = () => {
  return {
    name: "",
    id: 0,
    userId: 0,
    version: AppInfo.getAppVersion(),
    dateCreated: "",
    dateUpdated: "",
  } as PlanInfo;
};

/**
 * Get an empty plan with all plan settings reset to default values.
 */
const getEmptyPlanData = () => {
  return {
    layers: LayerOperations.getDefaultLayers(),
    groups: [],
    items: [],
  } as PlanData;
};

const downloadSnapshot = async (name: string) => {
  const element = document.getElementById("board");

  if (element !== null) {
    try {
      const options = {
        backgroundColor: "white",
      };
      const dataUrl = await htmlToImage.toBlob(element, options);
      if (dataUrl !== null) {
        download(dataUrl, name + ".png");
      }
    } catch (error) {
      console.error("Unable to capture image from canvas element", error);
      return null;
    }
  } else {
    console.error("Unable to find board element");
  }
  return null;
};

const getPlanThumbnailImage = async () => {
  const element = document.getElementById("board");

  if (element !== null) {
    try {
      const width = element.clientWidth;
      const height = element.clientHeight;
      const options = {
        backgroundColor: "white",
        width: 400,
        height: 400 * (height / width),
      };
      return await htmlToImage.toBlob(element, options);
    } catch (error) {
      console.error("Unable to capture image from canvas element", error);
      return null;
    }
  } else {
    console.error("Unable to find board element");
  }
  return null;
};

/**
 * Read the plan data from the backend. If the data is compressed it is umcompressed. Throws exception
 * in case of error.
 * @param data Data from backend
 * @returns
 */
const getPlanDataFromBackend = (data: any, version: string) => {
  if (data.l !== undefined && data.g !== undefined && data.i !== undefined) {
    // Compressed data
    const uncompressedData = PlanConverter.uncompressPlanData(data);
    uncompressedData.items = PlanPatcher.patchItems(
      uncompressedData.items,
      version
    );
    return uncompressedData;
  } else if (
    data.layers !== undefined &&
    data.groups !== undefined &&
    data.items !== undefined
  ) {
    // Uncompressed data
    const planData = getEmptyPlanData();
    if (data.layers !== undefined) {
      planData.layers = data.layers as Layer[];
    } else console.warn("Plan layers could not be read");
    if (data.groups !== undefined) {
      planData.groups = data.groups as Group[];
    } else console.warn("Plan groups could not be read");
    if (data.items !== undefined) {
      planData.items = PlanPatcher.patchItems(data.items, version);
    } else console.warn("Plan items could not be read");
    if (data.area !== undefined && data.area !== null) {
      planData.area = data.area as Area;
    } else console.warn("Area could not be read");
    if (data.planSettings !== undefined) {
      // Only old/uncompressed files might need to patch settings
      planData.planSettings = PlanPatcher.patchSettings(
        data.planSettings,
        version
      );
    }
    return planData;
  }

  console.error(data);
  SentryReporter.captureException("Invalid plan data received from backend", {
    "Plan data": JSON.stringify(data),
  });
  return getEmptyPlanData();
};

const getPlanInfoFromObject = (info: any) => {
  const planInfo = getDefaultPlanInfo();
  if (info.id !== undefined) {
    planInfo.id = info.id;
  }
  if (info.name !== undefined) {
    planInfo.name = info.name;
  }
  if (info.dateCreated !== undefined) {
    planInfo.dateCreated = info.dateCreated;
  }
  if (info.dateUpdated !== undefined) {
    planInfo.dateUpdated = info.dateUpdated;
  }
  if (info.version !== undefined) {
    planInfo.version = info.version;
  }
  if (info.userId !== undefined) {
    planInfo.userId = info.userId;
  }
  return planInfo;
};

const getPlantTemplatesForItems = async (
  usedPlantTemplates: PlantSchema[],
  newItems: Item[]
) => {
  const plantTemplateIds: number[] = [];
  const loadedPlantTemplateIds: number[] = [];

  const existingIds = usedPlantTemplates.map((template: PlantSchema) => {
    return template.id;
  });

  newItems.forEach((item: Item) => {
    if (item.type === ItemType.PLANT && item.templateId !== undefined) {
      if (
        !plantTemplateIds.includes(item.templateId) &&
        !existingIds.includes(item.templateId)
      ) {
        plantTemplateIds.push(item.templateId);
      }
      if (
        !plantTemplateIds.includes(item.templateId) &&
        existingIds.includes(item.templateId)
      ) {
        loadedPlantTemplateIds.push(item.templateId);
      }
    }
  });
  let templates = usedPlantTemplates.filter((plant: PlantSchema) => {
    return loadedPlantTemplateIds.includes(plant.id);
  });

  const response = await PlantsApi.getMultiplePlantTemplates(plantTemplateIds);
  if (response.success) {
    templates = templates.concat(response.data || []);
  } else {
    // Should not continue if plant templates failed to load
    // return undefined;
  }
  return templates;
};

// Loads all needed plant templates if they are not in the used plant templates list yet
const getObjectTemplatesForItems = async (
  usedObjectTemplates: ObjectSchema[],
  newItems: Item[]
) => {
  // Get list of template ids
  const objectTemplateIds: number[] = [];
  const loadedObjectTemplateIds: number[] = [];
  const existingIds = usedObjectTemplates.map((template: ObjectSchema) => {
    return template.id;
  });

  newItems.forEach((item: Item) => {
    if (item.type === ItemType.OBJECT && item.templateId !== undefined) {
      if (
        !objectTemplateIds.includes(item.templateId) &&
        !existingIds.includes(item.templateId)
      ) {
        objectTemplateIds.push(item.templateId);
      } else if (
        !objectTemplateIds.includes(item.templateId) &&
        existingIds.includes(item.templateId)
      ) {
        loadedObjectTemplateIds.push(item.templateId);
      }
    }
  });
  // const templates: ObjectSchema[] = [];
  // Keep the templates that are needed in the new plan
  let templates = usedObjectTemplates.filter((object: ObjectSchema) => {
    return loadedObjectTemplateIds.includes(object.id);
  });

  const response = await ObjectsApi.getMultipleObjectTemplates(
    objectTemplateIds
  );
  if (response.success) {
    templates = templates.concat(response.data ?? []);
  }
  if (response.data) {
    if (response.data.length < objectTemplateIds.length) {
      console.error("Requested:", objectTemplateIds);
      console.error("Loaded", response.data);
      console.error("Browser language:", navigator.language);
      SentryReporter.captureException("Unable to load all requested objects", {
        "Template IDs": JSON.stringify(objectTemplateIds),
        "Loaded templates": JSON.stringify(
          response.data.map((obj) => {
            return { id: obj.id, name: obj.name };
          })
        ),
      });
    }
  }

  return templates;
};

export const PlanOperations = {
  getDefaultPlanSettings,
  getDefaultPlanInfo,
  getEmptyPlanData,
  downloadSnapshot,
  getPlanThumbnailImage,
  getPlanDataFromBackend,
  getPlanInfoFromObject,
  getPlantTemplatesForItems,
  getObjectTemplatesForItems,
};
