import {
  RectangleProperties,
  EllipseProperties,
  LinePathProperties,
  CurvedLinePathProperties,
  TextProperties,
  DimensionProperties,
  ShapeProperties,
  Item,
  ShapeType,
} from "..";

import _ from "lodash";

const cloneRectangleProperties = (
  properties: RectangleProperties
): RectangleProperties => {
  return _.cloneDeep(properties);
};

const cloneEllipseProperties = (
  properties: EllipseProperties
): EllipseProperties => {
  return _.cloneDeep(properties);
};

const cloneLinePathProperties = (
  properties: LinePathProperties
): LinePathProperties => {
  return {
    points: properties.points.map((point) => ({ x: point.x, y: point.y })),
    closePath: properties.closePath,
  };
};

const cloneCurvedLinePathProperties = (
  properties: CurvedLinePathProperties
): CurvedLinePathProperties => {
  return {
    curves: properties.curves.map((curve) => {
      return _.cloneDeep(curve);
    }),
    closePath: properties.closePath,
  };
};

const cloneTextProperties = (properties: TextProperties): TextProperties => {
  const textLines = properties.textLines
    ? properties.textLines.map((line) => line)
    : undefined;
  const clonedProperties = _.cloneDeep(properties);
  clonedProperties.textLines = textLines;
  return clonedProperties;
};

const cloneDimensionProperties = (
  properties: DimensionProperties
): DimensionProperties => {
  return _.cloneDeep(properties);
};

const cloneShapeProperties = (properties: ShapeProperties): ShapeProperties => {
  const clonedProperties = _.cloneDeep(properties);
  switch (properties.type) {
    case ShapeType.RECTANGLE:
      clonedProperties.properties = cloneRectangleProperties(
        properties.properties as RectangleProperties
      );
      break;
    case ShapeType.ELLIPSE:
      clonedProperties.properties = cloneEllipseProperties(
        properties.properties as EllipseProperties
      );
      break;
    case ShapeType.LINE:
      clonedProperties.properties = cloneLinePathProperties(
        properties.properties as LinePathProperties
      );
      break;
    case ShapeType.CURVED_LINE:
      clonedProperties.properties = cloneCurvedLinePathProperties(
        properties.properties as CurvedLinePathProperties
      );
      break;
    case ShapeType.TEXT:
      clonedProperties.properties = cloneTextProperties(
        properties.properties as TextProperties
      );
      break;
    case ShapeType.DIMENSION:
      clonedProperties.properties = cloneDimensionProperties(
        properties.properties as DimensionProperties
      );
      break;
  }
  return clonedProperties;
};

const cloneItem = (item: Item): Item => {
  const clonedItem = _.cloneDeep(item);
  if (item.shapeProperties) {
    clonedItem.shapeProperties = cloneShapeProperties(item.shapeProperties);
  }
  return clonedItem;
};

export const Cloner = {
  cloneItem,
  cloneShapeProperties,
  cloneRectangleProperties,
  cloneEllipseProperties,
  cloneLinePathProperties,
  cloneCurvedLinePathProperties,
  cloneTextProperties,
  cloneDimensionProperties,
};
