/**
 * The color groups that plants can be filtered by.
 */
export enum ColorGroup {
  UNKNOWN = -1,
  NONE = 0,
  WHITE = 1,
  BLACK = 2,
  YELLOW = 3,
  ORANGE = 4,
  RED = 5,
  PINK = 6,
  VIOLET = 7,
  BLUE = 8,
  GREEN = 9,
  BROWN = 10,
  GREY = 11,
}
