import { MarkerType } from "..";

interface Props {
  uniqueId: string; /// Unique ID is added to the type
  type: MarkerType;
  fill: string;
}

/**
 * Marker to put at the end of a line to create arrows etc.
 */
export const Marker = (props: Props) => {
  const size = 12;
  const getRefX = () => {
    if (props.type === MarkerType.ARROW_END) return size;
    else return 0;
  };

  const getMarkerShape = () => {
    switch (props.type) {
      case MarkerType.ARROW_END:
        return <path d="M12,6 L0,10 L0,2 L12,6" fill={props.fill} />;
      case MarkerType.ARROW_START:
        return <path d="M0,6 L12,2 L12,10 L0,6" fill={props.fill} />;
      default:
        throw Error("Undefined marker type");
    }
  };

  return (
    <marker
      id={props.uniqueId}
      markerWidth={size}
      markerHeight={size}
      refX={getRefX()}
      refY={size / 2}
      orient="auto"
      viewBox="0 0 12 12"
      markerUnits="strokeWidth"
    >
      {getMarkerShape()}
    </marker>
  );
};
