import { PlantProperty } from "../types/PlantProperty";
import { useMemo } from "react";
import sunIcon from "../assets/plant-properties/sun.svg";
import semiShadeIcon from "../assets/plant-properties/semi-shade.svg";
import shadeIcon from "../assets/plant-properties/shade.svg";
import fallColorIcon from "../assets/plant-properties/fall-color.svg";
import groundcoverIcon from "../assets/plant-properties/groundcover.svg";
import evergreenIcon from "../assets/plant-properties/evergreen.svg";
import windIcon from "../assets/plant-properties/wind.svg";
import beeIcon from "../assets/plant-properties/bee.svg";
import birdIcon from "../assets/plant-properties/bird.svg";
import edibleIcon from "../assets/plant-properties/edible.svg";
import fragrantIcon from "../assets/plant-properties/fragrant.svg";
import allergyIcon from "../assets/plant-properties/allergy.svg";
import coastIcon from "../assets/plant-properties/coast.svg";
import cutFlowerIcon from "../assets/plant-properties/cut-flower.svg";
import nitrogenIcon from "../assets/plant-properties/nitrogen.svg";
import potassiumIcon from "../assets/plant-properties/potassium.svg";
import poisonousIcon from "../assets/plant-properties/poisonous.svg";
import dryIcon from "../assets/plant-properties/dry.svg";
import wellDrainedIcon from "../assets/plant-properties/well-drained.svg";
import wetIcon from "../assets/plant-properties/wet.svg";
import waterplantIcon from "../assets/plant-properties/waterplant.svg";
import winterIcon from "../assets/plant-properties/winter.svg";
import thornsIcon from "../assets/plant-properties/thorn.svg";
import phLowIcon from "../assets/plant-properties/ph-low.svg";
import phNeutralIcon from "../assets/plant-properties/ph-neutral.svg";
import phHighIcon from "../assets/plant-properties/ph-high.svg";
import sandIcon from "../assets/plant-properties/sand.svg";
import clayIcon from "../assets/plant-properties/clay.svg";
import loamIcon from "../assets/plant-properties/loam.svg";
import rootIcon from "../assets/plant-properties/roots.svg";
import seedsIcon from "../assets/plant-properties/seeds.svg";
import nativeIcon from "../assets/plant-properties/native.svg";
import potsIcon from "../assets/plant-properties/pots.svg";
import hedgeIcon from "../assets/plant-properties/hedge.svg";
import specialCareIcon from "../assets/plant-properties/special-care.svg";
import { PlantText } from "../utils";

interface Props {
  property: PlantProperty;
  className?: string;
}

/**
 * Displays an icon for a plant property.
 * @param props property
 * @returns
 */
export const PlantPropertyIcon = (props: Props) => {
  const iconSrc = useMemo(() => {
    switch (props.property) {
      case "sun":
        return sunIcon;
      case "semiShade":
        return semiShadeIcon;
      case "shade":
        return shadeIcon;
      case "fallColors":
        return fallColorIcon;
      case "groundcover":
        return groundcoverIcon;
      case "evergreen":
        return evergreenIcon;
      case "wind":
        return windIcon;
      case "bees":
        return beeIcon;
      case "birds":
        return birdIcon;
      case "edible":
        return edibleIcon;
      case "allergyFriendly":
        return allergyIcon;
      case "coast":
        return coastIcon;
      case "cutFlowers":
        return cutFlowerIcon;
      case "nitrogen":
        return nitrogenIcon;
      case "carbon":
        return potassiumIcon;
      case "poisonous":
        return poisonousIcon;
      case "drySoil":
        return dryIcon;
      case "drainedSoil":
        return wellDrainedIcon;
      case "wetSoil":
        return wetIcon;
      case "waterPlant":
        return waterplantIcon;
      case "fragrant":
        return fragrantIcon;
      case "winter":
        return winterIcon;
      case "thorns":
        return thornsIcon;
      case "acidSoil":
        return phLowIcon;
      case "alkalicSoil":
        return phHighIcon;
      case "neutralSoil":
        return phNeutralIcon;
      case "sandySoil":
        return sandIcon;
      case "loamSoil":
        return loamIcon;
      case "claySoil":
        return clayIcon;
      case "rootSpreading":
        return rootIcon;
      case "seedSpreading":
        return seedsIcon;
      case "native":
        return nativeIcon;
      case "pots":
        return potsIcon;
      case "hedge":
        return hedgeIcon;
      case "specialCare":
        return specialCareIcon;
      default:
        return "";
    }
  }, [props.property]);
  const text = PlantText.getPlantPropertyText(props.property);
  return (
    <img
      alt={text}
      src={iconSrc}
      title={text}
      width="18px"
      className={props.className}
    ></img>
  );
};
