import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlantSearchFilter } from "../types";

import _ from "lodash";
import { Button, Checkbox, Label, Select, TextInput } from "imagine-ui";
import { ColorCheckboxes, MonthCheckboxes, PlantCategoryCheckboxes } from ".";
import { ColorGroup, MeasureInput, PlantCategory } from "draw";
import { UnitScale } from "project";
import { Delimiter } from "imagine-essentials";
import { PlantData } from "..";

const toIntHeight = (heightDec: number) => {
  return Math.round(heightDec * 100);
};

const fromIntHeight = (heightInt: number) => {
  return heightInt / 100;
};

type PlantFinderPageProps = {
  onSearch: (filter: PlantSearchFilter) => void;
  onReset: () => void;
  layout?: "narrow" | "medium";
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneUSDA?: boolean;
  enableBetaFeatures?: boolean;
};

const labelClass = "text-bold pb";

/**
 * Shows the plant filter options in a vertical layout.
 */
export const PlantFilter = (props: PlantFinderPageProps) => {
  const { t, i18n } = useTranslation();
  const defaultFilter = useMemo(() => {
    const newFilter: PlantSearchFilter = {};

    return newFilter;
  }, []);

  const [filter, setFilter] = useState<PlantSearchFilter>({ ...defaultFilter });

  /**
   * Reset the filter.
   */
  const reset = () => {
    setFilter({ ...defaultFilter });
    props.onReset();
  };

  const search = async () => {
    // Clear empty filter properties
    const sanitizedFilter = { ...filter };
    if (sanitizedFilter.name === "") {
      delete sanitizedFilter.name;
    }
    if (sanitizedFilter.category?.length === 0) {
      delete sanitizedFilter.category;
    }
    if (sanitizedFilter.flowerColor?.length === 0) {
      delete sanitizedFilter.flowerColor;
    }
    if (sanitizedFilter.flowerSeason?.length === 0) {
      delete sanitizedFilter.flowerSeason;
    }
    if (sanitizedFilter.heightMax === 0) {
      delete sanitizedFilter.heightMax;
    }
    if (sanitizedFilter.heightMin === 0) {
      delete sanitizedFilter.heightMin;
    }
    props.onSearch(sanitizedFilter);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateFilter = (object: any) => {
    const f = Object.assign(filter, object);
    setFilter(_.cloneDeep(f));
  };

  const updateCategories = (categories: PlantCategory[]) => {
    setFilter({
      ...filter,
      category: categories,
    });
  };

  const updateFlowerColors = (colors: ColorGroup[]) => {
    setFilter({
      ...filter,
      flowerColor: colors,
    });
  };

  const updateLeafColors = (colors: ColorGroup[]) => {
    setFilter({
      ...filter,
      leafColor: colors,
    });
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updateProperties = (object: any) => {
    const properties = filter.properties || {};
    const newProperties = Object.assign(properties, object);
    setFilter({ ...filter, properties: newProperties });
  };

  const hardinessZoneOptions = useMemo(() => {
    if (!i18n.language) return [];
    if (props.showHardinessZoneUSDA) {
      return PlantData.getHardinessZoneOptionsUSDA(t("imagine:all"));
    }
    return [];
  }, [props.showHardinessZoneUSDA, t, i18n.language]);

  return (
    <>
      <div className="grid gap mb">
        <Button icon="magnifier" onClick={search}>
          {t("imagine:search")}
        </Button>
        <Button className="mb-3" size="sm" outline onClick={reset}>
          {t("imagine:reset")}
        </Button>
      </div>
      <div
        className={
          "grid gap " + (props.layout === "narrow" ? "columns-1" : "columns-2")
        }
      >
        <div className="mb mb-xs--lg">
          <Label className={labelClass}>{t("imagine:name")}:</Label>
          <TextInput
            value={filter.name || ""}
            onFinish={(value: string) => updateFilter({ name: value })}
          />
        </div>

        <div className="mb mb-xs--lg">
          {props.unitScale === UnitScale.METRIC && (
            <Label className={labelClass}>{t("imagine:height")}:</Label>
          )}
          {props.unitScale === UnitScale.IMPERIAL && (
            <Label className={labelClass}>{t("plants:minHeight")}:</Label>
          )}
          <div
            className={
              props.unitScale === UnitScale.METRIC ? "row items-center" : ""
            }
          >
            <MeasureInput
              value={fromIntHeight(filter.heightMin || 0)}
              onFinish={(value: number) =>
                updateFilter({ heightMin: toIntHeight(value) })
              }
              className="stretch"
              unitScale={props.unitScale}
              delimiter={props.delimiter}
              omitYards
              omitFractions
            />
            {props.unitScale === UnitScale.METRIC && (
              <span className="mx">-</span>
            )}
            {props.unitScale === UnitScale.IMPERIAL && (
              <Label className={labelClass + " mt"}>
                {t("plants:maxHeight")}:
              </Label>
            )}

            <MeasureInput
              value={fromIntHeight(filter.heightMax || 0)}
              onFinish={(value: number) =>
                updateFilter({ heightMax: toIntHeight(value) })
              }
              className="stretch"
              unitScale={props.unitScale}
              delimiter={props.delimiter}
              omitYards
              omitFractions
            />
          </div>
        </div>

        {props.showHardinessZoneUSDA && (
          <div className="mb">
            <Label className={labelClass}>{t("plants:hardinessZone")}:</Label>
            <Select
              elementId="hardiness-zone"
              selected={filter.hardinessZone || 0}
              onSelectedNumber={(val: number) =>
                updateFilter({ hardinessZone: val })
              }
              options={hardinessZoneOptions}
            />
          </div>
        )}
      </div>

      <div className="mb">
        <Label className={labelClass}>{t("imagine:category")}:</Label>

        <PlantCategoryCheckboxes
          columns={props.layout === "narrow" ? 2 : 3}
          onChange={updateCategories}
          selected={filter.category || []}
        />
      </div>

      <div className="mb">
        <Label className={labelClass}>{t("plants:flowerColor")}:</Label>
        <ColorCheckboxes
          onChange={updateFlowerColors}
          selected={filter.flowerColor || []}
          columns={props.layout === "narrow" ? 2 : 3}
        />
      </div>

      <div className="mb">
        <Label className={labelClass}>{t("plants:flowerSeason")}:</Label>
        <MonthCheckboxes
          selected={filter.flowerSeason || []}
          direction={props.layout === "narrow" ? "vertical" : "horizontal"}
          onChange={(months: number[]) =>
            updateFilter({ flowerSeason: months })
          }
        />
      </div>

      <div className="mb">
        <Label className={labelClass}>{t("plants:leafColor")}:</Label>
        <ColorCheckboxes
          onChange={updateLeafColors}
          selected={filter.leafColor || []}
          columns={props.layout === "narrow" ? 2 : 3}
          availableColors={[
            ColorGroup.GREEN,
            ColorGroup.RED,
            ColorGroup.VIOLET,
            ColorGroup.BROWN,
            ColorGroup.YELLOW,
            ColorGroup.WHITE,
          ]}
        />
      </div>

      <div
        className={
          "grid " + (props.layout === "narrow" ? "columns-1" : "columns-3")
        }
      >
        <div className="mt">
          <Label className={labelClass}>{t("plants:lightConditions")}:</Label>
          <Checkbox
            label={t("plants:sun")}
            checked={filter.properties?.sun || false}
            onChange={(value: boolean) => updateProperties({ sun: value })}
          />
          <Checkbox
            label={t("plants:semiShade")}
            checked={filter.properties?.semiShade || false}
            onChange={(value: boolean) =>
              updateProperties({ semiShade: value })
            }
          />
          <Checkbox
            label={t("plants:shade")}
            checked={filter.properties?.shade || false}
            onChange={(value: boolean) => updateProperties({ shade: value })}
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:soilTypes")}:</Label>
          <Checkbox
            id={"sandy-soil"}
            label={t("plants:sandySoil")}
            checked={filter.properties?.sandySoil || false}
            onChange={(value: boolean) =>
              updateProperties({ sandySoil: value })
            }
          />
          <Checkbox
            id={"clay-soil"}
            label={t("plants:claySoil")}
            checked={filter.properties?.claySoil || false}
            onChange={(value: boolean) => updateProperties({ claySoil: value })}
          />
          <Checkbox
            id={"loam-soil"}
            label={t("plants:loamySoil")}
            checked={filter.properties?.loamSoil || false}
            onChange={(value: boolean) => updateProperties({ loamSoil: value })}
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:waterConditions")}:</Label>
          <Checkbox
            id={"drained"}
            label={t("plants:wellDrainedSoil")}
            checked={filter.properties?.drainedSoil || false}
            onChange={(value: boolean) =>
              updateProperties({ drainedSoil: value })
            }
          />
          <Checkbox
            id={"dry-soil"}
            label={t("plants:drySoil")}
            checked={filter.properties?.drySoil || false}
            onChange={(value: boolean) => updateProperties({ drySoil: value })}
          />
          <Checkbox
            id={"wet-soil"}
            label={t("plants:wetSoil")}
            checked={filter.properties?.wetSoil || false}
            onChange={(value: boolean) => updateProperties({ wetSoil: value })}
          />
          <Checkbox
            id={"water-plant"}
            label={t("plants:waterPlant")}
            checked={filter.properties?.waterPlant || false}
            onChange={(value: boolean) =>
              updateProperties({ waterPlant: value })
            }
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:phConditions")}:</Label>
          <Checkbox
            id={"neutral-soil"}
            label={t("plants:neutralSoil")}
            checked={filter.properties?.neutralSoil || false}
            onChange={(value: boolean) =>
              updateProperties({ neutralSoil: value })
            }
          />
          <Checkbox
            id={"acid-soil"}
            label={t("plants:acidSoil")}
            checked={filter.properties?.acidSoil || false}
            onChange={(value: boolean) => updateProperties({ acidSoil: value })}
          />
          <Checkbox
            id={"alkalic-soil"}
            label={t("plants:alkalicSoil")}
            checked={filter.properties?.alkalicSoil || false}
            onChange={(value: boolean) =>
              updateProperties({ alkalicSoil: value })
            }
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:plantUsage")}:</Label>
          <Checkbox
            id={"groundcover"}
            label={t("plants:groundcover")}
            checked={filter.properties?.groundcover || false}
            onChange={(value: boolean) =>
              updateProperties({ groundcover: value })
            }
          />
          <Checkbox
            id={"edible"}
            label={t("plants:edibleFruitsBerryEtc")}
            checked={filter.properties?.edible || false}
            onChange={(value: boolean) => updateProperties({ edible: value })}
          />
          <Checkbox
            id={"cutFlower"}
            label={t("plants:cutFlower")}
            checked={filter.properties?.cutFlowers || false}
            onChange={(value: boolean) =>
              updateProperties({ cutFlowers: value })
            }
          />
          <Checkbox
            id={"pots"}
            label={t("plants:pots")}
            checked={filter.properties?.pots || false}
            onChange={(value: boolean) => updateProperties({ pots: value })}
          />
          <Checkbox
            id={"hedge"}
            label={t("plants:hedge")}
            checked={filter.properties?.hedge || false}
            onChange={(value: boolean) => updateProperties({ hedge: value })}
          />
          <Checkbox
            id={"coast"}
            label={t("plants:coastalArea")}
            checked={filter.properties?.coast || false}
            onChange={(value: boolean) => updateProperties({ coast: value })}
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:contributions")}:</Label>
          <Checkbox
            id={"bees"}
            label={t("plants:attractsBees")}
            checked={filter.properties?.bees || false}
            onChange={(value: boolean) => updateProperties({ bees: value })}
          />
          <Checkbox
            id={"birds"}
            label={t("plants:goodForBirds")}
            checked={filter.properties?.birds || false}
            onChange={(value: boolean) => updateProperties({ birds: value })}
          />
          <Checkbox
            id={"nitrogen"}
            label={t("plants:nitrogenFixating")}
            checked={filter.properties?.nitrogen || false}
            onChange={(value: boolean) => updateProperties({ nitrogen: value })}
          />

          <Checkbox
            id={"carbon"}
            label={t("plants:carbonFarming")}
            checked={filter.properties?.carbon || false}
            onChange={(value: boolean) => updateProperties({ carbon: value })}
          />
          <Checkbox
            id={"native"}
            label={t("plants:native")}
            checked={filter.properties?.native || false}
            onChange={(value: boolean) => updateProperties({ native: value })}
          />
        </div>

        <div className="mt">
          <Label className={labelClass}>{t("plants:plantQualities")}:</Label>

          <Checkbox
            label={t("plants:evergreen")}
            checked={filter.properties?.evergreen || false}
            onChange={(value: boolean) =>
              updateProperties({ evergreen: value })
            }
          />
          <Checkbox
            label={t("plants:winterInterest")}
            checked={filter.properties?.winter || false}
            onChange={(value: boolean) => updateProperties({ winter: value })}
          />
          <Checkbox
            label={t("plants:interestingFallColors")}
            checked={filter.properties?.fallColors || false}
            onChange={(value: boolean) =>
              updateProperties({ fallColors: value })
            }
          />

          <Checkbox
            id={"fragrant"}
            label={t("plants:fragrant")}
            checked={filter.properties?.fragrant || false}
            onChange={(value: boolean) => updateProperties({ fragrant: value })}
          />
          <Checkbox
            id={"allergyFriendly"}
            label={t("plants:allergyFriendly")}
            checked={filter.properties?.allergyFriendly || false}
            onChange={(value: boolean) =>
              updateProperties({ allergyFriendly: value })
            }
          />
          <Checkbox
            id={"wind"}
            label={t("plants:windTolerant")}
            checked={filter.properties?.wind || false}
            onChange={(value: boolean) => updateProperties({ wind: value })}
          />
        </div>
      </div>

      <div className="grid mt">
        <Button icon="magnifier" onClick={search}>
          {t("imagine:search")}
        </Button>
      </div>
    </>
  );
};
