import { Button, Device, Link, ProgressBar } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { CursorMode, MapMode, Pages, Tour } from "../../enums";
import {
  CanvasSelector,
  LayoutActions,
  LayoutSelector,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ItemType, ShapeType, TextProperties } from "draw";
import { UserActions, UserSelector, UserThunks } from "imagine-users";
import { FeatureKey, UnitScale, UserHelpers } from "project";

enum Steps {
  NEW_PLAN = 1,
  OPEN_DRAW_TAB,
  CLICK_RECTANGLE,
  DRAW_RECTANGLE,
  SELECT_RECTANGLE, // In case user deselected
  SET_WIDTH,
  SET_BACKGROUND_COLOR,
  SET_PATTERN,
  SET_OPACITY,
  SET_BORDER,
  ROTATE,
  LOCK,
  DESELECT_RECTANGLE,
  UNLOCK,
  OPEN_DRAW_TAB_ELLIPSE,
  CLICK_ELLIPSE,
  DRAW_ELLIPSE,
  OPEN_DRAW_TAB_LINES,
  CLICK_LINES,
  DRAW_LINES,
  OPEN_DRAW_TAB_CURVED_LINES,
  CLICK_CURVED_LINES,
  DRAW_CURVED_LINES,
  OPEN_DRAW_TAB_TEXT,
  CLICK_TEXT,
  DRAW_TEXT,
  EDIT_TEXT,
  ENTER_TEXT,
  OPEN_DRAW_TAB_DIMENSION,
  CLICK_DIMENSION,
  DRAW_DIMENSION,
  DONE,
}

interface Props {
  onFinish: () => void;
}

export const ShapesTourContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [lockCompleted, setLockCompleted] = useState(false);

  const touchDevice = Device.isTouchDevice();
  const planId = useAppSelector(PlanEditorSelector.getPlanId);
  const activeTab = useAppSelector(LayoutSelector.getActiveTab);
  const activeTabFallback = useAppSelector(LayoutSelector.getActiveTabFallback);
  const selectedShapeTool = useAppSelector(CanvasSelector.getSelectedShapeTool);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const selectedItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences)
  );

  const actualActiveTab = useMemo(() => {
    if (activeTab === "item-tab" && selectedItems.length === 0) {
      return activeTabFallback;
    }
    return activeTab;
  }, [activeTab, activeTabFallback, selectedItems]);

  const selectedShapeType = useMemo(() => {
    if (selectedItems.length !== 1) return undefined;
    const item = selectedItems[0];
    if (item.type !== ItemType.SHAPE) return undefined;
    const shapeProperties = item.shapeProperties;
    if (shapeProperties === undefined) return undefined;
    return shapeProperties.type;
  }, [selectedItems]);

  const shapeItems = useMemo(() => {
    return items.filter((item) => item.type === ItemType.SHAPE);
  }, [items]);

  const rectangleItem = useMemo(() => {
    return shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.RECTANGLE;
      }

      return false;
    });
  }, [shapeItems]);

  const ellipseItem = useMemo(() => {
    return shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.ELLIPSE;
      }
      return false;
    });
  }, [shapeItems]);

  const lineItem = useMemo(() => {
    return shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.LINE;
      }
      return false;
    });
  }, [shapeItems]);

  const curvedLineItem = useMemo(() => {
    return shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.CURVED_LINE;
      }
      return false;
    });
  }, [shapeItems]);

  const textItemProperties = useMemo(() => {
    const textItem = shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.TEXT;
      }
      return false;
    });
    if (textItem) return textItem.shapeProperties?.properties as TextProperties;
    return undefined;
  }, [shapeItems]);

  const dimensionItem = useMemo(() => {
    return shapeItems.find((item) => {
      const shapeProperties = item.shapeProperties;
      if (shapeProperties) {
        return shapeProperties.type === ShapeType.DIMENSION;
      }
      return false;
    });
  }, [shapeItems]);

  const rectangleStyle = useMemo(() => {
    const rectangle = items.find((item) => {
      if (item.type === ItemType.SHAPE) {
        const shapeProperties = item.shapeProperties;
        if (shapeProperties) {
          return shapeProperties.type === ShapeType.RECTANGLE;
        }
      }
      return false;
    });
    if (rectangle) return rectangle.shapeProperties?.style;
  }, [items]);

  const tourStep = useMemo(() => {
    if (planId) {
      return Steps.NEW_PLAN;
    }
    // Retangle and styling
    if (rectangleStyle === undefined || rectangleItem === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB;
      } else if (selectedShapeTool !== ShapeType.RECTANGLE) {
        return Steps.CLICK_RECTANGLE;
      } else {
        return Steps.DRAW_RECTANGLE;
      }
    } else {
      if (selectedShapeType !== ShapeType.RECTANGLE && !rectangleItem.locked) {
        return Steps.SELECT_RECTANGLE;
      } else if (
        rectangleItem.size.width !== 5 &&
        userPreferences.unitScale === UnitScale.METRIC
      ) {
        return Steps.SET_WIDTH;
      } else if (rectangleStyle.fillColor === "#f5f5f5") {
        return Steps.SET_BACKGROUND_COLOR;
      } else if (rectangleStyle.fillPattern === undefined) {
        return Steps.SET_PATTERN;
      } else if (rectangleStyle.opacity === 1) {
        return Steps.SET_OPACITY;
      } else if (rectangleStyle.borderType === 1) {
        return Steps.SET_BORDER;
      } else if (rectangleItem.rotation === 0) {
        return Steps.ROTATE;
      } else if (!rectangleItem.locked && !lockCompleted) {
        return Steps.LOCK;
      } else if (
        rectangleItem.locked &&
        selectedShapeType === ShapeType.RECTANGLE &&
        shapeItems.length === 1
      ) {
        return Steps.DESELECT_RECTANGLE;
      }
    }
    // Ellipse
    if (ellipseItem === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB_ELLIPSE;
      } else if (selectedShapeTool !== ShapeType.ELLIPSE) {
        return Steps.CLICK_ELLIPSE;
      } else {
        return Steps.DRAW_ELLIPSE;
      }
    }

    // Lines
    if (lineItem === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB_LINES;
      } else if (selectedShapeTool !== ShapeType.LINE) {
        return Steps.CLICK_LINES;
      } else {
        return Steps.DRAW_LINES;
      }
    }

    // Curved Lines
    if (curvedLineItem === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB_CURVED_LINES;
      } else if (selectedShapeTool !== ShapeType.CURVED_LINE) {
        return Steps.CLICK_CURVED_LINES;
      } else {
        return Steps.DRAW_CURVED_LINES;
      }
    }

    // Text
    if (textItemProperties === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB_TEXT;
      } else if (selectedShapeTool !== ShapeType.TEXT) {
        return Steps.CLICK_TEXT;
      } else {
        return Steps.DRAW_TEXT;
      }
    } else if (displayedPage === Pages.TEXT_SHAPE_TEXT) {
      return Steps.ENTER_TEXT;
    } else if (textItemProperties.text === "") {
      return Steps.EDIT_TEXT;
    }

    // Dimension
    if (dimensionItem === undefined) {
      if (actualActiveTab !== "draw-tab") {
        return Steps.OPEN_DRAW_TAB_DIMENSION;
      } else if (selectedShapeTool !== ShapeType.DIMENSION) {
        return Steps.CLICK_DIMENSION;
      } else {
        return Steps.DRAW_DIMENSION;
      }
    }

    if (rectangleItem.locked && shapeItems.length === 6) {
      return Steps.UNLOCK;
    }

    return Steps.DONE;
  }, [
    rectangleStyle,
    actualActiveTab,
    selectedShapeTool,
    selectedShapeType,
    rectangleItem,
    ellipseItem,
    lineItem,
    curvedLineItem,
    textItemProperties,
    displayedPage,
    dimensionItem,
  ]);

  useEffect(() => {
    if (rectangleItem?.locked) {
      setLockCompleted(true);
    }
  }, [rectangleItem]);

  const completeTour = useCallback(async () => {
    dispatch(UserActions.track(FeatureKey.FINISH_TOUR_SHAPES));
    await dispatch(UserThunks.postUsedFeatures());
    props.onFinish();
  }, []);

  useEffect(() => {
    if (tourStep === Steps.DONE) {
      completeTour();
    }
  }, [tourStep]);

  const finishTour = () => {
    dispatch(LayoutActions.setCurrentTour(Tour.NONE));
  };

  return (
    <div className="">
      <p className="text-bold">{t("introToShapes")}</p>
      <ProgressBar value={tourStep} max={Steps.DONE} className="my-sm" />
      {/* New plan */}
      {tourStep === Steps.NEW_PLAN && <p>{t("shapesTourNewPlan")}</p>}
      {/* Draw rectangle */}
      {tourStep === Steps.OPEN_DRAW_TAB && <p>{t("shapesTourOpenDrawTab")}</p>}
      {tourStep === Steps.CLICK_RECTANGLE && (
        <p>{t("shapesTourClickRectangle")}</p>
      )}
      {tourStep === Steps.DRAW_RECTANGLE && (
        <p>{t("shapesTourDrawRectangle")}</p>
      )}
      {tourStep === Steps.SELECT_RECTANGLE && (
        <p>
          {t(
            touchDevice
              ? "shapesTourSelectRectangleTouch"
              : "shapesTourSelectRectangle"
          )}
        </p>
      )}
      {/* Style rectangle */}
      {tourStep === Steps.SET_WIDTH && <p>{t("shapesTourSetWidth")}</p>}
      {tourStep === Steps.SET_BACKGROUND_COLOR && (
        <p>{t("shapesTourSetBackgroundColor")}</p>
      )}
      {tourStep === Steps.SET_PATTERN && <p>{t("shapesTourSetPattern")}</p>}
      {tourStep === Steps.SET_OPACITY && <p>{t("shapesTourSetOpacity")}</p>}
      {tourStep === Steps.SET_BORDER && <p>{t("shapesTourSetBorder")}</p>}
      {tourStep === Steps.ROTATE && <p>{t("shapesTourRotate")}</p>}
      {tourStep === Steps.LOCK && <p>{t("shapesTourLock")}</p>}
      {tourStep === Steps.DESELECT_RECTANGLE && (
        <p>{t("shapesTourDeselectRectangle")}</p>
      )}
      {tourStep === Steps.UNLOCK && (
        <p>{t(touchDevice ? "shapesTourUnlockTouch" : "shapesTourUnlock")}</p>
      )}

      {/* Draw ellipse */}
      {tourStep === Steps.OPEN_DRAW_TAB_ELLIPSE && (
        <p>{t("shapesTourOpenDrawTabEllipse")}</p>
      )}
      {tourStep === Steps.CLICK_ELLIPSE && (
        <>
          <p className="mb">{t("shapesTourRectangleIsLocked")}</p>
          <p>{t("shapesTourClickEllipse")}</p>
        </>
      )}
      {tourStep === Steps.DRAW_ELLIPSE && <p>{t("shapesTourDrawEllipse")}</p>}

      {/* Draw lines */}

      {tourStep === Steps.OPEN_DRAW_TAB_LINES && (
        <p>{t("shapesTourOpenDrawTabLines")}</p>
      )}
      {tourStep === Steps.CLICK_LINES && <p>{t("shapesTourClickLines")}</p>}
      {tourStep === Steps.DRAW_LINES && <p>{t("shapesTourDrawLines")}</p>}

      {/* Draw curved lines */}
      {tourStep === Steps.OPEN_DRAW_TAB_CURVED_LINES && (
        <p>{t("shapesTourOpenDrawTabCurvedLines")}</p>
      )}
      {tourStep === Steps.CLICK_CURVED_LINES && (
        <>
          <p>{t("shapesTourClickCurvedLines")}</p>
        </>
      )}
      {tourStep === Steps.DRAW_CURVED_LINES && (
        <>
          <p className="mb">{t("shapesTourDrawCurvedLines")}</p>
          <p>{t("shapesTourDrawCurvedLinesEdit")}</p>
        </>
      )}

      {/* Draw text */}
      {tourStep === Steps.OPEN_DRAW_TAB_TEXT && (
        <p>{t("shapesTourOpenDrawTabText")}</p>
      )}
      {tourStep === Steps.CLICK_TEXT && <p>{t("shapesTourClickText")}</p>}
      {tourStep === Steps.DRAW_TEXT && <p>{t("shapesTourDrawText")}</p>}
      {tourStep === Steps.EDIT_TEXT && <p>{t("shapesTourEditText")}</p>}
      {tourStep === Steps.ENTER_TEXT && <p>{t("shapesTourEnterText")}</p>}

      {/* Draw dimension */}
      {tourStep === Steps.OPEN_DRAW_TAB_DIMENSION && (
        <p>{t("shapesTourOpenDrawTabDimension")}</p>
      )}
      {tourStep === Steps.CLICK_DIMENSION && (
        <p>{t("shapesTourClickDimension")}</p>
      )}
      {tourStep === Steps.DRAW_DIMENSION && (
        <p>{t("shapesTourDrawDimension")}</p>
      )}

      {tourStep === Steps.DONE && (
        <>
          <p className="mb">{t("shapesTourDone")}</p>
        </>
      )}
      <Button
        color="danger"
        outline
        size="sm"
        onClick={finishTour}
        className="mt"
      >
        {t("finishTour")}
      </Button>
    </div>
  );
};
