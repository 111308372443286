import { PlantCategory, PlantSchema } from "draw";
import { ColorUtils, Point, Size, Tools } from "imagine-essentials";
import { useMemo } from "react";
import {
  BranchProfileTemplates,
  FlowerProfileTemplates,
  FruitProfileTemplates,
  LeafProfileTemplates,
} from "../templates";
import { FlowerProfilePosition } from "../enums";

interface Props {
  plant: PlantSchema;
  size: Size;
  position: Point;
  className?: string;
  month?: number;
}

/**
 * Renders an SVG of a plant profile.
 * @param props
 */
export const PlantProfileSVG = (props: Props) => {
  // All templates are designed for a 100px width plant. Scale the plant to the correct size
  const factor = 100 / props.plant.width;

  const leafTemplate = useMemo(() => {
    const templateId = props.plant.leafProfileTemplate || 0;
    return LeafProfileTemplates.find((d) => d.id === templateId);
  }, [
    props.plant.leafColorTransitionalSeason,
    props.plant.leafProfileTemplate,
  ]);

  const branchTemplate = useMemo(() => {
    const templateId = props.plant.branchProfileTemplate || 0;
    return BranchProfileTemplates.find((d) => d.id === templateId);
  }, [props.plant.branchProfileTemplate]);

  const flowerTemplate = useMemo(() => {
    const templateId = props.plant.flowerProfileTemplate || 0;
    return FlowerProfileTemplates.find((d) => d.id === templateId);
  }, [props.plant.flowerProfileTemplate]);

  const fruitTemplate = useMemo(() => {
    const templateId = props.plant.fruitProfileTemplate || 0;
    return FruitProfileTemplates.find((d) => d.id === templateId);
  }, [props.plant.fruitProfileTemplate]);

  /**
   * The height of the leaf template and flower template combined.
   */
  const totalTemplateHeight = useMemo(() => {
    if (!leafTemplate || !flowerTemplate) return 0;
    if (flowerTemplate.flowerPosition === FlowerProfilePosition.ABOVE) {
      return (
        leafTemplate.size.height +
        flowerTemplate.size.height -
        (leafTemplate.overlap || 0) * leafTemplate.size.height
      );
    }
    return leafTemplate.size.height;
  }, [leafTemplate, flowerTemplate]);

  const leafHeight = useMemo(() => {
    if (!leafTemplate) return 0;
    if (flowerTemplate?.flowerPosition === FlowerProfilePosition.INSIDE) {
      return ((props.plant.heightMin || 0) + (props.plant.heightMax || 0)) / 2;
    } else if (flowerTemplate?.flowerPosition === FlowerProfilePosition.ABOVE) {
      const avgHeight =
        ((props.plant.heightMax || 0) + (props.plant.heightMin || 0)) / 2;
      const leafPercentage = leafTemplate?.size.height / totalTemplateHeight;

      return avgHeight * leafPercentage;
    }
    return props.plant.heightLeaf || 0;
  }, [
    props.plant.heightLeaf,
    props.plant.heightMax,
    props.plant.heightMin,
    totalTemplateHeight,
  ]);

  const leafsVisible = useMemo(() => {
    if (!leafTemplate) return false;
    if (props.month === 0 || props.month === undefined) return true;
    return (
      props.plant.leafSeason.includes(props.month) ||
      props.plant.leafColorTransitionalSeason?.includes(props.month)
    );
  }, [
    leafTemplate,
    props.month,
    props.plant.leafSeason,
    props.plant.leafColorTransitionalSeason,
  ]);

  const flowersVisible = useMemo(() => {
    if (!flowerTemplate) return false;
    if (props.month === 0 || props.month === undefined) return true;
    return props.plant.flowerSeason.includes(props.month);
  }, [flowerTemplate, props.month, props.plant.flowerSeason]);

  // The size of the plant SVG's viewbox
  const size = useMemo(() => {
    if (!leafTemplate) return;

    const avgHeight =
      ((props.plant.heightMax || 0) + (props.plant.heightMin || 0)) / 2;
    return {
      width: 100,
      height: avgHeight * factor,
    };
  }, [
    props.plant.heightMax,
    props.plant.heightMin,
    leafTemplate,
    flowerTemplate,
    factor,
  ]);

  const fruitsVisible = useMemo(() => {
    if (
      !leafTemplate ||
      props.plant.fruitSeason === undefined ||
      props.month === undefined
    )
      return false;
    return props.plant.fruitSeason.includes(props.month);
  }, [leafTemplate, props.plant.fruitSeason, props.month]);

  const viewboxSize = useMemo(() => {
    if (!size) return { width: 0, height: 0 };
    // Get the larges size to fit in a square while keeping the aspect ratio
    if (size.width > size.height) {
      return {
        width: size.width,
        height: size.width,
      };
    }
    return {
      width: size.height,
      height: size.height,
    };
  }, [size]);

  // The leaf template is multiplied by this factor to get the correct height (specified in the plant schema)
  const leafHeightFactor = useMemo(() => {
    if (!leafTemplate || !viewboxSize || !size) return 1;

    const averagePlantHeight =
      ((props.plant.heightMin || 0) + (props.plant.heightMax || 0)) / 2;
    const plantLeafHeight = leafHeight || averagePlantHeight;

    const leafHeightPercentage = plantLeafHeight / averagePlantHeight;
    const leafSvgHeight = size.height * leafHeightPercentage;
    const templateHeightFactor = leafSvgHeight / leafTemplate.size.height;

    return templateHeightFactor;
    // return 1;
  }, [
    leafTemplate,
    flowerTemplate,
    totalTemplateHeight,
    props.plant.heightMax,
    props.plant.heightMin,
    leafHeight,
    size,
  ]);

  const branchHeightFactor = useMemo(() => {
    if (!branchTemplate || !size) return 1;
    const branchSvgHeight = size.height;
    const templateHeightFactor = branchSvgHeight / branchTemplate.size.height;

    return templateHeightFactor;
  }, [branchTemplate, size]);

  const flowerHeightFactor = useMemo(() => {
    if (!leafTemplate || !flowerTemplate || !size) return 1;
    const averagePlantHeight =
      ((props.plant.heightMin || 0) + (props.plant.heightMax || 0)) / 2;
    const plantLeafHeight = leafHeight || averagePlantHeight;

    let flowerHeightFactor = 1;

    if (flowerTemplate.flowerPosition === FlowerProfilePosition.ABOVE) {
      // Adding 10% of total height to the flower height to make some overlap between the leaves and the flowers
      let overlap = 0;
      if (leafTemplate.overlap) {
        overlap = leafTemplate.overlap * plantLeafHeight;
      }
      const plantFlowerHeight = averagePlantHeight - plantLeafHeight + overlap;
      flowerHeightFactor = plantFlowerHeight / averagePlantHeight;
    } else if (flowerTemplate.flowerPosition === FlowerProfilePosition.INSIDE) {
      flowerHeightFactor = 1;
    } else if (flowerTemplate.flowerPosition === FlowerProfilePosition.EXTEND) {
      flowerHeightFactor = 1;
    }

    const flowerSvgHeight = size.height * flowerHeightFactor;
    const templateHeightFactor = flowerSvgHeight / flowerTemplate.size.height;

    return templateHeightFactor;
  }, [
    leafTemplate,
    flowerTemplate,
    totalTemplateHeight,
    props.plant.heightMax,
    props.plant.heightMin,
    leafHeight,
  ]);

  const fruitHeightFactor = useMemo(() => {
    if (!fruitTemplate || !size) return 1;
    const fruitSvgHeight = size.height;
    const templateHeightFactor = fruitSvgHeight / fruitTemplate.size.height;

    return templateHeightFactor;
  }, [fruitTemplate, size]);

  const flowerHeightOffset = useMemo(() => {
    if (!leafTemplate || !flowerTemplate || !size) return 0;
    if (flowerTemplate.flowerPosition === FlowerProfilePosition.ABOVE) {
      const plantHeight =
        leafTemplate.size.height * leafHeightFactor +
        flowerTemplate.size.height * flowerHeightFactor;
      const overlap = leafTemplate.overlap
        ? leafTemplate.overlap * leafTemplate.size.height * leafHeightFactor
        : 0;
      return viewboxSize.height - plantHeight + overlap;
      // return 0;
    } else if (flowerTemplate.flowerPosition === FlowerProfilePosition.INSIDE) {
      // return 0;
      return viewboxSize.height - leafTemplate.size.height * leafHeightFactor;
    } else if (flowerTemplate.flowerPosition === FlowerProfilePosition.EXTEND) {
      return (
        viewboxSize.height - flowerTemplate.size.height * flowerHeightFactor
      );
    }
  }, [leafTemplate, flowerTemplate, leafHeightFactor]);

  const leafHeightOffset = useMemo(() => {
    if (!leafTemplate) return 0;
    return viewboxSize.height - leafTemplate.size.height * leafHeightFactor;
  }, [leafTemplate, leafHeightFactor]);

  const fruitHeightOffset = useMemo(() => {
    if (!fruitTemplate) return 0;
    return viewboxSize.height - fruitTemplate.size.height * fruitHeightFactor;
  }, [fruitTemplate, fruitHeightFactor]);

  const halfSizeTranslate = useMemo(() => {
    const fullSizeCategories = [
      PlantCategory.TREE,
      PlantCategory.SHRUB,
      PlantCategory.ROSES,
      PlantCategory.CLIMBER,
    ];
    if (
      fullSizeCategories.includes(props.plant.category) ||
      props.plant.leafSeason.length >= 12
    ) {
      return "translate(0, 0)";
    }
    if (props.month === 0 || props.month === undefined)
      return "translate(0, 0)";
    const allVisibleMonths = [...props.plant.leafSeason];
    if (props.plant.leafColorTransitionalSeason) {
      props.plant.leafColorTransitionalSeason.forEach((month) => {
        if (!allVisibleMonths.includes(month)) {
          allVisibleMonths.push(month);
        }
      });
    }
    if (allVisibleMonths.length >= 12) {
      return "translate(0, 0)";
    }

    if (props.plant.leafSeason.includes(props.month)) {
      if (props.month === Tools.getMin(props.plant.leafSeason)) {
        return `translate(${0.125 * viewboxSize.width}, ${
          0.5 * viewboxSize.height
        }) scale(0.5, 0.5)`;
      }
    }
    return "translate(0, 0)";
  }, [props.month, props.plant.leafSeason, viewboxSize]);

  const leafColor = useMemo(() => {
    if (!leafTemplate) return props.plant.leafColor;
    if (props.month === 0 || props.month === undefined)
      return props.plant.leafColor;
    if (props.plant.leafColorTransitionalSeason?.includes(props.month)) {
      return props.plant.leafColorTransitional || props.plant.leafColor;
    }
    return props.plant.leafColor;
  }, [
    leafTemplate,
    props.month,
    props.plant.leafColor,
    props.plant.leafColorTransitionalSeason,
  ]);

  return (
    <>
      {props.size.width > 0 && props.size.height > 0 && leafTemplate && (
        <svg
          x={props.position.x}
          y={props.position.y}
          viewBox={`${(leafTemplate?.size.width - viewboxSize.width) / 2} 0 ${
            viewboxSize.width
          } ${viewboxSize.height}`}
          width={props.size.width}
          height={props.size.height}
          // preserveAspectRatio="none"
          className={props.className}
        >
          <g transform={halfSizeTranslate}>
            {branchTemplate && (
              <g
                transform={`translate(0, ${leafHeightOffset}) scale(1, ${branchHeightFactor})`}
              >
                {branchTemplate.paths.map((path, index) => (
                  <g key={"branch-" + index}>
                    <path
                      key={index + "-fill"}
                      d={path}
                      fill={props.plant.branchColor}
                    />
                    <path
                      key={index + "-border"}
                      d={path}
                      fill="none"
                      stroke={ColorUtils.darkenColor(props.plant.branchColor)}
                      strokeWidth={1}
                      vectorEffect="non-scaling-stroke"
                    />
                  </g>
                ))}
              </g>
            )}
            {flowerTemplate && flowersVisible && (
              <g
                transform={`translate(0, ${flowerHeightOffset}) scale(1, ${flowerHeightFactor})`}
              >
                {flowerTemplate.stemPaths?.map((path, index) => (
                  <g key={"flower-stem-" + index}>
                    <path key={index + "-fill"} d={path} fill={leafColor} />
                    <path
                      key={index + "-border"}
                      d={path}
                      fill="none"
                      stroke={ColorUtils.darkenColor(leafColor)}
                      strokeWidth={1}
                    />
                  </g>
                ))}
              </g>
            )}
            {leafTemplate && leafsVisible && (
              <g
                transform={`translate(0, ${leafHeightOffset}) scale(1, ${leafHeightFactor})`}
              >
                {leafTemplate.paths.map((path, index) => (
                  <g key={"leaf-" + index}>
                    <path key={index + "-fill"} d={path} fill={leafColor} />
                    <path
                      key={index + "-border"}
                      d={path}
                      fill="none"
                      stroke={ColorUtils.darkenColor(leafColor)}
                      strokeWidth={1}
                      vectorEffect="non-scaling-stroke"
                    />
                  </g>
                ))}
                {props.plant.leafColorVariegation &&
                  leafTemplate.variegationPaths?.map((path, index) => (
                    <g key={"leaf-variegation-" + index}>
                      <path
                        key={index + "-fill"}
                        d={path}
                        fill={props.plant.leafColorVariegation}
                      />
                    </g>
                  ))}
              </g>
            )}
            {flowerTemplate && flowersVisible && (
              <g
                transform={`translate(0, ${flowerHeightOffset}) scale(1, ${flowerHeightFactor})`}
              >
                {flowerTemplate.paths.map((path, index) => (
                  <g key={"flower-" + index}>
                    <path
                      key={index + "-fill"}
                      d={path}
                      fill={props.plant.flowerColor}
                    />

                    {/* <path
                      key={index + "-border"}
                      d={path}
                      fill="none"
                      stroke={ColorUtils.darkenColor(props.plant.flowerColor)}
                      strokeWidth={1}
                      vectorEffect="non-scaling-stroke"
                    /> */}
                  </g>
                ))}
                {props.plant.flowerColorVariegation &&
                  flowerTemplate.variegationPaths?.map((path, index) => (
                    <g key={"flower-variegation-" + index}>
                      <path
                        key={index + "-fill"}
                        d={path}
                        fill={props.plant.flowerColorVariegation}
                      />
                    </g>
                  ))}
              </g>
            )}

            {fruitTemplate && fruitsVisible && (
              <g
                transform={`translate(0, ${fruitHeightOffset}) scale(1, ${fruitHeightFactor})`}
              >
                {fruitTemplate.paths.map((path, index) => (
                  <g key={"fruit-" + index}>
                    <path
                      key={index + "-fill"}
                      d={path}
                      fill={props.plant.fruitColor}
                    />
                  </g>
                ))}
              </g>
            )}
          </g>
        </svg>
      )}
    </>
  );
};
