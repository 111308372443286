import { Loader } from "@googlemaps/js-api-loader";
import { MapPosition } from "draw";
import { Point } from "imagine-essentials";

/**
 * Convert angle to radiants.
 */
const rad = (angle: number) => {
  return (angle * Math.PI) / 180;
};

/**
 * The earth is not perfectly round. This uses the latitude to calculate the radius
 * that fits the best (in meters)
 * @param latitude
 */
const getEarthRadius = (latitude: number) => {
  const equatorialRadius = 6378137;
  const polarRadius = 6356752;
  const latitudeFactor = latitude / 90;
  const radiusDiff = equatorialRadius - polarRadius;
  const radiusAddition = radiusDiff * latitudeFactor;
  return equatorialRadius - radiusAddition;
};

/**
 * Get the distance in meters between to positions on the satellite map.
 */
const getDistance = (p1: MapPosition, p2: MapPosition) => {
  // Equatorial radius: 6378137 m
  // Polar radius: 6356752
  const R = getEarthRadius(p1.lat); //6378137; // Earth’s mean radius in meter
  const dLat = rad(p2.lat - p1.lat);
  const dLong = rad(p2.lng - p1.lng);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(p1.lat)) *
      Math.cos(rad(p2.lat)) *
      Math.sin(dLong / 2) *
      Math.sin(dLong / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c;
  return d; // returns the distance in meter
};

/**
 * Get the rectangle made by 2 positions on the map. Width and height is given in meters.
 */
const calculateRectDistance = (p1: MapPosition, p2: MapPosition) => {
  // Longitude
  const horizontal = getDistance(p1, { lng: p2.lng, lat: p1.lat });
  // Latitude
  const vertical = getDistance(p2, { lat: p1.lat, lng: p2.lng });
  return { width: horizontal, height: vertical };
};

/**
 * Get a new latitude and longitude position, based on an original positions and an x,y offset in meter
 * @param latitude Original latutude and longitude
 * @param offset X and y offset in meters
 */
const getMapPositionOffset = (mapPosition: MapPosition, offset: Point) => {
  const r = getEarthRadius(mapPosition.lat);
  const metersPerLongitude =
    (Math.PI / 180) * r * Math.cos(rad(mapPosition.lat));
  const metersPerLatitude = (Math.PI / 180) * r;
  return {
    // Latitude scale is opposite computer y scale (increases as it goes up/north)
    lat: mapPosition.lat + offset.y / metersPerLatitude,
    lng: mapPosition.lng - offset.x / metersPerLongitude,
  };
};

/**
 * Get pixels per meter for a Google maps zoom level. Equation found here:
 * https://groups.google.com/g/google-maps-js-api-v3/c/hDRO4oHVSeM
 * @param mapZoom Google maps zoom level
 * @param latitude
 */
const getMapPPM = (mapZoom: number, latitude: number) => {
  return (
    1 /
    ((156543.03392 * Math.cos((latitude * Math.PI) / 180)) /
      Math.pow(2, mapZoom))
  );
};

const getMaxZoom = async (
  position: MapPosition
): Promise<number | undefined> => {
  const loader = new Loader({
    // apiKey: "AIzaSyA3LsOVkitoEswjuBNDKj8Us-7TwZP4f9I",
    apiKey: "AIzaSyB6437lw96uwoQc8KbiZzWepMt8p95GJ8c",
    version: "weekly",
  });

  await loader.load();

  // let maxZoomService: google.maps.MaxZoomService;
  const maxZoomService = new google.maps.MaxZoomService();

  return new Promise((resolve, reject) => {
    maxZoomService.getMaxZoomAtLatLng(
      position,
      (result: google.maps.MaxZoomResult) => {
        if (result.status === "OK") {
          const maxZoom = result.zoom;
          resolve(maxZoom);
        } else {
          reject(undefined);
        }
      }
    );
  });
};

export const MapOperations = {
  rad,
  getEarthRadius: getEarthRadius,
  getDistance,
  calculateRectDistance,
  getMapPositionOffset,
  getMapPPM,
  getMaxZoom,
};
