import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  CanvasOperations,
  MapPosition,
  PlanSettings,
  ShapeType,
  Zoom,
} from "draw";
import { Point, Rectangle, Size } from "imagine-essentials";
import { CursorMode, MapMode, MarkerMode } from "../../enums";
import { MapOperations } from "../../utils";
import { CanvasSliceHelpers } from "./CanvasSliceHelpers";
import { Device } from "imagine-ui";
import { SceneView } from "scenery";

export const MapConstant = {
  defaultMapZoomLevel: 18,
  mapMinZoomLevel: 16,
  mapMaxZoomLevelTemp: 20, // 13/7-2023 Changed from 21 to 20 because some adresses do not zoom at level 21
};

interface MapState {
  address: string;
  center: MapPosition;
  zoomIndex: number;
  maxZoom: number;
  zoomPPM: number | undefined;
  zeroReference: Point; // The position on the board where 0,0 is located
  zeroReferenceCoordinates: MapPosition | undefined; // The lat/lng at the zero reference
  mode: MapMode;
  frozen: boolean; // When frozen, the map does not move with the rest of the plan data
}

const initialMapState: MapState = {
  address: "",
  center: { lat: 0, lng: 0 },
  zoomIndex: 18,
  maxZoom: 20,
  zoomPPM: undefined,
  zeroReference: { x: 0, y: 0 },
  zeroReferenceCoordinates: undefined,
  mode: MapMode.NONE,
  frozen: false,
};

interface CanvasState {
  offset: Point; // The offset of the board compared to the browser windowplan
  size: Size; // The size of the board
  blockedLeft: number; // Blocked area to the left due to open sidebar
  blockedRight: number;
  blockedBottom: number;
  zoom: number; // The zoom value (px pr. meter)
  zeroReference: Point; // The position on the board where the point 0,0 is located. This is the reference for all items
  isDragging: boolean;
  ready: boolean; // True when size and offset is set
  message: string; // Message that is shown to the user
  loadingMessage: string; // Message shown in middle of screen together with spinner
  enteringText: boolean; // Whether text is currently being entered in a text shape
  updatingDimension: boolean; // Whether the value in the dimension is being updated in a dimension component (backspace should behave differently)
  markedRectangle: Rectangle | null; // The currently marked rectangle
  markerMode: MarkerMode;
  map: MapState;
  cursorOverride: string | null; // If set, the default cursor are overriden with this one
  selectedShapeTool: ShapeType; // Currently selected shape tool
  cursorMode: CursorMode;
  contextMenuPosition: Point | null;
  sceneView: SceneView | undefined;
  showReferenceImage: boolean;
}

const initialState: CanvasState = {
  offset: { x: 0, y: 0 }, // Position in the window where the board's (0,0) is located
  size: { width: 0, height: 0 },
  blockedLeft: 0,
  blockedRight: 0,
  blockedBottom: 0,
  zoom: 70,
  zeroReference: { x: 0, y: 0 },
  isDragging: false,
  ready: false,
  message: "",
  loadingMessage: "",
  enteringText: false,
  updatingDimension: false,
  markerMode: MarkerMode.SELECT,
  markedRectangle: null,
  map: initialMapState,
  cursorOverride: null,
  selectedShapeTool: ShapeType.NONE,
  cursorMode: Device.isTouchDevice() ? CursorMode.DRAG : CursorMode.POINTER,
  contextMenuPosition: null,
  sceneView: undefined,
  showReferenceImage: false,
};

export const canvasSlice = createSlice({
  name: "canvas",
  initialState,
  reducers: {
    setOffset: (state, action: PayloadAction<Point>) => {
      state.offset = action.payload;
    },
    setSize: (state, action: PayloadAction<Size>) => {
      state.size = action.payload;
    },
    setBlockedLeft: (state, action: PayloadAction<number>) => {
      state.blockedLeft = action.payload;
    },
    setBlockedRight: (state, action: PayloadAction<number>) => {
      state.blockedRight = action.payload;
    },
    setBlockedBottom: (state, action: PayloadAction<number>) => {
      state.blockedBottom = action.payload;
    },
    setZoom: (state, action: PayloadAction<number>) => {
      state.zoom = action.payload;
    },
    openPlan: (state, action: PayloadAction<PlanSettings>) => {
      state.zoom = action.payload.zoom;
      state.zeroReference = action.payload.zeroReference;

      state.map.address = action.payload.mapAddress ?? "";
      state.map.center = action.payload.mapCenter ?? { lat: 0, lng: 0 };
      state.map.zoomIndex =
        action.payload.mapZoom ?? MapConstant.defaultMapZoomLevel;
      state.map.zeroReference = action.payload.mapZeroReference ?? {
        x: 0,
        y: 0,
      };
      state.map.zeroReferenceCoordinates =
        action.payload.mapZeroReferencePosition;

      state.map.mode = MapMode.NONE;
      state.map.frozen = false;
    },
    updateZoom: (
      state,
      action: PayloadAction<{ offset: number; position: Point; ppm?: number }>
    ) => {
      const oldZoom = state.zoom;
      let newZoom =
        action.payload.ppm ??
        Zoom.getZoomOffset(state.zoom, action.payload.offset * -1); //zoom + offset;
      let validZoom = true;
      if (state.map.mode === MapMode.VISIBLE) {
        const newZoomIndex = state.map.zoomIndex + action.payload.offset;
        // @TODO: Google Maps return wrong max zoom value, so for now we use this manual restriction that works for most locations.
        if (newZoomIndex > MapConstant.mapMaxZoomLevelTemp) return;
        // if (newZoomIndex > mapMaxZoom) return;
        if (newZoomIndex < MapConstant.mapMinZoomLevel) return;
        state.map.zoomIndex = state.map.zoomIndex + action.payload.offset;

        // Set the zoom value to match the map zoom
        const ppm = MapOperations.getMapPPM(
          state.map.zoomIndex,
          state.map.center.lat
        );
        newZoom = ppm;
        // dispatch(MapActions.setZoomIndex(mapZoomIndex + offset));
      } else {
        validZoom = Zoom.checkZoom(newZoom);
      }

      if (!validZoom) return;

      const newZeroReference = CanvasOperations.calculateZoomedZeroReference(
        oldZoom,
        newZoom,
        action.payload.position,
        state.zeroReference,
        state.offset
      );
      state.zoom = newZoom;
      state.zeroReference = newZeroReference;

      // Make sure the map always follow the zero reference and zoom
      if (state.map.mode == MapMode.VISIBLE) {
        if (state.map.zeroReferenceCoordinates === undefined) return;
        if (state.map.frozen) return;

        state.map.center = CanvasSliceHelpers.calculateMapCenter(
          newZeroReference,
          newZoom,
          state.size,
          state.map.zeroReferenceCoordinates
        );
      }
    },
    zoomInAuto: (state) => {
      const canvasCenter = CanvasOperations.getCanvasCenter(
        state.size,
        state.offset
      );
      const payload = { offset: 1, position: canvasCenter };
      canvasSlice.caseReducers.updateZoom(state, {
        payload: payload,
        type: "canvas/updateZoom",
      });
    },
    zoomOutAuto: (state) => {
      const canvasCenter = CanvasOperations.getCanvasCenter(
        state.size,
        state.offset
      );
      const payload = { offset: -1, position: canvasCenter };
      canvasSlice.caseReducers.updateZoom(state, {
        payload: payload,
        type: "canvas/updateZoom",
      });
    },
    setZeroReference: (state, action: PayloadAction<Point>) => {
      state.zeroReference = action.payload;

      // Make sure the map always follow the zero reference and zoom
      if (state.map.mode == MapMode.VISIBLE) {
        if (state.map.zeroReferenceCoordinates === undefined) return;
        if (state.map.frozen) return;

        state.map.center = CanvasSliceHelpers.calculateMapCenter(
          action.payload,
          state.zoom,
          state.size,
          state.map.zeroReferenceCoordinates
        );
      }
    },
    setMapMode: (state, action: PayloadAction<MapMode>) => {
      state.map.mode = action.payload;

      // A location must be selected before the map can be displayed
      if (
        state.map.zeroReferenceCoordinates !== undefined &&
        action.payload === MapMode.VISIBLE
      ) {
        // Switch to zoom level and position from last time map was visible
        const lastMapZoom = MapOperations.getMapPPM(
          state.map.zoomIndex,
          state.map.center.lat
        );
        state.zeroReference = state.map.zeroReference;
        state.zoom = lastMapZoom;
      } else if (action.payload === MapMode.NONE) {
        // Switch to Garden Sketcher zoom values
        const closestZoomIndex = Zoom.getClosestZoom(state.zoom);
        const ppm = Zoom.getZoomValue(closestZoomIndex);
        const boardCenter = CanvasOperations.getCanvasCenter(state.size, {
          x: 0,
          y: 0,
        });
        const oldZoom = state.zoom;
        const newZoom = ppm;

        if (Zoom.checkZoom(newZoom)) {
          const newZeroReference =
            CanvasOperations.calculateZoomedZeroReference(
              oldZoom,
              newZoom,
              boardCenter,
              state.zeroReference,
              state.offset
            );

          // Save current zero reference to be able to match map and canvas when map is visible again
          state.map.zeroReference = state.zeroReference;
          state.zoom = newZoom;
          state.zeroReference = newZeroReference;
        } else {
          console.warn("Zoom level not supported");
        }
      }
    },
    setMapAddress: (state, action: PayloadAction<string>) => {
      state.map.address = action.payload;
    },
    setMapCenter: (state, action: PayloadAction<MapPosition>) => {
      state.map.center = action.payload;
    },
    setMapZoomIndex: (state, action: PayloadAction<number>) => {
      state.map.zoomIndex = action.payload;
    },
    setMapFrozen: (state, action: PayloadAction<boolean>) => {
      state.map.frozen = action.payload;
    },
    setMapMaxZoom: (state, action: PayloadAction<number>) => {
      state.map.maxZoom = action.payload;
    },
    setMapZeroReference: (state, action: PayloadAction<Point>) => {
      state.map.zeroReference = action.payload;
    },
    setMapZeroReferenceCoordinates: (
      state,
      action: PayloadAction<MapPosition | undefined>
    ) => {
      state.map.zeroReferenceCoordinates = action.payload;
    },

    setIsDragging: (state, action: PayloadAction<boolean>) => {
      state.isDragging = action.payload;
    },
    setReady: (state) => {
      state.ready = true;
    },

    setMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setLoadingMessage: (state, action: PayloadAction<string>) => {
      state.loadingMessage = action.payload;
    },
    clearMessage: (state) => {
      state.message = "";
    },
    setEnteringText: (state, action: PayloadAction<boolean>) => {
      state.enteringText = action.payload;
    },
    setUpdatingDimension: (state, action: PayloadAction<boolean>) => {
      state.updatingDimension = action.payload;
    },
    setMarkedRectangle: (state, action: PayloadAction<Rectangle | null>) => {
      state.markedRectangle = action.payload;
    },
    setCursorOverride: (state, action: PayloadAction<string | null>) => {
      state.cursorOverride = action.payload;
    },
    setSelectedShapeTool: (state, action: PayloadAction<ShapeType>) => {
      state.selectedShapeTool = action.payload;
    },
    /**
     * Link map and canvas. If map is already linked, this will not do anything, unless force is set.
     * @param state
     * @param action Object containing lat/long center on the map, zoom value in pixels/meter, and whether to force link
     */
    linkMap: (
      state,
      action: PayloadAction<{
        center: MapPosition;
        ppm: number;
        force?: boolean;
      }>
    ) => {
      if (
        state.map.zeroReferenceCoordinates !== undefined &&
        !action.payload.force
      ) {
        return;
      }
      if (
        action.payload.ppm === undefined ||
        action.payload.center === undefined
      ) {
        console.error("Map information missing");
        return;
      }
      const boardCenterPx = CanvasOperations.getCanvasCenter(state.size, {
        x: 0,
        y: 0,
      });
      const distFromZeroRefPx = CanvasOperations.getPointSubtracted(
        boardCenterPx,
        state.zeroReference
      );

      const offsetMeters = {
        x: distFromZeroRefPx.x / action.payload.ppm,
        y: distFromZeroRefPx.y / action.payload.ppm,
      };
      const zeroRefMapPosition = MapOperations.getMapPositionOffset(
        action.payload.center,
        offsetMeters
      );
      state.map.zeroReferenceCoordinates = zeroRefMapPosition;
      state.map.mode = MapMode.VISIBLE;
    },
    setCursorMode: (state, action: PayloadAction<CursorMode>) => {
      state.cursorMode = action.payload;
    },
    openContextMenu: (state, action: PayloadAction<Point>) => {
      state.contextMenuPosition = action.payload;
    },
    closeContextMenu: (state) => {
      state.contextMenuPosition = null;
    },
    setMarkerMode: (state, action: PayloadAction<MarkerMode>) => {
      state.markerMode = action.payload;
    },
    centerArea: (state, action: PayloadAction<Rectangle>) => {
      const area = action.payload;
      const canvasCenter = {
        x: state.size.width / 2,
        y: state.size.height / 2,
      };

      // Zero reference is the position on the board where the 0,0 reference point is located

      // Relative to 0,0 (in meters)
      const areaCenter = {
        x: area.position.x + area.size.width / 2,
        y: area.position.y + area.size.height / 2,
      };

      // Find the zoom level that will display the entire area
      const zoomWidth = state.size.width / area.size.width;
      const zoomHeight = state.size.height / area.size.height;
      const z = Math.min(zoomWidth, zoomHeight);
      const newZoomIndex = Zoom.getZoomIndex(z) + 5;
      const newZoomValue = Zoom.getZoomValue(newZoomIndex);

      // Get area center in px relative to the board (using new zoom value)
      const areaCenterPx = CanvasOperations.unitToPixelPosition(
        areaCenter,
        newZoomValue,
        state.zeroReference
      );
      // Calculate the difference from the area center and the board center.
      const diff = CanvasOperations.getPointSubtracted(
        areaCenterPx,
        canvasCenter
      );
      // Move the zero reference to place the area center in the board center
      const newZeroReference = CanvasOperations.getPointSubtracted(
        state.zeroReference,
        diff
      );
      state.zeroReference = newZeroReference;
      state.zoom = newZoomValue;
    },
    setSceneView: (state, action: PayloadAction<SceneView | undefined>) => {
      state.sceneView = action.payload;
    },
    setShowReferenceImage: (state, action: PayloadAction<boolean>) => {
      state.showReferenceImage = action.payload;
    },
  },
});

export const CanvasActions = canvasSlice.actions;

export default canvasSlice.reducer;
