import { useTranslation } from "react-i18next";
import { PlantCard, PlantCardAction, PlantData } from "..";

import { useCallback, useMemo } from "react";

import {
  Alert,
  Modal,
  NotificationType,
  Spinner,
  useNotification,
} from "imagine-ui";

import { PlantSchema } from "draw";
import { UnitScale } from "project";
import { Delimiter } from "imagine-essentials";

interface Props {
  visible: boolean;
  plants: PlantSchema[];
  onEdit: (plant: PlantSchema) => void;
  onDelete: (plantId: number) => void;
  onInsert: (plant: PlantSchema) => void;
  onShowPlantInfo: (plant: PlantSchema) => void;
  onCopy: (plant: PlantSchema) => void;
  onClose: () => void;
  errorMessage?: string;
  loading?: boolean;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneRHS?: boolean;
}

/**
 * Displays a page with base info of a plant collection and an overview of all plants within the collections.
 * Also makes it possible to edit and delete the plant collection, and remove plants from the collection.
 * @param props
 * @returns
 */
export const UserPlantsPage = (props: Props) => {
  const { t } = useTranslation();

  const notification = useNotification();

  const { onShowPlantInfo, onDelete, onEdit, onCopy, onInsert } = props;

  const deletePlant = useCallback(
    async (plantId: number) => {
      onDelete(plantId);
    },
    [onDelete]
  );

  const requestDeletePlant = useCallback(
    (plantId: number) => {
      notification.add(
        NotificationType.CONFIRM,
        t("plants:confirmDeletePlant")
      );
      notification.onConfirm(() => {
        deletePlant(plantId);
      });
    },
    [deletePlant, notification, t]
  );

  const plantsWithActions = useMemo(() => {
    if (props.plants === undefined) return [];
    return props.plants.map((plant: PlantSchema) => {
      return {
        plant: plant,
        actions: [
          {
            action: () => {
              onShowPlantInfo(plant);
            },
            label: t("plants:plantInfo"),
          },
          {
            action: () => {
              onInsert(plant);
            },

            label: t("imagine:insert"),
          },
          {
            action: () => {
              onEdit(plant);
            },
            newGroup: true,
            label: t("imagine:edit"),
          },
          {
            action: () => {
              onCopy(plant);
            },
            label: t("imagine:copy"),
          },
          // {
          //   action: () => {
          //     requestDeletePlant(plant.id);
          //   },
          //   newGroup: true,
          //   label: t("imagine:delete"),
          //   disabled: plant.public,
          // },
        ],
      };
    });
  }, [onCopy, onEdit, onInsert, onShowPlantInfo, props.plants, t]);

  return (
    <Modal
      title={t("plants:myPlants")}
      visible={props.visible}
      onClose={props.onClose}
      size="xl"
      hideCancel
      confirmText={t("imagine:close")}
      onConfirm={props.onClose}
      elementId="user-plants-modal"
    >
      <div className="">
        {props.errorMessage && !props.loading && (
          <Alert color="danger">{props.errorMessage}</Alert>
        )}
        {props.loading && (
          <div className="row items-center my-xl">
            <Spinner />
          </div>
        )}
        <div className="grid columns-2 columns-1--lg gap" id="user-plants-list">
          {plantsWithActions.map(
            (entry: { plant: PlantSchema; actions: PlantCardAction[] }) => (
              <PlantCard
                plant={entry.plant}
                showProperties
                showSeason
                showHeight
                boxed
                actions={entry.actions}
                key={entry.plant.id.toString()}
                unitScale={props.unitScale}
                delimiter={props.delimiter}
                showHardinessZoneRHS={props.showHardinessZoneRHS}
              />
            )
          )}
        </div>

        {(props.plants === undefined || props.plants?.length === 0) &&
          !props.loading && (
            <Alert color="warning">{t("plants:noPlantsCreated")}</Alert>
          )}
      </div>
    </Modal>
  );
};
