import { Delimiter, Size } from "imagine-essentials";
import { Device, Label, Select, Spinner, TextInput } from "imagine-ui";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  ModelDesign,
  ModelDesignSvg,
  ModelOperations,
  ObjectCategory,
  ObjectOperations,
  ObjectSchema,
  ModelPicker,
  Measure,
} from "draw";
import { UnitScale } from "project";
import i18n from "src/i18n";

interface Props {
  onSearch: (category?: ObjectCategory, name?: string) => void;
  objects: ObjectSchema[];
  onSelectObjectTemplate: (id?: number) => void;
  loading?: boolean;
  selectedObjectTemplateId?: number;
  delimiter: Delimiter;
  unitScale: UnitScale;
}

export const ObjectLibrary = (props: Props) => {
  const { t, i18n } = useTranslation();
  const [name, setName] = useState("");
  const [category, setCategory] = useState<ObjectCategory>(
    ObjectCategory._SIZE
  );
  const [markedTemplate, setMarkedTemplate] = useState<ModelDesign | null>(
    null
  );
  const [displaySize, setDisplaySize] = useState<Size>({ width: 0, height: 0 });
  const isTouchDevice = Device.isTouchDevice();

  const handleNewName = (val: string) => {
    setName(val);
  };

  const handleNewNameAndSearch = (val: string) => {
    setName(val);
    props.onSearch(category, val);
  };

  const search = () => {
    props.onSearch(category, name);
  };

  const designs = useMemo(() => {
    return props.objects.map((template: ObjectSchema) => {
      return {
        id: template.id,
        shapes: ModelOperations.getObjectTemplateShapes(template),
        viewBox: "0 0 " + template.width + " " + template.height,
        name: template.name,
      } as ModelDesign;
    });
  }, [props.objects]);

  const previewSize = useMemo(() => {
    if (markedTemplate === null) return { width: 0, height: 0 };

    const maxWidth = 150;
    const maxHeight = 150;

    const width = displaySize.width;
    const height = displaySize.height;

    const xFactor = maxWidth / width;
    const yFactor = maxHeight / height;
    const factor = Math.min(xFactor, yFactor);

    return {
      width: width * factor,
      height: height * factor,
    };
  }, [displaySize, markedTemplate]);

  const objectCategory = useMemo(() => {
    if (markedTemplate === null) return "";
    const object = ObjectOperations.findObject(
      props.objects,
      markedTemplate.id
    );
    if (object) {
      return ObjectOperations.getCategoryText(object.category);
    }
    return "";
  }, [markedTemplate, props.objects]);

  const categoryOptions = useMemo(() => {
    return ObjectOperations.getCategoryOptions(true);
  }, [i18n.language]);

  const previewObject = (id: number | null) => {
    if (id === null) {
      setMarkedTemplate(null);
      return;
    }
    const objectDesign = designs.find((template: ModelDesign) => {
      return template.id === id;
    });
    if (objectDesign !== undefined) {
      setMarkedTemplate(objectDesign);

      const objectTemplate = ObjectOperations.findObject(props.objects, id);

      setDisplaySize({
        width: objectTemplate.width,
        height: objectTemplate.height,
      });
    }
  };

  const selectObjectTemplate = (id?: number) => {
    props.onSelectObjectTemplate(id);
  };

  const updateCategory = (cat: ObjectCategory) => {
    setCategory(cat);
    props.onSearch(cat, name);
  };

  return (
    <div className="position-relative column h-full scroll-y-always--sm">
      <div className="p">
        <TextInput
          elementId="plant-library-search"
          value={name}
          onFinish={handleNewName}
          onEnterPress={handleNewNameAndSearch}
          onClick={search}
          prependIcon="magnifier"
          size="sm"
        />
        <Select
          elementId="object-library-category"
          className="select-form-control mt"
          options={categoryOptions}
          onSelectedNumber={updateCategory}
          selected={category}
          size="sm"
        />

        {props.loading && (
          <div className="text-center mt">
            <Spinner />
          </div>
        )}
      </div>
      <div className="stretch scroll-y-always overflow-y--sm px">
        {!props.loading && (
          <ModelPicker
            designs={designs}
            selected={props.selectedObjectTemplateId}
            onSelect={selectObjectTemplate}
            onPreview={previewObject}
            // reload={reloadPlantDisplay}
            // disabled={props.disabled}
            displayNames
            idPrefix="plant-library-design"
            // onDragOutStarted={props.onItemGrabbed}
          />
        )}
      </div>

      {markedTemplate !== null && !isTouchDevice && (
        <div className="item-preview-container shadow">
          <div className="item-preview">
            <div className="row">
              <div className="mr h-150">
                <ModelDesignSvg
                  design={markedTemplate}
                  size={previewSize}
                  position={{ x: 0, y: 0 }}
                  viewBox={markedTemplate.viewBox}
                />
              </div>
              <div className="stretch">
                <p className="text-bold mt-none">{markedTemplate.name}</p>
                <p className="text-italic">{objectCategory}</p>
                <p>
                  {t("size")}:{" "}
                  {Measure.getMeasureText(
                    displaySize.width,
                    props.unitScale,
                    props.delimiter,
                    true
                  )}{" "}
                  x{" "}
                  {Measure.getMeasureText(
                    displaySize.height,
                    props.unitScale,
                    props.delimiter,
                    true
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
