import { Cloner, Item, ItemOperations } from "draw";
import React, { useEffect, useMemo, useState } from "react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProvided,
  DroppableStateSnapshot,
  DraggableProvided,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import { SentryReporter, Tools } from "imagine-essentials";
import { ItemThumbnail, StrictModeDroppable } from "..";

type Props = {
  items: Item[];
  title?: string;
  onReorder?: (items: Item[]) => void;
  selected?: Item | null;
  onSelect?: (item: Item) => void;
  idPrefix?: string;
};

/**
 * Displays a list of items. Alternative way of selecting item or changing the drawing order.
 * @param props
 */
export const ItemList = (props: Props) => {
  const { t } = useTranslation();

  // The last item must be displayed on top to make it more intuitive to the user
  const reverseItems = useMemo(
    () => Array.from(props.items).reverse(),
    [props.items]
  );

  const reorder = (sourceIndex: number, destinationIndex: number) => {
    const reorderedItems = reverseItems.map((item) => Cloner.cloneItem(item));

    const [removed] = reorderedItems.splice(sourceIndex, 1);

    if (removed) {
      reorderedItems.splice(destinationIndex, 0, removed);
    }

    // Need to update the index values, since they are sorted by index when updated in store
    if (props.onReorder) {
      props.onReorder(
        reorderedItems.reverse().map((item: Item, index: number) => {
          return {
            ...item,
            index: index,
          };
        })
      );
    }
  };

  const handleDragEnd = (result: any) => {
    if (result.source !== null && result.destination !== null) {
      reorder(result.source.index, result.destination.index);
    }
  };

  const handleClick = (item: Item) => {
    if (props.onSelect) {
      props.onSelect(item);
    }
  };

  const getItemClass = (id: number) => {
    if (props.selected !== null && props.selected !== undefined) {
      if (props.selected.id === id) return "clickable item-list-item selected";
    }
    return "clickable item-list-item";
  };

  return (
    <div id={props.idPrefix + "-list"}>
      <DragDropContext onDragEnd={handleDragEnd}>
        <StrictModeDroppable droppableId="item-list">
          {(provided: DroppableProvided, snapshot: DroppableStateSnapshot) => (
            <div
              className="item-list"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {props.title && <div className="title">{props.title}</div>}
              {reverseItems.map((item: Item, index: number) => (
                <Draggable
                  key={item.id.toString()}
                  draggableId={item.id.toString()}
                  index={index}
                >
                  {(
                    provided: DraggableProvided,
                    snapshot: DraggableStateSnapshot
                  ) => (
                    <div
                      className={getItemClass(item.id)}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      onClick={() => handleClick(item)}
                      id={props.idPrefix + "-item-" + index}
                    >
                      <div className="thumbnail ml-xs mr-sm">
                        <ItemThumbnail
                          item={item}
                          size={{ width: 40, height: 28 }}
                        />
                      </div>
                      <div className="text">
                        {Tools.numberToText(item.size.width, 2, ",")} x{" "}
                        {Tools.numberToText(item.size.height, 2, ",")} m
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
};
