import { UserActions } from "imagine-users";
import { ZoneManager } from "../../components";
import {
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { ItemOperations, Layer } from "draw";
import { FeatureKey } from "project";
import { useTranslation } from "react-i18next";
import { NotificationType, useNotification } from "imagine-ui";

interface Props {
  visible: boolean;
}

export const LayersContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const currentLayerId = useAppSelector(PlanEditorSelector.getCurrentLayerId);
  const isSmallDevice = useAppSelector(LayoutSelector.isSmOrSmaller);
  const items = useAppSelector(PlanEditorSelector.getAllItems);

  const updateLayers = (layers: Layer[]) => {
    dispatch(PlanEditorActions.setLayers(layers));
  };

  const updateLayer = (layer: Layer) => {
    // Check if current layer is made invisible
    if (!layer.visible && currentLayerId === layer.id) {
      notification.add(
        NotificationType.INFO,
        t("cannotHideSelectedLayer", { name: layer.name })
      );
      return;
    }
    dispatch(PlanEditorActions.updateLayer(layer));
  };

  const addLayer = (layer: Layer) => {
    dispatch(PlanEditorActions.addLayer(layer));
    dispatch(UserActions.track(FeatureKey.CREATE_LAYER));
  };

  const deleteLayer = (id: number) => {
    const layerItems = ItemOperations.getItemsInLayer(items, id);
    if (layerItems.length === 0) {
      dispatch(PlanEditorActions.deleteLayer(id));
    } else {
      notification.add(
        NotificationType.CONFIRM,
        t("deletingLayerWillDeleteItems", { count: layerItems.length })
      );
      notification.onConfirm(() => {
        dispatch(PlanEditorActions.deleteMultipleItems(layerItems));
        dispatch(PlanEditorActions.deleteLayer(id));
      });
    }

    dispatch(PlanEditorActions.deleteLayer(id));
  };

  const setCurrentLayer = (id: number) => {
    dispatch(PlanEditorActions.setCurrentLayer(id));
  };

  const selectAllElements = (id: number) => {
    dispatch(PlanEditorActions.selectAllElementsInLayer(id));
  };

  return (
    <>
      {props.visible && (
        <ZoneManager
          zones={layers}
          activeId={currentLayerId}
          onReorderZones={updateLayers}
          onUpdateZone={updateLayer}
          onAddZone={addLayer}
          onDeleteZone={deleteLayer}
          onSelectActiveZone={setCurrentLayer}
          onSelectAllElements={selectAllElements}
          defaultName={t("layer")}
          smallDevice={isSmallDevice}
          minCount={1}
          elementId="layers"
          itemIdPrefix="layer"
          addZoneText={t("newLayer")}
        />
      )}
    </>
  );
};
