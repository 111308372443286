export type Param = {
  key: string;
  value: string;
};
/**
 * Checks the browser's URL with the route. The routes should start with /. Use $ in front of variables.
 * Returns false if no match, and a list of the variables in case of match.
 * @param route
 */
const urlMatches = (route: string) => {
  const url = window.location.pathname;
  // console.log("Comparing", url, route);

  // The BASE_URL is set in the vite config file
  const compare = import.meta.env.BASE_URL + route;

  const urlItems = url.split("/");
  const compareItems = compare.split("/");
  const vars: string[] = [];
  let match = true;
  for (let i = 0; i < compareItems.length; i++) {
    if (urlItems[i] === undefined) {
      match = false;
    } else if (urlItems[i] !== compareItems[i]) {
      // Check if this is a variable if the strings are not matching
      if (compareItems[i].startsWith("$")) {
        vars.push(urlItems[i]);
      } else if (compareItems[i] !== "*") {
        match = false;
      }
    }
    if (!match) break;
  }

  if (match) return vars;
  else return false;
};

const getSearchParams = () => {
  let search = window.location.search;
  search = search.replace("?", "");
  const params = search.split("&");
  const list: Param[] = [];
  params.forEach((param: string) => {
    const elements = param.split("=");
    if (elements.length > 1) {
      list.push({
        key: elements[0],
        value: elements[1],
      } as Param);
    }
  });
  return list;
};

/**
 * Find the value in a param list based on a key
 */
const getParam = (list: Param[], key: string) => {
  const param = list.find((p: Param) => {
    return p.key === key;
  });
  if (param !== undefined) return param.value;
  else return undefined;
};
export const UrlParser = {
  urlMatches,
  getSearchParams,
  getParam,
};
