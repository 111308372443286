import { fromEvent, Subject, throttleTime } from "rxjs";
import { EventProcess, MouseState } from "..";
import { Device } from "imagine-ui";

/**
 * On my Android phone, both touch and mouse events are triggered. Mouse events are
 * therefore ignored on touch devices to avoid duplicate events. I cannot assume that
 * all devices will just trigger the mouse events.
 */

const mouseMove$ = fromEvent(document, "mousemove").pipe(throttleTime(75));
const mouseDown$ = fromEvent(document, "mousedown");
const mouseUp$ = fromEvent(document, "mouseup");
const touchMove$ = fromEvent(document, "touchmove").pipe(throttleTime(75));
const touchStart$ = fromEvent(document, "touchstart");
const touchEnd$ = fromEvent(document, "touchend");

// const result = clicks.pipe(throttle(() => interval(1000)));

const move = new Subject<MouseState>();
const press = new Subject<MouseState>();
const release = new Subject<MouseState>();

mouseMove$.subscribe((event: any) => {
  if (Device.isTouchDevice()) return;
  const mouseEvent: MouseState = {
    position: { x: event.clientX, y: event.clientY },
    pressed: event.buttons === 1, // 1 = left button pressed, 2 = right button pressed
    rightPressed: event.buttons === 2,
    elementClass: EventProcess.getClass(event),
    positions: [{ x: event.clientX, y: event.clientY }],
  };
  move.next(mouseEvent);
});

touchMove$.subscribe((event: any) => {
  const positions = EventProcess.getTouchEventPositions(event);
  if (positions.length > 0) {
    const mouseEvent: MouseState = {
      position: positions[0],
      pressed: true, // 1 = left button pressed, 2 = right button pressed
      rightPressed: false,
      elementClass: EventProcess.getClass(event),
      positions: positions,
    };
    move.next(mouseEvent);
  }
});

mouseDown$.subscribe((event: any) => {
  if (Device.isTouchDevice()) return;
  // Don't steal event from actual buttons
  if (EventProcess.isButton(event.target)) return;
  const mouseEvent: MouseState = {
    position: { x: event.clientX, y: event.clientY },
    pressed: event.buttons === 1, // 1 = left button pressed, 2 = right button pressed
    rightPressed: event.buttons === 2,
    elementClass: EventProcess.getClass(event),
    positions: [{ x: event.clientX, y: event.clientY }],
  };
  press.next(mouseEvent);
});

touchStart$.subscribe((event: any) => {
  // Don't steal event from actual buttons
  if (EventProcess.isButton(event.target)) return;
  const positions = EventProcess.getTouchEventPositions(event);
  if (positions.length > 0) {
    const mouseEvent: MouseState = {
      position: positions[0],
      pressed: true, // 1 = left button pressed, 2 = right button pressed
      rightPressed: false,
      elementClass: EventProcess.getClass(event),
      positions: positions,
    };
    press.next(mouseEvent);
  }
});

mouseUp$.subscribe((event: any) => {
  if (Device.isTouchDevice()) return;
  const mouseEvent: MouseState = {
    position: { x: event.clientX, y: event.clientY },
    pressed: event.buttons === 1, // 1 = left button pressed, 2 = right button pressed
    rightPressed: event.buttons === 2,
    elementClass: EventProcess.getClass(event),
    positions: [{ x: event.clientX, y: event.clientY }],
  };
  release.next(mouseEvent);
});

touchEnd$.subscribe((event: any) => {
  const positions = EventProcess.getTouchEventPositions(event);
  if (positions.length > 0) {
    const mouseEvent: MouseState = {
      position: positions[0],
      pressed: false, // 1 = left button pressed, 2 = right button pressed
      rightPressed: false,
      elementClass: EventProcess.getClass(event),
      positions: positions,
    };
    release.next(mouseEvent);
  }
});

export const Mouse = {
  move,
  press,
  release,
};
