import { useTranslation } from "react-i18next";
import { Modal, Tab, TabBar } from "imagine-ui";
import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
} from "../../store";
import { useState } from "react";
import {
  BuyStripeMembershipContainer,
  ChangePasswordContainer,
  MembershipContainer,
  PaymentsContainer,
  ProfileContainer,
} from "..";
import { UserAccountTab } from "../../types";
import { WindowLocation } from "../../utils";
import { MembershipActions } from "imagine-memberships";

export const UserAccountModalContainer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const activeTab = useAppSelector(LayoutSelector.getUserAccountTab);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    setErrorMessage("");
    setLoading(false);
    WindowLocation.clearUrlParams();
    dispatch(MembershipActions.clearPurchase());
  };
  const tabs: Tab[] = [
    {
      text: t("users:profile"),
      id: "profile",
    },
    {
      text: t("users:changePassword"),
      id: "changePassword",
    },
    {
      text: t("memberships:membership"),
      id: "membership",
    },
    {
      text: t("memberships:buy"),
      id: "buy",
    },
    {
      text: t("memberships:payments"),
      id: "payments",
    },
  ];
  return (
    <>
      {displayedPage === Pages.USER_ACCOUNT && (
        <>
          <Modal
            title={t("users:myAccount")}
            visible
            onClose={close}
            hideCancel
            hideFooter={true}
            size="xl"
            discreteHeader
            elementId="user-account-modal"
          >
            <TabBar
              tabs={tabs}
              active={activeTab}
              onChange={(tab) =>
                dispatch(LayoutActions.setUserAccountTab(tab as UserAccountTab))
              }
            />
            <div className="pt-lg">
              {activeTab === "profile" && <ProfileContainer />}
              {activeTab === "changePassword" && <ChangePasswordContainer />}
              {activeTab === "membership" && <MembershipContainer />}
              {/* {activeTab === "buy" && <BuyMembershipContainer />} */}
              {activeTab === "buy" && <BuyStripeMembershipContainer />}
              {activeTab === "payments" && <PaymentsContainer />}
            </div>
          </Modal>
        </>
      )}
    </>
  );
};
