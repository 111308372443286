import { ProfileTemplate } from "../types";

export const FruitProfileTemplates: ProfileTemplate[] = [
  {
    id: 1,
    leafTemplates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 20, 21, 22],
    description: "Large fruits",
    paths: [
      "m 19.499998,61 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z m 30.5,-18.609954 c 8.934032,-2.97801 9.678532,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z M 73.5,60 c 8.93403,-2.97801 9.67853,14.890046 0,14.890046 -9.67853,0 -9.67853,-17.868056 0,-14.890046 z M 22.499998,24 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z M 77.5,27.5 c 8.93402,-2.97801 9.67853,14.890046 0,14.890046 -9.67853,0 -9.67853,-17.868056 0,-14.890046 z M 53.499998,4.890046 c 8.934032,-2.97801 9.678532,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z",
    ],
    size: {
      width: 100,
      height: 100,
    },
  },
  {
    id: 2,
    leafTemplates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 20, 21, 22],
    description: "Medium fruits",
    paths: [
      "m 33.908992,74.053872 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 70,75 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 89.693892,47.973064 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 52.5,57.5 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z m -35,-10.993347 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 38.694743,33.705972 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 67.5,35 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 75,12.5 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 45,10 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z",
    ],
    size: {
      width: 100,
      height: 100,
    },
  },
  {
    id: 3,
    leafTemplates: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 20, 21, 22],
    description: "Small fruits",
    paths: [
      "m 44.086444,68.475214 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 65,20 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -42.091948,8.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 60,40 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -68,-3 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 44,-7 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 32.213208,10.9563 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -20,35.047486 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.5563557,0 -3.6783517,-5.5 0,-5.471316 z M 34.908052,78.04455 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 32.786792,-1.276478 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 36.125702,48.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 21.78235,-42 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 5.74035,33.792858 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 23.21765,7.735714 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 84.171208,27.796644 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 44.086444,28.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z",
    ],
    size: {
      width: 100,
      height: 100,
    },
  },
  {
    id: 4,
    leafTemplates: [30, 31],
    description: "Large tree fruits",
    paths: [
      "m 19.499998,61 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z m 30.5,-18.609954 c 8.934032,-2.97801 9.678532,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z M 73.5,60 c 8.93403,-2.97801 9.67853,14.890046 0,14.890046 -9.67853,0 -9.67853,-17.868056 0,-14.890046 z M 22.499998,24 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z M 77.5,27.5 c 8.93402,-2.97801 9.67853,14.890046 0,14.890046 -9.67853,0 -9.67853,-17.868056 0,-14.890046 z M 53.499998,4.890046 c 8.934032,-2.97801 9.678532,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z",
    ],
    size: {
      width: 100,
      height: 150,
    },
  },
  {
    id: 5,
    leafTemplates: [30, 31],
    description: "Medium tree fruits",
    paths: [
      "m 33.908992,74.053872 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 70,75 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 89.693892,47.973064 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 52.5,57.5 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z m -35,-10.993347 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 38.694743,33.705972 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 67.5,35 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 75,12.5 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 45,10 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z",
    ],
    size: {
      width: 100,
      height: 150,
    },
  },
  {
    id: 6,
    leafTemplates: [30, 31],
    description: "Small tree fruits",
    paths: [
      "m 44.086444,68.475214 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 65,20 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -42.091948,8.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 60,40 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -68,-3 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 44,-7 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 32.213208,10.9563 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -20,35.047486 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.5563557,0 -3.6783517,-5.5 0,-5.471316 z M 34.908052,78.04455 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 32.786792,-1.276478 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 36.125702,48.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 21.78235,-42 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 5.74035,33.792858 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 23.21765,7.735714 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 84.171208,27.796644 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 44.086444,28.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z",
    ],
    size: {
      width: 100,
      height: 150,
    },
  },
];
