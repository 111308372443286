import { useState, useEffect } from "react";
// import { throttle } from "lodash";

type SliderProps = {
  id?: string;
  min: number;
  max: number;
  value?: number; // Need to update this value within 1000ms, otherwise the thumb position jumps back
  onChange?: (value: number) => void;
  stepSize?: number;
  className?: string;
  disabled?: boolean;
  vertical?: boolean;
};

/**
 * Slider.
 * @param props
 */
export const Slider = (props: SliderProps) => {
  const [internalValue, setInternalValue] = useState(props.value || props.min);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: any) => {
    setInternalValue(event.target.value);
    if (props.onChange) {
      props.onChange(event.target.value);
    }
  };

  // const throttleNewValue = useCallback(throttle(handleChange, 50), [
  //   handleChange,
  // ]);

  useEffect(() => {
    if (props.value !== undefined) {
      setInternalValue(props.value);
    }
  }, [props.value]);

  const className = props.vertical
    ? "slider slider-vertical"
    : "slider slider-horizontal";

  return (
    <div className={props.className ? props.className : ""}>
      <input
        type="range"
        className={className}
        value={internalValue}
        min={props.min}
        max={props.max}
        step={props.stepSize}
        id={props.id}
        onChange={handleChange}
        disabled={props.disabled}
      />
    </div>
  );
};
