import React, { useMemo } from "react";
import { Icon, NumberInput } from "imagine-ui";
import { Delimiter } from "imagine-essentials";
import { UnitScale } from "project";
import { MeasureInput } from "draw";
// import '../../styling/navigation.css';

type Props = {
  text?: string;
  icon?: string | React.ReactNode;
  value?: number;
  disabled?: boolean; // The number input is enabled
  readonlyValue?: string; // Display as plain text instead of number input
  elementId?: string;
  onChange?: (value: number) => void;
  className?: string; // Additional class on container div

  delimiter: Delimiter;
  unitScale: UnitScale;
  omitYards?: boolean;
  omitFractions?: boolean;
};

/**
 * This displays a button customized for the header.
 * @param props
 */
export const ToolNumberInput = (props: Props) => {
  const className = useMemo(() => {
    let className = "half";

    if (props.disabled) {
      className += " disabled";
    }
    return className;
  }, [props.disabled]);

  const getIcon = () => {
    if (props.icon) {
      if (typeof props.icon === "string") {
        return <Icon name={props.icon} className="icon" />;
      }
      return props.icon;
    }
  };

  return (
    <div
      className={
        "tool-container " + (props.className ? " " + props.className : "")
      }
    >
      <div className="tool-number-input">
        {getIcon()}
        <div className="text">{props.text}</div>
        {props.readonlyValue && (
          <div className="readonly">{props.readonlyValue}</div>
        )}
        {!props.readonlyValue && (
          <MeasureInput
            value={props.value}
            onFinish={props.onChange}
            onEnterPress={props.onChange}
            size="sm"
            unitScale={props.unitScale}
            delimiter={props.delimiter}
            omitYards={props.omitYards}
            omitFractions={props.omitFractions}
          />
        )}
      </div>
    </div>
  );
};
