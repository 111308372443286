import { useCallback, useMemo, useState } from "react";
import {
  CursorModeContainer,
  ContextMenuContainer,
  EditableAreaContainer,
  ItemStampContainer,
  StaticAreaContainer,
  StaticPlanItemsContainer,
  CancelStampContainer,
  ExitObjectModeContainer,
  MarkedRectangleContainer,
  ZoomButtonsContainer,
  ReferenceImageContainer,
} from "..";
import { AppInfo, Point } from "imagine-essentials";
import { Alert, Device } from "imagine-ui";
import {
  Canvas,
  Grid,
  LoadingMessage,
  SatelliteMap,
  Scale,
  StaticReferenceImage,
} from "../../components";

import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  CanvasSelector,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  LayoutSelector,
  ObjectEditorActions,
  LayoutActions,
} from "../../store";
import { CursorMode, MapMode, MarkerMode, Pages } from "../../enums";
import { ShapeCreatorContainer } from "../draw-containers/ShapeCreatorContainer";
import { SelectedPlanItemsContainer } from "../draw-containers/SelectedPlanItemsContainer";
import { CanvasOperations, ItemType, ShapeType } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { MapOperations } from "../../utils";
import { start } from "repl";
import { useTranslation } from "react-i18next";
import { I18nTools } from "imagine-i18n";
import { SceneView, SceneryView } from "scenery";

export const CanvasContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const canvasVisibleRectangle = useAppSelector(
    CanvasSelector.getVisibleRectangle
  );
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const touchDevice = Device.isTouchDevice();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);
  const mapMode = useAppSelector(CanvasSelector.getMapMode);
  const mapCenter = useAppSelector(CanvasSelector.getMapCenter);
  const mapZoom = useAppSelector(CanvasSelector.getMapZoomIndex);
  const isMapFrozen = useAppSelector(CanvasSelector.isMapFrozen);
  const canvasMessage = useAppSelector(CanvasSelector.getMessage);
  const canvasLoadingMessage = useAppSelector(CanvasSelector.getLoadingMessage);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const isEnteringText = useAppSelector(CanvasSelector.isEnteringText);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const isEditingReferenceImage = useAppSelector(
    PlanEditorSelector.isEditingReferenceImage
  );
  const selectedShapeTool = useAppSelector(CanvasSelector.getSelectedShapeTool);
  const itemStampType = useAppSelector(PlanEditorSelector.getStampType);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const selectedObjectItem = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const markerMode = useAppSelector(CanvasSelector.getMarkerMode);
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const isTouchDevice = Device.isTouchDevice();
  const apiVersion = useAppSelector(UserSelector.getApiVersion);

  const showReferenceImage = useAppSelector(
    CanvasSelector.getShowReferenceImage
  );

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItem : selectedPlanItems;
  }, [objectMode, selectedObjectItem, selectedPlanItems]);

  const isOneShapeSelected = useMemo(() => {
    if (selectedItems.length === 1) {
      return selectedItems[0].type === ItemType.SHAPE;
    }
    return false;
  }, [selectedItems]);

  const selectedShape = useAppSelector(CanvasSelector.getSelectedShapeTool);

  const [originalZeroReference, setOriginalZeroReference] = useState<Point>({
    ...zeroReference,
  });

  const cursorOverride = useMemo(() => {
    if (selectedShape !== ShapeType.NONE) {
      return "crosshair";
    }
    if (cursorMode === CursorMode.DRAG) {
      return "grab";
    }
    return "";
  }, [cursorMode, selectedShape]);

  const dragEnabled = useMemo(() => {
    if (selectedShapeTool !== ShapeType.NONE) return false;
    return cursorMode === CursorMode.DRAG;
  }, [cursorMode, selectedShapeTool]);

  const clearSelected = () => {
    // Should not exit directly if text is being entered. TextCreator will handle exiting text editing mode.
    if (isEnteringText) return;

    // If area has been edited, exit area editing mode
    if (isEditingArea) {
      dispatch(PlanEditorActions.setEditingArea(false));
    }
    if (isEditingReferenceImage) {
      dispatch(PlanEditorActions.setEditingReferenceImage(false));
    }
    if (objectMode) {
      dispatch(ObjectEditorActions.setSelectedItems([]));
    } else {
      dispatch(PlanEditorActions.setSelectedItems([]));
    }
  };

  /**
   * Updates the zoom. This will calculate the new zero reference.
   * @param offset The offset in number of indexes.
   * @param position Mouse position while zooming
   * @param ppm New zoom pixels per meter. If set, this will override the offset.
   */
  const updateZoom = useCallback(
    (offset: number, position: Point) => {
      if (mapMode === MapMode.VISIBLE) {
        if (isMapFrozen) {
          if (canvasMessage !== t("noZoomWhileMapFrozen")) {
            dispatch(CanvasActions.setMessage(t("noZoomWhileMapFrozen")));
            setTimeout(() => {
              dispatch(CanvasActions.setMessage(""));
            }, 2000);
          }
          return;
        }
        // Calculate the actual zoom from the map. This will be used instead of the Garden Sketcher zoom
        const ppm = MapOperations.getMapPPM(mapZoom + offset, mapCenter.lat);
        dispatch(
          CanvasActions.updateZoom({
            offset: offset,
            position: position,
            ppm: ppm,
          })
        );
      } else {
        // No map, normal Garden Sketcher zoom
        dispatch(
          CanvasActions.updateZoom({
            offset: offset,
            position: position,
            ppm: undefined,
          })
        );
      }
    },
    [canvasMessage, dispatch, isMapFrozen, mapCenter.lat, mapMode, mapZoom, t]
  );

  const setZoom = useCallback(
    (newZom: number, newZeroReference: Point) => {
      dispatch(CanvasActions.setZoom(newZom));
      dispatch(CanvasActions.setZeroReference(newZeroReference));
    },
    [dispatch]
  );

  const startDrag = () => {
    setOriginalZeroReference({ ...zeroReference });
    if (objectMode) {
      dispatch(ObjectEditorActions.setSelectedItems([]));
    } else {
      dispatch(PlanEditorActions.setSelectedItems([]));
    }
  };

  const drag = (dist: Point) => {
    dispatch(
      CanvasActions.setZeroReference(
        CanvasOperations.getPointSum(originalZeroReference, dist)
      )
    );
  };

  const openContextMenu = (position: Point, className: string) => {
    // if (modificationsLocked) return;
    // // Touch devices do not use the context menu
    // if (isTouchDevice) return;

    // if (isStampingItems) return;
    // Check identifier to avoid triggering this when an item is clicked (board context would display on top of item context)
    if (className === "board" || className === "canvas") {
      dispatch(CanvasActions.openContextMenu(position));
    }
  };

  const showVersionWarning = useMemo(() => {
    if (apiVersion === "") return false;
    return AppInfo.isVersionOlder(AppInfo.getAppVersion(), apiVersion, true);
  }, [apiVersion]);

  const backgroundChildren = useMemo(() => {
    return (
      <>
        {mapMode === MapMode.VISIBLE && (
          <SatelliteMap center={mapCenter} zoom={mapZoom} />
        )}
      </>
    );
  }, [mapCenter, mapMode, mapZoom]);

  const foregroundChildren = useMemo(() => {
    return (
      <>
        {showVersionWarning && (
          <div className="canvas-tools-top-center rounded border-radius bg-main">
            <Alert color="warning">{t("versionWarning")}</Alert>
          </div>
        )}
        <CursorModeContainer />
        <ZoomButtonsContainer />
        <CancelStampContainer />
        <ContextMenuContainer />
        {canvasMessage && <div className="canvas-message">{canvasMessage}</div>}

        {canvasLoadingMessage && (
          <LoadingMessage message={canvasLoadingMessage} />
        )}
        {objectMode && <ExitObjectModeContainer />}
      </>
    );
  }, [canvasMessage, showVersionWarning, t, canvasLoadingMessage, objectMode]);

  const setSceneView = useCallback((sceneView: SceneView) => {
    dispatch(CanvasActions.setSceneView(sceneView));
    dispatch(LayoutActions.setDisplayedPage(Pages.SCENERY_PAGE));
    dispatch(CanvasActions.setMarkerMode(MarkerMode.NONE));
    dispatch(CanvasActions.setMessage(""));
  }, []);

  return (
    <Canvas
      size={canvasSize}
      offset={canvasOffset}
      visibleRectangle={canvasVisibleRectangle}
      zoom={zoom}
      zeroReference={zeroReference}
      onClick={clearSelected}
      onZoom={updateZoom}
      onUpdateZoom={setZoom}
      cursor={cursorOverride}
      backgroundChildren={backgroundChildren}
      foregroundChildren={foregroundChildren}
      mapVisible={mapMode === MapMode.VISIBLE}
      enableDrag={dragEnabled}
      onDragStart={startDrag}
      onDrag={drag}
      onRightClick={openContextMenu}
    >
      {showReferenceImage && <ReferenceImageContainer />}

      {!objectMode && <StaticAreaContainer />}
      {userPreferences.showGrid && mapMode === MapMode.NONE && (
        <Grid
          zoom={zoom}
          zeroReference={zeroReference}
          size={canvasSize}
          lineColor="#989ca0"
          unitScale={userPreferences.unitScale}
        />
      )}

      {<StaticPlanItemsContainer />}
      {(isOneShapeSelected || selectedShape !== ShapeType.NONE) && (
        <ShapeCreatorContainer />
      )}
      <EditableAreaContainer />
      {!isOneShapeSelected && <SelectedPlanItemsContainer />}
      {itemStampType !== null && <ItemStampContainer />}

      {userPreferences.showScale && mapMode === MapMode.NONE && (
        <Scale
          zoom={zoom}
          zeroReference={zeroReference}
          size={canvasSize}
          color="#57595b"
          unitScale={userPreferences.unitScale}
          delimiter={delimiter}
        />
      )}
      {/* TODO: Allow when user has selected mark-mode */}
      <MarkedRectangleContainer />
      {markerMode === MarkerMode.DRAW_VIEW && (
        <SceneryView
          zoom={zoom}
          zeroReference={zeroReference}
          canvasOffset={canvasOffset}
          onViewChange={setSceneView}
        />
      )}
    </Canvas>
  );
};
