import { PlanInfo } from "draw";
import { Alert, Button, ContextListItem, Modal, TabBar } from "imagine-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { PlanPreviewCard } from "..";

interface Props {
  onClose: () => void;
  onOpenPlan: (planId: number) => void;
  onDeletePlan: (planId: number, name: string) => void;
  userPlans: PlanInfo[];
  publicPlans: PlanInfo[];
  errorMessage?: string;
}

export const OpenPlanModal = (props: Props) => {
  const { onDeletePlan } = props;
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("user");

  const plans = useMemo(() => {
    if (activeTab === "user") {
      return props.userPlans;
    } else if (activeTab === "public") {
      return props.publicPlans;
    }
    return [];
  }, [activeTab, props.publicPlans, props.userPlans]);

  const plansWithActions = useMemo(() => {
    return plans.map((plan) => {
      const actions: ContextListItem[] = [];
      if (activeTab === "user") {
        actions.push({
          text: t("imagine:delete"),
          action: () => onDeletePlan(plan.id, plan.name),
        });
      }
      return {
        plan: { ...plan },
        actions: actions,
      };
    });
  }, [plans, activeTab, t, onDeletePlan]);

  // const [selectedPlanId, setSelectedPlanId] = useState(0);

  // const selectedPlan = useMemo(() => {
  //   return props.plans.find((plan) => plan.id === selectedPlanId);
  // }, [props.plans, selectedPlanId]);

  const tabs = useMemo(
    () => [
      {
        text: t("myPlans"),
        id: "user",
      },
      {
        text: t("publicPlans"),
        id: "public",
      },
    ],
    [t]
  );

  return (
    <Modal
      title={t("openPlan")}
      visible
      onClose={props.onClose}
      hideFooter
      size="lg"
      elementId="open-plan-modal"
    >
      <div id="open-plan-page">
        <TabBar
          tabs={tabs}
          active={activeTab}
          onChange={setActiveTab}
          className="mb-xl"
        />
        {props.errorMessage && (
          <Alert color="danger">{props.errorMessage}</Alert>
        )}

        <div className="grid columns-4 columns-3--md columns-2--sm gap">
          {plansWithActions.map(
            (
              entry: { plan: PlanInfo; actions: ContextListItem[] },
              index: number
            ) => (
              <PlanPreviewCard
                planInfo={entry.plan}
                key={entry.plan.id}
                actions={entry.actions}
                elementId={"plan-" + index}
              >
                <Button
                  className="w-full"
                  size="sm"
                  onClick={() => props.onOpenPlan(entry.plan.id)}
                  elementId={"open-plan-" + index + "-button"}
                >
                  {t("imagine:open")}
                </Button>
              </PlanPreviewCard>
            )
          )}
        </div>
      </div>
    </Modal>
  );
};
