import { useState, useRef, useEffect, useCallback, useMemo } from "react";
import { CanvasOperations, EventProcess, Mouse, MouseState } from "..";
import { Point } from "imagine-essentials";
import { DateTimeText } from "imagine-datetime";
import { useWindowWidth } from "imagine-ui";

type Props = {
  month: number;
  onChanged: (month: number) => void;
  disabled?: boolean;
  darkLines?: boolean; // If the lines and text should be dark
  className?: string;
  visible?: boolean; // Makes sure correct positions are calculated when it becomes visible
  elementId?: string;
  onGrabbed?: () => void;
};

// Minimum size where large season slider is displayed
const breakPoint = 576;

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
const markerDiameter = 48;
const markerRadius = 24;

/**
 * Displays a month slider. This is tied strongly together with the CSS styles.
 * Elements inherit the background color of the parent, so avoid positioning it
 * on a transparent parent (use wrapper)
 */
export const MonthSlider = (props: Props) => {
  const targetRef = useRef<HTMLDivElement>(null);
  const displayMonth = useMemo(() => {
    return props.month > 0 ? props.month : 1;
  }, [props.month]);
  const { onChanged, onGrabbed } = props;

  const [sliderWidth, setSliderWidth] = useState(0);
  const [cachedMonth, setCachedMonth] = useState(displayMonth);
  const [movingMarkerPos, setMovingMarkerPos] = useState<number | null>(null);
  const [startPxPos, setStartPxPos] = useState<number | null>(null);
  const windowWidth = useWindowWidth();

  const getMonthCenter = useCallback(
    (month: number) => {
      if (sliderWidth >= breakPoint) {
        // Each season has 32+16=48px available
        return markerRadius + (month - 1) * markerDiameter;
      } else {
        // Reserve room for marker at the beginning and end, and share the rest in 11 bits
        const dist = (sliderWidth - markerDiameter) / 11;
        return markerRadius + (month - 1) * dist;
      }
    },
    [sliderWidth]
  );

  const markerPos = useMemo(() => {
    if (movingMarkerPos !== null) return movingMarkerPos;
    return getMonthCenter(displayMonth) - markerRadius;
  }, [getMonthCenter, movingMarkerPos, displayMonth]);

  const getSeasonCircleStyle = (month: number) => {
    // Large season slider requires a width of 37rem=592px
    if (sliderWidth >= breakPoint) {
      return {
        left: getMonthCenter(month) - 16 + "px",
      };
    }
    return {
      left: getMonthCenter(month) - 3 + "px",
    };
  };

  const getMarkerStyle = () => {
    return {
      left: markerPos + "px",
    };
  };

  const handleMouseDown = (event: any) => {
    if (props.disabled) return;
    const pos = EventProcess.getEventPosition(event);
    setCachedMonth(displayMonth);
    setStartPxPos(pos.x);
    if (onGrabbed) onGrabbed();
  };

  const handleMouseMove = useCallback(
    (position: Point) => {
      if (startPxPos !== null) {
        const pos = position;
        const diff = pos.x - startPxPos;

        const originalPos = getMonthCenter(cachedMonth) - markerRadius;
        const firstSeasonPos = getMonthCenter(1) - markerRadius;
        const lastSeasonPos = getMonthCenter(12) - markerRadius;
        const markerDistHalf = (getMonthCenter(2) - getMonthCenter(1)) / 2;
        let newMarkerPos = originalPos + diff;
        // Make sure marker is within slider
        if (newMarkerPos < firstSeasonPos) newMarkerPos = firstSeasonPos;
        else if (newMarkerPos > lastSeasonPos) newMarkerPos = lastSeasonPos;

        setMovingMarkerPos(newMarkerPos);

        let newMonth = 1;
        const markerCenter = markerPos + markerRadius;
        months.forEach((month: number) => {
          const currentMonthCenter = getMonthCenter(month);

          if (
            markerCenter >= currentMonthCenter - markerDistHalf &&
            markerCenter < currentMonthCenter + markerDistHalf
          ) {
            newMonth = month;
          }
        });

        if (newMonth !== displayMonth) {
          onChanged(newMonth);
        }
      }
    },
    [
      cachedMonth,
      getMonthCenter,
      markerPos,
      onChanged,
      displayMonth,
      startPxPos,
    ]
  );

  const handleMouseUp = useCallback(() => {
    if (startPxPos !== null) {
      setStartPxPos(null);
      setMovingMarkerPos(null);
      // snapMarkerPos();
    }
  }, [startPxPos]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        handleMouseMove(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      if (startPxPos !== null) {
        handleMouseUp();
      }
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [handleMouseMove, handleMouseUp, startPxPos]);

  const handleClick = (month: number) => {
    if (!props.disabled === true) {
      props.onChanged(month);
    }
  };

  const getClassName = () => {
    let className = "season-slider";
    if (props.disabled) className += " disabled";
    if (props.darkLines) className += " dark";
    if (sliderWidth < breakPoint) className += " w-full";
    if (props.className) className += " " + props.className;
    return className;
  };

  const getSeasonCircleClassName = () => {
    if (targetRef.current !== null) {
      const rect = targetRef.current?.getBoundingClientRect();
      if (rect.width >= breakPoint) return "season";
      else return "season-circle";
    }
    return "";
  };

  const updateWidth = useCallback(() => {
    if (targetRef.current !== null) {
      const rect = targetRef.current?.getBoundingClientRect();
      setSliderWidth(rect.width);
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateWidth();
    });
    const currentRef = targetRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [targetRef, updateWidth]);

  useEffect(() => {
    if (props.disabled) {
      setCachedMonth(displayMonth);
    } else {
      onChanged(cachedMonth);
    }
  }, [props.disabled]);

  return (
    <div className={getClassName()} ref={targetRef} id={props.elementId}>
      <div className="line"></div>
      {months.map((month: number) => (
        <div
          className={getSeasonCircleClassName()}
          key={DateTimeText.getMonthTextShort(month)}
          style={getSeasonCircleStyle(month)}
          onClick={() => handleClick(month)}
        >
          {sliderWidth >= breakPoint && DateTimeText.getMonthTextShort(month)}
        </div>
      ))}
      {props.disabled !== true && (
        <div
          className="marker"
          style={getMarkerStyle()}
          onMouseDown={handleMouseDown}
          onTouchStart={handleMouseDown}
        >
          {DateTimeText.getMonthTextShort(
            displayMonth !== 0 ? displayMonth : cachedMonth
          )}
          <div className="marker-background"></div>
        </div>
      )}
    </div>
  );
};
