import { RGB } from "..";

/**
 * Sort an array of colors from lightest to darkest given by the hex number.
 * The result is printet to the console. The function is meant as assistance
 * when selecting and sorting colors for the color picker.
 * @param colors Array of colors.
 */
// const sortColors = (colors: string[]) => {
//   const rgbs = colors.map((color: string) => {
//     return {
//       r: parseInt(color.substr(1, 2), 16),
//       g: parseInt(color.substr(3, 2), 16),
//       b: parseInt(color.substr(5, 2), 16),
//     } as RGB;
//   });
//   const sorted = rgbs.sort((a: RGB, b: RGB) => {
//     return b.b + b.g + b.r - (a.b + a.g + a.r);
//   });
//   let str = "";
//   sorted.forEach((color: RGB) => {
//     let r = color.r.toString(16);
//     let g = color.g.toString(16);
//     let b = color.b.toString(16);
//     if (color.r < 0x10) r = "0" + r;
//     if (color.g < 0x10) g = "0" + g;
//     if (color.b < 0x10) b = "0" + b;
//     str += '"#' + r + g + b + '", ';
//     // str += '"#' + color.r.toString() + color.r.toString() + color.r.toString() + '", ';
//   });
// };

/**
 * Make color darker or lighter.
 * @param color The color hex number
 * @param factor Below 1 to make darker, above 1 to make lighter and 1 to keep as it is.
 */
const alterColor = (color: string, factor: number) => {
  const rgb = {
    r: parseInt(color.substr(1, 2), 16),
    g: parseInt(color.substr(3, 2), 16),
    b: parseInt(color.substr(5, 2), 16),
  } as RGB;
  rgb.r = Math.round(rgb.r * factor);
  rgb.g = Math.round(rgb.g * factor);
  rgb.b = Math.round(rgb.b * factor);
  if (rgb.r > 255) rgb.r = 255;
  if (rgb.g > 255) rgb.g = 255;
  if (rgb.b > 255) rgb.b = 255;

  let r = rgb.r.toString(16);
  let g = rgb.g.toString(16);
  let b = rgb.b.toString(16);

  if (rgb.r < 16) r = "0" + r;
  if (rgb.g < 16) g = "0" + g;
  if (rgb.b < 16) b = "0" + b;
  const newColor = "#" + r + g + b;
  return newColor;
};

const darkenColor = (color: string, factor: number = 0.9) => {
  return alterColor(color, factor);
};

const lightenColor = (color: string, factor: number = 1.2) => {
  return alterColor(color, factor);
};

/**
 * Get the text color that will be most readable on a background of the given color.
 * @param hexColor The background color.
 */
const getTextColor = (hexColor: string) => {
  const rgb = {
    r: parseInt(hexColor.substr(1, 2), 16),
    g: parseInt(hexColor.substr(3, 2), 16),
    b: parseInt(hexColor.substr(5, 2), 16),
  } as RGB;
  const brightness = Math.round(
    (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000
  );
  return brightness > 175 ? "#000000" : "#ffffff";
};

export const ColorUtils = {
  darkenColor,
  lightenColor,
  getTextColor,
};
