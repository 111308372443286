import {
  LayoutActions,
  LayoutSelector,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import { Button, NotificationType, useNotification } from "imagine-ui";
import { useTranslation } from "react-i18next";

/**
 * Displays a exit object mode button on top of the canvas.
 * @param props
 * @returns
 */
export const ExitObjectModeContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const unsavedChanges = useAppSelector(ObjectEditorSelector.hasUnsavedChanges);
  const objectTemplate = useAppSelector(
    ObjectEditorSelector.getSavedObjectTemplate
  );
  const objectItem = useAppSelector(ObjectEditorSelector.getObjectItem);
  const isOnlyOneObjectItemSelected = useAppSelector(
    PlanEditorSelector.isOnlyOneObjectItemSelected
  );

  const sidebarLeftOpen = useAppSelector(LayoutSelector.getSidebarLeftOpen);

  const exit = () => {
    dispatch(ObjectEditorActions.setObjectMode(false));
    dispatch(LayoutActions.restoreSidebarLeftActiveTab());
  };

  const convertObject = () => {
    // Delete the selected item in the plan and add the object item instead
    if (objectItem && objectTemplate) {
      if (isOnlyOneObjectItemSelected) {
        dispatch(PlanEditorActions.updateItem(objectItem));
      } else {
        dispatch(PlanEditorActions.deleteSelectedItems());
        dispatch(PlanEditorActions.addItem(objectItem));
      }
      dispatch(PlanEditorActions.addObjectTemplate(objectTemplate));
      dispatch(PlanEditorActions.updateObjectSearchResult(objectTemplate));
    }
  };

  /**
   * Checks if user needs to confirm exit, and then exists object mode and possible transfers the object data to the plan
   */
  const exitObjectMode = () => {
    if (unsavedChanges) {
      if (objectTemplate === null) {
        // Template has not been created and unsaved changes exist
        notification.add(
          NotificationType.CONFIRM,
          t("exitNewObjectWithoutSaving")
        );
        notification.onConfirm(() => {
          exit();
        });
      } else {
        // Template has been created and unsaved changes exist
        notification.add(
          NotificationType.CONFIRM,
          t("exitObjectWithoutSaving")
        );
        notification.onConfirm(() => {
          convertObject();
          exit();
        });
      }
    } else {
      // No unsaved changes
      if (objectTemplate !== null) {
        convertObject();
      }
      exit();
    }
  };

  return (
    <>
      {objectMode && (
        <div
          className={
            "canvas-tools-top-left bg-main rounded" +
            (sidebarLeftOpen ? " sidebar-open" : "")
          }
        >
          <Button color="danger" outline onClick={exitObjectMode}>
            {t("exitObjectModeAndReturnToPlan")}
          </Button>
        </div>
      )}
    </>
  );
};
