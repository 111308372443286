import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FeedbackType } from "..";
import { Option } from "imagine-essentials";
import { UserText } from "../utils";
import {
  Alert,
  ImageInput,
  Label,
  Modal,
  NotificationType,
  Select,
  TextInput,
  useNotification,
} from "imagine-ui";

interface Props {
  onClose: () => void;
  onSend: (description: string, type: FeedbackType, customImage?: File) => void;
  message?: string;
  errorMessage?: string;
  loading?: boolean;
}

/**
 * Displays a list of reasons for cancelling the subscription, and also a text area for the user to write a comment.
 * @param props
 * @returns
 */
export const FeedbackModal = (props: Props) => {
  const { t } = useTranslation();
  const notification = useNotification();

  const [description, setDescription] = useState("");
  const [type, setType] = useState<FeedbackType | undefined>(undefined);

  const [customImage, setCustomImage] = useState<File | undefined>();

  const options = useMemo(() => {
    const feedbackTypes = [
      FeedbackType.QUESTION,
      FeedbackType.BUG_REPORT,
      FeedbackType.FEATURE_REQUEST,
      FeedbackType.IMPROVEMENT,
      FeedbackType.OTHER,
    ];
    return feedbackTypes.map((i) => {
      return {
        value: i,
        label: UserText.getFeedbackTypeText(i),
      } as Option;
    });
  }, []);

  const checkDescription = (value: string) => {
    if (description === "") setDescription(value);
  };

  const disableSend = useMemo(() => {
    return description === "" || type === undefined || props.loading;
  }, [description, type, props.loading]);

  const sendFeedback = async () => {
    // If message is displayed, just close
    if (props.message) return props.onClose();

    if (description === "" || type === undefined) return;
    props.onSend(description, type, customImage);
  };

  const displayError = (message: string) => {
    notification.add(NotificationType.INFO, message);
  };

  return (
    <>
      <Modal
        title={t("users:giveFeedback")}
        visible
        onClose={props.onClose}
        confirmText={props.message ? t("imagine:close") : t("imagine:send")}
        onConfirm={sendFeedback}
        confirmDisabled={disableSend}
        loading={props.loading}
        hideCancel={props.message != "" && props.errorMessage !== undefined}
        size="lg"
        elementId="feedback-modal"
      >
        {props.message && (
          <>
            <h5 className="mb-lg">{props.message}</h5>
          </>
        )}
        {props.errorMessage && (
          <>
            <Alert className="mb-lg" color="danger">
              {props.errorMessage}
            </Alert>
          </>
        )}

        {!props.message && (
          <>
            <Label>{t("users:whichTypeOfFeedback")}</Label>
            <Select
              options={options}
              selected={type}
              onSelectedNumber={setType}
              className="mb"
              elementId="feedback-type-select"
            />
            {type === FeedbackType.BUG_REPORT && (
              <>
                <p className="mb-xs">{t("users:descripeTheBug")}</p>
                <ol className="mb">
                  <li>{t("users:bugDescriptionSteps")}</li>
                  <li>{t("users:bugDescriptionExpectation")}</li>
                  <li>{t("users:bugDescriptionActual")}</li>
                </ol>
              </>
            )}
            {type !== FeedbackType.BUG_REPORT && (
              <Label>{t("imagine:description")}</Label>
            )}
            <TextInput
              className="mb"
              value={description}
              multiline
              onFinish={setDescription}
              onChange={checkDescription}
              height="lg"
              elementId="feedback-description-input"
            />
            {type === FeedbackType.BUG_REPORT && (
              <>
                <p className="mb">{t("users:includeImagesInBugReport")}:</p>
                <div>
                  <ImageInput
                    id="custom-screenshot"
                    onImageSelected={setCustomImage}
                    maxSize={5}
                    onError={displayError}
                  />
                </div>
              </>
            )}
          </>
        )}
      </Modal>
    </>
  );
};
