import { Icon, Link } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { Tour } from "../../enums";
import {
  LayoutActions,
  LayoutSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import {
  UsedFeature,
  UserActions,
  UserSelector,
  UsersApi,
} from "imagine-users";
import { FeatureKey } from "project";
import {
  LayersTourContainer,
  SatelliteMapTourContainer,
  ShapesTourContainer,
} from "..";
import { useCallback, useEffect, useMemo, useState } from "react";

export const TourContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [usedFeatures, setUsedFeatures] = useState<UsedFeature[]>([]);
  const user = useAppSelector(UserSelector.getUserNotNull);

  const loadFeatures = useCallback(async () => {
    const response = await UsersApi.getUsedFeatures({ userId: user.id });
    if (response.success && response.data) {
      setUsedFeatures(response.data);
    }
  }, []);

  useEffect(() => {
    loadFeatures();
  }, [loadFeatures]);

  const hasCompletedSatelliteMapTour = useMemo(() => {
    const feature = usedFeatures.find(
      (f) => f.feature === FeatureKey.FINISH_TOUR_SATELLITE_MAP
    );
    return feature !== undefined;
  }, [usedFeatures]);

  const hasCompletedShapesTour = useMemo(() => {
    const feature = usedFeatures.find(
      (f) => f.feature === FeatureKey.FINISH_TOUR_SHAPES
    );
    return feature !== undefined;
  }, [usedFeatures]);

  const hasCompletedLayersTour = useMemo(() => {
    const feature = usedFeatures.find(
      (f) => f.feature === FeatureKey.FINISH_TOUR_LAYERS
    );
    return feature !== undefined;
  }, [usedFeatures]);

  const currentTour = useAppSelector(LayoutSelector.getCurrentTour);

  const selectTour = (tour: number) => {
    dispatch(LayoutActions.setCurrentTour(tour));
    switch (tour) {
      case Tour.SATELLITE_MAP:
        dispatch(UserActions.track(FeatureKey.START_TOUR_SATELLITE_MAP));
        break;
      case Tour.SHAPES:
        dispatch(UserActions.track(FeatureKey.START_TOUR_SHAPES));
        break;
      default:
        break;
    }
  };
  return (
    <div className="p text-sm">
      {currentTour === Tour.NONE && (
        <>
          <p className="mb text-bold">{t("doYouNeedHelp")}</p>
          <p className="mb">{t("readArticlesOrFollowGuides")}</p>

          <table className="table table-transparent">
            <tbody>
              <tr>
                <td width="10%">
                  {hasCompletedShapesTour ? <Icon name="check" /> : ""}
                </td>
                <td>
                  <Link onClick={() => selectTour(Tour.SHAPES)}>
                    {t("introToShapes")}
                  </Link>
                </td>
              </tr>
              <tr>
                <td width="10%">
                  {hasCompletedSatelliteMapTour ? <Icon name="check" /> : ""}
                </td>
                <td>
                  <Link onClick={() => selectTour(Tour.SATELLITE_MAP)}>
                    {t("drawPropertyWithSatelliteMap")}
                  </Link>
                </td>
              </tr>
              <tr>
                <td width="10%">
                  {hasCompletedLayersTour ? <Icon name="check" /> : ""}
                </td>
                <td>
                  <Link onClick={() => selectTour(Tour.LAYERS)}>
                    {t("createLayers")}
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      {currentTour === Tour.SATELLITE_MAP && (
        <SatelliteMapTourContainer onFinish={loadFeatures} />
      )}
      {currentTour === Tour.SHAPES && (
        <ShapesTourContainer onFinish={loadFeatures} />
      )}
      {currentTour === Tour.LAYERS && (
        <LayersTourContainer onFinish={loadFeatures} />
      )}
    </div>
  );
};
