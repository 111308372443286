import React, { useMemo } from "react";

interface Props {
  label?: string;
  checked: boolean;
  children?: React.ReactNode;
  onChange?: (checked: boolean) => void;
  id?: string; /// Unique ID used to map the label to the checkbox when clicked, no spaces allowed
  disabled?: boolean;
  className?: string;
  invalid?: boolean;
}

/**
 * A checkbox wrapper for easier usage.
 * @param props
 */
export const Checkbox = (props: Props) => {
  // const [checked, setChecked] = useState(props.checked || false);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onChange = (event: any) => {
    event.stopPropagation();
    // setChecked(event.target.checked || false);
    if (props.onChange) {
      props.onChange(event.target.checked || false);
    }
  };

  const groupClassName = useMemo(() => {
    let className = "checkbox";
    if (props.invalid) className += " invalid";
    if (props.disabled) className += " disabled";
    if (props.className) className += " " + props.className;
    return className;
  }, [props.className, props.disabled, props.invalid]);

  // useEffect(() => {
  //   setChecked(props.checked || false);
  // }, [props.checked]);

  return (
    <div className={groupClassName}>
      <input
        id={props.id}
        type="checkbox"
        onChange={onChange}
        checked={props.checked}
        disabled={props.disabled}
      />
      {(props.label || props.children) && (
        <label htmlFor={props.id} id={props.id + "-label"}>
          <>{props.label || props.children}</>
        </label>
      )}
    </div>
  );
};
