import { BorderType, Pattern, TextAlign, TextMode } from "..";
import i18next from "i18next";

const getBorderTypeName = (type: BorderType | number) => {
  switch (type) {
    case BorderType.NONE:
      return i18next.t("imagine:none");
    case BorderType.DASHED:
      return i18next.t("dashed");
    case BorderType.DOTTED:
      return i18next.t("dotted");
    case BorderType.SOLID:
      return i18next.t("solid");
    default: {
      throw new Error("Unsupported border type: " + type);
    }
  }
};

const getTextModeName = (type: TextMode | number) => {
  switch (type) {
    case TextMode.STATIC:
      return i18next.t("fixedSize");
    case TextMode.DYNAMIC:
      return i18next.t("followZoom");
    default: {
      throw new Error("Unsupported text mode: " + type);
    }
  }
};

const getTextAlignName = (type: TextAlign | number) => {
  switch (type) {
    case TextAlign.LEFT:
      return i18next.t("imagine:left");
    case TextAlign.CENTER:
      return i18next.t("imagine:alignCenter", {
        context: "When aligning text",
      });
    case TextAlign.RIGHT:
      return i18next.t("imagine:right", { context: "When aligning text" });
    default: {
      throw new Error("Unsupported text align: " + type);
    }
  }
};

const getPatternName = (pattern: Pattern | number) => {
  switch (pattern) {
    case Pattern.NONE:
      return i18next.t("noPattern");
    case Pattern.TILES_30_30:
      return i18next.t("concreteTiles30x30");
    case Pattern.HERREGARD_TILES_HORIZONTAL:
      return i18next.t("herregardTilesHorizontal");
    case Pattern.HERREGARD_TILES_VERTICAL:
      return i18next.t("herregardTilesVertical");
    case Pattern.PLANKS_HORIZONTAL:
      return i18next.t("planksHorizontal");
    case Pattern.PLANKS_VERTICAL:
      return i18next.t("planksVertical");
    case Pattern.GRAVEL_LARGE:
      return i18next.t("gravelLarge");
    case Pattern.GRAVEL_SMALL:
      return i18next.t("gravelSmall");
    case Pattern.MIX_SIZES:
      return i18next.t("tilesSizeMix");
    case Pattern.TILES_60_30_HORIZONTAL:
      return i18next.t("tiles60x30Horizontal");
    case Pattern.TILES_60_30_VERTICAL:
      return i18next.t("tiles60x30Vertical");
    case Pattern.TILES_60_60:
      return i18next.t("tiles60x60");
    case Pattern.TILES_90_90:
      return i18next.t("tiles90x90");
    case Pattern.TILES_10_10:
      return i18next.t("tiles10x10");
    case Pattern.BASKET_WEAVE_DOUBLE:
      return i18next.t("basketWeaveDouble");
    case Pattern.HERRINGBONE:
      return i18next.t("herringBonePattern");
    case Pattern.THREE_CONNECTED:
      return i18next.t("threeConnectedPattern");
    default: {
      return "Not ready yet " + pattern;
      //throw new Error("Unsupported pattern: " + pattern);
    }
  }
};

export const ShapeText = {
  getBorderTypeName,
  getTextModeName,
  getTextAlignName,
  getPatternName,
};
