import {
  MembershipSelector,
  StripeCheckout,
  SubscriptionCoupon,
  SubscriptionTools,
} from "imagine-memberships";
import { UserSelector } from "imagine-users";
import { useAppDispatch, useAppSelector } from "../../store";
import { useMemo, useState } from "react";
import { Alert, useNotification } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { Api } from "imagine-essentials";

export const UpdatePaymentInformationContainer = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const notification = useNotification();

  const [coupons, setCoupons] = useState<SubscriptionCoupon[]>([]);

  const clientSecret = useAppSelector(MembershipSelector.getClientSecret);
  const customer = useAppSelector(MembershipSelector.getCustomer);

  // const purchaseInvoice = useAppSelector(MembershipSelector.getPurchaseInvoice);
  // const purchasedSubscription = useAppSelector(
  //   MembershipSelector.getPurchasedSubscription
  // );
  // const upcomingInvoice = useAppSelector(MembershipSelector.getUpcomingInvoice);

  // const purchasedDisplayProduct = useMemo(() => {
  //   if (purchaseInvoice && purchasedSubscription && upcomingInvoice) {
  //     return SubscriptionTools.getInvoiceDisplayProduct(
  //       purchaseInvoice,
  //       purchasedSubscription
  //     );
  //   }
  //   return undefined;
  // }, [coupons, purchaseInvoice, purchasedSubscription, upcomingInvoice]);

  return (
    <>
      {clientSecret && (
        <Alert color="accent" className="mt-xl">
          <StripeCheckout
            // product={purchasedDisplayProduct}
            // invoice={purchaseInvoice || undefined}
            clientSecret={clientSecret}
            returnUrl={Api.getHost() + "app/payment-updated"}
            updatePayment
            customer={customer}
            user={user}
          />
        </Alert>
      )}
    </>
  );
};
