import { Api, DataResponse, SentryReporter } from "imagine-essentials";
import {
  DbObject,
  ObjectCategory,
  ObjectOperations,
  ObjectSchema,
  ObjectSearchFilter,
} from "..";
import { async } from "rxjs";

const search = async (category?: ObjectCategory, name?: string) => {
  // setMarkedTemplate(null);

  const filter: ObjectSearchFilter = {};
  if (name) filter.name = name;
  if (category !== undefined && category !== ObjectCategory._SIZE)
    filter.category = category;
  const responsePublic = await Api.post("api/objects/search", filter);
  if (responsePublic.success) {
    return ObjectOperations.fromDbFormats(responsePublic.data);
  }
};

const getMultipleObjectTemplates = async (ids: number[]) => {
  if (ids.length === 0)
    return {
      success: true,
      data: [],
    };
  const result = await Api.get("api/objects/ids/" + ids.join(","));
  const response: DataResponse<ObjectSchema[]> = {
    success: result.success,
  };
  if (result.success) {
    response.data = result.data.map((object: never) => {
      return ObjectOperations.fromDbFormat(object as DbObject);
    }) as ObjectSchema[];
  } else {
    SentryReporter.captureException("Failed to get multiple object templates", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const addObjectTemplate = async (object: ObjectSchema) => {
  const result = await Api.post(
    "api/objects/add",
    ObjectOperations.toDbFormat(object)
  );
  const response: DataResponse<ObjectSchema> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = ObjectOperations.fromDbFormat(result.data as DbObject);
  } else {
    if (result.status === 403) {
      SentryReporter.captureException(
        "User was signed out when adding object",
        { Result: JSON.stringify(result) }
      );
    } else {
      SentryReporter.captureException("Failed to add object template", {
        Result: JSON.stringify(result),
      });
    }
  }
  return response;
};

const updateObjectTemplate = async (object: ObjectSchema) => {
  const result = await Api.post(
    "api/objects/update/" + object.id,
    ObjectOperations.toDbFormat(object)
  );
  const response: DataResponse<ObjectSchema> = {
    success: result.success,
  };
  if (result.success && result.data) {
    response.data = ObjectOperations.fromDbFormat(result.data as DbObject);
  } else {
    if (result.status === 403) {
      SentryReporter.captureException(
        "User was signed out when updating object",
        { Result: JSON.stringify(result) }
      );
    } else {
      SentryReporter.captureException("Failed to update object template", {
        Result: JSON.stringify(result),
      });
    }
  }
  return response;
};

const getObjectNames = async (language: string) => {
  const result = await Api.get("api/objects/names/" + language);
  const response: DataResponse<ObjectSchema[]> = {
    success: result.success,
  };
  if (result.success) {
    response.data = ObjectOperations.fromDbFormats(result.data);
  } else {
    SentryReporter.captureException("Failed to get missing object names", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const updateObjectName = async (
  objectId: number,
  name: string,
  language: string
) => {
  const result = await Api.post("api/objects/" + objectId + "/name", {
    name,
    language,
  });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    if (result.status === 403) {
      SentryReporter.captureException(
        "User was signed out when updating object name",
        { Result: JSON.stringify(result) }
      );
    } else {
      SentryReporter.captureException("Failed to update object name", {
        Result: JSON.stringify(result),
      });
    }
  }
  return response;
};

export const ObjectsApi = {
  search,
  getMultipleObjectTemplates,
  addObjectTemplate,
  updateObjectTemplate,
  getObjectNames,
  updateObjectName,
};
