import { useCallback, useEffect, useState } from "react";
import { Delimiter, Point, Size, Tools } from "imagine-essentials";
import { CanvasOperations, Measure } from "draw";
import { UnitScale } from "project";
import { useTranslation } from "react-i18next";

interface Props {
  size: Size;
  zeroReference: Point;
  zoom: number;
  color: string;
  unitScale: UnitScale;
  delimiter: Delimiter;
}

type Step = {
  value: number;
  width: number;
  start: number;
  color: string;
};

/**
 * Displays gridlines on the board.
 * @param props
 */
export const Scale = (props: Props) => {
  const { t } = useTranslation();
  const [steps, setSteps] = useState<Step[]>([]);
  const [offset, setOffset] = useState(0);
  // The x position where the center of the scale should be placed (to align with grid)
  const [labelXPos, setLabelXPos] = useState(0);
  const [unitText, setUnitText] = useState("m");

  /**
   * Get the horixontal center of the scale. This might not be the board center, because the scale should fit the grid pattern
   * @param stepPx
   */
  const getXCenter = useCallback(
    (stepPx: number) => {
      let xCenter = props.size.width / 2;
      let xStepCenterBefore = props.zeroReference.x;
      let xStepCenterAfter = props.zeroReference.x;
      if (props.zeroReference.x < xCenter) {
        for (let i = props.zeroReference.x; i < xCenter; i += stepPx) {
          xStepCenterBefore = i;
        }
        xStepCenterAfter = xStepCenterBefore + stepPx;
      }

      if (props.zeroReference.x > xCenter) {
        for (let i = props.zeroReference.x; i > xCenter; i -= stepPx) {
          xStepCenterAfter = i;
        }
        xStepCenterBefore = xStepCenterBefore - stepPx;
      }

      if (
        props.zeroReference.x - xStepCenterBefore <
        xStepCenterAfter - props.zeroReference.x
      ) {
        xCenter = xStepCenterBefore;
      } else {
        xCenter = xStepCenterAfter;
      }
      return xCenter;
    },
    [props.size.width, props.zeroReference.x]
  );

  const updateSteps = useCallback(() => {
    const step = CanvasOperations.getGridCellSize(props.zoom, props.unitScale);
    let unit = "m";
    let stepValue = step;
    if (props.unitScale === UnitScale.IMPERIAL) {
      const stepImperial = Measure.convertToImperial(step);
      if (stepImperial.yards > 0 || stepImperial.feet >= 3) {
        stepValue = stepImperial.yards + stepImperial.feet / 3;
        unit = t("draw:yardsUnitShort");
      } else if (stepImperial.feet > 0 || stepImperial.inches >= 6) {
        stepValue = stepImperial.feet + stepImperial.inches / 12;
        unit = t("draw:feetUnitShort");
      } else {
        stepValue = stepImperial.inches;
        unit = t("draw:inchesUnitShort");
      }
    }
    setUnitText(unit);
    const stepPx = CanvasOperations.unitToPixel(step, props.zoom);
    const xCenter = getXCenter(stepPx);

    const newSteps: Step[] = [];
    const pxWidth = CanvasOperations.unitToPixel(step, props.zoom);
    const newOffset = xCenter - 3 * stepPx;
    setLabelXPos(xCenter + 3 * stepPx);
    for (let i = 1; i <= 6; i++) {
      newSteps.push({
        value: Tools.round(stepValue * i, 3),
        width: pxWidth,
        start:
          CanvasOperations.unitToPixel(step * (i - 1), props.zoom) + newOffset,
        color: i % 2 === 1 ? "white" : props.color,
      } as Step);
    }
    setSteps(newSteps);
    setOffset(newOffset);
  }, [getXCenter, props.color, props.unitScale, props.zoom]);

  useEffect(() => {
    updateSteps();
  }, [updateSteps]);

  return (
    <svg
      id="scale"
      width={props.size.width}
      height={50}
      x={0}
      y={props.size.height - 50}
    >
      <rect width={props.size.width} height={100} fill={"white"} opacity={0} />
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        x={offset}
        y={10}
        fill={props.color}
        fontSize={10}
      >
        0
      </text>
      <text
        textAnchor="middle"
        dominantBaseline="middle"
        x={labelXPos}
        y={40}
        fill={props.color}
        fontSize={10}
      >
        {unitText}
      </text>
      {steps.map((step: Step, index: number) => (
        <g key={index.toString()}>
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            x={step.start + step.width}
            y={10}
            fill={props.color}
            fontSize={10}
          >
            {step.value}
          </text>

          <rect
            x={step.start}
            y={20}
            width={step.width}
            height={10.5}
            stroke={props.color}
            strokeWidth={0.5}
            fill={step.color}
          />
        </g>
      ))}
    </svg>
  );
};
