import { Pages } from "../../enums";
import { LayoutSelector, useAppSelector } from "../../store";
import {
  FeedbackPageContainer,
  LogInModalContainer,
  OpenPlanPageContainer,
  PlantCollectionOverviewPageContainer,
  PlantCollectionPageContainer,
  PlantEditorModalContainer,
  PlantInfoPageContainer,
  PlantPurchasePageContainer,
  PlantSearchModalContainer,
  PlantingPlanPageContainer,
  ReferenceImageDistanceModalContainer,
  ReferenceImageModalContainer,
  RenameObjectModalContainer,
  ResetPasswordModalContainer,
  SaveObjectModalContainer,
  SavePlanModalContainer,
  SceneryPageContainer,
  SignUpModalContainer,
  TextShapeModalContainer,
  UserAccountModalContainer,
  UserPlantsPageContainer,
  UserPreferencesModalContainer,
  VersionNotificationPageContainer,
} from "../page-containers";
import { MapLocationModalContainer } from "../page-containers/MapLocationModalContainer";
import { PlantListPageContainer } from "../page-containers/PlantListPageContainer";

/**
 * Displays the currently visible plant.
 */
const PageContainer = () => {
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  return (
    <>
      <div>
        <PlantSearchModalContainer />
        {displayedPage === Pages.PLANT_COLLECTIONS_OVERVIEW && (
          <PlantCollectionOverviewPageContainer />
        )}
        <PlantCollectionPageContainer />
        <PlantInfoPageContainer />
        {displayedPage === Pages.PLANT_LIST && <PlantListPageContainer />}
        <LogInModalContainer />
        <SignUpModalContainer />
        <ResetPasswordModalContainer />
        <UserAccountModalContainer />
        {displayedPage === Pages.MAP_LOCATION && <MapLocationModalContainer />}
        <UserPlantsPageContainer />
        {displayedPage === Pages.USER_PREFERENCES && (
          <UserPreferencesModalContainer />
        )}
        {displayedPage === Pages.OPEN_PLAN && <OpenPlanPageContainer />}
        {displayedPage === Pages.PLANT_EDITOR && <PlantEditorModalContainer />}
        {displayedPage === Pages.SAVE_OBJECT && <SaveObjectModalContainer />}
        {displayedPage === Pages.RENAME_OBJECT && (
          <RenameObjectModalContainer />
        )}
        {/* Container must be present to control loading message */}
        <SavePlanModalContainer />
        {displayedPage === Pages.PLANTING_PLAN && <PlantingPlanPageContainer />}
        {displayedPage === Pages.FEEDBACK && <FeedbackPageContainer />}
        <VersionNotificationPageContainer />
        {displayedPage === Pages.TEXT_SHAPE_TEXT && <TextShapeModalContainer />}
        {displayedPage === Pages.SCENERY_PAGE && <SceneryPageContainer />}
        {displayedPage === Pages.REFERENCE_IMAGE_PAGE && (
          <ReferenceImageModalContainer />
        )}
        {displayedPage === Pages.REFERENCE_IMAGE_DISTANCE_PAGE && (
          <ReferenceImageDistanceModalContainer />
        )}
        {/* {displayedPage === Pages.PLANT_PURCHASE_PAGE && (
          <PlantPurchasePageContainer />
        )} */}
      </div>
    </>
  );
};

export default PageContainer;
