import { useTranslation } from "react-i18next";
import { LeftSidebar, Sidebar } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
  CanvasActions,
  ObjectEditorSelector,
} from "../../store";
import {
  GroupsContainer,
  LayersContainer,
  ObjectItemsContainer,
  ObjectLibraryContainer,
  PlantLibraryContainer,
} from "..";
import { useCallback, useMemo, useState } from "react";

export const LeftSidebarContainer = () => {
  const { t } = useTranslation();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const open = useAppSelector(LayoutSelector.getSidebarLeftOpen);
  const activeTab = useAppSelector(LayoutSelector.getSidebarLeftActiveTab);
  const dispatch = useAppDispatch();
  const [width, setWidth] = useState(250);

  const toggle = useCallback(
    (tab: string) => {
      if (activeTab === tab) {
        dispatch(LayoutActions.setSidebarLeftActiveTab(""));
        dispatch(CanvasActions.setBlockedLeft(0));
      } else {
        dispatch(LayoutActions.setSidebarLeftActiveTab(tab));
        dispatch(CanvasActions.setBlockedLeft(width));
      }
    },
    [activeTab, dispatch, width]
  );

  const sidebarTabs = useMemo(() => {
    if (objectMode) {
      return [
        {
          id: "objectItems",
          title: t("objectItems"),
          icon: "shapes",
          onClick: () => {
            toggle("objectItems");
          },
        },
      ];
    } else {
      return [
        {
          id: "plantLibrary",
          title: t("plantLibrary"),
          icon: "flower-2",
          onClick: () => {
            toggle("plantLibrary");
          },
        },
        {
          id: "objectLibrary",
          title: t("objectLibrary"),
          icon: "parasol",
          onClick: () => {
            toggle("objectLibrary");
          },
        },
        {
          id: "layers",
          title: t("layers"),
          icon: "layers",
          onClick: () => {
            toggle("layers");
          },
        },
        {
          id: "groups",
          title: t("groups"),
          icon: "group",
          onClick: () => {
            toggle("groups");
          },
        },
      ];
    }
  }, [objectMode, t, toggle]);

  return (
    <Sidebar
      open={open}
      tabs={sidebarTabs}
      activeTab={activeTab}
      position="left"
      onChangeWidth={setWidth}
      elementId="left-sidebar"
    >
      <PlantLibraryContainer visible={activeTab === "plantLibrary"} />
      <ObjectLibraryContainer visible={activeTab === "objectLibrary"} />
      <LayersContainer visible={activeTab === "layers"} />
      <GroupsContainer visible={activeTab === "groups"} />
      {activeTab === "objectItems" && <ObjectItemsContainer visible />}
    </Sidebar>
  );
};
