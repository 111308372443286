import { StyleSheet } from "@react-pdf/renderer";

const defaultFontSize = "10pt";
const smallFontSize = "8pt";

export const PlantingPlanStyle = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#ffffff",
    padding: "1.5cm",
    paddingBottom: "1.5cm",
  },
  drawing: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  plantLabel: {
    fontSize: "10pt",
  },
  footer: {
    height: "3cm",
    borderWidth: "0.03cm",
    borderColor: "black",
    flexDirection: "row",
    padding: "0.25cm",
  },
  header: {
    height: "3cm",
    flexDirection: "row",
    padding: "0.25cm",
    marginBottom: "1cm",
  },
  customLogo: {},
  gsLogo: {},

  planInfo: {
    flex: 1,
    flexDirection: "column",
  },
  title: {
    fontSize: "14pt",
  },
  subTitle: {
    fontSize: "12pt",
    paddingTop: "0.25cm",
  },
  scaleRatio: {
    fontSize: "10pt",
  },
  plantList: {
    flexDirection: "column",
  },
  plantName: {
    fontSize: "12pt",
    marginBottom: "5pt",
  },
  filler1: {
    flex: 1,
  },
  filler2: {
    flex: 2,
  },
});
