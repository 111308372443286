import React from "react";
import { Pattern, PatternTemplate } from "..";
import { ColorUtils, Point } from "imagine-essentials";
import { Colors } from "project";

type Props = {
  id?: string;
  zoom?: number; // Needed to show proper unit size in shape creators
  color?: string;
  startPosition?: Point;
  pattern?: number;
};

export const ShapePattern = (props: Props) => {
  const getPrimaryColor = () => {
    if (props.color === undefined) return Colors.grey[3];
    else return props.color;
  };
  const getDarkerColor = (steps: number = 5) => {
    if (props.color === undefined)
      return ColorUtils.darkenColor(Colors.grey[3], 0.7);
    return ColorUtils.darkenColor(props.color, 0.7);
    // if (props.color === undefined) return Colors.getShiftedColor(Colors.grey[3], steps);
    // else return Colors.getShiftedColor(props.color, steps);
  };

  const getLighterColor = (steps: number = 5) => {
    if (props.color === undefined)
      return ColorUtils.lightenColor(Colors.grey[3], 1.4);
    return ColorUtils.lightenColor(props.color, 1.4);
  };

  return (
    <>
      {props.pattern === Pattern.TILES_30_30 && (
        // Tiles 30x30cm
        <PatternTemplate
          id={Pattern.TILES_30_30 + "-" + props.id}
          unitSize={{ width: 0.3, height: 0.3 }}
          viewBox="0 0 30 30"
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <>
            <rect width="30" height="30" fill={getDarkerColor()}></rect>
            <rect width="29" height="29" fill={getPrimaryColor()}></rect>
          </>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.HERREGARD_TILES_HORIZONTAL && (
        // Herregårdssten joint 21x14cm
        <PatternTemplate
          id={Pattern.HERREGARD_TILES_HORIZONTAL + "-" + props.id}
          viewBox="0,0,21,28"
          unitSize={{ width: 0.21, height: 0.28 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="21" height="28" fill={getDarkerColor()}></rect>

          <rect width="20.5" height="13.5" fill={getPrimaryColor()}></rect>

          <rect
            width="10.25"
            height="13.5"
            x="0"
            y="14"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="10.25"
            height="13.5"
            x="10.75"
            y="14"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.HERREGARD_TILES_VERTICAL && (
        // Herregårdssten joint 21x14cm
        <PatternTemplate
          id={Pattern.HERREGARD_TILES_VERTICAL + "-" + props.id}
          viewBox="0,0,28,21"
          unitSize={{ width: 0.28, height: 0.21 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="28" height="21" fill={getDarkerColor()}></rect>

          <rect width="13.5" height="20.5" fill={getPrimaryColor()}></rect>

          <rect
            width="13.5"
            height="10.25"
            x="14"
            y="0"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="13.5"
            height="10.25"
            x="14"
            y="10.75"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.PLANKS_HORIZONTAL && (
        // Wooden planks
        <PatternTemplate
          id={Pattern.PLANKS_HORIZONTAL + "-" + props.id}
          viewBox="0,0,50,25"
          unitSize={{ width: 0.5, height: 0.25 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="50" height="25" fill={getPrimaryColor()}></rect>

          <rect width="50" height="1" y="24" fill={getDarkerColor()}></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.PLANKS_VERTICAL && (
        // Wooden planks
        <PatternTemplate
          id={Pattern.PLANKS_VERTICAL + "-" + props.id}
          viewBox="0,0,25,50"
          unitSize={{ width: 0.25, height: 0.5 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="25" height="50" fill={getPrimaryColor()}></rect>

          <rect width="1" height="50" x="24" fill={getDarkerColor()}></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.MIX_SIZES && (
        // Tiles mixed sizes
        <PatternTemplate
          id={Pattern.MIX_SIZES + "-" + props.id}
          viewBox="0,0,100,100"
          unitSize={{ width: 1, height: 1 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="100" height="100" fill={getDarkerColor()}></rect>

          <rect width="19.5" height="14.5" fill={getPrimaryColor()}></rect>
          <rect
            width="29.5"
            height="14.5"
            y="15"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="19.5"
            height="49.5"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect width="19.5" height="20" y="80" fill={getPrimaryColor()}></rect>

          <rect
            width="9.5"
            height="14.5"
            x="20"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="9.5"
            height="9.5"
            x="20"
            y="30"
            fill={getPrimaryColor()}
          ></rect>

          <rect
            width="29.5"
            height="39.5"
            x="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="59.5"
            height="59.5"
            x="20"
            y="40"
            fill={getPrimaryColor()}
          ></rect>

          <rect
            width="39.5"
            height="29.5"
            x="60"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="19.5"
            height="9.5"
            x="60"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="20"
            height="49.5"
            x="80"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="19.5"
            height="19.5"
            x="80"
            y="80"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.TILES_60_30_HORIZONTAL && (
        // Tiles 60x30
        <PatternTemplate
          id={Pattern.TILES_60_30_HORIZONTAL + "-" + props.id}
          viewBox="0,0,60,60"
          unitSize={{ width: 0.6, height: 0.6 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="60" height="60" fill={getDarkerColor()}></rect>

          <rect width="59.5" height="29.5" fill={getPrimaryColor()}></rect>
          <rect
            width="29.25"
            height="29.5"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="30"
            height="29.5"
            x="30"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.TILES_60_30_VERTICAL && (
        // Tiles 60x30
        <PatternTemplate
          id={Pattern.TILES_60_30_VERTICAL + "-" + props.id}
          viewBox="0,0,60,60"
          unitSize={{ width: 0.6, height: 0.6 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="60" height="60" fill={getDarkerColor()}></rect>

          <rect width="29.5" height="59.5" fill={getPrimaryColor()}></rect>
          <rect
            width="29.5"
            height="29.25"
            x="30"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="29.5"
            height="30"
            x="30"
            y="30"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.TILES_60_60 && (
        // Tiles 60x60
        <PatternTemplate
          id={Pattern.TILES_60_60 + "-" + props.id}
          viewBox="0,0,60,60"
          unitSize={{ width: 0.6, height: 0.6 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="60" height="60" fill={getDarkerColor()}></rect>

          <rect width="59.5" height="59.5" fill={getPrimaryColor()}></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.TILES_90_90 && (
        // Tiles 90x90
        <PatternTemplate
          id={Pattern.TILES_90_90 + "-" + props.id}
          viewBox="0,0,90,90"
          unitSize={{ width: 0.9, height: 0.9 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="90" height="90" fill={getDarkerColor()}></rect>

          <rect width="89.5" height="89.5" fill={getPrimaryColor()}></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.TILES_10_10 && (
        // Tiles 10x10
        <PatternTemplate
          id={Pattern.TILES_10_10 + "-" + props.id}
          viewBox="0,0,10,10"
          unitSize={{ width: 0.1, height: 0.1 }}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="10" height="10" fill={getLighterColor()}></rect>

          <rect width="9.5" height="9.5" fill={getPrimaryColor()}></rect>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.GRAVEL_SMALL && (
        <PatternTemplate
          id={Pattern.GRAVEL_SMALL + "-" + props.id}
          unitSize={{ width: 0.5, height: 0.5 }}
          viewBox={"0 0 100 100"}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="100" height="100" fill={getPrimaryColor()}></rect>

          <path
            d="M 1.607,0.9599 C -0.4832,3.129 0.4068,5.511 4.098,4.831 5.828,-0.6903 4.378,0.2098 1.607,0.9599 Z M 40.9,65.91 C 38.81,68.08 39.7,70.46 43.39,69.78 45.12,64.26 43.67,65.16 40.9,65.91 Z M 85.43,2.38 C 85.43,2.38 87.98,5.251 84.11,6.172 84.11,6.172 77.63,2.17 85.43,2.38 Z M 16.75,33.65 C 16.75,33.65 19.29,36.52 15.43,37.44 15.43,37.44 8.944,33.44 16.75,33.65 Z M 97.61,52.23 C 94.74,50.46 91.54,55.33 94.95,56.47 97.43,57.63 99.67,53.42 97.61,52.23 Z M 44.15,93.92 C 41.29,92.15 38.09,97.02 41.5,98.16 43.98,99.32 46.22,95.11 44.15,93.92 Z M 54.34,40.21 C 51.5,38.59 51,43.17 52.27,44.04 58.16,42.42 55.15,41.14 54.34,40.21 Z M 46.32,8.139 C 43.48,6.519 42.98,11.1 44.25,11.97 50.14,10.35 47.13,9.069 46.32,8.139 Z M 88.73,28.69 C 85.5,33.82 88.17,33.95 91.39,33.62 93.13,30.22 94.4,27.14 88.73,28.69 Z M 5.881,63.44 C 2.651,68.57 5.321,68.7 8.533,68.37 10.28,64.97 11.55,61.89 5.881,63.44 Z M 75.28,83.63 C 75.28,83.63 77.83,86.5 73.96,87.42 73.96,87.42 67.48,83.42 75.28,83.63 Z M 60.58,62.52 C 60.58,62.52 63.13,65.39 59.26,66.31 59.26,66.31 52.78,62.31 60.58,62.52 Z M 25.48,78.16 C 23.39,80.33 24.28,82.71 27.97,82.03 29.7,76.51 28.25,77.41 25.48,78.16 Z M 69.56,52.92 C 66.88,51.77 65.02,54.48 67.68,57.11 69.42,56.22 71.22,55.31 69.56,52.92 Z M 64.62,17.34 C 61.78,15.72 61.28,20.3 62.55,21.17 68.44,19.55 65.43,18.27 64.62,17.34 Z M 34.77,43.57 C 31.91,41.8 28.71,46.67 32.12,47.81 34.6,48.97 36.84,44.76 34.77,43.57 Z M 22.68,16.25 C 22.68,16.25 25.23,19.12 21.36,20.04 21.36,20.04 14.88,16.04 22.68,16.25 Z"
            fill={getDarkerColor(2)}
          ></path>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.GRAVEL_LARGE && (
        <PatternTemplate
          id={Pattern.GRAVEL_LARGE + "-" + props.id}
          unitSize={{ width: 0.5, height: 0.5 }}
          viewBox={"0 0 100 100"}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="100" height="100" fill={getPrimaryColor()}></rect>

          <path
            d="M 23.26,68.08 C 25.31,65.57 28.66,64.57 41.61,74.75 39.35,79.43 37.39,81.69 34.37,92.44 32.04,93.2 17.31,73.44 23.26,68.08 Z M 12.95,33.59 C 8.168,32.42 3.879,33.51 0.3577,38.18 1.278,42.3 -1.112,49.38 3.678,50.01 8.229,50.64 15.36,63.34 18.17,46.95 18.17,46.95 13.8,38.66 12.95,33.59 Z M 12.46,75.59 C 23.33,81.11 14.48,86.79 9.712,88.82 4.752,84.71 -0.6437,75.32 12.46,75.59 Z M 55.18,47.24 C 66.05,52.76 57.2,58.44 52.42,60.47 47.47,56.36 42.07,46.97 55.18,47.24 Z M 58.93,1.564 C 54.86,-3.569 42.84,10.18 41.26,18.4 45.02,22.24 48.44,24.33 55.99,20.47 58.81,12.99 62.36,5.076 58.93,1.564 Z M 67.09,44.66 C 61.33,47.78 72.77,62 80.59,64.99 85.03,61.95 87.68,58.95 85.18,50.85 78.31,46.77 71.14,41.91 67.09,44.66 Z M 26.42,7.96 C 37.29,13.48 28.44,19.16 23.67,21.19 18.71,17.08 13.32,7.69 26.42,7.96 Z M 94.36,76.53 C 89.58,75.36 85.29,76.45 81.77,81.12 82.69,85.24 80.3,92.31 85.09,92.94 89.64,93.57 96.77,106.3 99.58,89.88 99.58,89.88 95.21,81.6 94.36,76.53 Z M 62.65,69.92 C 45.12,80.32 52.69,79.8 58.87,86.53 62.93,79.93 82.25,67.56 62.65,69.92 Z M 86.57,6.437 C 89.81,6.397 92.73,8.327 93.25,24.79 88.22,26.07 85.23,26.02 75.05,30.62 72.97,29.37 78.64,5.327 86.57,6.437 Z M 34.38,29.01 C 28.29,26.6 24.75,44.51 27.49,52.42 32.67,53.87 36.68,53.98 41.27,46.86 39.99,38.97 39.09,30.34 34.38,29.01 Z"
            fill={getDarkerColor()}
          ></path>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.BASKET_WEAVE_DOUBLE && (
        <PatternTemplate
          id={Pattern.BASKET_WEAVE_DOUBLE + "-" + props.id}
          unitSize={{ width: 0.06, height: 0.06 }}
          viewBox={"-0.05 -0.05 6 6"}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect
            x="-0.05"
            y="-0.05"
            width="6"
            height="1"
            fill={getPrimaryColor()}
          />
          <rect x="-0.05" y="2" width="6" height="2" fill={getPrimaryColor()} />
          <rect x="-0.05" y="5" width="6" height="1" fill={getPrimaryColor()} />

          <rect
            x="-0.05"
            y="-0.05"
            width="1"
            height="6"
            fill={getPrimaryColor()}
          />
          <rect x="2" y="-0.05" width="2" height="6" fill={getPrimaryColor()} />
          <rect x="5" y="-0.05" width="1" height="6" fill={getPrimaryColor()} />

          {/* Horizontal lines */}
          <path
            d="M 0,0 L 2,0 M 4,0 L 6,0 M 0,1 L 2,1 M 4,1 L 6,1 M 1,2 L 5,2 M 1,3 L 5,3 M 1,4 L 5,4 M 0,5 L 2,5 M 4,5 L 6,5"
            strokeWidth="0.1"
            stroke={getDarkerColor()}
          ></path>
          {/* Vertical lines */}
          <path
            d="M 0,1 L 0,5 M 1,1 L 1,5 M 2,0 L 2,2 M 2,4 L 2,6 M 3,0 L 3,2 M 3,4 L 3,6 M 4,0 L 4,2 M 4,4 L 4,6 M 5,1 L 5,5"
            strokeWidth="0.1"
            stroke={getDarkerColor()}
          ></path>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.HERRINGBONE && (
        <PatternTemplate
          id={Pattern.HERRINGBONE + "-" + props.id}
          unitSize={{ width: 0.21, height: 0.42 }}
          viewBox={"0 0 21 42"}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="21" height="42" fill={getPrimaryColor()}></rect>

          <path
            d="M 0,0 L 21,21 L 0,42 M 21,0 L 10.5,10.5 M 10.5,31.5 L 0,21"
            strokeWidth="0.5"
            stroke={getDarkerColor()}
            fill="none"
          ></path>
        </PatternTemplate>
      )}
      {props.pattern === Pattern.THREE_CONNECTED && (
        <PatternTemplate
          id={Pattern.THREE_CONNECTED + "-" + props.id}
          unitSize={{ width: 0.42, height: 0.42 }}
          viewBox={"0 0 42 42"}
          startPosition={props.startPosition}
          zoom={props.zoom}
        >
          <rect width="42" height="42" fill={getDarkerColor()}></rect>

          <rect
            width="6.5"
            height="20.5"
            x="0.25"
            y="0.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="6.5"
            height="20.5"
            x="7.25"
            y="0.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="6.5"
            height="20.5"
            x="14.25"
            y="0.25"
            fill={getPrimaryColor()}
          ></rect>

          <rect
            width="20.5"
            height="6.5"
            x="0.25"
            y="21.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="20.5"
            height="6.5"
            x="0.25"
            y="28.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="20.5"
            height="6.5"
            x="0.25"
            y="35.25"
            fill={getPrimaryColor()}
          ></rect>

          <rect
            width="20.5"
            height="6.5"
            x="21.25"
            y="0.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="20.5"
            height="6.5"
            x="21.25"
            y="7.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="20.5"
            height="6.5"
            x="21.25"
            y="14.25"
            fill={getPrimaryColor()}
          ></rect>

          <rect
            width="6.5"
            height="20.5"
            x="21.25"
            y="21.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="6.5"
            height="20.5"
            x="28.25"
            y="21.25"
            fill={getPrimaryColor()}
          ></rect>
          <rect
            width="6.5"
            height="20.5"
            x="35.25"
            y="21.25"
            fill={getPrimaryColor()}
          ></rect>
        </PatternTemplate>
      )}
    </>
  );
};
