interface Props {
  children?: React.ReactNode;
  direction?: "horizontal" | "vertical";
}

export const ButtonGroup = (props: Props) => {
  return (
    <div
      className={
        "btn-group" +
        (props.direction === "vertical" ? " btn-group-vertical" : "")
      }
    >
      {props.children}
    </div>
  );
};
