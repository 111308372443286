import { useCallback, useEffect } from "react";
import {
  CanvasActions,
  CanvasSelector,
  LayoutActions,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { EventProcess, Item, ItemOperations } from "draw";
import { NotificationType, useNotification } from "imagine-ui";
import { PlanThunks } from "../../utils";
import { useTranslation } from "react-i18next";
import { ObjectThunks } from "../../utils/ObjectThunks";
import { UserRole, UserSelector } from "imagine-users";
import { Pages } from "../../enums";

export const KeyboardShortcutContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const { t } = useTranslation();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const planSettingsCanvas = useAppSelector(CanvasSelector.getPlanSettings);
  const planSettingsPlanEditor = useAppSelector(
    PlanEditorSelector.getPlanSettings
  );
  const currentObjectTemplate = useAppSelector(
    ObjectEditorSelector.getCurrentObjectTemplate
  );
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);

  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const selectedItems = objectMode ? selectedObjectItems : selectedPlanItems;

  const savePlan = useCallback(async () => {
    if (user.role === UserRole.NONE || user.role === UserRole.STANDARD) return;
    console.log("Saving plan", planInfo);
    if (planInfo.id === 0) {
      dispatch(LayoutActions.setDisplayedPage(Pages.SAVE_PLAN));
      return;
    }

    dispatch(CanvasActions.setLoadingMessage(t("savingPlan")));
    const planSettings = {
      ...planSettingsCanvas,
      ...planSettingsPlanEditor,
    };

    const errorMesage = await PlanThunks.savePlan(
      dispatch,
      planInfo,
      layers,
      groups,
      items,
      area,
      planSettings,
      referenceImage
    );
    if (errorMesage !== "") {
      notification.add(NotificationType.ERROR, errorMesage);
    }
    dispatch(CanvasActions.setLoadingMessage(""));
  }, [
    area,
    dispatch,
    groups,
    items,
    layers,
    notification,
    planInfo,
    planSettingsCanvas,
    planSettingsPlanEditor,
    t,
  ]);

  const saveObject = useCallback(async () => {
    const errorMessage = await ObjectThunks.saveObject(
      dispatch,
      currentObjectTemplate
    );
    if (errorMessage !== "") {
      notification.add(NotificationType.ERROR, errorMessage);
    }
  }, [currentObjectTemplate, dispatch, notification]);

  const updateItems = useCallback(
    (newItems: Item[]) => {
      if (objectMode) {
        if (newItems.length === 1) {
          dispatch(ObjectEditorActions.updateItem(newItems[0]));
        } else {
          dispatch(ObjectEditorActions.updateMultipleItems(newItems));
        }
      } else {
        if (newItems.length === 1) {
          dispatch(PlanEditorActions.updateItem(newItems[0]));
        } else {
          dispatch(PlanEditorActions.updateMultipleItems(newItems));
        }
      }
    },
    [dispatch, objectMode]
  );

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Shortcuts that requires CTRL to be pressed
      if (event.ctrlKey || event.metaKey) {
        switch (event.key) {
          case "c":
            if (objectMode) {
              dispatch(ObjectEditorActions.copySelectedItems());
            } else {
              dispatch(PlanEditorActions.copySelectedItems());
            }
            break;
          case "v":
            if (objectMode) {
              dispatch(ObjectEditorActions.pasteCopiedItems());
            } else {
              dispatch(PlanEditorActions.pasteCopiedItems());
            }
            break;
          case "z":
            if (objectMode) {
              dispatch(ObjectEditorActions.undo());
            } else {
              dispatch(PlanEditorActions.undo());
            }
            break;
          case "y":
            if (objectMode) {
              dispatch(ObjectEditorActions.redo());
            } else {
              dispatch(PlanEditorActions.redo());
            }
            break;
          case "s":
            event.preventDefault();
            if (objectMode) {
              saveObject();
            } else {
              savePlan();
              //dispatch(PlanEditorActions.redo());
            }
            break;
          case "+":
            event.preventDefault();
            dispatch(CanvasActions.zoomInAuto());
            break;
          case "-":
            event.preventDefault();
            dispatch(CanvasActions.zoomOutAuto());
            break;
          // Add more keyboard shortcuts here
          default:
            break;
        }
      } else {
        // Return if target is an input field
        if (!EventProcess.isInputField(event)) {
          switch (event.key) {
            case "Delete":
              if (objectMode) {
                dispatch(ObjectEditorActions.deleteSelectedItems());
              } else {
                dispatch(PlanEditorActions.deleteSelectedItems());
              }
              break;
            case "ArrowUp": {
              if (selectedItems.length === 0) return;
              const newItems = ItemOperations.moveUpMultiple(
                selectedItems,
                zoom
              );
              updateItems(newItems);
              break;
            }
            case "ArrowRight": {
              if (selectedItems.length === 0) return;
              const newItems = ItemOperations.moveRightMultiple(
                selectedItems,
                zoom
              );
              updateItems(newItems);
              break;
            }
            case "ArrowDown": {
              if (selectedItems.length === 0) return;
              const newItems = ItemOperations.moveDownMultiple(
                selectedItems,
                zoom
              );
              updateItems(newItems);
              break;
            }
            case "ArrowLeft": {
              if (selectedItems.length === 0) return;
              const newItems = ItemOperations.moveLeftMultiple(
                selectedItems,
                zoom
              );
              updateItems(newItems);
              break;
            }
            // Add more keyboard shortcuts here
            default:
              break;
          }
        }
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    dispatch,
    objectMode,
    saveObject,
    savePlan,
    selectedItems,
    updateItems,
    zoom,
  ]);

  return null;
};
