import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { KeyPage } from "imagine-pages";
import { fetchKeyPages } from "./websiteThunks";
import { DataResponse, RequestStatus } from "imagine-essentials";

interface WebsiteState {
  keyPages: KeyPage[];
  keyPagesStatus: RequestStatus;
}

const initialState: WebsiteState = {
  keyPages: [],
  keyPagesStatus: RequestStatus.IDLE,
};

export const websiteSlice = createSlice({
  name: "website",
  initialState,
  reducers: {
    setKeyPages: (state, action: PayloadAction<KeyPage[]>) => {
      state.keyPages = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchKeyPages.fulfilled,
      (state, action: PayloadAction<DataResponse<KeyPage[]>>) => {
        state.keyPages = action.payload.data || [];
        state.keyPagesStatus = RequestStatus.READY;
      }
    );
  },
});

export const WebsiteActions = websiteSlice.actions;

export default websiteSlice.reducer;
