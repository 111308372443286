import { useTranslation } from "react-i18next";
import { Modal, NotificationType, useNotificationData } from "..";

/**
 * This components must be layed out after any other modals (otherwise it will
 * not work together with other modals due to wrong z-indexes)
 */
export const NotificationModal = () => {
  const { t } = useTranslation();
  const data = useNotificationData();

  const title =
    data.type === NotificationType.ERROR ? t("imagine:error") : undefined;

  return (
    <Modal
      visible={data.type !== NotificationType.NONE}
      onClose={data.clear}
      center
      hideHeader
      backdropClassName="notification-backdrop"
      // contentClassName="large-font"
      elementId="notification-dialog"
      title={title}
      confirmText={t("imagine:ok")}
      hideCancel={data.type !== NotificationType.CONFIRM}
      onConfirm={() => data.confirm()}
    >
      {data.message}
    </Modal>
  );
};
