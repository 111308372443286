import { Item, MeasureInput, MonthSlider, PlantSchema } from "draw";
import { Delimiter, Point } from "imagine-essentials";
import { Alert, Label, Modal, NumberInput, Select, Slider } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { SceneryImage } from ".";
import { useEffect, useMemo, useState } from "react";
import { UnitScale } from "project";
import { StringOption } from "imagine-essentials";

interface Props {
  onClose: () => void;
  cameraPosition: Point;
  viewPointA: Point;
  viewPointB: Point;
  items: Item[];
  plantTemplates: PlantSchema[];
  delimiter: Delimiter;
  unitScale: UnitScale;
}

export const SceneryPage = (props: Props) => {
  const { t } = useTranslation();
  const [month, setMonth] = useState(7);
  const [slope, setSlope] = useState(0.4);
  const [bgColor, setBgColor] = useState("");

  const colorOptions = useMemo(() => {
    return [
      {
        value: "",
        label: t("draw:white"),
      },
      {
        value: "scenery-bg-green",
        label: t("draw:green"),
      },
      {
        value: "scenery-bg-brown",
        label: t("draw:brown"),
      },
    ] as StringOption[];
  }, [t]);

  return (
    <Modal
      title={t("plantScenery")}
      visible
      onClose={props.onClose}
      hideFooter
      size="lg"
      elementId="scenery-modal"
    >
      <SceneryImage
        cameraPosition={props.cameraPosition}
        viewPointA={props.viewPointA}
        viewPointB={props.viewPointB}
        items={props.items}
        slope={slope}
        plantTemplates={props.plantTemplates}
        enablePerspective
        month={month}
        className={bgColor}
      />
      <MonthSlider month={month} onChanged={setMonth} />
      <div className="grid columns-2 gap-xl mt-lg">
        <div className="">
          <Label className="mb-sm">{t("plants:slopeOnGround")}</Label>
          <Slider
            value={slope}
            onChange={setSlope}
            min={0}
            max={2}
            stepSize={0.1}
          />
        </div>
        <div className="">
          <Label>{t("backgroundColor")}</Label>
          <Select
            options={colorOptions}
            selected={bgColor}
            onSelectedString={setBgColor}
          />
        </div>
      </div>
    </Modal>
  );
};
