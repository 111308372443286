import {
  FeedbackApi,
  FeedbackModal,
  FeedbackType,
  UserSelector,
} from "imagine-users";
import {
  LayoutActions,
  LayoutSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { Pages } from "../../enums";
import { AppInfo } from "imagine-essentials";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const FeedbackPageContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const displayFeedback =
    useAppSelector(LayoutSelector.getDisplayedPage) === Pages.FEEDBACK;
  const user = useAppSelector(UserSelector.getUser);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const sendFeedback = async (
    description: string,
    feedbackType: FeedbackType,
    customImage?: File
  ) => {
    if (user === null) return;
    setLoading(true);
    const version = AppInfo.getAppVersion();
    const response = await FeedbackApi.sendFeedback(
      description,
      feedbackType,
      version,
      user.id,
      user.role,
      customImage
    );
    if (response.success) {
      if (feedbackType === FeedbackType.QUESTION) {
        setMessage(t("users:thanksForFeedbackQuestion"));
      } else {
        setMessage(t("users:thanksForFeedback"));
      }
    } else if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    }
    setLoading(false);
  };

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      {displayFeedback && (
        <FeedbackModal
          onClose={close}
          onSend={sendFeedback}
          loading={loading}
          message={message}
          errorMessage={errorMessage}
        />
      )}
    </>
  );
};
