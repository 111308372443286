import ImagineConfig from "../../../../../ImagineConfig";

const getPublicKey = () => {
  if (window.location.hostname.includes("gardensketcher")) {
    return ImagineConfig.stripePublicKeyLive;
  } else return ImagineConfig.stripePublicKeyTest;
};

export const StripeTools = {
  getPublicKey,
};
