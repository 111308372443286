import { PlantCategory, PlantModelText, PlantSchema } from "draw";
import { PlantCollection, PlantCollectionType, PlantText } from "..";
import { Option } from "imagine-essentials";
import { t } from "i18next";
import { Colors, HardinessZoneRHS, HardinessZoneUSDA } from "project";
import * as _ from "lodash";

const getInitialPlant = (hardinessZone?: HardinessZoneUSDA) => {
  return {
    id: 0,
    name: "",
    width: 1,
    leafTemplate: 6,
    leafColor: Colors.green[3],
    leafSeason: [4, 5, 6, 7, 8, 9, 10],
    branchTemplate: 0,
    branchColor: Colors.brown[6],
    flowerTemplate: 0,
    flowerColor: Colors.violet[3],
    flowerSeason: [],
    category: PlantCategory.PERENNIAL,
    fruitTemplate: 0,
    fruitColor: Colors.red[1],
    properties: {
      loamSoil: true,
      drainedSoil: true,
      neutralSoil: true,
    },
    hardinessZoneMin: HardinessZoneUSDA.NONE,
    hardinessZoneMax: HardinessZoneUSDA.NONE,
    hardinessZoneRhs: HardinessZoneRHS.NONE,
  } as PlantSchema;
};

/**
 * Makes a deep copy of the plant object. If copyMode is set it will set the plant id to 0 and remove userId.
 * @param plant
 * @returns
 */
const getPlantCopy = (plant: PlantSchema, copyMode?: boolean) => {
  const copy = _.cloneDeep(plant);
  if (copyMode) {
    copy.id = 0;
    if (copy.userId) {
      delete copy.userId;
    }
  }
  return copy;
};

const getCategoryOptions = (allowAll?: boolean) => {
  const categories: Option[] = [];
  if (allowAll) {
    categories.push({
      value: PlantCategory._SIZE,
      label: t("imagine:all"),
    });
  }
  for (let i = 0; i < PlantCategory._SIZE; i++) {
    categories.push({
      value: i,
      label: PlantModelText.getCategoryText(i),
    } as Option);
  }
  return categories;
};

const getEmptyPlantCollection = () => {
  return {
    name: "",
    description: "",
    type: PlantCollectionType.NONE,
    id: 0,
    userId: 0,
    planId: 0,
  } as PlantCollection;
};

const getPlantCollectionTypes = () => {
  return [
    PlantCollectionType.NONE,
    PlantCollectionType.GOES_WELL_TOGETHER,
    PlantCollectionType.GARDEN_BED,
    PlantCollectionType.FAVORITES,
  ];
};

const getPlantCollectionTypeOptions = () => {
  return getPlantCollectionTypes().map((type: PlantCollectionType) => {
    return {
      value: type,
      label: PlantText.getPlantCollectionTypeText(type),
    } as Option;
  });
};

const getHardinessZoneOptionsUSDA = (emptyText) => {
  const options: Option[] = [];
  for (let i = 0; i < HardinessZoneUSDA._SIZE; i++) {
    options.push({
      value: i,
      label: PlantText.getHardinessZoneTextUSDA(i, emptyText),
    });
  }
  return options;
};

const getHardinessZoneOptionsRHS = (emptyText: string) => {
  const options: Option[] = [];
  for (let i = 0; i < HardinessZoneRHS._SIZE; i++) {
    options.push({
      value: i,
      label: PlantText.getHardinessZoneTextRHS(i, emptyText),
    });
  }
  return options;
};

export const PlantData = {
  getInitialPlant,
  getPlantCopy,
  getCategoryOptions,
  getEmptyPlantCollection,
  getPlantCollectionTypes,
  getPlantCollectionTypeOptions,
  getHardinessZoneOptionsUSDA,
  getHardinessZoneOptionsRHS,
};
