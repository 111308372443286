export enum FeatureKey {
  NAV_FIND_PLANT = 1,
  NAV_CREATE_PLANT = 2,
  NAV_NEW_PLAN = 3,
  NAV_DRAW_RECTANGLE = 4,
  NAV_DRAW_ELLIPSE = 5,
  NAV_DRAW_LINES = 6,
  NAV_DRAW_CURVED_LINES = 7,
  NAV_DRAW_TEXT = 8,
  NAV_DRAW_DIMENSION = 9,
  NAV_TOGGLE_PLANT_HEIGHTS = 10,
  NAV_TOGGLE_HINTS = 11,
  NAV_TOGGLE_PRESENT = 12,
  CHANGE_MONTH = 13,
  NAV_EDIT_AREA = 14,
  NAV_PLANT_LIST = 15,
  NAV_PLANT_DETAILS = 16,
  NAV_SNAPSHOT = 17,
  SELECT_PLANT_FROM_LIBRARY = 18,
  DRAG_INSERT_OBJECT = 19,
  CREATE_GROUP = 20,
  CREATE_LAYER = 21,
  SAVE_OBJECT = 22,
  SAVE_PLANT = 23,
  SAVE_PLAN = 24,
  SAVE_PLAN_AS = 25,
  SAVE_PLANT_COPY = 26,
  SEARCHED_FOR_PLANT = 27,
  INSERT_SEARCHED_PLANT = 28,
  CREATE_PLANT_COLLECTION = 29,
  ADD_PLANT_TO_COLLECTION = 30,
  NAV_PLANTING_PLAN = 31,
  SATELLITE_MAP = 32,
  SELECT_PLANT_FROM_COLLECTION = 33,
  SELECT_PLANT_FROM_PLANT_FINDER = 34,
  REPLACE_PLANTS = 35,
  NAV_CENTER_PLAN = 36,
  START_TOUR_SATELLITE_MAP = 37,
  FINISH_TOUR_SATELLITE_MAP = 38,
  START_TOUR_SHAPES = 39,
  FINISH_TOUR_SHAPES = 40,
  START_TOUR_LAYERS = 41,
  FINISH_TOUR_LAYERS = 42,
  SCENERY = 43,
  REFERENCE_IMAGE = 44,
}
