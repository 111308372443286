// Possible pages to be displayed. Can only display one page at a time.
export enum Pages {
  NONE = 0,
  PLANT_EDITOR = 1,
  SAVE_PLAN = 2,
  OPEN_PLAN = 3,
  PLANT_FINDER = 4,
  PLANT_LIST = 5,
  PLANT_DETAILS = 6,
  PLANT_INFO = 7,
  MAP_LOCATION = 8,
  PLANTING_PLAN = 9,
  PLANT_COLLECTIONS_OVERVIEW = 10,
  FEEDBACK = 11,
  PLANT_COLLECTION = 12,
  LOGIN = 13,
  SIGN_UP = 14,
  RESET_PASSWORD = 15,
  USER_ACCOUNT = 16,
  USER_PLANTS = 17,
  SAVE_OBJECT = 18,
  RENAME_OBJECT = 19,
  USER_PREFERENCES = 20,
  TEXT_SHAPE_TEXT = 21,
  SCENERY_PAGE = 22,
  REFERENCE_IMAGE_PAGE = 23,
  REFERENCE_IMAGE_DISTANCE_PAGE = 24,
  PLANT_PURCHASE_PAGE = 25,
}
