import { useEffect, useMemo, useState } from "react";
import { Button, Icon } from "imagine-ui";
import { EventProcess, Mouse, MouseState } from "draw";

export interface SidebarTab {
  id: string;
  title: string;
  icon: string;
  onClick: () => void;
}

interface Props {
  children?: React.ReactNode[] | React.ReactNode;
  position: "left" | "right";
  tabs: SidebarTab[];
  open: boolean;
  activeTab: string;
  canResize?: boolean;
  minWidth?: number;
  onChangeWidth?: (width: number) => void;
  elementId?: string;
}

export const Sidebar = (props: Props) => {
  const { onChangeWidth } = props;

  const className = "sidebar sidebar-" + props.position;
  const [width, setWidth] = useState(250);
  const [startX, setStartX] = useState<number | null>(null);
  const [startWidth, setStartWidth] = useState(250);

  const style = useMemo(() => {
    if (props.position === "left") {
      return {
        left: props.open ? "48px" : -width - 52 + "px",
        width: width + "px",
      };
    } else if (props.position === "right") {
      return {
        right: props.open ? "0px" : -width + "px",
        width: width + "px",
      };
    }
  }, [props.open, props.position, width]);

  const activeTitle = useMemo(() => {
    const activeTab = props.tabs.find(
      (tab: SidebarTab) => tab.id === props.activeTab
    );
    if (activeTab !== undefined) return activeTab.title;
    return "";
  }, [props.activeTab, props.tabs]);

  const startResize = (event: any) => {
    const pos = EventProcess.getEventPosition(event);
    setStartX(pos.x);
    setStartWidth(width);
  };

  useEffect(() => {
    if (!props.canResize) return;
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed && startX !== null) {
        const diff = state.position.x - startX;
        setWidth(startWidth + diff);
      }
    });

    const releaseObserver = Mouse.release.subscribe(() => {
      setStartX(null);
      if (onChangeWidth) onChangeWidth(width);
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [onChangeWidth, props.canResize, startWidth, startX, width]);

  useEffect(() => {
    if (onChangeWidth) onChangeWidth(width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={className}>
      <div className="sidebar-content" style={style}>
        {props.position === "right" && (
          <div
            className={"border-line" + (props.canResize ? " resizable" : "")}
            onMouseDown={startResize}
            onTouchStart={startResize}
          ></div>
        )}
        <div className="sidebar-panel">
          <div
            className="sidebar-title"
            id={(props.elementId ? props.elementId + "-" : "") + "title"}
          >
            {activeTitle}
          </div>
          <div className="sidebar-body">{props.children}</div>
        </div>
        {props.position === "left" && (
          <div
            className={"border-line" + (props.canResize ? " resizable" : "")}
            onMouseDown={startResize}
            onTouchStart={startResize}
          ></div>
        )}
      </div>
      <div className="sidebar-tabs">
        {props.tabs.map((tab: SidebarTab) => (
          <Button
            color={props.activeTab === tab.id ? "accent" : "dark"}
            className="sidebar-tab-button"
            transparent={props.activeTab !== tab.id}
            key={tab.id}
            onClick={tab.onClick}
            elementId={tab.id + "-sidebar-tab-button"}
          >
            <Icon name={tab.icon} />
          </Button>
        ))}
      </div>
    </div>
  );
};
