import { useEffect } from "react";
import { BoardContainer, HeaderContainer, ToolbarContainer } from "..";
import { useAppDispatch, LayoutActions, useAppSelector } from "../../store";
import PageContainer from "./PageContainer";
import { UserSelector } from "imagine-users";
import { RequestStatus } from "imagine-essentials";
import { Spinner } from "imagine-ui";

export const LayoutContainer = () => {
  const dispatch = useAppDispatch();
  const userStatus = useAppSelector(UserSelector.getStatus);

  useEffect(() => {
    const handleResize = () => {
      dispatch(
        LayoutActions.setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      );
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <div className="app">
      {userStatus === RequestStatus.READY && (
        <>
          <HeaderContainer />
          <ToolbarContainer />
          <BoardContainer />
          <PageContainer />
        </>
      )}
      {userStatus !== RequestStatus.READY && (
        <div className="row h-full-screen w-full items-y-center items-center">
          <Spinner color="accent" size="lg" />
        </div>
      )}
    </div>
  );
};
