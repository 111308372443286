import { useTranslation } from "react-i18next";
import { Modal } from "imagine-ui";
import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
  PlantManagementActions,
  PlanEditorActions,
} from "../../store";
import { LogIn, UserActions, UsersApi } from "imagine-users";
import { useState } from "react";
import { WindowLocation } from "../../utils";
import { MembershipActions } from "imagine-memberships";
import { UserHelpers } from "project";

export const LogInModalContainer = () => {
  const dispatch = useAppDispatch();

  const { t, i18n } = useTranslation();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));

    setErrorMessage("");
    setLoading(false);
    WindowLocation.clearUrlParams();
  };

  const login = async (
    email: string,
    password: string,
    rememberMe: boolean
  ) => {
    setLoading(true);
    const response = await UsersApi.logIn(email, password, rememberMe);
    setLoading(false);
    if (response.apiVersion) {
      dispatch(UserActions.setApiVersion(response.apiVersion));
    }

    if (response.data) {
      if (i18n.language !== response.data.user.language) {
        i18n.changeLanguage(response.data.user.language);
      }
      dispatch(UserActions.setUser(response.data.user));
      dispatch(
        MembershipActions.setCustomerCountry(response.data.user.country)
      );
      dispatch(
        UserActions.setPreferences({
          ...UserHelpers.getDefaultPreferences(response.data.user.country),
          ...response.data.preferences,
        })
      );
      dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
      // Reset to start loading user data
      dispatch(PlantManagementActions.clearPlantCollections());
      dispatch(MembershipActions.clearMemberships());
      dispatch(PlanEditorActions.clearPlan());
    }
    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    }
  };

  const openResetPassword = (email: string) => {
    dispatch(LayoutActions.setDisplayedPage(Pages.RESET_PASSWORD));
  };

  const openSignupModal = () => {
    dispatch(LayoutActions.replaceDisplayedPage(Pages.SIGN_UP));
  };

  return (
    <>
      {displayedPage === Pages.LOGIN && (
        <>
          <Modal
            title={t("users:login")}
            visible
            onClose={close}
            hideCancel
            hideFooter={true}
            discreteHeader
            center
          >
            <LogIn
              onLoginClick={login}
              onForgotPasswordClick={openResetPassword}
              onCreateAccountClick={openSignupModal}
              errorMessage={errorMessage}
              loading={loading}
            />
          </Modal>
        </>
      )}
    </>
  );
};
