import { Api, AppInfo, DataResponse, SentryReporter } from "imagine-essentials";
import {
  Subscription,
  SubscriptionInvoice,
  DisplayProduct,
  Customer,
  CancelReason,
  CheckoutSession,
} from "..";
import i18next from "i18next";

const createSubscription = async (
  displayProduct: DisplayProduct,
  customer: Customer
) => {
  const priceId = displayProduct.pricing[0].id;
  const initialCoupon = displayProduct.pricing[0].coupons.find(
    (c) => c.duration === "once"
  );
  const recurringCoupon = displayProduct.pricing[0].coupons.find(
    (c) => c.duration === "forever"
  );

  const result = await Api.post("api/subscriptions/create", {
    customerInfo: customer,
    priceId,
    initialCouponId: initialCoupon?.id,
    recurringCouponId: recurringCoupon?.id,
  });
  const response: DataResponse<{
    invoice: SubscriptionInvoice;
    clientSecret: string;
  }> = {
    success: result.success,
  };
  if (result.success && result.data !== undefined) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to create subscription", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const createCheckoutSession = async (
  displayProduct: DisplayProduct,
  customer: Customer
) => {
  const priceId = displayProduct.pricing[0].id;
  const initialCoupon = displayProduct.pricing[0].coupons.find(
    (c) => c.duration === "once"
  );
  // const recurringCoupon = displayProduct.pricing[0].coupons.find(
  //   (c) => c.duration === "forever"
  // );

  const result = await Api.post("api/subscriptions/prepare", {
    customerInfo: customer,
    priceId,
    initialCouponId: initialCoupon?.id,
  });
  const response: DataResponse<CheckoutSession> = {
    success: result.success,
  };
  if (result.success && result.data !== undefined) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to create checkout session", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const updatePayment = async () => {
  const result = await Api.post("api/subscriptions/update-payment", {});
  const response: DataResponse<{ clientSecret: string }> = {
    success: result.success,
  };
  if (result.success && result.data !== undefined) {
    response.data = result.data;
  } else {
    SentryReporter.captureException("Failed to update payment", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const createContinuationSubscription = async () => {
  const result = await Api.post("api/subscriptions/create-continuation", {});
  const response: DataResponse<{
    subscription: Subscription;
    invoice: SubscriptionInvoice;
    upcomingInvoice: SubscriptionInvoice;
    clientSecret: string;
  }> = {
    success: result.success,
  };
  if (result.success && result.data !== undefined) {
    response.data = result.data;
    if (response.data?.subscription.couponIds) {
      const couponIds = JSON.parse(result.data.subscription.couponIds);
      response.data.subscription.coupons = couponIds;
    }
  } else {
    SentryReporter.captureException(
      "Failed to create continuation subscription",
      {
        Result: JSON.stringify(result),
      }
    );
  }
  return response;
};

const unsubscripe = async (
  subscriptionId: string,
  reasons: CancelReason[],
  comment: string
) => {
  const result = await Api.post("api/subscriptions/unsubscripe", {
    subscriptionId: subscriptionId,
    reason: reasons,
    comment: comment,
    version: AppInfo.getAppVersion(),
  });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to unsubscripe", {
      "Server response": JSON.stringify(result),
    });
    response.errorMessage = i18next.t("memberships:cancelSubscriptionFailed");
  }
  return response;
};

const extendSubscription = async (
  subscriptionId: number,
  days: number,
  note: string
) => {
  const result = await Api.post("api/subscriptions/extend", {
    subscriptionId,
    days,
    note,
  });
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to extend subscription", {
      "Server response": JSON.stringify(result),
    });
  }
  return response;
};

export const SubscriptionManagerApi = {
  createSubscription,
  createCheckoutSession,
  unsubscripe,
  createContinuationSubscription,
  updatePayment,
  extendSubscription,
};
