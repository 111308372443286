import { useTranslation } from "react-i18next";
import { PlantInfo } from "..";
import { Modal } from "imagine-ui";
import { PlantSchema } from "draw";
import { UnitScale } from "project";
import { Delimiter } from "imagine-essentials";

interface Props {
  plant: PlantSchema;
  visible: boolean;
  onClose: () => void;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneUSDA?: boolean;
  showHardinessZoneRHS?: boolean;
}

export const PlantInfoPage = (props: Props) => {
  const { t } = useTranslation();
  return (
    <Modal
      title={t("plants:plantInfo")}
      visible={props.visible}
      onClose={props.onClose}
      hideFooter
      size="lg"
    >
      <PlantInfo
        plant={props.plant}
        unitScale={props.unitScale}
        delimiter={props.delimiter}
        showHardinessZoneUSDA={props.showHardinessZoneUSDA}
        showHardinessZoneRHS={props.showHardinessZoneRHS}
      />
    </Modal>
  );
};
