import { UserActions, UserSelector } from "imagine-users";
import { ItemList, ObjectLibrary } from "../../components";
import {
  LayoutSelector,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useMemo, useState } from "react";
import { Item, ItemType, ObjectCategory, ObjectSchema, ObjectsApi } from "draw";
import { FeatureKey } from "project";
import { use } from "i18next";

interface Props {
  visible: boolean;
}

export const ObjectItemsContainer = (props: Props) => {
  const dispatch = useAppDispatch();

  const activeTab = useAppSelector(LayoutSelector.getSidebarLeftActiveTab);

  const items = useAppSelector(ObjectEditorSelector.getItems);
  const selectedItems = useAppSelector(ObjectEditorSelector.getSelectedItems);

  const selectedItem = useMemo(() => {
    return selectedItems.length === 1 ? selectedItems[0] : undefined;
  }, [selectedItems]);

  const updateItems = (items: Item[]) => {
    dispatch(ObjectEditorActions.updateMultipleItems(items));
  };

  const selectItem = (item: Item) => {
    dispatch(ObjectEditorActions.setSelectedItems([item]));
  };

  return (
    <>
      {props.visible && (
        <div className="p scroll-y h-full">
          <ItemList
            items={items}
            onReorder={updateItems}
            onSelect={selectItem}
            selected={selectedItem}
          />
        </div>
      )}
    </>
  );
};
