import {
  useAppSelector,
  CanvasSelector,
  PlanEditorSelector,
  useAppDispatch,
  CanvasActions,
  PlanEditorActions,
  LayoutActions,
} from "../../store";
import { EditableReferenceImage, StaticReferenceImage } from "../../components";
import { Pages } from "../../enums";
import { ReferenceImage } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { Point } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";

/**
 * Displays the editable version of the reference image
 */
export const ReferenceImageContainer = () => {
  const dispatch = useAppDispatch();
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const isEditingReferenceImage = useAppSelector(
    PlanEditorSelector.isEditingReferenceImage
  );

  const delimiter = I18nTools.getDelimiter(user.language);

  const updateReferenceImage = (
    referenceImage: ReferenceImage,
    zoom: number,
    zeroReference: Point
  ) => {
    console.log("Update reference image", referenceImage);
    dispatch(PlanEditorActions.setReferenceImage(referenceImage));
    dispatch(CanvasActions.setZoom(zoom));
  };

  const openDistanceModal = () => {
    console.log("Open distance modal");
    dispatch(
      LayoutActions.setDisplayedPage(Pages.REFERENCE_IMAGE_DISTANCE_PAGE)
    );
  };

  return (
    <>
      {referenceImage !== undefined && isEditingReferenceImage && (
        <EditableReferenceImage
          referenceImage={referenceImage}
          zoom={zoom}
          zeroReference={zeroReference}
          onChange={updateReferenceImage}
          onUpdateDistance={openDistanceModal}
          unitScale={userPreferences.unitScale}
          delimiter={delimiter}
        />
      )}
      {referenceImage !== undefined && !isEditingReferenceImage && (
        <StaticReferenceImage
          referenceImage={referenceImage}
          zoom={zoom}
          zeroReference={zeroReference}
        />
      )}
    </>
  );
};
