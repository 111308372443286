import { CanvasOperations } from "draw";
import { Point } from "imagine-essentials";
import { useEffect, useMemo } from "react";

interface Props {
  position: Point;
  direction: number;
  zoom: number;
}

export const Person = (props: Props) => {
  const personWidth = CanvasOperations.unitToPixel(0.5, props.zoom);
  const rotation = useMemo(() => {
    const angle = props.direction < 0 ? 360 + props.direction : props.direction;
    return (
      "rotate(" +
      angle +
      ", " +
      props.position.x +
      ", " +
      props.position.y +
      ")"
    );
  }, [props.direction, props.position]);
  useEffect(() => {
    console.log("Direction:", props.direction);
  }, [props.direction]);
  return (
    <g transform={rotation}>
      <ellipse
        cx={props.position.x}
        cy={props.position.y}
        rx={personWidth}
        ry={personWidth / 3}
        fill="#707167"
      />
      <ellipse
        cx={props.position.x}
        cy={props.position.y}
        rx={personWidth / 2.5}
        ry={personWidth / 2.5}
        fill="#4b4b44"
      />
    </g>
  );
};
