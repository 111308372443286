import { useCallback, useMemo } from "react";
import { Footer } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
  ObjectEditorSelector,
  CanvasActions,
} from "../../store";
import { useTranslation } from "react-i18next";
import {
  GroupsContainer,
  LayersContainer,
  ObjectItemsContainer,
  ObjectLibraryContainer,
  PlantLibraryContainer,
  SupportContainer,
} from "..";

export const FooterContainer = () => {
  const { t } = useTranslation();
  const activeTab = useAppSelector(LayoutSelector.getFooterActiveTab);
  const height = useAppSelector(LayoutSelector.getWindowHeight);
  const dispatch = useAppDispatch();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);

  const toggle = useCallback(
    (tab: string) => {
      if (activeTab === tab) {
        dispatch(LayoutActions.setFooterActiveTab(""));
        dispatch(CanvasActions.setBlockedBottom(0));
      } else {
        dispatch(LayoutActions.setFooterActiveTab(tab));
        dispatch(CanvasActions.setBlockedBottom(height / 2));
      }
    },
    [activeTab, dispatch, height]
  );

  const sidebarTabs = useMemo(() => {
    if (objectMode) {
      return [
        {
          id: "objectItems",
          title: t("objectItems"),
          icon: "shapes",
          onClick: () => {
            toggle("objectItems");
          },
        },
      ];
    } else {
      return [
        {
          id: "plantLibrary",
          title: t("plantLibrary"),
          icon: "flower-2",
          onClick: () => {
            toggle("plantLibrary");
          },
        },
        {
          id: "objectLibrary",
          title: t("objectLibrary"),
          icon: "parasol",
          onClick: () => {
            toggle("objectLibrary");
          },
        },
        {
          id: "layers",
          title: t("layers"),
          icon: "layers",
          onClick: () => {
            toggle("layers");
          },
        },
        {
          id: "groups",
          title: t("groups"),
          icon: "group",
          onClick: () => {
            toggle("groups");
          },
        },
        {
          id: "support",
          title: t("support"),
          icon: "question",
          onClick: () => {
            toggle("support");
          },
        },
      ];
    }
  }, [objectMode, t, toggle]);

  return (
    <Footer
      open={activeTab !== ""}
      tabs={sidebarTabs}
      activeTab={activeTab}
      height={height / 2}
      onClose={() => toggle("")}
    >
      <PlantLibraryContainer visible={activeTab === "plantLibrary"} />
      <ObjectLibraryContainer visible={activeTab === "objectLibrary"} />
      <LayersContainer visible={activeTab === "layers"} />
      <GroupsContainer visible={activeTab === "groups"} />
      <ObjectItemsContainer visible={activeTab === "objectItems"} />
      <SupportContainer visible={activeTab === "support"} />
    </Footer>
  );
};
