import { Point, Rectangle, Size, Tools } from "..";

const getPointSum = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x + b.x, decimals),
    y: Tools.round(a.y + b.y, decimals),
  } as Point;
};

const getSizeSum = (a: Size, b: Size, decimals = 2) => {
  return {
    width: Tools.round(a.width + b.width, decimals),
    height: Tools.round(a.height + b.height, decimals),
  } as Size;
};

const getPointSubtracted = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x - b.x, decimals),
    y: Tools.round(a.y - b.y, decimals),
  } as Point;
};

const getSizeSubtracted = (a: Size, b: Size, decimals = 2) => {
  return {
    width: Tools.round(a.width - b.width, decimals),
    height: Tools.round(a.height - b.height, decimals),
  } as Size;
};

const getPointMultiplied = (a: Point, b: Point, decimals = 2) => {
  return {
    x: Tools.round(a.x * b.x, decimals),
    y: Tools.round(a.y * b.y, decimals),
  } as Point;
};

const pointToSize = (p: Point) => {
  return {
    width: p.x,
    height: p.y,
  };
};

const sizeToPoint = (s: Size) => {
  return {
    x: s.width,
    y: s.height,
  };
};

/**
 * Get the absolutes value of each point (no negative values)
 * @param p
 * @returns
 */
const getAbsolutePoint = (p: Point) => {
  return {
    x: Math.abs(p.x),
    y: Math.abs(p.y),
  };
};

const getMinPoint = (a: Point, b: Point) => {
  return {
    x: a.x < b.x ? a.x : b.x,
    y: a.y < b.y ? a.y : b.y,
  } as Point;
};

const getMaxPoint = (a: Point, b: Point) => {
  return {
    x: a.x > b.x ? a.x : b.x,
    y: a.y > b.y ? a.y : b.y,
  } as Point;
};

const getSizeCenter = (size: Size) => {
  return {
    width: size.width / 2,
    height: size.height / 2,
  };
};

const roundSize = (size: Size, decimals: number) => {
  return {
    width: Tools.round(size.width, decimals),
    height: Tools.round(size.height, decimals),
  };
};

const roundPoint = (point: Point, decimals: number) => {
  return {
    x: Tools.round(point.x, decimals),
    y: Tools.round(point.y, decimals),
  };
};

const getRectangleFromPoints = (p1: Point, p2: Point) => {
  const position = {
    x: Math.min(p1.x, p2.x),
    y: Math.min(p1.y, p2.y),
  };
  const size = {
    width: Math.abs(p1.x - p2.x),
    height: Math.abs(p1.y - p2.y),
  };
  return {
    position: position,
    size: size,
  } as Rectangle;
};

const calculateDistance = (a: Point, b: Point, decimals = 2) => {
  return Tools.round(
    Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2)),
    decimals
  );
};

export const Trig = {
  getPointSum,
  getSizeSum,
  getPointSubtracted,
  getSizeSubtracted,
  getPointMultiplied,
  pointToSize,
  sizeToPoint,
  getAbsolutePoint,
  getMinPoint,
  getMaxPoint,
  getSizeCenter,
  roundSize,
  roundPoint,
  getRectangleFromPoints,
  calculateDistance,
};
