import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  ObjectEditorSelector,
  LayoutActions,
  ObjectEditorActions,
} from "../../store";
import { Pages } from "../../enums";
import { ObjectOperations, ObjectsApi } from "draw";
import {
  ToolButton,
  ToolDropdown,
  ToolGroup,
  ToolSeparator,
} from "../../components";
import { NotificationType, useNotification } from "imagine-ui";
import { useMemo } from "react";
import { ObjectThunks } from "../../utils/ObjectThunks";
import { UserSelector } from "imagine-users";

/**
 * Displays the tool and actions in the home toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const ObjectToolbarContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const unsavedObjectChanges = useAppSelector(
    ObjectEditorSelector.hasUnsavedChanges
  );
  const category = useAppSelector(ObjectEditorSelector.getCategory);
  const currentObjectTemplate = useAppSelector(
    ObjectEditorSelector.getCurrentObjectTemplate
  );
  const savedObjectTemplate = useAppSelector(
    ObjectEditorSelector.getSavedObjectTemplate
  );

  const isAuthor = useMemo(() => {
    if (currentObjectTemplate === null || user === null) return false;
    return user?.id === currentObjectTemplate.userId;
  }, [user, currentObjectTemplate]);

  const categoryOptions = useMemo(() => {
    return ObjectOperations.getCategoryOptions();
  }, []);

  const categoryText = useMemo(() => {
    if (category !== undefined) {
      return ObjectOperations.getCategoryText(category);
    }
    return t("imagine:category");
  }, [category, t]);

  const updateCategory = (cat: number) => {
    dispatch(ObjectEditorActions.setCategory(cat));
  };

  const saveObject = async () => {
    if (!objectMode) return;
    const errorMessage = await ObjectThunks.saveObject(
      dispatch,
      currentObjectTemplate
    );
    if (errorMessage !== "") {
      notification.add(NotificationType.ERROR, errorMessage);
    }
  };

  const saveObjectCopy = () => {
    if (!objectMode) return;
    dispatch(LayoutActions.setDisplayedPage(Pages.SAVE_OBJECT));
  };

  const openRenameModal = () => {
    if (!objectMode) return;
    dispatch(LayoutActions.setDisplayedPage(Pages.RENAME_OBJECT));
  };

  return (
    <div id="object-toolbar" className="toolbar-row">
      <ToolButton
        elementId="save-object-nav-button"
        text={t("imagine:save")}
        icon="save"
        onClick={saveObject}
        disabled={
          !unsavedObjectChanges || savedObjectTemplate === null || !isAuthor
        }
      />
      <ToolButton
        elementId="save-object-copy-nav-button"
        text={t("imagine:saveAs")}
        icon="save-as"
        onClick={saveObjectCopy}
      />
      <ToolButton
        elementId="rename-object-nav-button"
        text={t("imagine:rename")}
        icon="edit-text"
        onClick={openRenameModal}
        disabled={savedObjectTemplate?.public}
      />
      <ToolSeparator />
      <ToolGroup>
        {/* <ToolTextInput
          text={t("imagine:name")}
          value={name}
          onChange={updateName}
          className="half"
        /> */}
        <ToolDropdown
          elementId="object-category-dropdown"
          options={categoryOptions}
          icon="label"
          text={categoryText}
          onSelect={updateCategory}
          selected={category}
          layout="text-list"
        />
      </ToolGroup>
    </div>
  );
};
