import { useState } from "react";
import { ColorPicker } from "draw";
import { ColorIcon, ToolButton } from ".";

interface Props {
  text: string;
  color: string | undefined;
  onChange?: (hex: string) => void;
  disabled?: boolean;
  elementId?: string;
  half?: boolean;
}

export const ToolColor = (props: Props) => {
  const [displayColorPicker, setDisplayColorPicker] = useState(false);

  const openColorPicker = () => {
    if (props.disabled) return;
    setDisplayColorPicker(true);
  };

  const handleColorChange = (newColor: any) => {
    setTimeout(() => {
      setDisplayColorPicker(false);
    }, 100);
    if (props.onChange) {
      props.onChange(newColor);
    }
  };

  const closeColorPicker = () => {
    // State update prevents this from being set until after af timeout
    setTimeout(() => {
      setDisplayColorPicker(false);
    }, 100);
  };

  return (
    <ToolButton
      text={props.text}
      icon={<ColorIcon color={props.color} />}
      disabled={props.disabled}
      elementId={props.elementId}
      half={props.half}
      onClick={openColorPicker}
    >
      <ColorPicker
        show={displayColorPicker}
        color={props.color}
        onChange={handleColorChange}
        onClose={closeColorPicker}
      />
    </ToolButton>
  );
};
