import { useContext } from "react";
import { NotificationContext } from "..";

/**
 * Hook for the dialog that is responsible for showing the notification.
 */
export const useNotificationData = () => {
  const context = useContext(NotificationContext);
  return {
    type: context.type,
    message: context.message,
    clear: context.clear,
    confirm: context.confirm,
  };
};
