import { Alert, Checkbox, Modal, Select, Tab, TabBar } from "imagine-ui";
import { UnitScale, UserPreferences } from "project";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Option } from "imagine-essentials";
import { PlantData } from "plants";

interface Props {
  preferences: UserPreferences;
  onSave: (preferences: UserPreferences) => void;
  onClose: () => void;
  loading?: boolean;
  errorMessage?: string;
}

export const UserPreferencesModal = (props: Props) => {
  const { t } = useTranslation();
  const [preferences, setPreferences] = useState(props.preferences);
  const [activeTab, setActiveTab] = useState("general");

  const updatePreferences = (change: Partial<UserPreferences>) => {
    setPreferences({ ...preferences, ...change });
  };

  const save = () => {
    props.onSave(preferences);
  };

  const unitScaleOptions: Option[] = [
    {
      value: UnitScale.METRIC,
      label: t("draw:metricWithSamples"),
    },
    {
      value: UnitScale.IMPERIAL,
      label: t("draw:imperialWithSamples"),
    },
  ];

  useEffect(() => {
    setPreferences(props.preferences);
  }, [props.preferences]);

  const tabs: Tab[] = [
    {
      text: t("general"),
      id: "general",
    },
    {
      text: t("settings"),
      id: "settings",
    },
  ];

  return (
    <Modal
      title={t("myPreferences")}
      visible
      onClose={props.onClose}
      onConfirm={save}
      confirmText={t("imagine:save")}
      loading={props.loading}
      discreteHeader
    >
      <TabBar tabs={tabs} active={activeTab} onChange={setActiveTab} />
      <div className="">
        {props.errorMessage && (
          <Alert color="danger" className="mb-lg">
            {props.errorMessage}
          </Alert>
        )}

        {activeTab === "general" && (
          <div className="mt-xl grid columns-2 gap">
            <Checkbox
              className="colspan-2"
              label={t("showGrid")}
              checked={preferences.showGrid}
              onChange={(val: boolean) => updatePreferences({ showGrid: val })}
            />
            <Checkbox
              className="colspan-2"
              label={t("showScale")}
              checked={preferences.showScale}
              onChange={(val: boolean) => updatePreferences({ showScale: val })}
            />
            <Checkbox
              className="colspan-2"
              label={t("showPlantHeights")}
              checked={preferences.showPlantHeights}
              onChange={(val: boolean) =>
                updatePreferences({ showPlantHeights: val })
              }
            />

            <Checkbox
              className="colspan-2"
              label={t("showZoomButtonsOnCanvas")}
              checked={preferences.showZoomButtons}
              onChange={(val: boolean) =>
                updatePreferences({ showZoomButtons: val })
              }
            />
            <Checkbox
              className="colspan-2"
              label={t("autoAdjustItems")}
              checked={preferences.snap}
              onChange={(val: boolean) => updatePreferences({ snap: val })}
            />

            <Checkbox
              className="colspan-2"
              label={t("enableAutoSave")}
              checked={preferences.enableAutoSave}
              onChange={(val: boolean) =>
                updatePreferences({ enableAutoSave: val })
              }
            />
          </div>
        )}
        {activeTab === "hardinessZones" && (
          <div className="mt-xl grid columns gap items-center"></div>
        )}
        {activeTab === "settings" && (
          <div className="mt-xl grid columns gap items-center">
            <Checkbox
              className="colspan-2"
              label={t("enableBetaFeatures")}
              checked={preferences.enableBetaFeatures}
              onChange={(val: boolean) =>
                updatePreferences({ enableBetaFeatures: val })
              }
            />

            <Checkbox
              className="colspan-2"
              label={t("showHardinessZoneUSDA")}
              checked={preferences.showHardinessZoneUSDA}
              onChange={(val: boolean) =>
                updatePreferences({ showHardinessZoneUSDA: val })
              }
            />
            <Checkbox
              className="colspan-2"
              label={t("showHardinessZoneRHS")}
              checked={preferences.showHardinessZoneRHS}
              onChange={(val: boolean) =>
                updatePreferences({ showHardinessZoneRHS: val })
              }
            />

            <label>{t("draw:unitScale")}</label>
            <Select
              options={unitScaleOptions}
              selected={preferences.unitScale}
              onSelectedNumber={(val: UnitScale) =>
                updatePreferences({ unitScale: val })
              }
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
