import { useTranslation } from "react-i18next";
import { Button, Icon } from "imagine-ui";
import { UserDropdownItem } from "./UserDropdownItem";
import { useState } from "react";
import logo from "../../assets/img/logo-big.png";

interface Props {
  title: string;
  unsaved: boolean;
  displayName: string;
  onAccountClick: () => void;
  onPreferencesClick: () => void;
  onLogOutClick: () => void;
  onLogInClicked: () => void;
  loggedIn?: boolean;
}

export const Header = (props: Props) => {
  const { t } = useTranslation();
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    if (props.loggedIn) {
      setShowDropdown(!showDropdown);
    } else {
      props.onLogInClicked();
    }
  };

  const openAccount = () => {
    setShowDropdown(false);
    props.onAccountClick();
  };

  const openPreferences = () => {
    setShowDropdown(false);
    props.onPreferencesClick();
  };

  const logout = () => {
    setShowDropdown(false);
    props.onLogOutClick();
  };

  return (
    <div className="header">
      <div className="pl-sm row items-y-center text-lg">
        <img
          src={logo}
          alt="Garden Sketcher logo"
          className="logo"
          height="32px"
        />
        <span className="text-accent ml-sm d-none--md d-inline-block">
          Garden&nbsp;
        </span>
        <span className="text-highlight d-none--md d-inline-block">
          Sketcher
        </span>{" "}
        &nbsp;
      </div>
      <div
        id="current-plan-name"
        className="text-center row items-y-center items-center"
      >
        <span className={props.unsaved ? "text-bold" : ""}>
          {props.title}
          {props.unsaved ? "*" : ""}
        </span>
      </div>
      <div className="account-wrapper row items-right items-y-center pr-xs">
        <Button
          elementId="account-button"
          onClick={toggleDropdown}
          transparent
          color="dark"
          active={showDropdown}
        >
          <span id="current-user" className="d-none--md d-inline-block mr">
            {props.displayName}
          </span>
          <Icon name="user" className="icon" />
        </Button>
        {showDropdown && (
          <div className="account-dropdown">
            <UserDropdownItem
              icon="user"
              text={t("users:myAccount")}
              onClick={openAccount}
              elementId="account-dropdown-item"
            />
            <UserDropdownItem
              icon="gear"
              text={t("users:preferences")}
              onClick={openPreferences}
              elementId="preferences-dropdown-item"
            />
            <UserDropdownItem
              icon="door"
              text={t("users:logout")}
              onClick={logout}
              elementId="logout-dropdown-item"
            />
          </div>
        )}
      </div>
    </div>
  );
};
