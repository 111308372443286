import { PlantingPlanPage } from "../../components";
import { MarkerMode, Pages } from "../../enums";
import {
  CanvasActions,
  CanvasSelector,
  LayoutActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";

export const PlantingPlanPageContainer = () => {
  const dispatch = useAppDispatch();

  const items = useAppSelector(PlanEditorSelector.getVisibleItems);
  const name = useAppSelector(PlanEditorSelector.getPlanName);

  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const markedRectangle = useAppSelector(CanvasSelector.getMarkedRectangle);
  const markerMode = useAppSelector(CanvasSelector.getMarkerMode);

  const close = () => {
    dispatch(CanvasActions.setMessage(""));
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    dispatch(CanvasActions.setMarkerMode(MarkerMode.SELECT));
  };

  return (
    <>
      {markedRectangle !== null && markerMode === MarkerMode.DRAW && (
        <PlantingPlanPage
          items={items}
          name={name}
          visible
          plantTemplates={plantTemplates}
          rectangle={markedRectangle}
          onClose={close}
        />
      )}
    </>
  );
};
