import { useTranslation } from "react-i18next";
import { Toolbar, ToolbarTab } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
  PlanEditorSelector,
  ObjectEditorSelector,
} from "../../store";
import { useCallback, useMemo } from "react";
import {
  AreaItemToolbarContainer,
  AreaToolbarContainer,
  DrawToolbarContainer,
  ExportToolbarContainer,
  HomeToolbarContainer,
  ItemToolbarContainer,
  ObjectToolbarContainer,
  PresentToolbarContainer,
} from "..";

export const ToolbarContainer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const activeTab = useAppSelector(LayoutSelector.getActiveTab);
  const activeTabFallback = useAppSelector(LayoutSelector.getActiveTabFallback);
  const width = useAppSelector(LayoutSelector.getWindowWidth);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );

  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const openTab = useCallback(
    (id: string) => {
      dispatch(LayoutActions.setActiveTab(id));
    },
    [dispatch]
  );

  const narrowLayout = width < 600;

  const toolbarTabs: ToolbarTab[] = useMemo(() => {
    if (i18n.language === undefined) return [];
    let tabs: ToolbarTab[] = [];
    if (objectMode) {
      tabs = [
        {
          title: t("object"),
          id: "home-tab",
          icon: "house",
          onClick: () => {
            openTab("home-tab");
          },
        },
        {
          title: t("draw"),
          id: "draw-tab",
          icon: "shapes",
          onClick: () => {
            openTab("draw-tab");
          },
        },
      ];
    } else {
      tabs = [
        {
          title: t("home"),
          id: "home-tab",
          icon: "house",
          onClick: () => {
            openTab("home-tab");
          },
        },
        {
          title: t("draw"),
          id: "draw-tab",
          icon: "shapes",
          onClick: () => {
            openTab("draw-tab");
          },
        },

        {
          title: t("present"),
          id: "present-tab",
          icon: "preview-plant",
          onClick: () => {
            openTab("present-tab");
          },
        },

        {
          title: t("area"),
          id: "area-tab",
          icon: "pin-map",
          onClick: () => {
            openTab("area-tab");
          },
        },

        {
          title: t("export"),
          id: "export-tab",
          icon: "document-export",
          onClick: () => {
            openTab("export-tab");
          },
        },
      ];
    }
    if (selectedItems.length > 0 || isEditingArea) {
      tabs.push({
        title: t("item"),
        id: "item-tab",
        icon: "rectangle-shape",
        onClick: () => {
          openTab("item-tab");
        },
      });
    }
    return tabs;
  }, [
    isEditingArea,
    objectMode,
    openTab,
    selectedItems.length,
    t,
    i18n.language,
  ]);

  /**
   * If the active tab is not available, open the home tab
   */
  const availableActiveTab = useMemo(() => {
    const selectedTab = toolbarTabs.find((tab) => tab.id === activeTab);
    if (selectedTab) return activeTab;

    const selectedFallbackTab = toolbarTabs.find(
      (tab) => tab.id === activeTabFallback
    );
    if (selectedFallbackTab) return activeTabFallback;

    return "home-tab";
  }, [activeTab, toolbarTabs]);

  return (
    <Toolbar
      tabs={toolbarTabs}
      activeTab={availableActiveTab}
      narrowLayout={narrowLayout}
      elementId="toolbar"
    >
      {availableActiveTab === "home-tab" && !objectMode && (
        <HomeToolbarContainer />
      )}
      {availableActiveTab === "home-tab" && objectMode && (
        <ObjectToolbarContainer />
      )}
      {availableActiveTab === "draw-tab" && <DrawToolbarContainer />}
      {availableActiveTab === "area-tab" && <AreaToolbarContainer />}
      {availableActiveTab === "item-tab" && (
        <>
          {selectedItems.length > 0 && <ItemToolbarContainer />}
          {isEditingArea && <AreaItemToolbarContainer />}
        </>
      )}
      {availableActiveTab === "present-tab" && <PresentToolbarContainer />}
      {availableActiveTab === "export-tab" && <ExportToolbarContainer />}
    </Toolbar>
  );
};
