import {
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import {
  PaymentMethodCreateParams,
  StripePaymentElementOptions,
} from "@stripe/stripe-js";
import { useState } from "react";
import { Alert, Button } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { SentryReporter } from "imagine-essentials";
import ReactPixel from "react-facebook-pixel";

interface Props {
  returnUrl: string;
  buttonText: string;
  updatePayment?: boolean;
  billingDetails?: PaymentMethodCreateParams.BillingDetails;
  currency?: string;
  amount?: number;
}

const options: StripePaymentElementOptions = {
  fields: {
    billingDetails: {
      address: {
        country: "never",
        postalCode: "never",
      },
    },
  },
};

export const StripePayment = (props: Props) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const confirmPayment = async () => {
    if (stripe && elements) {
      setLoading(true);
      const params = {
        return_url: props.returnUrl,
        payment_method_data: {
          billing_details: props.billingDetails,
        },
      };
      if (props.updatePayment) {
        try {
          const result = await stripe.confirmSetup({
            elements: elements,
            confirmParams: params,
          });
          if (result.error) {
            setErrorMessage(t("memberships:failedToUpdateYourPaymentDetails"));
          }
        } catch (e) {
          setErrorMessage(t("memberships:failedToUpdateYourPaymentDetails"));
          SentryReporter.captureException(e);
        }
      } else {
        try {
          
          const result = await stripe?.confirmPayment({
            elements: elements,
            confirmParams: params,
          });

          try{
            ReactPixel.track("Purchase", {
              currency: props.currency,
              value: props.amount,
            });
          } catch(e) {
            SentryReporter.captureException(e);
          }

          if (result.error) {
            setErrorMessage(t("memberships:failedToConfirmPayment"));
          }
        } catch (e) {
          setErrorMessage(
            t("memberships:failedToConfirmPaymentUnexpectedError")
          );
          SentryReporter.captureException(e);
        }
      }
      setLoading(false);
    } else {
      setErrorMessage(t("memberships:failedToConfirmPaymentUnexpectedError"));
      SentryReporter.captureException("Stripe or elements not loaded");
    }
  };

  return (
    <div>
      {errorMessage !== "" && (
        <Alert className="mb-xl" color="danger" elementId="payment-error">
          {errorMessage}
        </Alert>
      )}
      <PaymentElement options={options} />
      <div className="row">
        <Button
          elementId="confirm-payment"
          className="mt stretch"
          onClick={confirmPayment}
          loading={loading}
        >
          {props.buttonText}
        </Button>
      </div>
    </div>
  );
};
