import { useTranslation } from "react-i18next";
import { Modal } from "imagine-ui";
import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
} from "../../store";
import {
  ForgottenPassword,
  ForgottenPasswordConfirmation,
  UsersApi,
} from "imagine-users";
import { useState } from "react";

export const ResetPasswordModalContainer = () => {
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const close = () => {
    setLoading(false);
    setErrorMessage("");
    setShowConfirmation(false);
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const resetPassword = async (email: string) => {
    setLoading(true);
    const result = await UsersApi.resetPassword(email);
    if (result.success) {
      setShowConfirmation(true);
    }
    if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    }
    setLoading(false);
  };

  return (
    <>
      {displayedPage === Pages.RESET_PASSWORD && (
        <>
          (
          <Modal
            title={t("users:resetPassword")}
            visible
            onClose={close}
            hideCancel
            hideFooter={true}
            discreteHeader
            center
          >
            {showConfirmation && <ForgottenPasswordConfirmation />}
            {!showConfirmation && (
              <ForgottenPassword
                onResetClick={resetPassword}
                errorMessage={errorMessage}
                loading={loading}
              />
            )}
          </Modal>
          )
        </>
      )}
    </>
  );
};
