interface Props {
  color?: string;
}

export const ColorIcon = (props: Props) => {
  return (
    <span
      className="color-icon"
      style={{ backgroundColor: props.color ?? "transparent" }}
    ></span>
  );
};
