import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { User, UserData, UserRole } from "..";
import { Button, Select, TextInput } from "imagine-ui";
import { Country, I18nData, Language } from "imagine-i18n";
import { use } from "i18next";

type UserProfileProps = {
  // languageOptions?: StringOption[];
  // countryOptions?: StringOption[];
  user: User;
  onSave: (
    email: string,
    name: string,
    language: Language,
    country: Country,
    role?: UserRole
  ) => void;
  className?: string;
  errorMessage?: string;
  loading?: boolean;
  enableRole?: boolean;
  // onRefreshUser: () => void;
};

/**
 * The page displays the current user settings
 */
export const UserProfile = (props: UserProfileProps) => {
  const { t } = useTranslation();

  const [email, setEmail] = useState(props.user.email);
  const [name, setName] = useState(props.user.name || "");
  const [language, setLanguage] = useState<Language>(props.user.language);
  const [country, setCountry] = useState<Country>(props.user.country);
  const [role, setRole] = useState<UserRole>(props.user.role);

  const [emailError, setEmailError] = useState("");

  const save = () => {
    if (email === "") {
      setEmailError(t("users:enterEmail"));
      return;
    }
    setEmailError("");
    props.onSave(email, name || "", language, country, role);
  };

  const countryOptions = useMemo(() => {
    return I18nData.getCountryOptions();
  }, []);

  const languageOptions = useMemo(() => {
    return I18nData.getLanguageOptions();
  }, []);

  const roleOptions = useMemo(() => {
    return UserData.getUserRoleOptions();
  }, []);

  return (
    <div id="user-profile">
      {props.errorMessage && (
        <p id="user-profile-error-message" className="text-danger mb">
          {props.errorMessage}
        </p>
      )}
      <div
        className={"grid columns-2 columns-1--md gap " + props.className}
        id="user-profile"
      >
        <div>
          <div className="mb">
            <label className="label">{t("imagine:email")}:</label>
            <TextInput
              elementId="user-profile-email"
              invalid={emailError !== ""}
              value={email || ""}
              onFinish={setEmail}
            />
            <div className="text-danger">{emailError}</div>
          </div>
          <div className="mb">
            <label className="label">{t("imagine:name")}:</label>
            <TextInput
              elementId="user-profile-name"
              value={name}
              onFinish={setName}
            />
          </div>
          {props.enableRole && (
            <div className="mb">
              <label className="label">{t("users:userRole")}:</label>
              <Select
                options={roleOptions}
                selected={role}
                onSelectedNumber={(val) => setRole(val as UserRole)}
              />
            </div>
          )}
        </div>
        <div>
          {languageOptions !== undefined && (
            <div className="mb">
              <label className="label">{t("imagine:language")}:</label>
              <Select
                elementId="user-profile-language"
                options={languageOptions}
                onSelectedString={(val) => setLanguage(val as Language)}
                selected={language}
              />
            </div>
          )}
          {countryOptions !== undefined && (
            <div className="mb">
              <label className="label">{t("imagine:country")}:</label>
              <Select
                elementId="user-profile-country"
                options={countryOptions}
                onSelectedString={(val) => setCountry(val as Country)}
                selected={country}
              />
            </div>
          )}
        </div>
      </div>
      <div className="row items-right">
        <Button
          elementId="user-profile-save"
          onClick={save}
          loading={props.loading}
        >
          {t("imagine:save")}
        </Button>
      </div>
    </div>
  );
};
