// import visaIcon from "../assets/visa.svg";
// import masterCardIcon from "../assets/mastercard.svg";
import { Customer } from "..";
import { Button, Checkbox, Label, Link, Select, TextInput } from "imagine-ui";
import { useCallback, useMemo, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { I18nData } from "imagine-i18n";

interface Props {
  onFinish: (customer: Customer) => void;
  customer: Customer;
  termsAndConditionsUrl: string;
  onCountryChange?: (countryIsoCode: string) => void;
  errorMessage?: string;
  loading?: boolean;
}

export const CustomerInfo = (props: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState(
    sessionStorage.getItem("customerName") ?? props.customer.name ?? ""
  );

  const [address1, setAddress1] = useState(
    sessionStorage.getItem("customerAddress1") ?? props.customer.address1 ?? ""
  );
  const [address2, setAddress2] = useState(
    sessionStorage.getItem("customerAddress2") ?? props.customer.address2 ?? ""
  );
  const [postCode, setPostCode] = useState(
    sessionStorage.getItem("customerPostCode") ?? props.customer.postCode ?? ""
  );
  const [city, setCity] = useState(
    sessionStorage.getItem("customerCity") ?? props.customer.city ?? ""
  );
  const [country, setCountry] = useState(
    sessionStorage.getItem("customerCountry") ?? props.customer.country ?? "208"
  ); // Defaults to Denmark

  const updateCountry = (country: string) => {
    setCountry(country);
    setCountryInvalid(false);
    if (country !== props.customer.country && props.onCountryChange) {
      props.onCountryChange(country);
    }
  };

  const countryOptions = I18nData.getCountryOptions();

  const [acceptConditions, setAcceptConditions] = useState(false);
  const [acceptConditionsInvalid, setAcceptConditionsInvalid] = useState(false);

  const [fullNameInvalid, setFullNameInvalid] = useState(false);
  const [address1Invalid, setAddress1Invalid] = useState(false);
  const [postCodeInvalid, setPostCodeInvalid] = useState(false);
  const [cityInvalid, setCityInvalid] = useState(false);
  const [countryInvalid, setCountryInvalid] = useState(false);

  const errorMessage = useMemo(() => {
    if (
      fullNameInvalid ||
      address1Invalid ||
      postCodeInvalid ||
      countryInvalid ||
      acceptConditionsInvalid
    ) {
      return t("memberships:pleaseFillAllRequiredFields");
    }
    return "";
  }, [
    acceptConditionsInvalid,
    address1Invalid,
    countryInvalid,
    fullNameInvalid,
    postCodeInvalid,
    t,
  ]);

  const updateName = (val: string) => {
    setName(val);
    setFullNameInvalid(false);
  };

  const updateAddress1 = (val: string) => {
    setAddress1(val);
    setAddress1Invalid(false);
  };

  const updateAcceptConditions = (val: boolean) => {
    setAcceptConditions(val);
    setAcceptConditionsInvalid(false);
  };

  const updateCity = (val: string) => {
    setCity(val);
    setCityInvalid(false);
  };

  const updatePostCode = (val: string) => {
    setPostCode(val);
    setPostCodeInvalid(false);
  };

  const validate = useCallback(() => {
    let valid = true;
    if (name === "") {
      valid = false;
      setFullNameInvalid(true);
    } else {
      setFullNameInvalid(false);
    }

    if (address1 === "") {
      valid = false;
      setAddress1Invalid(true);
    } else {
      setAddress1Invalid(false);
    }

    if (postCode === "") {
      valid = false;
      setPostCodeInvalid(true);
    } else {
      setPostCodeInvalid(false);
    }

    if (city === "") {
      valid = false;
      setCityInvalid(true);
    } else {
      setCityInvalid(false);
    }

    if (country === "") {
      valid = false;
      setCountryInvalid(true);
    } else {
      setCountryInvalid(false);
    }

    if (!acceptConditions) {
      valid = false;
      setAcceptConditionsInvalid(true);
    } else {
      setAcceptConditionsInvalid(false);
    }

    return valid;
  }, [acceptConditions, address1, city, country, name, postCode]);

  const finish = () => {
    if (validate()) {
      props.onFinish({
        name: name,
        address1: address1,
        address2: address2,
        postCode: postCode,
        city: city,
        country: country,
      });
    }
  };

  return (
    <div id="buy-membership-customer-info">
      {props.errorMessage && (
        <p className="text-danger" id="buy-membership-error-message">
          {props.errorMessage}
        </p>
      )}
      {errorMessage && <p className="text-danger">{errorMessage}</p>}
      <div className="grid columns-3 columns-1--lg mt">
        <div>
          <Label>{t("memberships:fullName")}*:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb-xl">
          <TextInput
            elementId="payment-fullname"
            value={name}
            onChange={updateName}
            invalid={fullNameInvalid}
          />
        </div>

        <div>
          <Label>{t("imagine:address")}*:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb">
          <TextInput
            elementId="payment-address1"
            value={address1}
            onChange={updateAddress1}
            invalid={address1Invalid}
          />
        </div>

        <div>
          <Label>{t("imagine:address")}:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb">
          <TextInput
            elementId="payment-address2"
            value={address2}
            onChange={setAddress2}
          />
        </div>

        <div>
          <Label>{t("memberships:postCode")}*:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb">
          <TextInput
            elementId="payment-post-code"
            value={postCode}
            onChange={updatePostCode}
            invalid={postCodeInvalid}
          />
        </div>

        <div>
          <Label>{t("memberships:city")}*:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb">
          <TextInput
            elementId="payment-city"
            value={city}
            onChange={updateCity}
            invalid={cityInvalid}
          />
        </div>

        <div>
          <Label>{t("imagine:country")}*:</Label>
        </div>
        <div className="colspan-2 colspan-1--lg mb">
          <Select
            elementId="payment-country"
            searchable
            selected={country}
            options={countryOptions}
            onSelectedString={updateCountry}
            invalid={countryInvalid}
          />
        </div>
        <div className="colspan-3 colspan-1--lg mb mt">
          <Checkbox
            id="buy-accept-conditions"
            invalid={acceptConditionsInvalid}
            checked={acceptConditions}
            onChange={(checked) => updateAcceptConditions(checked)}
          >
            <Trans
              i18nKey="memberships:acceptingSubscriptionConditions"
              components={{
                link1: (
                  <Link href={props.termsAndConditionsUrl} target="_blank" />
                ),
              }}
            />
          </Checkbox>
          {acceptConditionsInvalid && (
            <div id="buy-accept-conditions-error" className="text-danger">
              {t("memberships:acceptSubscriptionConditions")}
            </div>
          )}
        </div>
      </div>
      <div className="row items-right items-right">
        <Button
          elementId="go-to-payment"
          onClick={finish}
          loading={props.loading}
        >
          {t("memberships:continueToPayment")}
        </Button>
      </div>
      {/* <div className="row items-right mt-xl">
        <img alt="" src={visaIcon} title={"VISA"} className="mr" width="50px" />
        <img alt="" src={masterCardIcon} title={"Master Card"} width="50px" />
      </div> */}
    </div>
  );
};
