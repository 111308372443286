import { Pattern, ShapePattern } from "draw";
import { Size } from "imagine-essentials";

interface Props {
  pattern: Pattern;
  color: string;
  toolTip?: string;
  size?: Size;
}

export const PatternIcon = (props: Props) => {
  return (
    <div className="item-icon" title={props.toolTip}>
      <svg
        viewBox="0 0 60 60"
        // width={props.size?.width}
        // height={props.size?.height}
      >
        {props.pattern === Pattern.NONE && (
          <rect width={60} height={60} fill={props.color} />
        )}
        {props.pattern !== Pattern.NONE && (
          <>
            <ShapePattern
              id={"tool-pattern"}
              zoom={85}
              color={props.color}
              startPosition={{ x: 0, y: 0 }}
              pattern={props.pattern}
            ></ShapePattern>
            <rect
              width={60}
              height={60}
              fill={"url(#" + props.pattern + "-tool-pattern)"}
            />
          </>
        )}
      </svg>
    </div>
  );
};
