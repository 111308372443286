export enum HardinessZoneUSDA {
  NONE = 0,
  ZONE_1A = 1, // 1-51.1 to -48.5
  ZONE_1B = 2, // -48.5 to -45.6
  ZONE_2A = 3, // -45.6 to -42.8
  ZONE_2B = 4, // -42.8 to -40.0
  ZONE_3A = 5, // -40.0 to -37.2
  ZONE_3B = 6, // -37.2 to -34.4
  ZONE_4A = 7, // -34.4 to -31.7
  ZONE_4B = 8, // -31.7 to -28.9
  ZONE_5A = 9, // -28.9 to -26.1
  ZONE_5B = 10, // -26.1 to -23.3
  ZONE_6A = 11, // -23.3 to -20.6
  ZONE_6B = 12, // -20.6 to -17.8
  ZONE_7A = 13, // -17.8 to -15.0
  ZONE_7B = 14, // -15.0 to -12.2
  ZONE_8A = 15, // -12.2 to -9.4
  ZONE_8B = 16, // -9.4 to -6.7
  ZONE_9A = 17, // -6.7 to -3.9
  ZONE_9B = 18, // -3.9 to -1.1
  ZONE_10A = 19, // -1.1 to 1.7
  ZONE_10B = 20, // 1.7 to 4.4
  ZONE_11A = 21, // 4.4 to 7.2
  ZONE_11B = 22, // 7.2 to 10.0
  ZONE_12A = 23, // 10.0 to 12.8
  ZONE_12B = 24, // 12.8 to 15.6
  ZONE_13A = 25, // 15.6 to 18.3
  ZONE_13B = 26, // 18.3 to 21.1
  _SIZE = 27,
}
