import { useMemo, useState } from "react";
import { DeviceSize, Icon } from "..";

export interface Tab {
  text: string;
  id: string;
}

interface Props {
  tabs: Tab[];
  active: string;
  onChange: (id: string) => void;
  breakpoint?: DeviceSize; // Will change to compressed layout when this size or smaller
  className?: string;
}

export const TabBar = (props: Props) => {
  const activeTabText = useMemo(() => {
    const activeTab = props.tabs.find((tab) => tab.id === props.active);
    return activeTab ? activeTab.text : "";
  }, [props.tabs, props.active]);

  const [open, setOpen] = useState(false);
  const toggle = () => {
    setOpen(!open);
  };

  const selectTab = (id: string) => {
    setOpen(false);
    props.onChange(id);
  };

  return (
    <div className={"tab-bar" + (props.className ? " " + props.className : "")}>
      <button
        className={"btn active-tab w-full" + (open ? " open" : "")}
        onClick={toggle}
      >
        <span className="title">{activeTabText}</span>
        <Icon name="chevron-down" />
      </button>
      <div className={"tab-buttons" + (open ? " open" : "")}>
        {props.tabs.map((tab: Tab) => (
          <button
            className={"tab" + (props.active === tab.id ? " active" : "")}
            onClick={() => selectTab(tab.id)}
            key={tab.id}
            id={"tab-" + tab.id}
          >
            {tab.text}
          </button>
        ))}
      </div>
    </div>
  );
};
