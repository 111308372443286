import { HardinessZoneUSDA, UnitScale, UserPreferences } from "..";
import { Country, I18nTools } from "imagine-i18n";
import { User } from "imagine-users";

const getDisplayedLanguage = (user: User | null) => {
  if (user?.language) {
    return I18nTools.getValidLanguage(user.language);
  }
  const browserLanguage = I18nTools.getBrowserLanguage();
  return I18nTools.getValidLanguage(browserLanguage);
};

const getDefaultHardinessZone = (country?: Country) => {
  switch (country) {
    case "DK":
      return HardinessZoneUSDA.ZONE_7B;
    case "GB":
      return HardinessZoneUSDA.ZONE_8B;
    case "IE":
      return HardinessZoneUSDA.ZONE_9B;
    default:
      return HardinessZoneUSDA.NONE;
  }
};

const getDefaultShowHardinessZoneRHS = (country?: Country) => {
  switch (country) {
    case "GB":
    case "IE":
      return true;
    default:
      return false;
  }
};

const getDefaultShowHardinessZoneUSDA = (country?: Country) => {
  switch (country) {
    case "DK":
    case "GB":
    case "IE":
      return false;
    default:
      return true;
  }
};

const getDefaultPreferences = (country?: Country) => {
  return {
    // General
    showScale: true,
    showGrid: true,
    showPlantHeights: false,
    showZoomButtons: false,
    snap: true,
    unitScale: UnitScale.METRIC,
    // Hardiness zone
    showHardinessZoneRHS: getDefaultShowHardinessZoneRHS(country),
    showHardinessZoneUSDA: getDefaultShowHardinessZoneUSDA(country),
    // Beta features
    enableBetaFeatures: false,
    enableAutoSave: false,
  } as UserPreferences;
};

/**
 * Fills the undefined fields of the preferences with the default values.
 * @param preferences
 * @returns
 */
const getCompleteUserPreferences = (
  preferences: Partial<UserPreferences>,
  country?: Country
) => {
  return {
    ...getDefaultPreferences(country),
    ...preferences,
  } as UserPreferences;
};

export const UserHelpers = {
  getDisplayedLanguage,
  getDefaultPreferences,
  getCompleteUserPreferences,
};
