import { useCallback, useEffect, useMemo } from "react";
import { CanvasOperations, Item, ItemOperations, ShapeType } from "draw";
import {
  useAppDispatch,
  useAppSelector,
  CanvasSelector,
  PlanEditorActions,
  PlanEditorSelector,
  CanvasActions,
  LayoutActions,
  ObjectEditorSelector,
  ObjectEditorActions,
} from "../../store";
import { Point, SentryReporter } from "imagine-essentials";
import { StaticItems } from "../../components";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { CursorMode, MarkerMode } from "../../enums";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for drawing all static items on the canvas.
 */
export const StaticPlanItemsContainer = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const planItems = useAppSelector(PlanEditorSelector.getAllItems);
  const selectedPlanItemIds = useAppSelector(
    PlanEditorSelector.getSelectedItemIds
  );
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const objectItems = useAppSelector(ObjectEditorSelector.getItems);
  const selectedObjectItemIds = useAppSelector(
    ObjectEditorSelector.getSelectedItemIds
  );
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );

  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const objectTemplates = useAppSelector(PlanEditorSelector.getObjectTemplates);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const month = useAppSelector(PlanEditorSelector.getPreviewPlanMonth);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const selectedShapeTool = useAppSelector(CanvasSelector.getSelectedShapeTool);
  const delimiter = I18nTools.getDelimiter(user.language);
  const markerMode = useAppSelector(CanvasSelector.getMarkerMode);

  const items = useMemo(() => {
    return objectMode ? objectItems : planItems;
  }, [objectMode, objectItems, planItems]);

  const selectedItemIds = useMemo(() => {
    return objectMode ? selectedObjectItemIds : selectedPlanItemIds;
  }, [objectMode, selectedObjectItemIds, selectedPlanItemIds]);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const staticItems = useMemo(() => {
    // Locked item are not displayed as selected, so they are always displayed as static
    return items.filter(
      (item: Item) => !selectedItemIds.includes(item.id) || item.locked
    );
  }, [items, selectedItemIds]);

  const locked = useMemo(() => {
    return (
      selectedShapeTool !== ShapeType.NONE ||
      markerMode === MarkerMode.DRAW_VIEW ||
      markerMode === MarkerMode.DRAW
    );
  }, [selectedShapeTool, markerMode]);

  // const getCursor = (locked?: boolean) => {
  //   if (props.cursor) return props.cursor;
  //   else if (locked) return "default";
  //   else return "move";
  // };
  const cursorOverride = useMemo(() => {
    if (cursorMode === CursorMode.DRAG) return "grab";
    if (selectedShapeTool !== ShapeType.NONE) return "crosshair";
    return undefined;
  }, [cursorMode, selectedShapeTool]);

  const setSelectedItems = useCallback(
    (items: Item[]) => {
      if (objectMode) {
        dispatch(ObjectEditorActions.setSelectedItems(items));
      } else {
        dispatch(PlanEditorActions.setSelectedItems(items));
      }
    },
    [dispatch, objectMode]
  );

  const selectItem = useCallback(
    (id: number, multiSelect?: boolean) => {
      // If item is already selected
      if (selectedItemIds.includes(id)) {
        // De-select if multi-select is enabled
        if (multiSelect !== true) {
          const newSelectedItems = ItemOperations.excludeItems(selectedItems, [
            id,
          ]);
          setSelectedItems(newSelectedItems);
          dispatch(LayoutActions.setActiveTab("item-tab"));
        }
        return;
      }
      // Item is not already selected
      const item = ItemOperations.findItem(items, id);
      if (item) {
        // Locked items can only be selected by right clicking
        if (item.locked) {
          // The locked item still takes the clock from the canvas, so it should deselect all other items
          setSelectedItems([]);
        } else {
          if (multiSelect) {
            setSelectedItems([...selectedItems, item]);
          } else {
            setSelectedItems([item]);
          }

          dispatch(LayoutActions.setActiveTab("item-tab"));
        }
      } else {
        SentryReporter.captureException("Failed to select item", { Item: id });
      }
      dispatch(CanvasActions.closeContextMenu());
    },
    [dispatch, items, selectedItemIds, selectedItems, setSelectedItems]
  );

  const clearSelected = (className: string) => {
    if (objectMode) {
      dispatch(ObjectEditorActions.setSelectedItems([]));
    } else {
      dispatch(PlanEditorActions.setSelectedItems([]));
    }
    dispatch(CanvasActions.closeContextMenu());
  };

  const openItemContextMenu = (itemId: number, position: Point) => {
    // This is the only way a locked item can be selected
    const item = ItemOperations.findItem(staticItems, itemId);
    setSelectedItems([item]);
    const pos = CanvasOperations.getPointSubtracted(position, canvasOffset);
    dispatch(CanvasActions.openContextMenu(pos));
    dispatch(LayoutActions.setActiveTab("item-tab"));
  };

  return (
    <StaticItems
      items={staticItems}
      plantTemplates={plantTemplates}
      objectTemplates={objectTemplates}
      showPlantHeights={userPreferences.showPlantHeights}
      canvasSize={canvasSize}
      zoom={zoom}
      zeroReference={zeroReference}
      layers={layers}
      groups={groups}
      month={month}
      locked={locked}
      cursor={cursorOverride}
      onItemClick={selectItem}
      onFastTouch={() => clearSelected("canvas")}
      onItemCtrlClick={(id: number) => selectItem(id, true)}
      onItemRightClick={openItemContextMenu}
      cursorMode={cursorMode}
      delimiter={delimiter}
      unitScale={userPreferences.unitScale}
    />
  );
};
