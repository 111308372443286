export enum HardinessZoneRHS {
  NONE = 0,
  ZONE_H1A = 1,
  ZONE_H1B = 2,
  ZONE_H1C = 3,
  ZONE_H2 = 4,
  ZONE_H3 = 5,
  ZONE_H4 = 6,
  ZONE_H5 = 7,
  ZONE_H6 = 8,
  ZONE_H7 = 9,
  _SIZE = 10,
}
