import {
  MembershipActions,
  MembershipSelector,
  MembershipThunks,
  StripeOrderConfirmation,
  StripeTools,
  SubscriptionTools,
} from "imagine-memberships";
import { useAppDispatch, useAppSelector } from "../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { UserActions, UserRole, UserSelector, UsersApi } from "imagine-users";
import { loadStripe } from "@stripe/stripe-js";
import { UrlParser } from "imagine-essentials";
import { useTranslation } from "react-i18next";
// import ReactPixel from "react-facebook-pixel";

const stripePromise = loadStripe(StripeTools.getPublicKey());

export const ConfirmationStripeContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [confirmationState, setConfirmationsState] = useState<
    "verifying" | "updating" | "ready" | "error" | "timeout" | "declined"
  >("verifying");
  const orderStatus = useAppSelector(MembershipSelector.getOrderStatus);
  const invoices = useAppSelector(MembershipSelector.getInvoices);
  const activeSubscription = useAppSelector(
    MembershipSelector.getActiveSubscription
  );
  const activeInvoice = useAppSelector(MembershipSelector.getActiveInvoice);
  const [retry, setRetry] = useState(4);

  const user = useAppSelector(UserSelector.getUserNotNull);

  const displayProduct = useMemo(() => {
    if (activeInvoice && activeSubscription) {
      return SubscriptionTools.getInvoiceDisplayProduct(
        activeInvoice,
        activeSubscription
      );
    }
    return undefined;
  }, [activeInvoice, activeSubscription]);

  // useEffect(() => {
  //   if (displayProduct && import.meta.env.PROD) {
  //     ReactPixel.track("Purchase", {
  //       currency: displayProduct.pricing[0].currency,
  //       value: displayProduct.pricing[0].amount,
  //     });
  //   }
  // }, [displayProduct]);

  const checkPaymentStatus = useCallback(async () => {
    const params = UrlParser.getSearchParams();
    const paymentIntentId = UrlParser.getParam(
      params,
      "payment_intent_client_secret"
    );
    if (paymentIntentId) {
      const stripe = await stripePromise;
      if (stripe) {
        const { paymentIntent } = await stripe.retrievePaymentIntent(
          paymentIntentId
        );
        if (paymentIntent?.status === "succeeded") {
          setConfirmationsState("updating");
          dispatch(MembershipThunks.fetchUserSubscriptions());
        } else {
          setConfirmationsState("error");
        }
      }
    }
  }, []);

  useEffect(() => {
    checkPaymentStatus();
  }, [checkPaymentStatus]);

  const updateUser = useCallback(async () => {
    // Tokens are automatically updated inside this function
    const response = await UsersApi.getLoggedInUser();
    if (response.success && response.data) {
      dispatch(UserActions.setUser(response.data.user));
      dispatch(UserActions.setPreferences(response.data.preferences));
      return response.data.user.role;
    }
  }, [dispatch]);

  useEffect(() => {
    const checkUserRole = async () => {
      if (confirmationState === "updating") {
        if (retry > 0) {
          console.log("Checking user role", retry);
          if (user.role === UserRole.PREMIUM) {
            setConfirmationsState("ready");
            clearInterval(interval);
          } else {
            setRetry((prev) => prev - 1);
            await updateUser();
          }
        } else {
          console.log("Giving up...");
          setConfirmationsState("timeout");
          clearInterval(interval);
        }
      }
    };

    const interval = setInterval(checkUserRole, 3000); // Wait for 5 seconds before checking again

    return () => {
      if (interval) {
        clearTimeout(interval);
      }
    };
  }, [confirmationState, retry, updateUser, user.role]);

  const reset = () => {
    dispatch(MembershipActions.clearPurchase());
  };

  return (
    <StripeOrderConfirmation
      product={displayProduct}
      status={confirmationState}
      onRetryClick={reset}
    />
  );
};
