import { UserActions, UserRole, UserSelector } from "imagine-users";
import { ObjectLibrary } from "../../components";
import {
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useState } from "react";
import { ItemType, ObjectCategory, ObjectSchema, ObjectsApi } from "draw";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { useTranslation } from "react-i18next";
import { I18nTools } from "imagine-i18n";

interface Props {
  visible: boolean;
}

export const ObjectLibraryContainer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const objects = useAppSelector(PlanEditorSelector.getObjectSearchResult);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);

  const [loading, setLoading] = useState(false);
  const selectedObjectTemplate = useAppSelector(
    PlanEditorSelector.getObjectStampTemplate
  );

  const search = async (category?: ObjectCategory, name?: string) => {
    setLoading(true);
    const objectTemplates = await ObjectsApi.search(category, name);
    if (objectTemplates) {
      dispatch(PlanEditorActions.setObjectSearchResult(objectTemplates));
    }
    setLoading(false);
  };

  const selectObjectTemplate = (id?: number) => {
    if (id === undefined) {
      dispatch(PlanEditorActions.clearStamp());
    }
    const selectedTemplate = objects.find((template: ObjectSchema) => {
      return template.id === id;
    });
    if (selectedTemplate !== undefined) {
      dispatch(PlanEditorActions.setStampTemplate(selectedTemplate));
      dispatch(PlanEditorActions.setStampType(ItemType.OBJECT));
      dispatch(PlanEditorActions.setSelectedItems([]));

      dispatch(UserActions.track(FeatureKey.DRAG_INSERT_OBJECT));
    }
  };

  return (
    <>
      {props.visible && (
        <>
          {user.role !== UserRole.NONE && (
            <ObjectLibrary
              objects={objects}
              onSearch={search}
              onSelectObjectTemplate={selectObjectTemplate}
              loading={loading}
              selectedObjectTemplateId={selectedObjectTemplate?.id}
              delimiter={delimiter}
              unitScale={userPreferences.unitScale}
            />
          )}
          {user.role === UserRole.NONE && (
            <p className="p text-sm">{t("loginToAccessObjectLibrary")}</p>
          )}
        </>
      )}
    </>
  );
};
