import React, { useMemo } from "react";
import {
  CanvasOperations,
  DimensionProperties,
  Marker,
  MarkerType,
  Measure,
  ShapeOperations,
  ShapeStyle,
} from "..";
import { Delimiter, Point, Tools } from "imagine-essentials";
import { UnitScale } from "project";

interface Props {
  dimension: DimensionProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  cursor?: string;
  className?: string;
  uniqueId?: string;
  delimiter: Delimiter;
  unitScale: UnitScale;
}

const lineWidthPx = 1;
// Hidden border with this width makes it possibel to still grab the border
const lineWidthPxGrab = 5;

const padding = 5;
const fontSizePx = 10;

export const DimensionSvg = (props: Props) => {
  const labelText = useMemo(() => {
    const ppp = props.pointsPerPixel.x;

    const distanceMeters = Tools.round(
      CanvasOperations.calculateDistance(
        props.dimension.start,
        props.dimension.end
      ),
      2
    );
    const distancePx = distanceMeters / ppp;

    if (distancePx < 50) {
      return "";
    }

    const text = Measure.getMeasureText(
      distanceMeters,
      props.unitScale,
      props.delimiter
    );
    const textWidth = Tools.round(
      ShapeOperations.getTextPxWidth(text, fontSizePx) + 2 * padding,
      1
    );

    const xDistancePx =
      Math.abs(props.dimension.start.x - props.dimension.end.x) / ppp;
    const yDistancePx =
      Math.abs(props.dimension.start.y - props.dimension.end.y) / ppp;

    // Arrows are approximately 12px wide each
    if (xDistancePx < textWidth + 24 && yDistancePx < 50) {
      return "";
    }

    return text;
  }, [
    props.delimiter,
    props.dimension.end,
    props.dimension.start,
    props.pointsPerPixel.x,
    props.unitScale,
  ]);

  const labelSize = useMemo(() => {
    const ppp = props.pointsPerPixel.x;
    return {
      width: Tools.round(
        (ShapeOperations.getTextPxWidth(labelText, fontSizePx) + 2 * padding) *
          ppp,
        3
      ),
      height: Tools.round((fontSizePx + 2 * padding) * ppp, 3),
    };
  }, [labelText, props.pointsPerPixel]);

  const labelCenter = useMemo(() => {
    return {
      x: Tools.round((props.dimension.start.x + props.dimension.end.x) / 2, 3),
      y: Tools.round((props.dimension.start.y + props.dimension.end.y) / 2, 3),
    };
  }, [props.dimension]);

  const fontSizeUnit = fontSizePx * props.pointsPerPixel.x;

  return (
    <g strokeWidth={1 * props.pointsPerPixel.x}>
      <Marker
        uniqueId={"marker-start-" + props.uniqueId || ""}
        type={MarkerType.ARROW_START}
        fill={props.style?.borderColor || "none"}
      />
      <Marker
        uniqueId={"marker-end-" + props.uniqueId || ""}
        type={MarkerType.ARROW_END}
        fill={props.style?.borderColor || "none"}
      />
      {/* We cannot use non-scaling-stroke on this line, because the markers
      are using the stroke-width as reference, and Safari cannot scale the 
      markers */}
      <line
        x1={props.dimension.start.x}
        y1={props.dimension.start.y}
        x2={props.dimension.end.x}
        y2={props.dimension.end.y}
        markerStart={"url(#marker-start-" + (props.uniqueId ?? "") + ")"}
        markerEnd={"url(#marker-end-" + (props.uniqueId ?? "") + ")"}
        stroke={ShapeOperations.getStroke(props.style)}
        strokeOpacity={ShapeOperations.getStrokeOpacity()}
        strokeDasharray={ShapeOperations.getStrokeDashArray(
          props.style,
          lineWidthPx
        )}
        style={{ cursor: props.cursor || "move" }}
        strokeWidth={lineWidthPx * props.pointsPerPixel.x}
        // vectorEffect="non-scaling-stroke"
      />
      <line
        x1={props.dimension.start.x}
        y1={props.dimension.start.y}
        x2={props.dimension.end.x}
        y2={props.dimension.end.y}
        strokeWidth={lineWidthPxGrab}
        vectorEffect="non-scaling-stroke"
        stroke={"red"}
        strokeOpacity={0}
        fill={"none"}
        style={{ cursor: props.cursor || "move" }}
        pointerEvents="stroke"
        className={props.className}
      />
      {labelText !== "" && (
        <>
          <rect
            x={labelCenter.x - labelSize.width / 2}
            y={labelCenter.y - labelSize.height / 2}
            width={labelSize.width}
            height={labelSize.height}
            fill="white"
            opacity={0.8}
            cursor={"grab"}
            className={props.className}
          />
          <text
            textAnchor="middle"
            dominantBaseline="middle"
            x={labelCenter.x}
            y={labelCenter.y}
            fill="black"
            fontSize={fontSizeUnit}
          >
            {labelText}
          </text>
        </>
      )}
    </g>
  );
};
