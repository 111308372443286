import { PlanInfo } from "draw";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";
import { Api, Tools } from "imagine-essentials";
import { Language } from "imagine-i18n";
import {
  ButtonDropdown,
  ButtonDropdownItem,
  Card,
  ContextListItem,
} from "imagine-ui";
import React from "react";
import { useTranslation } from "react-i18next";

interface Props {
  children?: React.ReactNode;
  planInfo: PlanInfo;
  elementId?: string;
  canDelete?: boolean;
  canRename?: boolean;
  canOpen?: boolean;
  onClick?: () => void;
  actions?: ContextListItem[];
}

export const PlanPreviewCard = (props: Props) => {
  const { t, i18n } = useTranslation();
  // Implement your component logic here

  return (
    <Card className="position-relative">
      <div className="column gap-sm h-full">
        <img
          src={
            Api.getHost() +
            "/user-plans/" +
            props.planInfo.id +
            ".png?r=" +
            Tools.getRandomNumber()
          }
          className="fluid"
        />
        <h5
          className="mt text-md--md"
          id={(props.elementId ? props.elementId + "-" : "") + "title"}
        >
          {props.planInfo.name}
        </h5>
        <p className="text-dark-muted text-sm">
          {t("draw:createdDate")}:{" "}
          {DateTimeText.getDbDateTimeText(
            props.planInfo.dateCreated,
            DateTimeFormat.SHORT_DATE
          )}
        </p>
        <p className="text-dark-muted text-sm">
          {t("draw:lastUpdateDate")}:{" "}
          {DateTimeText.getDbDateTimeText(
            props.planInfo.dateUpdated,
            DateTimeFormat.SHORT_DATE
          )}
        </p>
        <div className="stretch"></div>
        {props.children}
      </div>
      {props.actions && props.actions.length > 0 && (
        <div className="position-absolute top right pt-sm pr-sm">
          <ButtonDropdown
            size="sm"
            menuSize="sm"
            color="secondary"
            icon="more"
            className=""
            align="right"
          >
            {props.actions.map((action, index) => (
              <ButtonDropdownItem
                key={index}
                text={action.text}
                onClick={action.action}
                disabled={action.disabled}
                divider={action.divider}
              />
            ))}
          </ButtonDropdown>
        </div>
      )}
    </Card>
  );
};
