import {
  BorderType,
  CanvasOperations,
  Curve,
  CurvePoint,
  CurvedLinePathProperties,
  DimensionProperties,
  EllipseProperties,
  ItemCorner,
  LinePathProperties,
  Pattern,
  RectangleProperties,
  ShapeProperties,
  ShapeStyle,
  ShapeType,
  TextProperties,
  Trig,
} from "..";
import { Point, Rectangle, Size, Tools } from "imagine-essentials";

const getSanitizedRectangle = (
  start: Point,
  current: Point,
  minLimit: number
) => {
  // Need to copy, otherwise changing this will also change the parent value passed in
  const upperLeft = { ...start };
  const diff = CanvasOperations.getPointSubtracted(current, start);

  if (diff.x >= 0 && diff.x < minLimit) {
    diff.x = minLimit;
  } else if (diff.x < 0 && diff.x > -minLimit) {
    diff.x = -minLimit;
    upperLeft.x = start.x - minLimit;
  } else {
    upperLeft.x = Math.min(start.x, current.x);
  }

  if (diff.y >= 0 && diff.y < minLimit) {
    diff.y = minLimit;
  } else if (diff.y < 0 && diff.y > -minLimit) {
    diff.y = -minLimit;
    upperLeft.y = start.y - minLimit;
  } else {
    upperLeft.y = Math.min(start.y, current.y);
  }

  return {
    position: upperLeft,
    size: {
      width: Math.abs(Tools.round(diff.x, 1)),
      height: Math.abs(Tools.round(diff.y, 1)),
    },
  } as RectangleProperties;
};

const getCenter = (rectangle: Rectangle) => {
  return {
    x: rectangle.position.x + rectangle.size.width / 2,
    y: rectangle.position.y + rectangle.size.height / 2,
  } as Point;
};

/**
 * Get a new position that is rotated from another position.
 * @param position The original position.
 * @param original The rotation center.
 * @param rotation The roration angle in degrees.
 */
const getRotatedPosition = (
  position: Point,
  center: Point,
  rotation: number
) => {
  // Get vector from item rectangle center to position
  const originalVector = {
    x: Tools.round(position.x - center.x, 5),
    y: Tools.round(position.y - center.y, 5),
  };
  // Now rotate the original vector opposite rotation
  const angle = -rotation;
  const rotatedVector = Trig.getRotatedVector(originalVector, angle);
  // And get the vector end position
  const rotatedPosition = {
    x: Tools.round(center.x + rotatedVector.x, 5),
    y: Tools.round(center.y + rotatedVector.y, 5),
  };
  return rotatedPosition;
};

/**
 * Get the position of a point rotated from one position around a center, in a specified distance from the
 * center. An X degrees angle line with a speficic length.
 */
const getRotatedPositionLength = (
  position: Point,
  center: Point,
  rotation: number,
  length: number
) => {
  const pos = getRotatedPosition(position, center, rotation);
  const dist = CanvasOperations.calculateDistance(center, pos, 4);
  const factor = length / dist;
  const vector = CanvasOperations.getPointSubtracted(pos, center, 4);
  const adjustedVector = { x: vector.x * factor, y: vector.y * factor };
  return CanvasOperations.getPointSum(center, adjustedVector, 4);
};

/**
 * Get a new position that is rotated from another position.
 * @param position The original position.
 * @param original The original rectangle. The rotates is based on the center of the rectangle.
 * @param rotation The roration angle in degrees.
 */
const getRotatedPositionWithRectangle = (
  position: Point,
  original: Rectangle,
  rotation: number
) => {
  const center = getCenter(original);
  return getRotatedPosition(position, center, rotation);
};

const getLineShapeCenter = (curves: Curve[]) => {
  const min = { x: 99999, y: 99999 };
  const max = { x: -99999, y: -99999 };
  curves.forEach((curve: Curve) => {
    const cMin = getMinCurvePoint(curve);
    const cMax = getMaxCurvePoint(curve);
    if (cMin.x < min.x) min.x = cMin.x;
    if (cMin.y < min.y) min.y = cMin.y;
    if (cMax.x > max.x) max.x = cMax.x;
    if (cMax.y > max.y) max.y = cMax.y;
  });
  const size = CanvasOperations.getPointSubtracted(max, min, 5);
  return {
    x: Tools.round(min.x + size.x / 2, 5),
    y: Tools.round(min.y + size.y / 2, 5),
  } as Point;
};

/**
 * Calculates the new px size and position for a rectangle being resized.
 * @param position Current mouse position in px
 * @param resizeCorner The corner used for resizing
 * @param resizeStart The mouse position when resize started
 * @param original The original size ans position in px
 * @param rotation The rotation angle in deg (-180:180)
 * @param keepAspectRatio True if aspect ratio should be kept when the cornes are grabbed
 */
const getResize = (
  position: Point,
  resizeCorner: ItemCorner,
  resizeStart: Point,
  original: Rectangle,
  rotation: number,
  keepAspectRatio: boolean
) => {
  if (resizeCorner !== ItemCorner.NONE) {
    // Calculate offset and size diff based on starting position and current position
    const offset = { x: 0, y: 0 };
    const sizeDiff = { width: 0, height: 0 };

    let pos = { ...position };
    let startPos = { ...resizeStart };
    if (rotation !== 0) {
      pos = getRotatedPositionWithRectangle(position, original, rotation);
      startPos = getRotatedPositionWithRectangle(
        resizeStart,
        original,
        rotation
      );
    }

    if (
      resizeCorner === ItemCorner.N ||
      resizeCorner === ItemCorner.NE ||
      resizeCorner === ItemCorner.NW
    ) {
      sizeDiff.height = startPos.y - pos.y;
      offset.y = -sizeDiff.height;
    }
    if (
      resizeCorner === ItemCorner.S ||
      resizeCorner === ItemCorner.SE ||
      resizeCorner === ItemCorner.SW
    ) {
      sizeDiff.height = pos.y - startPos.y;
    }
    if (
      resizeCorner === ItemCorner.W ||
      resizeCorner === ItemCorner.NW ||
      resizeCorner === ItemCorner.SW
    ) {
      sizeDiff.width = startPos.x - pos.x;
      offset.x = -sizeDiff.width;
    }
    if (
      resizeCorner === ItemCorner.E ||
      resizeCorner === ItemCorner.NE ||
      resizeCorner === ItemCorner.SE
    ) {
      sizeDiff.width = pos.x - startPos.x;
    }

    // Always keep aspect ratio when the corners are selected
    if (keepAspectRatio) {
      if (
        resizeCorner === ItemCorner.NE ||
        resizeCorner === ItemCorner.NW ||
        resizeCorner === ItemCorner.SE ||
        resizeCorner === ItemCorner.SW
      ) {
        // Both should be the highest value
        if (Math.abs(sizeDiff.height) > Math.abs(sizeDiff.width)) {
          sizeDiff.width = sizeDiff.height;
        } else if (Math.abs(sizeDiff.height) < Math.abs(sizeDiff.width)) {
          sizeDiff.height = sizeDiff.width;
        }
        // Update offset
        if (resizeCorner === ItemCorner.NE || resizeCorner === ItemCorner.NW) {
          offset.y = -sizeDiff.height;
        }
        if (resizeCorner === ItemCorner.NW || resizeCorner === ItemCorner.SW) {
          offset.x = -sizeDiff.width;
        }
      }
    }

    // Calculate new size and position based on original
    let newPosition = {
      x: original.position.x + offset.x,
      y: original.position.y + offset.y,
    };

    const newSize = {
      width: original.size.width + sizeDiff.width,
      height: original.size.height + sizeDiff.height,
    };

    // Flip rectangle if size is negative
    if (newSize.width < 1) {
      newSize.width = Math.abs(newSize.width);
      newPosition.x = newPosition.x - newSize.width;
    }
    if (newSize.height < 1) {
      newSize.height = Math.abs(newSize.height);
      newPosition.y = newPosition.y - newSize.height;
    }

    // Adjust center to make sure unly the grabbed portion of the rectangle is changed
    if (rotation !== 0) {
      // Also update center (rectangle position)
      const originalCenter = getCenter(original);
      // Center of the new unrotated rectangle
      const newCenter = getCenter({ size: newSize, position: newPosition });
      // Vector from original center to this new center (still unrotated)
      const diffVector = CanvasOperations.getPointSubtracted(
        newCenter,
        originalCenter
      );
      // Now get vector to new center of rotates rectangle
      const rotatedCenter = Trig.getRotatedVector(diffVector, rotation);
      // Calculate the difference, the rectangle must be shifted by this
      const positionShifting = CanvasOperations.getPointSubtracted(
        rotatedCenter,
        diffVector
      );
      newPosition = CanvasOperations.getPointSum(newPosition, positionShifting);
    }

    // Return new size and position in Rectangle wrapper
    return {
      position: newPosition,
      size: newSize,
    } as Rectangle;
  } else {
    throw new Error("Cannot resize if resize corner is set to NONE");
  }
};

const rectangleToEllipse = (rectangle: RectangleProperties) => {
  return {
    radius: {
      width: Tools.round(rectangle.size.width / 2, 3),
      height: Tools.round(rectangle.size.height / 2, 3),
    },
    center: {
      x: Tools.round(rectangle.position.x + rectangle.size.width / 2, 3),
      y: Tools.round(rectangle.position.y + rectangle.size.height / 2, 3),
    },
  } as EllipseProperties;
};

const ellipseToRectagle = (ellipse: EllipseProperties) => {
  return {
    size: {
      width: Tools.round(ellipse.radius.width * 2, 3),
      height: Tools.round(ellipse.radius.height * 2, 3),
    },
    position: {
      x: Tools.round(ellipse.center.x - ellipse.radius.width, 3),
      y: Tools.round(ellipse.center.y - ellipse.radius.height, 3),
    },
  } as RectangleProperties;
};

/**
 * Get a straight line in curve format.
 * @param start
 * @param end
 */
const getStraightCurve = (start: Point, end: Point) => {
  const center = {
    x: (start.x + end.x) / 2,
    y: (start.y + end.y) / 2,
  };
  const handle1 = {
    x: Tools.round((start.x + center.x) / 2, 3),
    y: Tools.round((start.y + center.y) / 2, 3),
  };
  const handle2 = {
    x: Tools.round((center.x + end.x) / 2, 3),
    y: Tools.round((center.y + end.y) / 2, 3),
  };

  return {
    p1: start,
    h1: handle1,
    p2: end,
    h2: handle2,
  } as Curve;
};

/**
 * Convert a curved line path to a straight line path. All curves are removed.
 */
const curvedToStraightLine = (curvedLine: CurvedLinePathProperties) => {
  // The curves include both start and end point, only include start points for all
  const points = curvedLine.curves.map((curve: Curve) => {
    return curve.p1;
  });
  // Then add end point for the last curve
  points.push(curvedLine.curves[curvedLine.curves.length - 1].p2);
  return {
    points: points,
    closePath: curvedLine.closePath,
  } as LinePathProperties;
};

/**
 * Convert a straight line path to a curved line path. No curves will be set,
 * but the new format allows for settings the curves.
 */
const straightToCurvedLine = (line: LinePathProperties) => {
  const curves: Curve[] = [];
  line.points.forEach((point: Point, index: number) => {
    // The first point is added together with the second
    if (index > 0) {
      const curve = getStraightCurve(line.points[index - 1], point);
      curves.push(curve);
    }
  });
  return {
    curves: curves,
    closePath: line.closePath,
  } as CurvedLinePathProperties;
};

const getMinPoint = (a: Point, b: Point) => {
  return {
    x: a.x < b.x ? a.x : b.x,
    y: a.y < b.y ? a.y : b.y,
  } as Point;
};

const getMaxPoint = (a: Point, b: Point) => {
  return {
    x: a.x > b.x ? a.x : b.x,
    y: a.y > b.y ? a.y : b.y,
  } as Point;
};

/**
 * Get the minimum x and y values for all four points in a curve.
 */
const getMinCurvePoint = (curve: Curve) => {
  let x = curve.p1.x;
  let y = curve.p1.y;
  if (curve.p2.x < x) x = curve.p2.x;
  if (curve.h1.x < x) x = curve.h1.x;
  if (curve.h2.x < x) x = curve.h2.x;
  if (curve.p2.y < y) y = curve.p2.y;
  if (curve.h1.y < y) y = curve.h1.y;
  if (curve.h2.y < y) y = curve.h2.y;
  return {
    x: x,
    y: y,
  };
};

/**
 * Get the maximum x and y values for all four points in a curve.
 */
const getMaxCurvePoint = (curve: Curve) => {
  let x = curve.p1.x;
  let y = curve.p1.y;
  if (curve.p2.x > x) x = curve.p2.x;
  if (curve.h1.x > x) x = curve.h1.x;
  if (curve.h2.x > x) x = curve.h2.x;
  if (curve.p2.y > y) y = curve.p2.y;
  if (curve.h1.y > y) y = curve.h1.y;
  if (curve.h2.y > y) y = curve.h2.y;
  return {
    x: x,
    y: y,
  };
};

/**
 * Get the rectangle the contains the dimension. The position will always
 * be the upper left corner.
 */
const dimensionToRectangle = (dimension: DimensionProperties) => {
  const min = getMinPoint(dimension.start, dimension.end);
  const max = getMaxPoint(dimension.start, dimension.end);
  const diff = CanvasOperations.getPointSubtracted(max, min);

  return {
    position: min,
    size: {
      width: diff.x,
      height: diff.y,
    },
  } as RectangleProperties;
};

/**
 * Wrap the index around so it fits within an array of the given length.
 */
const getFittedIndex = (index: number, length: number) => {
  if (index < 0) {
    return length + index;
  }
  if (index >= length) {
    return index - length;
  }
  return index;
};

/**
 * Get a snap point if the position is within the max distance of the snap point. Snap points are
 * calculated as lines perpendicular, 45 degrees pr 135 degrees to the previous line or the closing line.
 * Returns null if no snap point is found.
 * @param position Current mouse position.
 * @param index The index
 * @param curvePoint The curvepoint that is being moved. Use NONE when creating a new line point.
 * @param curves Curves drawn so far.
 * @param maxDistance The maximum distance to the snap point before it should snap.
 */
const getAngleBasedSnapPoint = (
  position: Point,
  index: number,
  curvePoint: CurvePoint | null,
  curves: Curve[],
  maxDistance: number
) => {
  let pos = { ...position };
  let snap: Point | null = null;
  // Nothing to snap to for the first line
  if (
    (curvePoint === CurvePoint.NONE && curves.length > 1) ||
    (curvePoint !== CurvePoint.NONE && curves.length > 2)
  ) {
    // Adjust the indexes to wrap around
    let currentIndex = index;
    let previousIndex = getFittedIndex(index - 1, curves.length);
    // Assuming the end handle is being updated, the line to compare with is the one after the
    // next one (the one after will be updated when the handle updates)
    let nextIndex = getFittedIndex(index + 2, curves.length);

    // All calculations below expects that the end point/handle is the end handle of the line
    // If handle is actually a start handle, then adjust index
    if (curvePoint === CurvePoint.START) {
      currentIndex = getFittedIndex(currentIndex - 1, curves.length);
      previousIndex = getFittedIndex(previousIndex - 1, curves.length);
      nextIndex = getFittedIndex(nextIndex - 1, curves.length);
    }

    if (curvePoint === CurvePoint.NONE) {
      nextIndex = 0;
    }

    // Get snap point compared to the previous line (always)

    // Is the line made from the point close to a -90 deg angle
    let length = CanvasOperations.calculateDistance(
      curves[currentIndex].p1,
      pos,
      4
    );
    let snapPoint = getRotatedPositionLength(
      curves[previousIndex].p1,
      curves[previousIndex].p2,
      -90,
      length
    );
    let snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint, 4);
    if (snapPointDist < maxDistance) {
      snap = { ...snapPoint };
    }
    // Is the line made from the point close to a 90 deg angle
    if (snap === null) {
      snapPoint = getRotatedPositionLength(
        curves[previousIndex].p1,
        curves[previousIndex].p2,
        90,
        length
      );
      snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
      if (snapPointDist < maxDistance) {
        snap = { ...snapPoint };
      }
    }
    // Is the line made from the point close to a 45 deg angle
    if (snap === null) {
      snapPoint = getRotatedPositionLength(
        curves[previousIndex].p1,
        curves[previousIndex].p2,
        45,
        length
      );
      snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
      if (snapPointDist < maxDistance) {
        snap = { ...snapPoint };
      }
    }
    // Is the line made from the point close to a -45 deg angle
    if (snap === null) {
      snapPoint = getRotatedPositionLength(
        curves[previousIndex].p1,
        curves[previousIndex].p2,
        -45,
        length
      );
      snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
      if (snapPointDist < maxDistance) {
        snap = { ...snapPoint };
      }
    }

    if (snap !== null) {
      pos = snap;
    }

    // Angle between this line and the next
    length = CanvasOperations.calculateDistance(curves[nextIndex].p1, pos);
    snapPoint = getRotatedPositionLength(
      curves[nextIndex].p2,
      curves[nextIndex].p1,
      90,
      length
    );
    snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
    if (snapPointDist < maxDistance) {
      if (snap !== null) {
        const intersection = Trig.getLinesIntersectionPoint(
          curves[currentIndex].p1,
          snap,
          curves[nextIndex].p1,
          snapPoint
        );

        if (intersection !== null) {
          return intersection;
        }
      }
      return snapPoint;
    }
    snapPoint = getRotatedPositionLength(
      curves[nextIndex].p2,
      curves[nextIndex].p1,
      -90,
      length
    );
    snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
    if (snapPointDist < maxDistance) {
      if (snap !== null) {
        const intersection = Trig.getLinesIntersectionPoint(
          curves[currentIndex].p1,
          snap,
          curves[nextIndex].p1,
          snapPoint
        );
        if (intersection !== null) {
          return intersection;
        }
      }
      return snapPoint;
    }
    snapPoint = getRotatedPositionLength(
      curves[nextIndex].p2,
      curves[nextIndex].p1,
      45,
      length
    );
    snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
    if (snapPointDist < maxDistance) {
      if (snap !== null) {
        const intersection = Trig.getLinesIntersectionPoint(
          curves[currentIndex].p1,
          snap,
          curves[nextIndex].p1,
          snapPoint
        );
        if (intersection !== null) {
          return intersection;
        }
      }
      return snapPoint;
    }
    snapPoint = getRotatedPositionLength(
      curves[nextIndex].p2,
      curves[nextIndex].p1,
      -45,
      length
    );
    snapPointDist = CanvasOperations.calculateDistance(pos, snapPoint);
    if (snapPointDist < maxDistance) {
      if (snap !== null) {
        const intersection = Trig.getLinesIntersectionPoint(
          curves[currentIndex].p1,
          snap,
          curves[nextIndex].p1,
          snapPoint
        );
        if (intersection !== null) {
          return intersection;
        }
      }
      return snapPoint;
    }
  }
  return snap;
};

/**
 * Returns the snap point if the position is near any of the points in the curves. Does not check first and last point.
 */
const getCurveSnapPoint = (
  position: Point,
  curves: Curve[],
  tolerance: number
) => {
  let snap: Point | null = null;
  curves.forEach((curve: Curve, index: number) => {
    if (index > 0) {
      const dist = CanvasOperations.calculateDistance(position, curve.p1);
      if (dist < tolerance) snap = curve.p1;
    }
  });
  return snap;
};

/**
 * 
 * 
  borderColor?: string;
  fillColor?: string;
  fillPattern?: number; // ID of a predefined pattern
  opacity?: number;
  borderType?: BorderType;
  shineColor?: string; // Color of a shine spot (for plants)
};
 */

const getFill = (style: ShapeStyle | undefined, patternId?: string) => {
  if (style === undefined) return "none";
  if (style.opacity === 0) return "none";
  if (style.fillPattern !== undefined && style.fillPattern !== Pattern.NONE) {
    if (patternId === undefined) {
      console.error("Missing pattern ID");
      return "url(#" + style.fillPattern + "-shape)";
    }
    return "url(#" + style.fillPattern + "-" + patternId + ")";
  }
  if (style.fillColor !== undefined) return style.fillColor;
  return "none";
};

const getOpacity = (style: ShapeStyle | undefined) => {
  if (style === undefined) return 1;
  if (style.opacity === undefined) return 1;
  return style.opacity;
};

const getStroke = (style: ShapeStyle | undefined) => {
  if (style === undefined) return "none";
  if (style.borderType === BorderType.NONE) return "none";
  if (style.borderColor !== undefined) return style.borderColor;
  return "none";
};

const getStrokeOpacity = () => {
  return 1;
};

const getStrokeDashArray = (
  style: ShapeStyle | undefined,
  lineWidth: number
) => {
  if (style === undefined) return "none";
  if (style.borderType === BorderType.DASHED)
    return lineWidth * 6 + " " + lineWidth * 6;
  if (style.borderType === BorderType.DOTTED)
    return lineWidth * 1 + " " + lineWidth * 2;
  return "none";
};

/**
 * Move a all points in a curves linepath a given distance.
 */
const moveCurvedLinePath = (curves: Curve[], dist: Point) => {
  return curves.map((curve: Curve) => {
    return {
      p1: CanvasOperations.getPointSum(curve.p1, dist, 3),
      p2: CanvasOperations.getPointSum(curve.p2, dist, 3),
      h1: CanvasOperations.getPointSum(curve.h1, dist, 3),
      h2: CanvasOperations.getPointSum(curve.h2, dist, 3),
    } as Curve;
  });
};

/**
 * Get a snap point if any corner in the rectangle is within the distance limit. If multiple corners are
 * within the distance, the closest point are returned. Null are returned if not within distance.
 * @param rectangleProps The rectangel properties.
 * @param pos The position to compare against.
 * @param distance The distance limit.
 */
const getRectangleSnapPoint = (
  rectangleProps: RectangleProperties,
  pos: Point,
  distance: number
) => {
  let minDistance = CanvasOperations.calculateDistance(
    rectangleProps.position,
    pos
  );
  let snapPosition = rectangleProps.position;
  const topRightCorner = {
    x: rectangleProps.position.x + rectangleProps.size.width,
    y: rectangleProps.position.y,
  };
  const bottomLeftCorner = {
    x: rectangleProps.position.x,
    y: rectangleProps.position.y + rectangleProps.size.height,
  };
  const bottomRightCorner = {
    x: rectangleProps.position.x + rectangleProps.size.width,
    y: rectangleProps.position.y + rectangleProps.size.height,
  };

  let d = CanvasOperations.calculateDistance(topRightCorner, pos);
  if (d < minDistance) {
    minDistance = d;
    snapPosition = topRightCorner;
  }

  d = CanvasOperations.calculateDistance(bottomLeftCorner, pos);
  if (d < minDistance) {
    minDistance = d;
    snapPosition = bottomLeftCorner;
  }

  d = CanvasOperations.calculateDistance(bottomRightCorner, pos);
  if (d < minDistance) {
    minDistance = d;
    snapPosition = bottomRightCorner;
  }

  if (minDistance <= distance) return snapPosition;
  else return null;
};

/**
 * Parses a viewbox string and returns the width and height in a Size object.
 * @param viewBox
 */
const getViewSize = (viewBox: string) => {
  const val = viewBox.split(" ");
  if (val.length < 4) {
    throw new Error(
      "Viewbox passed to ResizeSvg is not correct format: " + viewBox
    );
  }
  return {
    width: Tools.round(Number(val[2]), 4),
    height: Tools.round(Number(val[3]), 4),
  } as Size;
};

const pointsPerPixel = (viewBox: string, pixelSize: Size) => {
  const viewSize = getViewSize(viewBox);

  const ppp = {
    x: viewSize.width / pixelSize.width,
    y: viewSize.height / pixelSize.height,
  } as Point;

  if (isNaN(ppp.x)) ppp.x = 0;
  if (isNaN(ppp.y)) ppp.y = 0;

  return ppp;
};

const getBoundingRectangleFromLinePath = (lineShape: LinePathProperties) => {
  const min = { x: 99999, y: 99999 };
  const max = { x: -99999, y: -99999 };
  lineShape.points.forEach((point: Point) => {
    if (point.x < min.x) min.x = point.x;
    if (point.x > max.x) max.x = point.x;
    if (point.y < min.y) min.y = point.y;
    if (point.y > max.y) max.y = point.y;
  });
  const size = {
    width: max.x - min.x,
    height: max.y - min.y,
  };
  return {
    position: min,
    size: size,
  } as Rectangle;
};

const getBoundingRectangleFromCurvedLinePath = (
  curvedLineShape: CurvedLinePathProperties
) => {
  const min = { x: 99999, y: 99999 };
  const max = { x: -99999, y: -99999 };
  curvedLineShape.curves.forEach((curve: Curve) => {
    const curveMin = getMinCurvePoint(curve);
    const curveMax = getMaxCurvePoint(curve);
    if (curveMin.x < min.x) min.x = curveMin.x;
    if (curveMax.x > max.x) max.x = curveMax.x;
    if (curveMin.y < min.y) min.y = curveMin.y;
    if (curveMax.y > max.y) max.y = curveMax.y;
  });
  const size = {
    width: max.x - min.x,
    height: max.y - min.y,
  };
  return {
    position: min,
    size: size,
  } as Rectangle;
};

const getBoundingRectangleFromEllipse = (ellipse: EllipseProperties) => {
  const pos = {
    x: ellipse.center.x - ellipse.radius.width,
    y: ellipse.center.y - ellipse.radius.height,
  };
  const size = {
    width: ellipse.radius.width * 2,
    height: ellipse.radius.height * 2,
  };
  return {
    position: pos,
    size: size,
  } as Rectangle;
};

const getBoundingRectangleFromText = (text: TextProperties) => {
  return {
    position: text.position,
    size: text.size,
  } as Rectangle;
};

const getBoundingRectangle = (shape: ShapeProperties) => {
  switch (shape.type) {
    case ShapeType.RECTANGLE:
      return shape.properties as Rectangle;
    case ShapeType.ELLIPSE:
      return getBoundingRectangleFromEllipse(
        shape.properties as EllipseProperties
      );
    case ShapeType.LINE:
      return getBoundingRectangleFromLinePath(
        shape.properties as LinePathProperties
      );
    case ShapeType.CURVED_LINE:
      return getBoundingRectangleFromCurvedLinePath(
        shape.properties as CurvedLinePathProperties
      );
    case ShapeType.TEXT:
      return getBoundingRectangleFromText(shape.properties as TextProperties);
    default:
      console.error(
        "Bounding rectangle not supported for shape type",
        shape.type
      );
      return {
        position: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
      };
  }
};

const getTextPxWidth = (text: string, fontSizePx: number) => {
  // TODO: Will this delete the element, or will it always exist (= memory leak)?
  const element = document.createElement("canvas");
  const context = element.getContext("2d");
  let width = 0;
  if (context !== null) {
    context.font = fontSizePx + "px Noto Sans";
    // context.font = fontSizePx + "px Segoe UI";
    width = context.measureText(text).width;
  }
  element.remove();
  return width;
};

/**
 * This will split a texts into lines such that all lines a kept within the max width (unless a really long word exceeds the width alone)
 * @param text
 * @param fontSizePx
 * @param maxWidth
 */
const textToLines = (text: string, fontSizePx: number, maxWidth: number) => {
  const element = document.createElement("canvas");
  const context = element.getContext("2d");
  const lines: string[] = [];
  if (context !== null) {
    context.font = fontSizePx + "px Noto Sans";
    const paragraphs = text.split("\n");
    let lineToAdd = "";
    let lineToTest = "";
    paragraphs.forEach((paragraph: string) => {
      const words = paragraph.split(" ");

      words.forEach((word: string) => {
        if (word === "") return;
        if (lineToTest !== "") lineToTest += " ";
        lineToTest += word;
        if (context.measureText(lineToTest).width <= maxWidth) {
          lineToAdd = lineToTest;
        } else {
          // Width exceeded
          if (lineToAdd !== "") {
            lines.push(lineToAdd);
            lineToAdd = word;
            lineToTest = word;
          } else {
            // A single word exceeded the width. Add anyway
            lines.push(word);
            lineToAdd = "";
            lineToTest = "";
          }
        }
      });
      // if (lineToAdd !== "") {
      lines.push(lineToAdd);
      lineToAdd = "";
      lineToTest = "";
      // }
    });
    // lines.push(lineToAdd);
  }
  return lines;
};

export const ShapeOperations = {
  getSanitizedRectangle,
  getRotatedPosition,
  getRotatedPositionLength,
  getRotatedPositionWithRectangle,
  getLineShapeCenter,
  getResize,
  rectangleToEllipse,
  ellipseToRectagle,
  getStraightCurve,
  curvedToStraightLine,
  straightToCurvedLine,
  getMinPoint,
  getMaxPoint,
  getMinCurvePoint,
  getMaxCurvePoint,
  dimensionToRectangle,
  moveCurvedLinePath,
  getAngleBasedSnapPoint,
  getCurveSnapPoint,
  getFill,
  getOpacity,
  getStroke,
  getStrokeOpacity,
  getStrokeDashArray,
  getViewSize,
  pointsPerPixel,
  getBoundingRectangle,
  getTextPxWidth,
  textToLines,
  getRectangleSnapPoint,
};
