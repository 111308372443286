import { Spinner } from "imagine-ui";

type Props = {
  message: string;
};

export const LoadingMessage = (props: Props) => {
  return (
    <div className="canvas-loading-message column gap-lg items-x-center">
      <span className="text-center text-lg" id="loading-message">
        {props.message}
      </span>
      <span className="pb">
        <Spinner size="lg" color="accent" />
      </span>
    </div>
  );
};
