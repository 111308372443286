import { useTranslation } from "react-i18next";

import { FeatureKey } from "project";
import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  CanvasSelector,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
} from "../../store";
import { ShapeType } from "draw";
import { ToolButton, ToolSeparator } from "../../components";
import { useMemo } from "react";
import { UserActions } from "imagine-users";
import { Device } from "imagine-ui";
import { CursorMode } from "../../enums";

/**
 * Displays the tool and actions in the home toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const DrawToolbarContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const mapFrozen = useAppSelector(CanvasSelector.isMapFrozen);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const isPreviewMode = useAppSelector(PlanEditorSelector.getPreviewPlan);
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const isTouchDevice = Device.isTouchDevice();

  const selectedShape = useAppSelector(CanvasSelector.getSelectedShapeTool);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const copiedPlanItems = useAppSelector(PlanEditorSelector.getCopiedItems);
  const copiedObjectItems = useAppSelector(ObjectEditorSelector.getCopiedItems);

  const copiedItems = useMemo(() => {
    return objectMode ? copiedObjectItems : copiedPlanItems;
  }, [objectMode, copiedObjectItems, copiedPlanItems]);

  const canUndoPlan = useAppSelector(PlanEditorSelector.isUndoAllowed);
  const canUndoObject = useAppSelector(ObjectEditorSelector.isUndoAllowed);
  const canUndo = objectMode ? canUndoObject : canUndoPlan;

  const canRedoPlan = useAppSelector(PlanEditorSelector.isRedoAllowed);
  const canRedoObject = useAppSelector(ObjectEditorSelector.isRedoAllowed);
  const canRedo = objectMode ? canRedoObject : canRedoPlan;

  const selectShapeTool = (shape: ShapeType) => {
    if (selectedShape === shape) {
      dispatch(CanvasActions.setSelectedShapeTool(ShapeType.NONE));
      return;
    } else {
      dispatch(CanvasActions.setSelectedShapeTool(shape));
      dispatch(PlanEditorActions.setEditingArea(false));
      dispatch(PlanEditorActions.setEditingReferenceImage(false));
    }

    switch (shape) {
      case ShapeType.RECTANGLE:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_RECTANGLE));
        break;
      case ShapeType.ELLIPSE:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_ELLIPSE));
        break;
      case ShapeType.LINE:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_LINES));
        break;
      case ShapeType.CURVED_LINE:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_CURVED_LINES));
        break;
      case ShapeType.TEXT:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_TEXT));
        break;
      case ShapeType.DIMENSION:
        dispatch(UserActions.track(FeatureKey.NAV_DRAW_DIMENSION));
        break;
    }
    if (objectMode) {
      dispatch(ObjectEditorActions.setSelectedItemIds([]));
    } else {
      dispatch(PlanEditorActions.setSelectedItems([]));
    }
  };

  const modificationsLocked = useMemo(() => {
    return (
      mapFrozen ||
      isPreviewMode ||
      (cursorMode === CursorMode.DRAG && !isTouchDevice)
    );
  }, [cursorMode, isPreviewMode, isTouchDevice, mapFrozen]);

  const copyItems = () => {
    if (selectedItems.length < 1) {
      return;
    }
    if (objectMode) {
      dispatch(ObjectEditorActions.setCopiedItems(selectedItems));
    } else {
      dispatch(PlanEditorActions.setCopiedItems(selectedItems));
    }
  };

  const pasteItems = () => {
    if (copiedItems.length < 1) {
      return;
    }
    if (objectMode) {
      dispatch(ObjectEditorActions.pasteCopiedItems());
    } else {
      dispatch(PlanEditorActions.pasteCopiedItems());
    }
  };

  const undo = () => {
    if (!canUndo) return;
    if (objectMode) {
      dispatch(ObjectEditorActions.undo());
    } else {
      dispatch(PlanEditorActions.undo());
    }
  };

  const redo = () => {
    if (!canRedo) return;
    if (objectMode) {
      dispatch(ObjectEditorActions.redo());
    } else {
      dispatch(PlanEditorActions.redo());
    }
  };

  const toggleSnap = () => {
    // TODO
  };

  return (
    <div id="draw-toolbar" className="toolbar-row">
      <ToolButton
        elementId="rectangle-nav-button"
        text={t("rectangle")}
        icon="rectangle-shape"
        onClick={() => selectShapeTool(ShapeType.RECTANGLE)}
        disabled={modificationsLocked}
        selected={selectedShape === ShapeType.RECTANGLE}
      />
      <ToolButton
        elementId="ellipse-nav-button"
        text={t("ellipse")}
        icon="ellipse-shape"
        onClick={() => selectShapeTool(ShapeType.ELLIPSE)}
        disabled={modificationsLocked}
        selected={selectedShape === ShapeType.ELLIPSE}
      />
      <ToolButton
        elementId="line-path-nav-button"
        text={t("linePath")}
        icon="line-shape"
        onClick={() => selectShapeTool(ShapeType.LINE)}
        disabled={modificationsLocked}
        selected={selectedShape === ShapeType.LINE}
      />
      <ToolButton
        elementId="curved-line-path-nav-button"
        text={t("curvedLinePath")}
        icon="curved-line-shape"
        onClick={() => selectShapeTool(ShapeType.CURVED_LINE)}
        disabled={modificationsLocked}
        selected={selectedShape === ShapeType.CURVED_LINE}
      />

      <ToolButton
        elementId="text-nav-button"
        text={t("text")}
        icon="text-shape"
        onClick={() => selectShapeTool(ShapeType.TEXT)}
        selected={selectedShape === ShapeType.TEXT}
        disabled={modificationsLocked}
      />

      <ToolButton
        elementId="dimension-nav-button"
        text={t("dimension")}
        icon="dimension-shape"
        onClick={() => selectShapeTool(ShapeType.DIMENSION)}
        selected={selectedShape === ShapeType.DIMENSION}
        disabled={objectMode || modificationsLocked}
      />

      <ToolSeparator />

      <ToolButton
        elementId="copy-nav-button"
        text={t("imagine:copy")}
        icon="copy"
        onClick={copyItems}
        disabled={selectedItems.length < 1 || modificationsLocked}
      />

      <ToolButton
        elementId="paste-nav-button"
        text={t("paste")}
        icon="paste"
        onClick={pasteItems}
        disabled={copiedItems.length < 1 || modificationsLocked}
      />
      <ToolSeparator />

      <ToolButton
        elementId="undo-nav-button"
        text={t("undo")}
        icon="undo"
        onClick={undo}
        disabled={!canUndo || modificationsLocked}
      />

      <ToolButton
        elementId="redo-nav-button"
        text={t("redo")}
        icon="redo"
        onClick={redo}
        disabled={!canRedo || modificationsLocked}
      />
    </div>
  );
};
