import { useTranslation } from "react-i18next";
import { DisplayProduct, SubscriptionProductCard } from "..";
import { Button, Spinner } from "imagine-ui";

interface Props {
  product?: DisplayProduct;
  status: "verifying" | "updating" | "ready" | "error" | "timeout" | "declined";
  onRetryClick: () => void;
}

export const StripeOrderConfirmation = (props: Props) => {
  const { t } = useTranslation();

  return (
    <div id="buy-membership-confirmation" className="">
      <div className="grid columns-3 columns-2--lg columns-1--sm gap-xl gap--lg">
        <div className="colspan-2 colspan-1--l">
          {props.status === "declined" && (
            <>
              <h2 className="mb-xl" id="buy-membership-payment-declined">
                {t("memberships:sorry")}
              </h2>
              <p className="mb-xl">{t("memberships:paymentDeclined")}</p>
              <Button
                elementId="retry-buy-membership"
                onClick={props.onRetryClick}
              >
                {t("memberships:retry")}
              </Button>
            </>
          )}
          {(props.status === "verifying" || props.status === "updating") && (
            <>
              <h2 className="mb-xl" id="buy-membership-thanks">
                {t("memberships:thanksForMembership")}
              </h2>
              <div className="row my items-center">
                <Spinner className="mt-xl" />
              </div>
              <p className="text-center mb-xl">
                {props.status === "verifying" &&
                  t("memberships:verifyingPayment")}
                {props.status === "updating" &&
                  t("memberships:preparingNewFeatures")}
              </p>
            </>
          )}
          {props.status === "ready" && (
            <>
              <h2 className="mb-xl" id="buy-membership-thanks">
                {t("memberships:thanksForMembership")}
              </h2>
              <p id="buy-membership-features-ready">
                {t("memberships:newFunctionalitiesAvailable")}
              </p>
              <p className="mb-xl">{t("memberships:confirmationEmailSent")}</p>
            </>
          )}
          {props.status === "timeout" && (
            <>
              <h2 className="mb-xl" id="buy-membership-thanks">
                {t("memberships:thanksForMembership")}
              </h2>
              <p className="mb-xl">{t("memberships:confirmationEmailSent")}</p>
              <p>{t("memberships:prepareFeaturesTimeout")}</p>
            </>
          )}
        </div>
        <div className="">
          {props.product && (
            <SubscriptionProductCard
              product={props.product}
              hideButton
              elementId="bought-membership"
            />
          )}
        </div>
      </div>
    </div>
  );
};
