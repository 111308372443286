import { ModelDesign, ShapeType, TopTemplate } from "..";
import _ from "lodash";

export const FruitTemplates: TopTemplate[] = [
  {
    id: 1,
    name: "Large fruits",
    paths: [
      "M 22,66.109954 C 30.934027,63.131944 31.67853,81 22,81 12.321469,81 12.321469,63.131944 22,66.109954 Z M 52.5,47.5 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z M 76,65.109954 C 84.934027,62.131944 85.67853,80 76,80 66.321469,80 66.321469,62.131944 76,65.109954 Z m -51,-36 C 33.934027,26.131944 34.67853,44 25,44 15.321469,44 15.321469,26.131944 25,29.109954 Z m 55,3.5 C 88.934027,29.631944 89.67853,47.5 80,47.5 70.321469,47.5 70.321469,29.631944 80,32.609954 Z M 56,10 c 8.934027,-2.97801 9.67853,14.890046 0,14.890046 -9.678531,0 -9.678531,-17.868056 0,-14.890046 z",
    ],
  },
  {
    id: 2,
    name: "Medium fruits",
    paths: [
      "m 31.408992,75.047219 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 67.5,75.993347 C 72.787295,75.185953 73.354324,85 67.5,85 c -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 87.193892,48.966411 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 50,58.493347 C 55.287295,57.685953 55.854324,67.5 50,67.5 c -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 15,47.5 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.8543252,0 -5.9029372,-9.814047 0,-9.006653 z M 36.194743,34.699319 c 5.287295,-0.807394 5.854324,9.006653 0,9.006653 -5.854325,0 -5.902937,-9.814047 0,-9.006653 z M 65,35.993347 C 70.287295,35.185953 70.854324,45 65,45 c -5.854325,0 -5.902937,-9.814047 0,-9.006653 z m 7.5,-22.5 C 77.787295,12.685953 78.354324,22.5 72.5,22.5 c -5.854325,0 -5.902937,-9.814047 0,-9.006653 z m -30,-2.5 C 47.787295,10.185953 48.354324,20 42.5,20 c -5.854325,0 -5.902937,-9.814047 0,-9.006653 z",
    ],
  },
  {
    id: 3,
    name: "Small fruits",
    paths: [
      "m 43.8916,69.735826 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 64.805156,21.260612 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -42.091948,8.7395 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 60,40 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -68,-3 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 44,-7 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -26.694844,-47.7832 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m -20,35.047486 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.5563561,0 -3.6783521,-5.5 0,-5.471316 z m 22.694844,36.040764 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 67.5,82.028684 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 35.930858,50.000112 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 21.78235,-42 c 3.580903,-0.028684 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 5.74035,33.792858 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z m 23.21765,7.735714 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 83.976364,29.057256 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z M 43.8916,30.000112 c 3.580903,-0.02868 3.556355,5.471316 0,5.471316 -3.556356,0 -3.678352,-5.5 0,-5.471316 z",
    ],
  },
];
