import {
  useAppDispatch,
  useAppSelector,
  LayoutSelector,
  LayoutActions,
  PlantManagementThunks,
} from "../../store";
import { PlanInfo } from "draw";
import { useCallback, useEffect, useState } from "react";
import { Pages } from "../../enums";
import { OpenPlanModal } from "../../components";
import { PlansApi } from "../../api";
import { useTranslation } from "react-i18next";
import { PlanThunks } from "../../utils";
import { NotificationType, useNotification } from "imagine-ui";

/**
 *
 */
export const OpenPlanPageContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { t } = useTranslation();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const [userPlans, setUserPlans] = useState<PlanInfo[]>([]);
  const [publicPlans, setPublicPlans] = useState<PlanInfo[]>([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const loadPlans = useCallback(async () => {
    const plans = await PlansApi.getUserPlans(0);
    if (plans !== undefined) {
      setUserPlans(plans);
    } else {
      setErrorMessage(t("failedToLoadUserPlans"));
    }
    const plansPublic = await PlansApi.getPublicPlans();
    if (plansPublic !== undefined) {
      setPublicPlans(plansPublic);
    }
  }, [t]);

  const openPlan = async (planId: number) => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    const opened = await PlanThunks.openPlan(dispatch, planId);
    if (!opened) {
      notification.add(NotificationType.ERROR, t("errorOpeningPlan"));
    }
  };

  const deletePlan = async (planId: number) => {
    const response = await PlansApi.deletePlan(planId);
    if (!response.success) {
      notification.add(NotificationType.ERROR, t("errorDeletingPlan"));
    } else {
      const autoOpenPlanId = localStorage.getItem("planId");
      // If the plan that was deleted was the one that was set to auto open, remove the auto open plan id
      if (autoOpenPlanId && parseInt(autoOpenPlanId) === planId) {
        localStorage.removeItem("planId");
      }
    }
    loadPlans();
  };

  const requestDeletePlan = (planId: number, planName: string) => {
    notification.add(
      NotificationType.CONFIRM,
      t("confirmDeletePlan", { name: planName })
    );
    notification.onConfirm(() => {
      deletePlan(planId);
    });
  };

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  return (
    <>
      {displayedPage === Pages.OPEN_PLAN}
      <OpenPlanModal
        userPlans={userPlans}
        publicPlans={publicPlans}
        onClose={close}
        onOpenPlan={openPlan}
        onDeletePlan={requestDeletePlan}
      />
    </>
  );
};
