import { useTranslation } from "react-i18next";
import { PlantCardAction } from "../types";
import { PlantText } from "../utils";
import { Api, Delimiter } from "imagine-essentials";
import { Badge, ButtonDropdown, ButtonDropdownItem, Icon } from "imagine-ui";
import { PlantPropertyIcon } from "./PlantPropertyIcon";
import { DateTimeText } from "imagine-datetime";
import { Measure, PlantModelText, PlantSchema } from "draw";
import { HardinessZoneRHS, UnitScale } from "project";

type Props = {
  plant: PlantSchema;
  showSeason?: boolean;
  showProperties?: boolean;
  showHeight?: boolean;
  amount?: number; /// Displays amount if set
  className?: string; /// Classname for container, will override default card layout
  boxed?: boolean; // True if the plant card should be displayed inside a boxed layout
  actions?: PlantCardAction[];
  elementId?: string;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneRHS?: boolean;
};

const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

/**
 * Displays a plant card. A visual overview of the plant and it's properties.
 * @param props
 */
export const PlantCard = (props: Props) => {
  const [t] = useTranslation();

  const getDisplayColor = (month: number) => {
    if (props.plant.flowerSeason.includes(month)) {
      return props.plant.flowerColor;
    } else if (props.plant.leafColorTransitionalSeason?.includes(month)) {
      return props.plant.leafColorTransitional || "#e9ecef";
    } else if (props.plant.leafSeason.includes(month)) {
      return props.plant.leafColor;
    } else return "#e9ecef"; // Color a little lighter than the default outline color
  };

  /**
   * This is a workaround, because browsers does not display background-colors on print versions. Otherwise
   * user has to enable background colors manually when printing.
   */
  const getBackgroundColorWorkaround = (month: number) => {
    return {
      boxShadow: "inset 0 0 0 1000px " + getDisplayColor(month),
      height: "12px",
    };
  };

  const getClassName = () => {
    let className = "text-sm";
    if (props.boxed) className += " card border--print";
    if (props.className) className += " " + props.className;
    return className;
  };

  return (
    <div className={getClassName()} id={props.elementId}>
      <div className="plant-card">
        <div className="plant-card-image">
          {props.plant.image && (
            <img
              src={Api.getHost() + "/plant-images/" + props.plant.image}
              alt={props.plant.name}
              className="fluid mb-sm--sm"
            />
          )}
        </div>
        <div className="ml plant-card-info">
          <div className="row items-y-center items-space-between">
            <div className="row items-y-center">
              <span>{props.plant.name} &nbsp;</span>
            </div>
            <div className="row items-y-center gap">
              {/* <div className="d-none--sm">
                {props.showHeight &&
                  Measure.getMeasureTextInterval(
                    props.plant.heightMin || 0,
                    props.plant.heightMax || 0,
                    props.unitScale,
                    props.delimiter,
                    true,
                    true
                  )}
              </div> */}
              {props.actions !== undefined && (
                <ButtonDropdown
                  icon="more"
                  align="right"
                  color="secondary"
                  size="sm"
                  menuSize="lg"
                  elementId={props.elementId + "-actions"}
                >
                  {props.actions?.map(
                    (action: PlantCardAction, index: number) => (
                      <ButtonDropdownItem
                        key={index}
                        text={action.label}
                        onClick={action.action}
                        disabled={action.disabled}
                        divider={action.newGroup}
                      />
                    )
                  )}
                </ButtonDropdown>
              )}
            </div>
          </div>
          <div className="div">
            <span className="text-italic">
              {PlantText.getLatinAndHybridName(props.plant)}
            </span>
            {props.showHardinessZoneRHS &&
              props.plant.hardinessZoneRhs !== null &&
              props.plant.hardinessZoneRhs !== HardinessZoneRHS.NONE && (
                <Badge color="secondary" className="ml-sm">
                  {PlantText.getPlantHardinessZoneRHS(props.plant)}
                </Badge>
              )}
          </div>
          <div className="mt-xs">
            {props.showHeight &&
              Measure.getMeasureTextInterval(
                props.plant.heightMin || 0,
                props.plant.heightMax || 0,
                props.unitScale,
                props.delimiter,
                true,
                true
              )}
          </div>
          {props.amount !== undefined && (
            <div className="mt-sm">
              <Badge className="text-bold" color="dark">
                {props.amount} {t("plants:piecesAmount")}
              </Badge>
            </div>
          )}
          {props.showProperties === true && (
            <p className="pt-sm text-sm">
              {props.plant.properties?.sun === true && (
                <PlantPropertyIcon property="sun" />
              )}
              {props.plant.properties?.semiShade === true && (
                <PlantPropertyIcon property="semiShade" />
              )}
              {props.plant.properties?.shade === true && (
                <PlantPropertyIcon property="shade" />
              )}

              {props.plant.properties?.loamSoil === true && (
                <PlantPropertyIcon property="loamSoil" />
              )}
              {props.plant.properties?.sandySoil === true && (
                <PlantPropertyIcon property="sandySoil" />
              )}
              {props.plant.properties?.claySoil === true && (
                <PlantPropertyIcon property="claySoil" />
              )}

              {props.plant.properties?.drainedSoil === true && (
                <PlantPropertyIcon property="drainedSoil" />
              )}
              {props.plant.properties?.drySoil === true && (
                <PlantPropertyIcon property="drySoil" />
              )}
              {props.plant.properties?.wetSoil === true && (
                <PlantPropertyIcon property="wetSoil" />
              )}
              {props.plant.properties?.waterPlant === true && (
                <PlantPropertyIcon property="waterPlant" />
              )}

              {props.plant.properties?.neutralSoil === true && (
                <PlantPropertyIcon property="neutralSoil" />
              )}
              {props.plant.properties?.acidSoil === true && (
                <PlantPropertyIcon property="acidSoil" />
              )}
              {props.plant.properties?.alkalicSoil === true && (
                <PlantPropertyIcon property="alkalicSoil" />
              )}

              {props.plant.properties?.groundcover === true && (
                <PlantPropertyIcon property="groundcover" />
              )}
              {props.plant.properties?.edible === true && (
                <PlantPropertyIcon property="edible" />
              )}
              {props.plant.properties?.cutFlowers === true && (
                <PlantPropertyIcon property="cutFlowers" />
              )}
              {props.plant.properties?.bees === true && (
                <PlantPropertyIcon property="bees" />
              )}
              {props.plant.properties?.birds === true && (
                <PlantPropertyIcon property="birds" />
              )}

              {props.plant.properties?.nitrogen === true && (
                <PlantPropertyIcon property="nitrogen" />
              )}
              {props.plant.properties?.carbon === true && (
                <PlantPropertyIcon property="carbon" />
              )}

              {props.plant.properties?.evergreen === true && (
                <PlantPropertyIcon property="evergreen" />
              )}
              {props.plant.properties?.winter === true && (
                <PlantPropertyIcon property="winter" />
              )}
              {props.plant.properties?.fallColors === true && (
                <PlantPropertyIcon property="fallColors" />
              )}

              {props.plant.properties?.fragrant === true && (
                <PlantPropertyIcon property="fragrant" />
              )}
              {props.plant.properties?.allergyFriendly === true && (
                <PlantPropertyIcon property="allergyFriendly" />
              )}
              {props.plant.properties?.native === true && (
                <PlantPropertyIcon property="native" />
              )}

              {props.plant.properties?.wind === true && (
                <PlantPropertyIcon property="wind" />
              )}
              {props.plant.properties?.coast === true && (
                <PlantPropertyIcon property="coast" />
              )}
              {props.plant.properties?.pots === true && (
                <PlantPropertyIcon property="pots" />
              )}
              {props.plant.properties?.hedge === true && (
                <PlantPropertyIcon property="hedge" />
              )}

              {props.plant.properties?.poisonous === true && (
                <PlantPropertyIcon property="poisonous" />
              )}
              {props.plant.properties?.thorns === true && (
                <PlantPropertyIcon property="thorns" />
              )}
              {props.plant.properties?.rootSpreading === true && (
                <PlantPropertyIcon property="rootSpreading" />
              )}
              {props.plant.properties?.seedSpreading === true && (
                <PlantPropertyIcon property="seedSpreading" />
              )}
              {props.plant.properties?.specialCare === true && (
                <PlantPropertyIcon property="specialCare" />
              )}
            </p>
          )}
          {props.showSeason === true && (
            <div className="grid columns-12 pt-sm">
              {months.map((month: number) => (
                <div
                  className={
                    "border-top border-bottom border-right" +
                    (month === 1 ? " border-left" : "")
                  }
                  key={month.toString()}
                >
                  <div
                    className={"month print-background"}
                    // style={{backgroundColor: "black"}}
                    style={getBackgroundColorWorkaround(month)}
                    title={DateTimeText.getMonthTextFull(month)}
                  ></div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
