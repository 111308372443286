import { useMemo } from "react";
import { Modal } from ".";

type ColorInputProps = {
  show: boolean;
  color: string | undefined;
  onChange?: (hex: string) => void;
  onClose: () => void;
  colors: string[];
};

/**
 * Displays a small modal with a grid of colors to select from.
 * @param props
 * @returns
 */
export const ColorPicker = (props: ColorInputProps) => {
  const handleColorChange = (newColor: string) => {
    if (newColor === undefined) {
      console.warn("Unexpected color format: ", newColor);
      return;
    }
    if (props.onChange) {
      props.onChange(newColor);
    }
  };

  const close = () => {
    props.onClose();
  };

  const colorRows = useMemo(() => {
    const rows: string[][] = [];
    let currentRow: string[] = [];
    props.colors.forEach((c: string) => {
      currentRow.push(c);
      if (currentRow.length >= 12) {
        rows.push([...currentRow]);
        currentRow = [];
      }
    });
    return rows;
  }, [props.colors]);

  return (
    <>
      {props.show && (
        <Modal
          className="color-modal"
          visible
          onClose={close}
          hideFooter
          discreteHeader
          center
          elementId="color-picker-modal"
        >
          <div className="color-picker">
            {colorRows.map((row: string[], index: number) => (
              <div className="color-row" key={index}>
                {row.map((circle: string, colIndex: number) => (
                  <div
                    key={circle}
                    className={
                      "color-square" +
                      (circle === props.color ? " selected" : "")
                    }
                  >
                    <div className="color-padding">
                      <div
                        className="circle"
                        id={"color-circle-" + index + "-" + colIndex}
                        style={{
                          backgroundColor: circle,
                          border:
                            circle === "#ffffff" ? "1px solid #ddd" : "none",
                        }}
                        onClick={() => handleColorChange(circle)}
                      ></div>
                    </div>
                  </div>
                ))}
              </div>
            ))}
          </div>
        </Modal>
      )}
    </>
  );
};
