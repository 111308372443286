import { Link } from "imagine-ui";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const getImages = (language: string) => {
  switch (language) {
    case "da":
      return [
        "https://gardensketcher.com/upload/img/profil-visning/da-kamera.png",
        "https://gardensketcher.com/upload/img/profil-visning/da-profile-juli.png",
      ];
    default:
      return [
        "https://gardensketcher.com/upload/img/profil-visning/en-camera.png",
        "https://gardensketcher.com/upload/img/profil-visning/en-scenery-juli.png",
      ];
  }
};

interface Props {
  profileViewUrl: string;
  referenceImageUrl: string;
}

export const Version2Dot5 = (props: Props) => {
  const { t, i18n } = useTranslation();

  const images = useMemo(() => getImages(i18n.language), [i18n.language]);

  return (
    <>
      <h5 className="mt">{t("2_5_newFeature")}</h5>
      <p className="mt-sm">{t("2_5_profileViewDescription")}</p>
      <p className="mt">
        <Link href={props.profileViewUrl} target="_blank">
          {t("2_5_readMore")}
        </Link>
      </p>
      <div className="grid columns-2 columns-1--md gap-xl mt-xl">
        {images.map((image, index) => (
          <div key={index}>
            <img src={image} alt="" className="fluid" />
          </div>
        ))}
      </div>
      <h5 className="mt">{t("2_5_customReferenceImage")}</h5>
      <p className="mt-sm">{t("2_5_customReferenceImageDescription")}</p>

      <p className="mt">
        <Link href={props.referenceImageUrl} target="_blank">
          {t("2_5_readMore")}
        </Link>
      </p>
    </>
  );
};
