import { useCallback, useEffect, useMemo, useState } from "react";

import { Trans, useTranslation } from "react-i18next";
import { Alert, Button, Checkbox, Link, Select, TextInput } from "imagine-ui";
import { Country, I18nData, I18nTools, Language } from "imagine-i18n";

type SignUpProps = {
  conditionsUrl: string;
  dataProcessingAgreementUrl: string;
  onSignUpClick: (
    email: string,
    password: string,
    country?: Country,
    language?: Language,
    ipCountry?: string
  ) => void; // Triggered if the user requests to login instead
  onLoginClick: () => void;
  loading?: boolean;
  className?: string;
  errorMessage?: string;
  emailErrorMessage?: string;
  passwordErrorMessage?: string;
  showCountrySelector?: boolean;
  onCountryVote?: (country: string, ip: string) => void;
};

/**
 * Displays a sign-up form.
 */
export const SignUp = (props: SignUpProps) => {
  const { t, i18n } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [country, setCountry] = useState<Country | undefined>(undefined);
  const [userCountry, setUserCountry] = useState<string>("");
  const [countryUnsupported, setCountryUnsupported] = useState(false);
  const [acceptConditions, setAcceptConditions] = useState(false);
  const [countryName, setCountryName] = useState("");
  const [ip, setIp] = useState("");
  const [voted, setVoted] = useState(false);

  const loadUserCountry = useCallback(async () => {
    if (!props.showCountrySelector) return;
    const data = await I18nTools.getUserLocationInfo();
    console.log("IP data", data);
    const countryCode = data?.country;
    const countryText = data?.country_name;
    setCountryName(countryText || "");
    console.log("Detected country:", countryCode);
    setUserCountry(countryCode);
    setIp(data?.ip || "");
    if (I18nTools.isCountrySupported(countryCode)) {
      setCountry(countryCode as Country);
    } else {
      setCountryUnsupported(true);
    }
  }, []);

  useEffect(() => {
    loadUserCountry();
  }, [loadUserCountry]);

  // Whether the data has been validated, this does not indicate whether validation passed or failed
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  // const [validated, setValidated] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [acceptConditionsError, setAcceptConditionsError] = useState("");

  const validatePassword = (password: string) => {
    return password.length > 5 && password.length < 30;
  };

  const validate = () => {
    let valid = true;
    if (email === "") {
      setEmailError(t("users:enterEmail"));
      valid = false;
    } else {
      setEmailError("");
    }
    if (password === "") {
      setPasswordError(t("users:enterPassword"));
      valid = false;
    } else if (!validatePassword(password)) {
      setPasswordError(t("users:passwordMin6Max30Characters"));
      valid = false;
    } else {
      setPasswordError("");
    }

    if (!acceptConditions) {
      setAcceptConditionsError(t("users:acceptConditionsAndAgreement"));
      valid = false;
    } else {
      setAcceptConditionsError("");
    }
    // setValidated(true);
    return valid;
  };

  const disableSubmit = useMemo(() => {
    if (email === "" || password === "" || !acceptConditions) return true;
    if (props.showCountrySelector && !country) return true;
    return false;
  }, [email, password, acceptConditions, country, props.showCountrySelector]);

  const signup = () => {
    if (validate()) {
      const language = i18n.language;
      props.onSignUpClick(
        email,
        password,
        country,
        language as Language,
        userCountry
      );
    }
  };

  useEffect(() => {
    if (acceptConditions) setAcceptConditionsError("");
  }, [acceptConditions]);

  const countryOptions = useMemo(() => {
    if (i18n.language) {
      return I18nData.getCountryOptions();
    }
    return [];
  }, [i18n.language]);

  const vote = () => {
    if (props.onCountryVote) {
      props.onCountryVote(userCountry, ip);
      setVoted(true);
    }
  };

  return (
    <div className={props.className} id="signup">
      {props.errorMessage && (
        <div id="signup-general-error" className="text-danger">
          {props.errorMessage}
        </div>
      )}
      {props.showCountrySelector && countryUnsupported && (
        <Alert color="warning" className="mb">
          {t("users:yourCountryIsNotSupported")}
          <div className="text-center">
            <Button
              className="mt"
              color="warning"
              size="sm"
              onClick={vote}
              disabled={voted}
            >
              {t("users:prioritizeMyCountry")} ({countryName})
            </Button>
          </div>
        </Alert>
      )}
      <div id="signup-form">
        <div className="mb">
          <span className="label">{t("users:emailAddress")}</span>
          <TextInput
            elementId="signup-email"
            invalid={emailError !== ""}
            value={email}
            onChange={(value) => setEmail(value)}
          />
          <div id="signup-email-error" className="text-danger">
            {emailError || props.emailErrorMessage}
          </div>
        </div>
        <div className="mb">
          <span className="label">{t("users:password")}</span>
          <TextInput
            elementId="signup-password"
            password={true}
            invalid={passwordError !== ""}
            value={password}
            onChange={(value) => setPassword(value)}
            // onValid={() => setPasswordError("")}
          />
          <div id="signup-password-error" className="text-danger">
            {passwordError || props.passwordErrorMessage}
          </div>
        </div>
        {props.showCountrySelector && (
          <div className="mb">
            <span className="label">{t("imagine:country")}</span>
            <Select
              options={countryOptions}
              selected={country}
              onSelectedString={(value) => {
                setCountry(value as Country);
              }}
            />
          </div>
        )}
        <div className="mb">
          <Checkbox
            id="signup-accept-conditions"
            invalid={acceptConditionsError !== ""}
            checked={acceptConditions}
            onChange={(checked) => setAcceptConditions(checked)}
          >
            <Trans
              i18nKey="users:acceptingTermsAndConditions"
              components={{
                link1: <Link href={props.conditionsUrl} target="_blank" />,
                link2: (
                  <Link
                    href={props.dataProcessingAgreementUrl}
                    target="_blank"
                  />
                ),
              }}
            />
          </Checkbox>
          <div id="signup-accept-conditions-error" className="text-danger">
            {acceptConditionsError}
          </div>
        </div>
        <div className="row items-center">
          <Button
            elementId="signup-submit"
            className="mt mt-lg--md"
            onClick={signup}
            loading={props.loading}
            disabled={disableSubmit}
          >
            {t("users:createFreeAccount")}
          </Button>
        </div>
        {props.onLoginClick && (
          <>
            <hr className="my-lg" />
            <p className="text-center">
              {t("users:alreadyHaveAccount")}{" "}
              <Link elementId="signup-login-link" onClick={props.onLoginClick}>
                {t("users:login")}
              </Link>
            </p>
          </>
        )}
      </div>
    </div>
  );
};
