import React, { useMemo } from "react";
import {
  CanvasOperations,
  Curve,
  CurvedLinePathProperties,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
} from "..";
import { Point } from "imagine-essentials";

const getPath = (line: CurvedLinePathProperties) => {
  let newPath = "";
  line.curves.forEach((curve: Curve) => {
    if (newPath === "") {
      newPath = "M " + curve.p1.x + " " + curve.p1.y;
    }
    newPath += " C " + curve.h1.x + " " + curve.h1.y;
    newPath += ", " + curve.h2.x + " " + curve.h2.y;
    newPath += ", " + curve.p2.x + " " + curve.p2.y;
  });
  return newPath;
};

const getCenter = (line: CurvedLinePathProperties) => {
  const finalMin = { x: 99999, y: 99999 };
  const finalMax = { x: -99999, y: -99999 };
  line.curves.forEach((curve: Curve) => {
    const min = ShapeOperations.getMinCurvePoint(curve);
    const max = ShapeOperations.getMaxCurvePoint(curve);
    if (min.x < finalMin.x) finalMin.x = min.x;
    if (min.y < finalMin.y) finalMin.y = min.y;
    if (max.x > finalMax.x) finalMax.x = max.x;
    if (max.y > finalMax.y) finalMax.y = max.y;
  });
  const size = CanvasOperations.getPointSubtracted(finalMax, finalMin);
  return {
    x: finalMin.x + size.x / 2,
    y: finalMin.y + size.y / 2,
  } as Point;
};

const defaultStyle: ShapeStyle = {
  borderColor: "#000000",
  fillColor: "#CCCCCC",
  opacity: 1,
};

const lineWidthPx = 1;
// Hidden border with this width makes it possibel to still grab the border
const lineWidthPxGrab = 5;

interface Props {
  curvedLinePath: CurvedLinePathProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  cursor?: string;
  rotation?: number;
  className?: string;
  uniqueId?: string; // Used to link the patterns to the shapes
}

/**
 * Draws a path from a line path property. The width is always 1 px. It could also be set to a
 * given width in m or cm, but that requires more work to adjust the viewbox to include a wide
 * line width.
 * @param props
 */
export const CurvedLinePathSvg = (props: Props) => {
  const style = props.style !== undefined ? props.style : defaultStyle;

  const path = useMemo(() => {
    return getPath(props.curvedLinePath);
  }, [props.curvedLinePath]);

  const center = useMemo(() => {
    return getCenter(props.curvedLinePath);
  }, [props.curvedLinePath]);

  return (
    <g
      transform={
        "rotate(" +
        (props.rotation || 0) +
        ", " +
        center.x +
        ", " +
        center.y +
        ")"
      }
    >
      <defs>
        <ShapePattern
          color={style.fillColor}
          startPosition={center}
          id={props.uniqueId}
          pattern={style.fillPattern}
        />
      </defs>
      <path
        d={path}
        stroke={ShapeOperations.getStroke(props.style)}
        strokeOpacity={ShapeOperations.getStrokeOpacity()}
        strokeDasharray={ShapeOperations.getStrokeDashArray(style, lineWidthPx)}
        fill={ShapeOperations.getFill(style, props.uniqueId)}
        fillOpacity={ShapeOperations.getOpacity(style)}
        style={{ cursor: props.cursor || "move" }}
        className={props.className}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
      />
      <path
        d={path}
        stroke={"red"}
        strokeOpacity={0}
        fill={"none"}
        style={{ cursor: props.cursor || "move" }}
        className={props.className}
        strokeWidth={lineWidthPxGrab}
        vectorEffect="non-scaling-stroke"
      />
    </g>
  );
};
