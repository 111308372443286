import {
  CancelReason,
  MembershipInfo,
  MembershipSelector,
  MembershipType,
  MembershipsApi,
  MembershipsTools,
  Payments,
  StripeInvoices,
} from "imagine-memberships";
import { UserSelector } from "imagine-users";
import { useAppSelector } from "../../store";
import { InvoiceData, MembershipProductTemplates } from "project";
import { Device } from "imagine-ui";
import { useMemo } from "react";

export const PaymentsContainer = () => {
  const user = useAppSelector(UserSelector.getUserNotNull);
  const membershipDeals = useAppSelector(MembershipSelector.getDeals);
  const isTouchDevice = Device.isTouchDevice();
  const invoices = useAppSelector(MembershipSelector.getInvoices);

  // Filter out upcoming draft invoice, and 0 amount invoices
  const pastInvoices = useMemo(() => {
    return invoices.filter(
      (inv) => inv.status !== "draft" && inv.status !== "void" && inv.total > 0
    );
  }, [invoices]);

  const membershipProducts = MembershipsTools.getMembershipProducts(
    MembershipProductTemplates,
    user.country
  );
  const membershipHistory = useAppSelector(
    MembershipSelector.getMembershipHistory
  );

  const invoiceInfo = InvoiceData.getInvoiceInfo();

  return (
    <>
      <StripeInvoices invoices={pastInvoices} />
      <Payments
        membershipHistory={membershipHistory}
        membershipProducts={membershipProducts}
        membershipDeals={membershipDeals}
        invoiceInfo={invoiceInfo}
        download={isTouchDevice}
      />
    </>
  );
};
