/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CurvedLinePathProperties,
  DimensionProperties,
  EllipseProperties,
  LinePathProperties,
  PathProperties,
  RectangleProperties,
  ShapeType,
  TextProperties,
} from "..";

const isRectangle = (value: any) => {
  if (
    (value as RectangleProperties).position &&
    (value as RectangleProperties).size
  ) {
    return true;
  } else {
    return false;
  }
};

const isPath = (value: any) => {
  if ((value as PathProperties).path) {
    return true;
  } else {
    return false;
  }
};

const isEllipse = (value: any) => {
  if (
    (value as EllipseProperties).center &&
    (value as EllipseProperties).radius
  ) {
    return true;
  } else {
    return false;
  }
};

const isLinePath = (value: any) => {
  if ((value as LinePathProperties).points) {
    return true;
  } else {
    return false;
  }
};

const isCurvedLinePath = (value: any) => {
  if ((value as CurvedLinePathProperties).curves) {
    return true;
  } else {
    return false;
  }
};

const isDimension = (value: any) => {
  if (
    (value as DimensionProperties).start &&
    (value as DimensionProperties).end
  ) {
    return true;
  } else {
    return false;
  }
};

const isText = (value: any) => {
  const res =
    (value as TextProperties).text !== undefined &&
    (value as TextProperties).position !== undefined &&
    (value as TextProperties).size !== undefined &&
    (value as TextProperties).fontSize !== undefined;

  return res;
};

const isShape = (value: any) => {
  if (value.type === undefined) return false;
  if (value.properties === undefined) return false;
  switch (value.type as ShapeType) {
    case ShapeType.RECTANGLE:
      return isRectangle(value.properties);
    case ShapeType.ELLIPSE:
      return isEllipse(value.properties);
    case ShapeType.LINE:
      return isLinePath(value.properties);
    case ShapeType.CURVED_LINE:
      return isCurvedLinePath(value.properties);
    case ShapeType.DIMENSION:
      return isDimension(value.properties);
    case ShapeType.PATH:
      return isPath(value.properties);
    default:
      return false;
  }
};

export const ShapeValidation = {
  isRectangle,
  isPath,
  isEllipse,
  isLinePath,
  isCurvedLinePath,
  isDimension,
  isText,
  isShape,
};
