import { Slider } from "imagine-ui";

interface Props {
  text: string;
  min: number;
  max: number;
  value?: number; // Need to update this value within 1000ms, otherwise the thumb position jumps back
  onChange?: (value: number) => void;
  stepSize?: number;
  className?: string;
  disabled?: boolean;
  elementId?: string;
}

export const ToolSlider = (props: Props) => {
  return (
    <div
      className={
        "tool-container tool-slider" +
        (props.className ? " " + props.className : "")
      }
    >
      <div className="text">{props.text}</div>
      <Slider
        min={props.min}
        max={props.max}
        stepSize={props.stepSize}
        value={props.value}
        onChange={props.onChange}
        className={""}
        disabled={props.disabled}
        id={props.elementId}
      />
    </div>
  );
};
