import { UserActions } from "imagine-users";
import { ZoneManager } from "../../components";
import {
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { Group, ItemOperations } from "draw";
import { FeatureKey } from "project";
import { useTranslation } from "react-i18next";
import { NotificationType, useNotification } from "imagine-ui";

interface Props {
  visible: boolean;
}

export const GroupsContainer = (props: Props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const isSmallDevice = useAppSelector(LayoutSelector.isSmOrSmaller);
  const items = useAppSelector(PlanEditorSelector.getAllItems);

  const updateGroups = (groups: Group[]) => {
    dispatch(PlanEditorActions.setGroups(groups));
  };

  const updateGroup = (group: Group) => {
    dispatch(PlanEditorActions.updateGroup(group));
  };

  const addGroup = (group: Group) => {
    dispatch(PlanEditorActions.addGroup(group));
    dispatch(UserActions.track(FeatureKey.CREATE_GROUP));
  };

  const deleteGroup = (id: number) => {
    const groupItems = ItemOperations.getItemsInGroup(items, id);
    if (groupItems.length === 0) {
      dispatch(PlanEditorActions.deleteGroup(id));
    } else {
      notification.add(
        NotificationType.CONFIRM,
        t("deletingGroupWillDeleteItems", { count: groupItems.length })
      );
      notification.onConfirm(() => {
        dispatch(PlanEditorActions.deleteMultipleItems(groupItems));
        dispatch(PlanEditorActions.deleteGroup(id));
      });
    }

    dispatch(PlanEditorActions.deleteGroup(id));
  };

  const selectAllElements = (id: number) => {
    dispatch(PlanEditorActions.selectAllElementsInGroup(id));
  };

  return (
    <>
      {props.visible && (
        <ZoneManager
          zones={groups}
          onReorderZones={updateGroups}
          onUpdateZone={updateGroup}
          onAddZone={addGroup}
          onDeleteZone={deleteGroup}
          onSelectAllElements={selectAllElements}
          defaultName={t("group")}
          smallDevice={isSmallDevice}
          minCount={1}
          elementId="groups"
          itemIdPrefix="group"
          addZoneText={t("newGroup")}
        />
      )}
    </>
  );
};
