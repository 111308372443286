import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CanvasOperations,
  DimensionProperties,
  EventProcess,
  ItemCorner,
  RectangleProperties,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
  ShapeType,
  Mouse,
  MouseState,
} from "draw";
import { Delimiter, Point, Rectangle } from "imagine-essentials";
import { ResizeSvg, ShapeDimension } from ".";
import { Device } from "imagine-ui";
import { UnitScale } from "project";

// If rectangle width and height is smaller than this when created,
// the creator will exit immediately without creating the rectangle
const minimimSize = 2;
const longTouchTime = 600;

// Width and height will snap to this minimum value if smaller
const minWidthHeight = 8;

interface Props {
  lineWidth: number;
  style: ShapeStyle;
  dimensionStyle: ShapeStyle;
  rectangle: RectangleProperties;
  shapeId: number; // Used to detect when a new shape is selected
  onEditingChange: (editing: boolean) => void; // Triggered when editing is started/stopped (dragging or resizing)
  // onEditingStart?: () => void;
  onRectangleChange: (shape: RectangleProperties) => void;
  update: boolean;
  // onFinish: () => void;
  onClick?: () => void;
  onRightClick?: (pos: Point) => void;
  onLongTouch?: (pos: Point) => void;
  shapeType: ShapeType; /// Must be either RECTANGLE or ELLIPSE
  rotation: number;
  className?: string;
  canvasOffset: Point;
  zoom: number;
  cursorOverride?: string;
  disabled?: boolean; // Will not trigger on mouse events if set to true
  delimiter: Delimiter;
  unitScale: UnitScale;
}

const isTouchDevice = Device.isTouchDevice();

/**
 * Creator of rectangles and ellipses. All functions are similar for the rectangles and
 * ellipses, only the displayed shape is different, so they are merged in one component
 * to minimize maintenance source.
 */
export const RectangleCreator = (props: Props) => {
  const {
    onRectangleChange,
    onEditingChange,
    onRightClick,
    onClick,
    onLongTouch,
  } = props;

  const [startingPoint, setStartingPoint] = useState<Point | null>(null);
  const [resizeStart, setResizeStart] = useState<Point | null>(null);
  const [resizeCorner, setResizeCorner] = useState<ItemCorner>(ItemCorner.NONE);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [tempRectangle, setTempRectangle] = useState<
    RectangleProperties | undefined
  >(undefined);

  const rectangle = useMemo(() => {
    if (tempRectangle !== undefined) return tempRectangle;
    else return props.rectangle;
  }, [tempRectangle, props.rectangle]);

  // Original rectangle (needed for resizing)
  const [rectangleReference, setRectangleReference] =
    useState<RectangleProperties>(props.rectangle);

  const ellipse = useMemo(() => {
    return ShapeOperations.rectangleToEllipse(rectangle);
  }, [rectangle]);

  const getBoardPosition = useCallback(
    (position: Point) => {
      return CanvasOperations.getPointSubtracted(position, props.canvasOffset);
    },
    [props.canvasOffset]
  );

  const startDrawing = useCallback(
    (position: Point) => {
      if (!props.update) {
        const start = getBoardPosition(position);
        setStartingPoint(start);
      }
    },
    [getBoardPosition, props.update]
  );

  const updateShape = useCallback(
    (position: Point) => {
      if (startingPoint !== null) {
        const sanitizedRectangle = ShapeOperations.getSanitizedRectangle(
          startingPoint,
          getBoardPosition(position),
          minWidthHeight
        );
        setTempRectangle(sanitizedRectangle);
      }
    },
    [getBoardPosition, startingPoint]
  );

  const dimensions = useMemo(() => {
    const offset = 20;
    const newDimensions: DimensionProperties[] = [];

    if (rectangle.size.width === 0 || rectangle.size.height === 0) {
      return newDimensions;
    }

    // Width dimension, show below
    newDimensions.push({
      start: {
        x: rectangle.position.x,
        y: rectangle.position.y + rectangle.size.height + offset,
      },
      end: {
        x: rectangle.position.x + rectangle.size.width,
        y: rectangle.position.y + rectangle.size.height + offset,
      },
    });
    // Height dimension, show to the left
    newDimensions.push({
      end: {
        x: rectangle.position.x - offset,
        y: rectangle.position.y,
      },
      start: {
        x: rectangle.position.x - offset,
        y: rectangle.position.y + rectangle.size.height,
      },
    });
    return newDimensions;
  }, [rectangle]);

  const finishDrawing = useCallback(() => {
    if (startingPoint !== null || resizeCorner !== ItemCorner.NONE) {
      setStartingPoint(null);
      setResizeCorner(ItemCorner.NONE);
      setTempRectangle(undefined);

      if (
        rectangle.size.width < minimimSize &&
        rectangle.size.height < minimimSize
      ) {
        onEditingChange(false);
      } else {
        onRectangleChange(rectangle);
        onEditingChange(false);
      }
    }
  }, [
    onEditingChange,
    onRectangleChange,
    rectangle,
    resizeCorner,
    startingPoint,
  ]);

  /**
   * Starts dragging mode and stores the initial position. Does nothing if already dragging or resizing.
   */
  const startDragging = useCallback(
    (event?: any, position?: Point) => {
      if (onClick) onClick();
      if (props.disabled) return;
      if (event) {
        event.stopPropagation();
      }
      if (isDragging || resizeCorner !== ItemCorner.NONE) return;
      // The board offset has already been subtracted when the attribute was set
      let pos: Point | undefined;
      if (position !== undefined) {
        pos = getBoardPosition(position);
      }
      // Position not stored yet for touch devices
      if (event !== undefined) {
        // Do not start dragging on right clicks
        if (!EventProcess.isLeftClick(event)) return;
        // The RectangleCreator used the board 0,0 as reference
        pos = getBoardPosition(EventProcess.getEventPosition(event));
      }
      if (pos === undefined) return;
      setDragStart(pos);
      setIsDragging(true);
      if (onEditingChange) {
        onEditingChange(true);
      }

      if (isTouchDevice) {
        timeoutRef.current = setTimeout(() => {
          if (pos !== undefined && onLongTouch) {
            if (onLongTouch) {
              onLongTouch(EventProcess.getEventPosition(event));
            }
            // This should also release the event
            Mouse.release.next({
              position: pos,
              pressed: false,
              rightPressed: false,
              elementClass: EventProcess.getClass(event),
              positions: [pos],
            });
          }
        }, longTouchTime);
      }
    },
    [
      onClick,
      props.disabled,
      isDragging,
      resizeCorner,
      onEditingChange,
      getBoardPosition,
      onLongTouch,
    ]
  );

  /**
   * Updates the shape accoring to the new position.
   */
  const dragShape = useCallback(
    (position: Point) => {
      if (isDragging) {
        const diff = CanvasOperations.getPointSubtracted(
          getBoardPosition(position),
          dragStart
        );
        const newPosition = CanvasOperations.getPointSum(
          props.rectangle.position,
          diff
        );
        setTempRectangle({ ...rectangle, position: newPosition });
      }
      // Clear long touch timeout if touch was moved (more than 2 px)
      if (timeoutRef.current !== null) {
        const pos = EventProcess.getEventPosition(event);
        const dist = CanvasOperations.calculateDistance(dragStart, pos);
        if (dist > 2) {
          clearTimeout(timeoutRef.current);
        }
      }
    },
    [
      dragStart,
      getBoardPosition,
      isDragging,
      props.rectangle.position,
      rectangle,
    ]
  );

  /**
   * Exits dragging mode and notifies parent with new position.
   */
  const finishDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      onRectangleChange(rectangle);
      setTempRectangle(undefined);
      onEditingChange(false);
    }
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  }, [isDragging, onEditingChange, onRectangleChange, rectangle]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  /**
   * Enters resizing mode and stores the initial size and position.
   */
  const beginResizing = (corner: ItemCorner, pos: Point) => {
    if (props.update) {
      if (onEditingChange) {
        onEditingChange(true);
      }
      // To support touch devices, the position is passed in the callback, but this position is not aligned with the board (because only the Creator components are using the board's 0,0 as reference,. The items for instance is using the position as it is, so dont change it in the ResizeSvg.)
      const adjustedPos = getBoardPosition(pos);
      setResizeStart(adjustedPos);
      setResizeCorner(corner);
      setRectangleReference(rectangle);
    }
  };

  /**
   * Updates the shape with the new size and position.
   */
  const resizeRectangle = useCallback(
    (position: Point) => {
      if (resizeStart !== null && resizeCorner !== ItemCorner.NONE) {
        const newRectangle = ShapeOperations.getResize(
          getBoardPosition(position),
          resizeCorner,
          resizeStart,
          rectangleReference as Rectangle,
          props.rotation,
          false
        );
        setTempRectangle(newRectangle);
      }
    },
    [
      getBoardPosition,
      props.rotation,
      rectangleReference,
      resizeCorner,
      resizeStart,
    ]
  );

  /**
   * Get the path used to draw a cross at the center of the ellipse.
   */
  const getCenterMarkPath = () => {
    const markerSize = props.lineWidth * 3;
    let path =
      "M " +
      (ellipse.center.x - markerSize) +
      " " +
      (ellipse.center.y - markerSize);
    path +=
      " L " +
      (ellipse.center.x + markerSize) +
      " " +
      (ellipse.center.y + markerSize);
    path +=
      "M " +
      (ellipse.center.x - markerSize) +
      " " +
      (ellipse.center.y + markerSize);
    path +=
      " L " +
      (ellipse.center.x + markerSize) +
      " " +
      (ellipse.center.y - markerSize);
    return path;
  };

  const triggerRightClick = (event: any) => {
    if (Device.isTouchDevice()) return;
    const pos = EventProcess.getEventPosition(event);
    if (onRightClick) onRightClick(pos);
  };

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (props.disabled) return;

      if (state.pressed) {
        if (props.update) {
          // Start dragging immediately if mouse if pressed and element beneath is the creator shape
          if (state.elementClass === "creator") {
            startDragging(undefined, state.position);
          }
          // These functions return immediately if shape is currently not being resized/dragged
          resizeRectangle(state.position);
          dragShape(state.position);
        } else {
          updateShape(state.position);
        }
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      if (props.disabled) return;
      if (props.update && resizeCorner === ItemCorner.NONE) {
        onEditingChange(false);
      }
      startDrawing(state.position);
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      if (props.disabled) return;
      finishDrawing();
      finishDragging();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    dragShape,
    finishDragging,
    finishDrawing,
    onEditingChange,
    props.disabled,
    props.update,
    resizeCorner,
    resizeRectangle,
    startDragging,
    startDrawing,
    updateShape,
  ]);

  return (
    <>
      {(startingPoint !== null || props.update) && (
        <g
          id="rectangle-creator"
          transform={
            "rotate(" +
            props.rotation +
            ", " +
            (rectangle.position.x + rectangle.size.width / 2) +
            ", " +
            (rectangle.position.y + rectangle.size.height / 2) +
            ")"
          }
        >
          <defs>
            <ShapePattern
              zoom={props.zoom}
              color={props.style.fillColor}
              startPosition={rectangle.position}
              id={"rectangle-creator"}
              pattern={props.style.fillPattern}
            />
          </defs>
          {props.shapeType === ShapeType.RECTANGLE && (
            <rect
              x={rectangle.position.x}
              y={rectangle.position.y}
              width={rectangle.size.width}
              height={rectangle.size.height}
              strokeWidth={props.lineWidth} // 1 cm
              stroke={ShapeOperations.getStroke(props.style)}
              strokeOpacity={ShapeOperations.getStrokeOpacity()}
              strokeDasharray={ShapeOperations.getStrokeDashArray(
                props.style,
                props.lineWidth
              )}
              fill={ShapeOperations.getFill(props.style, "rectangle-creator")}
              fillOpacity={ShapeOperations.getOpacity(props.style)}
              cursor={props.cursorOverride || "move"}
              onMouseDown={startDragging}
              onTouchStart={startDragging}
              onContextMenu={triggerRightClick}
              className={props.className}
              id="rectangle-creator-rect"
            />
          )}
          {props.shapeType === ShapeType.ELLIPSE && (
            <>
              <ellipse
                rx={ellipse.radius.width}
                ry={ellipse.radius.height}
                cx={ellipse.center.x}
                cy={ellipse.center.y}
                strokeWidth={props.lineWidth}
                stroke={ShapeOperations.getStroke(props.style)}
                strokeOpacity={ShapeOperations.getStrokeOpacity()}
                strokeDasharray={ShapeOperations.getStrokeDashArray(
                  props.style,
                  props.lineWidth
                )}
                fill={ShapeOperations.getFill(props.style, "rectangle-creator")}
                fillOpacity={ShapeOperations.getOpacity(props.style)}
                cursor={"move"}
                onMouseDown={startDragging}
                onTouchStart={startDragging}
                onContextMenu={triggerRightClick}
                className={props.className}
                id="rectangle-creator-ellipse"
              />
              <path
                d={getCenterMarkPath()}
                strokeWidth={props.lineWidth}
                stroke={"#000000"}
                className={props.className}
              />
            </>
          )}
          {dimensions.map((dimension: DimensionProperties, index: number) => (
            <ShapeDimension
              key={"dim-" + index}
              start={dimension.start}
              end={dimension.end}
              zoom={props.zoom}
              lineWidth={props.lineWidth}
              style={props.dimensionStyle}
              showArrow={true}
              uniqueId="rectangle-creator"
              delimiter={props.delimiter}
              unitScale={props.unitScale}
              followDirection
            />
          ))}
          {props.update && (
            <ResizeSvg
              pixelSize={rectangle.size}
              position={rectangle.position}
              onResizeStarted={beginResizing}
              preserveAspectRatio={false}
              resizable={true}
              rotation={props.rotation}
            />
          )}
        </g>
      )}
    </>
  );
};
