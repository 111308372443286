import { useTranslation } from "react-i18next";
import { PlantCollection } from "..";
import { useMemo } from "react";
import { Card } from "imagine-ui";
import { Api } from "imagine-essentials";
import { PlantSchema } from "draw";

interface Props {
  plantCollection: PlantCollection;
  onClick?: () => void;
  className?: string;
}

/**
 * Displays a summary of a plant collection in a card layout.
 * @param props
 */
export const PlantCollectionCard = (props: Props) => {
  const { t } = useTranslation();
  const images = useMemo(() => {
    if (props.plantCollection.plants === undefined) return [];
    const imageList = props.plantCollection.plants
      .map((plant: PlantSchema) => {
        return plant.image;
      })
      .filter((img: string | undefined) => img !== undefined) as string[];

    if (imageList.length > 8) {
      return imageList.slice(0, 8);
    }
    return imageList;
  }, [props.plantCollection]);

  return (
    <Card className={"column h-full"} onClick={props.onClick}>
      <div className="">
        <div>
          <div className="grid columns-3 gap-sm">
            {images.map((img: string, index: number) => (
              <img
                src={Api.getHost() + "/plant-images/" + img}
                className="fluid rounded"
                alt="Plant"
                key={index.toString()}
              />
            ))}
            {props.plantCollection.plants !== undefined &&
              props.plantCollection.plants.length > 8 && (
                <div className="row items-center items-y-center h-full">
                  <span className="text-xl text-dark-muted">
                    +{props.plantCollection.plants.length - 8}
                  </span>
                </div>
              )}
          </div>
        </div>
      </div>
      <div className="stretch"></div>
      <h4 className="mt">{props.plantCollection.name}</h4>
      <p className="text-muted mt-sm">
        {t("plants:countPlants", {
          count: props.plantCollection.plants?.length ?? 0,
        })}
      </p>
    </Card>
  );
};
