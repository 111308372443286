import { useCallback } from "react";
import {
  CanvasSelector,
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { Point, Trig } from "imagine-essentials";
import {
  CanvasOperations,
  Item,
  ItemOperations,
  ItemType,
  PlantModelOperations,
} from "draw";
import { UserActions } from "imagine-users";
import { FeatureKey } from "project";
import { ItemStamp } from "../../components";
import { Pages } from "../../enums";

/**
 * Shows a plant or object items that is not added yet. Will be added when clicked.
 */
export const ItemStampContainer = () => {
  const stampType = useAppSelector(PlanEditorSelector.getStampType);
  const stampTemplate = useAppSelector(PlanEditorSelector.getStampTemplate);
  const plantTemplate = useAppSelector(
    PlanEditorSelector.getPlantStampTemplate
  );
  const objectTemplate = useAppSelector(
    PlanEditorSelector.getObjectStampTemplate
  );
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const canvasViewport = useAppSelector(CanvasSelector.getVisibleRectangle);
  const currentLayerId = useAppSelector(PlanEditorSelector.getCurrentLayerId);

  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const items = useAppSelector(PlanEditorSelector.getAllItems);

  const dispatch = useAppDispatch();
  // Used to make sure items are not added if board has been dragged

  const addPlantItem = useCallback(
    (position: Point) => {
      if (plantTemplate === null) return;
      const size = PlantModelOperations.getItemSizeFromTemplate(plantTemplate);
      const itemPosition = ItemOperations.getItemPosition(
        position,
        { x: 0, y: 0 },
        zeroReference,
        zoom
      );

      const newItem: Item = {
        id: 0, // Will be overridden by store action
        type: ItemType.PLANT,
        index: items.length,
        templateId: plantTemplate.id,
        size: size,
        rotation: 0,
        position: itemPosition,
        viewBox: "0 0 " + size.width + " " + size.height,
        layerId: currentLayerId,
      };
      dispatch(PlanEditorActions.addPlantTemplate(plantTemplate));
      dispatch(PlanEditorActions.addItem(newItem));
    },
    [currentLayerId, dispatch, items.length, plantTemplate, zeroReference, zoom]
  );

  const addObjectItem = useCallback(
    (position: Point) => {
      if (objectTemplate === null) return;
      const size = {
        width: objectTemplate.width,
        height: objectTemplate.height,
      };
      const itemPosition = ItemOperations.getItemPosition(
        position,
        { x: 0, y: 0 },
        zeroReference,
        zoom
      );
      // const cornerPosition = Trig.getPointSubtracted(
      //   itemPosition,
      //   Trig.sizeToPoint(Trig.getSizeCenter(size)),
      //   3
      // );
      const newItem: Item = {
        id: 0, // Will be overriden by store action
        type: ItemType.OBJECT,
        index: items.length,
        templateId: objectTemplate.id,
        size: size,
        rotation: 0,
        position: itemPosition,
        viewBox: "0 0 " + size.width + " " + size.height,
        layerId: currentLayerId,
      };
      dispatch(PlanEditorActions.addObjectTemplate(objectTemplate));
      dispatch(PlanEditorActions.addItem(newItem));
      dispatch(UserActions.track(FeatureKey.DRAG_INSERT_OBJECT));
    },
    [
      currentLayerId,
      dispatch,
      items.length,
      objectTemplate,
      zeroReference,
      zoom,
    ]
  );

  const addItem = useCallback(
    (position: Point) => {
      // Never insert items when a page is open
      if (displayedPage !== Pages.NONE) return;
      if (stampType === ItemType.PLANT) {
        addPlantItem(position);
      }
      if (stampType === ItemType.OBJECT) {
        addObjectItem(position);
      }
    },
    [displayedPage, stampType, addPlantItem, addObjectItem]
  );

  const exit = () => {
    dispatch(PlanEditorActions.clearStamp());
  };

  return (
    <>
      {stampType !== null && stampTemplate !== null && (
        <ItemStamp
          template={stampTemplate}
          type={stampType}
          zoom={zoom}
          zeroReference={zeroReference}
          canvasOffset={canvasOffset}
          viewport={canvasViewport}
          onInsertItem={addItem}
          onExit={exit}
        />
      )}
    </>
  );
};
