import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  Action,
  ActionHistoryOperations,
  ActionType,
  Cloner,
  Item,
  ItemOperations,
  ObjectCategory,
  ObjectOperations,
  ObjectSchema,
} from "draw";
import { stat } from "fs";

interface ObjectEditorState {
  objectMode: boolean;
  index: number; // The index value where the finished object will be placed
  items: Item[];
  selectedItemIds: number[];
  originalSelectedItems: Item[]; // The original shape items selected when entering object mode (in case we need to cancel)
  savedObjectTemplate: ObjectSchema | null; // The object template saved in the database
  originalObjectItem: Item | null; // The object item that is being edited (original item)
  actionHistory: Action[];
  actionUndoneHistory: Action[];
  unsavedChanges: boolean;
  layerId: number; // The layer that the new object should be added to
  name: string;
  category: ObjectCategory | undefined;
  copiedItems: Item[];
}

const initialState: ObjectEditorState = {
  objectMode: false,
  index: 0,
  items: [],
  selectedItemIds: [],
  originalSelectedItems: [],
  savedObjectTemplate: null,
  originalObjectItem: null,
  actionHistory: [],
  actionUndoneHistory: [],
  unsavedChanges: false,
  layerId: 0,
  name: "",
  category: undefined,
  copiedItems: [],
};

export const objectEditorSlice = createSlice({
  name: "objectEditor",
  initialState,
  reducers: {
    setObjectMode: (state, action: PayloadAction<boolean>) => {
      state.objectMode = action.payload;
    },
    setIndex: (state, action: PayloadAction<number>) => {
      state.index = action.payload;
    },
    setSavedObjectTemplate: (state, action: PayloadAction<ObjectSchema>) => {
      state.savedObjectTemplate = action.payload;
      state.name = action.payload.name;
      state.category = action.payload.category;
      state.unsavedChanges = false;
    },
    setOriginalObjectItem: (state, action: PayloadAction<Item | null>) => {
      state.originalObjectItem = action.payload;
    },
    setItems: (state, action: PayloadAction<Item[]>) => {
      state.items = ItemOperations.removeLayerAndGroup(action.payload);
    },
    setSelectedItemIds: (state, action: PayloadAction<number[]>) => {
      state.selectedItemIds = action.payload;
    },
    setSelectedItems: (state, action: PayloadAction<Item[]>) => {
      state.selectedItemIds = action.payload.map((item: Item) => item.id);
    },
    setOriginalSelectedItems: (state, action: PayloadAction<Item[]>) => {
      state.originalSelectedItems = action.payload;
    },
    addItem: (state, action: PayloadAction<Item>) => {
      const newItem = {
        ...action.payload,
        layerId: undefined,
        groupId: undefined,
        id: ItemOperations.getTimestampId(0),
      };
      state.items.push(newItem);
      state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
        null,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      state.selectedItemIds = [newItem.id];
    },
    addMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const newItems = ItemOperations.removeLayerAndGroup(action.payload);
      state.items = state.items.concat(newItems);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        null,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
      state.selectedItemIds = newItems.map((item: Item) => item.id);
    },
    updateItem: (state, action: PayloadAction<Item>) => {
      const oldItem = state.items.find(
        (item: Item) => item.id === action.payload.id
      );
      const items = state.items.map((item: Item) => {
        if (item.id === action.payload.id) return action.payload;
        else return item;
      });
      state.items = ItemOperations.sortByIndex(items);
      if (oldItem !== undefined) {
        state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
          oldItem,
          action.payload,
          state.actionHistory
        );
        state.actionUndoneHistory = [];
      }
      state.unsavedChanges = true;
    },
    updateMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const updateItemIds = action.payload.map((item: Item) => item.id);
      // Old items needed for undo
      const oldItems = state.items.filter((item: Item) =>
        updateItemIds.includes(item.id)
      );

      const items = state.items.map((item: Item) => {
        // If item is in update list, then use updated item
        if (updateItemIds.includes(item.id)) {
          const updatedItem = action.payload.find(
            (i: Item) => i.id === item.id
          );
          if (updatedItem !== undefined) return updatedItem;
        }
        // Else use the original item
        return item;
      });
      state.items = ItemOperations.sortByIndex(items);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        oldItems,
        action.payload,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteItem: (state, action: PayloadAction<Item>) => {
      const items = state.items.filter(
        (item: Item) => item.id !== action.payload.id
      );
      state.items = ItemOperations.fixIndexValues(items);
      state.actionHistory = ActionHistoryOperations.pushItemUpdateAction(
        action.payload,
        null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteMultipleItems: (state, action: PayloadAction<Item[]>) => {
      const itemIds = action.payload.map((item: Item) => item.id);
      const items = state.items.filter(
        (item: Item) => !itemIds.includes(item.id)
      );
      state.items = ItemOperations.fixIndexValues(items);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        action.payload,
        null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.unsavedChanges = true;
    },
    deleteSelectedItems: (state) => {
      // Clone selected items for undo
      const deletedItems = state.items
        .filter((item: Item) => state.selectedItemIds.includes(item.id))
        .map((item: Item) => Cloner.cloneItem(item));

      const items = state.items.filter(
        (item: Item) => !state.selectedItemIds.includes(item.id)
      );

      state.items = ItemOperations.fixIndexValues(items);
      state.actionHistory = ActionHistoryOperations.pushItemsUpdateAction(
        deletedItems,
        null,
        state.actionHistory
      );
      state.actionUndoneHistory = [];
      state.selectedItemIds = [];
      state.unsavedChanges = true;
    },
    clearItems: (state) => {
      state.items = [];
    },
    clearUnsavedChanges: (state) => {
      state.unsavedChanges = false;
    },
    setLayerId: (state, action: PayloadAction<number>) => {
      state.layerId = action.payload;
    },
    prepareNewObject: (state, action: PayloadAction<Item[]>) => {
      if (action.payload.length === 0) return;
      state.objectMode = true;
      state.items = ItemOperations.removeLayerAndGroup(
        _.cloneDeep(action.payload)
      );
      state.selectedItemIds = action.payload.map((item: Item) => item.id);
      state.originalSelectedItems = _.cloneDeep(action.payload);
      state.index = action.payload[0].index;
      // state.layerId = action.payload[0].layerId;
      state.savedObjectTemplate = null; //ObjectOperations.getDefaultObject();
      state.originalObjectItem = null;
      state.actionHistory = [];
      state.actionUndoneHistory = [];
      state.unsavedChanges = false;
      state.name = "";
      state.category = undefined;
    },
    prepareEditingObject: (
      state,
      action: PayloadAction<{ template: ObjectSchema; item: Item }>
    ) => {
      state.objectMode = true;
      state.items = ObjectOperations.generateItemsFromObject(
        action.payload.template,
        action.payload.item.position
      );
      state.selectedItemIds = [];
      state.originalSelectedItems = [action.payload.item];
      state.index = action.payload.item.index;
      // state.layerId = action.payload.layerId;
      state.savedObjectTemplate = action.payload.template; //ObjectOperations.getDefaultObject();
      state.originalObjectItem = _.cloneDeep(action.payload.item);
      state.actionHistory = [];
      state.actionUndoneHistory = [];
      state.unsavedChanges = false;
      state.name = "";
      state.category = undefined;
    },
    setCategory: (state, action: PayloadAction<ObjectCategory>) => {
      state.category = action.payload;
      state.unsavedChanges = true;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
      state.unsavedChanges = true;
    },
    setCopiedItems: (state, action: PayloadAction<Item[]>) => {
      state.copiedItems = action.payload;
    },
    undo: (state) => {
      if (state.actionHistory.length === 0) return;
      state.selectedItemIds = [];
      const undoAction = state.actionHistory.pop();
      if (undoAction === undefined) return;
      state.unsavedChanges = true;
      switch (undoAction.type) {
        case ActionType.ITEM_UPDATE:
          state.items = ActionHistoryOperations.undoItems(
            state.items,
            undoAction
          );
          break;
        case ActionType.ITEMS_UPDATE:
          state.items = ActionHistoryOperations.undoMultipleItems(
            state.items,
            undoAction
          );
          break;

        default:
          throw Error("Action type not supported: " + undoAction.type);
      }
      state.actionUndoneHistory.push(undoAction);
    },
    redo: (state) => {
      if (state.actionUndoneHistory.length === 0) return;

      state.selectedItemIds = [];
      const redoAction = state.actionUndoneHistory.pop();
      if (redoAction === undefined) return;
      state.unsavedChanges = true;
      switch (redoAction.type) {
        case ActionType.ITEM_UPDATE:
          state.items = ActionHistoryOperations.redoItems(
            state.items,
            redoAction
          );
          break;
        case ActionType.ITEMS_UPDATE:
          state.items = ActionHistoryOperations.redoMultipleItems(
            state.items,
            redoAction
          );
          break;

        default:
          throw Error("Action type not supported: " + redoAction.type);
      }
      state.actionHistory.push(redoAction);
    },
    copySelectedItems: (state) => {
      if (state.selectedItemIds.length > 0) {
        state.copiedItems = state.items.filter((item: Item) =>
          state.selectedItemIds.includes(item.id)
        );
      }
    },
    pasteCopiedItems: (state) => {
      if (state.copiedItems.length > 0) {
        const newItems = state.copiedItems.map((item: Item, index: number) => {
          // Position new items a little shifted from the original position
          const pos = {
            x: item.position.x + item.size.width / 2,
            y: item.position.y,
          };
          const newItem = Cloner.cloneItem(item);
          newItem.id = ItemOperations.getTimestampId(index);
          newItem.position = pos;
          newItem.index = state.items.length + index;
          return newItem;
        });
        if (newItems.length === 1) {
          objectEditorSlice.caseReducers.addItem(state, {
            payload: newItems[0],
            type: "objectEditor/addItem",
          });
        } else {
          objectEditorSlice.caseReducers.addMultipleItems(state, {
            payload: newItems,
            type: "objectEditor/addItem",
          });
        }
        state.selectedItemIds = newItems.map((item: Item) => item.id);
        // Make new items the copied ones, in order to make multiple pasted items not lay on top of each other
        state.copiedItems = newItems;
      }
    },

    moveSelectedItemToFront: (state) => {
      if (state.selectedItemIds.length === 1) {
        const item = state.items.find(
          (item: Item) => item.id === state.selectedItemIds[0]
        );
        if (item === undefined) return;
        const updatedItem = Cloner.cloneItem(item);
        updatedItem.index = state.items.length;
        objectEditorSlice.caseReducers.updateItem(state, {
          payload: updatedItem,
          type: "objectEditor/updateItem",
        });
      }
    },
    moveSelectedItemToBack: (state) => {
      if (state.selectedItemIds.length === 1) {
        const item = state.items.find(
          (item: Item) => item.id === state.selectedItemIds[0]
        );
        if (item === undefined) return;
        const updatedItem = Cloner.cloneItem(item);
        updatedItem.index = -1;
        objectEditorSlice.caseReducers.updateItem(state, {
          payload: updatedItem,
          type: "objectEditor/updateItem",
        });
      }
    },
  },
});

export const ObjectEditorActions = objectEditorSlice.actions;

export default objectEditorSlice.reducer;
