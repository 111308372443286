import { Api, AppInfo, DataResponse, SentryReporter } from "imagine-essentials";

import { PlanOperations } from "../utils";
import {
  Area,
  Group,
  Item,
  Layer,
  Plan,
  PlanConverter,
  PlanData,
  PlanInfo,
  PlanSettings,
  ReferenceImage,
} from "draw";
import { t } from "i18next";
import { Data } from "react-facebook-pixel";

/**
 * Retrieves the plans associated with a user.
 * @param userId The ID of the user
 * @returns A Promise that resolves to the user's plans
 */
const getUserPlans = async (userId: number) => {
  const result = await Api.get("api/plans/user");
  if (result.success) {
    return result.data as PlanInfo[];
  } else {
    SentryReporter.captureException("Failed to get user plans", {
      Result: JSON.stringify(result),
    });
    return undefined;
  }
};

/**
 * Retrieves the public plans.
 * @returns A Promise that resolves to the public plans
 */
const getPublicPlans = async () => {
  const result = await Api.get("api/plans/public");
  if (result.success) {
    return result.data as PlanInfo[];
  } else {
    SentryReporter.captureException("Failed to get user plans", {
      Result: JSON.stringify(result),
    });
  }
};

/**
 * Updates the thumbnail of a plan.
 * Throws an error on failure.
 * @param id The ID of the plan
 * @param thumbnail The thumbnail image as a Blob
 * @returns A Promise that resolves to an object with a `success` property indicating the success of the update
 */
const updateThumbnail = async (id: number, thumbnail: Blob) => {
  const formData = new FormData();
  formData.append("thumbnail", thumbnail);

  const result = await Api.postForm("api/plans/update-thumb/" + id, formData);

  if (result.success) {
    return {
      success: true,
    };
  } else {
    SentryReporter.captureException("Failed to update plan thumbnail", {
      Result: JSON.stringify(result),
    });
    return {
      success: false,
    };
  }
};

const addReferenceImage = async (id: number, image: Blob) => {
  const formData = new FormData();
  formData.append("referenceImage", image);

  const result = await Api.postForm(
    "api/plans/add-reference-image/" + id,
    formData
  );
  const response: DataResponse<string> = {
    success: result.success,
  };

  if (result.success) {
    response.data = result.data.image as string;
  } else {
    SentryReporter.captureException("Failed to add reference image", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

const removeReferenceImage = async (id: number) => {
  const result = await Api.post("api/plans/remove-reference-image/" + id, {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to remove reference image", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

/**
 * Creates a new plan in the database. Throws an error on failure.
 * @param name The name of the plan
 * @param layers The layers in the plan.
 * @param groups The groups in the plan.
 * @param items The items in the plan.
 * @param area The area of the plan.
 * @returns Plan info for the saved plan. Null if the user does not have permission to
 * save (might have been logged out by mistake)
 */
const saveNewPlan = async (
  name: string,
  layers: Layer[],
  groups: Group[],
  items: Item[],
  area: Area | undefined,
  planSettings: PlanSettings
) => {
  const plan: PlanData = {
    layers: layers,
    groups: groups,
    items: items,
    area: area,
    planSettings: planSettings,
  };
  const json = {
    name: name,
    version: AppInfo.getAppVersion(),
    // data: JSON.stringify(plan),
    data: PlanConverter.compressPlanData(plan),
  };
  const result = await Api.post("api/plans/add", json);
  const response: DataResponse<PlanInfo> = {
    success: result.success,
  };

  if (result.success && result.data) {
    response.data = PlanOperations.getPlanInfoFromObject(
      result.data
    ) as PlanInfo;
  } else {
    if (result.status === 403) {
      // User might have been logged out
      response.errorCode = "no-access";
      SentryReporter.captureException(
        "User session expired when user tried to save new plan",
        {
          Result: JSON.stringify(result),
        }
      );
    } else {
      SentryReporter.captureException("Failed to save new plan", {
        Result: JSON.stringify(result),
      });
      response.errorMessage = t("errorSavingPlan");
    }
  }
  return response;
};

/**
 * Update an existing plan. Throws an error on failure.
 * @param planInfo The plan info, including the ID
 * @param layers The layers in the plan.
 * @param groups The groups in the plan.
 * @param items The items in the plan.
 * @param area The area of the plan.
 * @returns The plan info with new dateUpdated value on success. If permission is denied it might indicate that the tokens have expired and the user needs to login again. In this case it will return null.
 */
const updatePlan = async (
  planInfo: PlanInfo,
  layers: Layer[],
  groups: Group[],
  items: Item[],
  area: Area | undefined,
  planSettings: PlanSettings,
  referenceImage: ReferenceImage | undefined
) => {
  if (planInfo.id === 0) {
    console.error("Do 'Save As' first");
    throw new Error("Plan needs to be saved before it can be updated");
  }

  const plan: PlanData = {
    layers: layers,
    groups: groups,
    items: items,
    area: area,
    planSettings: planSettings,
    referenceImage: referenceImage,
  };

  const result = await Api.post(
    "api/plans/update/" + AppInfo.getAppVersion() + "/" + planInfo.id,
    PlanConverter.compressPlanData(plan)
  );

  const response: DataResponse<PlanInfo> = {
    success: result.success,
  };

  if (result.success && result.data) {
    response.data = PlanOperations.getPlanInfoFromObject(
      result.data
    ) as PlanInfo;
  } else {
    if (result.status === 403) {
      // User might have been logged out
      response.errorCode = "no-access";
      SentryReporter.captureException(
        "User session expired when user tried to update plan",
        {
          Result: JSON.stringify(result),
        }
      );
    } else {
      SentryReporter.captureException("Failed to update plan", {
        Result: JSON.stringify(result),
        "Plan info": JSON.stringify(planInfo),
      });
      response.errorMessage = t("errorSavingPlan");
    }
  }
  return response;
};

/**
 * Open a plan from the backend. This will throw an error if the user does not have permission to access the plans.
 * @param id Id of the plan. Undefined to select the currently opened plan (might return an empty plan)
 */
const openPlan = async (id: number | undefined) => {
  const url = "api/plans/id/" + id;
  const result = await Api.get(url);
  const response: DataResponse<Plan> = {
    success: result.success,
  };
  if (result.success) {
    const planVersion = result.data.version;

    if (result.status === 204) {
      response.data = {
        info: PlanOperations.getDefaultPlanInfo(),
        data: PlanOperations.getEmptyPlanData(),
      } as Plan;
    }
    response.data = {
      info: PlanOperations.getPlanInfoFromObject(result.data),
      data: PlanOperations.getPlanDataFromBackend(
        result.data.data,
        planVersion
      ),
    } as Plan;
  } else if (result.status !== 403) {
    SentryReporter.captureException("Failed to open plan", {
      Result: JSON.stringify(result),
    });
  } else {
    console.log("User not allowed to open plan");
  }
  return response;
};

const deletePlan = async (planId: number) => {
  const result = await Api.post("api/plans/delete/" + planId, {});
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to delete plan", {
      Result: JSON.stringify(result),
    });
  }
  return response;
};

export const PlansApi = {
  getUserPlans,
  getPublicPlans,
  updateThumbnail,
  addReferenceImage,
  removeReferenceImage,
  saveNewPlan,
  updatePlan,
  openPlan,
  deletePlan,
};
