import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  CanvasActions,
  LayoutActions,
  PlanEditorSelector,
  LayoutSelector,
  CanvasSelector,
} from "../../store";
import { CursorMode, MarkerMode, Pages } from "../../enums";
import { ToolButton, ToolSeparator } from "../../components";
import { Device } from "imagine-ui";
import { UserActions, UserSelector } from "imagine-users";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { PlanOperations } from "../../utils";
import { useState } from "react";

/**
 * Displays the tool and actions in the home toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const ExportToolbarContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isTouchDevice = Device.isTouchDevice();
  const planName = useAppSelector(PlanEditorSelector.getPlanName);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const markerMode = useAppSelector(CanvasSelector.getMarkerMode);

  const preparePlantingPlan = () => {
    if (displayedPage !== Pages.PLANTING_PLAN) {
      dispatch(CanvasActions.setCursorMode(CursorMode.POINTER));
      dispatch(CanvasActions.setMarkedRectangle(null));
      dispatch(CanvasActions.setMarkerMode(MarkerMode.DRAW));
      // Planting plan page will not be visible until the rectangle is drawn
      dispatch(LayoutActions.setDisplayedPage(Pages.PLANTING_PLAN));
      dispatch(CanvasActions.setMessage(t("markArea")));

      dispatch(UserActions.track(FeatureKey.NAV_PLANTING_PLAN));
    } else {
      dispatch(CanvasActions.setMarkerMode(MarkerMode.SELECT));
      dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
      dispatch(CanvasActions.setMessage(""));
    }
  };

  const openPlantList = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_LIST));
    dispatch(UserActions.track(FeatureKey.NAV_PLANT_LIST));
  };

  const prepareScenery = () => {
    if (markerMode === MarkerMode.DRAW_VIEW) {
      dispatch(CanvasActions.setMarkerMode(MarkerMode.NONE));
      dispatch(CanvasActions.setMessage(""));
    } else {
      dispatch(CanvasActions.setCursorMode(CursorMode.POINTER));
      dispatch(CanvasActions.setMarkerMode(MarkerMode.DRAW_VIEW));
      // dispatch(LayoutActions.setDisplayedPage(Pages.PLANTING_PLAN));
      dispatch(CanvasActions.setMessage(t("markView")));

      dispatch(UserActions.track(FeatureKey.SCENERY));
    }
  };

  const captureSnapshot = async () => {
    await PlanOperations.downloadSnapshot(planName);
    dispatch(CanvasActions.setLoadingMessage(""));
  };

  const prepareCaptureSnapshot = async () => {
    dispatch(CanvasActions.setLoadingMessage(t("preparingSnapshot")));

    // Force the capture function to run after the state has been updated (otherwise the loading
    // message will not be displayed until after the capture has completed)
    setTimeout(() => {
      captureSnapshot();
    }, 0);
  };

  return (
    <div id="export-toolbar" className="toolbar-row">
      <ToolButton
        elementId="planting-plan-nav-button"
        text={t("plantingPlan")}
        icon="document-plan"
        onClick={preparePlantingPlan}
        disabled={isTouchDevice}
        selected={displayedPage === Pages.PLANTING_PLAN}
      />
      <ToolButton
        elementId="plant-list-nav-button"
        text={t("plants:plantList")}
        icon="document-list"
        onClick={openPlantList}
      />
      <ToolSeparator />

      <ToolButton
        elementId="scenery-nav-button"
        text={t("plantScenery")}
        icon="plants"
        onClick={prepareScenery}
        selected={markerMode === MarkerMode.DRAW_VIEW}
      />
      <ToolSeparator />

      <ToolButton
        elementId="snapshot-nav-button"
        text={t("planImage")}
        icon="camera"
        onClick={prepareCaptureSnapshot}
      />
    </div>
  );
};
