import { CanvasOperations, EventProcess, Mouse, MouseState } from "draw";
import { get } from "http";
import { Point, Rectangle } from "imagine-essentials";
import { useCallback, useEffect, useMemo, useState } from "react";
import { start } from "repl";

interface Props {
  position: Point;
  onChange: (position: Point) => void;
  onDrag?: (position: Point | null) => void;
  window: Rectangle; // Only positions within this rectangle are valid
}

const getNewPosition = (
  startPosition: Point,
  diff: Point,
  window: Rectangle
): Point => {
  const pos = { x: startPosition.x + diff.x, y: startPosition.y + diff.y };
  if (pos.x < window.position.x) {
    pos.x = window.position.x;
  }
  if (pos.y < window.position.y) {
    pos.y = window.position.y;
  }
  if (pos.x > window.position.x + window.size.width) {
    pos.x = window.position.x + window.size.width;
  }
  if (pos.y > window.position.y + window.size.height) {
    pos.y = window.position.y + window.size.height;
  }
  return pos;
};

export const PositionMarker = (props: Props) => {
  const [startPos, setStartPos] = useState<Point | null>(null);
  const [startPosMouse, setStartPosMouse] = useState<Point | null>(null);
  const [diff, setDiff] = useState<Point>({ x: 0, y: 0 });

  const position = useMemo(() => {
    if (startPos === null) {
      return props.position;
    }
    return getNewPosition(startPos, diff, props.window);
  }, [props.position, diff, props.window, startPos]);

  const startDragging = useCallback(
    (event) => {
      event.stopPropagation();
      const mousePosition = EventProcess.getEventPosition(event);
      setStartPos(props.position);
      setStartPosMouse(mousePosition);
    },
    [props.position]
  );

  const drag = useCallback(
    (pos: Point) => {
      if (startPos !== null && startPosMouse !== null) {
        const difference = {
          x: pos.x - startPosMouse.x,
          y: pos.y - startPosMouse.y,
        };
        const newPosition = getNewPosition(startPos, difference, props.window);
        if (props.onDrag) {
          props.onDrag(newPosition);
        }
        setDiff(difference);
      }
    },
    [startPos, props.onDrag, props.window, startPosMouse]
  );

  const stopDragging = useCallback(() => {
    if (startPos !== null) {
      props.onChange(position);
    }
    props.onDrag?.(null);
    setStartPos(null);
    setStartPosMouse(null);
    setDiff({ x: 0, y: 0 });
  }, [startPos, props.onChange, position]);

  const path = useMemo(() => {
    const crossSize = 5;
    return (
      "M" +
      (position.x - crossSize) +
      " " +
      (position.y - crossSize) +
      " L" +
      (position.x + crossSize) +
      " " +
      (position.y + crossSize) +
      " M" +
      (position.x - crossSize) +
      " " +
      (position.y + crossSize) +
      " L" +
      (position.x + crossSize) +
      " " +
      (position.y - crossSize)
    );
  }, [position]);

  const path2 = useMemo(() => {
    const crossSize = 5;
    return (
      "M" +
      (position.x - crossSize) +
      " " +
      position.y +
      " L" +
      (position.x + crossSize) +
      " " +
      position.y +
      " M" +
      position.x +
      " " +
      (position.y + crossSize) +
      " L" +
      position.x +
      " " +
      (position.y - crossSize)
    );
  }, [position]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        drag(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      stopDragging();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [drag, stopDragging]);

  return (
    <g>
      <circle
        cx={position.x}
        cy={position.y}
        r={15}
        fill="none"
        stroke={"red"}
        opacity={1}
        strokeWidth={1}
      />
      <path d={path2} stroke="red" strokeWidth={1} />
      <circle
        cx={position.x}
        cy={position.y}
        r={15}
        fill="red"
        opacity={0}
        strokeWidth={1}
        className="position-marker"
        onMouseDown={startDragging}
        onTouchStart={startDragging}
      />
    </g>
  );
};
