import { useEffect, useMemo, useState } from "react";
import {
  MapLocationModal,
  ReferenceImageDistanceModal,
  ReferenceImageModal,
} from "../../components";
import { MapMode, Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
  CanvasActions,
  CanvasSelector,
  PlanEditorSelector,
  PlanEditorActions,
} from "../../store";
import { PlantCollection, PlantCollectionOverviewPage } from "plants";
import { MapLocation } from "../../types";
import { useTranslation } from "react-i18next";
import { Loader } from "@googlemaps/js-api-loader";
import { UserActions, UserSelector } from "imagine-users";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { MapOperations } from "../../utils";
import { PlansApi } from "../../api";
import { Api, ImageUtils } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for connecting the state and API calls to the reference image distance modal
 */
export const ReferenceImageDistanceModalContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const delimiter = I18nTools.getDelimiter(user.language);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const updateDistance = (distance: number) => {
    if (referenceImage) {
      const distanceBefore = referenceImage.distance;
      const newReferenceImage = { ...referenceImage, distance: distance };
      dispatch(PlanEditorActions.setReferenceImage(newReferenceImage));
      // Also update zoom
      // const newZoom = (distance / distanceBefore) * zoom;
      // dispatch(CanvasActions.setZoom(newZoom));
    }
    close();
  };

  return (
    <ReferenceImageDistanceModal
      visible={true}
      onClose={close}
      onChange={updateDistance}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
      distance={referenceImage?.distance || 0}
    />
  );
};
