import { PlantInfoPage } from "plants";

import {
  useAppDispatch,
  useAppSelector,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
} from "../../store";
import { Pages } from "../../enums";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for rendering the plant info plage.
 */
export const PlantInfoPageContainer = () => {
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const plant = useAppSelector(PlantManagementSelector.getFocusPlantTemplate);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      {plant !== null && (
        <PlantInfoPage
          visible={displayedPage === Pages.PLANT_INFO}
          plant={plant}
          onClose={close}
          unitScale={userPreferences.unitScale}
          delimiter={delimiter}
          showHardinessZoneUSDA={userPreferences.showHardinessZoneUSDA}
          showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
        />
      )}
    </>
  );
};
