import {
  CanvasOperations,
  Item,
  ItemType,
  Mouse,
  MouseState,
  PlantModelText,
  PlantSchema,
  StraightLineEquation,
  Trig,
} from "draw";
import { use } from "i18next";
import { Point, Size, Tools } from "imagine-essentials";
import { useCallback, useEffect, useMemo, useState } from "react";
import { PlantProfileSVG } from ".";
import { Alert } from "imagine-ui";
import { useTranslation } from "react-i18next";

interface Props {
  cameraPosition: Point;
  viewPointA: Point;
  viewPointB: Point;
  items: Item[];
  slope: number;
  plantTemplates: PlantSchema[];
  enablePerspective?: boolean;
  month: number;
  className?: string;
}

interface SceneryPlant {
  plantTemplate: PlantSchema;
  position: Point;
  distance: number;
  percentPosition: number;
  size: Size;
  heightOffset: number;
}

export const SceneryImage = (props: Props) => {
  const { t } = useTranslation();
  const imageLineEquation: StraightLineEquation = useMemo(() => {
    return Trig.getStraightLineEquation(props.viewPointA, props.viewPointB);
  }, [props.viewPointA, props.viewPointB]);

  // The line that goes from the camera to the view point A
  const sideAEquation: StraightLineEquation = useMemo(() => {
    return Trig.getStraightLineEquation(props.cameraPosition, props.viewPointA);
  }, [props.cameraPosition, props.viewPointA]);

  // The line that goes from the camera to the view point B
  const sideBEquation: StraightLineEquation = useMemo(() => {
    return Trig.getStraightLineEquation(props.cameraPosition, props.viewPointB);
  }, [props.cameraPosition, props.viewPointB]);

  // Image width will be the distance between the view points
  const imageWidth = useMemo(() => {
    return CanvasOperations.calculateDistance(
      props.viewPointA,
      props.viewPointB
    );
  }, [props.viewPointA, props.viewPointB]);

  const sideLength = useMemo(() => {
    return CanvasOperations.calculateDistance(
      props.cameraPosition,
      props.viewPointA
    );
  }, [props.cameraPosition, props.viewPointA]);

  // Plant sorted by distance to camera. The ones closest to the camera are rendered last.
  const sceneryPlants: Array<SceneryPlant | null> = useMemo(() => {
    return (
      props.items
        // Only include plants that are inside the viewing triangle
        .filter((item) => {
          if (item.type !== ItemType.PLANT) return false;
          // Get center of plant
          const center = {
            x: item.position.x + item.size.width / 2,
            y: item.position.y + item.size.height / 2,
          };
          // Check if plant is inside the viewing triangle
          const inside = Trig.isPointInsideTriangle(
            center,
            props.cameraPosition,
            props.viewPointA,
            props.viewPointB
          );
          return inside;
        })
        .map((item) => {
          const center = {
            x: item.position.x + item.size.width / 2,
            y: item.position.y + item.size.height / 2,
          };
          // Direct distance from camera to plant center
          const distance = CanvasOperations.calculateDistance(
            props.cameraPosition,
            center
          );

          // console.log("Image line equation", imageLineEquation);

          // The line that is parallel to the backline and goes through the plant center
          const plantLineEquation: StraightLineEquation = {
            a: imageLineEquation.a,
            b: center.y - imageLineEquation.a * center.x,
          };
          if (plantLineEquation.a === Infinity) {
            plantLineEquation.x = center.x;
          }

          console.log("Plant line equation", plantLineEquation);

          // The plant lines intersections with the view lines (A and B)
          const intersectionA = Trig.getLineEquationIntersectionPoint(
            plantLineEquation,
            sideAEquation
          );
          const intersectionB = Trig.getLineEquationIntersectionPoint(
            plantLineEquation,
            sideBEquation
          );

          console.log("Intersection A", intersectionA);
          console.log("Intersection B", intersectionB);

          if (!intersectionA || !intersectionB) return null;

          // The distance between the camera and the center of the plant line (to avoid a straight line of plants to look curved)
          const plantLineCenter = {
            x: (intersectionA.x - intersectionB.x) / 2 + intersectionB.x,
            y: (intersectionA.y - intersectionB.y) / 2 + intersectionB.y,
          };
          const plantLineCenterDistance = CanvasOperations.calculateDistance(
            props.cameraPosition,
            plantLineCenter
          );

          // console.log("Plant line center", plantLineCenter);

          // The width of the line that goes through the plant center
          const plantImageWidth = CanvasOperations.calculateDistance(
            intersectionA,
            intersectionB
          );
          const distancePointA = CanvasOperations.calculateDistance(
            intersectionA,
            center
          );

          let adjustedSizeFactor = 1;
          if (props.enablePerspective) {
            const sizeFactor = imageWidth / plantImageWidth;
            adjustedSizeFactor = (1 - sizeFactor) * 0.8 + sizeFactor;
          }

          let offsetFactor = 0.4;
          if (props.slope) {
            offsetFactor = props.slope;
          }

          let offset = 0;
          if (props.enablePerspective) {
            const distancePercentage = plantLineCenterDistance / sideLength;
            console.log("Distance percentage", distancePercentage);
            offset = distancePercentage * (imageWidth / 2) * offsetFactor;
            console.log("Offset", offset);
          }

          const percentPosition = distancePointA / plantImageWidth;
          const template = props.plantTemplates.find(
            (template) => template.id === item.templateId
          );
          if (!template) return null;

          const avgHeight =
            ((template.heightMin || 0) + (template.heightMax || 0)) / 2;
          const maxSize = Math.max(avgHeight, item.size.width);
          return {
            plantTemplate: template,
            size: {
              width: maxSize * adjustedSizeFactor * 1.25, // Increase size to make plants blend better
              height: maxSize * adjustedSizeFactor * 1.25,
            },
            position: center,
            distance: plantLineCenterDistance,
            percentPosition: percentPosition,
            heightOffset: offset,
          } as SceneryPlant;
        })
    );
  }, [
    props.items,
    imageLineEquation,
    sideAEquation,
    sideBEquation,
    props.plantTemplates,
    props.cameraPosition,
    props.viewPointA,
    props.viewPointB,
    props.enablePerspective,
    props.slope,
  ]);

  const sceneryPlantsSorted = useMemo(() => {
    // Sort by distance - shortest distance last
    const plants = sceneryPlants.filter(
      (plant) => plant !== null
    ) as SceneryPlant[];
    return plants.sort((a: SceneryPlant, b: SceneryPlant) => {
      return b.distance - a.distance;
    });
  }, [sceneryPlants]);

  const missingProfileTemplates = useMemo(() => {
    const plants: string[] = [];
    sceneryPlantsSorted.forEach((plant) => {
      if (!plant.plantTemplate.leafProfileTemplate) {
        if (!plants.includes(plant.plantTemplate.name))
          plants.push(plant.plantTemplate.name);
      }
    });
    return plants;
  }, [sceneryPlantsSorted]);

  const minOffset = useMemo(() => {
    // The closest plant is last and has the smallest offset
    return (
      sceneryPlantsSorted[sceneryPlantsSorted.length - 1]?.heightOffset || 0
    );
  }, [sceneryPlantsSorted]);

  return (
    <>
      {missingProfileTemplates.length > 0 && (
        <>
          <p>
            {t("plants:countMissingProfileTemplate", {
              count: missingProfileTemplates.length,
            })}
            :
          </p>
          <ul>
            {missingProfileTemplates.map((plant, index) => (
              <li key={index}>{plant}</li>
            ))}
          </ul>
        </>
      )}
      <svg
        x={0}
        y={0}
        viewBox={`0 ${imageWidth / 2} ${imageWidth} ${imageWidth / 2}`}
        className={"scenery-image " + (props.className ? props.className : "")}

        // preserveAspectRatio="none"
      >
        {sceneryPlantsSorted.map((plant, index) => (
          <PlantProfileSVG
            key={index}
            plant={plant.plantTemplate}
            size={plant.size}
            position={{
              x: plant.percentPosition * imageWidth - plant.size.width / 2,
              y:
                imageWidth -
                (plant.size.height + plant.heightOffset - minOffset),
            }}
            month={props.month}
          />
        ))}
      </svg>
    </>
  );
};
