import { useTranslation } from "react-i18next";
import { Button, Ratio } from "..";
import { useEffect, useRef, useState } from "react";

interface Props {
  id: string;
  onImageSelected?: (image: File | undefined) => void;
  onRemoveImage?: () => void;
  initialImage?: string; // Src path of an initial image to show
  previewSizeRatio?: Ratio; /// 1,1 for square image, 2,1 for twice width etc.
  maxSize?: number; // Max file size allowed in MB
  hideButton?: boolean; // In case it should not be possible to upload a new image
  onError: (message: string) => void;
}

/**
 * Field that reads an image into memory when selected and provides the image file to the parent.
 * @param props
 */
export const ImageInput = (props: Props) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | undefined>(undefined);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const [canvasSize, setCanvasSize] = useState<Ratio>({
    width: 0,
    height: 0,
  });

  const [showInitialImage, setShowInitialImage] = useState<boolean>(
    props.initialImage !== undefined
  );

  /**
   * Checks that a file has a supported format and file size.
   * @param file
   * @returns
   */
  const validateFile = (file: File) => {
    if (file === undefined) return false;
    if (file.type !== undefined) {
      if (file.type !== "image/jpeg" && file.type !== "image/png") {
        props.onError(t("imagine:fileMustBeJpgOrPng"));
        return false;
      }
    }
    if (file.size !== undefined && props.maxSize !== undefined) {
      if (file.size > props.maxSize * 1000000) {
        props.onError(t("imagine:fileSizeLimitMB", { limit: props.maxSize }));
        return false;
      }
    }
    return true;
  };

  /**
   * Validate and load the file image.
   * @param event
   * @returns
   */
  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files[0];

      if (!validateFile(file)) return;
      setSelectedFile(file);
      loadFile(file);
    }
  };

  /**
   * Read file and trigger the image to be drawn. Also send event to parent.
   * @param file
   */
  const loadFile = (file: File) => {
    // Read as blob
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      function () {
        //setPreviewSource(reader.result);
        if (typeof reader.result == "string") {
          drawImage(reader.result);
        }
      },
      false
    );

    if (file) {
      reader.readAsDataURL(file);
      if (props.onImageSelected) {
        props.onImageSelected(file);
      }
    }
  };

  /**
   * Clear the image drawn on the canvas and set the canvas size to 0.
   */
  const clearImage = () => {
    if (wrapperRef.current !== null && canvasRef.current !== null) {
      const ctx = canvasRef.current.getContext("2d");
      if (ctx !== null) {
        ctx.clearRect(0, 0, canvasSize.width, canvasSize.height);
      }
      setCanvasSize({ width: 0, height: 0 });
    }
  };

  const removeImage = () => {
    clearImage();
    setSelectedFile(undefined);
    if (props.onRemoveImage) {
      props.onRemoveImage();
    }
  };

  /**
   * Draw image on the canvas.
   * @param imageData
   */
  const drawImage = (imageData: string) => {
    const img = new Image();
    if (wrapperRef.current !== null && canvasRef.current !== null) {
      const ctx = canvasRef.current.getContext("2d");
      const wrapperRect = wrapperRef.current?.getBoundingClientRect();

      img.onload = function () {
        if (ctx !== null) {
          const imgRatio = img.width / img.height;

          let sx = 0;
          let sy = 0;
          let swidth = img.width;
          let sheight = img.height;
          const x = 0;
          const y = 0;
          const width = wrapperRect.width;
          let height = wrapperRect.width / imgRatio;
          if (props.previewSizeRatio) {
            const previewRatio =
              props.previewSizeRatio.width / props.previewSizeRatio.height;
            // Image wider than wanted
            if (imgRatio > previewRatio) {
              swidth = img.height * previewRatio;
              sx = (img.width - swidth) / 2;
              height = wrapperRect.width * previewRatio;
            }
            // Image is taller than wanted
            else if (imgRatio < previewRatio) {
              sheight = img.width / previewRatio;
              sy = (img.height - sheight) / 2;
              height = wrapperRect.width * previewRatio;
            }
          }
          if (canvasSize.width !== width || canvasSize.height !== height) {
            setCanvasSize({ width: width, height: height });
          }
          setShowInitialImage(false);
          ctx.drawImage(img, sx, sy, swidth, sheight, x, y, width, height);
        }
      };
      img.src = imageData;
    }
  };

  // For some reason the canvas suddenly stopped working. Seems like it tried to draw image
  // before the canvas was resized and nothing was shown. Don't know why it worked before - maybe
  // due to React update or restructuring the app pointers (to support touch devices)
  useEffect(() => {
    if (selectedFile !== undefined && canvasSize.height > 0) {
      loadFile(selectedFile);
    }
  }, [canvasSize]);

  useEffect(() => {
    if (props.initialImage === undefined) setShowInitialImage(false);
    else setShowInitialImage(true);
    clearImage();
  }, [props.initialImage]);

  return (
    <div ref={wrapperRef} className="w-full">
      <div className="image-canvas-wrapper">
        <canvas
          ref={canvasRef}
          className="w-full"
          width={canvasSize.width}
          height={canvasSize.height}
          id={props.id + "-preview"}
        ></canvas>
      </div>
      {showInitialImage && props.initialImage && (
        <img
          ref={imageRef}
          src={props.initialImage}
          alt=""
          width="100%"
          className=""
          id={props.id + "-image"}
        ></img>
      )}
      {props.hideButton !== true && (
        <div className={"row gap-sm" + (canvasSize.height > 0 ? " pt-sm" : "")}>
          <label className="btn btn-accent-outline">
            <input
              type="file"
              onChange={handleFileChange}
              className="input-file"
              id={props.id + "-file-input"}
            />
            {t("imagine:selectFile")}
          </label>
          {(selectedFile || (showInitialImage && props.initialImage)) && (
            <Button color="danger" outline onClick={removeImage}>
              {t("imagine:remove")}
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
