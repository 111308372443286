import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CanvasOperations,
  EventProcess,
  ItemCorner,
  ShapeOperations,
  ShapeStyle,
  TextAlign,
  TextProperties,
  Mouse,
  MouseState,
} from "draw";
import { Point, Rectangle, Size } from "imagine-essentials";

import { ResizeSvg } from ".";
import { Device } from "imagine-ui";

const minimimSize = 2;
const minWidthHeight = 8;
const longTouchTime = 600;

interface Props {
  style: ShapeStyle;
  text: TextProperties;
  shapeId: number;
  onEditingChange: (editing: boolean) => void; // Triggered when editing is started/stopped (dragging or resizing)
  onTextShapeChange: (shape: TextProperties) => void;
  update: boolean;
  onRightClick?: (position: Point) => void;
  onLongTouch?: (pos: Point) => void;
  onClick?: () => void;
  rotation: number;
  className?: string;
  canvasOffset: Point;
  disabled?: boolean; // Will not trigger on mouse events if set to true
  onEditText: () => void; // When the text needs to be edited (not handled by the text creator)
}

const isTouchDevice = Device.isTouchDevice();

/**
 * Creator of texts.
 */
export const TextCreator = (props: Props) => {
  const {
    onEditingChange,
    onClick,
    onTextShapeChange,
    onEditText,
    onLongTouch,
  } = props;
  // Used to time long touch
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const fontSizePx = useMemo(() => {
    return props.text.fontSize ?? 16;
  }, [props.text]);

  /**
   * Padding is 1/4th of the font size.
   */
  const padding = useMemo(() => {
    return (props.text.fontSize ?? 30) / 4;
  }, [props.text.fontSize]);

  const [tempSize, setTempSize] = useState<Size | undefined>();
  const size = useMemo(() => {
    return tempSize !== undefined ? tempSize : props.text.size;
    // if(s.height < minHeight) s.height = minHeight;
    // return s;
  }, [props.text.size, tempSize]);

  const textLines = useMemo(() => {
    return ShapeOperations.textToLines(
      props.text.text,
      fontSizePx,
      size.width - 2 * padding
    );
  }, [fontSizePx, padding, size.width, props.text.text]);

  const minHeight = useMemo(() => {
    // 1.5 is the line height of the lines
    const lineHeight = textLines.length * fontSizePx * 1.5 + 2 * padding;
    return lineHeight;
    // return Math.max(lineHeight, size.height);
  }, [fontSizePx, padding, textLines.length]);

  const [startingPoint, setStartingPoint] = useState<Point | null>(null);
  const [resizeStart, setResizeStart] = useState<Point | null>(null);
  const [resizeCorner, setResizeCorner] = useState<ItemCorner>(ItemCorner.NONE);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  const clicked = useRef(false);

  const [tempPosition, setTempPosition] = useState<Point | undefined>();
  const position = useMemo(() => {
    if (tempPosition !== undefined) return tempPosition;
    return props.text.position;
  }, [tempPosition, props.text]);

  const getRotation = () => {
    return props.rotation;
  };

  // Used to time double click
  const timer = useRef<NodeJS.Timeout | undefined>();

  const recordClick = useCallback(() => {
    if (onClick) onClick();
    if (clicked.current) {
      // Double click
      onEditText();

      clicked.current = false;
      if (timer.current !== undefined) {
        clearTimeout(timer.current);
        timer.current = undefined;
      }
    } else {
      clicked.current = true;
      timer.current = setTimeout(() => {
        clicked.current = false;
      }, 500);
    }
  }, [onClick, onEditText]);

  const getBoardPosition = useCallback(
    (position: Point) => {
      return CanvasOperations.getPointSubtracted(position, props.canvasOffset);
    },
    [props.canvasOffset]
  );

  const startDrawing = useCallback(
    (position: Point) => {
      if (!props.update) {
        const start = getBoardPosition(position);
        setStartingPoint(start);
      }
    },
    [getBoardPosition, props.update]
  );

  const updateShape = useCallback(
    (position: Point) => {
      if (startingPoint !== null) {
        const sanitizedRectangle = ShapeOperations.getSanitizedRectangle(
          startingPoint,
          getBoardPosition(position),
          minWidthHeight
        );
        setTempSize(sanitizedRectangle.size);
        setTempPosition(sanitizedRectangle.position);
      }
    },
    [getBoardPosition, startingPoint]
  );

  /**
   * Starts dragging mode and stores the initial position. Does nothing if already dragging or resizing.
   */
  const startDragging = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (event?: any, position?: Point) => {
      if (onClick) onClick();
      if (props.disabled) return;
      if (event) {
        event.stopPropagation();
      }
      if (isDragging || resizeCorner !== ItemCorner.NONE) return;
      recordClick();
      // The board offset has already been subtracted when the attribute was set
      let pos: Point | undefined;
      if (position !== undefined) {
        pos = getBoardPosition(position);
      }
      // Position not stored yet for touch devices
      if (event !== undefined) {
        // Do not start dragging on right clicks
        if (!EventProcess.isLeftClick(event)) return;
        // The RectangleCreator used the board 0,0 as reference
        pos = getBoardPosition(EventProcess.getEventPosition(event));
      }
      if (pos === undefined) return;
      setDragStart(pos);
      setIsDragging(true);
      onEditingChange(true);

      if (isTouchDevice) {
        timeoutRef.current = setTimeout(() => {
          if (pos !== undefined && onLongTouch) {
            onLongTouch(EventProcess.getEventPosition(event));

            // This should also release the event
            Mouse.release.next({
              position: pos,
              pressed: false,
              rightPressed: false,
              elementClass: EventProcess.getClass(event),
              positions: [pos],
            });
          }
        }, longTouchTime);
      }
    },
    [
      onClick,
      props.disabled,
      isDragging,
      resizeCorner,
      recordClick,
      onEditingChange,
      getBoardPosition,
      onLongTouch,
    ]
  );

  /**
   * Updates the shape accoring to the new position.
   */
  const dragShape = useCallback(
    (position: Point) => {
      const diff = CanvasOperations.getPointSubtracted(
        getBoardPosition(position),
        dragStart
      );
      if (isDragging) {
        const newPosition = CanvasOperations.getPointSum(
          props.text.position,
          diff
        );
        setTempPosition(newPosition);
      }
      // Clear long touch timeout if touch was moved (more than 2 px)
      if (timeoutRef.current !== null) {
        // const pos = EventProcess.getEventPosition(event);
        const dist = CanvasOperations.calculateDistance(diff, { x: 0, y: 0 });
        if (dist > 2) {
          clearTimeout(timeoutRef.current);
        }
      }
    },
    [dragStart, getBoardPosition, isDragging, props.text.position]
  );

  /**
   * Exits dragging mode and notifies parent with new position.
   */
  const finishDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);
      onTextShapeChange({
        ...props.text,
        position: position,
      });
      onEditingChange(false);
      setTempPosition(undefined);
    }
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  }, [isDragging, onEditingChange, onTextShapeChange, position, props.text]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  /**
   * Enters resizing mode and stores the initial size and position.
   */
  const beginResizing = (corner: ItemCorner, pos: Point) => {
    if (props.update) {
      onEditingChange(true);

      // To support touch devices, the position is passed in the callback, but this position is not aligned with the board (because only the Creator components are using the board's 0,0 as reference,. The items for instance is using the position as it is, so dont change it in the ResizeSvg.)
      const adjustedPos = getBoardPosition(pos);
      setResizeStart(adjustedPos);
      setResizeCorner(corner);
    }
  };

  /**
   * Updates the shape with the new size and position.
   */
  const resizeRectangle = useCallback(
    (position: Point) => {
      if (resizeStart !== null && resizeCorner !== ItemCorner.NONE) {
        const storedRect = {
          size: props.text.size,
          position: props.text.position,
        };

        const newRectangle = ShapeOperations.getResize(
          getBoardPosition(position),
          resizeCorner,
          resizeStart,
          storedRect as Rectangle,
          props.rotation,
          false
        );

        if (newRectangle.size.height < minHeight) {
          newRectangle.size.height = minHeight;
        }
        setTempPosition(newRectangle.position);
        setTempSize(newRectangle.size);
      }
    },
    [
      resizeStart,
      resizeCorner,
      props.text.size,
      props.text.position,
      props.rotation,
      getBoardPosition,
      minHeight,
    ]
  );

  const finishDrawing = useCallback(() => {
    if (startingPoint !== null || resizeCorner !== ItemCorner.NONE) {
      setStartingPoint(null);
      setResizeCorner(ItemCorner.NONE);

      if (size.width < minimimSize && size.height < minimimSize) {
        onEditingChange(false);
      } else {
        const newText = {
          ...props.text,
          // textLines: textLines,
          size: size,
          position: position,
        };
        onTextShapeChange(newText);
        setTempSize(undefined);
        setTempPosition(undefined);

        onEditingChange(false);
        // Open text editor immediately
        if (!props.update) onEditText();
        // startEditText();
      }
    }
  }, [
    onEditText,
    onEditingChange,
    onTextShapeChange,
    position,
    props.text,
    props.update,
    resizeCorner,
    size,
    startingPoint,
  ]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        if (props.update) {
          // Start dragging immediately if mouse if pressed and element beneath is the creator shape
          if (state.elementClass === "text-creator-shape") {
            startDragging(undefined, state.position);
          }
          // These functions return immediately if shape is currently not being resized/dragged
          resizeRectangle(state.position);
          dragShape(state.position);
        } else {
          updateShape(state.position);
        }
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      // if (props.update && resizeCorner === ItemCorner.NONE) {
      //   if (props.editingText && state.elementClass !== "text-creator-shape") {
      //     finish();
      //   } else if (
      //     state.elementClass !== "text-creator-shape" &&
      //     state.elementClass !== "static-item"
      //   ) {
      //     finish();
      //   }
      // }
      startDrawing(state.position);
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      finishDrawing();
      finishDragging();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    dragShape,
    // finish,
    finishDragging,
    finishDrawing,
    props.update,
    resizeCorner,
    resizeRectangle,
    startDragging,
    startDrawing,
    updateShape,
  ]);

  // useEffect(() => {
  //   // Make sure new zoom values are not overridden by internal valus
  //   setTempSize(undefined);
  //   setTempPosition(undefined);
  //   setText(props.text.text);
  // }, [props.text]);

  /**
   * When the font size has been changed, the lines should be updated immediately. Otherwise it will not
   * update if exiting the text creator.
   */
  // useEffect(() => {
  //   const fs = props.text.fontSize ?? 16;
  //   const p = fs / 4;
  //   const lines = Util.textToLines(text, fs, size.width - 2 * p);
  //   props.onTextChanged({
  //     ...props.text,
  //     textLines: lines,
  //   });
  // }, [props.text.fontSize]);

  // This will fix the height of the box and split to multiple lines. It shold only be called once.
  // Only needed because the text element had major changes in version 1.5
  useEffect(() => {
    if (!props.update) return;
    const fs = props.text.fontSize ?? 16;
    const p = fs / 4;
    const lines = ShapeOperations.textToLines(
      props.text.text,
      fs,
      props.text.size.width - 2 * p
    );
    const min = lines.length * fs + 2 * p;
    if (props.text.size.height < min) {
      onTextShapeChange({
        ...props.text,
        // textLines: lines,
        size: {
          ...props.text.size,
          height: min,
        },
      });
    }
    // Must only run once!
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const textAnchor = useMemo(() => {
    switch (props.text.textAlign) {
      case TextAlign.LEFT:
        return "start";
      case TextAlign.CENTER:
        return "middle";
      case TextAlign.RIGHT:
        return "end";
    }
  }, [props.text.textAlign]);

  const textXPosition = useMemo(() => {
    switch (props.text.textAlign) {
      case TextAlign.LEFT:
        return position.x + padding;
      case TextAlign.CENTER:
        return position.x + size.width / 2;
      case TextAlign.RIGHT:
        return position.x + size.width - padding;
    }
  }, [props.text.textAlign, position.x, padding, size.width]);

  const triggerRightClick = (event: any) => {
    if (isTouchDevice) return;
    const pos = EventProcess.getEventPosition(event);
    if (props.onRightClick) props.onRightClick(pos);
  };

  return (
    <>
      {/* {(props.text.text !== "" || editingText) && ( */}
      <g
        id="text-creator"
        transform={
          "rotate(" +
          getRotation() +
          ", " +
          (position.x + size.width / 2) +
          ", " +
          (position.y + size.height / 2) +
          ")"
        }
        className={"text-creator"}
      >
        {size.width > 0 && size.height > 0 && (
          <>
            <rect
              x={position.x}
              y={position.y}
              width={size.width}
              height={size.height}
              fill={ShapeOperations.getFill(props.style)}
              opacity={ShapeOperations.getOpacity(props.style)}
              // onMouseDown={handleMouseDown}
              // onDoubleClick={startEditText}
              cursor={"move"}
              // onContextMenu={triggerRightClick}
              onMouseDown={startDragging}
              onTouchStart={startDragging}
              onContextMenu={triggerRightClick}
              className={"text-creator-shape"}
            ></rect>

            <text
              x={position.x + padding} // Minus 1 because border shifts the box
              y={position.y + padding}
              width={size.width}
              fill={props.style.borderColor}
              // height={size.height}
              textAnchor={textAnchor}
              // dominantBaseline="text-before-edge"
              fontSize={fontSizePx}
              cursor={"move"}
              // onContextMenu={triggerRightClick}
              onMouseDown={startDragging}
              onTouchStart={startDragging}
              onContextMenu={triggerRightClick}
              className={"text-creator-shape"}
              fontFamily="Noto Sans"
            >
              {textLines.map((line: string, index: number) => (
                <tspan
                  key={index}
                  x={textXPosition}
                  // dy={fontSizePx * 1.5}
                  // Line height is 1.5 * font size
                  dy={index === 0 ? fontSizePx * 1.25 : fontSizePx * 1.5}
                  // alignmentBaseline="before-edge"

                  className={"text-creator-shape"}
                >
                  {line}
                </tspan>
              ))}
            </text>

            <ResizeSvg
              onResizeStarted={beginResizing}
              pixelSize={size}
              position={position}
              rotation={props.rotation}
              preserveAspectRatio={false}
              resizable={true}
            />
          </>
        )}
      </g>
      {/* )} */}
    </>
  );
};
