import { Point, Size } from "imagine-essentials";
import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
} from "react";

import { Mouse, MouseState } from "draw";

type ContextMenuProps = {
  children?: React.ReactNode;
  position: Point; /// Requested position of the context menu (might adjust if there is not enough space)
  canvasSize: Size;
  canvasOffset: Point;
  fullscreen?: boolean;
  onClose?: () => void;
};

/**
 * Displays an custom context menu when right clicking.
 * @param props
 */
export const CanvasContextMenu = (props: ContextMenuProps) => {
  const contextRef = useRef<HTMLInputElement>(null);

  const { onClose } = props;

  const [cssPosition, setCssPosition] = useState<{
    left?: string;
    right?: string;
    top?: string;
    bottom?: string;
  }>({});

  /**
   * This will keep the context menu hidden until the correct position is calculated. Otherwise it will blink at 0,0 location
   */
  const displayName = useMemo(() => {
    if (cssPosition.left !== undefined || cssPosition.right !== undefined)
      return "d-block";
    return "d-none";
  }, [cssPosition.left, cssPosition.right]);

  /**
   * Always close the context menu when an item has been clicked. The item click will
   * propagate to the context menu element
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    event.stopPropagation();
    if (props.onClose) props.onClose();
  };

  // // Close the context menu 1 cycle after it has lost focus, this will allow a possible click
  // // on one of the context items to be registered before context menu is closed
  // const handleBlur = () => {
  //   setTimeout(handleClick, 0);
  // };

  const calculateContextMenuPosition = useCallback(() => {
    if (contextRef.current === null) return;
    const canvasPosition = props.position;
    const widthLimit = props.canvasSize.width / 2;
    const heightLimit = props.canvasSize.height / 2;

    const rect = contextRef.current?.getBoundingClientRect();
    const contextSize = { width: rect.width, height: rect.height };

    const left =
      props.position.x + contextSize.width < props.canvasSize.width
        ? props.position.x
        : props.canvasSize.width - contextSize.width;
    const top =
      props.position.y + contextSize.height < props.canvasSize.height
        ? props.position.y
        : props.canvasSize.height - contextSize.height;
    const right =
      props.position.x > contextSize.width
        ? props.canvasSize.width - props.position.x
        : props.canvasSize.width - contextSize.width;
    const bottom =
      props.position.y > contextSize.height
        ? props.canvasSize.height - props.position.y
        : props.canvasSize.height - contextSize.height;

    if (canvasPosition.x <= widthLimit && canvasPosition.y <= heightLimit) {
      // Top left
      setCssPosition({
        left: left + "px",
        top: top + "px",
      });
    } else if (
      canvasPosition.x <= widthLimit &&
      canvasPosition.y > heightLimit
    ) {
      // Bottom left
      setCssPosition({
        left: left + "px",
        bottom: bottom + "px",
      });
    } else if (
      canvasPosition.x > widthLimit &&
      canvasPosition.y <= heightLimit
    ) {
      // Top right
      setCssPosition({
        right: right + "px",
        top: top + "px",
      });
    } else if (
      canvasPosition.x > widthLimit &&
      canvasPosition.y > heightLimit
    ) {
      // Bottom right
      setCssPosition({
        right: right + "px",
        bottom: bottom + "px",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.canvasSize, props.position, contextRef.current]);

  useEffect(() => {
    calculateContextMenuPosition();
    if (contextRef.current !== null) {
      contextRef.current.focus();
    }
  }, [calculateContextMenuPosition]);

  // Close contect menu on any mouse down event outside of the context menu div
  useEffect(() => {
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      const rect = contextRef.current?.getBoundingClientRect();
      if (rect === undefined) return;
      if (
        state.position.x < rect.left ||
        state.position.x > rect.right ||
        state.position.y < rect.top ||
        state.position.y > rect.bottom
      ) {
        if (onClose) onClose();
      }
    });

    return () => {
      pressObserver.unsubscribe();
    };
  }, [onClose, props.canvasOffset, props.canvasSize]);

  return (
    <>
      <div
        className={"context-menu " + displayName}
        style={cssPosition}
        ref={contextRef}
        id="canvas-context-menu"
        onClick={handleClick}
        onTouchStart={handleClick}
      >
        {props.children}
      </div>
    </>
  );
};
