import { useEffect, useMemo, useState } from "react";
import { MapLocationModal, ReferenceImageModal } from "../../components";
import { MapMode, Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
  CanvasActions,
  CanvasSelector,
  PlanEditorSelector,
  PlanEditorActions,
} from "../../store";
import { PlantCollection, PlantCollectionOverviewPage } from "plants";
import { MapLocation } from "../../types";
import { CanvasOperations, MapPosition, ReferenceImage } from "draw";
import { useTranslation } from "react-i18next";
import { Loader } from "@googlemaps/js-api-loader";
import { UserActions, UserSelector } from "imagine-users";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { MapOperations } from "../../utils";
import { PlansApi } from "../../api";
import { Api, ImageUtils, SentryReporter } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for connecting the state and API calls to the map location.
 */
export const ReferenceImageModalContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const planInfo = useAppSelector(PlanEditorSelector.getPlanInfo);
  const referenceImage = useAppSelector(PlanEditorSelector.getReferenceImage);
  const visibleRectangle = useAppSelector(CanvasSelector.getVisibleRectangle);
  const [errorMessage, setErrorMessage] = useState("");
  const [uploading, setUploading] = useState(false);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const delimiter = I18nTools.getDelimiter(user.language);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const uploadImage = async (image: File | undefined, distance: number) => {
    if (image) {
      setUploading(true);
      setErrorMessage("");
      dispatch(UserActions.track(FeatureKey.REFERENCE_IMAGE));
      const response = await PlansApi.addReferenceImage(planInfo.id, image);
      if (response.success && response.data) {
        const size = await ImageUtils.getImageDimensions(
          Api.getHost() + "/user-plans/" + response.data
        );
        const newReferenceImage: ReferenceImage = {
          fileName: response.data,
          center: { x: 0, y: 0 },
          rotation: 0,
          pointA: { x: 0, y: 0 },
          pointB: { x: size.width, y: 0 },
          distance: distance,
          size: size,
        };
        dispatch(PlanEditorActions.setReferenceImage(newReferenceImage));
        dispatch(CanvasActions.setShowReferenceImage(true));
        dispatch(PlanEditorActions.setEditingReferenceImage(true));
        close();
      } else {
        setErrorMessage("failedToUploadReferenceImage");
      }

      setUploading(false);
    }
  };

  const removeImage = async () => {
    setErrorMessage("");
    const response = await PlansApi.removeReferenceImage(planInfo.id);
    if (response.success) {
      dispatch(PlanEditorActions.setReferenceImage(undefined));
      close();
    } else {
      setErrorMessage("failedToRemoveReferenceImage");
    }
  };

  return (
    <ReferenceImageModal
      visible={true}
      onClose={close}
      onSelectImage={uploadImage}
      onRemoveImage={removeImage}
      loading={uploading}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
      distance={referenceImage?.distance || 0}
      image={referenceImage?.fileName}
      errorMessage={errorMessage}
    />
  );
};
