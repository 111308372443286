import { createAsyncThunk } from "@reduxjs/toolkit";
import { PagesApi } from "imagine-pages";

export const fetchKeyPages = createAsyncThunk(
  "website/fetchKeyPages",
  async (arg, thunkApi) => {
    return await PagesApi.getKeyPages();
  }
);

export const WebsiteThunks = {
  fetchKeyPages,
};
