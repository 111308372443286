import React, { useMemo } from "react";
import { ShapeOperations, ShapeStyle, TextAlign, TextProperties } from "..";
import { Point } from "imagine-essentials";

interface Props {
  text: TextProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  cursor?: string; // override cursor
  rotation?: number;
  className?: string;
  uniqueId?: string; // Used to link the patterns to the shapes
}

const defaultStyle: ShapeStyle = {
  borderColor: "#000000",
  fillColor: "#CCCCCC",
  opacity: 1,
};

export const TextSvg = (props: Props) => {
  const style = props.style !== undefined ? props.style : defaultStyle;

  const fontSizePx = useMemo(() => {
    return (props.text.fontSize ?? 50) / 100;
  }, [props.text]);

  const padding = useMemo(() => {
    return fontSizePx / 4;
  }, [fontSizePx]);

  const textAnchor = useMemo(() => {
    switch (props.text.textAlign) {
      case TextAlign.LEFT:
        return "start";
      case TextAlign.CENTER:
        return "middle";
      case TextAlign.RIGHT:
        return "end";
    }
  }, [props.text.textAlign]);

  const textXPosition = useMemo(() => {
    switch (props.text.textAlign) {
      case TextAlign.LEFT:
        return props.text.position.x + padding;
      case TextAlign.CENTER:
        return props.text.position.x + props.text.size.width / 2;
      case TextAlign.RIGHT:
        return props.text.position.x + props.text.size.width - padding;
    }
  }, [props.text, padding]);

  const textLines = useMemo(() => {
    // console.log(fontSizePx, size.width, padding, props.text.text);
    return ShapeOperations.textToLines(
      props.text.text,
      fontSizePx,
      props.text.size.width - 2 * padding
    );
  }, [fontSizePx, padding, props.text.size.width, props.text.text]);

  return (
    <>
      <g
        transform={
          "rotate(" +
          (props.rotation || 0) +
          ", " +
          (props.text.position.x + props.text.size.width / 2) +
          ", " +
          (props.text.position.y + props.text.size.height / 2) +
          ")"
        }
      >
        <rect
          width={props.text.size.width}
          height={props.text.size.height}
          x={0}
          y={0}
          fill={ShapeOperations.getFill(style, props.uniqueId)}
          fillOpacity={ShapeOperations.getOpacity(style)}
          style={{ cursor: props.cursor || "move" }}
          className={props.className}
        />

        <text
          x={padding}
          y={padding}
          fill={ShapeOperations.getStroke(style)}
          // dominantBaseline="text-before-edge"
          style={{ cursor: props.cursor || "move" }}
          fontSize={fontSizePx}
          textAnchor={textAnchor}
          fontFamily="Noto Sans"
        >
          {textLines.map((line: string, index: number) => (
            <tspan
              key={index}
              x={textXPosition}
              // dy={fontSizePx}
              dy={index === 0 ? fontSizePx * 1.25 : fontSizePx * 1.5}

              // dy={index === 0 ? 0 : fontSizePx}
              // alignmentBaseline="before-edge"
            >
              {line}
            </tspan>
          ))}
        </text>
      </g>
    </>
  );
};
