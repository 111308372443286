import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import gsDa from "./lan/da_gs.json" assert { type: "json" };
import { usersDa } from "imagine-users";
import { membershipsDa } from "imagine-memberships";
import { imagineDa } from "imagine-essentials";
import { datetimeDa } from "imagine-datetime";
import { I18nTools, i18nDa } from "imagine-i18n";
import { plantsDa } from "plants";
import { drawDa } from "draw";
import { projectDa } from "project";
import { plantPurchaseDa } from "plant-purchase";

import gsEnGB from "./lan/en-GB_gs.json" assert { type: "json" };
import { usersEnGB } from "imagine-users";
import { membershipsEnGB } from "imagine-memberships";
import { imagineEnGB } from "imagine-essentials";
import { datetimeEnGB } from "imagine-datetime";
import { i18nEnGB } from "imagine-i18n";
import { plantsEnGB } from "plants";
import { drawEnGB } from "draw";
import { projectEnGB } from "project";
import { plantPurchaseEnGB } from "plant-purchase";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
  da: {
    gs: gsDa,
    users: usersDa,
    memberships: membershipsDa,
    imagine: imagineDa,
    datetime: datetimeDa,
    i18n: i18nDa,
    plants: plantsDa,
    draw: drawDa,
    project: projectDa,
    plantPurchase: plantPurchaseDa,
  },
  "en-GB": {
    gs: gsEnGB,
    users: usersEnGB,
    memberships: membershipsEnGB,
    imagine: imagineEnGB,
    datetime: datetimeEnGB,
    i18n: i18nEnGB,
    plants: plantsEnGB,
    draw: drawEnGB,
    project: projectEnGB,
    plantPurchase: plantPurchaseEnGB,
  },
};

const getDefaultLanguage = () => {
  const browserLanguage = I18nTools.getBrowserLanguage();
  const validLanguage = I18nTools.getValidLanguage(browserLanguage);
  return validLanguage;
  // return I18nTools.getValidLanguage(I18nTools.getBrowserLanguage());
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    returnNull: false,
    ns: [
      "gs",
      "users",
      "imagine",
      "country",
      "admin",
      "plants",
      "datetime",
      "plantPurchase",
    ],
    defaultNS: "gs",
    resources,
    debug: true,
    lng: getDefaultLanguage(), // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
