export enum ItemCorner {
  NONE = 0,
  N = 1,
  E = 2,
  S = 3,
  W = 4,
  NE = 5,
  SE = 6,
  SW = 7,
  NW = 8,
}
