import { Device, DeviceSize } from "imagine-ui";
import { Pages } from "../../enums";
import { RootState } from "..";

export const LayoutSelector = {
  isSmOrLarger: (state: RootState) => {
    return Device.isMinSize(state.layout.windowSize.width, DeviceSize.SM);
  },
  isMdOrLarger: (state: RootState) => {
    return Device.isMinSize(state.layout.windowSize.width, DeviceSize.MD);
  },
  isLgOrLarger: (state: RootState) => {
    return Device.isMinSize(state.layout.windowSize.width, DeviceSize.LG);
  },
  isXlOrLarger: (state: RootState) => {
    return Device.isMinSize(state.layout.windowSize.width, DeviceSize.XL);
  },
  isSmOrSmaller: (state: RootState) => {
    return Device.isMaxSize(state.layout.windowSize.width, DeviceSize.SM);
  },
  isMdOrSmaller: (state: RootState) => {
    return Device.isMaxSize(state.layout.windowSize.width, DeviceSize.MD);
  },
  isLgOrSmaller: (state: RootState) => {
    return Device.isMinSize(state.layout.windowSize.width, DeviceSize.LG);
  },
  isXlOrSmaller: (state: RootState) => {
    return Device.isMaxSize(state.layout.windowSize.width, DeviceSize.XL);
  },
  getSidebarLeftOpen: (state: RootState) => {
    return state.layout.sidebarLeftActiveTab !== "";
  },
  getSidebarLeftActiveTab: (state: RootState) => {
    return state.layout.sidebarLeftActiveTab;
  },
  getSidebarRightOpen: (state: RootState) => {
    return state.layout.sidebarRightActiveTab !== "";
  },
  getSidebarRightActiveTab: (state: RootState) => {
    return state.layout.sidebarRightActiveTab;
  },
  getFooterOpen: (state: RootState) => {
    return state.layout.footerActiveTab !== "";
  },
  getFooterActiveTab: (state: RootState) => {
    return state.layout.footerActiveTab;
  },
  getWindowHeight: (state: RootState) => {
    return state.layout.windowSize.height;
  },
  getWindowWidth: (state: RootState) => {
    return state.layout.windowSize.width;
  },
  getActiveTab: (state: RootState) => {
    return state.layout.activeTab;
  },
  getActiveTabFallback: (state: RootState) => {
    return state.layout.activeTabPrevious;
  },
  getDisplayedPage: (state: RootState) => {
    if (state.layout.displayedPageStack.length === 0) return Pages.NONE;
    return state.layout.displayedPageStack[
      state.layout.displayedPageStack.length - 1
    ];
  },
  getUserAccountTab: (state: RootState) => {
    return state.layout.userAccountTab;
  },
  getCurrentTour: (state: RootState) => {
    return state.layout.currentTour;
  },
};
