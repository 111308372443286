import { Item, ItemType, ObjectOperations, ObjectSchema } from "draw";
import { RootState } from "..";
import { createSelector } from "@reduxjs/toolkit";
import _ from "lodash";
import { stat } from "fs";

export const ObjectEditorSelector = {
  isObjectMode: (state: RootState) => {
    return state.objectEditor.objectMode;
  },
  getSavedObjectTemplate: (state: RootState) => {
    return state.objectEditor.savedObjectTemplate;
  },

  getOriginalObjectItem: (state: RootState) => {
    return state.objectEditor.originalObjectItem;
  },
  getIndex: (state: RootState) => {
    return state.objectEditor.index;
  },
  getItems: (state: RootState) => {
    return state.objectEditor.items;
  },
  getSelectedItemIds: (state: RootState) => {
    return state.objectEditor.selectedItemIds;
  },
  getSelectedItems: createSelector(
    (state: RootState) => state.objectEditor.items,
    (state: RootState) => state.objectEditor.selectedItemIds,
    (items, selectedItemIds) => {
      return items.filter((item: Item) => selectedItemIds.includes(item.id));
    }
  ),
  getOriginalSelectedItems: (state: RootState) => {
    return state.objectEditor.originalSelectedItems;
  },
  hasUnsavedChanges: (state: RootState) => {
    return state.objectEditor.unsavedChanges;
  },
  getLayerId: (state: RootState) => {
    return state.objectEditor.layerId;
  },
  // The object template needed to represent the current configuration
  getCurrentObjectTemplate: createSelector(
    (state: RootState) => state.objectEditor.savedObjectTemplate,
    (state: RootState) => state.objectEditor.name,
    (state: RootState) => state.objectEditor.category,
    (state: RootState) => state.objectEditor.items,
    (savedObjectTemplate, name, category, items) => {
      let template: ObjectSchema = savedObjectTemplate
        ? _.cloneDeep(savedObjectTemplate)
        : ObjectOperations.getDefaultObject();
      if (name !== "") {
        template.name = name;
      }
      if (category !== undefined) {
        template.category = category;
      }

      template = ObjectOperations.generateObjectFromItems(items, template);

      return template;
    }
  ),
  // The object item that the shapes will be transformed into in the plan (based on saved template and not actual items)
  getObjectItem: createSelector(
    (state: RootState) => state.objectEditor.savedObjectTemplate,
    (state: RootState) => state.objectEditor.index,
    (state: RootState) => state.objectEditor.layerId,
    (state: RootState) => state.objectEditor.originalSelectedItems,
    (template, index, layerId, originalSelectedItems) => {
      if (template === null) return;
      const position = ObjectOperations.getObjectDefaultPosition(
        template.items
      );
      const viewBox = "0 0 " + template.width + " " + template.height;
      // Might set size and rotation to the value a potential edited object item has
      const newItem: Item = {
        id: 0, // Will be overridden when inserted
        type: ItemType.OBJECT,
        index: index,
        templateId: template.id,
        size: { width: template.width, height: template.height },
        rotation: 0,
        position: position,
        viewBox: viewBox,
        layerId: layerId, // Should be in the same layer as the object it was copied from
      };
      if (originalSelectedItems.length === 0) return newItem;

      const originalPosition = ObjectOperations.getObjectDefaultPosition(
        originalSelectedItems
      );
      // If the shapes in the object has changed positions, the object might not return at the exact same position in the plan
      newItem.id = originalSelectedItems[0].id;
      newItem.index = originalSelectedItems[0].index;
      newItem.layerId = originalSelectedItems[0].layerId;
      newItem.position = originalPosition;
      // newItem.size = originalObjectItem.size;
      // newItem.rotation = originalObjectItem.rotation;

      return newItem;
    }
  ),
  getCategory: (state: RootState) => {
    return (
      state.objectEditor.category ||
      state.objectEditor.savedObjectTemplate?.category ||
      undefined
    );
  },
  getName: (state: RootState) => {
    return (
      state.objectEditor.name ||
      state.objectEditor.savedObjectTemplate?.name ||
      ""
    );
  },
  getCopiedItems: (state: RootState) => {
    return state.objectEditor.copiedItems;
  },
  isUndoAllowed: (state: RootState) => {
    return state.objectEditor.actionHistory.length > 0;
  },
  isRedoAllowed: (state: RootState) => {
    return state.objectEditor.actionUndoneHistory.length > 0;
  },
};
