import { InvoiceInfo } from "imagine-memberships";

const invoiceInfo: InvoiceInfo[] = [
  {
    productName: "Garden Sketcher",
    companyName: "Garden Sketcher - En del af Imagine Development",
    companyAddress: "Kantatevej 18",
    companyCity: "2730 Herlev",
    companyCountry: "Danmark",
    companyCVR: "43418726",
    companyWebsite: "gardensketcher.com",
    companyEmail: "kinna@gardensketcher.com",
    logoPath: window.location.origin + "/img/invoice-logo.png",
    vat: 0,
    date: "2000-01-01",
    language: "da",
  },
  {
    productName: "Garden Sketcher",
    companyName: "Garden Sketcher ApS",
    companyAddress: "Kantatevej 18",
    companyCity: "2730 Herlev",
    companyCountry: "Danmark",
    companyCVR: "44067161",
    companyWebsite: "gardensketcher.com",
    companyEmail: "kinna@gardensketcher.com",
    logoPath: window.location.origin + "/img/invoice-logo.png",
    vat: 25,
    date: "2023-09-01",
    language: "da",
  },
];

const getInvoiceInfo = () => {
  return invoiceInfo;
};

export const InvoiceData = {
  getInvoiceInfo,
};
