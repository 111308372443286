import {
  CheckoutStep,
  CheckoutStepBar,
  Customer,
  CustomerInfo,
  DisplayProduct,
  MembershipActions,
  MembershipSelector,
  StripeCheckout,
  StripeProductListing,
  SubscriptionCoupon,
  SubscriptionProduct,
  SubscriptionProductCard,
  SubscriptionTools,
  SubscriptionDataApi,
  SubscriptionManagerApi,
} from "imagine-memberships";
import { UserRole, UserSelector } from "imagine-users";
import { WebsiteSelector, useAppDispatch, useAppSelector } from "../../store";
import { useCallback, useEffect, useMemo, useState } from "react";
import { Country, CurrencyTools } from "imagine-i18n";
import { Button, NotificationType, Spinner, useNotification } from "imagine-ui";
import { ConfirmationStripeContainer } from "..";
import { useTranslation } from "react-i18next";
import { Api } from "imagine-essentials";
import { KeyPageUtils } from "imagine-pages";

export const BuyStripeMembershipContainer = () => {
  const dispatch = useAppDispatch();
  // const notification = useNotification();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const [loading, setLoading] = useState(false);
  const [preparingPayment, setPreparingPayment] = useState(false);
  const { t } = useTranslation();
  const notification = useNotification();
  const keyPages = useAppSelector(WebsiteSelector.getKeyPages);

  const [subscriptionProducts, setSubscriptionProducts] = useState<
    SubscriptionProduct[]
  >([]);
  const [coupons, setCoupons] = useState<SubscriptionCoupon[]>([]);

  const selectedDisplayProduct = useAppSelector(
    MembershipSelector.getSelectedDisplayProduct
  );
  const activeSubscription = useAppSelector(
    MembershipSelector.getActiveSubscription
  );

  const customer = useAppSelector(MembershipSelector.getCustomerInfo);
  const checkoutStep = useAppSelector(MembershipSelector.getCheckoutStep);
  const [clientSecret, setClientSecret] = useState<string>("");
  const [checkoutUrl, setCheckoutUrl] = useState<string>("");
  const purchaseInvoice = useAppSelector(MembershipSelector.getPurchaseInvoice);

  const getStripeMembershipProducts = useCallback(async () => {
    const response = await SubscriptionDataApi.getActiveProducts();
    if (response.success && response.data) {
      setSubscriptionProducts(response.data);
    }
  }, []);

  const getAvailableCoupons = useCallback(async () => {
    const response = await SubscriptionDataApi.getAvailableCoupons();
    if (response.success && response.data) {
      setCoupons(response.data);
    }
  }, []);

  const loadData = useCallback(async () => {
    if (checkoutStep === CheckoutStep.SELECT_MEMBERSHIP) {
      setLoading(true);
      await getStripeMembershipProducts();
      await getAvailableCoupons();
      setLoading(false);
    }
  }, [checkoutStep, getStripeMembershipProducts, getAvailableCoupons]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const selectProduct = useCallback(
    (product: DisplayProduct) => {
      dispatch(MembershipActions.setSelectedDisplayProduct(product));
      dispatch(MembershipActions.setCheckoutStep(CheckoutStep.CUSTOMER_INFO));
    },
    [dispatch]
  );

  const displayProducts = useMemo(() => {
    const currency = CurrencyTools.getCountryCurrency(
      customer.country as Country
    );
    const allProducts = SubscriptionTools.getDisplayProducts(
      subscriptionProducts,
      coupons,
      currency,
      true
    );

    const filteredProducts = allProducts.filter((product) => {
      return product.pricing.some((pricing) => pricing.amount > 0);
    });

    // Add buttons
    if (user.role === UserRole.STANDARD) {
      return filteredProducts.map((product) => {
        return {
          ...product,
          buttonText: t("memberships:buy"),
          onButtonClick: () => selectProduct(product),
        };
      });
    } else {
      return filteredProducts;
    }
  }, [
    activeSubscription,
    customer.country,
    selectProduct,
    coupons,
    subscriptionProducts,
    t,
  ]);

  const setCustomerInfo = async (customer: Customer) => {
    if (selectedDisplayProduct) {
      dispatch(MembershipActions.setCustomerInfo(customer));
      setPreparingPayment(true);
      const response = await SubscriptionManagerApi.createSubscription(
        selectedDisplayProduct,
        customer
      );
      // const response = await SubscriptionManagerApi.createCheckoutSession(
      //   selectedDisplayProduct,
      //   customer
      // );
      if (response.success && response.data) {
        // setCheckoutUrl(response.data.url);
        dispatch(MembershipActions.setPurchaseInvoice(response.data.invoice));

        setClientSecret(response.data.clientSecret);
        dispatch(MembershipActions.setCheckoutStep(CheckoutStep.PAYMENT));
        setPreparingPayment(false);
      } else {
        notification.add(
          NotificationType.ERROR,
          t("memberships:failedToPrepareSubscription")
        );
        setPreparingPayment(false);
      }
    }
  };

  const updateCountry = (country: string) => {
    const currency = CurrencyTools.getCountryCurrency(country as Country);
    // Get product name and interval for selected product
    if (!selectedDisplayProduct) return;
    // const stripeProduct = stripeProducts.find((stripeProduct: StripeProduct) => {
    //   return stripeProduct.id === selectedDisplayProduct.id;
    // });
    // if(!stripeProduct) return
    const title = selectedDisplayProduct.title;
    const interval = selectedDisplayProduct.pricing[0].billingInterval;

    const displayProducts = SubscriptionTools.getDisplayProducts(
      subscriptionProducts,
      coupons,
      currency,
      true
    );

    const newDisplayProduct = displayProducts.find((product) => {
      return (
        product.title === title &&
        product.pricing[0].billingInterval === interval
      );
    });
    if (!newDisplayProduct) return;

    dispatch(
      MembershipActions.setCustomerInfo({
        ...customer,
        country: country,
      })
    );

    dispatch(MembershipActions.setSelectedDisplayProduct(newDisplayProduct));
  };

  const termsAndConditionsUrl = useMemo(() => {
    return KeyPageUtils.getAbsolutePageUrl(11, keyPages, user.language);
  }, [user.language, keyPages]);

  return (
    <>
      {user.role === UserRole.PREMIUM &&
        checkoutStep === CheckoutStep.SELECT_MEMBERSHIP && (
          <div className="mb-xl text-center">
            <p className="py">{t("youAlreadyHaveAMembership")}</p>
          </div>
        )}

      <>
        {user.role === UserRole.STANDARD && (
          <CheckoutStepBar
            step={checkoutStep}
            className="my-xl"
            onClick={(val) => dispatch(MembershipActions.setCheckoutStep(val))}
          />
        )}
        {checkoutStep === CheckoutStep.SELECT_MEMBERSHIP && (
          <div>
            {loading && (
              <div className="row items-center mt">
                <Spinner />
              </div>
            )}
            {!loading && <StripeProductListing products={displayProducts} />}
          </div>
        )}
        {checkoutStep === CheckoutStep.CUSTOMER_INFO &&
          selectedDisplayProduct && (
            <div className="grid columns-3 columns-2--lg columns-1--sm gap-xl gap--lg">
              <div className="colspan-2 colspan-1--lg">
                <CustomerInfo
                  customer={customer}
                  onFinish={setCustomerInfo}
                  loading={preparingPayment}
                  onCountryChange={updateCountry}
                  termsAndConditionsUrl={termsAndConditionsUrl}
                />
              </div>
              <div className="colspan-1">
                <SubscriptionProductCard
                  product={selectedDisplayProduct}
                  hideButton
                  elementId="selected-membership"
                />
              </div>
            </div>
          )}
        {/* {checkoutStep === CheckoutStep.PAYMENT && (
          <div id="payment-step mb-xl text-center">
            <Button
              href={checkoutUrl}
              elementId="checkout-button"
              className="my-xl"
            >
              {t("memberships:openPaymentWindow")}
            </Button>

            <p>{t("memberships:opensInNewWindow")}</p>
          </div>
        )} */}
        {checkoutStep === CheckoutStep.PAYMENT &&
          // purchasedDisplayProduct &&
          purchaseInvoice && (
            <>
              <StripeCheckout
                product={selectedDisplayProduct || undefined}
                invoice={purchaseInvoice}
                clientSecret={clientSecret}
                returnUrl={Api.getHost() + "/app/membership-success"}
                customer={customer}
                user={user}
              />
            </>
          )}
        {checkoutStep === CheckoutStep.CONFIRMATION && (
          <ConfirmationStripeContainer />
        )}
      </>
    </>
  );
};
