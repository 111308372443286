import {
  PlantData,
  PlantEditorModal,
  PlantImage,
  PlantImageGalleryModal,
  PlantsApi,
} from "plants";
import {
  LayoutActions,
  PlanEditorActions,
  PlantManagementActions,
  PlantManagementSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { Pages } from "../../enums";
import { PlantSchema } from "draw";
import { NotificationType, useNotification } from "imagine-ui";
import { useTranslation } from "react-i18next";
import { useMemo, useState } from "react";
import {
  HardinessZoneRHS,
  HardinessZoneUSDA,
  UserHelpers,
  UserPreferences,
} from "project";
import { UserSelector } from "imagine-users";
import { I18nTools } from "imagine-i18n";

export const PlantEditorModalContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);
  const [saving, setSaving] = useState(false);
  const [showPlantImageGallery, setShowPlantImageGallery] = useState(false);
  const [plantImages, setPlantImages] = useState<PlantImage[]>([]);
  const [loadingImages, setLoadingImages] = useState(false);
  const [selectedPlantImage, setSelectedPlantImage] = useState<
    PlantImage | undefined
  >(undefined);

  const focusPlant = useAppSelector(
    PlantManagementSelector.getFocusPlantTemplate
  );
  const copy = useAppSelector(PlantManagementSelector.getCopyPlant);

  const initialPlantData = useMemo(() => {
    if (focusPlant) {
      const plantData = PlantData.getPlantCopy(focusPlant, copy);
      if (copy) {
        plantData.userId = user.id;
        plantData.country = user.country;
        // Only copy hardiness zones if user can see (and edit) the hardiness zones
        plantData.hardinessZoneMin = userPreferences.showHardinessZoneUSDA
          ? focusPlant.hardinessZoneMin
          : HardinessZoneUSDA.NONE;
        plantData.hardinessZoneMax = userPreferences.showHardinessZoneUSDA
          ? focusPlant.hardinessZoneMax
          : HardinessZoneUSDA.NONE;
        plantData.hardinessZoneRhs = userPreferences.showHardinessZoneRHS
          ? focusPlant.hardinessZoneRhs
          : HardinessZoneRHS.NONE;
      }
      return plantData;
    }
    return PlantData.getInitialPlant();
  }, [focusPlant, copy, user.id, user.country]);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const addPlant = async (
    plant: PlantSchema,
    image?: File,
    publicImage?: boolean
  ) => {
    // In case the image was set and then another image was uploaded
    if (image) {
      delete plant.image;
    }
    const plantData = {
      ...plant,
      country: user.country,
    };
    const response = await PlantsApi.addPlant(plantData);
    if (response.success && response.data) {
      if (image) {
        const imageResponse = await PlantsApi.uploadPlantImage(
          response.data.id,
          image,
          publicImage || false
        );
        if (imageResponse.success) {
          close();
        } else {
          if (imageResponse.errorCode === "imageSizeOrFormatNotSupported") {
            notification.add(
              NotificationType.INFO,
              t("plants:imageSizeOrFormatNotSupported")
            );
          } else if (imageResponse.errorCode === "imageUploadFailed") {
            notification.add(
              NotificationType.INFO,
              t("plants:imageUploadFailed")
            );
          } else {
            notification.add(
              NotificationType.ERROR,
              t("plants:errorUploadingPlantImage")
            );
          }
        }
      }
    } else {
      notification.add(NotificationType.ERROR, t("plants:errorCreatingPlant"));
      return;
    }
    close();
  };

  const updatePlant = async (
    plant: PlantSchema,
    image?: File,
    publicImage?: boolean
  ) => {
    const response = await PlantsApi.updatePlant(plant);
    if (response.success && response.data) {
      if (image) {
        const imageResponse = await PlantsApi.uploadPlantImage(
          response.data.id,
          image,
          publicImage || false
        );
        if (imageResponse.success && imageResponse.data) {
          dispatch(PlanEditorActions.addPlantTemplate(imageResponse.data));
          dispatch(PlantManagementActions.updatePlant(imageResponse.data));
          close();
        } else {
          dispatch(PlanEditorActions.addPlantTemplate(response.data));
          dispatch(PlantManagementActions.updatePlant(response.data));
          if (imageResponse.errorCode === "imageSizeOrFormatNotSupported") {
            notification.add(
              NotificationType.INFO,
              t("plants:imageSizeOrFormatNotSupported")
            );
          } else if (imageResponse.errorCode === "imageUploadFailed") {
            notification.add(
              NotificationType.INFO,
              t("plants:imageUploadFailed")
            );
          } else {
            notification.add(
              NotificationType.ERROR,
              t("plants:errorUploadingPlantImage")
            );
          }
        }
      } else {
        dispatch(PlanEditorActions.addPlantTemplate(response.data));
        dispatch(PlantManagementActions.updatePlant(response.data));
      }
    } else {
      notification.add(NotificationType.ERROR, t("plants:errorUpdatingPlant"));
      return;
    }
    close();
  };

  const savePlant = async (
    plant: PlantSchema,
    image?: File,
    publicImage?: boolean
  ) => {
    setSaving(true);
    if (focusPlant && !copy) {
      await updatePlant(plant, image, publicImage);
    } else {
      await addPlant(plant, image, publicImage);
    }
    setSaving(false);
  };

  const loadPlantImages = async (latinName: string) => {
    setLoadingImages(true);
    const response = await PlantsApi.getPlantImages(latinName);
    if (response.success && response.data) {
      setPlantImages(response.data);
    } else {
      setPlantImages([]);
    }
    setLoadingImages(false);
  };

  const openImageGallery = (latinName: string) => {
    setShowPlantImageGallery(true);
    loadPlantImages(latinName);
  };

  const selectPlantImage = (image) => {
    setSelectedPlantImage(image);
    setShowPlantImageGallery(false);
  };

  return (
    <>
      <PlantEditorModal
        plant={initialPlantData}
        onClose={close}
        onSavePlant={savePlant}
        loading={saving}
        editMode={focusPlant !== null && !copy}
        copyMode={copy}
        onOpenImageGallery={openImageGallery}
        plantImage={selectedPlantImage}
        delimiter={delimiter}
        unitScale={userPreferences.unitScale}
        showHardinessZoneUSDA={userPreferences.showHardinessZoneUSDA}
        showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
        enableBetaFeatures={userPreferences.enableBetaFeatures}
      />
      <PlantImageGalleryModal
        visible={showPlantImageGallery}
        onClosed={() => setShowPlantImageGallery(false)}
        onSelected={selectPlantImage}
        images={plantImages}
        loading={loadingImages}
      />
    </>
  );
};
