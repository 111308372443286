import { PlantCategory } from "draw";
import { PlantSearchFilter } from "..";

const adjustPlantSearchFilter = (
  filter: PlantSearchFilter
): PlantSearchFilter => {
  switch (filter.name) {
    case "bunddække":
      return {
        ...filter,
        name: undefined,
        properties: { ...filter.properties, groundcover: true },
      };
    case "roser":
      return { ...filter, name: undefined, category: [PlantCategory.ROSES] };
    case "græsser":
      return { ...filter, name: undefined, category: [PlantCategory.GRASS] };
    case "groundcover":
    case "ground cover":
      return {
        ...filter,
        name: undefined,
        properties: { ...filter.properties, groundcover: true },
      };
    case "roses":
      return { ...filter, name: undefined, category: [PlantCategory.ROSES] };
    case "grasses":
    case "grass":
      return { ...filter, name: undefined, category: [PlantCategory.GRASS] };
    default:
      return filter;
  }
};

export const PlantSearchHelper = {
  adjustPlantSearchFilter,
};
