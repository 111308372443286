import { useTranslation } from "react-i18next";
import {
  CanvasActions,
  LayoutActions,
  PlanEditorActions,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import {
  CanvasSelector,
  ObjectEditorSelector,
  PlanEditorSelector,
} from "../../store";
import { CursorMode, MapMode, Pages } from "../../enums";
import { ToolButton, ToolSeparator } from "../../components";
import { useMemo } from "react";
import { Device, useNotification } from "imagine-ui";
import { UserSelector } from "imagine-users";
import { MonthSlider } from "draw";

/**
 * Displays the tool and actions in the present toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const PresentToolbarContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const previewPlan = useAppSelector(PlanEditorSelector.getPreviewPlan);
  const month = useAppSelector(PlanEditorSelector.getPreviewMonth);

  /**
   * Deselect any selected items if needed and toggle the state.
   */
  const togglePlanViewingMode = () => {
    dispatch(PlanEditorActions.setPreviewPlan(!previewPlan));
  };

  const setMonth = (month: number) => {
    dispatch(PlanEditorActions.setPreviewMonth(month));
  };

  return (
    <div id="area-toolbar" className="toolbar-row">
      <ToolButton
        elementId="present-plan-nav-button"
        text={t("presentPlan")}
        icon="preview-plant"
        onClick={togglePlanViewingMode}
        selected={previewPlan}
      />
      <ToolSeparator />
      <div className="w-full bg-secondary">
        <MonthSlider
          month={month}
          onChanged={setMonth}
          disabled={!previewPlan}
        ></MonthSlider>
      </div>
    </div>
  );
};
