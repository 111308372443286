import { Delimiter, Option } from "imagine-essentials";
import { Device, Link, Select, Spinner, TextInput } from "imagine-ui";
import { PlantCard, PlantData } from "plants";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { PlantModelOperations, PlantSchema, ModelPicker } from "draw";
import { UnitScale } from "project";

interface Props {
  onSearch: (name: string) => void;
  plants: PlantSchema[];
  onAdvancedSearch: () => void;
  plantCollectionOptions: Option[];
  onSelectPlantCollection: (id: number, searchName: string) => void;
  onSelectPlantTemplate: (id?: number) => void;
  selectedPlantCollectionId?: number;
  loading?: boolean;
  selectedPlantTemplateId?: number;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneRHS?: boolean;
}

export const PlantLibrary = (props: Props) => {
  const { t } = useTranslation();
  const [name, setName] = useState("");
  const [markedTemplate, setMarkedTemplate] = useState<PlantSchema | null>(
    null
  );

  const isTouchDevice = Device.isTouchDevice();

  const handleNewName = (val: string) => {
    setName(val);
  };

  const handleNewNameAndSearch = (val: string) => {
    setName(val);
    props.onSearch(val);
  };

  const search = () => {
    props.onSearch(name);
  };

  const designs = useMemo(() => {
    return PlantModelOperations.getTemplateDesigns(props.plants);
  }, [props.plants]);

  const previewPlant = (id: number | null) => {
    if (id === null) {
      setMarkedTemplate(null);
      return;
    }
    const plant = props.plants.find((template: PlantSchema) => {
      return template.id === id;
    });
    if (plant !== undefined) {
      setMarkedTemplate(plant);
    }
  };

  const selectPlantTemplate = (id?: number) => {
    props.onSelectPlantTemplate(id);
  };

  return (
    <div className="position-relative column h-full scroll-y-always--sm">
      <div className="p">
        <TextInput
          elementId="plant-library-search"
          value={name}
          onFinish={handleNewName}
          onEnterPress={handleNewNameAndSearch}
          onClick={search}
          prependIcon="magnifier"
          size="sm"
        />
        <div className="row items-right">
          <Link className="text-sm mt-xs" onClick={props.onAdvancedSearch}>
            {t("plants:advancedSearch")}
          </Link>
        </div>
        {props.plantCollectionOptions.length > 0 && (
          <div className="mt">
            <Select
              elementId="plant-library-collection"
              className="select-form-control"
              size="sm"
              options={props.plantCollectionOptions}
              onSelectedNumber={(val: number) =>
                props.onSelectPlantCollection(val, name)
              }
              selected={props.selectedPlantCollectionId}
            />
          </div>
        )}
        {props.loading && (
          <div className="text-center mt">
            <Spinner />
          </div>
        )}
      </div>
      <div className="stretch scroll-y-always overflow-y--sm px">
        {!props.loading && (
          <ModelPicker
            designs={designs}
            selected={props.selectedPlantTemplateId}
            onSelect={selectPlantTemplate}
            onPreview={previewPlant}
            // reload={reloadPlantDisplay}
            // disabled={props.disabled}
            displayNames
            idPrefix="plant-library-design"
            // onDragOutStarted={props.onItemGrabbed}
          />
        )}
      </div>

      {markedTemplate !== null && !isTouchDevice && (
        <div className="item-preview-container shadow">
          <div className="item-preview">
            <PlantCard
              plant={markedTemplate}
              showProperties={true}
              showHeight={true}
              showSeason={true}
              unitScale={props.unitScale}
              delimiter={props.delimiter}
              showHardinessZoneRHS={props.showHardinessZoneRHS}
            ></PlantCard>
          </div>
        </div>
      )}
    </div>
  );
};
