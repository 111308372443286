/* eslint-disable @typescript-eslint/no-explicit-any */
import { Point, SentryReporter } from "imagine-essentials";

const isLeftClick = (event: any) => {
  // Touches always count as left clicks
  if (event.touches !== undefined) return true;
  return event.buttons === 1 && event.button === 0;
};

const getEventPosition = (event: any, touchIndex: number = 0) => {
  const point: Point = { x: 0, y: 0 };
  if (
    event.type === "touchstart" ||
    event.type === "touchmove" ||
    event.type === "touchcancel" ||
    event.type === "touchend"
  ) {
    if (getTouchCount(event) > touchIndex) {
      if (event.touches !== undefined) {
        return {
          x: event.touches[touchIndex].clientX,
          y: event.touches[touchIndex].clientY,
        } as Point;
      }
      return {
        x: event.nativeEvent.touches[touchIndex].clientX,
        y: event.nativeEvent.touches[touchIndex].clientY,
      } as Point;
    } else {
      // console.error("No touches in touch event:", event);
      return point;
    }
  }
  if (
    event.type === "mousedown" ||
    event.type === "mouseup" ||
    event.type === "mousemove" ||
    event.type === "mouseenter" ||
    event.type === "mouseleave" ||
    event.type === "click" ||
    event.type === "wheel" ||
    event.type === "contextmenu" ||
    event.type === "dblclick"
  ) {
    return { x: event.clientX, y: event.clientY } as Point;
  } else {
    SentryReporter.captureException(
      "Cannot calculate position for undefined event",
      { Event: JSON.stringify(event) }
    );
    console.error("Undefined event:", event.type, event);
    return point;
  }
};

const isTouchEvent = (event: any) => {
  return (
    event.type === "touchstart" ||
    event.type === "touchmove" ||
    event.type === "touchcancel"
  );
};

const getTouchCount = (event: any) => {
  if (event.touches !== undefined) {
    return event.touches.length;
  }
  if (event.nativeEvent !== undefined) {
    if (event.nativeEvent.touches !== undefined) {
      return event.nativeEvent.touches.length;
    }
  }
  return 0;
};

/**
 * Get class name of the event's target.
 * @param event
 * @returns
 */
const getClass = (event: any) => {
  if (event === undefined || event === null) return "";
  if (event.target === undefined || event.target === null) return "";
  if (event.target.className === undefined || event.target.className === null)
    return "";
  if (typeof event.target.className === "string") {
    return event.target.className;
  }
  if (event.target.className.baseVal === undefined) return "";
  return event.target.className.baseVal;
};

const getTouchEventPositions = (event: any) => {
  const positions: Point[] = [];
  if (event.touches !== undefined && event.touches?.length > 0) {
    for (let i = 0; i < event.touches.length; i++) {
      positions.push({
        x: event.touches[i].clientX,
        y: event.touches[i].clientY,
      });
    }
  } else if (
    event.targetTouches !== undefined &&
    event.targetTouches?.length > 0
  ) {
    for (let i = 0; i < event.targetTouches.length; i++) {
      positions.push({
        x: event.targetTouches[i].clientX,
        y: event.targetTouches[i].clientY,
      });
    }
  } else if (
    event.changedTouches !== undefined &&
    event.changedTouches?.length > 0
  ) {
    for (let i = 0; i < event.changedTouches.length; i++) {
      positions.push({
        x: event.changedTouches[i].clientX,
        y: event.changedTouches[i].clientY,
      });
    }
  }
  return positions;
};

const isButton = (element: any, levels = 3): boolean => {
  if (!element) return false;
  if (element.type === "button") return true;
  if (levels < 1) return false;
  if (element.parentElement !== undefined) {
    return isButton(element.parentElement, levels - 1);
  }
  if (element.parentNode !== undefined) {
    return isButton(element.parentNode, levels - 1);
  }
  return false;
};

const isInputField = (event: any): boolean => {
  const element = event.target;
  if (!element) return false;
  if (element.tagName) {
    if (element.tagName === "INPUT" || element.tagName === "TEXTAREA")
      return true;
  }
  if (element.nodeName) {
    if (element.nodeName === "INPUT" || element.nodeName === "TEXTAREA")
      return true;
  }

  return false;
};

const isCtrlPressed = (event: any): boolean => {
  return event.ctrlKey || event.metaKey;
};

export const EventProcess = {
  isLeftClick,
  getEventPosition,
  isTouchEvent,
  getTouchCount,
  getClass,
  getTouchEventPositions,
  isButton,
  isInputField,
  isCtrlPressed,
};
