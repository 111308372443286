export enum PlantCategory {
  PERENNIAL,
  GRASS,
  CLIMBER,
  SHRUB,
  TREE,
  VEGETABLE,
  BULB,
  ANNUALS,
  ROSES,
  _SIZE,
}
