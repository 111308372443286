import {
  Group,
  Item,
  ItemType,
  Layer,
  ObjectSchema,
  PlanSettings,
  PlantSchema,
} from "draw";
import { RootState } from "..";
import { createSelector } from "@reduxjs/toolkit";

export const PlanEditorSelector = {
  getPlanName: (state: RootState) => {
    return state.planEditor.planInfo.name;
  },
  getPlanId: (state: RootState) => {
    return state.planEditor.planInfo.id;
  },
  getPlanUserId: (state: RootState) => {
    return state.planEditor.planInfo.userId;
  },
  getPlanInfo: (state: RootState) => {
    return state.planEditor.planInfo;
  },
  getReferenceImage: (state: RootState) => {
    return state.planEditor.referenceImage;
  },
  isUndoAllowed: (state: RootState) => {
    return state.planEditor.actionHistory.length > 0;
  },
  isRedoAllowed: (state: RootState) => {
    return state.planEditor.actionUndoneHistory.length > 0;
  },
  /**
   * Return a list of items that is currently visible based on the visibility of each item's layer and group.
   * @param state
   */
  getVisibleItems: createSelector(
    (state: RootState) => state.planEditor.items,
    (state: RootState) => state.planEditor.layers,
    (state: RootState) => state.planEditor.groups,
    (items, layers, groups) => {
      const visibleLayerIds = layers
        .filter((layer: Layer) => layer.visible)
        .map((layer: Layer) => layer.id);
      const visibleGroupIds = groups
        .filter((group: Group) => group.visible)
        .map((group: Group) => group.id);

      return items.filter((item: Item) => {
        const layer = item.layerId;
        const group = item.groupId;
        return (
          (layer === undefined || visibleLayerIds.includes(layer)) &&
          (group === undefined || visibleGroupIds.includes(group))
        );
      });
    }
  ),
  getAllItems: (state: RootState) => {
    return state.planEditor.items;
  },
  getSelectedItems: (state: RootState) => {
    return state.planEditor.selectedItems;
  },
  isOnlyOneShapeItemSelected: (state: RootState) => {
    if (state.planEditor.selectedItems.length === 1) {
      return state.planEditor.selectedItems[0].type === ItemType.SHAPE;
    }
    return false;
  },
  isOnlyShapeItemsSelected: (state: RootState) => {
    if (state.planEditor.selectedItems.length > 0) {
      const nonShapeItem = state.planEditor.selectedItems.find(
        (item: Item) => item.type !== ItemType.SHAPE
      );
      return nonShapeItem === undefined;
    }
    return false;
  },
  isOnlyOnePlantItemSelected: (state: RootState) => {
    if (state.planEditor.selectedItems.length === 1) {
      return state.planEditor.selectedItems[0].type === ItemType.PLANT;
    }
    return false;
  },
  isOnlyOneObjectItemSelected: (state: RootState) => {
    if (state.planEditor.selectedItems.length === 1) {
      return state.planEditor.selectedItems[0].type === ItemType.OBJECT;
    }
    return false;
  },
  getSelectedItemIds: createSelector(
    (state: RootState) => state.planEditor.selectedItems,
    (selectedItems) => {
      return selectedItems.map((item) => item.id);
    }
  ),
  getSelectedPlants: createSelector(
    (state: RootState) => state.planEditor.selectedItems,
    (selectedItems) => {
      return selectedItems.filter((item: Item) => {
        return item.type === ItemType.PLANT;
      });
    }
  ),
  getSelectedObjects: createSelector(
    (state: RootState) => state.planEditor.selectedItems,
    (selectedItems) => {
      return selectedItems.filter((item: Item) => {
        return item.type === ItemType.OBJECT;
      });
    }
  ),
  getLayers: (state: RootState) => {
    return state.planEditor.layers;
  },
  getCurrentLayerId: (state: RootState) => {
    return state.planEditor.currentLayerId;
  },
  getGroups: (state: RootState) => {
    return state.planEditor.groups;
  },
  hasUnsavedChanges: (state: RootState) => {
    return state.planEditor.unsavedChanges;
  },
  getPlantTemplates: (state: RootState) => {
    return state.planEditor.plantTemplates;
  },
  getObjectTemplates: (state: RootState) => {
    return state.planEditor.objectTemplates;
  },
  getArea: (state: RootState) => {
    return state.planEditor.area;
  },
  getCopiedItems: (state: RootState) => {
    return state.planEditor.copiedItems;
  },
  getPreviewPlan: (state: RootState) => {
    return state.planEditor.previewPlan;
  },
  getPreviewPlanMonth: (state: RootState) => {
    return state.planEditor.previewPlan ? state.planEditor.previewMonth : 0;
  },
  getPreviewMonth: (state: RootState) => {
    return state.planEditor.previewMonth;
  },
  isAddingItem: (state: RootState) => {
    return state.planEditor.addingItem;
  },
  isEditingArea: (state: RootState) => {
    return state.planEditor.editingArea;
  },
  isEditingReferenceImage: (state: RootState) => {
    return state.planEditor.editingReferenceImage;
  },
  getStampType: (state: RootState) => {
    return state.planEditor.stampType;
  },
  getStampTemplate: (state: RootState) => {
    return state.planEditor.stampTemplate;
  },
  getPlantStampTemplate: (state: RootState) => {
    if (state.planEditor.stampType === ItemType.PLANT) {
      return state.planEditor.stampTemplate as PlantSchema;
    }
    return null;
  },
  getObjectStampTemplate: (state: RootState) => {
    if (state.planEditor.stampType === ItemType.OBJECT) {
      return state.planEditor.stampTemplate as ObjectSchema;
    }
    return null;
  },
  getObjectSearchResult: (state: RootState) => {
    return state.planEditor.objectSearchResult;
  },
  getPlanSettings: createSelector(
    (state: RootState) => state.planEditor.currentLayerId,
    (currentLayerId) => {
      return {
        currentLayerId: currentLayerId,
      } as Partial<PlanSettings>;
    }
  ),
  getPlanRequestStatus: (state: RootState) => {
    return state.planEditor.planRequestStatus;
  },
};
