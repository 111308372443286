import { useTranslation } from "react-i18next";
import { BonusDays } from "..";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";

type Props = {
  bonus: BonusDays;
  className?: string;
};

/**
 * The page displays all payments made and lets the user download an invoice for each.
 */
export const BonusDaysInfoCard = (props: Props) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={"card " + props.className}>
        <div className="grid columns-12 columns-1--lg">
          <div className="colspan-3 colspan-1--lg">
            <span className="fw-bold">
              {t("memberships:dayCount", { count: props.bonus.days })}
            </span>
          </div>
          <div className="colspan-4 colspan-1--lg">
            <span>
              {props.bonus.titleKey !== ""
                ? t(props.bonus.titleKey)
                : props.bonus.note}
            </span>
          </div>
          <div className="colspan-5 colspan-1--lg ">
            <span className="text-dark-muted text-italic text-sm ">
              {t("memberships:period")}:&nbsp;
              {DateTimeText.getDbDateTimeText(
                props.bonus.startDate,
                DateTimeFormat.LONG_DATE
              )}
              &nbsp;-&nbsp;
              {DateTimeText.getDbDateTimeText(
                props.bonus.expiryDate,
                DateTimeFormat.LONG_DATE
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};
