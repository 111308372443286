import { useTranslation } from "react-i18next";
import { PlantText } from "..";
import { DateTimeText } from "imagine-datetime";
import { Api, Delimiter } from "imagine-essentials";
import { ColorUtils, Measure, PlantModelText, PlantSchema } from "draw";
import { UnitScale } from "project";

interface Props {
  plant: PlantSchema;
  showOwner?: boolean;
  currentUserId?: number;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneUSDA?: boolean;
  showHardinessZoneRHS?: boolean;
}

export const PlantInfo = (props: Props) => {
  const { t } = useTranslation();

  const getSpeciesText = () => {
    return PlantText.getLatinAndHybridName(props.plant);
  };

  const getFloweringMonths = () => {
    return DateTimeText.getMonthListText(props.plant.flowerSeason);
  };

  const getFloweringColor = () => {
    if (
      props.plant.flowerSeason.length === 0 ||
      props.plant.flowerColorGroup === undefined
    ) {
      return t("imagine:none");
    }
    return ColorUtils.getColorGroupText(props.plant.flowerColorGroup);
  };

  return (
    <>
      <div className="grid columns-3 columns-1--sm gap">
        <div className="colspan-2 colspan-1--sm">
          <h3 className="mb-sm">{props.plant.name || ""}</h3>
          <p className="text-lg text-italic">{getSpeciesText()}</p>
          {props.showOwner && props.plant.userEmail !== undefined && (
            <p className="text-dark-muted text-italic">
              {t("imagine:createdBy")} {props.plant.userEmail}
            </p>
          )}
          <p className="mt">
            <span className="text-bold">{t("imagine:category")}: </span>
            {PlantModelText.getCategoryText(props.plant.category)}
          </p>

          <p className="mt">
            <span className="text-bold">{t("imagine:width")}: </span>
            {Measure.getMeasureText(
              props.plant.width,
              props.unitScale,
              props.delimiter,
              true,
              true
            )}
          </p>
          <p className="mb">
            <span className="text-bold">{t("imagine:height")}: </span>
            {Measure.getMeasureTextInterval(
              props.plant.heightMin || 0,
              props.plant.heightMax || 0,
              props.unitScale,
              props.delimiter,
              true,
              true
            )}
          </p>
          <p className="mb">
            <span className="text-bold">{t("plants:flowerSeason")}: </span>
            {getFloweringMonths()}
          </p>
          <p className="mb">
            <span className="text-bold">{t("plants:flowerColor")}: </span>
            {getFloweringColor()}
          </p>

          {props.showHardinessZoneUSDA && (
            <p className="pb">
              <span className="text-bold">
                {t("plants:hardinessZoneUSDA")}:{" "}
              </span>
              {PlantText.getPlantHardinessZoneInterval(props.plant)}
            </p>
          )}
          {props.showHardinessZoneRHS && (
            <p className="pb">
              <span className="text-bold">
                {t("plants:hardinessZoneRHS")}:{" "}
              </span>
              {PlantText.getPlantHardinessZoneRHS(props.plant)}
            </p>
          )}
        </div>
        <div className="">
          {props.plant.image && (
            <>
              <img
                src={Api.getHost() + "/plant-images/" + props.plant.image}
                className="fluid"
                alt={props.plant.name}
              />
              {props.showOwner && (
                <>
                  {props.plant.imageUserId === props.currentUserId && (
                    <span className="badge bg-primary me-1">
                      {t("plants:uploadedByYou")}
                    </span>
                  )}
                  {props.plant.imagePublic && (
                    <span className="badge bg-secondary me-1">
                      {t("plants:publicImage")}
                    </span>
                  )}
                  {!props.plant.imagePublic && (
                    <span className="badge bg-danger">
                      {t("plants:privateImage")}
                    </span>
                  )}
                </>
              )}
            </>
          )}
        </div>
      </div>

      <p className="text-bold">{t("imagine:note")}:</p>
      <p className="mb-lg">{props.plant.note}</p>

      <h5 className="mt-xl mb">{t("plants:plantProperties")}</h5>

      <div className="grid columns-3 columns-1--sm">
        <p className="text-bold mb">{t("plants:lightConditions")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.sun && <p>{t("plants:sun")}</p>}
          {props.plant.properties?.semiShade && <p>{t("plants:semiShade")}</p>}
          {props.plant.properties?.shade && <p>{t("plants:shade")}</p>}
        </div>

        <p className="text-bold mb">{t("plants:soilTypes")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.loamSoil && (
            <p className="no-margin">{t("plants:loamySoil")}</p>
          )}
          {props.plant.properties?.sandySoil && (
            <p className="no-margin">{t("plants:sandySoil")}</p>
          )}
          {props.plant.properties?.claySoil && (
            <p className="no-margin">{t("plants:claySoil")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:waterConditions")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.drainedSoil && (
            <p className="no-margin">{t("plants:wellDrainedSoil")}</p>
          )}
          {props.plant.properties?.drySoil && (
            <p className="no-margin">{t("plants:drySoil")}</p>
          )}
          {props.plant.properties?.wetSoil && (
            <p className="no-margin">{t("plants:wetSoil")}</p>
          )}
          {props.plant.properties?.waterPlant && (
            <p className="no-margin">{t("plants:waterPlant")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:phConditions")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.neutralSoil && (
            <p className="no-margin">{t("plants:neutralSoil")}</p>
          )}
          {props.plant.properties?.acidSoil && (
            <p className="no-margin">{t("plants:acidSoil")}</p>
          )}
          {props.plant.properties?.alkalicSoil && (
            <p className="no-margin">{t("plants:alkalicSoil")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:plantUsage")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.groundcover && (
            <p className="no-margin">{t("plants:groundcover")}</p>
          )}
          {props.plant.properties?.edible && (
            <p className="no-margin">{t("plants:edibleFruitsBerryEtc")}</p>
          )}
          {props.plant.properties?.cutFlowers && (
            <p className="no-margin">{t("plants:cutFlower")}</p>
          )}
          {props.plant.properties?.pots && (
            <p className="no-margin">{t("plants:pots")}</p>
          )}
          {props.plant.properties?.hedge && (
            <p className="no-margin">{t("plants:hedge")}</p>
          )}
          {props.plant.properties?.coast && (
            <p className="no-margin">{t("plants:coastalArea")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:contributions")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.bees && (
            <p className="no-margin">{t("plants:attractsBees")}</p>
          )}
          {props.plant.properties?.birds && (
            <p className="no-margin">{t("plants:goodForBirds")}</p>
          )}

          {props.plant.properties?.nitrogen && (
            <p className="no-margin">{t("plants:nitrogenFixating")}</p>
          )}
          {props.plant.properties?.carbon && (
            <p className="no-margin">{t("plants:carbonFarming")}</p>
          )}
          {props.plant.properties?.native && (
            <p className="no-margin">{t("plants:native")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:plantQualities")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.evergreen && (
            <p className="no-margin">{t("plants:evergreen")}</p>
          )}
          {props.plant.properties?.winter && (
            <p className="no-margin">{t("plants:winterInterest")}</p>
          )}
          {props.plant.properties?.fallColors && (
            <p className="no-margin">{t("plants:interestingFallColors")}</p>
          )}

          {props.plant.properties?.fragrant && (
            <p className="no-margin">{t("plants:fragrant")}</p>
          )}
          {props.plant.properties?.allergyFriendly && (
            <p className="no-margin">{t("plants:allergyFriendly")}</p>
          )}
          {props.plant.properties?.wind && (
            <p className="no-margin">{t("plants:windTolerant")}</p>
          )}
        </div>

        <p className="text-bold mb">{t("plants:beware")}:</p>

        <div className="colspan-2 colspan-1--sm mb">
          {props.plant.properties?.poisonous && (
            <p className="no-margin">{t("plants:poisonous")}</p>
          )}
          {props.plant.properties?.thorns && (
            <p className="no-margin">{t("plants:thorns")}</p>
          )}
          {props.plant.properties?.rootSpreading && (
            <p className="no-margin">{t("plants:speadingByRoots")}</p>
          )}
          {props.plant.properties?.seedSpreading && (
            <p className="no-margin">{t("plants:speadingBySeeds")}</p>
          )}
          {props.plant.properties?.specialCare && (
            <p className="no-margin">{t("plants:specialCare")}</p>
          )}
        </div>
      </div>
    </>
  );
};
