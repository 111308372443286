import { Area, Item, ItemType, ShapeValidation } from "..";

const itemToArea = (item: Item) => {
  if (item.shapeProperties === undefined) {
    console.error("Item:", item);
    throw new Error(
      "Item cannot be converted to area: missing shape properties"
    );
  }
  // Objects are copied, otherwise they will reference the same object
  return {
    shape: { ...item.shapeProperties },
    size: { ...item.size },
    position: { ...item.position },
    rotation: item.rotation,
  } as Area;
};

const areaToItem = (area: Area) => {
  // Objects are copied, otherwise they will reference the same object
  return {
    id: 100,
    index: 0,
    type: ItemType.SHAPE,
    shapeProperties: { ...area.shape },
    size: { ...area.size },
    position: { ...area.position },
    rotation: area.rotation ?? 0,
    viewBox: "0 0 " + area.size.width + " " + area.size.height,
  } as Item;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isArea = (data: any) => {
  if (data.shape === undefined) return false;
  if (data.size === undefined) return false;
  if (data.position === undefined) return false;
  return ShapeValidation.isShape(data.shape);
};

export const AreaOperations = {
  itemToArea,
  areaToItem,
  isArea,
};
