import {
  LayoutActions,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { TextShapeModal } from "../../components";
import { ItemType, ShapeType, TextProperties } from "draw";
import { useMemo } from "react";
import _ from "lodash";
import { Pages } from "../../enums";

export const TextShapeModalContainer = () => {
  const selectedItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const dispatch = useAppDispatch();

  const selectedTextShapeItem = useMemo(() => {
    if (selectedItems.length === 0) {
      return undefined;
    }
    if (selectedItems.length > 1) {
      return undefined;
    }
    return selectedItems.find((item) => {
      if (item.type === ItemType.SHAPE) {
        const shapeProperties = item.shapeProperties;
        if (shapeProperties?.type === ShapeType.TEXT) {
          return true;
        }
      }
      return false;
    });
  }, [selectedItems]);

  const updateText = (text: string) => {
    if (selectedTextShapeItem) {
      const item = _.cloneDeep(selectedTextShapeItem);
      item.shapeProperties.properties = {
        ...item.shapeProperties.properties,
        text: text,
      };
      dispatch(PlanEditorActions.updateItem(item));
    }
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      {selectedTextShapeItem && (
        <TextShapeModal
          text={
            (
              selectedTextShapeItem.shapeProperties
                ?.properties as TextProperties
            ).text || ""
          }
          onFinish={updateText}
          onClose={close}
        />
      )}
    </>
  );
};
