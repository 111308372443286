import { useState, useEffect, useMemo, useCallback } from "react";
import {
  CanvasSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { Point, Rectangle, Size, Trig } from "imagine-essentials";
import {
  CanvasOperations,
  Item,
  ItemOperations,
  ItemType,
  Model,
  ModelOperations,
  ModelSvg,
  ObjectSchema,
  PlantModelOperations,
  PlantSchema,
  Mouse,
  MouseState,
} from "draw";
import { Device } from "imagine-ui";
import { UnitScale } from "project";

type Props = {
  template: ObjectSchema | PlantSchema;
  type: ItemType;
  zoom: number;
  zeroReference: Point;
  canvasOffset: Point;
  viewport: Rectangle; // The viewport where items can be inserted (in global coordinates)
  onInsertItem: (position: Point) => void;
  onExit: () => void;
};

/**
 * Shows a plant or object items that is not added yet. Will be added when clicked.
 */
export const ItemStamp = (props: Props) => {
  const { onExit, onInsertItem } = props;

  const isTouchDevice = Device.isTouchDevice();

  // Used to make sure items are not added if board has been dragged
  const [pressPosition, setPressPosition] = useState<Point | null>(null);

  const [mousePosition, setMousePosition] = useState<Point>({ x: 0, y: 0 });

  const getItemModel = useCallback(
    (position: Point) => {
      if (props.type === undefined) {
        return null;
      }
      // Don't show item if position is outside of viewport
      if (!CanvasOperations.isPointWithinRectangle(position, props.viewport))
        return null;

      let templateModel: Model | null = null;
      if (props.type === ItemType.PLANT && props.template !== null) {
        const size = {
          width: props.template.width,
          height: props.template.width,
        };
        const pxSize = CanvasOperations.unitToPixelSize(size, props.zoom);
        templateModel = PlantModelOperations.createModel(
          props.template as PlantSchema,
          props.zoom,
          props.zeroReference,
          0,
          pxSize
        );
      }
      if (props.type === ItemType.OBJECT && props.template !== null) {
        const size = {
          width: props.template.width,
          height: (props.template as ObjectSchema).height,
        };
        templateModel = {
          id: props.template.id,
          itemType: ItemType.OBJECT,
          size: CanvasOperations.unitToPixelSize(size, props.zoom),
          rotation: 0,
          position: { x: 0, y: 0 },
          shapes: ModelOperations.getObjectTemplateShapes(
            props.template as ObjectSchema
          ),
          viewBox:
            "0 0 " +
            props.template.width +
            " " +
            (props.template as ObjectSchema).height,
        };
      }

      // Set position
      if (templateModel !== null) {
        const halfSizePoint = CanvasOperations.getPointMultiplied(
          Trig.sizeToPoint(templateModel.size),
          { x: 0.5, y: 0.5 }
        );

        const boardPosition = CanvasOperations.getPointSubtracted(
          position,
          props.canvasOffset
        );

        return {
          ...templateModel,
          position: CanvasOperations.getPointSubtracted(
            boardPosition,
            halfSizePoint
          ),
        };
      }
      return templateModel;
    },
    [
      props.canvasOffset,
      props.template,
      props.type,
      props.viewport,
      props.zeroReference,
      props.zoom,
    ]
  );

  const itemModel = useMemo(() => {
    return getItemModel(mousePosition);
  }, [getItemModel, mousePosition]);

  const addItem = useCallback(
    (position: Point) => {
      // Need to calculate the item model again for touch device support
      const newItemModel = getItemModel(position);
      if (newItemModel) {
        onInsertItem(newItemModel?.position);
      }
    },
    [getItemModel, onInsertItem]
  );

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (!state.pressed) {
        setMousePosition(state.position);
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      if (state.rightPressed) {
        onExit();
      } else {
        if (
          CanvasOperations.isPointWithinRectangle(
            state.position,
            props.viewport
          )
        ) {
          setPressPosition(state.position);
          addItem(state.position);
        }
      }
    });
    // const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
    //   if (pressPosition === null) return;
    //   const movedDistance = CanvasOperations.calculateDistance(
    //     pressPosition,
    //     state.position
    //   );
    //   if (movedDistance > 5) return;
    //   setMousePosition(state.position);
    //   addItem(state.position);
    // });

    return () => {
      moveObserver.unsubscribe();
      // releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [addItem, onExit, props.viewport]);

  return (
    <>
      {itemModel !== null && !isTouchDevice && (
        <ModelSvg
          model={itemModel}
          className="stamp-item"
          inactive
          unitScale={UnitScale.METRIC}
          delimiter="."
        />
      )}
    </>
  );
};
