import i18next from "i18next";
import {
  CanvasOperations,
  DbObject,
  Item,
  ItemType,
  ObjectCategory,
  ObjectItem,
  ObjectSchema,
  ShapeOperations,
} from "..";

import {
  SentryReporter,
  Point,
  Api,
  Rectangle,
  Tools,
  Option,
} from "imagine-essentials";

const convertToObjectItem = (item: Item) => {
  return {
    id: item.id,
    index: item.index,
    shapeProperties: item.shapeProperties,
    size: item.size,
    rotation: item.rotation,
    position: item.position,
    viewBox: item.viewBox,
  } as ObjectItem;
};

const convertToItem = (oItem: ObjectItem) => {
  return {
    id: oItem.id,
    index: oItem.index,
    type: ItemType.OBJECT,
    shapeProperties: oItem.shapeProperties,
    size: oItem.size,
    rotation: oItem.rotation,
    position: oItem.position,
    viewBox: oItem.viewBox,
  } as Item;
};

const convertToObjectItems = (items: Item[]) => {
  items.map((item: Item) => {
    return convertToObjectItem(item);
  });
};

const convertToItems = (oItems: ObjectItem[]) => {
  oItems.map((oItem) => {
    return convertToItem(oItem);
  });
};

const getFallbackObjectTemplate = (id: number) => {
  return {
    id: id,
    name: "",
    category: ObjectCategory.ACCESSORIES,
    width: 0,
    height: 0,
    items: [],
  } as ObjectSchema;
};

/**
 * Finds an object based on it's ID in an array of objects. The object must exist in the array, otherwise
 * a fallback object is returned (and Sentry notified - this should not happen)
 */
const findObject = (objects: ObjectSchema[], objectId: number | undefined) => {
  if (objectId === undefined) throw new Error("Object ID is undefined");
  const o = objects.find((object: ObjectSchema) => {
    return object.id === objectId;
  });
  if (o !== undefined) return o;
  else {
    console.error("Looking for", objectId);
    console.error("Object list:", objects);
    SentryReporter.captureException(
      "Object ID does not exist in list of objects",
      { "Object ID": objectId, "Object templates": JSON.stringify(objects) },
      "fatal"
    );
    return getFallbackObjectTemplate(objectId);
  }
};

const getDefaultObject = () => {
  return {
    id: 0,
    name: "",
    category: ObjectCategory.ACCESSORIES,
    width: 0,
    height: 0,
    items: [],
  } as ObjectSchema;
};

const getObjectTemplate = async (id: number) => {
  const result = await Api.get("api/objects/id/" + id);
  if (result.success) {
    return fromDbFormat(result.data);
  } else {
    console.error(result);
    throw new Error("Failed to load object template from server");
  }
};

const getMultipleObjectTemplates = async (ids: number[]) => {
  if (ids.length === 0) return [];
  const result = await Api.get("api/objects/ids/" + ids.join(","));
  if (result.success) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return result.data.map((object: any) => {
      return fromDbFormat(object as DbObject);
    }) as ObjectSchema[];
  } else {
    console.error("Request:", "objects/ids/" + ids.join(","));
    console.error(result);
    throw new Error("Failed to load object template from server");
  }
};

/**
 * Get the bounding rectangle based on an item's position, size and rotation. Any internal
 * shapes are not considered.
 */
const getBoundingRectangleFromItem = (item: Item) => {
  const originalRectangle = {
    position: item.position,
    size: item.size,
  } as Rectangle;
  if (item.rotation === 0) {
    return originalRectangle;
  }
  // Get rotated positions for all corners
  const rotatedCorners: Point[] = [];
  // Upper left
  rotatedCorners.push(
    ShapeOperations.getRotatedPositionWithRectangle(
      item.position,
      originalRectangle,
      item.rotation
    )
  );
  // Upper right
  rotatedCorners.push(
    ShapeOperations.getRotatedPositionWithRectangle(
      { x: item.position.x + item.size.width, y: item.position.y },
      originalRectangle,
      item.rotation
    )
  );
  // Lower left
  rotatedCorners.push(
    ShapeOperations.getRotatedPositionWithRectangle(
      { x: item.position.x, y: item.position.y + item.size.height },
      originalRectangle,
      item.rotation
    )
  );
  // Lower right
  rotatedCorners.push(
    ShapeOperations.getRotatedPositionWithRectangle(
      {
        x: item.position.x + item.size.width,
        y: item.position.y + item.size.height,
      },
      originalRectangle,
      item.rotation
    )
  );
  // Now find total min and total max
  let min = { x: 99999, y: 99999 };
  let max = { x: -99999, y: -99999 };
  rotatedCorners.forEach((point: Point) => {
    min = ShapeOperations.getMinPoint(min, point);
    max = ShapeOperations.getMaxPoint(max, point);
  });

  const size = CanvasOperations.getPointSubtracted(max, min, 3);
  const rotatedRectangle = {
    position: min,
    size: { width: size.x, height: size.y },
  } as Rectangle;
  return rotatedRectangle;
};

/**
 * Get upper left corner position of the bounding rectangle for a list of items.
 * @param items
 */
const getObjectDefaultPosition = (items: Item[]) => {
  if (items.length === 0) {
    throw new Error("No items to calculate position for");
  }
  // Take rotation into consideration
  const initialBoundingRectangle = getBoundingRectangleFromItem(items[0]);
  let upperLeft = initialBoundingRectangle.position;
  items.forEach((item: Item) => {
    const boundingRectangle = getBoundingRectangleFromItem(item);
    upperLeft = ShapeOperations.getMinPoint(
      upperLeft,
      boundingRectangle.position
    );
  });
  return upperLeft;
};

/**
 * Generates an object from a list of items, and an original state of the object. The items
 * within the object are overridden by the item list. All items are positioned
 * relative to the object upper left corner (object position).
 */
const generateObjectFromItems = (items: Item[], object: ObjectSchema) => {
  const updatedObject: ObjectSchema = {
    ...object,
    width: 0,
    height: 0,
    items: [],
  };

  if (items.length === 0) {
    return updatedObject;
  }
  // First find the upper left corner and lower right corner of the bounding rectangle - if item
  // is rotated, it will be different than item position and size.
  const initialBoundingRectangle = getBoundingRectangleFromItem(items[0]);

  let upperLeft = initialBoundingRectangle.position;
  let lowerRight = CanvasOperations.getEndPoint(
    upperLeft,
    initialBoundingRectangle.size
  );
  items.forEach((item: Item) => {
    const boundingRectangle = getBoundingRectangleFromItem(item);
    upperLeft = ShapeOperations.getMinPoint(
      upperLeft,
      boundingRectangle.position
    );
    const endPoint = CanvasOperations.getEndPoint(
      boundingRectangle.position,
      boundingRectangle.size
    );
    lowerRight = ShapeOperations.getMaxPoint(lowerRight, endPoint);
  });

  const size = CanvasOperations.getAreaSize(upperLeft, lowerRight);
  updatedObject.width = size.width;
  updatedObject.height = size.height;

  // Update all item positions to be relative to the upper left corner of the object
  const newItems = items.map((item: Item) => {
    return {
      ...item,
      position: CanvasOperations.getPointSubtracted(
        item.position,
        upperLeft,
        3
      ),
      groupId: undefined,
      layerId: undefined,
    } as Item;
  });

  updatedObject.items = newItems;
  return updatedObject;
};

/**
 * Generate a list of items from an object. The returned items are positioned relative
 * to the canvas.
 */
const generateItemsFromObject = (object: ObjectSchema, position: Point) => {
  // Get absolute positions for all items in the object
  const newItems = object.items.map((item: Item) => {
    return {
      ...item,
      position: CanvasOperations.getPointSum(position, item.position, 3),
      layerId: undefined, // Ensure no layer is set
      groupId: undefined, // Ensure no group is set
    } as Item;
  });
  return newItems;
};

const toDbFormat = (object: ObjectSchema) => {
  return {
    id: object.id,
    name: object.name,
    category: object.category,
    width: Tools.round(object.width * 100, 0),
    height: Tools.round(object.height * 100, 0),
    items: JSON.stringify(object.items),
  } as DbObject;
};

/**
 * Converts object received from backend into an ObjectSchema object. If parsing fails, it will return
 * a default object (and notify Sentry)
 */
const fromDbFormat = (object: DbObject) => {
  try {
    return {
      id: object.id,
      name: object.name || "",
      category: object.category,
      width: Tools.round(object.width / 100, 3),
      height: Tools.round(object.height / 100, 3),
      items: JSON.parse(object.items),
      userId: object.userId,
      public: object.public,
      userEmail: object.userEmail,
      nameId: object.nameId || 0,
      language: object.language || undefined,
      userName: object.userName,
      fallbackName: object.fallbackName,
      dateCreated: object.dateCreated,
      dateUpdated: object.dateUpdated,
    } as ObjectSchema;
  } catch (e) {
    console.error(
      "Unable to convert from db format: unexpected format",
      object
    );
    console.error(e);
    SentryReporter.captureException(e, {
      Action: "Parsing object from DB format",
      Object: JSON.stringify(object),
    });
    try {
      return {
        id: object.id,
        name: object.name,
        category: object.category,
        width: Tools.round(object.width / 100, 3),
        height: Tools.round(object.height / 100, 3),
        items: [],
        userId: object.userId,
        public: object.public,
        email: object.userEmail,
        nameId: object.nameId,
        language: object.language,
      } as ObjectSchema;
    } catch (e2) {
      console.error(
        "Critical failure when converting object from db format",
        object
      );
      console.error(e);
      SentryReporter.captureException(e, {
        Action: "Converting object from DB format",
        Object: JSON.stringify(object),
      });
      return getDefaultObject();
    }
  }
};

const fromDbFormats = (objects: DbObject[]) => {
  return objects.map((object: DbObject) => {
    return fromDbFormat(object);
  });
};

const getCategoryOptions = (allowAll?: boolean) => {
  const categories: Option[] = [];
  if (allowAll) {
    categories.push({
      value: ObjectCategory._SIZE,
      label: i18next.t("imagine:all"),
    });
  }
  for (let i = 0; i < ObjectCategory._SIZE; i++) {
    categories.push({
      value: i,
      label: getCategoryText(i),
    });
  }
  return categories;
};

const getCategoryText = (category: ObjectCategory | number) => {
  switch (category) {
    case ObjectCategory.POOLS:
      return i18next.t("draw:pools");
    case ObjectCategory.HOUSES:
      return i18next.t("draw:houses");
    case ObjectCategory.SMALL_BUILDINGS:
      return i18next.t("draw:smallBuildings");
    case ObjectCategory.FURNITURE:
      return i18next.t("draw:furniture");
    case ObjectCategory.GARDEN_PLAY:
      return i18next.t("draw:gardenPlay");
    case ObjectCategory.ACCESSORIES:
      return i18next.t("draw:accessories");
    case ObjectCategory.RAISED_BEDS:
      return i18next.t("draw:raisedBeds");
    default:
      throw new Error("Unknown object category" + category);
  }
};

export const ObjectOperations = {
  findObject,
  getDefaultObject,
  getObjectTemplate,
  getMultipleObjectTemplates,
  convertToObjectItem,
  convertToItem,
  convertToObjectItems,
  convertToItems,
  getObjectDefaultPosition,
  generateObjectFromItems,
  generateItemsFromObject,
  toDbFormat,
  fromDbFormat,
  fromDbFormats,
  getCategoryOptions,
  getCategoryText: getCategoryText,
};
