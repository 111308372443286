import { Point, Tools } from "imagine-essentials";

export type StraightLineEquation = {
  a: number; // Slope
  b: number; // Y-interception
  x?: number; // X value in case the line is a vertical line
  y?: number; // Y value in case the line is a horizontal line
};

const getZeroComparator = (value: number) => {
  let count = 10;
  let zero = 1000;
  while (Math.abs(value / zero) < 100 && count) {
    zero = zero * 0.1;
    count--;
  }
  return zero;
};
/**
 * Checks whether 2 values are close to equal. If comparing directly, rounding will say that they are not,
 * even though they technically are, so this function can be used to get around that.
 */
const equals = (val1: number, val2: number, tolerance?: number) => {
  const zero =
    tolerance || Math.max(getZeroComparator(val1), getZeroComparator(val2));
  // const zero = 0.001;
  return Math.abs(val1 - val2) < zero;
};

const getLineEquationX = (equation: StraightLineEquation, y: number) => {
  return (y - equation.b) / equation.a;
};

const getLineEquationY = (equation: StraightLineEquation, x: number) => {
  return equation.a * x + equation.b;
};

/**
 * Get the slope (a) and y-interception (b) for a straigh line defined by two points, p1 and p2. The slope
 * might be Infinity.
 */
const getStraightLineEquation = (p1: Point, p2: Point) => {
  let a: number;
  let b: number;
  if (equals(p1.x, p2.x)) {
    a = Infinity;
    b = NaN;
    console.warn("Vertical line detected", p1, p2);
    return {
      a: a,
      b: b,
      x: p1.x,
    } as StraightLineEquation;
  } else {
    // Steep slopes are considered vertical lines and plane slopes are considered horizontal lines
    // Otherwise rounded values will move any intersection points between lines way out of the screen
    a = (p2.y - p1.y) / (p2.x - p1.x);
    if (a > 500 || a < -500) {
      return {
        a: Infinity,
        b: NaN,
        x: p1.x,
      } as StraightLineEquation;
    } else if (a > -0.05 && a < 0.05) {
      return {
        a: 0,
        b: p1.y,
      } as StraightLineEquation;
    }

    b = p1.y - a * p1.x;
  }
  return {
    a: a,
    b: b,
  } as StraightLineEquation;
};

const getLineEquationIntersectionPoint = (
  line1: StraightLineEquation,
  line2: StraightLineEquation
) => {
  // First check that the two lines are not parallel
  if (equals(line1.a, line2.a, 0.01)) {
    return null;
  }

  if (line1.a === Infinity && line1.x) {
    // Line 1 is a vertical line
    const y = getLineEquationY(line2, line1.x);
    return { x: line1.x, y: y } as Point;
  } else if (line2.a === Infinity && line2.x) {
    // Line 2 is a vertical line
    const y = getLineEquationY(line1, line2.x);
    return { x: line2.x, y: y } as Point;
  }

  const x = (line2.b - line1.b) / (line1.a - line2.a);
  const y = line1.a * x + line1.b;

  return {
    x: x,
    y: y,
  } as Point;
};

/**
 * Get the intersection point from 2 lines. Each line is defined by 2 points on the line. Line 1 is defined
 * by p1 and p2 and line 2 is defined by p3 and p4. Returns null if the two line does not intercept (parallel lines).
 */
const getLinesIntersectionPoint = (
  p1: Point,
  p2: Point,
  p3: Point,
  p4: Point
) => {
  // Formula found here: https://en.wikipedia.org/wiki/Line%E2%80%93line_intersection

  const line1 = getStraightLineEquation(p1, p2);
  const line2 = getStraightLineEquation(p3, p4);

  let point: Point;

  // First check that the two lines are not parallel
  if (equals(line1.a, line2.a)) {
    return null;
  }

  if (line1.a === Infinity) {
    // Line 1 is a vertical line
    const y = getLineEquationY(line2, p1.x);
    point = { x: p1.x, y: y };
  } else if (line2.a === Infinity) {
    // Line 2 is a vertical line
    const y = getLineEquationY(line1, p3.x);
    point = { x: p3.x, y: y };
  } else {
    const x = (line2.b - line1.b) / (line1.a - line2.a);
    const y = line1.a * ((line2.b - line1.b) / (line1.a - line2.a)) + line1.b;
    point = { x: x, y: y };
  }

  if (isNaN(point.x)) return null;
  if (isNaN(point.y)) return null;
  if (!isFinite(point.x)) return null;
  if (!isFinite(point.y)) return null;

  return point;
};

/**
 * Rotates a vector and returns the resulting vector.
 * @param vector The vector
 * @param angleDegrees The angle in degreeds.
 * @returns The rotated vector.
 */
const getRotatedVector = (vector: Point, angleDegrees: number) => {
  const radians = Tools.degreesToRadians(angleDegrees);
  const x = Math.cos(radians) * vector.x - Math.sin(radians) * vector.y; //cosβx1−sinβy1
  const y = Math.sin(radians) * vector.x + Math.cos(radians) * vector.y; //sinβx1+cosβy1
  return {
    x: Tools.round(x, 5),
    y: Tools.round(y, 5),
  } as Point;
};

/**
 * Calculate the angle between two vectors.
 * @param a Vector given in point coordinates
 * @param b Vector given in point coordinates
 * @returns Angle in degrees
 */
const getAngle = (a: Point, b: Point) => {
  const num = a.x * b.x + a.y * b.y;
  const denum =
    Math.sqrt(Math.pow(a.x, 2) + Math.pow(a.y, 2)) *
    Math.sqrt(Math.pow(b.x, 2) + Math.pow(b.y, 2));
  return Tools.radiansToDegrees(Math.acos(num / denum));
};

const calculateTriangleArea = (a: Point, b: Point, c: Point) => {
  return Math.abs(
    (a.x * (b.y - c.y) + b.x * (c.y - a.y) + c.x * (a.y - b.y)) / 2
  );
};

const isPointInsideTriangle = (p: Point, a: Point, b: Point, c: Point) => {
  const areaABC = calculateTriangleArea(a, b, c);
  const areaPBC = calculateTriangleArea(p, b, c);
  const areaAPC = calculateTriangleArea(a, p, c);
  const areaABP = calculateTriangleArea(a, b, p);
  return equals(areaABC, areaPBC + areaAPC + areaABP, 0.1);
};

export const Trig = {
  getLineEquationX,
  equals,
  getStraightLineEquation,
  getLineEquationIntersectionPoint,
  getLinesIntersectionPoint,
  getRotatedVector,
  getAngle,
  isPointInsideTriangle,
};
