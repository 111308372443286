import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  PlanEditorActions,
  PlanEditorSelector,
} from "../../store";
import {
  ModelDesign,
  ModelDesignSvg,
  ModelOperations,
  ObjectOperations,
  ObjectSchema,
} from "draw";
import {
  ToolGroup,
  ToolInfo,
  ToolNumberInput,
  ToolSeparator,
} from "../../components";
import { useMemo } from "react";
import _ from "lodash";
import { Tools } from "imagine-essentials";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Displays the tool and actions in the object item toolbar. Should only show when exactly 1 object is selected.
 * @param props
 * @returns
 */
export const ObjectItemToolbarContainer = () => {
  const { t } = useTranslation();
  const selectedItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    PlanEditorSelector.getSelectedObjects
  );
  const user = useAppSelector(UserSelector.getUserNotNull);
  const preferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const visibleItems = useAppSelector(PlanEditorSelector.getVisibleItems);
  const objectTemplates = useAppSelector(PlanEditorSelector.getObjectTemplates);
  const delimiter = I18nTools.getDelimiter(user.language);

  const dispatch = useAppDispatch();

  /**
   * The template of the selected object. If multiple objects are selected and they are all
   * the same template, this is also set. Not set if multiple different plants are selected.
   */
  const selectedObjectTemplate = useMemo(() => {
    if (selectedObjectItems.length !== 1) return undefined;
    // return selectedObjectItems[0].templateId;

    return objectTemplates.find(
      (template: ObjectSchema) =>
        template.id === selectedObjectItems[0].templateId
    );
  }, [objectTemplates, selectedObjectItems]);

  const modelDesign = useMemo(() => {
    if (!selectedObjectTemplate) return undefined;
    return {
      id: selectedObjectTemplate.id,
      shapes: ModelOperations.getObjectTemplateShapes(selectedObjectTemplate),
      viewBox:
        "0 0 " +
        selectedObjectTemplate.width +
        " " +
        selectedObjectTemplate.height,
      name: selectedObjectTemplate.name,
    } as ModelDesign;
  }, [selectedObjectTemplate]);

  const updateWidth = (val: number) => {
    if (selectedObjectItems.length !== 1) return;

    const originalDiameter = selectedObjectItems[0].size.width;
    const originalPos = selectedObjectItems[0].position;

    const diameterDiff = val - originalDiameter;

    const newPos = {
      x: Tools.round(originalPos.x - diameterDiff / 2, 3),
      y: originalPos.y,
    };

    const updatedItem = {
      ...selectedObjectItems[0],
      size: {
        width: val,
        height: selectedObjectItems[0].size.height,
      },
      position: newPos,
    };

    dispatch(PlanEditorActions.updateItem(updatedItem));
  };

  const updateHeight = (val: number) => {
    if (selectedObjectItems.length !== 1) return;

    const originalHeight = selectedObjectItems[0].size.height;
    const originalPos = selectedObjectItems[0].position;

    const heightDiff = val - originalHeight;

    const newPos = {
      x: originalPos.x,
      y: Tools.round(originalPos.y - heightDiff / 2, 2),
    };

    const updatedItem = {
      ...selectedObjectItems[0],
      size: {
        width: selectedObjectItems[0].size.width,
        height: val,
      },
      position: newPos,
    };

    dispatch(PlanEditorActions.updateItem(updatedItem));
  };

  return (
    <>
      {selectedObjectItems.length > 0 && (
        <>
          {selectedObjectTemplate && (
            <>
              <ToolGroup>
                <ToolInfo elementId="object-info-tool">
                  <div className="row items-stretch">
                    {modelDesign && (
                      <div className="mr">
                        <ModelDesignSvg
                          design={modelDesign}
                          size={{ width: 56, height: 56 }}
                          position={{ x: 0, y: 0 }}
                          viewBox={modelDesign.viewBox}
                        />
                      </div>
                    )}
                    <div className="text-left column items-space-between">
                      <div className="text-bold">
                        {selectedObjectTemplate.name}
                      </div>
                      <div className="text-italic">
                        {ObjectOperations.getCategoryText(
                          selectedObjectTemplate.category
                        )}
                      </div>
                      <div className="text-dark-muted">&nbsp;</div>
                    </div>
                  </div>
                </ToolInfo>
              </ToolGroup>
              <ToolSeparator />
              <ToolGroup>
                <ToolNumberInput
                  icon="width"
                  text={t("imagine:width")}
                  onChange={updateWidth}
                  value={selectedObjectItems[0].size.width}
                  elementId="object-width-input"
                  unitScale={preferences.unitScale}
                  delimiter={delimiter}
                  omitYards
                />
                <ToolNumberInput
                  icon="height"
                  text={t("draw:length")}
                  onChange={updateHeight}
                  value={selectedObjectItems[0].size.height}
                  elementId="object-height-input"
                  unitScale={preferences.unitScale}
                  delimiter={delimiter}
                  omitYards
                />
              </ToolGroup>
            </>
          )}
        </>
      )}
    </>
  );
};
