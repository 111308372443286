import { useTranslation } from "react-i18next";
import { Modal, NotificationType, useNotification } from "imagine-ui";
import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
  WebsiteSelector,
} from "../../store";
import { SignUp, SignUpConfirmation, UsersApi } from "imagine-users";
import { useMemo, useState } from "react";
import { Tools } from "imagine-essentials";
import { WindowLocation } from "../../utils";
import { Country, Language } from "imagine-i18n";
import { KeyPageUtils } from "imagine-pages";
import ReactPixel from "react-facebook-pixel";

export const SignUpModalContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const { t, i18n } = useTranslation();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const keyPages = useAppSelector(WebsiteSelector.getKeyPages);
  const [errorMessage, setErrorMessage] = useState("");
  const [emailErrorMessage, setEmailErrorMessage] = useState("");
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [loading, setLoading] = useState(false);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    WindowLocation.clearUrlParams();
  };

  const signUp = async (
    email: string,
    password: string,
    country?: Country,
    language?: Language,
    ipCountry?: string
  ) => {
    setLoading(true);
    /**
     * Sets the source if it is not set already. This are done in most cases from the website, but
     * this is a fallback.
     */
    const previousSource = localStorage.getItem("source");
    if (previousSource === null) {
      const source = Tools.sanitizeUrl(document.referrer);
      localStorage.setItem("source", source);
    }

    const result = await UsersApi.signUp(
      email,
      password,
      country,
      language,
      ipCountry
    );
    if (result.success) {
      setShowConfirmation(true);

      if (import.meta.env.PROD) {
        ReactPixel.track("CompleteRegistration");
      }
    }
    if (result.errorMessage) {
      setErrorMessage(result.errorMessage);
    }
    if (result.emailErrorMessage) {
      setEmailErrorMessage(result.emailErrorMessage);
    }
    if (result.passwordErrorMessage) {
      setPasswordErrorMessage(result.passwordErrorMessage);
    }
    setLoading(false);
  };

  const openLoginModal = () => {
    dispatch(LayoutActions.replaceDisplayedPage(Pages.LOGIN));
  };

  const voteCountry = async (country: string, ip: string) => {
    const response = await UsersApi.voteCountryExpansion(country, ip);
    if (response?.success) {
      notification.add(NotificationType.INFO, t("users:countryVoteSuccess"));
    }
  };

  const privacyPolicyUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        12,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  const conditionsUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        11,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  return (
    <>
      {displayedPage === Pages.SIGN_UP && (
        <>
          <Modal
            title={t("users:signUp")}
            visible
            onClose={close}
            hideCancel
            hideFooter={true}
            discreteHeader
            center
          >
            {!showConfirmation && (
              <SignUp
                showCountrySelector
                onSignUpClick={signUp}
                onLoginClick={openLoginModal}
                errorMessage={errorMessage}
                emailErrorMessage={emailErrorMessage}
                passwordErrorMessage={passwordErrorMessage}
                conditionsUrl={conditionsUrl}
                dataProcessingAgreementUrl={privacyPolicyUrl}
                loading={loading}
                onCountryVote={voteCountry}
              />
            )}
            {showConfirmation && <SignUpConfirmation />}
          </Modal>
        </>
      )}
    </>
  );
};
