import { useCallback, useEffect, useMemo, useState } from "react";
import {
  useAppSelector,
  CanvasSelector,
  PlanEditorSelector,
  useAppDispatch,
  CanvasActions,
  PlanEditorActions,
} from "../../store";
import { PositionMarker, ShapeCreator } from "../../components";
import { CursorMode } from "../../enums";
import {
  AreaOperations,
  CanvasOperations,
  EventProcess,
  Item,
  Measure,
  Mouse,
  MouseState,
  ReferenceImage,
  ShapeOperations,
} from "draw";
import { UserSelector } from "imagine-users";
import { UnitScale, UserHelpers, UserPreferences } from "project";
import { Api, Delimiter, Point, Rectangle, Tools } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";
import { start } from "repl";
import ImagineConfig from "../../../../../ImagineConfig";

const FONT_SIZE_PX = 14;

interface Props {
  referenceImage: ReferenceImage;
  onChange: (
    referenceImage: ReferenceImage,
    zoom: number,
    zeroReference: Point
  ) => void;
  onUpdateDistance: () => void;
  zoom: number;
  zeroReference: Point;
  unitScale: UnitScale;
  delimiter: Delimiter;
}

/**
 * Displays the editable version of the reference image
 */
export const EditableReferenceImage = (props: Props) => {
  const imagePath =
    Api.getHost() +
    "/user-plans/" +
    props.referenceImage.fileName +
    "?cache=" +
    new Date().getTime();

  const [startPos, setStartPos] = useState<Point | null>(null);
  const [diff, setDiff] = useState<Point>({ x: 0, y: 0 });

  const [tempPointA, setTempPointA] = useState<Point | null>(null);
  const [tempPointB, setTempPointB] = useState<Point | null>(null);

  const updateTempPointB = (point: Point | null) => {
    setTempPointB(point);
  };

  /**
   * The size of the reference image in px
   */
  const size = useMemo(() => {
    const pxDist = CanvasOperations.calculateDistance(
      props.referenceImage.pointA,
      props.referenceImage.pointB
    );
    const factor = pxDist / props.referenceImage.distance;
    const sizeUnit = {
      width: props.referenceImage.size.width / factor,
      height: props.referenceImage.size.height / factor,
    };
    return CanvasOperations.unitToPixelSize(sizeUnit, props.zoom);
  }, [
    props.referenceImage.size,
    props.zoom,
    props.referenceImage.pointA,
    props.referenceImage.pointB,
    props.referenceImage.distance,
  ]);

  /**
   * The position of the reference image relative to the canvas
   */
  const position = useMemo(() => {
    let centerPx = CanvasOperations.unitToPixelPosition(
      props.referenceImage.center,
      props.zoom,
      props.zeroReference
    );
    if (startPos !== null) {
      centerPx = {
        x: centerPx.x + diff.x,
        y: centerPx.y + diff.y,
      };
    }
    return {
      x: centerPx.x - size.width / 2,
      y: centerPx.y - size.height / 2,
    };
  }, [props.referenceImage.center, props.zeroReference, startPos, diff, size]);

  /**
   * The position of point A relative to the canvas
   */
  const pointAPosition = useMemo(() => {
    if (tempPointA !== null) {
      return tempPointA;
    }
    const relativePosition = {
      x: props.referenceImage.pointA.x / props.referenceImage.size.width,
      y: props.referenceImage.pointA.y / props.referenceImage.size.height,
    };

    return {
      x: position.x + relativePosition.x * size.width,
      y: position.y + relativePosition.y * size.height,
    };
  }, [
    props.referenceImage.pointA,
    props.referenceImage.size,
    position,
    size,
    tempPointA,
  ]);

  /**
   * The position of point B relative to the canvas
   */
  const pointBPosition = useMemo(() => {
    if (tempPointB !== null) {
      return tempPointB;
    }
    const relativePosition = {
      x: props.referenceImage.pointB.x / props.referenceImage.size.width,
      y: props.referenceImage.pointB.y / props.referenceImage.size.height,
    };

    return {
      x: position.x + relativePosition.x * size.width,
      y: position.y + relativePosition.y * size.height,
    };
  }, [
    props.referenceImage.pointB,
    props.referenceImage.size,
    position,
    size,
    tempPointB,
  ]);

  const distanceCenter = useMemo(() => {
    const diff = CanvasOperations.getPointSubtracted(
      pointBPosition,
      pointAPosition
    );
    return {
      x: pointAPosition.x + diff.x / 2,
      y: pointAPosition.y + diff.y / 2,
    };
  }, [pointAPosition, pointBPosition]);

  const startDragging = useCallback((event) => {
    // Stop the event from bubbling up to the canvas
    event.stopPropagation();
    const mousePosition = EventProcess.getEventPosition(event);
    setStartPos(mousePosition);
  }, []);

  const drag = useCallback(
    (pos: Point) => {
      if (startPos !== null) {
        setDiff({
          x: pos.x - startPos.x,
          y: pos.y - startPos.y,
        });
      }
    },
    [startPos]
  );

  const stopDragging = useCallback(() => {
    if (startPos !== null) {
      const center = {
        x: position.x + size.width / 2,
        y: position.y + size.height / 2,
      };
      const newPosition = CanvasOperations.pixelToUnitPosition(
        center,
        props.zoom,
        props.zeroReference
      );
      props.onChange(
        {
          ...props.referenceImage,
          center: newPosition,
        },
        props.zoom,
        props.zeroReference
      );
    }
    setStartPos(null);
    setDiff({ x: 0, y: 0 });
  }, [
    startPos,
    props.onChange,
    props.referenceImage,
    props.zoom,
    props.zeroReference,
    position,
  ]);

  /**
   * Tells the parent component that the reference image has been updated. Zoom and zero
   * reference are updated to keep the reference image in the same position.
   */
  const updateReferenceImage = useCallback(
    (referenceImage: ReferenceImage) => {
      // Calculate the new zoom level
      const pxDistBefore = CanvasOperations.calculateDistance(
        props.referenceImage.pointA,
        props.referenceImage.pointB
      );
      const pxDistAfter = CanvasOperations.calculateDistance(
        referenceImage.pointA,
        referenceImage.pointB
      );
      const newZoom = (pxDistAfter / pxDistBefore) * props.zoom;

      props.onChange(referenceImage, newZoom, props.zeroReference);
    },
    [props.onChange, props.zoom, props.zeroReference]
  );

  const updatePointA = useCallback(
    (point: Point) => {
      const relativePosition = {
        x: (point.x - position.x) / size.width,
        y: (point.y - position.y) / size.height,
      };

      updateReferenceImage({
        ...props.referenceImage,
        pointA: {
          x: relativePosition.x * props.referenceImage.size.width,
          y: relativePosition.y * props.referenceImage.size.height,
        },
      });
    },
    [position, size, updateReferenceImage, props.referenceImage]
  );

  const updatePointB = useCallback(
    (point: Point) => {
      const relativePosition = {
        x: (point.x - position.x) / size.width,
        y: (point.y - position.y) / size.height,
      };

      updateReferenceImage({
        ...props.referenceImage,
        pointB: {
          x: relativePosition.x * props.referenceImage.size.width,
          y: relativePosition.y * props.referenceImage.size.height,
        },
      });
    },
    [position, size, updateReferenceImage, props.referenceImage]
  );

  const imageRectangle = useMemo(() => {
    return {
      position: position,
      size: size,
    } as Rectangle;
  }, [position, size]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (state.pressed) {
        drag(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      stopDragging();
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [drag, stopDragging]);

  const distanceLabelText = useMemo(() => {
    return Measure.getMeasureText(
      props.referenceImage.distance,
      props.unitScale,
      props.delimiter,
      true,
      true
    );
  }, [props.referenceImage.distance]);

  const pointsDistance = useMemo(() => {
    return CanvasOperations.calculateDistance(pointAPosition, pointBPosition);
  }, [pointAPosition, pointBPosition]);

  const textWidth = useMemo(() => {
    const textWidth = Tools.round(
      ShapeOperations.getTextPxWidth(distanceLabelText, FONT_SIZE_PX),
      1
    );
    return textWidth + 30;
  }, [distanceLabelText]);

  const updateDistance = (event) => {
    event.stopPropagation();
    props.onUpdateDistance();
  };

  return (
    <g>
      <image
        x={position.x}
        y={position.y}
        width={size.width}
        height={size.height}
        xlinkHref={imagePath}
        onMouseDown={startDragging}
        onTouchStart={startDragging}
        style={{ cursor: "move" }}
      />
      <PositionMarker
        position={pointAPosition}
        onChange={updatePointA}
        window={imageRectangle}
        onDrag={setTempPointA}
      />
      <PositionMarker
        position={pointBPosition}
        onChange={updatePointB}
        window={imageRectangle}
        onDrag={updateTempPointB}
      />
      <line
        x1={pointAPosition.x}
        y1={pointAPosition.y}
        x2={pointBPosition.x}
        y2={pointBPosition.y}
        stroke="red"
        strokeDasharray={"8,8"}
      />
      {pointsDistance > textWidth + 30 && (
        <>
          <rect
            x={distanceCenter.x - textWidth / 2}
            y={distanceCenter.y - 18}
            width={textWidth}
            height={36}
            fill={ImagineConfig.colors.secondary}
            stroke={ImagineConfig.colors.secondaryDark}
            rx={6}
            ry={6}
            onMouseDown={updateDistance}
            onTouchStart={updateDistance}
            style={{ cursor: "pointer" }}
          />
          <text
            x={distanceCenter.x}
            y={distanceCenter.y}
            textAnchor="middle"
            dominantBaseline="middle"
            fontSize={FONT_SIZE_PX}
            onMouseDown={updateDistance}
            onTouchStart={updateDistance}
            style={{ cursor: "pointer" }}
          >
            {distanceLabelText}
          </text>
        </>
      )}
    </g>
  );
};
