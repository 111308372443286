import { useTranslation } from "react-i18next";
import { PlantCollection, PlantCollectionCard } from "plants/src";
import { Modal, Button, Label, TextInput } from "imagine-ui";
import { useState } from "react";
import { MapLocation } from "../../types";

type Props = {
  visible: boolean;
  onClose: () => void;
  warningMessage?: string;
  message?: string;
  onFindClick: (address: string) => void;
  locations: MapLocation[];
  onSelectLocation: (location: MapLocation) => void;
  loading?: boolean;
};

/**
 * Displays a page with an overview of all plant collections.
 */
export const MapLocationModal = (props: Props) => {
  const { t } = useTranslation();

  const [address, setAddress] = useState("");

  const selectLocation = (location: MapLocation) => {
    props.onSelectLocation(location);
  };

  const findAddress = () => {
    props.onFindClick(address);
  };

  return (
    <Modal
      title={t("mapLocation")}
      visible={props.visible}
      onClose={props.onClose}
      hideFooter
      center
      elementId="map-location-modal"
    >
      <div id="map-location-page">
        {props.warningMessage && (
          <div className="alert alert-warning mb">{props.warningMessage}</div>
        )}
        <div className="">
          <Label>{t("imagine:address")}</Label>
          <TextInput
            elementId="map-location-address-input"
            value={address}
            onChange={(value) => setAddress(value)}
            onEnterPress={findAddress}
          />
        </div>

        <div className="text-right">
          <Button
            elementId="find-location-button"
            className="mt"
            onClick={findAddress}
            loading={props.loading}
          >
            {t("findLocation")}
          </Button>
        </div>

        {props.message !== "" && <p className="mt-lg">{props.message}</p>}

        <div className="grid gap-sm columns-1 mt" id="map-location-list">
          {props.locations.map((location: MapLocation, index: number) => (
            <Button
              key={index.toString()}
              elementId={"map-location-" + index}
              color="secondary"
              onClick={() => selectLocation(location)}
            >
              {location.address}
            </Button>
          ))}
        </div>
      </div>
    </Modal>
  );
};
