import { useMemo, useState } from "react";
import {
  CancelReason,
  CancelSubscriptionConfirm,
  CouponDetails,
  ProductDetails,
  SubscriptionSummary,
  Subscription,
  SubscriptionInvoice,
  SubscriptionTools,
  SubscriptionProductCard,
  SubscriptionExtension,
  SubscriptionText,
  SubscriptionPaymentMethod,
} from "..";
import { UserRole } from "imagine-users";
import { useTranslation } from "react-i18next";
import { Alert, Button, Modal } from "imagine-ui";
import { DateTimeFormat, DateTimeText } from "imagine-datetime";

type Props = {
  className?: string;
  invoice: SubscriptionInvoice;
  activeSubscription?: Subscription;
  ghostSubscription?: Subscription;
  subscriptions: Subscription[];
  paymentMethod?: SubscriptionPaymentMethod;
  productDetails: ProductDetails[];
  couponDetails: CouponDetails[];
  userRole?: UserRole;
  onCancelSubscription: (reasons: CancelReason[], comment: string) => void;
  onUpdatePaymentInformation: () => void;
  preparingPaymentInformation?: boolean;
  children?: React.ReactNode; // Used for payment window
};

/**
 * The page displays the current membership with expiry date
 */
export const SubscriptionInfo = (props: Props) => {
  const { t } = useTranslation();

  // When member cancels a subscription
  const [
    showCancelSubscriptionConfirmation,
    setShowCancelSubscriptionConfirmation,
  ] = useState(false);

  const [cancelReason, setCancelReason] = useState<CancelReason[]>([]);
  const [cancelComment, setCancelComment] = useState("");

  /**
   * There might be multiple subscriptions. In case user unsubscriped and later created a new subscription, or if user was migrated from old date to new.
   */

  const displayProduct = useMemo(() => {
    if (props.activeSubscription === undefined) return undefined;
    const product = SubscriptionTools.getInvoiceDisplayProduct(
      props.invoice,
      props.activeSubscription
    );
    return product;
  }, [
    props.couponDetails,
    props.invoice,
    props.productDetails,
    props.activeSubscription,
  ]);

  const paymentCardExpiration = useMemo(() => {
    if (props.activeSubscription === undefined) return undefined;

    if (props.paymentMethod?.type === "card") {
      const year = props.paymentMethod.cardExpiryYear;
      const month = props.paymentMethod.cardExpiryMonth;

      const totalMonths = year * 12 + month;

      const currentDate = new Date();
      const currentTotalMonths =
        currentDate.getFullYear() * 12 + currentDate.getMonth() + 1;

      if (totalMonths < currentTotalMonths + 3) {
        return DateTimeText.getMonthTextFull(month) + " " + year;
      }
    }
    return undefined;
  }, [props.activeSubscription, props.paymentMethod]);

  const initiateDeactiveAutoRenewal = async () => {
    setShowCancelSubscriptionConfirmation(true);
  };

  const deactivateAutoRenewal = async () => {
    setShowCancelSubscriptionConfirmation(false);
    props.onCancelSubscription(cancelReason, cancelComment);
  };

  const showUnsubscripeButton = useMemo(() => {
    if (props.ghostSubscription) {
      return !props.ghostSubscription.cancelAtPeriodEnd;
    }
    if (props.activeSubscription) {
      return !props.activeSubscription.cancelAtPeriodEnd;
    }
    return false;
  }, [props.activeSubscription, props.ghostSubscription]);

  const extensions = useMemo(() => {
    const extensionList: SubscriptionExtension[] = [];
    props.subscriptions.forEach((sub) => {
      if (sub.extensions) {
        sub.extensions.forEach((ext) => {
          extensionList.push(ext);
        });
      }
    });
    return extensionList;
  }, [props.subscriptions]);

  return (
    <div id="membership-info" className={props.className}>
      {displayProduct === undefined && (
        <p>{t("memberships:buyMembershipToAccessMoreFeatures")}</p>
      )}
      {displayProduct && props.activeSubscription && (
        <>
          <div className="grid columns-3 columns-2--lg columns-1--md gap-xl">
            <div className="">
              <SubscriptionProductCard
                product={displayProduct}
                hideButton
                elementId="active-membership"
              />
            </div>

            <div className="colspan-2 colspan-1--lg">
              {!props.children && paymentCardExpiration && (
                <Alert color="warning" className="mb">
                  <p className="mb">
                    {t("memberships:yourCardExpiresBy", {
                      date: paymentCardExpiration,
                    })}
                  </p>
                  <Button
                    color="warning"
                    onClick={props.onUpdatePaymentInformation}
                    loading={props.preparingPaymentInformation}
                  >
                    {t("memberships:updatePaymentInformation")}
                  </Button>
                </Alert>
              )}
              <SubscriptionSummary
                subscription={props.activeSubscription}
                ghostSubscription={props.ghostSubscription}
                product={displayProduct}
              />

              {showUnsubscripeButton && (
                <Button
                  className="mt"
                  onClick={initiateDeactiveAutoRenewal}
                  color="danger"
                  outline
                >
                  {t("memberships:cancelSubscription")}
                </Button>
              )}
              {props.children}
            </div>
          </div>
          {extensions.length > 0 && (
            <div className="">
              <h2 className="mt-xl">{t("memberships:extensions")}</h2>
              <p className="text-info text-italic text-sm my">
                {t("bonusDaysExplanation")}
              </p>
              <div className="grid columns-1 gap-sm">
                {extensions.map((extension: SubscriptionExtension) => (
                  <div
                    className="shadow rounded grid columns-2 p"
                    key={extension.id}
                  >
                    <div>
                      {DateTimeText.getDbDateTimeText(
                        extension.dateCreated,
                        DateTimeFormat.LONG_DATE
                      )}
                      : {extension.note}
                    </div>
                    <div className="text-right text-bold">
                      {t("memberships:daysCount", { count: extension.days })}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </>
      )}
      {showCancelSubscriptionConfirmation && (
        <Modal
          visible
          title={t("memberships:confirmCancelSubscription")}
          confirmText={t("memberships:cancelSubscription")}
          onClose={() => setShowCancelSubscriptionConfirmation(false)}
          onConfirm={deactivateAutoRenewal}
          size="lg"
          discreteHeader
          color="danger"
        >
          <CancelSubscriptionConfirm
            reason={cancelReason}
            onReasonChanged={setCancelReason}
            comment={cancelComment}
            onCommentChanged={setCancelComment}
          />
        </Modal>
      )}
    </div>
  );
};
function useAppSelector(getActiveSubscription: any) {
  throw new Error("Function not implemented.");
}
