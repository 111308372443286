import { Elements } from "@stripe/react-stripe-js";
import {
  DisplayProduct,
  StripePayment,
  SubscriptionProductCard,
  StripeTools,
  SubscriptionInvoice,
  Customer,
} from "..";
import { Appearance, StripeElementLocale, loadStripe } from "@stripe/stripe-js";
import { useEffect, useMemo, useState } from "react";
import ImagineConfig from "../../../../../ImagineConfig";
import { useTranslation } from "react-i18next";
import { Currency, CurrencyTools } from "imagine-i18n";
import { User } from "imagine-users";
import { SentryReporter } from "imagine-essentials";
import { Alert } from "imagine-ui";

// const stripePromise = loadStripe(StripeTools.getPublicKey());

interface Props {
  product?: DisplayProduct;
  invoice?: SubscriptionInvoice;
  clientSecret: string;
  returnUrl: string;
  updatePayment?: boolean;
  customer?: Customer;
  user?: User;
}

const appearance: Appearance = {
  theme: "stripe",
  variables: {
    colorPrimary: ImagineConfig.colors.accent,
  },
};

export const StripeCheckout = (props: Props) => {
  const { t } = useTranslation();
  const [stripe, setStripe] = useState<any>(undefined);
  const [errorMessage, setErrorMessage] = useState("");

  const initializeStripe = async () => {
    try {
      const s = await loadStripe(StripeTools.getPublicKey());
      // s?.elements({locale: props.user?.language || "en-GB"})
      setStripe(s);
    } catch (e) {
      console.error(e);
      SentryReporter.captureException(e);
      setErrorMessage(t("memberships:errorLoadingStripe"));
    }
  };

  useEffect(() => {
    initializeStripe();
  }, []);

  const buttonText = useMemo(() => {
    if (props.updatePayment) {
      return t("memberships:updatePaymentInformation");
    }
    if (props.invoice) {
      const priceText = CurrencyTools.getDisplayPrice(
        props.invoice.total / 100,
        props.invoice.currency as Currency,
        false
      );
      return t("memberships:payAmount", { amount: priceText });
    }
    return t("memberships:pay");
  }, [props.invoice, t]);

  const billingDetails = useMemo(() => {
    if (props.user && props.customer) {
      return {
        name: props.customer.name,
        email: props.user.email,
        address: {
          line1: props.customer.address1,
          line2: props.customer.address2,
          city: props.customer.city,
          postal_code: props.customer.postCode,
          state: props.customer.state,
          country: props.customer.country,
        },
      };
    }
    return undefined;
  }, [props.customer, props.user]);

  return (
    <>
      {props.clientSecret !== "" && (
        <div className="mt" id="buy-membership-payment">
          <div className="grid columns-3 columns-2--lg columns-1--sm gap-xl gap--lg">
            <div
              className={
                props.product
                  ? "colspan-2 colspan-1--lg"
                  : "colspan-3 colspan-1--lg"
              }
            >
              {props.updatePayment && (
                <p className="mb-xl">
                  {t("memberships:chargedWhenCurrentPeriodEnds")}
                </p>
              )}
              {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
              {errorMessage === "" && stripe && (
                <Elements
                  stripe={stripe}
                  options={{
                    clientSecret: props.clientSecret,
                    appearance: appearance,
                    locale:
                      (props.user?.language as StripeElementLocale) || "en-GB",
                  }}
                >
                  <StripePayment
                    returnUrl={props.returnUrl}
                    buttonText={buttonText}
                    updatePayment={props.updatePayment}
                    billingDetails={billingDetails}
                    currency={props.invoice?.currency}
                    amount={props.invoice?.total}
                  />
                </Elements>
              )}
            </div>

            {props.product && (
              <div className="colspan-1">
                <SubscriptionProductCard
                  product={props.product}
                  hideButton
                  elementId="selected-membership"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};
