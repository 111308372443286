import {
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import { Button } from "imagine-ui";
import { useTranslation } from "react-i18next";

/**
 * Displays a cancel stamp button on top of the canvas.
 * @param props
 * @returns
 */
export const CancelStampContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const sidebarLeftOpen = useAppSelector(LayoutSelector.getSidebarLeftOpen);
  const stampTemplate = useAppSelector(PlanEditorSelector.getStampTemplate);

  const stopInserting = () => {
    dispatch(PlanEditorActions.clearStamp());
  };

  return (
    <>
      {stampTemplate !== null && (
        <div
          className={
            "canvas-tools-top-left" + (sidebarLeftOpen ? " sidebar-open" : "")
          }
        >
          <Button color="danger" onClick={stopInserting}>
            {t("stopInserting")}
          </Button>
        </div>
      )}
    </>
  );
};
