import { useCallback, useMemo } from "react";
import {
  useAppSelector,
  CanvasSelector,
  PlanEditorSelector,
  useAppDispatch,
  CanvasActions,
  PlanEditorActions,
} from "../../store";
import { ShapeCreator } from "../../components";
import { CursorMode } from "../../enums";
import { AreaOperations, CanvasOperations, Item, ReferenceImage } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { Api, Point } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";

interface Props {
  referenceImage: ReferenceImage;
  zoom: number;
  zeroReference: Point;
}

/**
 * Displays the editable version of the reference image
 */
export const StaticReferenceImage = (props: Props) => {
  const imagePath =
    Api.getHost() +
    "/user-plans/" +
    props.referenceImage.fileName +
    "?cache=" +
    new Date().getTime();

  const size = useMemo(() => {
    const pxDist = CanvasOperations.calculateDistance(
      props.referenceImage.pointA,
      props.referenceImage.pointB
    );
    const factor = pxDist / props.referenceImage.distance;
    const sizeUnit = {
      width: props.referenceImage.size.width / factor,
      height: props.referenceImage.size.height / factor,
    };
    return CanvasOperations.unitToPixelSize(sizeUnit, props.zoom);
  }, [props.referenceImage.size, props.zoom]);

  const position = useMemo(() => {
    const centerPx = CanvasOperations.unitToPixelPosition(
      props.referenceImage.center,
      props.zoom,
      props.zeroReference
    );
    return {
      x: centerPx.x - size.width / 2,
      y: centerPx.y - size.height / 2,
    };
  }, [props.referenceImage.center, props.zeroReference]);

  return (
    <g>
      <image
        x={position.x}
        y={position.y}
        width={size.width}
        height={size.height}
        xlinkHref={imagePath}
        className="static-reference-image"
      />
    </g>
  );
};
