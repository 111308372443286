import { PlantCount, PlantInfoPage, PlantListPage } from "plants";

import {
  useAppDispatch,
  useAppSelector,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
  PlanEditorSelector,
} from "../../store";
import { Pages } from "../../enums";
import { useMemo } from "react";
import { ItemType, PlantSchema } from "draw";
import { UserSelector } from "imagine-users";
import { I18nTools } from "imagine-i18n";
import { UserHelpers, UserPreferences } from "project";

/**
 * Responsible for rendering the plant info plage.
 */
export const PlantListPageContainer = () => {
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const visibleItems = useAppSelector(PlanEditorSelector.getVisibleItems);
  const templates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const plants = useMemo(() => {
    const visiblePlantItems = visibleItems.filter(
      (item) => item.type === ItemType.PLANT
    );
    const neededTemplateIds = visiblePlantItems.map((item) => item.templateId);
    const neededTemplates = templates.filter((template) =>
      neededTemplateIds.includes(template.id)
    );
    return neededTemplates.map((template: PlantSchema) => {
      return {
        plant: template,
        count: neededTemplateIds.filter((id) => id === template.id).length,
      } as PlantCount;
    });
  }, [templates, visibleItems]);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  return (
    <>
      <PlantListPage
        visible={displayedPage === Pages.PLANT_LIST}
        plants={plants}
        onClose={close}
        unitScale={userPreferences.unitScale}
        delimiter={delimiter}
      />
    </>
  );
};
