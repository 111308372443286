import {
  User,
  UserActions,
  UserProfile,
  UserSelector,
  UsersApi,
} from "imagine-users";
import { useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../store";
import { I18nData, I18nTools } from "imagine-i18n";
import i18n from "../../i18n";
import { MembershipActions } from "imagine-memberships";

export const ProfileContainer = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const languageOptions = useMemo(() => {
    return I18nData.getLanguageOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.language]);

  const countryOptions = useMemo(() => {
    return I18nData.getCountryOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user.language]);

  const save = async (
    email: string,
    name: string,
    language: string,
    country: string
  ) => {
    setLoading(true);
    const response = await UsersApi.updateUserProfile(
      email,
      name,
      language,
      country
    );
    if (response.success && response.data) {
      dispatch(UserActions.setUser(response.data));
      // Change language if needed

      const lan = I18nTools.getValidLanguage(response.data.language);

      if (lan !== user.language) {
        console.log("Changing language", user.language, "->", lan);
        i18n.changeLanguage(lan);
      }
      dispatch(MembershipActions.setCustomerCountry(response.data.country));
    }

    if (response.errorMessage) {
      setErrorMessage(response.errorMessage);
    } else {
      setErrorMessage("");
    }
    setLoading(false);
  };

  return (
    <UserProfile
      user={user}
      onSave={save}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};
