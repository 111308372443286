import { useMemo } from "react";
import { BorderType } from "draw";
import { Icon } from "imagine-ui";

interface Props {
  borderType?: BorderType;
}

export const BorderTypeIcon = (props: Props) => {
  const iconName = useMemo(() => {
    switch (props.borderType) {
      case BorderType.NONE:
        return "none";
      case BorderType.SOLID:
        return "line-solid";
      case BorderType.DASHED:
        return "line-dashed";
      case BorderType.DOTTED:
        return "line-dotted";
      default:
        return "none";
    }
  }, [props.borderType]);

  return <Icon name={iconName}></Icon>;
};
