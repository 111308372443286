import React from "react";
import {
  ColorUtils,
  EllipseProperties,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
} from "..";
import { Point } from "imagine-essentials";

interface Props {
  ellipse: EllipseProperties;
  style?: ShapeStyle;
  pointsPerPixel: Point;
  markCenter?: boolean;
  cursor?: string;
  rotation?: number;
  className?: string;
  uniqueId?: string; // Used to link the patterns to the shapes
}

const defaultStyle: ShapeStyle = {
  borderColor: "#000000",
  fillColor: ColorUtils.defaultGrey(),
  opacity: 1,
};

const lineWidthPx = 1;

export const EllipseSvg = (props: Props) => {
  const style = props.style !== undefined ? props.style : defaultStyle;

  const getCenterMarkPath = () => {
    const markerSize = props.pointsPerPixel.x * 3;
    let path =
      "M " +
      (props.ellipse.center.x - markerSize) +
      " " +
      (props.ellipse.center.y - markerSize);
    path +=
      " L " +
      (props.ellipse.center.x + markerSize) +
      " " +
      (props.ellipse.center.y + markerSize);
    path +=
      "M " +
      (props.ellipse.center.x - markerSize) +
      " " +
      (props.ellipse.center.y + markerSize);
    path +=
      " L " +
      (props.ellipse.center.x + markerSize) +
      " " +
      (props.ellipse.center.y - markerSize);
    return path;
  };

  // The border is subtracted
  return (
    <g
      transform={
        "rotate(" +
        (props.rotation || 0) +
        ", " +
        props.ellipse.center.x +
        ", " +
        props.ellipse.center.y +
        ")"
      }
    >
      <defs>
        <ShapePattern
          color={style.fillColor}
          startPosition={props.ellipse.center}
          id={props.uniqueId}
          pattern={style.fillPattern}
        />
      </defs>
      {props.ellipse.radius.width > 0 && props.ellipse.radius.height > 0 && (
        <ellipse
          rx={props.ellipse.radius.width}
          ry={props.ellipse.radius.height}
          cx={props.ellipse.center.x}
          cy={props.ellipse.center.y}
          stroke={ShapeOperations.getStroke(style)}
          strokeOpacity={ShapeOperations.getStrokeOpacity()}
          strokeDasharray={ShapeOperations.getStrokeDashArray(
            style,
            lineWidthPx
          )}
          fill={ShapeOperations.getFill(style, props.uniqueId)}
          fillOpacity={ShapeOperations.getOpacity(style)}
          style={{ cursor: props.cursor || "move" }}
          className={props.className}
          strokeWidth={lineWidthPx}
          vectorEffect="non-scaling-stroke"
        />
      )}
      {props.ellipse.radius.width > 0 &&
        props.ellipse.radius.height > 0 &&
        style.borderColor && (
          <ellipse
            rx={props.ellipse.radius.width}
            ry={props.ellipse.radius.height}
            cx={props.ellipse.center.x}
            cy={props.ellipse.center.y}
            stroke={"red"}
            strokeOpacity={0}
            fill={"none"}
            style={{ cursor: props.cursor || "move" }}
            pointerEvents="stroke"
            className={props.className}
            strokeWidth={lineWidthPx}
            vectorEffect="non-scaling-stroke"
          />
        )}
      {props.markCenter && (
        <path
          d={getCenterMarkPath()}
          strokeWidth={lineWidthPx}
          stroke={"#000000"}
        />
      )}
    </g>
  );
};
