import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  CanvasOperations,
  Curve,
  CurvePoint,
  CurvedLinePathProperties,
  DimensionProperties,
  EventProcess,
  ShapeOperations,
  ShapePattern,
  ShapeStyle,
  ShapeType,
  Mouse,
  MouseState,
} from "draw";
import { Delimiter, Point, Rectangle } from "imagine-essentials";
import { Device } from "imagine-ui";
import { CheckCircle, ShapeDimension } from ".";
import { UnitScale } from "project";

const grapSizePx = 6;
const snapTolerance = 10;

const lineWidthGrab = 5;
const longTouchTime = 600;

const getPath = (newCurves: Curve[], closePath?: boolean) => {
  let newPath = "";
  newCurves.forEach((curve: Curve) => {
    if (newPath === "") {
      newPath = "M " + curve.p1.x + " " + curve.p1.y;
    }
    newPath += " C " + curve.h1.x + " " + curve.h1.y;
    newPath += ", " + curve.h2.x + " " + curve.h2.y;
    newPath += ", " + curve.p2.x + " " + curve.p2.y;
  });
  if (closePath === true && newPath !== "") newPath += "Z";
  return newPath;
};

const getHelperLinesPath = (newCurves: Curve[]) => {
  let newPath = "";
  newCurves.forEach((curve: Curve) => {
    newPath += "M " + curve.p1.x + " " + curve.p1.y;

    newPath += " L " + curve.h1.x + " " + curve.h1.y;
    newPath += " M " + curve.h2.x + " " + curve.h2.y;
    newPath += " L " + curve.p2.x + " " + curve.p2.y;
  });

  return newPath;
};

const getStraightLinesPath = (newCurves: Curve[], closePath?: boolean) => {
  let newPath = "";
  newCurves.forEach((curve: Curve) => {
    if (newPath === "") {
      newPath = "M " + curve.p1.x + " " + curve.p1.y;
    }
    newPath += " L " + curve.p2.x + " " + curve.p2.y;
  });
  if (closePath === true && newPath !== "") newPath += "Z";
  return newPath;
};

interface Props {
  lineWidth: number;
  style: ShapeStyle;
  dimensionStyle: ShapeStyle;
  curves: CurvedLinePathProperties;
  shapeId: number; // Used to detect when a new shape is selected
  onEditingChange: (editing: boolean) => void; // Triggered when editing is started/stopped (dragging or resizing)
  onLinesChange: (curves: CurvedLinePathProperties, ready: boolean) => void; // If ready is set, then an item can be create from the shape and it can enter update mode = close shape editor
  update: boolean;
  shapeType: ShapeType; /// Must be either CURVED_LINE or LINE
  onRightClicked?: (position: Point) => void;
  onLongTouch?: (pos: Point) => void;
  onClick?: () => void;
  rotation: number;
  onNoticeMessageSet?: (msg: string) => void;
  onNoticeMessageCleared?: () => void;
  className?: string;
  cursor?: string;
  canvasOffset: Point;

  visibleRectangle: Rectangle;
  zoom: number;
  snapEnabled: boolean;
  disabled?: boolean; // Will not trigger on mouse events if set to true
  delimiter: Delimiter;
  unitScale: UnitScale;
}

const isTouchDevice = Device.isTouchDevice();

/**
 * Creator of curved lines. It also supports viewing the line without curves, so it can be
 * used for both curved lines and straight lines.
 */
export const LineCreator = (props: Props) => {
  // The "Finish open path" button should appear after a little timeout. Otherwise it is instantly clicked
  // on touch events
  const finishTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [showFinishOpenPath, setShowFinishOpenPath] = useState(false);
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const { onEditingChange, onLinesChange, onLongTouch } = props;
  // The curves and points used to draw curved lines and straight line respectively.
  // The curved lines are the primary data and the straight lines are generated from the curved lines.
  const [tempCurves, setTempCurves] = useState<Curve[] | undefined>(
    props.curves.curves
  );
  const curves = useMemo(() => {
    if (tempCurves !== undefined) return tempCurves;
    else return props.curves.curves;
  }, [tempCurves, props.curves]);

  /**
   * The rotation center must be based on the currently stored shape. Otherwise the center will
   * change when the handles are moved.
   */
  const rotationCenter = useMemo(() => {
    return ShapeOperations.getLineShapeCenter(props.curves.curves);
  }, [props.curves.curves]);

  const [curveStart, setCurveStart] = useState<Point | null>(null);

  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });

  // Whether a new line path is currently being created (affects styling)
  const [creatingLine, setCreatingLine] = useState(false);
  const [downPosition, setDownPosition] = useState<Point | null>(null);

  /**
   * The index of the curves currently being edited.
   */
  const [selectedCurveIndex, setSelectedCurveIndex] = useState<number | null>(
    null
  );
  const [curvePoint, setCurvePoint] = useState(CurvePoint.NONE);

  const path = useMemo(() => {
    return getPath(curves, props.curves.closePath);
  }, [curves, props.curves.closePath]);

  const straightPath = useMemo(() => {
    return getStraightLinesPath(curves, props.curves.closePath);
  }, [curves, props.curves.closePath]);

  const isTouchDevice = useMemo(() => {
    return Device.isTouchDevice();
  }, []);

  const helperLinesPath = useMemo(() => {
    return getHelperLinesPath(curves);
  }, [curves]);

  const dimensions = useMemo(() => {
    return curves.map((curve: Curve) => {
      return {
        start: curve.p1,
        end: curve.p2,
      };
    });
  }, [curves]);

  const shouldClosePath = (updatedCurves) => {
    // Shapes created with an older version of Garden Sketcher might not have the closePath property set even if start and stop point is the same
    if (updatedCurves.length === 0) return undefined;
    return CanvasOperations.isPointsEqual(
      updatedCurves[0].p1,
      updatedCurves[updatedCurves.length - 1].p2
    );
  };

  const finishAddingLines = (updatedCurves: Curve[]) => {
    if (props.update) return;
    setSelectedCurveIndex(null);

    props.onLinesChange({ curves: updatedCurves, closePath: true }, true);
  };

  const removeLastCurveAndFinishAddingLines = (close: boolean) => {
    if (props.update) return;

    if (curves.length > 1) {
      const lastIndex = curves.length - 1;

      const newCurves = curves.filter((curve: Curve, index: number) => {
        return index < lastIndex;
      });

      setTempCurves(newCurves);
      setSelectedCurveIndex(null);

      props.onLinesChange({ curves: newCurves, closePath: close }, true);
    }
  };

  const updateCurve = useCallback(
    (newCurve: Curve, curveIndex: number) => {
      if (selectedCurveIndex === null) return;
      if (selectedCurveIndex < curves.length) {
        const newCurves = curves.map((curve: Curve, index: number) => {
          if (index === curveIndex) return newCurve;
          else return curve;
        });
        setTempCurves(newCurves);
      }
    },
    [curves, selectedCurveIndex]
  );

  // Maybe we don't need this??
  const setPosition = useCallback(
    (position: Point) => {
      if (props.rotation !== 0) {
        const pos = ShapeOperations.getRotatedPosition(
          position,
          rotationCenter,
          props.rotation
        );
        setDownPosition(pos);
      }
      setDownPosition(position);
    },
    [props.rotation, rotationCenter]
  );

  const addPoint = useCallback(
    (position: Point) => {
      if (props.update) return;

      setCreatingLine(true);
      setShowFinishOpenPath(false);

      // Get the position relative to the 0-rotation in case the shape is rotated
      let pos = { ...position };
      if (props.rotation !== 0) {
        pos = ShapeOperations.getRotatedPosition(
          pos,
          rotationCenter,
          props.rotation
        );
      }

      const startingPoint =
        curves.length > 0 && !isTouchDevice
          ? curves[curves.length - 1].p2
          : pos;

      const newCurve = ShapeOperations.getStraightCurve(
        startingPoint,
        startingPoint
      );
      // Initial curve is just a dot, but the last point is updated when the mouse is moved
      setCurveStart(startingPoint);
      setSelectedCurveIndex(curves.length);
      let newCurves = [...curves, newCurve];

      // For mouse events the end point of the curve is updated when the mouse is moving, but for
      // touch devices, the point is set when new curve is added
      if (isTouchDevice && curves.length > 0) {
        // Update previous curve

        const previousCurve = curves[curves.length - 1];
        const updatedPreviousCurves = ShapeOperations.getStraightCurve(
          previousCurve.p1,
          startingPoint
        );
        newCurves = curves.map((curve: Curve, index: number) => {
          if (index === curves.length - 1) return updatedPreviousCurves;
          return curve;
        });
        newCurves.push(newCurve);
        // if (curves.length === 1) {
        //   if (BoardConversion.isPointsEqual(curves[0].p1, curves[0].p2)) {
        //     newCurves = [newCurve];
        //   }
        // }
      }

      setTempCurves(newCurves);
      setDownPosition(null);

      finishTimeoutRef.current = setTimeout(() => {
        setShowFinishOpenPath(true);
      }, 200);

      // New
      // Let parent know every time a point is added (this is needed to fix the points on the area to avoid moving wheen zooming)
      onLinesChange({ curves: newCurves, closePath: false }, false);
      return newCurves;
    },
    [
      curves,
      isTouchDevice,
      onLinesChange,
      props.rotation,
      props.update,
      props.visibleRectangle,
      rotationCenter,
    ]
  );

  const releaseHandle = useCallback(() => {
    if (
      props.update &&
      curvePoint !== CurvePoint.NONE &&
      selectedCurveIndex !== null
    ) {
      setCurvePoint(CurvePoint.NONE);

      // Moving a handle might have modified the center. Shift all points to make the shape visually
      // stay in position
      let shiftedCurves = curves;
      if (props.rotation !== 0) {
        const newCenter = ShapeOperations.getLineShapeCenter(curves);
        const rotatedNewCenter = ShapeOperations.getRotatedPosition(
          newCenter,
          rotationCenter,
          -props.rotation
        );
        const diff = CanvasOperations.getPointSubtracted(
          rotatedNewCenter,
          newCenter,
          2
        );
        shiftedCurves = curves.map((curve: Curve) => {
          return {
            p1: CanvasOperations.getPointSum(curve.p1, diff, 2),
            h1: CanvasOperations.getPointSum(curve.h1, diff, 2),
            p2: CanvasOperations.getPointSum(curve.p2, diff, 2),
            h2: CanvasOperations.getPointSum(curve.h2, diff, 2),
          } as Curve;
        });
      }

      setSelectedCurveIndex(null);

      const closePath = shouldClosePath(shiftedCurves);
      const newClosePath =
        closePath !== undefined ? closePath : props.curves.closePath;

      onLinesChange({ curves: shiftedCurves, closePath: newClosePath }, true); // TODO: Should item store this (undo history)?
      if (onEditingChange) {
        onEditingChange(false);
      }
    }
  }, [
    curvePoint,
    curves,
    onLinesChange,
    onEditingChange,
    props.curves.closePath,
    props.rotation,
    props.update,
    rotationCenter,
    selectedCurveIndex,
  ]);

  /**
   * Updates a curve point. This function does nothing if the path is being created.
   */
  const updateCurvePoint = useCallback(
    (position: Point) => {
      if (
        props.update &&
        curvePoint !== CurvePoint.NONE &&
        selectedCurveIndex !== null
      ) {
        if (onEditingChange) {
          onEditingChange(true);
        }
        // Get the position relative to the 0-rotation in case the shape is rotated
        let pos = { ...position };
        if (props.rotation !== 0) {
          pos = ShapeOperations.getRotatedPosition(
            position,
            rotationCenter,
            props.rotation
          );
        }
        const index = selectedCurveIndex;
        const handle = curvePoint;

        if (handle === CurvePoint.START) {
          // Check snapping
          if (props.snapEnabled) {
            const snapPoint = ShapeOperations.getAngleBasedSnapPoint(
              pos,
              index,
              handle,
              curves,
              snapTolerance
            );
            if (snapPoint !== null) {
              pos = snapPoint;
            }
          }

          // Move handles together with points
          const diff = CanvasOperations.getPointSubtracted(
            pos,
            curves[index].p1
          );

          const newCurve = {
            ...curves[index],
            p1: pos,
            h1: CanvasOperations.getPointSum(curves[index].h1, diff),
          };
          // Also update end on previous curve
          let newCurvePrev: Curve | undefined = undefined;
          let copyCurveIndex: number | undefined = undefined;
          if (index > 0) {
            newCurvePrev = {
              ...curves[index - 1],
              p2: pos,
              h2: CanvasOperations.getPointSum(curves[index - 1].h2, diff),
            };
            copyCurveIndex = index - 1;
          }
          // Update last point on last curve (if selected point is first point on first curve)
          else if (props.curves.closePath) {
            newCurvePrev = {
              ...curves[curves.length - 1],
              p2: pos,
              h2: CanvasOperations.getPointSum(
                curves[curves.length - 1].h2,
                diff
              ),
            };
            copyCurveIndex = curves.length - 1;
          }
          const newCurves = curves.map((curve: Curve, cIndex: number) => {
            if (index === cIndex) return newCurve;
            else if (cIndex === copyCurveIndex && newCurvePrev !== undefined) {
              return newCurvePrev;
            } else return curve;
          });
          setTempCurves(newCurves);
        } else if (handle === CurvePoint.END) {
          // Check snapping
          if (props.snapEnabled) {
            const snapPoint = ShapeOperations.getAngleBasedSnapPoint(
              pos,
              index,
              handle,
              curves,
              snapTolerance
            );
            if (snapPoint !== null) {
              pos = snapPoint;
            }
          }
          // Move handles together with points
          const diff = CanvasOperations.getPointSubtracted(
            pos,
            curves[index].p2
          );
          const newCurve = {
            ...curves[index],
            p2: pos,
            h2: CanvasOperations.getPointSum(curves[index].h2, diff),
          };
          // Also update end on previous curve
          let newCurveNext: Curve | undefined = undefined;
          let copyCurveIndex: number | undefined = undefined;
          if (index < curves.length - 1) {
            newCurveNext = {
              ...curves[index + 1],
              p1: pos,
              h2: CanvasOperations.getPointSum(curves[index + 1].h2, diff),
            };
            copyCurveIndex = index + 1;
          }
          // Update first point on first curve (if select point is last point on last curve)
          else if (props.curves.closePath) {
            newCurveNext = {
              ...curves[0],
              p1: pos,
              h1: CanvasOperations.getPointSum(curves[0].h1, diff),
            };
            copyCurveIndex = 0;
          }
          const newCurves = curves.map((curve: Curve, cIndex: number) => {
            if (index === cIndex) return newCurve;
            else if (cIndex === copyCurveIndex && newCurveNext !== undefined) {
              return newCurveNext;
            } else return curve;
          });

          setTempCurves(newCurves);
        } else if (handle === CurvePoint.START_HANDLE) {
          const newCurve = {
            ...curves[index],
            h1: pos,
          };
          updateCurve(newCurve, index);
        } else if (handle === CurvePoint.END_HANDLE) {
          const newCurve = {
            ...curves[index],
            h2: pos,
          };
          updateCurve(newCurve, index);
        }
      }
    },
    [
      props.update,
      props.rotation,
      props.snapEnabled,
      props.curves.closePath,
      curvePoint,
      selectedCurveIndex,
      onEditingChange,
      rotationCenter,
      curves,
      updateCurve,
    ]
  );

  const finishClosedPath = () => {
    let newCurves = curves;
    // Update last curve if touch device, since the points are not updated on move
    if (isTouchDevice) {
      const lastCurve = curves[curves.length - 1];
      const updatedLastCurve = ShapeOperations.getStraightCurve(
        lastCurve.p1,
        curves[0].p1
      );
      newCurves = curves.map((curve: Curve, index: number) => {
        if (index === curves.length - 1) return updatedLastCurve;
        return curve;
      });
    }
    // setClosePath(true);
    // Need to keep the last path to be able to provide the curve handles (otherwise it will always just be a straight line)
    setCreatingLine(false);
    finishAddingLines(newCurves);
  };

  const finishOpenPath = () => {
    setCreatingLine(false);
    // if (isTouchDevice) {
    //   finishAddingLines(curves);
    // } else {
    removeLastCurveAndFinishAddingLines(false);
    // }
  };

  /**
   * Updates the path with the new point. This function does nothing if an existing handle is being updated.
   */
  const updatePath = useCallback(
    (position: Point) => {
      let snap = false;
      // Need to check the toggling downposition to make sure the state variables has been updated
      // Otherwise this function will cause the curves to be overwritten before the new curve is
      // actually added.
      if (selectedCurveIndex === null || downPosition !== null) {
        return;
      }
      // Drawing initial (straight) curve
      if (
        !props.update &&
        curveStart !== null &&
        selectedCurveIndex < curves.length
      ) {
        // Get the position relative to the 0-rotation in case the shape is rotated
        if (props.rotation !== 0) {
          position = ShapeOperations.getRotatedPosition(
            position,
            rotationCenter,
            props.rotation
          );
        }

        // Snap if mouse is near starting point in path, and at least 2 points (1 curve) are already drawn (line back and forth)
        // This should happen even if snap is diabled
        if (curves.length > 1) {
          const distance = CanvasOperations.calculateDistance(
            curves[0].p1,
            position
          );
          if (distance < snapTolerance) {
            position = curves[0].p1;
            snap = true;
          }
        }

        // Snap if mouse is near end point in path (if released here it will finish adding to the path)
        if (curves.length > 1) {
          const prevIndex = curves.length - 2;
          const distance = CanvasOperations.calculateDistance(
            curves[prevIndex].p2,
            position
          );
          if (distance < snapTolerance) {
            position = curves[prevIndex].p2;
            snap = true;
          }
        }

        // Snap if line to point is near the perpendicular line of previous or next point (helps to draw )
        if (!snap && props.snapEnabled) {
          const snapPoint = ShapeOperations.getAngleBasedSnapPoint(
            position,
            curves.length - 1,
            CurvePoint.NONE,
            curves,
            snapTolerance
          );
          if (snapPoint !== null) {
            position = snapPoint;
            snap = true;
          }
        }

        const newCurve = ShapeOperations.getStraightCurve(curveStart, position);
        updateCurve(newCurve, selectedCurveIndex);
      }
    },
    [
      curveStart,
      curves,
      downPosition,
      props.rotation,
      props.update,
      rotationCenter,
      selectedCurveIndex,
      props.snapEnabled,
      updateCurve,
    ]
  );

  const handlePointSelected = (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    event: any,
    index: number,
    handle: CurvePoint
  ) => {
    if (props.onClick) props.onClick();
    if (event) {
      event.stopPropagation();
    }
    if (props.update) {
      setSelectedCurveIndex(index);
      setCurvePoint(handle);
    }
  };

  /**
   * Starts dragging mode and stores the initial position.
   */
  const startDragging = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (position?: Point, event?: any) => {
      if (props.onClick) props.onClick();
      // Cannot drag while creating the item or curve point is selected
      if (!props.update || curvePoint !== CurvePoint.NONE || isDragging) {
        return;
      }
      if (props.disabled) return;
      if (event) {
        event.stopPropagation();
      }
      let pos: Point;
      if (position !== undefined) {
        pos = { ...position };
      } else if (event !== undefined) {
        pos = CanvasOperations.getPointSubtracted(
          EventProcess.getEventPosition(event),
          props.canvasOffset
        );
      } else {
        throw Error("Must set either position or event to start dragging");
      }
      if (props.rotation !== 0) {
        pos = ShapeOperations.getRotatedPosition(
          pos,
          rotationCenter,
          props.rotation
        );
      }
      setDragStart(pos);
      setIsDragging(true);
      if (onEditingChange) {
        onEditingChange(true);
      }
      if (isTouchDevice) {
        timeoutRef.current = setTimeout(() => {
          if (pos !== undefined && onLongTouch) {
            onLongTouch(EventProcess.getEventPosition(event));

            // This should also release the event
            Mouse.release.next({
              position: pos,
              pressed: false,
              rightPressed: false,
              elementClass: EventProcess.getClass(event),
              positions: [pos],
            });
          }
        }, longTouchTime);
      }
    },
    [
      props,
      curvePoint,
      isDragging,
      onEditingChange,
      rotationCenter,
      onLongTouch,
      isTouchDevice,
    ]
  );

  /**
   * Updates the shape accoring to the new position.
   */
  const dragShape = useCallback(
    (position: Point) => {
      if (isDragging) {
        let pos = { ...position };
        if (props.rotation !== 0) {
          pos = ShapeOperations.getRotatedPosition(
            position,
            rotationCenter,
            props.rotation
          );
        }
        const diff = CanvasOperations.getPointSubtracted(pos, dragStart);
        const newCurves = ShapeOperations.moveCurvedLinePath(
          props.curves.curves,
          diff
        );
        setTempCurves(newCurves);
      }
      // Clear long touch timeout if touch was moved (more than 2 px)
      if (timeoutRef.current !== null) {
        const pos = EventProcess.getEventPosition(event);
        const dist = CanvasOperations.calculateDistance(dragStart, pos);
        if (dist > 2) {
          clearTimeout(timeoutRef.current);
        }
      }
    },
    [dragStart, isDragging, props.curves.curves, props.rotation, rotationCenter]
  );

  /**
   * Exits dragging mode and notifies parent with new position.
   */
  const finishDragging = useCallback(() => {
    if (isDragging) {
      setIsDragging(false);

      if (props.rotation !== 0) {
        // The center will be re-calculated when the move is received in the parent - move the
        // curves the last bit so the positions fit the center
        const newRotationCenter = ShapeOperations.getLineShapeCenter(curves);
        const newRotationCenterAfterDrag = ShapeOperations.getRotatedPosition(
          newRotationCenter,
          rotationCenter,
          -props.rotation
        );
        // Calculate the difference between the new rotation centers (one for standard coordinates and one for rotated coordinates)
        const diff = CanvasOperations.getPointSubtracted(
          newRotationCenterAfterDrag,
          newRotationCenter
        );

        const newCurves = ShapeOperations.moveCurvedLinePath(curves, diff);
        onLinesChange(
          { curves: newCurves, closePath: props.curves.closePath },
          true
        );
      } else {
        onLinesChange(
          { curves: curves, closePath: props.curves.closePath },
          true
        );
      }
      if (onEditingChange) {
        onEditingChange(false);
      }
    }

    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  }, [
    curves,
    isDragging,
    onLinesChange,
    onEditingChange,
    props.curves.closePath,
    props.rotation,
    rotationCenter,
  ]);

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const getPatternStartPosition = () => {
    if (curves.length > 0) return curves[0].p1;
    else return { x: 0, y: 0 };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const triggerRightClick = (event: any) => {
    if (Device.isTouchDevice()) return;
    const pos = EventProcess.getEventPosition(event);
    if (props.onRightClicked) props.onRightClicked(pos);
  };

  // Update internal states when parent curves has changed
  useEffect(() => {
    // setTempCurves(props.curves.curves);
    // setRotationCenter(Shapes.getLineShapeCenter(props.curves.curves));
    setTempCurves(undefined);
    // Update curveStart to match the latest point (might have changed due to zoom)
    if (props.curves.curves.length > 0) {
      const last = props.curves.curves.length - 1;
      setCurveStart(props.curves.curves[last].p2);
    }
    // OBS: For some reason the shape is not redrawn until the mouse has moved...
  }, [props.curves]);

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      if (props.disabled) return;
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      if (state.pressed) {
        if (state.elementClass === "creator") {
          if (props.update && curves.length > 0) {
            startDragging(pos);
          }
        }
        updateCurvePoint(pos);
        dragShape(pos);
      } else {
        updatePath(pos);
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      if (props.disabled) return;
      if (
        CanvasOperations.isPointWithinRectangle(
          state.position,
          props.visibleRectangle
        )
      ) {
        const pos = CanvasOperations.getPointSubtracted(
          state.position,
          props.canvasOffset
        );
        setPosition(pos);
      }
    });

    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      if (props.disabled) return;
      if (
        !CanvasOperations.isPointWithinRectangle(
          state.position,
          props.visibleRectangle
        )
      ) {
        return;
      }
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      // Release (in debug mode this might not be detected for fast press-release events)
      // Do not add a new point if the mouse was released over a check circle
      if (state.elementClass !== "check-circle-content") {
        addPoint(pos);
        releaseHandle();
        finishDragging();
      }
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    addPoint,
    props.canvasOffset,
    curves,
    dragShape,
    finishDragging,
    props.update,
    releaseHandle,
    setPosition,
    startDragging,
    updateCurvePoint,
    updatePath,
    props.disabled,
    props.visibleRectangle,
  ]);

  return (
    <>
      {(curves.length > 0 || props.update) && (
        <g
          id="line-creator"
          transform={
            "rotate(" +
            props.rotation +
            ", " +
            rotationCenter.x +
            ", " +
            rotationCenter.y +
            ")"
          }
        >
          <defs>
            <ShapePattern
              zoom={props.zoom}
              color={props.style.fillColor}
              startPosition={getPatternStartPosition()}
              id={"line-creator"}
              pattern={props.style.fillPattern}
            />
          </defs>
          <path
            d={props.shapeType === ShapeType.CURVED_LINE ? path : straightPath}
            strokeWidth={props.lineWidth} // 1 px
            stroke={ShapeOperations.getStroke(props.style)}
            strokeOpacity={ShapeOperations.getStrokeOpacity()}
            strokeDasharray={ShapeOperations.getStrokeDashArray(
              props.style,
              props.lineWidth
            )}
            fill={ShapeOperations.getFill(props.style, "line-creator")}
            fillOpacity={
              props.curves.closePath || creatingLine
                ? ShapeOperations.getOpacity(props.style)
                : 0
            }
            cursor={props.cursor || "move"}
            onMouseDown={(event) => startDragging(undefined, event)}
            onTouchStart={(event) => startDragging(undefined, event)}
            onContextMenu={triggerRightClick}
            className={props.className}
          />
          <path
            d={props.shapeType === ShapeType.CURVED_LINE ? path : straightPath}
            strokeWidth={lineWidthGrab} // 1 px
            stroke={"red"}
            strokeOpacity={0}
            fill={"none"}
            cursor={props.cursor || "move"}
            onMouseDown={(event) => startDragging(undefined, event)}
            onTouchStart={(event) => startDragging(undefined, event)}
            onContextMenu={triggerRightClick}
            className={props.className}
          />

          {/* <circle cx={testPoint.x} cy={testPoint.y} r={grapSizePx} fill={"red"} /> */}

          {dimensions.map((dimension: DimensionProperties, index: number) => (
            <ShapeDimension
              key={"dim-" + index}
              start={dimension.start}
              end={dimension.end}
              zoom={props.zoom}
              lineWidth={props.lineWidth}
              style={props.dimensionStyle}
              showArrow={
                props.update && props.shapeType === ShapeType.CURVED_LINE
              }
              cursor={props.cursor}
              uniqueId="line-creator"
              delimiter={props.delimiter}
              unitScale={props.unitScale}
            />
          ))}

          {props.update && props.shapeType === ShapeType.CURVED_LINE && (
            <path
              d={helperLinesPath}
              strokeWidth={props.lineWidth} // 1 cm
              stroke={"red"}
              fill={"none"}
              className={props.className}
            />
          )}
          {/* {props.update && */}
          {curves.map((curve: Curve, index: number) => (
            <g key={index.toString()}>
              <circle
                key={index.toString() + "p1"}
                cx={curve.p1.x}
                cy={curve.p1.y}
                r={grapSizePx}
                fill={"white"}
                style={{ cursor: props.cursor || "pointer" }}
                stroke="grey"
                strokeWidth={0.5}
                onMouseDown={(event) =>
                  handlePointSelected(event, index, CurvePoint.START)
                }
                onTouchStart={(event) =>
                  handlePointSelected(event, index, CurvePoint.START)
                }
                className={props.className}
              />
              {(index < curves.length - 1 || props.update) && (
                <circle
                  key={index.toString() + "p2"}
                  cx={curve.p2.x}
                  cy={curve.p2.y}
                  r={grapSizePx}
                  fill={"white"}
                  style={{ cursor: props.cursor || "pointer" }}
                  stroke="grey"
                  strokeWidth={0.5}
                  onMouseDown={(event) =>
                    handlePointSelected(event, index, CurvePoint.END)
                  }
                  onTouchStart={(event) =>
                    handlePointSelected(event, index, CurvePoint.END)
                  }
                  className={props.className}
                />
              )}
              {props.shapeType === ShapeType.CURVED_LINE && props.update && (
                <>
                  <circle
                    key={index.toString() + "h1"}
                    cx={curve.h1.x}
                    cy={curve.h1.y}
                    r={grapSizePx}
                    fill={"white"}
                    stroke="grey"
                    strokeWidth={0.5}
                    style={{ cursor: props.cursor || "pointer" }}
                    onMouseDown={(event) =>
                      handlePointSelected(event, index, CurvePoint.START_HANDLE)
                    }
                    onTouchStart={(event) =>
                      handlePointSelected(event, index, CurvePoint.START_HANDLE)
                    }
                    className={props.className}
                  />
                  <circle
                    key={index.toString() + "h2"}
                    cx={curve.h2.x}
                    cy={curve.h2.y}
                    r={grapSizePx}
                    fill={"white"}
                    stroke="grey"
                    strokeWidth={0.5}
                    style={{ cursor: props.cursor || "pointer" }}
                    onMouseDown={(event) =>
                      handlePointSelected(event, index, CurvePoint.END_HANDLE)
                    }
                    onTouchStart={(event) =>
                      handlePointSelected(event, index, CurvePoint.END_HANDLE)
                    }
                    className={props.className}
                  />
                </>
              )}
            </g>
          ))}
          {!props.update && (
            <>
              {(curves.length > 2 ||
                (curves.length > 1 &&
                  props.shapeType === ShapeType.CURVED_LINE)) && (
                <CheckCircle
                  uniqueId="snap-first"
                  radius={snapTolerance}
                  position={curves[0].p1}
                  onClick={finishClosedPath}
                />
              )}
              {curves.length > 1 && showFinishOpenPath && (
                <CheckCircle
                  uniqueId="snap-last"
                  radius={snapTolerance}
                  position={curves[curves.length - 2].p2}
                  onClick={finishOpenPath}
                />
              )}
            </>
          )}
          {/* <circle
            cx={rotationCenter.x}
            cy={rotationCenter.y}
            r="3"
            fill="red"
          /> */}
        </g>
      )}
    </>
  );
};
