import { useTranslation } from "react-i18next";
import { RightSidebar, Sidebar } from "../../components";
import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  LayoutSelector,
} from "../../store";
import { ChecklistContainer, SupportContainer } from "..";

export const RightSidebarContainer = () => {
  const { t } = useTranslation();
  const open = useAppSelector(LayoutSelector.getSidebarRightOpen);
  const activeTab = useAppSelector(LayoutSelector.getSidebarRightActiveTab);
  const dispatch = useAppDispatch();

  const toggle = (tab: string) => {
    if (activeTab === tab) {
      dispatch(LayoutActions.setSidebarRightActiveTab(""));
    } else {
      dispatch(LayoutActions.setSidebarRightActiveTab(tab));
    }
  };

  const sidebarTabs = [
    {
      id: "support",
      title: t("support"),
      icon: "question",
      onClick: () => {
        toggle("support");
      },
    },
    {
      id: "checklist",
      title: t("checklist"),
      icon: "list-unordered",
      onClick: () => {
        toggle("checklist");
      },
    },
  ];
  return (
    <Sidebar
      open={open}
      tabs={sidebarTabs}
      activeTab={activeTab}
      position="right"
      elementId="right-sidebar"
    >
      <SupportContainer visible={activeTab === "support"} />
      <ChecklistContainer visible={activeTab === "checklist"} />
    </Sidebar>
  );
};
