import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Device } from "imagine-ui";
import {
  CanvasOperations,
  DimensionProperties,
  EventProcess,
  ShapeStyle,
  Mouse,
  MouseState,
} from "draw";
import { Delimiter, Point } from "imagine-essentials";
import { ShapeDimension } from ".";
import { UnitScale } from "project";

// If rectangle width and height is smaller than this when created,
// the creator will exit immediately without creating the rectangle
const minimimSize = 2;

// Radius of the transparant grab circle at the dimension ends
// const grabSizePx = 6;
const grabSizePx = Device.isTouchDevice() ? 12 : 6;
const longTouchTime = 600;

enum MarkerPoint {
  NONE,
  START,
  END,
}

interface Props {
  lineWidth: number;
  style: ShapeStyle;
  dimension: DimensionProperties;
  shapeId: number;
  onEditingStarted?: () => void;
  onChanged: (shape: DimensionProperties) => void;
  update: boolean;
  onFinished: () => void;
  onRightClicked?: (position: Point) => void;
  onLongTouch?: (pos: Point) => void;
  onClick?: () => void;
  className?: string;
  canvasOffset: Point;
  zoom: number;
  disabled?: boolean; // Will not trigger on mouse events if set to true
  delimiter: Delimiter;
  unitScale: UnitScale;
}

/**
 * Creator of dimension shapes directly on the board.
 */
export const DimensionCreator = (props: Props) => {
  const { onEditingStarted, onChanged, onFinished, onClick, onLongTouch } =
    props;
  const [tempDimension, setTempDimension] = useState<
    DimensionProperties | undefined
  >(props.dimension);
  const dimension = useMemo(() => {
    if (tempDimension !== undefined) return tempDimension;
    else return props.dimension;
  }, [tempDimension, props.dimension]);

  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  const [startingPoint, setStartingPoint] = useState<Point | null>(null);
  const [resizeStart, setResizeStart] = useState<Point | null>(null);
  const [resizeMarkerPoint, setResizeMarkerPoint] = useState<MarkerPoint>(
    MarkerPoint.NONE
  );
  const [dragStart, setDragStart] = useState<Point | null>(null);
  const [isDragging, setIsDragging] = useState(false);

  const startDrawing = useCallback(
    (position: Point) => {
      if (!props.update) {
        setStartingPoint(position);
      }
    },
    [props.update]
  );

  const updateShape = useCallback(
    (position: Point) => {
      if (startingPoint !== null) {
        setTempDimension({
          start: startingPoint,
          end: position,
        } as DimensionProperties);
      }
    },
    [startingPoint]
  );

  const finishDrawing = useCallback(() => {
    // The dimension was already set on last mouse move
    if (
      startingPoint !== null ||
      resizeMarkerPoint !== MarkerPoint.NONE ||
      dragStart !== null
    ) {
      setStartingPoint(null);
      setResizeMarkerPoint(MarkerPoint.NONE);
      setDragStart(null);
      setIsDragging(false);
      // Finish the editor without adding the item if distance is too small
      if (
        CanvasOperations.calculateDistance(dimension.start, dimension.end) <
        minimimSize
      ) {
        onFinished();
      } else {
        onChanged(dimension);
        onFinished();
      }
    }
  }, [
    dimension,
    dragStart,
    onChanged,
    onFinished,
    resizeMarkerPoint,
    startingPoint,
  ]);

  const beginResizing = useCallback(
    (corner: MarkerPoint, position?: Point, event?: any) => {
      if (onClick) onClick();
      if (props.disabled) return;
      if (event) {
        event.stopPropagation();
      }
      if (props.update) {
        if (onEditingStarted) {
          onEditingStarted();
        }
        if (position !== undefined) {
          setResizeStart(position);
        } else if (event !== undefined) {
          const pos = CanvasOperations.getPointSubtracted(
            EventProcess.getEventPosition(event),
            props.canvasOffset
          );
          setResizeStart(pos);
        }
        setResizeMarkerPoint(corner);
      }
    },
    [
      onClick,
      props.disabled,
      props.update,
      props.canvasOffset,
      onEditingStarted,
    ]
  );

  const resizeDimension = useCallback(
    (position: Point) => {
      if (resizeStart !== null && resizeMarkerPoint !== MarkerPoint.NONE) {
        if (resizeMarkerPoint === MarkerPoint.START) {
          setTempDimension({
            start: position,
            end: dimension.end,
          });
        } else if (resizeMarkerPoint === MarkerPoint.END) {
          setTempDimension({
            start: dimension.start,
            end: position,
          });
        }
      }
    },
    [dimension, resizeMarkerPoint, resizeStart]
  );

  const startDragging = useCallback(
    (event?: any, position?: Point) => {
      if (onClick) onClick();
      if (props.disabled) return;
      if (event) {
        event.stopPropagation();
      }
      if (isDragging || resizeMarkerPoint !== MarkerPoint.NONE) return;
      if (position !== undefined) setDragStart(position);
      else if (event !== undefined) {
        const pos = CanvasOperations.getPointSubtracted(
          EventProcess.getEventPosition(event),
          props.canvasOffset
        );
        setDragStart(pos);

        timeoutRef.current = setTimeout(() => {
          if (pos !== undefined && onLongTouch) {
            if (onLongTouch) {
              onLongTouch(EventProcess.getEventPosition(event));
            }
            // This should also release the event
            Mouse.release.next({
              position: pos,
              pressed: false,
              rightPressed: false,
              elementClass: EventProcess.getClass(event),
              positions: [pos],
            });
          }
        }, longTouchTime);
      }
      setIsDragging(true);
    },
    [
      onClick,
      props.disabled,
      props.canvasOffset,
      isDragging,
      resizeMarkerPoint,
      onLongTouch,
    ]
  );

  /**
   * Move the dimension according to the mouse change. Nothing happens if the mouseStart variable is
   * not set.
   */
  const moveDimension = useCallback(
    (position: Point) => {
      if (resizeMarkerPoint !== MarkerPoint.NONE) return;
      if (dragStart !== null) {
        // const pos = BoardConversion.getEventPosition(event);
        const diff = CanvasOperations.getPointSubtracted(position, dragStart);
        const newDimension = {
          start: CanvasOperations.getPointSum(props.dimension.start, diff, 3),
          end: CanvasOperations.getPointSum(props.dimension.end, diff, 3),
        };
        setTempDimension(newDimension);

        // Clear long touch timeout if touch was moved (more than 2 px)
        if (timeoutRef.current !== null) {
          const pos = EventProcess.getEventPosition(event);
          const dist = CanvasOperations.calculateDistance(dragStart, pos);
          if (dist > 2) {
            clearTimeout(timeoutRef.current);
          }
        }
      }
    },
    [dragStart, props.dimension.end, props.dimension.start, resizeMarkerPoint]
  );

  // Clear timeout on unmount
  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const triggerRightClick = (event: any) => {
    if (props.disabled) return;
    if (Device.isTouchDevice()) return;
    if (event) {
      event.stopPropagation();
    }
    const pos = EventProcess.getEventPosition(event);
    if (props.onRightClicked) props.onRightClicked(pos);
  };

  const getMarkerColor = () => {
    if (
      resizeMarkerPoint !== MarkerPoint.NONE ||
      dragStart !== null ||
      !props.update
    )
      return "transparent";
    else return "white";
  };

  const getMarkerStrokeColor = () => {
    if (
      resizeMarkerPoint !== MarkerPoint.NONE ||
      dragStart !== null ||
      !props.update
    )
      return "transparent";
    else return "grey";
  };

  useEffect(() => {
    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      if (state.pressed) {
        if (props.update) {
          resizeDimension(pos);
          if (state.elementClass !== "dimension-resize-handle") {
            if (state.elementClass === "creator") {
              // Drag initially
              startDragging(undefined, pos);
            }
            moveDimension(pos);
          }
        } else {
          updateShape(pos);
        }
      }
    });
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      const pos = CanvasOperations.getPointSubtracted(
        state.position,
        props.canvasOffset
      );
      startDrawing(pos);
    });

    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      finishDrawing();
      // Clear timeout on unmount

      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
      pressObserver.unsubscribe();
    };
  }, [
    props.canvasOffset,
    finishDrawing,
    moveDimension,
    props.update,
    resizeDimension,
    startDragging,
    startDrawing,
    updateShape,
  ]);

  useEffect(() => {
    setTempDimension(undefined);
  }, [props.dimension]);

  return (
    <>
      {(startingPoint !== null || props.update) && (
        <g id="dimension-creator">
          <ShapeDimension
            start={dimension.start}
            end={dimension.end}
            zoom={props.zoom}
            lineWidth={props.lineWidth}
            style={props.style}
            showArrow={true}
            uniqueId="dimension-creator"
            delimiter={props.delimiter}
            unitScale={props.unitScale}
          />
          <line
            x1={dimension.start.x}
            y1={dimension.start.y}
            x2={dimension.end.x}
            y2={dimension.end.y}
            strokeWidth={props.lineWidth * 10}
            stroke={"transparent"}
            cursor="move"
            onMouseDown={startDragging}
            onTouchStart={startDragging}
            onContextMenu={triggerRightClick}
            className={props.className}
          />

          <circle
            cx={dimension.start.x}
            cy={dimension.start.y}
            r={grabSizePx}
            fill={getMarkerColor()}
            stroke={getMarkerStrokeColor()}
            strokeWidth={0.5}
            onMouseDown={(event) =>
              beginResizing(MarkerPoint.START, undefined, event)
            }
            onTouchStart={(event) =>
              beginResizing(MarkerPoint.START, undefined, event)
            }
            cursor={"pointer"}
            className="dimension-resize-handle"
          />
          <circle
            cx={dimension.end.x}
            cy={dimension.end.y}
            r={grabSizePx}
            fill={getMarkerColor()}
            stroke={getMarkerStrokeColor()}
            strokeWidth={0.5}
            onMouseDown={(event) =>
              beginResizing(MarkerPoint.END, undefined, event)
            }
            onTouchStart={(event) =>
              beginResizing(MarkerPoint.END, undefined, event)
            }
            cursor={"pointer"}
            className="dimension-resize-handle"
          />
        </g>
      )}
    </>
  );
};
