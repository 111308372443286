import { CanvasOperations, MapPosition } from "draw";
import { MapOperations } from "../../utils";
import { Point, Size } from "imagine-essentials";

/**
 * Calculates a new map center based on the current zero reference, zoom and the coordinates
 * supposed to be located at the zero reference.
 * @param zeroReference
 * @param zoom
 * @param canvasSize
 * @param zeroReferenceCoordinates
 * @returns
 */
const calculateMapCenter = (
  zeroReference: Point,
  zoom: number,
  canvasSize: Size,
  zeroReferenceCoordinates: MapPosition
) => {
  const boardCenter = CanvasOperations.getCanvasCenter(canvasSize, {
    x: 0,
    y: 0,
  });
  // Distance from new zero reference and center of board in pixels
  const distPx = CanvasOperations.getPointSubtracted(
    zeroReference,
    boardCenter
  );
  const distMeters = CanvasOperations.pixelToUnitPosition(
    distPx,
    zoom,
    { x: 0, y: 0 },
    6
  );
  const newMapCenter = MapOperations.getMapPositionOffset(
    zeroReferenceCoordinates,
    distMeters
  );
  return newMapCenter;
};

export const CanvasSliceHelpers = {
  calculateMapCenter,
};
