import { ObjectSchema, ObjectsApi } from "draw";
import { AppDispatch, CanvasActions, ObjectEditorActions } from "../store";
import { t } from "i18next";

const saveObject = async (
  dispatch: AppDispatch,
  currentObjectTemplate: ObjectSchema,
  showMessage: boolean = true
) => {
  let errorMessage = "";
  if (showMessage) {
    dispatch(CanvasActions.setLoadingMessage(t("savingObject")));
  }
  const response = await ObjectsApi.updateObjectTemplate(currentObjectTemplate);
  if (response.success && response.data) {
    dispatch(ObjectEditorActions.setSavedObjectTemplate(response.data));
  } else {
    errorMessage = t("objectSaveFailed");
  }
  if (showMessage) {
    dispatch(CanvasActions.setLoadingMessage(""));
  }
  return errorMessage;
};

export const ObjectThunks = {
  saveObject,
};
