import React, { useMemo } from "react";
import { Icon } from "imagine-ui";
// import '../../styling/navigation.css';

type Props = {
  selected?: boolean;
  disabled?: boolean;
  elementId?: string;
  onClick?: () => void;
  children?: React.ReactNode;
  className?: string; // Additional class on container div
};

/**
 * This displays a button customized for the header.
 * @param props
 */
export const ToolInfo = (props: Props) => {
  const className = useMemo(() => {
    let className = "tool-button info";

    if (props.selected) {
      className += " selected";
    }
    if (props.disabled) {
      className += " disabled";
    }
    return className;
  }, [props.disabled, props.selected]);

  const handleClick = () => {
    if (props.disabled) return;
    if (props.onClick) {
      props.onClick();
    }
  };

  return (
    <div
      className={
        "tool-container" + (props.className ? " " + props.className : "")
      }
    >
      <button id={props.elementId} className={className} onClick={handleClick}>
        {props.children}
      </button>
    </div>
  );
};
