import {
  CanvasActions,
  LayoutSelector,
  PlanEditorActions,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import { CanvasSelector } from "../../store";
import { CursorMode } from "../../enums";
import { Button, ButtonGroup, Device } from "imagine-ui";

/**
 * Displays tools on top of the canvas. Eg. cursor mode.
 * @param props
 * @returns
 */
export const CursorModeContainer = () => {
  const dispatch = useAppDispatch();
  const isTouchDevice = Device.isTouchDevice();

  const sidebarRightOpen = useAppSelector(LayoutSelector.getSidebarRightOpen);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);

  const updateCursorMode = (mode: CursorMode) => {
    dispatch(CanvasActions.setCursorMode(mode));
    if (mode === CursorMode.DRAG) {
      dispatch(PlanEditorActions.setSelectedItems([]));
    }
  };

  return (
    <div
      className={
        "canvas-tools-top-right" + (sidebarRightOpen ? " sidebar-open" : "")
      }
    >
      <ButtonGroup>
        <Button
          icon={isTouchDevice ? "select" : "cursor"}
          color="secondary"
          active={cursorMode === CursorMode.POINTER}
          onClick={() => updateCursorMode(CursorMode.POINTER)}
          elementId="cursor-mode-pointer"
        ></Button>
        <Button
          icon={isTouchDevice ? "touch" : "hand"}
          color="secondary"
          active={cursorMode === CursorMode.DRAG}
          onClick={() => updateCursorMode(CursorMode.DRAG)}
          elementId="cursor-mode-drag"
        ></Button>
      </ButtonGroup>
    </div>
  );
};
