import { UserActions, UserRole, UserSelector } from "imagine-users";
import { PlantLibrary } from "../../components";
import {
  LayoutActions,
  PlanEditorActions,
  PlanEditorSelector,
  PlantManagementActions,
  PlantManagementSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { useMemo, useState } from "react";
import { PlantCollection, PlantSearchFilter, PlantsApi } from "plants";
import { Option, TextUtils } from "imagine-essentials";
import { ItemType, PlantSchema } from "draw";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { Pages } from "../../enums";
import { useTranslation } from "react-i18next";
import { I18nTools } from "imagine-i18n";

interface Props {
  visible: boolean;
}

export const PlantLibraryContainer = (props: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const plants = useAppSelector(PlantManagementSelector.getSearchResult);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const plantCollections = useAppSelector(
    PlantManagementSelector.getPlantCollections
  );
  const [plantCollection, setPlantCollection] = useState<
    PlantCollection | undefined
  >(undefined);
  const [loading, setLoading] = useState(false);
  const selectedPlantTemplate = useAppSelector(
    PlanEditorSelector.getPlantStampTemplate
  );

  const searchResult = useAppSelector(PlantManagementSelector.getSearchResult);

  const openPlantFinder = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_FINDER));
  };

  const searchInPlantCollections = (name: string) => {
    if (plantCollection?.plants) {
      const plants = plantCollection.plants.filter((plant: PlantSchema) => {
        if (plant.name.toLowerCase().includes(name)) return true;
        if (plant.latinName?.toLowerCase().includes(name)) return true;
        if (plant.hybridName?.toLowerCase().includes(name)) return true;
        if (
          TextUtils.calculateLevenshteinDistance(
            plant.name.toLowerCase(),
            name
          ) < 2
        ) {
          return true;
        }
        if (
          TextUtils.calculateLevenshteinDistance(
            plant.latinName?.toLowerCase() || "",
            name
          ) < 2
        ) {
          return true;
        }
        if (
          TextUtils.calculateLevenshteinDistance(
            plant.hybridName?.toLowerCase() || "",
            name
          ) < 2
        ) {
          return true;
        }
        return false;
      });
      dispatch(PlantManagementActions.setSearchResult(plants));
    } else {
      dispatch(PlantManagementActions.setSearchResult([]));
    }
  };

  const search = async (name: string, excludePlantCollections?: boolean) => {
    if (plantCollection && !excludePlantCollections) {
      searchInPlantCollections(name.toLowerCase());
      return;
    }
    const filter: PlantSearchFilter = {
      name: name,
    };
    setLoading(true);
    const plantTemplates = await PlantsApi.search(filter);
    if (plantTemplates) {
      dispatch(PlantManagementActions.setSearchResult(plantTemplates));
    }
    setLoading(false);
  };

  const plantCollectionOptions = useMemo(() => {
    if (!hasPremiumAccess) return [];
    const allOption = {
      value: 0,
      label: t("plants:allPlants"),
    } as Option;

    const collections = plantCollections.map(
      (plantCollection: PlantCollection) => {
        return {
          value: plantCollection.id,
          label: plantCollection.name,
        } as Option;
      }
    );
    return [allOption, ...collections];
  }, [hasPremiumAccess, plantCollections, t]);

  const selectPlantCollection = (id: number, name: string) => {
    if (id === 0) {
      setPlantCollection(undefined);
      search(name, true);
      return;
    }
    const selectedCollection = plantCollections.find(
      (collection: PlantCollection) => {
        return collection.id === id;
      }
    );
    if (selectedCollection) {
      setPlantCollection(selectedCollection);
      if (selectedCollection) {
        dispatch(
          PlantManagementActions.setSearchResult(
            selectedCollection.plants || []
          )
        );
      }
    }
  };

  const selectPlantTemplate = (id?: number) => {
    if (id === undefined) {
      dispatch(PlanEditorActions.clearStamp());
    }
    const selectedTemplate = searchResult.find((template: PlantSchema) => {
      return template.id === id;
    });
    if (selectedTemplate !== undefined) {
      dispatch(PlanEditorActions.setStampTemplate(selectedTemplate));
      dispatch(PlanEditorActions.setStampType(ItemType.PLANT));
      dispatch(PlanEditorActions.setSelectedItems([]));
      if (plantCollection) {
        dispatch(UserActions.track(FeatureKey.SELECT_PLANT_FROM_COLLECTION));
      } else {
        dispatch(UserActions.track(FeatureKey.SELECT_PLANT_FROM_LIBRARY));
      }
    }
  };

  return (
    <>
      {props.visible && (
        <>
          {user.role !== UserRole.NONE && (
            <PlantLibrary
              plants={plants}
              onSearch={search}
              onAdvancedSearch={openPlantFinder}
              plantCollectionOptions={plantCollectionOptions}
              onSelectPlantCollection={selectPlantCollection}
              onSelectPlantTemplate={selectPlantTemplate}
              selectedPlantCollectionId={plantCollection?.id || 0}
              loading={loading}
              selectedPlantTemplateId={selectedPlantTemplate?.id}
              unitScale={userPreferences.unitScale}
              delimiter={delimiter}
              showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
            />
          )}
          {user.role === UserRole.NONE && (
            <p className="p text-sm">{t("loginToAccessPlantLibrary")}</p>
          )}
        </>
      )}
    </>
  );
};
