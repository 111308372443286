import { createAsyncThunk } from "@reduxjs/toolkit";
import { PlantCollectionsApi } from "plants";

/**
 * Fetch plant collections for the currently logged in user. This should only be called
 * if the user has premium access.
 */
export const fetchPlantCollections = createAsyncThunk(
  "plants/fetchPlantCollections",
  async (arg: number, thunkApi) => {
    return await PlantCollectionsApi.getPlantCollections(arg);
  }
);

export const PlantManagementThunks = {
  fetchPlantCollections,
};
