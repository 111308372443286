import { ObjectCategory, ObjectOperations } from "draw";
import { Label, Modal, Select, TextInput } from "imagine-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onSave: (name: string, category: ObjectCategory) => void;
  name: string;
  category: ObjectCategory | undefined;
  loading?: boolean;
  errorMessage?: string;
}

export const SaveObjectModal = (props: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState(props.name);
  const [category, setCategory] = useState(props.category);

  const categoryOptions = ObjectOperations.getCategoryOptions();

  const save = () => {
    if (category === undefined) return;
    props.onSave(name, category);
  };

  return (
    <Modal
      title={t("saveNewObject")}
      visible
      onClose={props.onClose}
      center
      confirmText={t("imagine:save")}
      loading={props.loading}
      onConfirm={save}
      confirmDisabled={name === "" || category === undefined}
    >
      <Label>{t("imagine:name")}:</Label>
      <TextInput value={name} onChange={setName} />
      <Label className="mt">{t("imagine:category")}</Label>
      <Select
        options={categoryOptions}
        selected={category}
        onSelectedNumber={setCategory}
      />
    </Modal>
  );
};
