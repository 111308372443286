/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  CanvasOperations,
  EventProcess,
  Model,
  Mouse,
  ShapeOperations,
  ShapeProperties,
  ShapeSvg,
} from "..";
import { Delimiter, Point, Tools } from "imagine-essentials";
import { Device } from "imagine-ui";
import { UnitScale } from "project";

const fontSizePx = 12;
const longTouchTime = 600;

type ModelSvgProps = {
  elementId?: string;
  model: Model;
  onMouseDown?: (pos: Point) => void;
  onDoubleClicked?: () => void;
  onRightClicked?: (pos: Point) => void;
  onLongTouch?: (pos: Point) => void;
  onTouchStart?: (pos: Point) => void;
  onCtrlClick?: () => void;
  selected?: boolean; /// Some shapes will display a little different if they are selected
  cursor?: string; // Set to override cursor on shapes
  className?: string;
  inactive?: boolean; // Will not stop event propagation when inactive
  propagateTouch?: boolean; // If component propagate touch events always
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Displays a SVG wrapper element containing all the passed shapes. The last shape
 * in the array is drawn on top. The viewbox is always 100x100 for plants.
 * @param props
 */
export const ModelSvg = (props: ModelSvgProps) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const [touchStartPos, setTouchStartPos] = useState({ x: 0, y: 0 });

  const getTextPxWidth = useCallback((text: string) => {
    const element = document.createElement("canvas");
    const context = element.getContext("2d");
    if (context !== null) {
      context.font = fontSizePx + "px Helvetica Neue";
      return context.measureText(text).width;
    }
    return 0;
  }, []);

  const pointsPerPixel = useMemo(() => {
    return ShapeOperations.pointsPerPixel(
      props.model.viewBox,
      props.model.size
    );
  }, [props.model.viewBox, props.model.size]);

  const viewSize = useMemo(() => {
    return ShapeOperations.getViewSize(props.model.viewBox);
  }, [props.model]);

  const labelSize = useMemo(() => {
    if (props.model.text !== undefined && props.model.text !== "") {
      const padding = 5;
      return {
        width: Tools.round(
          (getTextPxWidth(props.model.text) + 2 * padding) * pointsPerPixel.x,
          1
        ),
        height: Tools.round((fontSizePx + 2 * padding) * pointsPerPixel.x, 1),
      };
    }
    return { width: 0, height: 0 };
  }, [props.model, pointsPerPixel, getTextPxWidth]);

  const labelPos = useMemo(() => {
    return {
      x: viewSize.width / 2 - labelSize.width / 2,
      y: viewSize.height / 2 - labelSize.height / 2,
    };
  }, [labelSize, viewSize]);

  const textPos = useMemo(() => {
    return {
      x: viewSize.width / 2,
      y: viewSize.height / 2,
    };
  }, [viewSize]);

  const handleMouseDown = (event: any) => {
    console.log("Inactive:", props.inactive);
    if (!props.inactive) event.stopPropagation();
    // Touch devices should not trigger on mouse down
    if (EventProcess.isTouchEvent(event)) return;
    if (Device.isTouchDevice()) return;
    // Ignore right clicks which are also triggeren on mouse down
    if (!EventProcess.isLeftClick(event)) return;
    if (event.ctrlKey && props.onCtrlClick) {
      props.onCtrlClick();
    } else {
      if (props.onMouseDown) {
        props.onMouseDown({
          x: event.clientX,
          y: event.clientY,
        });
      }
    }
  };

  const handleDoubleClick = (event: any) => {
    if (!props.inactive) event.stopPropagation();
    if (props.onDoubleClicked) {
      props.onDoubleClicked();
    }
  };

  const handleRightClick = (event: any) => {
    if (!props.inactive) event.stopPropagation();
    // Long press is considered right click by default, but we don't want that behavior (because event is fired when touch is released, instead of when the hold-period is reached)
    if (EventProcess.isTouchEvent(event)) return;
    if (Device.isTouchDevice()) return;

    const pos = EventProcess.getEventPosition(event);

    if (!props.inactive) event.stopPropagation();
    if (props.onRightClicked) {
      props.onRightClicked(pos);
    }
  };

  const handleTouchEnd = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  };

  /**
   * Store initial touch position and start a timer to trigger if touch is not released
   */
  const handleTouchStart = (event: any) => {
    if (!props.inactive && !props.propagateTouch) {
      event.stopPropagation();
    }
    const positions = EventProcess.getTouchEventPositions(event);

    if (positions.length === 1) {
      const pos = positions[0];
      setTouchStartPos(pos);
      if (props.onTouchStart) {
        props.onTouchStart(pos);
      }
      timeoutRef.current = setTimeout(() => {
        if (props.onLongTouch) {
          props.onLongTouch(pos);
        }
        // This should also release the event
        Mouse.release.next({
          position: pos,
          pressed: false,
          rightPressed: false,
          elementClass: EventProcess.getClass(event),
          positions: positions,
        });
      }, longTouchTime);
    } else if (positions.length === 2) {
      // Clear long touch timeout if 2 fingers are touching
      handleTouchEnd();
    }
  };

  /**
   * Clear long touch timeout if touch was moved (more than 2 px)
   */
  const handleTouchMove = (event: any) => {
    if (timeoutRef.current !== null) {
      const pos = EventProcess.getEventPosition(event);
      const dist = CanvasOperations.calculateDistance(touchStartPos, pos);
      if (dist > 2) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  useEffect(() => {
    return () => {
      if (timeoutRef.current !== null) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  const cursor = useMemo(() => {
    if (props.cursor) return props.cursor;
    if (props.model.locked) {
      // THIS IS NOT TRIGGERED!!!!!
      return "default";
    } else return undefined;
  }, [props.cursor, props.model]);

  // Render depending on item type
  // SVG size is 1 px larger to fit better when zooming out (utilizes the full width then)

  return (
    <>
      {props.model.size.width > 0 && props.model.size.height > 0 && (
        <svg
          x={props.model.position.x - 0.5}
          y={props.model.position.y - 0.5}
          viewBox={props.model.viewBox}
          width={props.model.size.width + 1}
          height={props.model.size.height + 1}
          preserveAspectRatio="none"
          onMouseDown={handleMouseDown}
          onDoubleClick={handleDoubleClick}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
          onContextMenuCapture={handleRightClick}
          className={props.className}
          id={props.elementId}
        >
          {/* <rect
            x={0}
            y={0}
            width={props.model.size.width}
            height={props.model.size.width}
            fill="orange"
          /> */}
          {props.model.shapes.map((shape: ShapeProperties, index: number) => (
            <ShapeSvg
              key={index.toString()}
              uniqueId={props.model.id + "-" + index.toString()}
              shape={shape}
              pointPerPixel={pointsPerPixel}
              selected={props.selected}
              cursor={cursor}
              className={props.className}
              delimiter={props.delimiter}
              unitScale={props.unitScale}
            />
          ))}
          {props.model.text !== undefined && props.model.text !== "" && (
            <>
              <rect
                x={labelPos.x}
                y={labelPos.y}
                width={labelSize.width}
                height={labelSize.height}
                fill="white"
                style={{ cursor: cursor }}
                opacity={0.7}
                className={props.className}
              />
              <text
                textAnchor="middle"
                dominantBaseline="middle"
                x={textPos.x}
                y={textPos.y}
                fill="black"
                style={{ cursor: cursor }}
                fontSize={Tools.round(fontSizePx * pointsPerPixel.x, 1)}
                className={props.className}
                //transform={"rotate(" + center.x + " " + center.y + "," + angle + ")"}
              >
                {props.model.text}
              </text>
            </>
          )}
        </svg>
      )}
    </>
  );
};
