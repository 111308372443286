import { useState } from "react";
import { ToolButton } from ".";
import { Device } from "imagine-ui";

export interface ToolDropdownOption {
  value: number;
  label: string;
  icon?: JSX.Element;
}

interface Props {
  text?: string;
  options: ToolDropdownOption[];
  icon?: string; // Default icon to show on button when no option is selected
  selected?: number;
  onSelect?: (value: number) => void;
  className?: string;
  disabled?: boolean;
  elementId?: string;
  half?: boolean;
  layout: "list" | "grid" | "text-list";
}

/**
 * Toolbar button that displays a dropdown when clicked.
 * @param props
 */
export const ToolDropdown = (props: Props) => {
  const [show, setShow] = useState(false);
  const isTouchDevice = Device.isTouchDevice();

  const toggleDropdown = () => {
    if (props.disabled !== true) {
      setShow(!show);
    }
  };

  const toggleDropdownTouch = () => {
    if (props.disabled !== true) {
      setShow(!show);
    }
  };

  const getSelectedIcon = () => {
    if (props.icon) return props.icon;
    const option = props.options.find((option: ToolDropdownOption) => {
      return option.value === props.selected;
    });
    return option?.icon;
  };

  const select = (value: number, event) => {
    event.stopPropagation();
    if (isTouchDevice) return;
    setShow(false);
    if (props.onSelect) {
      props.onSelect(value);
    }
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const touchSelect = (value: number, event: any) => {
    event.stopPropagation();
    setShow(false);
    if (props.onSelect) {
      props.onSelect(value);
    }
  };

  return (
    <ToolButton
      text={props.text}
      icon={getSelectedIcon()}
      disabled={props.disabled}
      elementId={props.elementId}
      half={props.half}
      onClick={toggleDropdown}
      className="tool-dropdown-container"
    >
      {show && (
        <div className={"tool-dropdown " + props.layout}>
          {props.layout === "grid" && (
            <>
              {props.options.map((option: ToolDropdownOption) => (
                <div
                  className={
                    "item" +
                    (props.selected === option.value ? " selected" : "")
                  }
                  onClick={(event) => select(option.value, event)}
                  onTouchStart={(event) => touchSelect(option.value, event)}
                  key={option.value.toString()}
                >
                  {option.icon}
                </div>
              ))}
            </>
          )}
          {(props.layout === "list" || props.layout === "text-list") && (
            <>
              {props.options.map((option: ToolDropdownOption) => (
                <div
                  className={"item"}
                  onClick={(event) => select(option.value, event)}
                  onTouchStart={(event) => touchSelect(option.value, event)}
                  key={option.value.toString()}
                >
                  {option.icon && <span className="icon">{option.icon}</span>}
                  <span
                    className={
                      "text" +
                      (props.selected === option.value ? " selected" : "")
                    }
                  >
                    {option.label}
                  </span>
                </div>
              ))}
            </>
          )}
        </div>
      )}
    </ToolButton>
  );
};
