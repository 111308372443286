export enum ShapeType {
  NONE = 0,
  RECTANGLE = 1,
  PATH = 2,
  ELLIPSE = 3,
  LINE = 4,
  CURVED_LINE = 5,
  DIMENSION = 6,
  TEXT = 7,
}
