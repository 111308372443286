import { Checkbox } from "imagine-ui";
import { PlantCategory, PlantModelText } from "draw";

interface Props {
  onChange: (categories: PlantCategory[]) => void;
  selected: PlantCategory[];
  columns: 1 | 2 | 3;
}

/**
 * Displays 2 columns of plant categories with checkboxes.
 * @param props onChange, selected
 */
export const PlantCategoryCheckboxes = (props: Props) => {
  const updateCategory = (category: PlantCategory, checked: boolean) => {
    let newCategories: PlantCategory[] = [];
    if (checked) {
      newCategories = [...props.selected] || [];
      newCategories.push(category);
    } else {
      newCategories =
        props.selected.filter((c: PlantCategory) => {
          return c !== category;
        }) || [];
    }
    props.onChange(newCategories);
  };

  return (
    <div className={"grid gap-xs columns-" + props.columns}>
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.TREE)}
        checked={props.selected.includes(PlantCategory.TREE) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.TREE, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.SHRUB)}
        checked={props.selected.includes(PlantCategory.SHRUB) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.SHRUB, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.CLIMBER)}
        checked={props.selected.includes(PlantCategory.CLIMBER) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.CLIMBER, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.VEGETABLE)}
        checked={props.selected.includes(PlantCategory.VEGETABLE) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.VEGETABLE, checked)
        }
      />

      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.PERENNIAL)}
        checked={props.selected.includes(PlantCategory.PERENNIAL) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.PERENNIAL, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.GRASS)}
        checked={props.selected.includes(PlantCategory.GRASS) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.GRASS, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.BULB)}
        checked={props.selected.includes(PlantCategory.BULB) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.BULB, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.ANNUALS)}
        checked={props.selected.includes(PlantCategory.ANNUALS) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.ANNUALS, checked)
        }
      />
      <Checkbox
        label={PlantModelText.getCategoryText(PlantCategory.ROSES)}
        checked={props.selected.includes(PlantCategory.ROSES) || false}
        onChange={(checked: boolean) =>
          updateCategory(PlantCategory.ROSES, checked)
        }
      />
    </div>
  );
};
