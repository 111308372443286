import {
  PlantsApi,
  PlantCollection,
  PlantCollectionsApi,
  PlantSearchFilter,
  PlantSearchPage,
} from "plants";

import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  PlantManagementThunks,
  LayoutSelector,
  PlanEditorSelector,
  LayoutActions,
  PlanEditorActions,
} from "../../store";
import { PlantSchema, ItemOperations, ItemType } from "draw";
import { useState } from "react";
import { UserActions, UserSelector } from "imagine-users";
import { Pages } from "../../enums";
import { FeatureKey, UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for rendering the correct pages and providing the pages
 * with the needed data.
 */
export const PlantSearchModalContainer = () => {
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);
  const plants = useAppSelector(PlantManagementSelector.getSearchResult);
  const plantCollections = useAppSelector(
    PlantManagementSelector.getPlantCollections
  );
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const selectedPlants = useAppSelector(PlanEditorSelector.getSelectedPlants);
  const [searching, setSearching] = useState(false);
  const planId = useAppSelector(PlanEditorSelector.getPlanId);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const search = async (filter: PlantSearchFilter) => {
    setSearching(true);
    const plants = await PlantsApi.search(filter);
    if (plants !== undefined) {
      dispatch(PlantManagementActions.setSearchResult(plants));
    }
    setSearching(false);
  };

  const reset = () => {
    dispatch(PlantManagementActions.setSearchResult([]));
  };

  const insert = (plant: PlantSchema) => {
    dispatch(PlanEditorActions.setStampType(ItemType.PLANT));
    dispatch(PlanEditorActions.setStampTemplate(plant));
    dispatch(PlanEditorActions.setSelectedItems([]));
    dispatch(UserActions.track(FeatureKey.SELECT_PLANT_FROM_PLANT_FINDER));
    close();
  };

  const replacePlants = (plant: PlantSchema) => {
    const updatedItems = ItemOperations.replacePlantItems(
      selectedPlants,
      plant
    );
    dispatch(PlanEditorActions.updateMultipleItems(updatedItems));
    dispatch(PlanEditorActions.setSelectedItems(updatedItems));
    dispatch(UserActions.track(FeatureKey.REPLACE_PLANTS));
    dispatch(PlanEditorActions.addPlantTemplate(plant));
    close();
  };

  const createNewCollection = () => {
    dispatch(PlantManagementActions.clearOpenPlantCollection());
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_COLLECTION));
  };

  const addToCollection = async (
    plant: PlantSchema,
    collection: PlantCollection
  ) => {
    await PlantCollectionsApi.addPlant(plant.id, collection.id);
    dispatch(PlantManagementThunks.fetchPlantCollections(planId));
    dispatch(UserActions.track(FeatureKey.ADD_PLANT_TO_COLLECTION));
  };

  const showPlantInfo = (plant: PlantSchema) => {
    dispatch(PlantManagementActions.setFocusTemplate(plant));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_INFO));
  };

  return (
    <PlantSearchPage
      visible={displayedPage === Pages.PLANT_FINDER}
      plants={plants}
      plantCollections={plantCollections}
      searching={searching}
      onClose={close}
      onSearch={search}
      onReset={reset}
      onInsertPlant={insert}
      onCreatePlantCollection={createNewCollection}
      onAddToPlantCollection={addToCollection}
      onShowPlantInfo={showPlantInfo}
      onReplacePlants={replacePlants}
      canCreatePlantCollections={hasPremiumAccess}
      selectedPlantsCount={selectedPlants.length}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
      showHardinessZoneUSDA={userPreferences.showHardinessZoneUSDA}
      showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
      enableBetaFeatures={userPreferences.enableBetaFeatures}
    />
  );
};
