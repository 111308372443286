import { useCallback, useMemo, useState } from "react";
import { PlantCard, PlantFilter } from ".";
import { PlantCardAction, PlantCollection, PlantSearchFilter } from "../types";
import {
  Button,
  Device,
  DeviceSize,
  Modal,
  NotificationType,
  Spinner,
  useNotification,
  useWindowWidth,
} from "imagine-ui";
import { useTranslation } from "react-i18next";
import { PlantSchema } from "draw";
import { UnitScale } from "project";
import { Delimiter } from "imagine-essentials";

interface Props {
  visible: boolean;
  plants: PlantSchema[];
  plantCollections: PlantCollection[];
  searching: boolean;
  onSearch: (filter: PlantSearchFilter) => void;
  onReset: () => void;
  onInsertPlant: (plant: PlantSchema) => void;
  onReplacePlants: (plant: PlantSchema) => void;
  onCreatePlantCollection: () => void;
  onShowPlantInfo: (plant: PlantSchema) => void;
  onAddToPlantCollection: (
    plant: PlantSchema,
    plantCollection: PlantCollection
  ) => void;
  onClose: () => void;
  canCreatePlantCollections: boolean;
  selectedPlantsCount: number; // If plants are selected, it is possible to replace those plants
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneUSDA?: boolean;
  showHardinessZoneRHS?: boolean;
  enableBetaFeatures?: boolean;
}

/**
 * Displays a modal with a plant search functionality inside.
 * @param props visible, plants, plantCollections, onSearch, onInsertPlant, onCreatePlantCollection, onAddToPlantCollection, onClose
 * @returns
 */
export const PlantSearchPage = (props: Props) => {
  const notification = useNotification();
  const {
    onInsertPlant,
    onCreatePlantCollection,
    onAddToPlantCollection,
    onShowPlantInfo,
    onReplacePlants,
  } = props;
  const { t } = useTranslation();
  const [showResults, setShowResults] = useState(false);
  const windowWidth = useWindowWidth();

  const [showFilter, setShowFilter] = useState(true);

  const search = (filter: PlantSearchFilter) => {
    setShowResults(true);
    // In case user disabled hardiness zone, remove it from filter
    if (!props.showHardinessZoneUSDA && filter.hardinessZone) {
      delete filter.hardinessZone;
    }
    props.onSearch(filter);
    // Hide filter after search has been pressed on small devices (to be able to see results instantly)
    if (Device.isMaxSize(windowWidth, DeviceSize.LG)) {
      setShowFilter(false);
    }
  };

  const reset = () => {
    setShowResults(false);
    props.onReset();
  };

  const requestReplacePlants = useCallback(
    (plant: PlantSchema) => {
      notification.add(
        NotificationType.CONFIRM,
        t("plants:confirmReplaceCountPlants", {
          count: props.selectedPlantsCount,
        })
      );
      notification.onConfirm(() => {
        onReplacePlants(plant);
      });
    },
    [notification, onReplacePlants, props.selectedPlantsCount, t]
  );

  /**
   * Add actions to each plant
   */
  const plantsWithActions = useMemo(() => {
    return props.plants.map((plant: PlantSchema) => {
      const actions: PlantCardAction[] = [
        {
          label: t("imagine:insert"),
          action: () => {
            onInsertPlant(plant);
          },
        },
      ];
      if (props.selectedPlantsCount > 0) {
        actions.push({
          label: t("imagine:replace"),
          action: () => {
            requestReplacePlants(plant);
          },
        });
      }
      props.plantCollections.forEach(
        (collection: PlantCollection, index: number) => {
          actions.push({
            label: t("plants:addToCollection", { collection: collection.name }),
            action: () => {
              onAddToPlantCollection(plant, collection);
            },
            newGroup: index === 0,
          });
        }
      );
      actions.push({
        label: t("plants:createNewCollection"),
        newGroup: true,
        disabled: !props.canCreatePlantCollections,
        action: () => {
          onCreatePlantCollection();
        },
      });
      actions.push({
        label: t("plants:showPlantInfo"),
        action: () => {
          onShowPlantInfo(plant);
        },
      });
      return {
        plant: plant,
        actions: actions,
      };
    });
  }, [
    onAddToPlantCollection,
    onCreatePlantCollection,
    onInsertPlant,
    requestReplacePlants,
    onShowPlantInfo,
    props.canCreatePlantCollections,
    props.plantCollections,
    props.plants,
    props.selectedPlantsCount,
    t,
  ]);

  const filterLayout = useMemo(() => {
    if (Device.isMaxSize(windowWidth, DeviceSize.MD)) {
      return "narrow";
    }
    if (Device.isMaxSize(windowWidth, DeviceSize.LG)) {
      return "medium";
    }
    return "narrow";
  }, [windowWidth]);

  return (
    <Modal
      title={t("plants:findPlant")}
      visible={props.visible}
      onClose={props.onClose}
      hideCancel
      confirmText={t("imagine:close")}
      onConfirm={props.onClose}
      size="xl"
    >
      <div className={"row column--lg gap-xl gap-none--lg"}>
        <div className="plant-filter w-350 w-full--lg">
          {showFilter ? (
            <>
              <PlantFilter
                onSearch={search}
                layout={filterLayout}
                onReset={reset}
                unitScale={props.unitScale}
                delimiter={props.delimiter}
                showHardinessZoneUSDA={props.showHardinessZoneUSDA}
                enableBetaFeatures={props.enableBetaFeatures}
              />

              <Button
                className="w-full mt"
                outline
                size="sm"
                onClick={() => setShowFilter(false)}
              >
                {t("plants:hideFilter")}
              </Button>
            </>
          ) : (
            <Button
              className="w-full"
              outline
              onClick={() => setShowFilter(true)}
            >
              {t("plants:showFilter")}
            </Button>
          )}
        </div>
        <div className="stretch mt-xl--lg">
          <>
            {props.searching && (
              <div className="row mt-xl items-center">
                <Spinner />
              </div>
            )}
            {showResults && !props.searching && (
              <>
                {plantsWithActions.length === 0 ? (
                  <p className="mb">{t("plants:noPlantsFound")}</p>
                ) : (
                  <p className="mb">
                    {t("plants:foundCountPlants", {
                      count: plantsWithActions.length,
                    })}
                  </p>
                )}
                {plantsWithActions.map(
                  (entry: {
                    plant: PlantSchema;
                    actions: PlantCardAction[];
                  }) => (
                    <PlantCard
                      plant={entry.plant}
                      actions={entry.actions}
                      showSeason
                      showProperties
                      showHeight
                      boxed
                      className="mb"
                      key={entry.plant.id}
                      unitScale={props.unitScale}
                      delimiter={props.delimiter}
                      showHardinessZoneRHS={props.showHardinessZoneRHS}
                    />
                  )
                )}
              </>
            )}
          </>
        </div>
      </div>
    </Modal>
  );
};
