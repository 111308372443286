import { useTranslation } from "react-i18next";
import {
  PlantCard,
  PlantCardAction,
  PlantCollection,
  PlantCollectionType,
  PlantData,
  PlantModelsOverview,
  PlantText,
} from "..";

import { useEffect, useMemo, useState } from "react";

import {
  Alert,
  Button,
  Label,
  Modal,
  NotificationType,
  Select,
  TextInput,
  useNotification,
} from "imagine-ui";
import _ from "lodash";
import { PlantSchema } from "draw";
import { UnitScale } from "project";
import { Api, Delimiter } from "imagine-essentials";

interface Props {
  visible: boolean;
  plantCollection: PlantCollection;
  plantCollections: PlantCollection[];
  saving: boolean;
  onSave: (plantCollection: PlantCollection) => void;
  onDelete: () => void;
  onRemovePlant: (plantId: number) => void;
  onCopyPlant: (plantId: number, collectionId: number) => void;
  onShowPlantInfo: (plant: PlantSchema) => void;
  onClose: () => void;
  unitScale: UnitScale;
  delimiter: Delimiter;
  showHardinessZoneRHS?: boolean;
  planId: number;
  view: string;
  onViewChange: (view: string) => void;
}

/**
 * Displays a page with base info of a plant collection and an overview of all plants within the collections.
 * Also makes it possible to edit and delete the plant collection, and remove plants from the collection.
 * @param props
 * @returns
 */
export const PlantCollectionPage = (props: Props) => {
  const { t, i18n } = useTranslation();

  const notification = useNotification();
  const [plantCollection, setPlantCollection] = useState(
    _.cloneDeep(props.plantCollection)
  );

  const [editMode, setEditMode] = useState(props.plantCollection.id === 0);
  const [previewMonth, setPreviewMonth] = useState(7);

  const { onRemovePlant, onShowPlantInfo } = props;

  useEffect(() => {
    setPlantCollection(_.cloneDeep(props.plantCollection));
    setEditMode(props.plantCollection.id === 0);
  }, [props.plantCollection]);

  /**
   * Update a property in the component stored plant collection.
   * @param obj
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const updatePlantCollection = (obj: any) => {
    const c = Object.assign(plantCollection, obj);
    setPlantCollection({ ...c });
  };

  const saveCollection = async () => {
    if (plantCollection.name === "") {
      notification.add(
        NotificationType.ERROR,
        t("plants.collectionNameCannotBeEmpty")
      );
      return;
    }

    const data = {
      ...props.plantCollection,
      name: plantCollection.name,
      description: plantCollection.description,
      type: plantCollection.type,
      planId: plantCollection.planId,
    };

    props.onSave(data);
  };

  const deleteCollection = async () => {
    if (props.plantCollection.id === 0) {
      return;
    }
    props.onDelete();
  };

  const requestDeleteCollection = () => {
    notification.add(
      NotificationType.CONFIRM,
      t("plants:confirmDeleteCollection")
    );
    notification.onConfirm(() => {
      deleteCollection();
    });
  };

  const plantsWithActions = useMemo(() => {
    if (props.plantCollection.plants === undefined) return [];
    return props.plantCollection.plants.map((plant: PlantSchema) => {
      const plantData = {
        plant: plant,
        actions: [
          {
            action: () => {
              onRemovePlant(plant.id);
            },
            label: t("plants:removeFromCollection"),
          },
          {
            action: () => {
              onShowPlantInfo(plant);
            },
            label: t("plants:plantInfo"),
          },
        ],
      };
      props.plantCollections.forEach(
        (collection: PlantCollection, index: number) => {
          plantData.actions.push({
            label: t("plants:copyToCollection", {
              collection: collection.name,
            }),
            action: () => {
              props.onCopyPlant(plant.id, collection.id);
            },
            newGroup: index === 0,
            disabled: collection.id === props.plantCollection.id,
          } as PlantCardAction);
        }
      );
      return plantData;
    });
  }, [onRemovePlant, onShowPlantInfo, props.plantCollection.plants, t]);

  const collectionTypeOptions = PlantData.getPlantCollectionTypeOptions();

  const hasUnsavedChanges = useMemo(() => {
    if (props.plantCollection.name !== plantCollection.name) return true;
    if (props.plantCollection.type !== plantCollection.type) return true;
    if (props.plantCollection.description !== plantCollection.description)
      return true;
    if (props.plantCollection.planId !== plantCollection.planId) return true;
    return false;
  }, [
    plantCollection.description,
    plantCollection.name,
    plantCollection.type,
    plantCollection.planId,
    props.plantCollection.description,
    props.plantCollection.name,
    props.plantCollection.type,
    props.plantCollection.planId,
  ]);

  const pageTitle =
    plantCollection.id === 0
      ? t("plants:createPlantCollection")
      : plantCollection.name;

  const planIdOptions = useMemo(() => {
    const options = [
      {
        label: t("plants:saveOnUserAccount"),
        value: 0,
      },
    ];
    if (props.planId !== 0) {
      options.push({
        label: t("plants:saveOnGardenPlan"),
        value: props.planId,
      });
    }
    return options;
  }, [t, props.planId]);

  const viewOptions = useMemo(() => {
    if (i18n.language) {
      return [
        {
          label: t("plants:plantCards"),
          value: "cards",
        },
        {
          label: t("plants:plantNames"),
          value: "list",
        },
        {
          label: t("plants:moodBoard"),
          value: "board",
        },
        {
          label: t("plants:plantModels"),
          value: "models",
        },
      ];
    }
    return [];
  }, [t, i18n.language]);

  return (
    <Modal
      title={pageTitle}
      visible={props.visible}
      onClose={props.onClose}
      confirmText={t("imagine:save")}
      confirmDisabled={!hasUnsavedChanges}
      // closeText={t("imagine:close")}
      onConfirm={saveCollection}
      size="xl"
      loading={props.saving}
    >
      {plantCollection.id !== 0 && (
        <div className="row items-right gap">
          <Button
            onClick={() => setEditMode(!editMode)}
            color="dark"
            icon="edit"
            outline
            active={editMode}
          >
            {t("imagine:edit")}
          </Button>

          <Button
            onClick={requestDeleteCollection}
            color="danger"
            icon="trash"
            outline
            className="ms-2"
          >
            {t("imagine:delete")}
          </Button>
        </div>
      )}

      <div className="mt">
        {!editMode && plantCollection.id > 0 && (
          <div className="mb-xl">
            <p className="mb">{plantCollection.description}</p>
            {plantCollection.type !== PlantCollectionType.NONE && (
              <p>
                {t("plants:collectionType")}:{" "}
                <span className="text-bold">
                  {PlantText.getPlantCollectionTypeText(plantCollection.type)}
                </span>
              </p>
            )}
          </div>
        )}
        {(editMode || plantCollection.id === 0) && (
          <>
            <div className="mb">
              <Label>{t("imagine:name")}*:</Label>
              <TextInput
                elementId="plant-collection-name"
                value={plantCollection.name || ""}
                onChange={(name: string) =>
                  updatePlantCollection({ name: name })
                }
              />
            </div>
            <div className="mb">
              <Label>{t("imagine:description")}:</Label>
              <TextInput
                elementId="plant-collection-description"
                value={plantCollection.description || ""}
                multiline
                onChange={(name: string) =>
                  updatePlantCollection({ description: name })
                }
              />
            </div>
            <div className="mb-xl">
              <Label>{t("plants:collectionType")}:</Label>
              <Select
                options={collectionTypeOptions}
                selected={plantCollection.type}
                onSelectedNumber={(val: number) =>
                  updatePlantCollection({ type: val })
                }
              />
            </div>
            <div className="mb-xl">
              <Label>{t("plants:whereToSaveCollection")}:</Label>
              <Select
                options={planIdOptions}
                selected={plantCollection.planId}
                onSelectedNumber={(val: number) =>
                  updatePlantCollection({ planId: val })
                }
              />
            </div>
          </>
        )}

        {plantCollection.plants !== undefined && (
          <>
            <hr />
            <div className="row items-right gap my">
              <Select
                options={viewOptions}
                className="w-200"
                selected={props.view}
                onSelectedString={(view: string) => props.onViewChange(view)}
              />
            </div>
            {props.view === "cards" && (
              <div className="grid columns-2 columns-1--md gap-sm">
                {plantsWithActions.map(
                  (entry: {
                    plant: PlantSchema;
                    actions: PlantCardAction[];
                  }) => (
                    <PlantCard
                      plant={entry.plant}
                      showProperties
                      showSeason
                      showHeight
                      boxed
                      actions={entry.actions}
                      key={entry.plant.id.toString()}
                      unitScale={props.unitScale}
                      delimiter={props.delimiter}
                      showHardinessZoneRHS={props.showHardinessZoneRHS}
                    />
                  )
                )}
              </div>
            )}

            {props.view === "board" && (
              <div className="grid columns-6 columns-4--lg columns-3--md columns2--sm">
                {props.plantCollection.plants?.map((plant: PlantSchema) => (
                  <img
                    src={Api.getHost() + "/plant-images/" + plant.image}
                    alt={plant.name}
                    className="fluid"
                    key={plant.id}
                  />
                ))}
              </div>
            )}

            {props.view === "list" && (
              <div className="">
                {props.plantCollection.plants?.map((plant: PlantSchema) => (
                  <p className="pb-sm" key={plant.id}>
                    <span className="text-bold">{plant.name}</span>
                    {", "}
                    <span className="text-italic">
                      {PlantText.getLatinAndHybridName(plant)}
                    </span>
                  </p>
                ))}
              </div>
            )}

            {props.view === "models" && (
              <PlantModelsOverview
                plants={plantCollection.plants}
                month={previewMonth}
                onMonthChange={setPreviewMonth}
              />
            )}
          </>
        )}
        {plantCollection.id !== 0 &&
          (plantCollection.plants === undefined ||
            plantCollection.plants?.length === 0) && (
            <Alert color="warning">{t("plants:noPlantsInCollection")}</Alert>
          )}
      </div>
    </Modal>
  );
};
