import { UrlParser } from "imagine-essentials";

const clearUrlParams = () => {
  const match = UrlParser.urlMatches("*");
  if (match) {
    let url = window.location.origin;
    // Port is not set in production
    if (window.location.port === "") url += "/app";
    window.history.pushState("", "", url);
  }
};

export const WindowLocation = {
  clearUrlParams,
};
