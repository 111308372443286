import {
  ModelDesign,
  ModelDesignSvg,
  MonthSlider,
  PlantModelOperations,
  PlantSchema,
} from "draw";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import ImagineConfig from "../../../../ImagineConfig";

interface Props {
  plants: PlantSchema[];
  month: number;
  onMonthChange: (month: number) => void;
}

export const PlantModelsOverview = (props: Props) => {
  const [designSize, setDesignSize] = useState({ width: 40, height: 40 });
  const [columns, setColumns] = useState(3);
  const targetRef = useRef<HTMLDivElement>(null);

  const designs = useMemo(() => {
    if (props.plants === undefined) return [];
    return PlantModelOperations.getTemplateDesigns(props.plants, props.month);
  }, [props.plants, props.month]);

  // Make the designs 1/3 of the picker width
  const updateSize = useCallback(() => {
    if (targetRef.current !== undefined) {
      const width =
        targetRef.current !== null ? targetRef.current.offsetWidth : 0;
      let cols = 3;
      if (width > ImagineConfig.breakpoints.md) cols = 6;
      else if (width > ImagineConfig.breakpoints.sm) cols = 4;
      const gap = 4; // Equivalent to gap-xs
      let d = Math.floor((width - 2 * gap) / cols) - 16;
      if (d < 0) d = 0;
      const newDesignSize = {
        width: d,
        height: d,
      };
      // Size might be negative initially when all components are not ready yet
      if (newDesignSize.width < 0 || newDesignSize.height < 0) {
        console.warn("Invalid design size, setting to 0");
        setDesignSize({ width: 0, height: 0 });
      } else {
        setDesignSize(newDesignSize);
        setColumns(cols);
      }
    }
  }, []);

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      updateSize();
    });
    const currentRef = targetRef.current;

    if (currentRef) {
      resizeObserver.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
    };
  }, [targetRef, updateSize]);

  useEffect(() => {
    updateSize();
  }, [targetRef]);

  return (
    <div className="bg-main" ref={targetRef}>
      <div className={"grid columns-" + columns + " gap-xs"}>
        {designs.map((design: ModelDesign, index: number) => (
          <div key={design.id}>
            <ModelDesignSvg
              design={design}
              size={designSize}
              position={{ x: 0, y: 0 }}
              viewBox={design.viewBox}
              cursor="pointer"
            />
          </div>
        ))}
      </div>
      <MonthSlider month={props.month} onChanged={props.onMonthChange} />
    </div>
  );
};
