import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Delimiter, Point, Size, Tools } from "imagine-essentials";
import {
  CanvasOperations,
  Marker,
  MarkerType,
  Measure,
  ShapeOperations,
  ShapeStyle,
  Trig,
} from "draw";
import { UnitScale } from "project";

const padding = 5;
const fontSizePx = 10;

type Props = {
  start: Point;
  end: Point;
  zoom: number;
  lineWidth: number;
  style: ShapeStyle;
  showArrow?: boolean;
  cursor?: string; // Overrides default cursor if set
  uniqueId?: string;
  delimiter: Delimiter;
  unitScale: UnitScale;
  followDirection?: boolean; // If true, the dimension label will follow the direction of the line
};

/**
 * Draw a dimension for a shape creator component. The start and end points are given in px
 * and the dimensions label is displayed in units.
 */
export const ShapeDimension = (props: Props) => {
  const [labelCenter, setLabelCenter] = useState<Point>({ x: 0, y: 0 });
  const [labelSize, setLabelSize] = useState<Size>({ width: 0, height: 0 });
  const [labelText, setLabelText] = useState("");

  const getLabelText = () => {
    return labelText;
  };

  const update = useCallback(() => {
    const distancePx = CanvasOperations.calculateDistance(
      props.start,
      props.end
    );
    const distanceMeters = Tools.round(
      CanvasOperations.pixelToUnit(distancePx, props.zoom),
      2
    );

    // No reason to spend CPU power on calculating the with if it is really small
    if (distancePx < 50) {
      setLabelText("");
      return;
    }

    const text = Measure.getMeasureText(
      distanceMeters,
      props.unitScale,
      props.delimiter
    );
    const textWidth = Tools.round(
      ShapeOperations.getTextPxWidth(text, fontSizePx) + 2 * padding,
      1
    );

    const xDistancePx = Math.abs(props.start.x - props.end.x);
    const yDistancePx = Math.abs(props.start.y - props.end.y);

    // Arrows are approximately 12px wide each
    if (xDistancePx < textWidth + 24 && yDistancePx < 50) {
      setLabelText("");
      return;
    }
    if (props.followDirection && distancePx < textWidth + 24) {
      setLabelText("");
      return;
    }

    setLabelText(text);

    const newLabelSize = {
      width: textWidth,
      height: Tools.round(fontSizePx + 2 * padding, 1),
    };
    const newCenter = {
      x: Tools.round((props.start.x + props.end.x) / 2, 3),
      y: Tools.round((props.start.y + props.end.y) / 2, 3),
    };
    setLabelSize(newLabelSize);
    setLabelCenter(newCenter);
  }, [
    props.delimiter,
    props.end,
    props.followDirection,
    props.start,
    props.unitScale,
    props.zoom,
  ]);

  const rotation = useMemo(() => {
    if (props.followDirection) {
      const dimensionVector = CanvasOperations.getPointSubtracted(
        props.end,
        props.start
      );
      const zeroVector = { x: 1, y: 0 };
      let angle = Trig.getAngle(dimensionVector, zeroVector);
      // Angle should be between ]90 and -90] degrees
      if (angle >= 90) angle = angle - 180;
      if (angle < -90) angle = angle + 180;
      return angle;
    }
    return 0;
  }, [props.end, props.followDirection, props.start]);

  useEffect(() => {
    update();
  }, [update]);

  return (
    <g>
      {props.showArrow && (
        <>
          <Marker
            uniqueId={"markerStart" + props.uniqueId || ""}
            type={MarkerType.ARROW_START}
            fill={props.style.borderColor || "none"}
          />
          <Marker
            uniqueId={"markerEnd" + props.uniqueId || ""}
            type={MarkerType.ARROW_END}
            fill={props.style.borderColor || "none"}
          />
          <line
            x1={props.start.x}
            y1={props.start.y}
            x2={props.end.x}
            y2={props.end.y}
            markerStart={"url(#markerStart" + (props.uniqueId ?? "") + ")"}
            markerEnd={"url(#markerEnd" + (props.uniqueId ?? "") + ")"}
            strokeWidth={props.lineWidth}
            stroke={ShapeOperations.getStroke(props.style)}
            strokeOpacity={ShapeOperations.getStrokeOpacity()}
            strokeDasharray={ShapeOperations.getStrokeDashArray(
              props.style,
              props.lineWidth
            )}
          />
        </>
      )}
      {labelText !== "" && (
        <g
          cursor={props.cursor || "default"}
          transform={
            "rotate(" +
            (rotation || 0) +
            ", " +
            labelCenter.x +
            ", " +
            labelCenter.y +
            ")"
          }
        >
          <rect
            x={labelCenter.x - labelSize.width / 2}
            y={labelCenter.y - labelSize.height / 2}
            width={labelSize.width}
            height={labelSize.height}
            fill="white"
            opacity={0.7}
            // onClick={editDimension}
            // cursor={canEditDimension() ? "text" : "default"}
            stroke={"none"}
          />
          <text
            textAnchor="middle"
            className="no-event"
            dominantBaseline="middle"
            x={labelCenter.x}
            y={labelCenter.y}
            fill="black"
            fontSize={fontSizePx}
            // onClick={editDimension}
            // cursor={canEditDimension() ? "text" : "default"}
            //transform={"rotate(" + center.x + " " + center.y + "," + angle + ")"}
          >
            {getLabelText()}
          </text>
        </g>
      )}
    </g>
  );
};
