import {
  CanvasActions,
  LayoutActions,
  LayoutSelector,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  PlantManagementActions,
  useAppDispatch,
  useAppSelector,
} from "../../store";

import { CanvasSelector } from "../../store";
import { Pages } from "../../enums";
import { NotificationType, useNotification } from "imagine-ui";
import {
  CanvasContextMenu,
  ContextMenuItem,
  ContextMenuSeparator,
} from "../../components";
import {
  AreaOperations,
  Item,
  ItemOperations,
  ItemType,
  ObjectSchema,
  PlantSchema,
  ShapeType,
} from "draw";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { UserSelector } from "imagine-users";

/**
 * Displays tools on top of the canvas. Eg. cursor mode.
 * @param props
 * @returns
 */
export const ContextMenuContainer = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();

  const user = useAppSelector(UserSelector.getUserNotNull);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const isSmallDevice = useAppSelector(LayoutSelector.isSmOrSmaller);
  const contextMenuPosition = useAppSelector(
    CanvasSelector.getContextMenuPosition
  );
  const hasStandardAccess = useAppSelector(UserSelector.hasStandardAccess);
  const usedObjectTemplates = useAppSelector(
    PlanEditorSelector.getObjectTemplates
  );
  const usedPlantTemplates = useAppSelector(
    PlanEditorSelector.getPlantTemplates
  );

  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const copiedPlanItems = useAppSelector(PlanEditorSelector.getCopiedItems);
  const copiedObjectItems = useAppSelector(ObjectEditorSelector.getCopiedItems);
  const copiedItems = useMemo(() => {
    return objectMode ? copiedObjectItems : copiedPlanItems;
  }, [objectMode, copiedObjectItems, copiedPlanItems]);

  const isOnlyOneShapeItemSelected = useMemo(() => {
    return (
      selectedItems.length === 1 && selectedItems[0].type === ItemType.SHAPE
    );
  }, [selectedItems]);

  const isOnlyShapeItemsSelected = useMemo(() => {
    return (
      selectedItems.length > 0 &&
      selectedItems.every((item) => item.type === ItemType.SHAPE)
    );
  }, [selectedItems]);

  const isOnlyOneTextShapeItemSelected = useMemo(() => {
    return (
      selectedItems.length === 1 &&
      selectedItems[0].type === ItemType.SHAPE &&
      selectedItems[0].shapeProperties?.type === ShapeType.TEXT
    );
  }, [selectedItems]);

  const isShapesSupportedInObjectMode = useMemo(() => {
    return (
      selectedItems.length > 0 &&
      selectedItems.every((item) => {
        if (item.type === ItemType.SHAPE) {
          if (
            item.shapeProperties?.type === ShapeType.RECTANGLE ||
            item.shapeProperties?.type === ShapeType.ELLIPSE ||
            item.shapeProperties?.type === ShapeType.LINE ||
            item.shapeProperties?.type === ShapeType.CURVED_LINE
          ) {
            return true;
          }
        }
        return false;
      })
    );
  }, [selectedItems]);

  const isOnlyOnePlantItemSelected = useMemo(() => {
    return (
      selectedItems.length === 1 && selectedItems[0].type === ItemType.PLANT
    );
  }, [selectedItems]);

  const isOnlyOneObjectItemSelected = useMemo(() => {
    return (
      selectedItems.length === 1 && selectedItems[0].type === ItemType.OBJECT
    );
  }, [selectedItems]);

  const canDefineArea = useMemo(() => {
    return ItemOperations.isAreaShapeSelected(selectedItems);
  }, [selectedItems]);

  const selectedPlantTemplate = useMemo(() => {
    if (!isOnlyOnePlantItemSelected) return undefined;
    return usedPlantTemplates.find(
      (template: PlantSchema) => template.id === selectedItems[0].templateId
    );
  }, [isOnlyOnePlantItemSelected, selectedItems, usedPlantTemplates]);

  const canEditPlant = useMemo(() => {
    if (selectedPlantTemplate === undefined) return false;
    return selectedPlantTemplate.userId === user.id;
  }, [selectedPlantTemplate, user.id]);

  const closeContextMenu = useCallback(() => {
    dispatch(CanvasActions.closeContextMenu());
  }, [dispatch]);

  const setNewArea = (item: Item) => {
    const newArea = AreaOperations.itemToArea(item);
    // Delete shape from list of items
    dispatch(PlanEditorActions.deleteItem(item));
    dispatch(PlanEditorActions.setSelectedItems([]));
    dispatch(PlanEditorActions.setArea(newArea));
  };

  const defineArea = () => {
    if (!canDefineArea) return;

    // No area is currently set, so update directly
    if (area === undefined) {
      setNewArea(selectedItems[0]);
      return;
    }
    // Confirm overriding old area
    notification.add(NotificationType.CONFIRM, t("confirmOverringOldArea"));
    notification.onConfirm(() => {
      if (selectedItems[0].shapeProperties === undefined) return;
      setNewArea(selectedItems[0]);
    });
  };

  const createObject = () => {
    if (!isOnlyShapeItemsSelected) return;
    dispatch(ObjectEditorActions.prepareNewObject(selectedItems));
    dispatch(LayoutActions.setSidebarLeftActiveTabAuto("objectItems"));
    dispatch(LayoutActions.setActiveTab("item-tab"));
  };

  const editObject = () => {
    if (!isOnlyOneObjectItemSelected) return;
    const template = usedObjectTemplates.find(
      (template: ObjectSchema) => template.id === selectedItems[0].templateId
    );
    if (template === undefined) return;
    dispatch(
      ObjectEditorActions.prepareEditingObject({
        template: template,
        item: selectedItems[0],
      })
    );
    dispatch(LayoutActions.setSidebarLeftActiveTabAuto("objectItems"));
    dispatch(LayoutActions.setActiveTab("home-tab"));
  };

  const lock = () => {
    dispatch(PlanEditorActions.lockSelectedItem());
  };

  const unlock = () => {
    dispatch(PlanEditorActions.unlockSelectedItem());
  };

  const deleteSelectedItems = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.deleteSelectedItems());
    } else {
      dispatch(PlanEditorActions.deleteSelectedItems());
    }
  };

  const copySelectedItems = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.copySelectedItems());
    } else {
      dispatch(PlanEditorActions.copySelectedItems());
    }
  };

  const paste = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.pasteCopiedItems());
    } else {
      dispatch(PlanEditorActions.pasteCopiedItems());
    }
  };

  const moveToFront = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.moveSelectedItemToFront());
    } else {
      dispatch(PlanEditorActions.moveSelectedItemToFront());
    }
  };

  const moveToBack = () => {
    if (objectMode) {
      dispatch(ObjectEditorActions.moveSelectedItemToBack());
    } else {
      dispatch(PlanEditorActions.moveSelectedItemToBack());
    }
  };

  const openPlantInfo = () => {
    if (selectedPlantTemplate === undefined) return;
    dispatch(PlantManagementActions.setFocusTemplate(selectedPlantTemplate));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_INFO));
  };

  const editPlant = () => {
    if (selectedPlantTemplate === undefined || !canEditPlant) return;
    dispatch(PlantManagementActions.setFocusTemplate(selectedPlantTemplate));
    dispatch(PlantManagementActions.setCopyPlant(false));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_EDITOR));
  };

  const createPlant = () => {
    if (selectedPlantTemplate === undefined) return;
    dispatch(PlantManagementActions.setFocusTemplate(selectedPlantTemplate));
    dispatch(PlantManagementActions.setCopyPlant(true));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_EDITOR));
  };

  const editShapeText = () => {
    if (!isOnlyOneTextShapeItemSelected) return;
    dispatch(LayoutActions.setDisplayedPage(Pages.TEXT_SHAPE_TEXT));
  };

  return (
    <>
      {contextMenuPosition !== null && (
        <CanvasContextMenu
          position={contextMenuPosition}
          canvasSize={canvasSize}
          canvasOffset={canvasOffset}
          fullscreen={isSmallDevice}
          onClose={closeContextMenu}
        >
          {selectedItems.length === 1 && !objectMode && (
            <>
              <ContextMenuItem
                id="context-item-lock"
                onClick={lock}
                disabled={selectedItems[0].locked}
              >
                {t("lock")}
              </ContextMenuItem>

              <ContextMenuItem
                id="context-item-unlock"
                onClick={unlock}
                disabled={!selectedItems[0].locked}
              >
                {t("unlock")}
              </ContextMenuItem>

              <ContextMenuSeparator />
            </>
          )}
          {selectedItems.length > 0 && (
            <ContextMenuItem
              id="context-item-delete"
              onClick={deleteSelectedItems}
              disabled={selectedItems[0].locked}
            >
              {t("imagine:delete")}
            </ContextMenuItem>
          )}
          {selectedItems.length === 1 && (
            <>
              <ContextMenuItem
                id="context-item-copy"
                onClick={copySelectedItems}
                disabled={selectedItems[0].locked}
              >
                {t("imagine:copy")}
              </ContextMenuItem>
              <ContextMenuSeparator />
            </>
          )}

          {selectedItems.length === 1 && (
            <>
              <ContextMenuItem
                id="context-item-move-to-front"
                onClick={moveToFront}
                disabled={selectedItems[0].locked}
              >
                {t("moveToFront")}
              </ContextMenuItem>
              <ContextMenuItem
                id="context-item-move-to-back"
                onClick={moveToBack}
                disabled={selectedItems[0].locked}
              >
                {t("moveToBack")}
              </ContextMenuItem>
              <ContextMenuSeparator />
            </>
          )}

          {isOnlyShapeItemsSelected && !objectMode && (
            <ContextMenuItem
              id="context-item-create-object"
              onClick={createObject}
              disabled={
                !hasStandardAccess ||
                selectedItems[0].locked ||
                !isShapesSupportedInObjectMode
              }
            >
              {t("createObject")}
            </ContextMenuItem>
          )}
          {isOnlyOneObjectItemSelected && !objectMode && (
            <>
              <ContextMenuItem
                id="context-item-edit-object"
                onClick={editObject}
                disabled={!hasStandardAccess}
              >
                {t("editObject")}
              </ContextMenuItem>
            </>
          )}
          {isOnlyOnePlantItemSelected && !objectMode && (
            <>
              <ContextMenuItem
                id="context-item-plant-info"
                onClick={openPlantInfo}
              >
                {t("plants:plantInfo")}
              </ContextMenuItem>
              <ContextMenuItem
                id="context-item-edit-plant"
                onClick={editPlant}
                disabled={!canEditPlant || !hasStandardAccess}
              >
                {t("plants:editPlant")}
              </ContextMenuItem>
              <ContextMenuItem
                id="context-item-create-new-plant"
                onClick={createPlant}
                disabled={!hasStandardAccess}
              >
                {t("plants:createNewPlant")}
              </ContextMenuItem>
            </>
          )}

          {canDefineArea && !objectMode && (
            <ContextMenuItem
              id="context-item-define-area"
              onClick={defineArea}
              disabled={selectedItems[0].locked}
            >
              {t("defineArea")}
            </ContextMenuItem>
          )}

          {selectedItems.length === 0 && (
            <ContextMenuItem
              id="context-item-paste"
              onClick={paste}
              disabled={copiedItems.length < 1}
            >
              {t("paste")}
            </ContextMenuItem>
          )}
          {isOnlyOneTextShapeItemSelected && (
            <ContextMenuItem
              id="context-item-edit-text"
              onClick={editShapeText}
            >
              {t("editText")}
            </ContextMenuItem>
          )}
        </CanvasContextMenu>
      )}
    </>
  );
};
