import { t } from "i18next";
import { PlanOperations } from ".";
import { PlansApi } from "../api";
import {
  AppDispatch,
  CanvasActions,
  LayoutActions,
  PlanEditorActions,
  PlantManagementThunks,
} from "../store";
import {
  Area,
  Group,
  Item,
  Layer,
  PlanInfo,
  PlanSettings,
  ReferenceImage,
} from "draw";
import { Pages } from "../enums";
import { RequestStatus } from "imagine-essentials";

const openPlan = async (dispatch: AppDispatch, planId: number) => {
  dispatch(CanvasActions.setLoadingMessage(t("loadingPlan")));
  dispatch(PlanEditorActions.setPlanRequestStatus(RequestStatus.LOADING));
  const response = await PlansApi.openPlan(planId);
  if (response.success && response.data) {
    dispatch(PlanEditorActions.clearPlan());
    const plantTemplates = await PlanOperations.getPlantTemplatesForItems(
      [],
      response.data.data.items
    );
    const objectTemplates = await PlanOperations.getObjectTemplatesForItems(
      [],
      response.data.data.items
    );
    dispatch(PlanEditorActions.setPlantTemplates(plantTemplates));
    dispatch(PlanEditorActions.setObjectTemplates(objectTemplates));
    // Plan data must be set after all needed templates are loaded (otherwise it will throw errors because of missing templates)
    dispatch(PlanEditorActions.openPlan(response.data));
    if (response.data.data.planSettings) {
      dispatch(CanvasActions.openPlan(response.data.data.planSettings));
    }
    // Make this plan auto open next time the Garden Sketcher i opened
    localStorage.setItem("planId", response.data.info.id.toString());
    dispatch(CanvasActions.setLoadingMessage(""));
    dispatch(PlanEditorActions.setPlanRequestStatus(RequestStatus.READY));

    // Also fetch the plant collections for the plan
    dispatch(
      PlantManagementThunks.fetchPlantCollections(response.data.info.id)
    );
    return true;
  }

  dispatch(PlanEditorActions.setPlanRequestStatus(RequestStatus.FAILED));
  dispatch(CanvasActions.setLoadingMessage(""));
  return false;
};

const savePlan = async (
  dispatch: AppDispatch,
  planInfo: PlanInfo,
  layers: Layer[],
  groups: Group[],
  items: Item[],
  area: Area | undefined,
  planSettings: PlanSettings,
  referenceImage: ReferenceImage | undefined,
  updateThumbnail: boolean = true
) => {
  let errorMessage = "";

  const response = await PlansApi.updatePlan(
    planInfo,
    layers,
    groups,
    items,
    area,
    planSettings,
    referenceImage
  );
  if (response.success && response.data) {
    dispatch(PlanEditorActions.setPlanInfo(response.data));

    if (updateThumbnail) {
      const thumbnail = await PlanOperations.getPlanThumbnailImage();
      if (thumbnail !== null) {
        await PlansApi.updateThumbnail(planInfo.id, thumbnail);
      }
    }
  } else {
    if (response.errorCode === "no-access") {
      errorMessage = t("needToLoginAgainDueToError");

      dispatch(LayoutActions.setDisplayedPage(Pages.LOGIN));
    } else if (response.errorMessage) {
      errorMessage = response.errorMessage;
    }
  }
  return errorMessage;
};

export const PlanThunks = {
  openPlan,
  savePlan,
};
