import {
  BorderType,
  CurvedLinePathProperties,
  DimensionProperties,
  EllipseProperties,
  LinePathProperties,
  Pattern,
  RectangleProperties,
  ShapeText,
  ShapeType,
  TextAlign,
  TextMode,
  TextProperties,
} from "..";
import { Option } from "imagine-essentials";

const getEmptyShapeProperties = (shape: ShapeType) => {
  switch (shape) {
    case ShapeType.LINE:
      return {
        points: [],
        closePath: true,
      } as LinePathProperties;
    case ShapeType.CURVED_LINE:
      return {
        curves: [],
        closePath: true,
      } as CurvedLinePathProperties;
    case ShapeType.RECTANGLE:
      return {
        size: { width: 0, height: 0 },
        position: { x: 0, y: 0 },
      } as RectangleProperties;
    case ShapeType.ELLIPSE:
      return {
        radius: { width: 0, height: 0 },
        center: { x: 0, y: 0 },
      } as EllipseProperties;
    case ShapeType.DIMENSION:
      return {
        start: { x: 0, y: 0 },
        end: { x: 0, y: 0 },
      } as DimensionProperties;
    case ShapeType.TEXT:
      return {
        text: "",
        position: { x: 0, y: 0 },
        size: { width: 0, height: 0 },
        fontSize: 30,
        textAlign: TextAlign.LEFT,
      } as TextProperties;
    default:
      throw new Error("Unhandled shape type: " + shape);
  }
};

const getBorderTypeOptions = (forceBorder?: boolean | undefined) => {
  const borderTypes: Option[] = [];
  const startIndex = forceBorder ? 1 : 0;
  for (let i = startIndex; i < BorderType._SIZE; i++) {
    borderTypes.push({
      value: i,
      label: ShapeText.getBorderTypeName(i),
    } as Option);
  }
  return borderTypes;
};

const getTextModeOptions = () => {
  const textModes: Option[] = [];
  for (let i = 0; i < TextMode._SIZE; i++) {
    textModes.push({
      value: i,
      label: ShapeText.getTextModeName(i),
    } as Option);
  }
  return textModes;
};

const getTextSizeOptions = () => {
  const options: Option[] = [];
  for (let i = 10; i <= 100; i += 10) {
    options.push({
      value: i,
      label: i + " cm",
    });
  }
  return options;
};

const getTextAlignOptions = () => {
  const options: Option[] = [];
  for (let i = 0; i < TextAlign._SIZE; i++) {
    options.push({
      value: i,
      label: ShapeText.getTextAlignName(i),
    });
  }
  return options;
};

const getPatternOptions = () => {
  const patterns: Option[] = [];
  for (let i = 0; i < Pattern._SIZE; i++) {
    patterns.push({
      value: i,
      label: ShapeText.getPatternName(i),
    } as Option);
  }
  return patterns;
};

export const ShapeData = {
  getEmptyShapeProperties,
  getBorderTypeOptions,
  getTextModeOptions,
  getTextSizeOptions,
  getTextAlignOptions,
  getPatternOptions,
};
