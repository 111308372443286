import { Delimiter } from "imagine-essentials";
import { NumberInput } from "imagine-ui";
import { UnitScale } from "project";
import { MeasureImperialInput } from ".";

interface Props {
  value?: number;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  onFinish?: (value: number) => void; // Enter, tab or lost focus
  onEnterPress?: (value: number) => void;
  elementId?: string;
  className?: string;
  invalid?: boolean;
  delimiter: Delimiter;
  unitScale: UnitScale;
  omitYards?: boolean;
  omitFractions?: boolean;
}

export const MeasureInput = (props: Props) => {
  return (
    <>
      {props.unitScale === UnitScale.METRIC && (
        <NumberInput
          value={props.value}
          onFinish={props.onFinish}
          onEnterPress={props.onEnterPress}
          elementId={props.elementId}
          className={props.className}
          invalid={props.invalid}
          delimiter={props.delimiter}
          size={props.size}
          unit="m"
        />
      )}
      {props.unitScale === UnitScale.IMPERIAL && (
        <MeasureImperialInput
          value={props.value}
          onFinish={props.onFinish}
          onEnterPress={props.onEnterPress}
          elementId={props.elementId}
          className={props.className}
          invalid={props.invalid}
          delimiter={props.delimiter}
          omitYards={props.omitYards}
          omitFractions={props.omitFractions}
          size={props.size}
        />
      )}
    </>
  );
};
