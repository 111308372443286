import { useCallback, useMemo } from "react";
import {
  useAppSelector,
  CanvasSelector,
  PlanEditorSelector,
  useAppDispatch,
  CanvasActions,
  PlanEditorActions,
} from "../../store";
import { ShapeCreator } from "../../components";
import { CursorMode } from "../../enums";
import { AreaOperations, CanvasOperations, Item } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { Point } from "imagine-essentials";
import { I18nTools } from "imagine-i18n";

/**
 * Displays the editable version of the area
 */
export const EditableAreaContainer = () => {
  const dispatch = useAppDispatch();
  const area = useAppSelector(PlanEditorSelector.getArea);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const isEnteringText = useAppSelector(CanvasSelector.isEnteringText);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const visibleRectangle = useAppSelector(CanvasSelector.getVisibleRectangle);

  const delimiter = I18nTools.getDelimiter(user.language);

  const areaItem = useMemo(() => {
    if (area === undefined) return undefined;
    return AreaOperations.areaToItem(area);
  }, [area]);

  const cursorOverride = useMemo(() => {
    if (cursorMode === CursorMode.DRAG) return "grab";
    return undefined;
  }, [cursorMode]);

  const setEnteringText = (enteringText: boolean) => {
    dispatch(CanvasActions.setEnteringText(enteringText));
  };

  const openItemContextMenu = (position: Point) => {
    const pos = CanvasOperations.getPointSubtracted(position, canvasOffset);
    dispatch(CanvasActions.openContextMenu(pos));
  };

  const updateArea = useCallback(
    (item: Item) => {
      const updatedArea = AreaOperations.itemToArea(item);
      dispatch(PlanEditorActions.setArea(updatedArea));
    },
    [dispatch]
  );

  const closeCreator = () => {
    dispatch(PlanEditorActions.setEditingArea(false));
  };

  return (
    <>
      {area !== undefined && areaItem !== undefined && isEditingArea && (
        <ShapeCreator
          shapeType={area.shape.type}
          item={areaItem}
          onItemChange={updateArea}
          onFinish={closeCreator}
          onRightClick={openItemContextMenu}
          zoom={zoom}
          zeroReference={zeroReference}
          canvasOffset={canvasOffset}
          snapEnabled={userPreferences.snap}
          cursor={cursorOverride}
          unitScale={userPreferences.unitScale}
          delimiter={delimiter}
          visibleRectangle={visibleRectangle}
        />
      )}
    </>
  );
};
