import { useState } from "react";
import { Icon } from ".";

interface Props {
  title?: string;
  titleComponent?: React.ReactNode;
  children?: React.ReactNode;
  className?: string;
  initialOpen?: boolean;
  elementId?: string;
}

export const CollapsibleBox = (props: Props) => {
  const [open, setOpen] = useState(props.initialOpen || false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div
      className={
        "collapsible-box" +
        (open ? " open" : "") +
        (props.className ? " " + props.className : "")
      }
    >
      <button
        className="collapsible-header"
        onClick={toggleOpen}
        id={props.elementId}
      >
        <div className="title stretch text-left">
          {props.title || props.titleComponent}
        </div>
        <Icon name={open ? "chevron-up" : "chevron-down"} className="icon" />
      </button>
      <div className="collapsible-body">{props.children}</div>
    </div>
  );
};
