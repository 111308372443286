import { Alert, Label, Modal, TextInput } from "imagine-ui";
import { useState } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  onClose: () => void;
  onSave: (name: string) => void;
  name: string;
}

export const RenameObjectModal = (props: Props) => {
  const { t } = useTranslation();

  const [name, setName] = useState(props.name);

  const save = () => {
    props.onSave(name);
  };

  return (
    <Modal
      title={t("renameObject")}
      visible
      onClose={props.onClose}
      center
      onConfirm={save}
      confirmDisabled={name === ""}
    >
      <Label>{t("imagine:name")}:</Label>
      <TextInput value={name} onChange={setName} />
    </Modal>
  );
};
