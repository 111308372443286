import { useMemo } from "react";
import { ModelDesign, ShapeProperties, ShapeSvg } from "..";
import { Point, Size } from "imagine-essentials";
import { UnitScale } from "project";

type Props = {
  design: ModelDesign;
  position: Point; // Upper left corner
  viewBox?: string; /// Viewbox of the SVG, default is "0 0 100 100"
  size: Size; // Px
  onMouseDown?: (pos: Point) => void;
  cursor?: string;
  className?: string; // Optional class name on the containing svg element
};

const getViewBox = (size: Size, viewBox: string | undefined) => {
  if (viewBox === undefined) return "0 0 100 100";

  const viewBoxArray = viewBox.split(" ");
  const originalSize = {
    width: Number(viewBoxArray[2]),
    height: Number(viewBoxArray[3]),
  };
  const viewRatio = originalSize.width / originalSize.height;

  const sizeRatio = size.width / size.height;

  const viewPos = { x: 0, y: 0 };
  const viewSize = { ...originalSize };

  // Make the SVG use as much available space as possible while keeping the aspect ratio of the original viewbox
  if (sizeRatio > viewRatio) {
    // Available space is wider than needed, height is the limiting factor
    viewSize.height = originalSize.height;
    viewSize.width = viewSize.height * viewRatio;
  } else if (sizeRatio < viewRatio) {
    // Available space is narrower than needed, width is the limiting factor
    viewSize.width = originalSize.width;
    viewSize.height = viewSize.width / viewRatio;
  }

  return (
    viewPos.x + " " + viewPos.y + " " + viewSize.width + " " + viewSize.height
  );
};

const getPointsPerPixel = (viewBox: string, size: Size) => {
  const viewBoxArray = viewBox.split(" ");
  const dim = {
    x: Number(viewBoxArray[2]) / size.width,
    y: Number(viewBoxArray[3]) / size.height,
  };
  return dim;
};

/**
 * Displays a SVG wrapper element containing all the passed shapes. The last shape
 * in the array is drawn on top. The viewbox is default 100x100.
 * @param props
 */
export const ModelDesignSvg = (props: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleMouseDown = (event: any) => {
    if (props.onMouseDown) {
      props.onMouseDown({
        x: event.clientX,
        y: event.clientY,
      });
    }
  };
  // Modified viewBox so image fills the square area while keeping aspect ratio
  const viewbox = useMemo(() => {
    return getViewBox(props.size, props.viewBox);
  }, [props.size, props.viewBox]);

  if (props.design.shapes === undefined) {
    return <></>;
  }
  return (
    <>
      {props.size.width > 0 && props.size.height > 0 && (
        <svg
          x={props.position.x}
          y={props.position.y}
          viewBox={viewbox}
          width={props.size.width}
          height={props.size.height}
          // preserveAspectRatio="none"
          onMouseDown={handleMouseDown}
          className={props.className}
        >
          {props.design.shapes.map((shape: ShapeProperties, index: number) => (
            <ShapeSvg
              key={index.toString()}
              uniqueId={props.design.id + "-" + index.toString()}
              shape={shape}
              pointPerPixel={getPointsPerPixel(viewbox, props.size)}
              cursor={props.cursor}
              delimiter="."
              unitScale={UnitScale.METRIC}
            />
          ))}
        </svg>
      )}
    </>
  );
};
