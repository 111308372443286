import { useTranslation } from "react-i18next";

export const ForgottenPasswordConfirmation = () => {
  const { t } = useTranslation();
  return (
    <p id="forgotten-password-confirmation">
      {t("users:resetPasswordConfirmation")}
    </p>
  );
};
