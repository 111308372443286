import { useCallback, useEffect, useMemo, useState } from "react";
import {
  BorderType,
  CanvasOperations,
  CurvedLinePathProperties,
  DimensionProperties,
  EllipseProperties,
  Item,
  ItemOperations,
  ItemType,
  LinePathProperties,
  ModelOperations,
  RectangleProperties,
  ShapeData,
  ShapeOperations,
  ShapeProperties,
  ShapeStyle,
  ShapeType,
  ShapeValidation,
  TextProperties,
} from "draw";
import { Delimiter, Point, Rectangle } from "imagine-essentials";
import {
  DimensionCreator,
  LineCreator,
  RectangleCreator,
  RotateHandle,
  TextCreator,
} from ".";
import { Colors, UnitScale } from "project";

const lineWidthPx = 1;

const getDefaultStyle = (shapeType: ShapeType) => {
  if (shapeType === ShapeType.TEXT) {
    return {
      borderColor: "#000000",
      borderType: BorderType.SOLID,
      fillColor: Colors.grey[0], //white
      opacity: 1,
    } as ShapeStyle;
  } else {
    return {
      borderColor: "#000000",
      borderType: BorderType.SOLID,
      fillColor: Colors.grey[1],
      opacity: 1,
    } as ShapeStyle;
  }
};

const dimensionStyle = {
  borderColor: Colors.grey[7],
};

// The padding used for the item rectangle containing the shape
// Do not change after release, the shapes on the saved plans will change position and size
// 08/11-21: Changed to 0. Otherwise setting the width and height on objects in the input fields are misleading
const containerPadding = 0;

interface Props {
  shapeType: ShapeType;
  item: Item | null;
  onEditingStart?: () => void;
  onItemChange: (item: Item) => void;
  onFinish: () => void;
  onRightClick?: (position: Point) => void;
  onClick?: () => void;
  // displayRotation?: number; // If set it will override the item rotation with this and display it
  cursor?: string; // Overrides default cursor if set
  style?: ShapeStyle;
  zoom: number;
  zeroReference: Point;
  canvasOffset: Point;
  snapEnabled: boolean;
  delimiter: Delimiter;
  unitScale: UnitScale;
  onEditText?: () => void;
  visibleRectangle: Rectangle;
}

/**
 * The editor overlay used when shapes are created or edited
 */
export const ShapeCreator = (props: Props) => {
  const { onEditingStart, onItemChange, onFinish } = props;
  // Local items holds click data between zooms for line shapes
  const [localItem, setLocalItem] = useState(props.item);

  const [rotationDiff, setRotationDiff] = useState(0);
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    setLocalItem(props.item);
  }, [props.item]);

  const editText = () => {
    if (props.onEditText) {
      props.onEditText();
    }
  };

  // Get style in this priority
  // 1. Style on item
  // 2. Style specified in prop
  // 3. Default style
  const style =
    props.item?.shapeProperties?.style !== undefined
      ? props.item.shapeProperties.style
      : props.style !== undefined
      ? props.style
      : getDefaultStyle(props.shapeType);

  const rotation = useMemo(() => {
    if (props.item === null) return 0;
    // if (props.displayRotation !== undefined) return props.displayRotation;
    return props.item.rotation + rotationDiff;
  }, [props.item, rotationDiff]);

  // Holds the data for rectangles and ellipses
  const rectanglePx = useMemo(() => {
    if (props.item === null)
      return ShapeData.getEmptyShapeProperties(
        ShapeType.RECTANGLE
      ) as RectangleProperties;
    if (
      props.item.shapeProperties?.type === ShapeType.RECTANGLE ||
      props.item.shapeProperties?.type === ShapeType.ELLIPSE
    ) {
      return ItemOperations.getRectangleFromItemContainer(
        props.item,
        props.zoom,
        props.zeroReference,
        containerPadding
      );
    }
    return null;
  }, [props.item, props.zoom, props.zeroReference]);

  /**
   * Holds the data for curved and straight lines
   * Create the curves in px based on the item curves. Nothing happens if the selected
   * shape is not a curve or a line.
   */
  const curvesPx = useMemo(() => {
    if (localItem === null)
      return ShapeData.getEmptyShapeProperties(
        ShapeType.CURVED_LINE
      ) as CurvedLinePathProperties;
    if (
      localItem.shapeProperties?.type === ShapeType.CURVED_LINE ||
      localItem.shapeProperties?.type === ShapeType.LINE
    ) {
      if (
        localItem.shapeProperties?.type === ShapeType.CURVED_LINE &&
        ShapeValidation.isCurvedLinePath(localItem.shapeProperties?.properties)
      ) {
        const curveProperties = localItem.shapeProperties
          ?.properties as CurvedLinePathProperties;
        // Offset if the absolute position in px of the containing item
        const itemPosPx = CanvasOperations.unitToPixelPosition(
          localItem.position,
          props.zoom,
          props.zeroReference
        );

        return ItemOperations.convertCurvesToPx(
          curveProperties,
          props.zoom,
          itemPosPx
        );
      } else if (
        localItem.shapeProperties?.type === ShapeType.LINE &&
        ShapeValidation.isLinePath(localItem.shapeProperties?.properties)
      ) {
        const lineProperties = localItem.shapeProperties
          ?.properties as LinePathProperties;
        // Offset if the absolute position in px of the containing item
        const itemPosPx = CanvasOperations.unitToPixelPosition(
          localItem.position,
          props.zoom,
          props.zeroReference
        );
        // The straigh lines must be converted to curve format to be supported in the LineCreator component
        const curveProperties =
          ShapeOperations.straightToCurvedLine(lineProperties);
        return ItemOperations.convertCurvesToPx(
          curveProperties,
          props.zoom,
          itemPosPx
        );
      } else {
        console.error("Item shape type is not curve or line");
        return null;
      }
    }
    return null;
  }, [localItem, props.zoom, props.zeroReference]);

  // Holds the data for dimensions
  const dimensionPx = useMemo(() => {
    if (props.item === null)
      return ShapeData.getEmptyShapeProperties(
        ShapeType.DIMENSION
      ) as DimensionProperties;
    if (props.item.shapeProperties?.type === ShapeType.DIMENSION) {
      if (ShapeValidation.isDimension(props.item.shapeProperties?.properties)) {
        const dimensionProperties = props.item.shapeProperties
          ?.properties as DimensionProperties;
        // Offset if the absolute position in px of the containing item
        const itemPosPx = CanvasOperations.unitToPixelPosition(
          props.item.position,
          props.zoom,
          props.zeroReference
        );
        // Convert dimension points to px points
        return {
          start: CanvasOperations.unitToPixelPosition(
            dimensionProperties.start,
            props.zoom,
            itemPosPx
          ),
          end: CanvasOperations.unitToPixelPosition(
            dimensionProperties.end,
            props.zoom,
            itemPosPx
          ),
        };
      } else {
        console.error("Item shape type is not dimension");
      }
    }
    return null;
  }, [props.item, props.zoom, props.zeroReference]);

  const textPx = useMemo(() => {
    if (props.item === null)
      return ShapeData.getEmptyShapeProperties(
        ShapeType.TEXT
      ) as TextProperties;
    if (props.item.shapeProperties?.type === ShapeType.TEXT) {
      if (ShapeValidation.isText(props.item.shapeProperties?.properties)) {
        const updatedTextShape = props.item.shapeProperties
          ?.properties as TextProperties;
        const pos = CanvasOperations.unitToPixelPosition(
          props.item.position,
          props.zoom,
          props.zeroReference
        );

        const newTextProperties = {
          text: updatedTextShape.text,
          position: pos,
          size: CanvasOperations.unitToPixelSize(
            updatedTextShape.size,
            props.zoom
          ), // In static mode this is overridden in the TextCreator
          fontSize:
            CanvasOperations.unitToPixel(
              updatedTextShape.fontSize ?? 50,
              props.zoom
            ) / 100, // Font size is in cm
          textAlign: updatedTextShape.textAlign,
        } as TextProperties;
        return newTextProperties;
      }
    }
    return null;
  }, [props.item, props.zoom, props.zeroReference]);

  const updateRectangleShape = useCallback(
    (rect: RectangleProperties) => {
      // The react is in px relative to the board's [0,0]. The itemContainer is in units relative to the zero reference.
      const itemContainer = ItemOperations.getItemContainerFromRectangle(
        rect,
        props.zoom,
        props.zeroReference
      );
      // The rectangle in units relative to the canvas
      const size = CanvasOperations.pixelToUnitSize(rect.size, props.zoom);
      const pos = CanvasOperations.pixelToUnitPosition(
        rect.position,
        props.zoom,
        props.zeroReference
      );

      const viewBox =
        "0 0 " + itemContainer.size.width + " " + itemContainer.size.height;
      // The rectangle in units relative to the item container
      const rectangleShape: RectangleProperties = {
        size: size,
        position: CanvasOperations.getPointSubtracted(
          pos,
          itemContainer.position
        ),
      };
      // The shape set in the item depends on whether this is actually a rectangle or an ellipse
      let shapeData: RectangleProperties | EllipseProperties;
      if (props.shapeType === ShapeType.RECTANGLE) shapeData = rectangleShape;
      else if (props.shapeType === ShapeType.ELLIPSE)
        shapeData = ShapeOperations.rectangleToEllipse(rectangleShape);
      else
        throw new Error(
          "updateRectangleShape was call without a rectangle or ellipse shape type set"
        );

      let referenceItem: Item;
      if (props.item !== null) {
        referenceItem = { ...props.item, rotation: rotation };
      } else {
        referenceItem = ItemOperations.getEmptyItem(ItemType.SHAPE, rotation);
        const shape: ShapeProperties = {
          type: props.shapeType,
          properties: shapeData,
          style: style,
        };
        referenceItem.shapeProperties = shape;
      }

      const newItem: Item = {
        ...referenceItem,
        position: itemContainer.position,
        size: itemContainer.size,
        viewBox: viewBox,
        shapeProperties: {
          ...referenceItem.shapeProperties!,
          properties: shapeData,
        },
      };
      onItemChange(newItem);
    },
    [
      onItemChange,
      props.item,
      props.shapeType,
      rotation,
      style,
      props.zeroReference,
      props.zoom,
    ]
  );

  /**
   * Takes the curves in px and converts it to units and updates the corresponding item. The parent
   * are not notified until the LineCreator triggers the onFinished event. Triggered whenever at new point is created.
   * @param newCurves
   */
  const updateCurveShape = useCallback(
    (newCurves: CurvedLinePathProperties, ready: boolean) => {
      // The containing item rectangle in units
      const itemContainer = ItemOperations.getItemContainerFromCurves(
        newCurves.curves,
        props.zoom,
        props.zeroReference,
        props.shapeType,
        containerPadding
      );

      const viewBox =
        "0 0 " + itemContainer.size.width + " " + itemContainer.size.height;

      // Curves reference is the containers absolute position (in px)
      const curvesReference = CanvasOperations.unitToPixelPosition(
        itemContainer.position,
        props.zoom,
        props.zeroReference
      );
      // Convert the curves from px to unit
      const curvedLinesShape = ItemOperations.convertCurvesToUnit(
        newCurves,
        props.zoom,
        curvesReference
      );

      // The shape set in the item depends on whether this is actually a rectangle or an ellipse
      let shapeData: CurvedLinePathProperties | LinePathProperties;
      if (props.shapeType === ShapeType.CURVED_LINE)
        shapeData = curvedLinesShape;
      else if (props.shapeType === ShapeType.LINE)
        shapeData = ShapeOperations.curvedToStraightLine(curvedLinesShape);
      else
        throw new Error(
          "updateCurveShape was called without a curve or line shape type set"
        );

      let referenceItem: Item;
      if (props.item !== null) {
        referenceItem = { ...props.item, rotation: rotation };
      } else {
        referenceItem = ItemOperations.getEmptyItem(ItemType.SHAPE, rotation);
        const shape: ShapeProperties = {
          type: props.shapeType,
          properties: shapeData,
          style: style,
        };
        referenceItem.shapeProperties = shape;
      }

      const newItem: Item = {
        ...referenceItem,
        position: itemContainer.position,
        size: itemContainer.size,
        viewBox: viewBox,
        shapeProperties: {
          ...referenceItem.shapeProperties!,
          properties: shapeData,
        },
      };

      setLocalItem(newItem);

      if (ready) {
        onItemChange(newItem);
        onFinish();
      }
    },
    [
      onFinish,
      onItemChange,
      props.item,
      props.shapeType,
      rotation,
      style,
      props.zeroReference,
      props.zoom,
    ]
  );

  const updateDimensionShape = useCallback(
    (dim: DimensionProperties) => {
      // Must have enough room for the width of label for vertical dimensions
      // 20/1: This extra width/height is given in the model, because it depends on the zoom level (px value)
      const rect = ShapeOperations.dimensionToRectangle(dim); // Or zero
      // The containing item rectangle in units
      const itemContainer = ItemOperations.getItemContainerFromRectangle(
        rect,
        props.zoom,
        props.zeroReference
      );
      // The rectangle in units relative to the canvas
      const viewBox =
        "0 0 " + itemContainer.size.width + " " + itemContainer.size.height;
      const reference = CanvasOperations.unitToPixelPosition(
        itemContainer.position,
        props.zoom,
        props.zeroReference
      );
      // The dimension in units relative to the item container
      const dimensionShape: DimensionProperties = {
        start: CanvasOperations.pixelToUnitPosition(
          dim.start,
          props.zoom,
          reference
        ),
        end: CanvasOperations.pixelToUnitPosition(
          dim.end,
          props.zoom,
          reference
        ),
      };

      let referenceItem: Item;
      if (props.item !== null) {
        referenceItem = props.item;
      } else {
        referenceItem = ItemOperations.getEmptyItem(ItemType.SHAPE, rotation);
        const shape: ShapeProperties = {
          type: props.shapeType,
          properties: dimensionShape, // Will be overwritten below, but needed to follow type
          style: style,
        };
        referenceItem.shapeProperties = shape;
      }

      const newItem: Item = {
        ...referenceItem,
        position: itemContainer.position,
        size: itemContainer.size,
        viewBox: viewBox,
        shapeProperties: {
          ...referenceItem.shapeProperties!,
          properties: dimensionShape,
        },
      };
      onItemChange(newItem);
    },
    [
      onItemChange,
      props.item,
      props.shapeType,
      rotation,
      style,
      props.zeroReference,
      props.zoom,
    ]
  );

  const updateTextShape = useCallback(
    (t: TextProperties) => {
      const rect = {
        size: t.size,
        position: t.position,
      };
      const itemContainer = ItemOperations.getItemContainerFromRectangle(
        rect,
        props.zoom,
        props.zeroReference
      );
      // The rectangle in units relative to the canvas
      const size = CanvasOperations.pixelToUnitSize(rect.size, props.zoom);
      const pos = CanvasOperations.pixelToUnitPosition(
        rect.position,
        props.zoom,
        props.zeroReference
      );

      const viewBox =
        "0 0 " + itemContainer.size.width + " " + itemContainer.size.height;
      // The rectangle in units relative to the item container
      const textShape: TextProperties = {
        size: size,
        position: CanvasOperations.getPointSubtracted(
          pos,
          itemContainer.position
        ),
        text: t.text,
        fontSize:
          (props.item?.shapeProperties?.properties as TextProperties)
            ?.fontSize ?? t.fontSize,
        textAlign: t.textAlign,
      };

      // const textShape = {
      //   text: t.text,
      //   position: { x: 0, y: 0 }, // The item holds the (unit) position
      //   size: { ...t.size },
      //   // mode: t.mode,
      // };

      let referenceItem: Item;
      if (props.item !== null) {
        referenceItem = props.item;
      } else {
        referenceItem = ItemOperations.getEmptyItem(ItemType.SHAPE, rotation);
        const shape: ShapeProperties = {
          type: props.shapeType,
          properties: textShape, // Will be overwritten below, but needed to follow type
          style: style,
        };
        referenceItem.shapeProperties = shape;
      }

      const newItem: Item = {
        ...referenceItem,
        position: itemContainer.position,
        size: itemContainer.size,
        viewBox: viewBox,
        shapeProperties: {
          ...referenceItem.shapeProperties!,
          properties: textShape,
        },
      };
      onItemChange(newItem);
    },
    [
      onItemChange,
      props.item,
      props.shapeType,
      rotation,
      style,
      props.zeroReference,
      props.zoom,
    ]
  );

  const rotationAllowed = useMemo(() => {
    // Hide rotation icon while shape is being resized/modified
    if (props.item === null) {
      return false;
    }
    if (props.shapeType === ShapeType.DIMENSION) {
      return false;
    }
    return true;
  }, [props.item, props.shapeType]);

  const itemModel = useMemo(() => {
    if (props.item === null) return null;
    const rotatedItem = {
      ...props.item,
      rotation: props.item.rotation + rotationDiff,
    };
    return ModelOperations.getModelFromItem(
      rotatedItem,
      props.zoom,
      props.zeroReference,
      [],
      [],
      0,
      false
    );
  }, [props.item, props.zeroReference, props.zoom, rotationDiff]);

  const startRotating = useCallback(() => {
    setRotationDiff(0);
  }, []);

  const updateRotation = useCallback(
    (rotation: number) => {
      if (props.item === null) return;
      setRotationDiff(rotation - props.item.rotation);
    },
    [props.item]
  );

  const finishRotating = useCallback(() => {
    if (props.item === null) return;
    const newItem = {
      ...props.item,
      rotation: props.item?.rotation + rotationDiff,
    };
    props.onItemChange(newItem);
    setRotationDiff(0);
  }, [props, rotationDiff]);

  return (
    <>
      {(props.shapeType === ShapeType.RECTANGLE ||
        props.shapeType === ShapeType.ELLIPSE) &&
        rectanglePx !== null && (
          <RectangleCreator
            lineWidth={lineWidthPx}
            style={style}
            dimensionStyle={dimensionStyle}
            rectangle={rectanglePx}
            shapeId={props.item?.id ?? 0}
            onEditingChange={setIsEditing}
            onRectangleChange={updateRectangleShape}
            update={props.item !== null}
            shapeType={props.shapeType}
            rotation={rotation}
            onRightClick={props.onRightClick}
            onLongTouch={props.onRightClick}
            onClick={props.onClick}
            className="creator"
            zoom={props.zoom}
            canvasOffset={props.canvasOffset}
            cursorOverride={props.cursor}
            disabled={rotationDiff !== 0}
            delimiter={props.delimiter}
            unitScale={props.unitScale}
          />
        )}
      {(props.shapeType === ShapeType.CURVED_LINE ||
        props.shapeType === ShapeType.LINE) &&
        curvesPx !== null && (
          <LineCreator
            lineWidth={lineWidthPx}
            style={style}
            dimensionStyle={dimensionStyle}
            curves={curvesPx}
            shapeId={props.item?.id ?? 0}
            onEditingChange={setIsEditing}
            onLinesChange={updateCurveShape}
            update={props.item !== null}
            shapeType={props.shapeType}
            rotation={rotation}
            onRightClicked={props.onRightClick}
            onLongTouch={props.onRightClick}
            onClick={props.onClick}
            className={"creator"}
            cursor={props.cursor}
            zoom={props.zoom}
            canvasOffset={props.canvasOffset}
            snapEnabled={props.snapEnabled}
            disabled={rotationDiff !== 0}
            delimiter={props.delimiter}
            unitScale={props.unitScale}
            visibleRectangle={props.visibleRectangle}
          />
        )}
      {props.shapeType === ShapeType.DIMENSION && dimensionPx !== null && (
        <DimensionCreator
          lineWidth={lineWidthPx}
          style={style}
          dimension={dimensionPx}
          shapeId={props.item?.id ?? 0}
          onEditingStarted={onEditingStart}
          onChanged={updateDimensionShape}
          update={props.item !== null}
          onFinished={onFinish}
          onRightClicked={props.onRightClick}
          onLongTouch={props.onRightClick}
          onClick={props.onClick}
          className="creator"
          canvasOffset={props.canvasOffset}
          zoom={props.zoom}
          delimiter={props.delimiter}
          unitScale={props.unitScale}
        />
      )}
      {props.shapeType === ShapeType.TEXT && textPx !== null && (
        <TextCreator
          style={style}
          text={textPx}
          shapeId={props.item?.id ?? 0}
          onEditingChange={setIsEditing}
          onTextShapeChange={updateTextShape}
          update={props.item !== null}
          rotation={rotation}
          onRightClick={props.onRightClick}
          onLongTouch={props.onRightClick}
          onClick={props.onClick}
          className="creator"
          canvasOffset={props.canvasOffset}
          disabled={rotationDiff !== 0}
          onEditText={editText}
        />
      )}
      {rotationAllowed && itemModel && !isEditing && (
        <RotateHandle
          position={itemModel.position}
          size={itemModel.size}
          rotation={itemModel.rotation}
          onRotationStarted={startRotating}
          onRotationChanged={updateRotation}
          onRotationFinished={finishRotating}
          snap={props.snapEnabled}
          canvasOffset={props.canvasOffset}
        ></RotateHandle>
      )}
    </>
  );
};
