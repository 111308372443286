import { t } from "i18next";
import { ColorGroup } from "..";
import { Colors } from "project";

export enum ThemeColor {
  NONE = 0,
  PRIMARY = 1,
  SECONDARY = 2,
  LIGHT = 3,
  INFO = 4,
  HIGHLIGHT = 5,
  DANGER = 6,
  WHITE = 7,
  BLACK = 8,
}

/**
 * Get the color group that a given hex color belongs to. The hex color must
 * be one of the predefined colors.
 * @param hexCode
 */
const getColorGroup = (hexCode: string | undefined) => {
  if (hexCode === undefined || hexCode === "") return ColorGroup.NONE;
  if (hexCode === "#000000") return ColorGroup.BLACK;
  else if (hexCode === "#ffffff") return ColorGroup.WHITE;
  else if (Colors.yellow.includes(hexCode)) return ColorGroup.YELLOW;
  else if (Colors.orange.includes(hexCode)) return ColorGroup.ORANGE;
  else if (Colors.red.includes(hexCode)) return ColorGroup.RED;
  else if (Colors.pink.includes(hexCode)) return ColorGroup.PINK;
  else if (Colors.violet.includes(hexCode)) return ColorGroup.VIOLET;
  else if (Colors.blue.includes(hexCode)) return ColorGroup.BLUE;
  else if (Colors.green.includes(hexCode)) return ColorGroup.GREEN;
  else if (Colors.brown.includes(hexCode)) return ColorGroup.BROWN;
  else if (Colors.grey.includes(hexCode)) return ColorGroup.GREY;
  else return ColorGroup.UNKNOWN;
};

const getShiftedColor = (hexCode: string, steps: number) => {
  const colorMatrix = Colors.colorMatrix;
  let shiftedColor = hexCode;
  colorMatrix.forEach((colors: string[]) => {
    const index = colors.indexOf(hexCode);
    if (index !== -1) {
      let shiftedIndex = index + steps;
      if (shiftedIndex < 0) shiftedIndex = 0;
      if (shiftedIndex >= colors.length) shiftedIndex = colors.length - 1;
      shiftedColor = colors[shiftedIndex];
      return;
    }
  });
  return shiftedColor;
};

const defaultGrey = () => {
  return Colors.grey[6];
};

/**
 * Get the text representation of a color.
 * @param group The color enum.
 */
const getColorGroupText = (group: ColorGroup | number) => {
  switch (group) {
    case ColorGroup.WHITE:
      return t("draw:white");
    case ColorGroup.BLACK:
      return t("draw:black");
    case ColorGroup.YELLOW:
      return t("draw:yellow");
    case ColorGroup.ORANGE:
      return t("draw:orange");
    case ColorGroup.RED:
      return t("draw:red");
    case ColorGroup.PINK:
      return t("draw:pink");
    case ColorGroup.VIOLET:
      return t("draw:violet");
    case ColorGroup.BLUE:
      return t("draw:blue");
    case ColorGroup.GREEN:
      return t("draw:green");
    case ColorGroup.BROWN:
      return t("draw:brown");
    case ColorGroup.GREY:
      return t("draw:grey");
    case ColorGroup.NONE:
      return t("imagine:none");
    default:
      return t("draw:unknownColor");
  }
};

const getColorText = (hexColor: string) => {
  if (hexColor === "") return "";
  const group = getColorGroup(hexColor);
  return getColorGroupText(group);
};

export const ColorUtils = {
  defaultGrey,
  getColorGroup,
  getColorGroupText,
  getShiftedColor,
  getColorText,
};
