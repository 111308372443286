import { useCallback, useEffect, useMemo } from "react";
import { CanvasOperations, Item } from "draw";
import { Point } from "imagine-essentials";
import { SelectedItems } from "../../components";
import {
  CanvasActions,
  CanvasSelector,
  LayoutSelector,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { Pages } from "../../enums";
import { I18nTools } from "imagine-i18n";

export const SelectedPlanItemsContainer = () => {
  const dispatch = useAppDispatch();
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);

  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const plantTemplates = useAppSelector(PlanEditorSelector.getPlantTemplates);
  const objectTemplates = useAppSelector(PlanEditorSelector.getObjectTemplates);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const month = useAppSelector(PlanEditorSelector.getPreviewPlanMonth);
  const isAddingItem = useAppSelector(PlanEditorSelector.isAddingItem);
  const selectedItemIds = useAppSelector(PlanEditorSelector.getSelectedItemIds);
  const viewport = useAppSelector(CanvasSelector.getVisibleRectangle);
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const setSelectedItems = useCallback(
    (items: Item[]) => {
      if (objectMode) {
        dispatch(ObjectEditorActions.setSelectedItems(items));
      } else {
        dispatch(PlanEditorActions.setSelectedItems(items));
      }
    },
    [dispatch, objectMode]
  );

  const updateMultipleItems = useCallback(
    (items: Item[]) => {
      if (objectMode) {
        dispatch(ObjectEditorActions.updateMultipleItems(items));
      } else {
        dispatch(PlanEditorActions.updateMultipleItems(items));
      }
    },
    [dispatch, objectMode]
  );

  const openItemContextMenu = (itemId: number, position: Point) => {
    const pos = CanvasOperations.getPointSubtracted(position, canvasOffset);
    dispatch(CanvasActions.openContextMenu(pos));
  };

  const closeContextMenu = () => {
    dispatch(CanvasActions.closeContextMenu());
  };

  const deselectItem = (itemId: number) => {
    if (selectedItemIds.length > 1) {
      if (selectedItemIds.includes(itemId)) {
        const newSelection = selectedItems.filter((item: Item) => {
          return item.id !== itemId;
        });
        setSelectedItems(newSelection);
      }
    }
  };

  const updateSelectedItems = (updatedItems: Item[]) => {
    updateMultipleItems(updatedItems);
  };

  return (
    <SelectedItems
      items={selectedItems}
      showPlantHeights={userPreferences.showPlantHeights}
      onItemClick={closeContextMenu}
      onItemCtrlClick={deselectItem}
      onItemRightClick={openItemContextMenu}
      onItemsChange={updateSelectedItems}
      new={isAddingItem}
      canvasOffset={canvasOffset}
      canvasSize={canvasSize}
      layers={layers}
      groups={groups}
      plantTemplates={plantTemplates}
      objectTemplates={objectTemplates}
      zoom={zoom}
      zeroReference={zeroReference}
      month={month}
      snap={userPreferences.snap}
      viewport={viewport}
      disabled={displayedPage !== Pages.NONE}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
    />
  );
};
