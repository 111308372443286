import { useMemo } from "react";
import { EventProcess, ItemCorner } from "draw";
import { Point, Size, Tools } from "imagine-essentials";
import { Device } from "imagine-ui";

type Props = {
  elementId?: string;
  onResizeStarted?: (corner: ItemCorner, position: Point) => void; // Position is relative to window
  pixelSize: Size; // The size of the parent element in px
  position: Point; // Px corner position
  preserveAspectRatio?: boolean; /// If set, the aspect ratio cannot be changed
  resizable?: boolean; /// True if the item is resizable, otherwise only the white outline is. Default is true
  rotation: number;
  widthOnly?: boolean; // If only the width is resizable
  className?: string;
};

const handleColor = "#FFFFFF";
const lineColor = "#989ca0";
const borderColor = "#989ca0";
const grapSizePx = Device.isTouchDevice() ? 24 : 12;
const lineWidthPx = 1;

const getHandleAngle = (handle: ItemCorner) => {
  switch (handle) {
    case ItemCorner.N:
      return 0;
    case ItemCorner.NE:
      return 45;
    case ItemCorner.E:
      return 90;
    case ItemCorner.SE:
      return 135;
    case ItemCorner.S:
      return 180;
    case ItemCorner.SW:
      return -135;
    case ItemCorner.W:
      return -90;
    case ItemCorner.NW:
      return -45;
    default:
      return 0;
  }
};

export const ResizeSvg = (props: Props) => {
  // Radius of grabbers in points
  const grabSize = useMemo(() => {
    if (props.pixelSize.width === 0 || props.pixelSize.height === 0) {
      // console.warn("Divide by zero avoided");
      return { width: 0, height: 0 };
    }

    const pointsPerPixel = {
      x: 100 / props.pixelSize.width,
      y: 100 / props.pixelSize.height,
    };
    // Divide with 2 to get radius
    return {
      width: Tools.round((grapSizePx * pointsPerPixel.x) / 2, 3),
      height: Tools.round((grapSizePx * pointsPerPixel.y) / 2, 3),
    } as Size;
  }, [props.pixelSize]);

  // const [grabSize, setGrabSize] = useState(getGrabSize());

  const getCursorStyle = (handle: ItemCorner) => {
    let angle = getHandleAngle(handle) + props.rotation;
    if (angle > 180) angle = angle - 360;
    else if (angle < -180) angle = angle + 360;

    if (angle >= 0) {
      if (angle < 22.5) return "n-resize";
      if (angle < 67.5) return "ne-resize";
      if (angle < 112.5) return "e-resize";
      if (angle < 157.5) return "se-resize";
      if (angle <= 180) return "s-resize";
    } else {
      if (angle > -22.5) return "n-resize";
      if (angle > -67.5) return "nw-resize";
      if (angle > -112.5) return "w-resize";
      if (angle > -157.5) return "sw-resize";
      if (angle >= -180) return "s-resize";
    }
  };

  const startResize = (corner: ItemCorner, event: any) => {
    // If stopPropagation is set, the general touchstart event is not triggered, and move is then ignored afterwards
    // (don't remember why this was added in the first place - maybe ealier when some of the resize handles were ignored)
    // event.stopPropagation();

    //event.stopPropagation();
    // const pos = BoardConversion.getPointSubtracted(BoardConversion.getEventPosition(event), boardDetails.offset);
    event.stopPropagation();
    const pos = EventProcess.getEventPosition(event);
    if (props.onResizeStarted) {
      props.onResizeStarted(corner, pos);
    }
  };

  return (
    <svg
      id={props.elementId}
      x={props.position.x - grapSizePx}
      y={props.position.y - grapSizePx}
      viewBox={
        -grabSize.width * 2 +
        " " +
        -grabSize.height * 2 +
        " " +
        (100 + 4 * grabSize.width) +
        " " +
        (100 + 4 * grabSize.height)
      }
      width={props.pixelSize.width + 2 * grapSizePx}
      height={props.pixelSize.height + 2 * grapSizePx}
      preserveAspectRatio="none"
      className={props.className}
    >
      <line
        x1={0}
        y1={0}
        x2={100}
        y2={0}
        stroke={lineColor}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
      <line
        x1={0}
        y1={100}
        x2={100}
        y2={100}
        stroke={lineColor}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
      <line
        x1={0}
        y1={0}
        x2={0}
        y2={100}
        stroke={lineColor}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
      <line
        x1={100}
        y1={0}
        x2={100}
        y2={100}
        stroke={lineColor}
        strokeWidth={lineWidthPx}
        vectorEffect="non-scaling-stroke"
        fill="none"
      />
      {props.resizable && props.widthOnly !== true && (
        <>
          <ellipse
            id="resize-handle-nw"
            cx={0}
            cy={0}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.NW) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.NW, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.NW, event);
            }}
          />
          <ellipse
            id="resize-handle-ne"
            cx={100}
            cy={0}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.NE) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.NE, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.NE, event);
            }}
          />
          <ellipse
            id="resize-handle-sw"
            cx={0}
            cy={100}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.SW) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.SW, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.SW, event);
            }}
          />
          <ellipse
            id="resize-handle-se"
            cx={100}
            cy={100}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.SE) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.SE, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.SE, event);
            }}
          />
        </>
      )}
      {props.preserveAspectRatio === false && props.resizable && (
        <>
          {props.widthOnly !== true && (
            <>
              <ellipse
                id="resize-handle-n"
                cx={50}
                cy={0}
                rx={grabSize.width}
                ry={grabSize.height}
                stroke={borderColor}
                strokeWidth={1}
                vectorEffect="non-scaling-stroke"
                fill={handleColor}
                style={{ cursor: getCursorStyle(ItemCorner.N) }}
                onMouseDown={(event: any) => {
                  startResize(ItemCorner.N, event);
                }}
                onTouchStart={(event: any) => {
                  startResize(ItemCorner.N, event);
                }}
              />
              <ellipse
                id="resize-handle-s"
                cx={50}
                cy={100}
                rx={grabSize.width}
                ry={grabSize.height}
                stroke={borderColor}
                strokeWidth={1}
                vectorEffect="non-scaling-stroke"
                fill={handleColor}
                style={{ cursor: getCursorStyle(ItemCorner.S) }}
                onMouseDown={(event: any) => {
                  startResize(ItemCorner.S, event);
                }}
                onTouchStart={(event: any) => {
                  startResize(ItemCorner.S, event);
                }}
              />
            </>
          )}
          <ellipse
            id="resize-handle-w"
            cx={0}
            cy={50}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.W) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.W, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.W, event);
            }}
          />
          <ellipse
            id="resize-handle-e"
            cx={100}
            cy={50}
            rx={grabSize.width}
            ry={grabSize.height}
            stroke={borderColor}
            strokeWidth={1}
            vectorEffect="non-scaling-stroke"
            fill={handleColor}
            style={{ cursor: getCursorStyle(ItemCorner.E) }}
            onMouseDown={(event: any) => {
              startResize(ItemCorner.E, event);
            }}
            onTouchStart={(event: any) => {
              startResize(ItemCorner.E, event);
            }}
          />
        </>
      )}
    </svg>
  );
};
