import { useTranslation } from "react-i18next";
import { PlantCollection, PlantCollectionCard } from "plants/src";
import {
  Modal,
  Button,
  Label,
  TextInput,
  ImageInput,
  NumberInput,
} from "imagine-ui";
import { useState } from "react";
import { MapLocation } from "../../types";
import { Api, Delimiter } from "imagine-essentials";
import { UnitScale } from "project";
import { MeasureInput } from "draw";

type Props = {
  visible: boolean;
  onClose: () => void;
  errorMessage?: string;
  onSelectImage: (image: File | undefined, distance: number) => void;
  onRemoveImage?: () => void;
  loading?: boolean;
  image?: string;
  distance: number;
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Displays a page with an overview of all plant collections.
 */
export const ReferenceImageModal = (props: Props) => {
  const { t } = useTranslation();

  const [errorMessage, setErrorMessage] = useState("");
  const [imageFile, setImageFile] = useState<File | undefined>(undefined);
  const [distance, setDistance] = useState(props.distance);

  const selectImage = () => {
    props.onSelectImage(imageFile, distance);
  };

  const initialImage = props.image
    ? Api.getHost() +
      "/user-plans/" +
      props.image +
      "?cache=" +
      new Date().getTime()
    : undefined;

  return (
    <Modal
      title={t("referenceImage")}
      visible={props.visible}
      onClose={props.onClose}
      onConfirm={selectImage}
      confirmDisabled={!imageFile}
      confirmText={t("imagine:save")}
      center
      elementId="reference-image-modal"
    >
      <div id="reference-image-page">
        {errorMessage && (
          <div className="alert alert-danger mb">{errorMessage}</div>
        )}
        {props.errorMessage && (
          <div className="alert alert-danger mb">{props.errorMessage}</div>
        )}
        <ImageInput
          id="reference-image"
          onImageSelected={setImageFile}
          onRemoveImage={props.onRemoveImage}
          onError={setErrorMessage}
          maxSize={5}
          initialImage={initialImage}
        />
        {imageFile !== undefined && (
          <div className="mt">
            <Label>{t("approximateWidthOfReferenceImage")}</Label>
            <MeasureInput
              elementId="plant-editor-height-min"
              value={distance}
              delimiter={props.delimiter}
              unitScale={props.unitScale}
              onFinish={setDistance}
              omitFractions
            />
          </div>
        )}
      </div>
    </Modal>
  );
};
