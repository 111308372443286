import { useCallback, useEffect, useState } from "react";
import { Point, Size, Tools } from "imagine-essentials";
import { CanvasOperations } from "draw";

interface Props {
  size: Size;
  zeroReference: Point;
  zoom: number;
  lineColor: string;
  unitScale: number;
}

/**
 * Displays gridlines on the board.
 * @param props
 */
export const Grid = (props: Props) => {
  const [pxSize, setPxSize] = useState({ width: 0, height: 0 });
  // const [pos, setPos] = useState({x: 0, y: 0});

  const [path, setPath] = useState("");

  // This should match the value in Scale component
  // const SCALE_WIDTH = 500;

  const updateGrid = useCallback(() => {
    const step = CanvasOperations.getGridCellSize(props.zoom, props.unitScale);

    const pxInterval = CanvasOperations.unitToPixel(step, props.zoom);

    let pxWidth = props.size.width;
    let pxHeight = props.size.height;

    let newPath = "";
    // Vertical lines
    for (let i = props.zeroReference.x; i < pxWidth; i += pxInterval) {
      if (i > 0) {
        newPath += "M " + Tools.round(i, 0) + " 0";
        newPath += "L " + Tools.round(i, 0) + " " + pxHeight;
      }
    }
    for (let i = props.zeroReference.x - pxInterval; i > 0; i -= pxInterval) {
      if (i < pxWidth) {
        newPath += "M " + Tools.round(i, 0) + " 0";
        newPath += "L " + Tools.round(i, 0) + " " + pxHeight;
      }
    }
    // Horizontal lines
    for (let i = props.zeroReference.y; i < pxHeight; i += pxInterval) {
      if (i > 0) {
        newPath += "M 0 " + Tools.round(i, 0);
        newPath += "L " + pxWidth + " " + Tools.round(i, 0);
      }
    }
    for (let i = props.zeroReference.y - pxInterval; i > 0; i -= pxInterval) {
      if (i < pxHeight) {
        newPath += "M 0 " + Tools.round(i, 0);
        newPath += "L " + pxWidth + " " + Tools.round(i, 0);
      }
    }
    setPath(newPath);
    setPxSize({
      width: pxWidth,
      height: pxHeight,
    });
  }, [
    props.size.height,
    props.size.width,
    props.zeroReference.x,
    props.zeroReference.y,
    props.zoom,
  ]);

  useEffect(() => {
    updateGrid();
  }, [updateGrid]);

  // Pointer events to make sure grid line does not take click from item underneath
  return (
    <svg
      width={pxSize.width}
      height={pxSize.height}
      x={0}
      y={0}
      style={{ pointerEvents: "none" }}
      id="grid"
      className="grid"
    >
      <path
        d={path}
        fill={"none"}
        strokeWidth={0.5}
        stroke={props.lineColor}
        opacity={0.6}
      />
    </svg>
  );
};
