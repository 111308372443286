import { RequestStatus } from "imagine-essentials";
import { PlantCollection } from "plants";
import { RootState } from "..";

export const PlantManagementSelector = {
  getFocusPlantTemplate: (state: RootState) => {
    return state.plantManagement.focusPlantTemplate;
  },
  getCopyPlant: (state: RootState) => {
    return state.plantManagement.copyPlant;
  },
  getSearchResult: (state: RootState) => {
    return state.plantManagement.searchResult;
  },
  getOpenPlantCollection: (state: RootState) => {
    const plantCollection = state.plantManagement.plantCollections.find(
      (collection: PlantCollection) =>
        collection.id === state.plantManagement.openPlantCollectionId
    );
    if (plantCollection === undefined) return null;
    return plantCollection;
  },
  getPlantCollectionsStatus: (state: RootState) => {
    return state.plantManagement.plantCollectionsStatus;
  },
  getPlantCollections: (state: RootState) => {
    return state.plantManagement.plantCollections;
  },
  isPlantCollectionsReady: (state: RootState) => {
    return state.plantManagement.plantCollectionsStatus === RequestStatus.READY;
  },
  getPlantCollectionsTab: (state: RootState) => {
    return state.plantManagement.plantCollectionsTab;
  },
  getPlantCollectionView: (state: RootState) => {
    return state.plantManagement.plantCollectionView;
  },
  getPlantPurchaseList: (state: RootState) => {
    return state.plantManagement.plantPurchaseList;
  },
  getSelectedPlantNurseryIds: (state: RootState) => {
    return state.plantManagement.selectedPlantNurseryIds;
  },
};
