import { UserActions } from "imagine-users";
import { ZoneManager } from "../../components";
import {
  LayoutActions,
  LayoutSelector,
  PlanEditorActions,
  PlanEditorSelector,
  WebsiteSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { ItemOperations, Layer } from "draw";
import { FeatureKey } from "project";
import { useTranslation } from "react-i18next";
import { Button, NotificationType, useNotification } from "imagine-ui";
import { Pages, Tour } from "../../enums";
import { AlertContainer } from "../support-containers/AlertContainer";
import { useMemo } from "react";
import { KeyPageUtils } from "imagine-pages";
import { Language } from "imagine-i18n";
import { Api } from "imagine-essentials";
import { TourContainer } from "../support-containers/TourContainer";

interface Props {
  visible: boolean;
}

export const SupportContainer = (props: Props) => {
  const { t, i18n } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const keyPages = useAppSelector(WebsiteSelector.getKeyPages);
  const currentTour = useAppSelector(LayoutSelector.getCurrentTour);

  const openFeedbackPage = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.FEEDBACK));
  };

  const gettingStartedUrl = useMemo(() => {
    if (i18n.language) {
      return KeyPageUtils.getAbsolutePageUrl(
        3,
        keyPages,
        i18n.language as Language
      );
    }
    return "";
  }, [i18n.language, keyPages]);

  return (
    <>
      {props.visible && (
        <>
          <div className="grid gap columns-1 p w-full ">
            <Button
              className="stretch"
              onClick={openFeedbackPage}
              icon="speak"
              outline
              size="sm"
            >
              {t("users:giveFeedback")}
            </Button>
            <Button
              className="stretch"
              href={gettingStartedUrl}
              target="_blank"
              icon="books"
              outline
              size="sm"
            >
              {t("openGuides")}
            </Button>
          </div>

          {currentTour === Tour.NONE && <AlertContainer />}
          <TourContainer />
        </>
      )}
    </>
  );
};
