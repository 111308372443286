import { useMemo } from "react";
import { Header } from "../../components";

import {
  useAppDispatch,
  useAppSelector,
  LayoutActions,
  PlanEditorActions,
  PlanEditorSelector,
  ObjectEditorSelector,
  PlantManagementActions,
} from "../../store";
import { useTranslation } from "react-i18next";
import { UserActions, UserSelector, UsersApi } from "imagine-users";
import { Pages } from "../../enums";
import { NotificationType, useNotification } from "imagine-ui";
import { UserHelpers } from "project";
import { MembershipActions } from "imagine-memberships";
import { RequestStatus } from "imagine-essentials";

export const HeaderContainer = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const notification = useNotification();
  const user = useAppSelector(UserSelector.getUser);
  const hasPremiumAccess = useAppSelector(UserSelector.hasPremiumAccess);
  const planName = useAppSelector(PlanEditorSelector.getPlanName);
  const unsavedPlan = useAppSelector(PlanEditorSelector.hasUnsavedChanges);
  const unsavedObject = useAppSelector(ObjectEditorSelector.hasUnsavedChanges);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const objectName = useAppSelector(ObjectEditorSelector.getName);
  const planStatus = useAppSelector(PlanEditorSelector.getPlanRequestStatus);

  const title = useMemo(() => {
    // TODO: Don't show any text until plan is loaded/failed, or decided not to load
    if (
      planStatus !== RequestStatus.READY &&
      planStatus !== RequestStatus.FAILED
    )
      return "";
    if (user === null) {
      return t("demoMode");
    }
    if (objectMode) {
      return objectName || t("newObject");
    } else {
      return planName || t("unsavedPlan");
    }
  }, [objectMode, objectName, planName, planStatus, t, user]);

  const unsaved = useMemo(() => {
    if (objectMode) {
      return unsavedObject;
    }
    return unsavedPlan;
  }, [objectMode, unsavedObject, unsavedPlan]);

  const username = useMemo(() => {
    if (user === null) {
      return t("users:login");
    }
    if (user.name) return user.name;
    return user.email;
  }, [t, user]);

  const openLogin = () => {
    if (user === null) {
      dispatch(LayoutActions.setDisplayedPage(Pages.LOGIN));
    }
  };

  const openAccount = () => {
    if (user !== null) {
      dispatch(LayoutActions.setDisplayedPage(Pages.USER_ACCOUNT));
    }
  };

  const openPreferences = () => {
    if (user !== null) {
      dispatch(LayoutActions.setDisplayedPage(Pages.USER_PREFERENCES));
    }
  };

  const logOut = () => {
    console.log("Log out");
    const message = unsaved
      ? t("unsavedChangesConfirmLogout")
      : t("users:confirmLogout");

    notification.add(NotificationType.CONFIRM, message);
    notification.onConfirm(() => {
      UsersApi.logOut();
      dispatch(UserActions.setUser(null));
      dispatch(PlantManagementActions.clearPlantCollections());
      localStorage.removeItem("planId");
      dispatch(
        UserActions.setPreferences(
          UserHelpers.getDefaultPreferences(user?.country)
        )
      );
      dispatch(PlanEditorActions.clearPlan());
      dispatch(MembershipActions.clearMemberships());
    });
  };

  return (
    <Header
      title={title}
      unsaved={unsaved && hasPremiumAccess}
      displayName={username}
      onLogInClicked={openLogin}
      onAccountClick={openAccount}
      onPreferencesClick={openPreferences}
      onLogOutClick={logOut}
      loggedIn={user !== null}
    ></Header>
  );
};
