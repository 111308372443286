import { Rectangle } from "imagine-essentials";

import {
  CanvasActions,
  CanvasSelector,
  LayoutActions,
  ObjectEditorActions,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  useAppDispatch,
  useAppSelector,
} from "../../store";
import { MarkedRectangle } from "../../components";
import { useCallback, useEffect, useMemo } from "react";
import { CursorMode, MarkerMode } from "../../enums";
import { ItemOperations, ShapeType } from "draw";
import { use } from "i18next";

export const MarkedRectangleContainer = () => {
  const dispatch = useAppDispatch();
  const canvasSize = useAppSelector(CanvasSelector.getSize);
  const canvasOffset = useAppSelector(CanvasSelector.getOffset);
  const zoom = useAppSelector(CanvasSelector.getZoom);
  const zeroReference = useAppSelector(CanvasSelector.getZeroReference);
  const isMapFrozen = useAppSelector(CanvasSelector.isMapFrozen);
  const isEnteringText = useAppSelector(CanvasSelector.isEnteringText);
  const cursorMode = useAppSelector(CanvasSelector.getCursorMode);
  const selectedShapeType = useAppSelector(CanvasSelector.getSelectedShapeTool);
  const planItems = useAppSelector(PlanEditorSelector.getVisibleItems);
  const objectItems = useAppSelector(ObjectEditorSelector.getItems);
  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const markerMode = useAppSelector(CanvasSelector.getMarkerMode);
  const isEditingReferenceImage = useAppSelector(
    PlanEditorSelector.isEditingReferenceImage
  );

  const items = useMemo(() => {
    return objectMode ? objectItems : planItems;
  }, [objectMode, objectItems, planItems]);

  const disabled = useMemo(() => {
    return (
      cursorMode === CursorMode.DRAG ||
      selectedShapeType !== ShapeType.NONE ||
      markerMode === MarkerMode.DRAW_VIEW ||
      isEditingReferenceImage
    );
  }, [cursorMode, selectedShapeType, markerMode, isEditingReferenceImage]);

  const updateMarkedRectangle = useCallback(
    (rectangle: Rectangle | null) => {
      if (rectangle !== null) {
        const selectedItems = ItemOperations.getItemsWithinRectangle(
          items,
          rectangle,
          true,
          false
        );
        if (objectMode) {
          dispatch(ObjectEditorActions.setSelectedItems(selectedItems));
        } else {
          dispatch(PlanEditorActions.setSelectedItems(selectedItems));
        }
        if (selectedItems.length > 0) {
          dispatch(LayoutActions.setActiveTab("item-tab"));
        }
      }
    },
    [dispatch, items, objectMode]
  );

  const saveMarkedRectangle = useCallback(
    (rectangle: Rectangle | null) => {
      dispatch(CanvasActions.setMarkedRectangle(rectangle));
    },
    [dispatch]
  );

  return (
    <>
      {!disabled && (
        <MarkedRectangle
          zoom={zoom}
          zeroReference={zeroReference}
          canvasOffset={canvasOffset}
          onChange={updateMarkedRectangle}
          onFinish={saveMarkedRectangle}
        />
      )}
    </>
  );
};
