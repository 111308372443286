import { useTranslation } from "react-i18next";

import {
  useAppDispatch,
  useAppSelector,
  CanvasSelector,
  ObjectEditorSelector,
  PlanEditorActions,
  PlanEditorSelector,
  ObjectEditorActions,
  LayoutActions,
} from "../../store";
import {
  AreaOperations,
  BorderType,
  EllipseProperties,
  ItemType,
  Pattern,
  RectangleProperties,
  ShapeData,
  ShapeStyle,
  ShapeType,
  TextProperties,
} from "draw";
import {
  BorderTypeIcon,
  PatternIcon,
  TextAlignIcon,
  ToolButton,
  ToolColor,
  ToolDropdown,
  ToolDropdownOption,
  ToolGroup,
  ToolNumberInput,
  ToolSeparator,
  ToolSlider,
} from "../../components";
import { useMemo } from "react";
import _ from "lodash";
import { Option } from "imagine-essentials";
import { useNotification } from "imagine-ui";
import { UserSelector } from "imagine-users";
import { Colors, UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";
import { Pages } from "../../enums";

/**
 * Displays the tool and actions in the item toolbar. The component has direct access to the store.
 * @param props
 * @returns
 */
export const ShapeItemToolbarContainer = () => {
  const { t } = useTranslation();
  const selectedPlanItems = useAppSelector(PlanEditorSelector.getSelectedItems);
  const selectedObjectItems = useAppSelector(
    ObjectEditorSelector.getSelectedItems
  );
  const notification = useNotification();
  const dispatch = useAppDispatch();
  const user = useAppSelector(UserSelector.getUserNotNull);
  const delimiter = I18nTools.getDelimiter(user.language);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );

  const objectMode = useAppSelector(ObjectEditorSelector.isObjectMode);
  const unsavedPlanChanges = useAppSelector(
    PlanEditorSelector.hasUnsavedChanges
  );
  const boardSize = useAppSelector(CanvasSelector.getSize);
  const isEditingArea = useAppSelector(PlanEditorSelector.isEditingArea);
  const area = useAppSelector(PlanEditorSelector.getArea);

  const selectedItems = useMemo(() => {
    return objectMode ? selectedObjectItems : selectedPlanItems;
  }, [objectMode, selectedObjectItems, selectedPlanItems]);

  const shapeItem = useMemo(() => {
    if (isEditingArea && area) {
      return AreaOperations.areaToItem(area);
    }
    if (selectedItems.length === 1) {
      if (selectedItems[0].type === ItemType.SHAPE) {
        return selectedItems[0];
      }
    }
    return null;
  }, [area, isEditingArea, selectedItems]);

  const shapeType = shapeItem?.shapeProperties?.type;

  const style = useMemo(() => {
    if (shapeItem === null) return {};
    if (shapeItem.shapeProperties?.style) {
      return shapeItem.shapeProperties.style;
    }
    return {};
  }, [shapeItem]);

  const textSize = useMemo(() => {
    if (shapeItem === null) return 0;
    if (shapeItem.shapeProperties?.type === ShapeType.TEXT) {
      return (shapeItem.shapeProperties.properties as TextProperties)
        .fontSize as number;
    }
    return 0;
  }, [shapeItem]);

  const textAlign = useMemo(() => {
    if (shapeItem === null) return 0;
    if (shapeItem.shapeProperties?.type === ShapeType.TEXT) {
      return (shapeItem.shapeProperties.properties as TextProperties)
        .textAlign as number;
    }
    return 0;
  }, [shapeItem]);

  const patternOptions = useMemo(() => {
    return ShapeData.getPatternOptions().map((option: Option) => {
      return {
        ...option,
        icon: (
          <PatternIcon
            pattern={option.value as Pattern}
            color={style.fillColor || Colors.grey[1]}
            toolTip={option.label}
          />
        ),
      } as ToolDropdownOption;
    });
  }, [style.fillColor]);

  const borderTypeOptions = useMemo(() => {
    return ShapeData.getBorderTypeOptions().map((option: Option) => {
      return {
        ...option,
        icon: <BorderTypeIcon borderType={option.value} />,
      } as ToolDropdownOption;
    });
  }, []);

  const textAlignOptions = useMemo(() => {
    return ShapeData.getTextAlignOptions().map((option: Option) => {
      return {
        ...option,
        icon: <TextAlignIcon align={option.value} />,
      } as ToolDropdownOption;
    });
  }, []);

  const textSizeOptions = useMemo(() => {
    return ShapeData.getTextSizeOptions().map((option: Option) => {
      return {
        ...option,
      } as ToolDropdownOption;
    });
  }, []);

  const updateStyleProperty = (partial: ShapeStyle) => {
    if (shapeItem === null) return;
    const updatedStyle = Object.assign({ ...style }, partial);
    // Make sure the style is copied and not just using a reference (otherwise the parent item style will be changed silently)
    const updatedItem = _.cloneDeep(shapeItem);
    if (updatedItem.shapeProperties) {
      updatedItem.shapeProperties.style = updatedStyle;
    }
    if (objectMode) {
      dispatch(ObjectEditorActions.updateItem(updatedItem));
    } else if (isEditingArea) {
      const updatedArea = AreaOperations.itemToArea(updatedItem);
      dispatch(PlanEditorActions.setArea(updatedArea));
    } else {
      dispatch(PlanEditorActions.updateItem(updatedItem));
    }
  };

  const updateTextProperty = (property: Partial<TextProperties>) => {
    if (shapeItem === null || objectMode) return;
    const updatedProperties = Object.assign(
      { ...shapeItem.shapeProperties?.properties },
      property
    );
    const updatedItem = _.cloneDeep(shapeItem);
    if (updatedItem.shapeProperties) {
      updatedItem.shapeProperties.properties = updatedProperties;
    }

    dispatch(PlanEditorActions.updateItem(updatedItem));
  };

  const shapeWidth = useMemo(() => {
    if (shapeItem === null) return 0;
    if (shapeItem.shapeProperties?.type === ShapeType.RECTANGLE) {
      return (shapeItem.shapeProperties.properties as RectangleProperties).size
        .width;
    }
    if (shapeItem.shapeProperties?.type === ShapeType.ELLIPSE) {
      return (
        (shapeItem.shapeProperties.properties as EllipseProperties).radius
          .width * 2
      );
    }
    return 0;
  }, [shapeItem]);

  const shapeHeight = useMemo(() => {
    if (shapeItem === null) return 0;
    if (shapeItem.shapeProperties?.type === ShapeType.RECTANGLE) {
      return (shapeItem.shapeProperties.properties as RectangleProperties).size
        .height;
    }
    if (shapeItem.shapeProperties?.type === ShapeType.ELLIPSE) {
      return (
        (shapeItem.shapeProperties.properties as EllipseProperties).radius
          .height * 2
      );
    }
    return 0;
  }, [shapeItem]);

  const updateWidthProperty = (width: number) => {
    if (shapeItem === null) return;
    const updatedItem = _.cloneDeep(shapeItem);
    if (shapeItem.shapeProperties?.type === ShapeType.RECTANGLE) {
      updatedItem.shapeProperties.properties.size.width = width;
      updatedItem.size.width = width;
      updatedItem.viewBox = "0 0 " + width + " " + shapeHeight;
    }
    if (shapeItem.shapeProperties?.type === ShapeType.ELLIPSE) {
      updatedItem.shapeProperties.properties.radius.width = width / 2;
      updatedItem.shapeProperties.properties.center.x = width / 2;
      updatedItem.size.width = width;
      updatedItem.viewBox = "0 0 " + width + " " + shapeHeight;
    }
    dispatch(PlanEditorActions.updateItem(updatedItem));
  };

  const updateHeightProperty = (height: number) => {
    if (shapeItem === null) return;
    const updatedItem = _.cloneDeep(shapeItem);
    if (shapeItem.shapeProperties?.type === ShapeType.RECTANGLE) {
      updatedItem.shapeProperties.properties.size.height = height;
      updatedItem.size.height = height;
      updatedItem.viewBox = "0 0 " + shapeWidth + " " + height;
    }
    if (shapeItem.shapeProperties?.type === ShapeType.ELLIPSE) {
      updatedItem.shapeProperties.properties.radius.height = height / 2;
      updatedItem.shapeProperties.properties.center.y = height / 2;
      updatedItem.size.height = height;
      updatedItem.viewBox = "0 0 " + shapeWidth + " " + height;
    }
    dispatch(PlanEditorActions.updateItem(updatedItem));
  };

  const openTextShapeModal = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.TEXT_SHAPE_TEXT));
  };

  return (
    <>
      {shapeItem && (
        <>
          {(shapeType === ShapeType.RECTANGLE ||
            shapeType === ShapeType.ELLIPSE) && (
            <>
              <ToolGroup>
                <ToolNumberInput
                  elementId="width-input"
                  text={t("imagine:width")}
                  value={shapeWidth}
                  onChange={(value: number) => updateWidthProperty(value)}
                  disabled={shapeItem.locked}
                  unitScale={userPreferences.unitScale}
                  delimiter={delimiter}
                />
                <ToolNumberInput
                  elementId="height-input"
                  text={t("draw:length")}
                  value={shapeHeight}
                  onChange={(value: number) => updateHeightProperty(value)}
                  disabled={shapeItem.locked}
                  unitScale={userPreferences.unitScale}
                  delimiter={delimiter}
                />
              </ToolGroup>
              <ToolSeparator />
            </>
          )}
          {shapeType !== ShapeType.DIMENSION && (
            <>
              <ToolGroup>
                {shapeType === ShapeType.TEXT && (
                  <ToolColor
                    elementId="text-color-tool"
                    text={t("textColor")}
                    color={style.borderColor}
                    half
                    onChange={(color: string) =>
                      updateStyleProperty({ borderColor: color })
                    }
                    disabled={shapeItem.locked}
                  />
                )}
                <ToolColor
                  elementId="background-color-tool"
                  text={t("backgroundColor")}
                  color={style.fillColor}
                  half
                  onChange={(color: string) =>
                    updateStyleProperty({ fillColor: color })
                  }
                  disabled={shapeItem.locked}
                />
                {shapeType !== ShapeType.TEXT && (
                  <ToolDropdown
                    options={patternOptions}
                    elementId="fill-pattern-tool"
                    text={t("fillPattern")}
                    selected={style.fillPattern || Pattern.NONE}
                    onSelect={(pattern: Pattern) =>
                      updateStyleProperty({ fillPattern: pattern })
                    }
                    disabled={shapeItem.locked}
                    layout="grid"
                    half
                  />
                )}
              </ToolGroup>
              <ToolSeparator />
              <ToolSlider
                elementId="opacity-slider-tool"
                text={t("opacity")}
                min={0}
                max={1}
                stepSize={0.1}
                value={style.opacity}
                onChange={(opacity: number) =>
                  updateStyleProperty({ opacity: opacity })
                }
                disabled={shapeItem.locked}
              />
              <ToolSeparator />
              {shapeType !== ShapeType.TEXT && (
                <ToolGroup>
                  <ToolDropdown
                    options={borderTypeOptions}
                    elementId="border-type-tool"
                    text={t("borderType")}
                    selected={style.borderType || BorderType.NONE}
                    onSelect={(type: BorderType) =>
                      updateStyleProperty({ borderType: type })
                    }
                    disabled={shapeItem.locked}
                    layout="list"
                    half
                  />
                  <ToolColor
                    elementId="border-color-tool"
                    text={t("borderColor")}
                    color={style.borderColor}
                    half
                    onChange={(color: string) =>
                      updateStyleProperty({ borderColor: color })
                    }
                    disabled={shapeItem.locked}
                  />
                </ToolGroup>
              )}
              {shapeType === ShapeType.TEXT && (
                <>
                  <ToolGroup>
                    <ToolDropdown
                      options={textSizeOptions}
                      elementId="font-size-tool"
                      text={t("textSize")}
                      selected={textSize}
                      icon="text-size"
                      onSelect={(size: number) =>
                        updateTextProperty({ fontSize: size })
                      }
                      disabled={shapeItem.locked}
                      layout="text-list"
                      half
                    />
                    <ToolDropdown
                      options={textAlignOptions}
                      elementId="text-align-tool"
                      text={t("textAlign")}
                      selected={textAlign}
                      onSelect={(align: number) =>
                        updateTextProperty({ textAlign: align })
                      }
                      disabled={shapeItem.locked}
                      layout="list"
                      half
                    />
                  </ToolGroup>
                  <ToolSeparator />
                  <ToolButton
                    elementId="text-edit-button"
                    text={t("editText")}
                    onClick={openTextShapeModal}
                    icon="edit-text"
                    disabled={shapeItem.locked}
                  />
                </>
              )}
            </>
          )}
          {shapeType === ShapeType.DIMENSION && (
            <>
              <ToolGroup>
                <ToolColor
                  elementId="dimension-color-tool"
                  text={t("imagine:color")}
                  color={style.borderColor}
                  onChange={(color: string) =>
                    updateStyleProperty({ borderColor: color })
                  }
                  disabled={shapeItem.locked}
                  half
                />
                <ToolDropdown
                  options={borderTypeOptions}
                  elementId="dimension-line-type-tool"
                  text={t("lineType")}
                  selected={style.borderType || BorderType.NONE}
                  onSelect={(type: BorderType) =>
                    updateStyleProperty({ borderType: type })
                  }
                  disabled={shapeItem.locked}
                  layout="list"
                  half
                />
              </ToolGroup>
            </>
          )}
        </>
      )}
    </>
  );
};
