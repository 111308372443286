import React, { useState, useEffect, useMemo, useCallback } from "react";
import {
  CanvasOperations,
  Group,
  Item,
  ItemCorner,
  ItemOperations,
  ItemType,
  Layer,
  Model,
  ModelOperations,
  ModelSvg,
  ObjectSchema,
  ShapeOperations,
  ShapeType,
  PlantSchema,
  Mouse,
  MouseState,
} from "draw";
import {
  Delimiter,
  Point,
  Rectangle,
  SentryReporter,
  Size,
} from "imagine-essentials";
import { ResizeSvg, RotateHandle } from ".";
import { UnitScale } from "project";

type SelectedItemProps = {
  items: Item[];
  showPlantHeights: boolean;
  onItemClick: (id: number) => void;
  onItemCtrlClick: (id: number) => void;
  onItemRightClick: (id: number, position: Point) => void;
  onItemsChange: (items: Item[]) => void;
  disableRotation?: boolean;
  new?: boolean; // True if the selected item is new and should always follow the mouse
  disabled?: boolean;
  canvasOffset: Point;
  canvasSize: Size;
  layers: Layer[];
  groups: Group[];
  plantTemplates: PlantSchema[];
  objectTemplates: ObjectSchema[];
  zoom: number;
  zeroReference: Point;
  month: number;
  snap: boolean;
  viewport: Rectangle;
  delimiter: Delimiter;
  unitScale: UnitScale;
};

/**
 * Responsible for rendering the items that should have the ability to move or resize.
 */
export const SelectedItems = (props: SelectedItemProps) => {
  // Need to destructure props to use properties as independent dependencies in useCallbacks
  const { onItemsChange } = props;

  /**
   * The items models representing the items in the store. Used as reference when changing selected item models.
   */
  const referenceItemModels = useMemo(() => {
    return ModelOperations.getRenderModels(
      props.layers,
      props.groups,
      props.items,
      props.zoom,
      props.zeroReference,
      props.plantTemplates,
      props.objectTemplates,
      props.month,
      props.showPlantHeights || false,
      false
    );
  }, [
    props.layers,
    props.groups,
    props.items,
    props.zoom,
    props.zeroReference,
    props.plantTemplates,
    props.objectTemplates,
    props.month,
    props.showPlantHeights,
  ]);

  useEffect(() => {}, [props.items, referenceItemModels]);

  const [positionOffset, setPositionOffset] = useState<Point | undefined>(
    undefined
  );
  const [tempRectangle, setTempRectangle] = useState<Rectangle | undefined>(
    undefined
  );
  // const [sizeAddition, setSizeAddition] = useState<Size | undefined>(undefined);
  const [tempRotation, setTempRotation] = useState<number | undefined>(
    undefined
  );

  // States used to manage drag
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  // States used to manage resize
  const [resizeStart, setResizeStart] = useState({ x: 0, y: 0 });
  const [resizeCorner, setResizeCorner] = useState(ItemCorner.NONE);
  // Whether shape is currently being edited (the period the shape is actually being resized/modified)
  const [editingShape, setEditingShape] = useState(false);

  const [rotating, setRotating] = useState(false);
  const [rotation, setRotation] = useState<number | undefined>(undefined);

  useEffect(() => {
    setPositionOffset(undefined);
    setTempRectangle(undefined);
    setTempRotation(undefined);
  }, [props.items]);

  const itemModels = useMemo(() => {
    return ModelOperations.getRenderModels(
      props.layers,
      props.groups,
      props.items,
      props.zoom,
      props.zeroReference,
      props.plantTemplates,
      props.objectTemplates,
      props.month,
      props.showPlantHeights || false,
      false
    )
      .filter((model: Model) => !model.locked)
      .map((model: Model) => {
        const updatedModel = { ...model };
        if (positionOffset !== undefined) {
          updatedModel.position = CanvasOperations.getPointSum(
            model.position,
            positionOffset
          );
        }
        if (tempRectangle !== undefined) {
          updatedModel.size = { ...tempRectangle.size };
          updatedModel.position = { ...tempRectangle.position };
        }
        if (tempRotation !== undefined) {
          updatedModel.rotation = tempRotation;
        }
        return updatedModel;
      });
    // Locked models should never be displayed as selected
  }, [
    props.layers,
    props.groups,
    props.items,
    props.zoom,
    props.zeroReference,
    props.plantTemplates,
    props.objectTemplates,
    props.month,
    props.showPlantHeights,
    positionOffset,
    tempRectangle,
    tempRotation,
  ]);

  // Prepare to start dragging. Will do nothing is already dragging or resizing.
  const grabItem = useCallback(
    (id: number, position: Point) => {
      props.onItemClick(id);
      if (isDragging || resizeCorner !== ItemCorner.NONE || props.disabled)
        return;
      const item = ItemOperations.findItem(props.items, id);
      // Not possible to grab locked items (will also result in error since the model does not exist)
      if (item.locked) return;

      if (props.items.length > 0) {
        // The shape creators handle dragging for single items
        if (props.items.length > 1 || props.items[0].type !== ItemType.SHAPE) {
          setDragStart(position);
          setIsDragging(true);
        }
      }
    },
    [isDragging, props, resizeCorner]
  );

  // Only models are updated while dragging. The items are updated when dragging has finished.
  const dragItems = useCallback(
    (mousePosition: Point) => {
      if (isDragging) {
        const pos = mousePosition;
        const diffX = pos.x - dragStart.x;
        const diffY = pos.y - dragStart.y;
        setPositionOffset({ x: diffX, y: diffY });
      }
    },
    [isDragging, dragStart]
  );

  /**
   * Updates a single item and propagates the entire item list to the parent.
   */
  const updateShapeItem = useCallback(
    (item: Item) => {
      const newItems = ItemOperations.updateItems(props.items, item);
      setEditingShape(false);
      onItemsChange(newItems);
    },
    [onItemsChange, props.items]
  );

  const startEditShape = useCallback(() => {
    setEditingShape(true);
  }, []);

  const finishEditing = () => {};

  /**
   * Get shape type if only one shape item is selected. Otherwise it returns NONE.
   */
  const getShapeType = useMemo(() => {
    if (props.items.length === 1) {
      if (props.items[0].shapeProperties !== undefined) {
        return props.items[0].shapeProperties?.type;
      }
    }
    return ShapeType.NONE;
  }, [props.items]);

  const beginResizing = useCallback(
    (corner: ItemCorner, position: Point) => {
      if (props.disabled) return;
      if (props.items.length !== 1) {
        console.error("Resize only possible when exactly 1 item is selected");
        return;
      }
      if (props.items[0].type === ItemType.SHAPE) {
        // The Shape Creator will handle resizing of the shapes
        return;
      }
      // Not possible to grab locked item (will also result in error since the model does not exist)
      if (props.items[0].locked) return;
      // Item should already be selected
      setResizeStart(position);
      setResizeCorner(corner);
    },
    [props.disabled, props.items]
  );

  /**
   * Resize an item model. Only 1 item model can be resized. The function will not do anything if more than
   * one item is selected.
   */
  const resize = useCallback(
    (mousePosition: Point) => {
      if (resizeCorner !== ItemCorner.NONE && itemModels.length === 1) {
        const rect = ShapeOperations.getResize(
          mousePosition,
          resizeCorner,
          resizeStart,
          {
            size: referenceItemModels[0].size,
            position: referenceItemModels[0].position,
          },
          referenceItemModels[0].rotation,
          props.items[0].type === ItemType.PLANT
        );

        setTempRectangle(rect);
      }
    },
    [referenceItemModels, resizeCorner, itemModels, resizeStart, props.items]
  );

  /**
   * Stop dragging or resizing (depending on which it was doing) and generates the new items that triggers
   * an item change in the parent.
   */
  const stopDraggingAndResizing = useCallback(
    (position: Point) => {
      if (isDragging || resizeCorner !== ItemCorner.NONE) {
        const movedDistancePx = CanvasOperations.calculateDistance(
          position,
          dragStart
        );
        const movedDistanceUnit = CanvasOperations.pixelToUnit(
          movedDistancePx,
          props.zoom
        );

        // If item moved less than 1 cm and was just selected, it should not update
        // (will cause issues for the undo-redo history)
        if (movedDistanceUnit >= 0.01) {
          try {
            const newItems = props.items.map((item: Item) => {
              const itemModel = ModelOperations.findModel(itemModels, item.id);
              const updatedItem = ModelOperations.getUpdatedItemFromModel(
                item,
                itemModel,
                props.zoom,
                props.zeroReference
              );
              return updatedItem;
            });
            onItemsChange(newItems);
            // Clear position offset to avoid the offset to be added to the items in the next render (this will cause a "blink")
            setPositionOffset(undefined);
          } catch (e) {
            console.error(e);
            console.error(
              "Unable to find item model, models are possibly empty due to state change"
            );
            console.error("Items:", JSON.stringify(props.items));
            console.error("Item models:", JSON.stringify(itemModels));
            SentryReporter.captureException(
              "Caught missing item model exception"
            );
          }
        }
        setIsDragging(false);
        setResizeCorner(ItemCorner.NONE);
      }
    },
    [
      isDragging,
      resizeCorner,
      dragStart,
      props.zoom,
      props.items,
      props.zeroReference,
      onItemsChange,
      itemModels,
    ]
  );

  const rotationAllowed = useMemo(() => {
    // Hide rotation icon while shape is being resized/modified
    if (editingShape) {
      return false;
    }

    if (props.disableRotation) {
      return false;
    }
    if (itemModels.length !== 1) return false;
    if (itemModels[0].locked) return false;
    if (itemModels[0].itemType === ItemType.SHAPE) {
      if (itemModels[0].shapes.length === 1) {
        if (itemModels[0].shapes[0].type === ShapeType.DIMENSION) return false;
      }
    }
    return true;
  }, [editingShape, itemModels, props.disableRotation]);

  const startRotating = () => {
    if (props.disabled) return;
    if (!rotating) setRotating(true);
  };

  const updateRotation = useCallback(
    (newRotation: number) => {
      if (itemModels.length > 0) {
        setRotation(newRotation);
        setTempRotation(newRotation);
      }
    },
    [itemModels]
  );

  const finishRotating = (newRotation: number) => {
    if (!rotating) {
      return;
    }
    setRotating(false);
    setRotation(undefined);
    if (props.items.length === 1 && itemModels.length === 1) {
      const newItems = [
        { ...props.items[0], rotation: itemModels[0].rotation },
      ];
      if (props.onItemsChange) {
        props.onItemsChange(newItems);
      }
    }
  };

  useEffect(() => {
    if (props.disabled) return;

    const moveObserver = Mouse.move.subscribe((state: MouseState) => {
      // Do not move items if a page is open
      if (props.disabled) return;
      if (
        !CanvasOperations.isPointWithinRectangle(state.position, props.viewport)
      )
        return;
      if (state.pressed) {
        if (props.items.length > 0 && !rotating) {
          // Drag initially
          grabItem(props.items[0].id, state.position);
        }
        dragItems(state.position);
        resize(state.position);
      }
    });
    const releaseObserver = Mouse.release.subscribe((state: MouseState) => {
      stopDraggingAndResizing(state.position);
    });

    return () => {
      moveObserver.unsubscribe();
      releaseObserver.unsubscribe();
    };
  }, [
    dragItems,
    grabItem,
    props.disabled,
    props.items,
    props.viewport,
    resize,
    rotating,
    stopDraggingAndResizing,
  ]);

  const getModelCursor = (locked: boolean) => {
    return locked ? "pointer" : "move";
  };

  return (
    <>
      {!ItemOperations.isOnlyOneShapeItemSelected(props.items) &&
        itemModels.map((model: Model, index: number) => (
          <g
            key={model.id.toString()}
            transform={
              "rotate(" +
              model.rotation +
              ", " +
              (model.position.x + model.size.width / 2) +
              ", " +
              (model.position.y + model.size.height / 2) +
              ")"
            }
            id={"selecteditem-" + index}
            className="selected-item"
          >
            <ModelSvg
              model={model}
              onMouseDown={(pos: Point) => grabItem(model.id, pos)}
              onTouchStart={(pos: Point) => grabItem(model.id, pos)}
              onLongTouch={(pos: Point) =>
                props.onItemRightClick(model.id, pos)
              }
              onRightClicked={(pos: Point) =>
                props.onItemRightClick(model.id, pos)
              }
              onCtrlClick={() => props.onItemCtrlClick(model.id)}
              className="selected-item"
              cursor={getModelCursor(model.locked ?? false)}
              delimiter={props.delimiter}
              unitScale={props.unitScale}
            />
            <ResizeSvg
              pixelSize={model.size}
              position={model.position}
              onResizeStarted={beginResizing}
              preserveAspectRatio={true}
              resizable={
                model.itemType !== ItemType.SHAPE && itemModels.length === 1
              }
              rotation={model.rotation}
              elementId={"resizer-" + index}
              className="selected-item"
            />
          </g>
        ))}
      {rotationAllowed && (
        <RotateHandle
          position={itemModels[0].position}
          size={itemModels[0].size}
          rotation={itemModels[0].rotation}
          onRotationStarted={startRotating}
          onRotationChanged={updateRotation}
          onRotationFinished={finishRotating}
          canvasOffset={props.canvasOffset}
          snap={props.snap}
        ></RotateHandle>
      )}
    </>
  );
};
