import { Pages } from "../../enums";
import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  PlantManagementSelector,
  LayoutSelector,
  LayoutActions,
} from "../../store";
import { PlantCollection, PlantCollectionOverviewPage } from "plants";

/**
 * Responsible for connection the state and API calls to the plant collection page.
 */
export const PlantCollectionOverviewPageContainer = () => {
  const dispatch = useAppDispatch();
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const plantCollections = useAppSelector(
    PlantManagementSelector.getPlantCollections
  );
  const activeTab = useAppSelector(
    PlantManagementSelector.getPlantCollectionsTab
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  const openPlantCollection = (plantCollection: PlantCollection) => {
    dispatch(PlantManagementActions.openPlantCollection(plantCollection.id));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_COLLECTION));
  };

  const createNewPlantCollection = () => {
    dispatch(PlantManagementActions.clearOpenPlantCollection());
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_COLLECTION));
  };

  const updateActiveTab = (tab: string) => {
    dispatch(PlantManagementActions.setPlantCollectionsTab(tab));
  };

  return (
    <PlantCollectionOverviewPage
      visible={displayedPage === Pages.PLANT_COLLECTIONS_OVERVIEW}
      plantCollections={plantCollections}
      onSelect={openPlantCollection}
      onCreateNew={createNewPlantCollection}
      onClose={close}
      activeTab={activeTab}
      onTabChange={updateActiveTab}
    />
  );
};
