import React, { useState, useCallback } from "react";
import { NotificationContext, NotificationType } from "..";

interface Props {
  children?: React.ReactNode;
}

/**
 * Wrapper for the notification context. This also holds the actual data.
 * @param param0
 */
export const NotificationProvider = (props: Props) => {
  const [type, setType] = useState(NotificationType.NONE);
  const [message, setMessage] = useState("");
  // Need to wrap function in another function without parameters, otherwise React will
  // use it's lazy initialization to call the function instead of setting it
  const [callback, setCallback] = useState(() => () => {
    // Do nothing initially
  });

  /**
   * Anyone should be able to clear the notification.
   */
  const clear = () => {
    setType(NotificationType.NONE);
    setMessage("");
    setCallback(() => () => {
      // Do nothing
    });
  };

  const add = (t: NotificationType, m: string) => {
    setType(t);
    setMessage(m);
  };

  /**
   * Set the confirm function
   */
  const onConfirm = (c: () => void) => {
    // Must be wrapped in argument-less function
    setCallback(() => c);
  };

  /**
   * Call the confirm function
   */
  const confirm = () => {
    // Must call callback before clearing all states
    callback();
    clear();
  };

  /**
   * The actual values stored in the provider and accessible to the entire application.
   * The confirm and onConfirm functions should not be inside useCallback, since the functions
   * actually do change (otherwise inital function will not be overwritten)
   */
  const contextValue = {
    add: useCallback((t: NotificationType, m: string) => add(t, m), []),
    onConfirm: onConfirm,
    clear: useCallback(() => clear(), []),
    confirm: confirm,
    type: type,
    message: message,
  };

  return (
    <NotificationContext.Provider value={contextValue}>
      {props.children}
    </NotificationContext.Provider>
  );
};
