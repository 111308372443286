import { Delimiter, Tools } from "imagine-essentials";
import { NumberInput, TextInput } from "imagine-ui";
import { ImperialMeasure, Measure } from "..";
import { useTranslation } from "react-i18next";
import { useEffect, useMemo } from "react";

interface Props {
  value?: number;
  size?: "sm" | "md" | "lg";
  disabled?: boolean;
  onFinish?: (value: number) => void; // Enter, tab or lost focus
  onEnterPress?: (value: number) => void;
  elementId?: string;
  className?: string;
  invalid?: boolean;
  delimiter: Delimiter;
  omitYards?: boolean;
  omitFractions?: boolean;
}

export const MeasureImperialInput = (props: Props) => {
  const { t } = useTranslation();

  const imperialValue = useMemo(() => {
    return Measure.convertToImperial(
      props.value || 0,
      props.omitYards,
      props.omitFractions
    );
  }, [props.omitFractions, props.omitYards, props.value]);

  const updateValue = (property: Partial<ImperialMeasure>) => {
    const updatedImperialValue = { ...imperialValue, ...property };

    const metricValue = Tools.round(
      Measure.convertFromImperial(
        updatedImperialValue.yards,
        updatedImperialValue.feet,
        updatedImperialValue.inches
      ),
      3
    );
    if (props.onFinish) {
      props.onFinish(metricValue);
    }
  };

  const inchesText = useMemo(() => {
    if (imperialValue.fraction?.numerator) {
      return `${imperialValue.inches} ${imperialValue.fraction.numerator}/${imperialValue.fraction.denominator}`;
    }
    return imperialValue.inches.toString();
  }, [imperialValue]);

  const convertInchesText = (text: string) => {
    let inches = imperialValue.inches;
    const parts = text.split(" ");
    if (parts.length > 0) {
      inches = parseInt(parts[0]);
    }
    if (parts.length > 1) {
      const fractionParts = parts[1].split("/");
      inches += parseInt(fractionParts[0]) / parseInt(fractionParts[1]);
    }
    updateValue({ inches: inches, fraction: undefined });
  };

  return (
    <div className="row gap-xs">
      {!props.omitYards && (
        <NumberInput
          value={imperialValue.yards}
          onFinish={(value) => updateValue({ yards: value })}
          unit={t("draw:yardsUnitShort")}
          delimiter={props.delimiter}
          size={props.size}
        />
      )}
      <NumberInput
        value={imperialValue.feet}
        onFinish={(value) => updateValue({ feet: value })}
        unit={t("draw:feetUnitShort")}
        delimiter={props.delimiter}
        decimals={1}
        size={props.size}
      />

      <TextInput
        value={inchesText}
        size={props.size}
        disabled={props.disabled}
        invalid={props.invalid}
        prependText={t("draw:inchesUnitShort")}
        onFinish={convertInchesText}
        onEnterPress={convertInchesText}
      />
    </div>
  );
};
