import { Point } from "imagine-essentials";
import React, { useEffect, useRef } from "react";
import { Mouse, MouseState } from "draw";

type ContextMenuProps = {
  children?: React.ReactNode;
  position: Point; /// Requested position of the context menu (might adjust if there is not enough space)
  fullscreen?: boolean;
  onClose?: () => void;
};

/**
 * Displays an custom context menu when right clicking.
 * @param props
 */
export const ContextMenu = (props: ContextMenuProps) => {
  const contextRef = useRef<HTMLInputElement>(null);

  const { onClose } = props;

  const cssPosition = {
    left: props.position.x,
    top: props.position.y,
  };

  /**
   * Always close the context menu when an item has been clicked. The item click will
   * propagate to the context menu element
   */
  const handleClick = (event: any) => {
    event.stopPropagation();
    if (props.onClose) props.onClose();
  };

  /**
   * Always close the context menu when an item has been touched. The item touch will
   * propagate to the context menu element
   */
  const handleTouch = (event: any) => {
    event.stopPropagation();
    if (props.onClose) props.onClose();
  };

  const handleTouchEnd = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (props.onClose) props.onClose();
  };

  // Close contect menu on any mouse down event outside of the context menu div
  useEffect(() => {
    const pressObserver = Mouse.press.subscribe((state: MouseState) => {
      const rect = contextRef.current?.getBoundingClientRect();
      if (rect === undefined) return;
      if (
        state.position.x < rect.left ||
        state.position.x > rect.right ||
        state.position.y < rect.top ||
        state.position.y > rect.bottom
      ) {
        if (onClose) onClose();
      }
    });

    return () => {
      pressObserver.unsubscribe();
    };
  }, [onClose]);

  return (
    <>
      <div
        className={"context-menu "}
        style={cssPosition}
        ref={contextRef}
        id="context-menu"
        onClick={handleClick}
        onTouchStart={handleClick}
        onTouchEnd={handleTouchEnd}
      >
        {props.children}
      </div>
    </>
  );
};
