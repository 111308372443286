import { t } from "i18next";
import { PlantCategory } from "../enums";
import { PlantSchema } from "../types";
import { Delimiter, Tools, Option } from "imagine-essentials";
import { ColorUtils } from ".";

/**
 * Get the text representation of a plant category.
 * @param category
 * @returns
 */
const getCategoryText = (category: PlantCategory | number) => {
  const cat = category as PlantCategory;
  switch (cat) {
    case PlantCategory.GRASS:
      return t("plants:grasses");
    case PlantCategory.PERENNIAL:
      return t("plants:perennials");
    case PlantCategory.SHRUB:
      return t("plants:shrubs");
    case PlantCategory.TREE:
      return t("plants:trees");
    case PlantCategory.CLIMBER:
      return t("plants:climbers");
    case PlantCategory.VEGETABLE:
      return t("plants:vegetables");
    case PlantCategory.BULB:
      return t("plants:bulbs");
    case PlantCategory.ANNUALS:
      return t("plants:annuals");
    case PlantCategory.ROSES:
      return t("plants:roses");
    default:
      throw new Error("Unknown plant category" + category);
  }
};

const getCategoryOptions = (includeAll = true) => {
  const options: Option[] = [];
  if (includeAll) {
    options.push({
      value: -1,
      label: t("imagine:all"),
    });
  }
  for (let i = 0; i < PlantCategory._SIZE; i++) {
    options.push({
      value: i,
      label: getCategoryText(i),
    });
  }
  return options;
};

/**
 * Get the full text representation of a plant's height.
 * @param plant
 * @param delimiter
 * @returns
 */
const getHeightTextFull = (plant: PlantSchema, delimiter: Delimiter) => {
  const max = Math.max(plant.heightMin || 0, plant.heightMin || 0);
  let unit = "m";
  let factor = 1;
  let decimals = 2;
  if (max < 1) {
    unit = "cm";
    factor = 100;
    decimals = 0;
  }

  if (plant.heightMin && plant.heightMax) {
    if (plant.heightMin === plant.heightMax) {
      return (
        Tools.numberToText(plant.heightMin * factor, decimals, delimiter) +
        " " +
        unit
      );
    }
    return (
      Tools.numberToText(plant.heightMin * factor, decimals, delimiter) +
      " - " +
      Tools.numberToText(plant.heightMax * factor, decimals, delimiter) +
      " " +
      unit
    );
  } else if (plant.heightMin) {
    return (
      Tools.numberToText(plant.heightMin * factor, decimals, delimiter) +
      " " +
      unit
    );
  } else if (plant.heightMax) {
    return (
      Tools.numberToText(plant.heightMax * factor, decimals, delimiter) +
      " " +
      unit
    );
  } else {
    return "";
  }
};

/**
 * Get the full text representation of a plant's min height.
 * @param plant
 * @param delimiter
 * @returns
 */
const getHeightAvgTextFull = (plant: PlantSchema, delimiter: Delimiter) => {
  if (plant.heightMax === undefined && plant.heightMin === undefined) return "";
  let avg = ((plant.heightMin || 0) + (plant.heightMin || 0)) / 2;
  if (plant.heightMax === undefined) avg = plant.heightMin || 0;
  if (plant.heightMin === undefined) avg = plant.heightMax || 0;

  let unit = "m";
  let factor = 1;
  let decimals = 2;
  if (avg < 1) {
    unit = "cm";
    factor = 100;
    decimals = 0;
  }

  return Tools.numberToText(avg * factor, decimals, delimiter) + " " + unit;
};

/**
 * Get the full text representation of a plants width.
 * @param plant
 * @param delimiter
 * @returns
 */
const getWidthText = (plant: PlantSchema, delimiter: Delimiter) => {
  let factor = 1;
  let unit = "m";
  if (plant.width < 1) {
    unit = "cm";
    factor = 100;
  }
  return Tools.numberToText(plant.width * factor, 2, delimiter) + " " + unit;
};

/**
 * Get the text representation of a plant's flower color.
 * @param plant
 * @returns
 */
const getFloweringColorText = (plant: PlantSchema) => {
  if (plant.flowerSeason.length === 0) return "";
  return ColorUtils.getColorGroupText(
    ColorUtils.getColorGroup(plant.flowerColor)
  );
};

/**
 * Get the text representation of a plant's leaf color. If plant leaves are multicolors, both
 * colors are included, separated by a slash.
 * @param plant
 * @returns
 */
const getLeafColorText = (plant: PlantSchema) => {
  const primary = ColorUtils.getColorGroupText(
    ColorUtils.getColorGroup(plant.leafColor)
  );
  if (plant.leafColorVariegation) {
    const secondary = ColorUtils.getColorGroupText(
      ColorUtils.getColorGroup(plant.leafColorVariegation)
    );
    return primary + "/" + secondary;
  }
  return primary;
};

/**
 * Get the text representation of a plant's fall color.
 * @param plant
 * @returns
 */
const getLeafColorTransitionalText = (plant: PlantSchema) => {
  if (
    plant.leafColorTransitionalSeason &&
    plant.leafColorTransitionalSeason.length > 0
  ) {
    return ColorUtils.getColorGroupText(
      ColorUtils.getColorGroup(plant.leafColorTransitional)
    );
  }
  return "";
};

/**
 * Get the text representation of a plant's trunk color.
 * @param plant
 * @returns
 */
const getTrunkColorText = (plant: PlantSchema) => {
  if (plant.branchTemplate) {
    return ColorUtils.getColorGroupText(
      ColorUtils.getColorGroup(plant.branchColor)
    );
  }
  return "";
};

export const PlantModelText = {
  getCategoryText,
  getCategoryOptions,
  getHeightTextFull,
  getHeightAvgTextFull,
  getWidthText,
  getFloweringColorText,
  getLeafColorText,
  getLeafColorTransitionalText,
  getTrunkColorText,
};
