import React, { useEffect, useState, useRef } from "react";

import { Point, Trig } from "imagine-essentials";
import { Checkbox, Device } from "imagine-ui";
import { EventProcess } from "draw";

const longTouchTime = 800;

type Props = {
  checked: boolean;
  onCheckedChanged?: (checked: boolean) => void;
  // id: string; /// Unique ID used to map the label to the checkbox when clicked, no spaces allowed
  text: string;
  editable?: boolean; /// Whether the text field is editable. No visual difference.
  onTextChanged?: (value: string) => void;
  onTextFinished?: (value: string) => void;
  idPrefix?: string;
  onLongTouch?: (position: Point) => void;
};

/**
 * A checkbox wrapper for easier usage.
 * @param props
 */
export const ZoneItem = (props: Props) => {
  const inputRef = useRef<HTMLInputElement>(null);

  // These are used to detect long touches (right click)
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [touchStartPos, setTouchStartPos] = useState({ x: 0, y: 0 });
  const isTouchDevice = Device.isTouchDevice();

  const handleCheckboxChange = (checked: boolean) => {
    if (props.onCheckedChanged) {
      props.onCheckedChanged(checked);
    }
  };

  const [internalValue, setInternalValue] = useState<string>(props.text);

  const updateValue = (event: any) => {
    setInternalValue(event.target.value);
    if (props.onTextChanged) {
      props.onTextChanged(event.target.value);
    }
  };

  const finishEditing = () => {
    if (props.onTextFinished) {
      props.onTextFinished(internalValue);
    }
  };

  const checkKeypress = (event: any) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      event.target.blur();
      if (props.onTextFinished) {
        props.onTextFinished(internalValue);
      }
    }
  };

  const startTouch = (event: any) => {
    const pos = EventProcess.getEventPosition(event);
    setTouchStartPos(pos);

    timeoutRef.current = setTimeout(() => {
      if (props.onLongTouch) {
        props.onLongTouch(pos);
      }
    }, longTouchTime);
  };

  const moveTouch = (event: any) => {
    if (timeoutRef.current !== null) {
      const pos = EventProcess.getEventPosition(event);
      if (Trig.calculateDistance(pos, touchStartPos) > 2) {
        clearTimeout(timeoutRef.current);
      }
    }
  };

  const endTouch = () => {
    if (timeoutRef.current !== null) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    setInternalValue(props.text);
  }, [props.text]);

  useEffect(() => {
    if (props.editable) {
      if (inputRef.current !== null) {
        inputRef.current.focus();
      }
    }
  }, [props.editable]);

  return (
    <div
      className="zone-item"
      onTouchStart={startTouch}
      onTouchMove={moveTouch}
      onTouchEnd={endTouch}
    >
      {/* <div className="check"> */}
      <Checkbox
        onChange={handleCheckboxChange}
        checked={props.checked}
        id={props.idPrefix + "-checkbox"}
      />
      {/* </div> */}
      <div className="name">
        {props.editable && (
          <input
            className="text"
            ref={inputRef}
            value={internalValue}
            onChange={updateValue}
            onBlur={finishEditing}
            onKeyPress={checkKeypress}
            id={props.idPrefix + "-input"}
            // style={{cursor: props.editable ? "text" : (props.defaultCursor || "default")}}
          />
        )}
        {!props.editable && <div className="text">{internalValue}</div>}
      </div>
    </div>
  );
};
