import { Api, DataResponse, SentryReporter } from "imagine-essentials";
import { FeedbackType, UserRole } from "..";
import { UserText } from "../utils";
import { t } from "i18next";

const sendFeedback = async (
  description: string,
  feedbackType: FeedbackType,
  version: string,
  userId: number,
  userRole: UserRole,
  customImage?: File
) => {
  const formData = new FormData();
  formData.append("description", description);
  formData.append("type", feedbackType.toString());
  formData.append("version", version);
  formData.append("userId", userId.toString());
  formData.append("userRole", userRole.toString());

  if (customImage) {
    formData.append("customImage", customImage);
  }

  const result = await Api.postForm("api/users/feedback", formData);
  const response: DataResponse<void> = {
    success: result.success,
  };
  if (!result.success) {
    SentryReporter.captureException("Failed to send feedback", {
      "Server response": JSON.stringify(result),
      Description: description,
      Type: UserText.getFeedbackTypeText(feedbackType),
    });
    response.errorMessage = t("users:failedToSendFeedback");
  }
  return response;
};

export const FeedbackApi = {
  sendFeedback,
};
