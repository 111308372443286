import { ColorPicker as ImagineColorPicker } from "imagine-ui";
import { Colors } from "project";

type ColorInputProps = {
  show: boolean;
  color: string | undefined;
  onChange?: (hex: string) => void;
  onClose: () => void;
};

/**
 * A wrapper for the ColorPicker from imagine-ui. This one has the Garden Sketcher colors pre-defined.
 * @param props
 * @returns
 */
export const ColorPicker = (props: ColorInputProps) => {
  return (
    <ImagineColorPicker
      show={props.show}
      color={props.color}
      onChange={props.onChange}
      onClose={props.onClose}
      colors={Colors.allColors}
    />
  );
};
