import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Size } from "imagine-essentials";
import { Device } from "imagine-ui";
import { Pages, Tour } from "../../enums";
import { UserAccountTab } from "../../types";

interface LayoutState {
  windowSize: Size;
  sidebarLeftActiveTab: string;
  sidebarRightActiveTab: string;
  footerActiveTab: string;
  activeTab: string;
  displayedPageStack: Pages[];
  userAccountTab: UserAccountTab;
  sidebarLeftActiveTabPrevious: string; // Used when a tab is automatically opened to store the previous tab
  activeTabPrevious: string;
  currentTour: Tour;
}

const initialState: LayoutState = {
  windowSize: {
    width: Device.getWindowWidth(),
    height: Device.getWindowHeight(),
  },
  sidebarLeftActiveTab: "",
  sidebarRightActiveTab: "",
  footerActiveTab: "",
  activeTab: "home-tab",
  displayedPageStack: [],
  userAccountTab: "profile",
  sidebarLeftActiveTabPrevious: "",
  activeTabPrevious: "",
  currentTour: Tour.NONE,
};

export const layoutSlice = createSlice({
  name: "layout",
  initialState,
  reducers: {
    setWindowSize: (state, action: PayloadAction<Size>) => {
      state.windowSize = action.payload;
    },
    setSidebarLeftActiveTab: (state, action: PayloadAction<string>) => {
      state.sidebarLeftActiveTab = action.payload;
    },
    setSidebarLeftActiveTabAuto: (state, action: PayloadAction<string>) => {
      state.sidebarLeftActiveTabPrevious = state.sidebarLeftActiveTab;
      state.sidebarLeftActiveTab = action.payload;
    },
    restoreSidebarLeftActiveTab: (state) => {
      if (state.sidebarLeftActiveTabPrevious !== "") {
        state.sidebarLeftActiveTab = state.sidebarLeftActiveTabPrevious;
        state.sidebarLeftActiveTabPrevious = "";
      } else state.sidebarLeftActiveTab = "";
    },
    setSidebarRightActiveTab: (state, action: PayloadAction<string>) => {
      state.sidebarRightActiveTab = action.payload;
    },
    setFooterActiveTab: (state, action: PayloadAction<string>) => {
      state.footerActiveTab = action.payload;
    },
    setActiveTab: (state, action: PayloadAction<string>) => {
      if (state.activeTab !== "item-tab") {
        state.activeTabPrevious = state.activeTab;
      }
      state.activeTab = action.payload;
    },
    setDisplayedPage: (state, action: PayloadAction<Pages>) => {
      // Displayed page is removed, and any previously hidden page is displayed
      if (action.payload === Pages.NONE) {
        if (state.displayedPageStack.length > 0) {
          state.displayedPageStack.pop();
        }
      } else {
        // Currently displayed page is added to stack and new page is displayed
        if (state.displayedPageStack.length > 0) {
          if (state.displayedPageStack[0] === action.payload) {
            return;
          }
        }
        state.displayedPageStack.push(action.payload);
      }
    },
    replaceDisplayedPage: (state, action: PayloadAction<Pages>) => {
      if (action.payload === Pages.NONE) {
        if (state.displayedPageStack.length > 0) {
          state.displayedPageStack = [];
        }
      } else {
        if (state.displayedPageStack.length > 0) {
          state.displayedPageStack.pop();
        }
        state.displayedPageStack.push(action.payload);
      }
    },
    setUserAccountTab: (state, action: PayloadAction<UserAccountTab>) => {
      state.userAccountTab = action.payload;
    },
    setCurrentTour: (state, action: PayloadAction<Tour>) => {
      state.currentTour = action.payload;
    },
  },
});

export const LayoutActions = layoutSlice.actions;

export default layoutSlice.reducer;
