import { Colors } from "project";
import {
  BranchTemplates,
  CanvasOperations,
  EllipseProperties,
  FlowerTemplates,
  ItemType,
  LeafTemplates,
  Model,
  ModelDesign,
  PathProperties,
  ShapeProperties,
  ShapeStyle,
  ShapeType,
  ShapeValidation,
  plantFlowersDataSimplified,
  PlantCategory,
  PlantSchema,
  TopTemplate,
  FruitTemplates,
} from "..";

import {
  SentryReporter,
  Point,
  Size,
  Tools,
  ColorUtils,
  Trig,
} from "imagine-essentials";

const missingTemplate: PlantSchema = {
  id: 0,
  name: "Missing template",
  width: 1,
  leafTemplate: 1,
  leafColor: "#54a647",
  leafSeason: [5, 6, 7, 8, 9],
  branchTemplate: 0,
  branchColor: "brown",
  flowerTemplate: 0,
  flowerColor: "#c255c9",
  flowerSeason: [],
  category: 0, //PlantCategory.PERENNIAL,
};

const SIMPLIFY_FLOWERS_LIMIT = 25;
const SIMPLIFY_LEAVES_LIMIT = 10; // Flowers are extremely simplified

const enum Simplify {
  NONE = 0,
  FLOWERS = 1,
  LEAVES = 2, // And flowser (most simplified)
}

// const emptyModels = [
//   {
//     id: 0,
//     shapes: [
//       {
//         type: ShapeType.RECTANGLE,
//         properties: {
//           size: { width: 100, height: 5 },
//           position: { x: 0, y: 48 },
//         },
//         style: {
//           fillColor: "red",
//         },
//       },
//     ],
//   },
// ] as ModelDesign[];

/**
 * Gets the first path in the matching template.
 * @param id
 */
const getLeavesPath = (id: number) => {
  let path = "";
  LeafTemplates.forEach((leaves: TopTemplate) => {
    if (leaves.id === id) {
      if (leaves.paths.length > 0) {
        path = leaves.paths[0];
      }
      // if (ShapeValidation.isPath(leaves.shapes[0].properties)) {
      //   const pathProperties = leaves.shapes[0].properties as PathProperties;
      //   path = pathProperties.path;
      // }
    }
  });
  if (path === "") console.error("Leaves ID does not exist:", id);
  return path;
};

/**
 * Generates a list of shapes from a leaf design with a specific style applies
 * @param id The ID of the leaf design
 * @param style The style that should be applied to the shapes
 * @param secondaryShapes True if the secondary shapes should be used. Default is the main shapes.
 * @param simplified True if shape should simply be a circle
 */
const getStyledLeafShapes = (
  id: number,
  style: ShapeStyle,
  secondaryShapes?: boolean,
  simplified = false
) => {
  if (simplified) {
    const ellipseProperties = {
      radius: { width: 45, height: 45 },
      center: { x: 50, y: 50 },
    } as EllipseProperties;
    return {
      type: ShapeType.ELLIPSE,
      properties: ellipseProperties,
      style: style,
    } as ShapeProperties;
  }

  const leaves = LeafTemplates.find((design: TopTemplate) => {
    return design.id === id;
  });
  if (leaves === undefined) {
    console.error("Unable to find leaves design with ID", id);
    SentryReporter.captureException("Unable to find leaves design", {
      "Template ID": id,
    });
    return [];
  }

  if (secondaryShapes !== true) {
    return leaves.paths.map((path: string) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: style,
      } as ShapeProperties;
    });
  }
  if (leaves.variegationPaths && secondaryShapes) {
    return leaves.variegationPaths.map((path: string) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: style,
      } as ShapeProperties;
    });
  }
  // The plant does not have any secondary shapes defined in the design
  return [];
};

/**
 * Get the outline of the leaves path. This is both the first and second shapes.
 * @param id
 */
const getLeavesOutlinePaths = (id: number) => {
  const template = LeafTemplates.find((leaves: TopTemplate) => {
    return leaves.id === id;
  });
  if (template === undefined) {
    console.error("Leaves ID does not exist:", id);
    return [];
  }

  return template.paths;

  // LeafTemplates.forEach((leaves: TopTemplate) => {
  //   if (leaves.id === id) {
  //     if (ShapeValidation.isPath(leaves.shapes[0].properties)) {
  //       const pathProperties = leaves.shapes[0].properties as PathProperties;
  //       path = pathProperties.path;
  //     }
  //     if (leaves.shapes.length > 1) {
  //       if (ShapeValidation.isPath(leaves.shapes[1].properties)) {
  //         const pathProperties = leaves.shapes[1].properties as PathProperties;
  //         path += " " + pathProperties.path;
  //       }
  //     }
  //   }
  // });
  // if (path === "") console.error("Leaves ID does not exist:", id);
  // return path;
};

const getLeavesShapes = (
  template: PlantSchema,
  fall: boolean,
  simplified = false
) => {
  const color =
    fall && template.leafColorTransitional !== undefined
      ? template.leafColorTransitional
      : template.leafColor;
  const shine = template.leafColorVariegation === undefined || fall;
  let shapes: ShapeProperties[] = [];
  const primaryStyle = {
    borderColor: ColorUtils.darkenColor(color),
    // borderColor: template.leafColor,
    fill: true,
    fillColor: color,
    opacity: template.leafTransparent ? 0.5 : 1,
    shineColor: shine ? ColorUtils.lightenColor(color) : undefined,
  } as ShapeStyle;
  shapes = shapes.concat(
    getStyledLeafShapes(template.leafTemplate, primaryStyle, false, simplified)
  );

  // Add secondary leaf color shapes (except if currently showing the fall colors or simplified shape)
  if (template.leafColorVariegation && !fall && !simplified) {
    const secondaryStyle = {
      border: false,
      // borderColor: template.leafColor,
      fill: true,
      fillColor: template.leafColorVariegation,
      opacity: template.leafTransparent ? 0.4 : 1,
      // shineColor: shine ? Colors.lightenColor(color) : undefined,
    } as ShapeStyle;
    shapes = shapes.concat(
      getStyledLeafShapes(template.leafTemplate, secondaryStyle, true)
    );
  }
  return shapes;
};

const getLeavesOutlineShapes = (
  template: PlantSchema,
  fall: boolean,
  simplified = false
) => {
  // Do not include outline for simplified plants
  if (simplified) return [];
  const color =
    fall && template.leafColorTransitional
      ? template.leafColorTransitional
      : template.leafColor;
  const shapes = getLeavesOutlinePaths(template.leafTemplate).map(
    (path: string) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: {
          border: true,
          borderColor: ColorUtils.darkenColor(color),
          fill: false,
          opacity: 1,
        },
      };
    }
  );
  return shapes;
};

const getLeafDesigns = (color: string, category: PlantCategory) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const leafDesigns = LeafTemplates.filter((template: TopTemplate) => {
    if (template.categories === undefined) return true;
    return template.categories.includes(category);
  }).map((data: TopTemplate) => {
    const shapeStyles = [
      {
        border: true,
        borderColor: ColorUtils.darkenColor(visibleColor),
        fill: true,
        fillColor: visibleColor,
        shineColor: ColorUtils.lightenColor(visibleColor),
      },
      {
        border: true,
        borderColor: ColorUtils.darkenColor(visibleColor),
        fill: false,
      },
    ];
    const styledShapes = data.paths.map((path: string, index: number) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: shapeStyles[0],
      };
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: styledShapes,
    };
    return design;
  });
  return leafDesigns;
};

const getBranchesPaths = (id: number) => {
  const template = BranchTemplates.find((branches: TopTemplate) => {
    return branches.id === id;
  });
  if (template !== undefined) {
    return template.paths;
  } else {
    console.error("Branches ID does not exist:", id);
    return [""];
  }
};

const getBranchesShapes = (template: PlantSchema) => {
  return getBranchesPaths(template.branchTemplate).map((path: string) => {
    const shape = {
      type: ShapeType.PATH,
      properties: {
        path: path,
      },
      style: {
        border: true,
        borderColor: template.branchColor,
        fill: true,
        fillColor: template.branchColor,
        opacity: 1,
      },
    };
    return shape;
  });
};

const getBranchDesigns = (color: string) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const designs = BranchTemplates.map((data: TopTemplate) => {
    const style = {
      border: true,
      borderColor: visibleColor,
      fill: true,
      fillColor: visibleColor,
      opacity: 1,
    };
    const styledShapes = data.paths.map((path: string) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: style,
      };
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: styledShapes,
    };
    return design;
  });
  return designs;
};

const getFruitShapes = (template: PlantSchema) => {
  const fruitTemplate = FruitTemplates.find((fruit: TopTemplate) => {
    return fruit.id === template.fruitTemplate;
  });
  if (fruitTemplate === undefined) {
    console.error("Fruit ID does not exist:", template.fruitTemplate);
    return [];
  }

  return fruitTemplate.paths.map((path: string) => {
    const shape = {
      type: ShapeType.PATH,
      properties: {
        path: path,
      },
      style: {
        border: true,
        borderColor: ColorUtils.darkenColor(
          template.fruitColor || Colors.red[1]
        ),
        fill: true,
        fillColor: template.fruitColor || Colors.red[1],
        opacity: 1,
      },
    };
    return shape;
  });
};

const getFruitDesigns = (color: string) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const designs = FruitTemplates.map((data: TopTemplate) => {
    const style = {
      border: true,
      borderColor: visibleColor,
      fill: true,
      fillColor: visibleColor,
      opacity: 1,
    };
    const styledShapes = data.paths.map((path: string) => {
      return {
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: style,
      };
    });
    const design: ModelDesign = {
      id: data.id,
      shapes: styledShapes,
    };
    return design;
  });
  return designs;
};

/**
 * Get all flower shapes for template.
 * @param template The plant template
 * @param simplified If only flowers are simplified, then each flowers if displayed as a
 * circle. If leaves are simplifies, then all flowers are displayed as a single circle.
 */
const getFlowerShapes = (template: PlantSchema, simplified = Simplify.NONE) => {
  let shapes: ShapeProperties[] = [];
  const data =
    simplified === Simplify.NONE ? FlowerTemplates : plantFlowersDataSimplified;
  const flowerTemplate = FlowerTemplates.find((flowers: TopTemplate) => {
    return flowers.id === template.flowerTemplate;
  });
  if (flowerTemplate !== undefined) {
    flowerTemplate.paths.forEach((path: string) => {
      shapes.push({
        type: ShapeType.PATH,
        properties: {
          path: path,
        },
        style: {
          fillColor: template.flowerColor,
          opacity: 1,
        },
      });
    });
    if (template.flowerColorVariegation && flowerTemplate.variegationPaths) {
      flowerTemplate.variegationPaths.forEach((path: string) => {
        shapes.push({
          type: ShapeType.PATH,
          properties: {
            path: path,
          },
          style: {
            fillColor: template.flowerColorVariegation,
            opacity: 1,
          },
        });
      });
    }
  }

  if (shapes.length === 0)
    console.error("Flowers ID does not exist:", template.flowerTemplate);
  return shapes;
};

const getFlowerDesigns = (color: string) => {
  let visibleColor = color;
  if (color === "#ffffff") {
    visibleColor = "#eeeeee";
  }
  const flowerStyle: ShapeStyle = {
    fillColor: visibleColor,
  };
  const flowersDesigns: ModelDesign[] = FlowerTemplates.map(
    (data: TopTemplate) => {
      const shapes: ShapeProperties[] = data.paths.map((path: string) => {
        return {
          type: ShapeType.PATH,
          properties: {
            path: path,
          },
          style: flowerStyle,
        };
      });
      // // TODO: Remove when done (border circle to help position flowers)
      // shapes.push({
      //   type: ShapeType.ELLIPSE,
      //   properties: {
      //     center: {x: 50, y: 50},
      //     radius: {width: 48, height: 48}
      //   }
      // });
      return {
        id: data.id,
        shapes: shapes,
      };
    }
  );

  return flowersDesigns;
};

/**
 * Get all shapes used to draw the plant based on the plant template and the current month
 * it should be displayed in.
 * @param template Plant template
 * @param month Month to display the plant.
 * @param simplified Simplify the shapes to increase performance
 */
const getShapes = (
  template: PlantSchema,
  month: number,
  simplified = Simplify.NONE
) => {
  let shapes: ShapeProperties[] = [];
  // If branches are defined, they are displayed for all months
  if (template.branchTemplate) {
    shapes = shapes.concat(getBranchesShapes(template));
  }

  if (template.leafColorTransitionalSeason?.includes(month)) {
    shapes = shapes.concat(
      getLeavesShapes(template, true, simplified === Simplify.LEAVES)
    );
    // shapes = shapes.concat(
    //   getLeavesOutlineShapes(template, true, simplified === Simplify.LEAVES)
    // );
  } else if (template.leafSeason.includes(month) || month < 1) {
    shapes = shapes.concat(
      getLeavesShapes(template, false, simplified === Simplify.LEAVES)
    );
    // shapes = shapes.concat(
    //   getLeavesOutlineShapes(template, false, simplified === Simplify.LEAVES)
    // );
  }

  if (template.flowerTemplate) {
    // Show flowers it is a flowering month OR if no month is selected and the plant is not a tree (displaying flowers on trees makes if difficult to see what's beneath the tree, as the leaves are often transparent)
    if (
      template.flowerSeason.includes(month) ||
      month === -1 ||
      (month === 0 && template.category !== PlantCategory.TREE)
    ) {
      shapes = shapes.concat(getFlowerShapes(template, simplified));
    }
  }

  if (template.fruitTemplate && template.fruitSeason) {
    if (template.fruitSeason.includes(month)) {
      shapes = shapes.concat(getFruitShapes(template));
    }
  }

  return shapes;
};

const createModel = (
  template: PlantSchema,
  zoom: number,
  reference: Point,
  month: number,
  itemSizePx: Size
) => {
  let simplified = Simplify.NONE;
  if (
    itemSizePx.width < SIMPLIFY_LEAVES_LIMIT &&
    itemSizePx.height < SIMPLIFY_LEAVES_LIMIT
  )
    simplified = Simplify.LEAVES;
  else if (
    itemSizePx.width < SIMPLIFY_FLOWERS_LIMIT &&
    itemSizePx.height < SIMPLIFY_FLOWERS_LIMIT
  )
    simplified = Simplify.FLOWERS;

  const model: Model = {
    id: template.id,
    itemType: ItemType.PLANT,
    size: itemSizePx,
    rotation: 0,
    position: CanvasOperations.unitToPixelPosition(
      { x: 0, y: 0 },
      zoom,
      reference
    ),
    shapes: getShapes(template, month, simplified),
    viewBox: "0 0 100 100",
  };

  return model;
};

/**
 * Creates design for the plant template.
 * @param template Plant template
 * @param month Viewing month. Use 1-12 for actual months. 0 for editing mode (tree flowers are hidden) and -1 for full colors.
 */
const createDesign = (template: PlantSchema, month: number = 0) => {
  try {
    const design: ModelDesign = {
      id: template.id,
      shapes: getShapes(template, month),
      name: template.name,
    };
    return design;
  } catch (e) {
    console.error(template);
    console.error(e);
    throw new Error("Invalid plant template");
  }
};

const getTemplateDesigns = (templates: PlantSchema[], month = -1) => {
  return templates.map((template: PlantSchema) => {
    return createDesign(template, month);
  });
};

const getTemplate = (templateId: number, plantTemplates: PlantSchema[]) => {
  const template = plantTemplates.find((template: PlantSchema) => {
    return template.id === templateId;
  });
  if (template !== undefined) return template;
  // TODO: Make the fallback a red circle or something
  else return missingTemplate;
};

/**
 * Get the size of a plant in m from a template.
 * @param template The plant template
 */
const getItemSizeFromTemplate = (template: PlantSchema) => {
  const widthM = Tools.round(template.width, 2);
  return { width: widthM, height: widthM } as Size;
};

export const PlantModelOperations = {
  getLeafDesigns,
  createModel,
  createDesign,
  getBranchDesigns,
  getFruitDesigns,
  getFlowerDesigns,
  getTemplateDesigns,
  getTemplate,
  getLeavesPath,
  getItemSizeFromTemplate,
};
