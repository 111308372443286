import {
  useAppDispatch,
  useAppSelector,
  LayoutSelector,
  LayoutActions,
  ObjectEditorSelector,
  ObjectEditorActions,
  PlanEditorSelector,
  CanvasSelector,
  PlanEditorActions,
  CanvasActions,
} from "../../store";
import { ObjectsApi } from "draw";
import { useState } from "react";
import { Pages } from "../../enums";
import { Canvas, PlanNameModal, SaveObjectModal } from "../../components";
import { useTranslation } from "react-i18next";
import { NotificationType, useNotification } from "imagine-ui";
import { PlansApi } from "../../api";
import { PlanOperations } from "../../utils";

/**
 * Handles the saving of a new object to the database, together with setting the name and category.
 */
export const SavePlanModalContainer = () => {
  const dispatch = useAppDispatch();
  const notification = useNotification();
  const { t } = useTranslation();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);

  const [loading, setLoading] = useState(false);

  const name = useAppSelector(PlanEditorSelector.getPlanName);
  const layers = useAppSelector(PlanEditorSelector.getLayers);
  const groups = useAppSelector(PlanEditorSelector.getGroups);
  const items = useAppSelector(PlanEditorSelector.getAllItems);
  const area = useAppSelector(PlanEditorSelector.getArea);
  const planSettingsCanvas = useAppSelector(CanvasSelector.getPlanSettings);
  const planSettingsPlanEditor = useAppSelector(
    PlanEditorSelector.getPlanSettings
  );

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  /**
   * Saves a new object to the database. This can either be a completely new obejct or a copy of an existing object.
   * @param name
   * @param category
   */
  const saveNew = async (name: string) => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
    dispatch(CanvasActions.setLoadingMessage(t("savingPlan")));
    const planSettings = {
      ...planSettingsCanvas,
      ...planSettingsPlanEditor,
    };
    const response = await PlansApi.saveNewPlan(
      name,
      layers,
      groups,
      items,
      area,
      planSettings
    );
    if (response.success && response.data) {
      dispatch(PlanEditorActions.setPlanInfo(response.data));

      const thumbnail = await PlanOperations.getPlanThumbnailImage();
      if (thumbnail !== null) {
        await PlansApi.updateThumbnail(response.data.id, thumbnail);
      }
      // Make this plan auto open next time the Garden Sketcher i opened
      localStorage.setItem("planId", response.data.id.toString());
    } else {
      if (response.errorCode === "no-access") {
        notification.add(
          NotificationType.ERROR,
          t("needToLoginAgainDueToError")
        );
        dispatch(LayoutActions.setDisplayedPage(Pages.LOGIN));
      } else if (response.errorMessage) {
        notification.add(NotificationType.ERROR, response.errorMessage);
      }
    }
    dispatch(CanvasActions.setLoadingMessage(""));
  };

  return (
    <>
      {displayedPage === Pages.SAVE_PLAN && (
        <PlanNameModal
          name={name}
          onClose={close}
          onSave={saveNew}
          loading={loading}
          confirmText={t("imagine:save")}
          title={t("saveNewPlan")}
        />
      )}
    </>
  );
};
