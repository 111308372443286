export enum Pattern {
  NONE = 0,
  PLANKS_HORIZONTAL = 1,
  PLANKS_VERTICAL = 2,
  TILES_30_30 = 3,
  TILES_60_30_HORIZONTAL = 4,
  TILES_60_30_VERTICAL = 5,
  TILES_60_60 = 6,
  TILES_90_90 = 7,
  HERREGARD_TILES_HORIZONTAL = 8,
  HERREGARD_TILES_VERTICAL = 9,
  TILES_10_10 = 10,
  MIX_SIZES = 11,
  GRAVEL_LARGE = 12,
  GRAVEL_SMALL = 13,
  BASKET_WEAVE_DOUBLE = 14,
  HERRINGBONE = 15,
  THREE_CONNECTED = 16,
  _SIZE = 17,
}
