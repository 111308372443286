import React from "react";
import {
  CurvedLinePathProperties,
  CurvedLinePathSvg,
  DimensionProperties,
  DimensionSvg,
  EllipseProperties,
  EllipseSvg,
  LinePathProperties,
  LinePathSvg,
  PathProperties,
  PathSvg,
  RectangleProperties,
  RectangleSvg,
  ShapeProperties,
  ShapeType,
  ShapeValidation,
  TextProperties,
  TextSvg,
} from "..";
import { Delimiter, Point } from "imagine-essentials";
import { UnitScale } from "project";

type ShapeSvgProps = {
  shape: ShapeProperties;
  pointPerPixel?: Point;
  uniqueId?: string;
  selected?: boolean; /// Some shapes will display a little different if they are selected
  cursor?: string;
  className?: string; // This class will be given to the shape
  delimiter: Delimiter;
  unitScale: UnitScale;
};

export const ShapeSvg = (props: ShapeSvgProps) => {
  const render = () => {
    switch (props.shape.type) {
      case ShapeType.RECTANGLE:
        if (ShapeValidation.isRectangle(props.shape.properties)) {
          return (
            <RectangleSvg
              rectangle={props.shape.properties as RectangleProperties}
              style={props.shape.style}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              cursor={props.cursor}
              rotation={props.shape.rotation}
              className={props.className}
              uniqueId={props.uniqueId}
            />
          );
        } else {
          throw Error("Rectangle properties mismatch");
        }
      case ShapeType.PATH:
        if (ShapeValidation.isPath(props.shape.properties)) {
          return (
            <PathSvg
              path={props.shape.properties as PathProperties}
              style={props.shape.style}
              uniqueId={props.uniqueId}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              cursor={props.cursor}
              className={props.className}
            />
          );
        } else {
          throw Error("Path properties mismatch");
        }
      case ShapeType.ELLIPSE:
        if (ShapeValidation.isEllipse(props.shape.properties)) {
          return (
            <EllipseSvg
              ellipse={props.shape.properties as EllipseProperties}
              style={props.shape.style}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              markCenter={props.selected}
              cursor={props.cursor}
              rotation={props.shape.rotation}
              className={props.className}
              uniqueId={props.uniqueId}
            />
          );
        } else {
          throw Error("Ellipse properties mismatch");
        }
      case ShapeType.LINE:
        if (ShapeValidation.isLinePath(props.shape.properties)) {
          return (
            <LinePathSvg
              linePath={props.shape.properties as LinePathProperties}
              style={props.shape.style}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              cursor={props.cursor}
              rotation={props.shape.rotation}
              className={props.className}
              uniqueId={props.uniqueId}
            />
          );
        } else {
          throw Error("Line path properties mismatch");
        }
      case ShapeType.CURVED_LINE:
        if (ShapeValidation.isCurvedLinePath(props.shape.properties)) {
          return (
            <CurvedLinePathSvg
              curvedLinePath={
                props.shape.properties as CurvedLinePathProperties
              }
              style={props.shape.style}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              cursor={props.cursor}
              rotation={props.shape.rotation}
              className={props.className}
              uniqueId={props.uniqueId}
            />
          );
        } else {
          throw Error("Curved line path properties mismatch");
        }
      case ShapeType.DIMENSION:
        if (ShapeValidation.isDimension(props.shape.properties)) {
          return (
            <DimensionSvg
              dimension={props.shape.properties as DimensionProperties}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              style={props.shape.style}
              cursor={props.cursor}
              className={props.className}
              uniqueId={props.uniqueId}
              delimiter={props.delimiter}
              unitScale={props.unitScale}
            />
          );
        } else {
          throw Error("Dimension properties mismatch");
        }
      case ShapeType.TEXT:
        if (ShapeValidation.isText(props.shape.properties)) {
          return (
            <TextSvg
              text={props.shape.properties as TextProperties}
              pointsPerPixel={props.pointPerPixel || { x: 1, y: 1 }}
              style={props.shape.style}
              cursor={props.cursor}
              className={props.className}
            />
          );
        } else {
          console.error("Text properties:", props.shape.properties);
          throw Error("Text properties mismatch");
        }
      default:
        throw Error("Undefined shape type: " + props.shape.type);
    }
  };

  return render();
};
