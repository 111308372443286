import { useCallback, useEffect, useState } from "react";

import {
  useAppDispatch,
  useAppSelector,
  PlantManagementActions,
  LayoutSelector,
  LayoutActions,
  PlanEditorActions,
} from "../../store";
import { PlantsApi, UserPlantsPage } from "plants";

import { useTranslation } from "react-i18next";
import { useNotification } from "imagine-ui";
import { Pages } from "../../enums";
import { ItemType, PlantSchema } from "draw";
import { UserSelector } from "imagine-users";
import { UserHelpers, UserPreferences } from "project";
import { I18nTools } from "imagine-i18n";

/**
 * Responsible for connection the state and API calls to the user plants page.
 */
export const UserPlantsPageContainer = () => {
  const { t } = useTranslation();
  const notification = useNotification();
  const dispatch = useAppDispatch();
  // The currently displayed page (will fill most of the screen)
  const displayedPage = useAppSelector(LayoutSelector.getDisplayedPage);
  const [error, setError] = useState("");
  const [plants, setPlants] = useState<PlantSchema[]>([]);
  const [loading, setLoading] = useState(false);
  const user = useAppSelector(UserSelector.getUserNotNull);
  const userPreferences = UserHelpers.getCompleteUserPreferences(
    useAppSelector(UserSelector.getPreferences) as UserPreferences,
    user.country
  );
  const delimiter = I18nTools.getDelimiter(user.language);

  const loadUserPlants = useCallback(async () => {
    if (displayedPage !== Pages.USER_PLANTS) return;
    setLoading(true);
    const response = await PlantsApi.getUserPlants();
    if (response.success && response.data) {
      setPlants(response.data);
    } else {
      setError(t("plants:errorLoadingUserPlants"));
    }
    setLoading(false);
  }, [displayedPage, t]);

  useEffect(() => {
    loadUserPlants();
  }, [loadUserPlants]);

  const close = () => {
    dispatch(LayoutActions.setDisplayedPage(Pages.NONE));
  };

  // Implement this when the project is moved to the cloud and it is possibel to check if the plant is used in any plans
  const deletePlant = async (plantId: number) => {};

  const showPlantInfo = (plant: PlantSchema) => {
    dispatch(PlantManagementActions.setFocusTemplate(plant));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_INFO));
  };

  const copyPlant = (plant: PlantSchema) => {
    dispatch(PlantManagementActions.setFocusTemplate(plant));
    dispatch(PlantManagementActions.setCopyPlant(true));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_EDITOR));
  };

  const insertPlant = (plant: PlantSchema) => {
    dispatch(PlanEditorActions.setStampTemplate(plant));
    dispatch(PlanEditorActions.setStampType(ItemType.PLANT));
    close();
  };

  const editPlant = (plant: PlantSchema) => {
    dispatch(PlantManagementActions.setFocusTemplate(plant));
    dispatch(PlantManagementActions.setCopyPlant(false));
    dispatch(LayoutActions.setDisplayedPage(Pages.PLANT_EDITOR));
  };

  return (
    <UserPlantsPage
      visible={displayedPage === Pages.USER_PLANTS}
      plants={plants}
      onDelete={deletePlant}
      onShowPlantInfo={showPlantInfo}
      onCopy={copyPlant}
      onInsert={insertPlant}
      onEdit={editPlant}
      onClose={close}
      errorMessage={error}
      loading={loading}
      unitScale={userPreferences.unitScale}
      delimiter={delimiter}
      showHardinessZoneRHS={userPreferences.showHardinessZoneRHS}
    />
  );
};
